import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import NewUserRequestContext from 'context/NewUserRequestContext';
import moment from 'moment';
import { getSpecificDealers } from 'pages/CreateApplication/InformationRequest/moduleUtilities/dropdownApis';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { userRequestSchema } from 'schemaValidations/userRequestSchema';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { handleFocusError } from 'utils/utility';

import Loader from 'components/Loader/Loader';

import UserRequestInformation from './UserRequestInformation/UserRequestInformation';

const UserRequest = () => {
  const [loader, setLoader] = useState(false);
  const ApiClient = new ApiClass();
  const [formDetails, setFormDetails] = useState({ dealers: [] });
  const [dealers, setDealers] = useState([]);
  const [accessTypes, setAccessTypes] = useState([]);
  let { accessToken, role, userID, userName, setOpenDiscardModal } = useContext(AuthContext);
  const {
    control,
    watch,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm({
    mode: 'all',

    defaultValues: {
      dealer: '',

      mobileUser: '',
      firstName: '',
      lastName: '',
      secondLastName: '',
      email: '',
      ssn: '',
      dob: '',
      accessType: '',
      comment: '',
    },
    resolver: yupResolver(userRequestSchema),
  });
  const [
    watchdealer,

    watchmobileUser,
    watchfirstName,
    watchlastName,
    watchsecondLastName,
    watchemail,
    watchssn,
    watchaccessType,
    watchcomment,
    watchdob,
  ] = watch([
    'dealer',

    'mobileUser',
    'firstName',
    'lastName',
    'secondLastName',
    'email',
    'ssn',
    'accessType',
    'comment',
    'dob',
  ]);
  console.log({ errors });
  const navigate = useNavigate();
  useEffect(() => {
    handleFocusError({ errors, setFocus });
  }, [errors]);
  const getAccessTypes = async () => {
    try {
      return JSON.parse((await ApiClient.get(UrlHelpers.accessTypes))['access-type']);
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(async () => {
    setFormDetails({ ...formDetails, name: userName });
    setDealers(await getSpecificDealers(userID));
    setAccessTypes(await getAccessTypes());
  }, []);

  useEffect(async () => {
    if (watchdealer) {
      const dealerNumber = dealers?.find((d) => d?.dealerName === watchdealer)?.dealerId;
      if (dealerNumber) {
        setFormDetails({
          ...formDetails,
          dealerNumber: dealerNumber,
        });
      }
    }
  }, [watchdealer]);

  const nextStep = async () => {
    const formObj = {
      dealer: watchdealer,
      dealerNumber: formDetails?.dealerNumber,
      phone: '+1' + watchmobileUser,
      accessType: watchaccessType,
      firstName: watchfirstName,
      lastName: watchlastName,
      secondLastName: watchsecondLastName,
      ssn: watchssn,
      dob: moment(watchdob).format('MM/DD/YYYY'),
      phoneUser: '+1' + watchmobileUser,
      email: watchemail,
      commentsDealer: watchcomment,
      autherize: true,
      title: '',
      date: moment().format('MM/DD/YYYY'),
      name: userName,
      requesterId: userID,
    };
    console.log('formObj');
    console.log(formObj);

    try {
      setLoader(true);
      const r = await ApiClient.post(
        `${UrlHelpers.newUserRequest}`,
        { ...formObj },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
            RoleID: role,
          },
        }
      );
      console.log(r);
      navigate('/home');
    } catch (e) {
      console.log('kkkkkkkkkk');
      console.log('e', e);
    } finally {
      setLoader(false);
      console.log('finally');
    }
  };
  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const userContextValue = useMemo(
    () => ({
      formDetails,
      setFormDetails,
      accessTypes,
      dealers,
    }),
    [formDetails, setFormDetails, accessTypes, dealers]
  );

  return (
    <>
      {loader && <Loader open={loader} />}
      <NewUserRequestContext.Provider value={userContextValue}>
        <Box py={3} px={2} display="flex" justifyContent="space-between">
          <Typography variant="h3">New user request</Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Box sx={{ width: '70%' }}>
            <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
              <Grid id="1" container justifyContent="center">
                <Box id="2" sx={{ width: '70%' }}>
                  <UserRequestInformation control={control} errors={errors} />
                </Box>
              </Grid>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" gap={2} py={4} marginLeft={'auto'}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setOpenDiscardModal(true);
                    }}
                    sx={{ fontSize: '16px' }}
                    data-testid="discard-userinfo-application"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleSubmit(nextStep)}
                    data-testid="save-continue-userinfo"
                    disabled={!formDetails.authorize || Object.entries(errors)?.length}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
      </NewUserRequestContext.Provider>
    </>
  );
};

export default UserRequest;
