import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import AvatarEditor from 'react-avatar-edit';
import classes from 'styles/profile.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn, errorHandlingMessage } from 'utils/utility';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';
import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

const ProfilePictureChangeModal = ({
  profileChangeModal,
  setProfileChangeModal,
  fetchPersonalDetail,
  setApiErr,
  setISApiSuccess,
}) => {
  const [preview, setPreview] = useState(null);
  const [apiErrr, setApiErrr] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [loader, setLoader] = useState(false);
  const ApiClient = new ApiClass();
  const { role } = useContext(AuthContext);
  const accessTokenFromIdToken = sessionStorage.getItem('accessTokenFromIdToken');
  const onCrop = (view) => {
    setPreview(view);
  };

  const handleClose = () => {
    setProfileChangeModal(false);
    fetchPersonalDetail();
    setApiErrr('');
    setApiErr('');
  };
  const handleUploadImageSize = async () => {
    const base64Data = preview?.replace(/^data:image\/\w+;base64,/, '');
    console.log({ okkk: preview });
    try {
      const binaryData = atob(base64Data);
      const imageSizeinBytes = binaryData?.length;
      const imageSizeinKB = imageSizeinBytes / 1024;
      console.log({ imageSizeinKB });
      if (imageSizeinKB > 300000) {
        setIsSuccess(false);
        setApiErrr('Upload photo lesser than 300kb');
      }
    } catch (e) {
      console.log('ert', e);
    }
  };
  console.log({ qwerty: preview });
  const handleProfileUpload = async () => {
    await handleUploadImageSize();

    if (!apiErrr) {
      try {
        setLoader(true);
        let profilePictureUpload = await ApiClient.post(
          `${UrlHelpers?.uploadPhoto}`,

          {
            decodeEncodeValue: preview,
          },
          {
            headers: {
              appid: 'auto',
              roleid: role,
              user: accessTokenFromIdToken,
              'Content-Type': 'application/json',
            },
          }
        );

        if (profilePictureUpload?.messages[0]?.code === '200') {
          setApiErr('Profile picture uploaded successfully');
          setApiErrr('');
          setPreview(null);
          setIsSuccess(true);
          setISApiSuccess(true);
          setProfileChangeModal(false);
          fetchPersonalDetail();
        }
      } catch (e) {
        console.log('error fetching', e);
        setIsSuccess(false);
        setApiErrr(errorHandlingMessage[e?.response?.data?.errorCode || 'defaultCode']?.message);
      } finally {
        setLoader(false);
      }
    }
  };

  const handleUOnBeforeFileUpload = (elem) => {
    let element = elem;
    const filetypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!filetypes?.includes(element?.target?.files[0]?.type)) {
      setIsSuccess(false);
      setApiErrr('Please upload JPEG or PNG');
      element.target.value = '';
    } else {
      setApiErrr('');
    }
  };

  return (
    <>
      <Dialog
        open={profileChangeModal}
        PaperProps={{
          sx: {
            minWidth: 'clamp(400px, 50vw, 800px) !important',
            minHeight: 'clamp(450px,60vh,100%) !important',
          },
        }}
      >
        <Loader open={loader} />
        <BootstrapDialogTitle
          id="customized-dialog-title"
          data-testid="close-modal"
          onClose={handleClose}
        >
          <Typography variant="h3" component="span">
            Change photo
          </Typography>
          <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
        </BootstrapDialogTitle>
        <DialogContent display="flex" justifyContent="center">
          <ErrorAlert apiErr={apiErrr} setApiErr={setApiErrr} success={isSuccess} />

          <Box className={classes.avatarPosition} mt={2}>
            <AvatarEditor
              width={280}
              imageWidth={280}
              height={280}
              onCrop={onCrop}
              onClose={() => setPreview(null)}
              onBeforeFileLoad={handleUOnBeforeFileUpload}
              label={
                <Box>
                  <Typography variant="h6" fontWeight="700" component="span">
                    {' '}
                    Choose a file
                  </Typography>

                  <Link
                    color="secondary"
                    data-testid="uploadPhoto"
                    className={classes.uploadButton}
                    py={2}
                    height="100%"
                  >
                    <Box display="flex" alignItems="center" justifyContent="center">
                      <Typography
                        style={{ cursor: 'pointer', textDecoration: 'none' }}
                        variant="subtitle2"
                        component="p"
                        className="plus"
                      >
                        + &nbsp;
                      </Typography>
                      <Typography
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        variant="subtitle2"
                        component="div"
                      >
                        Upload new photo
                      </Typography>
                    </Box>
                  </Link>
                </Box>
              }
              labelStyle={{ height: '100%' }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
            id="cancel-btn"
            data-testid="cancel-btn"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            sx={{ maxHeight: '40px' }}
            id="continue-btn"
            onClick={handleProfileUpload}
            disabled={apiErrr && !isSuccess}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ProfilePictureChangeModal;
ProfilePictureChangeModal.propTypes = {
  profileChangeModal: PropTypes.bool,
  setProfileChangeModal: PropTypes.func,
  setLoader: PropTypes.func,
  fetchPersonalDetail: PropTypes.func,
  setApiErr: PropTypes.func,
  setISApiSuccess: PropTypes.func,
};
ProfilePictureChangeModal.defaultProps = {
  profileChangeModal: false,
  setProfileChangeModal: defaultFn,
  setLoader: defaultFn,
  fetchPersonalDetail: defaultFn,
  setApiErr: defaultFn,
  setISApiSuccess: defaultFn,
};
