import {
  Box,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CircleTick from 'assets/svg/circleTick.svg';
import globeIcon from 'assets/svg/globeIcon.svg';
import PropTypes from 'prop-types';
import { defaultFn } from 'utils/utility';

export const HeaderLanguageComponent = ({
  isLanguageOpen,
  options,
  handleLanguageChange,
  setLanguageAnchor,
  selectedLanguage,
  languageAnchor,
  handleLanguageClose,
  handleLanguageMenu,
  setSelectedLanguage,
}) => {
  const isMobile = useMediaQuery('(max-width:899px)');
  return (
    <Box>
      <List component="nav" aria-label="Device settings" sx={{ bgcolor: 'background.paper' }}>
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="language"
          aria-expanded={isLanguageOpen ? 'true' : undefined}
          data-testid="languageOpenButton"
          onClick={(event) => handleLanguageChange(event, setLanguageAnchor)}
          sx={{ padding: 0 }}
        >
          <ListItemText
            sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
            primary={
              <img
                alt="globe"
                src={globeIcon}
                style={isMobile ? { width: '70%' } : { width: '100%' }}
              />
            }
            secondary={
              <Typography variant={isMobile ? 'body1' : 'subtitle2'} px={1}>
                {options[selectedLanguage]}
              </Typography>
            }
          />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={languageAnchor}
        open={isLanguageOpen}
        onClose={() => handleLanguageClose(setLanguageAnchor)}
        data-testid="languageCloseButton"
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedLanguage}
            data-testid="languageItem"
            onClick={(event) =>
              handleLanguageMenu(event, index, setSelectedLanguage, setLanguageAnchor)
            }
            sx={{ minWidth: '100px', fontSize: '14px !important' }}
          >
            {option} &ensp;&ensp;&ensp;
            {index === selectedLanguage && <img src={CircleTick} />}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
HeaderLanguageComponent.propTypes = {
  isLanguageOpen: PropTypes.bool,
  options: PropTypes.array,
  handleLanguageChange: PropTypes.func,
  setLanguageAnchor: PropTypes.func,
  selectedLanguage: PropTypes.number,
  languageAnchor: PropTypes.bool,
  handleLanguageClose: PropTypes.func,
  handleLanguageMenu: PropTypes.func,
  setSelectedLanguage: PropTypes.func,
};
HeaderLanguageComponent.defaultProps = {
  isLanguageOpen: defaultFn,
  options: [],
  handleLanguageChange: defaultFn,
  setLanguageAnchor: defaultFn,
  selectedLanguage: 1,
  languageAnchor: false,
  handleLanguageClose: defaultFn,
  handleLanguageMenu: defaultFn,
  setSelectedLanguage: defaultFn,
};
