const basicPayload = async ({ eventName, applicationNum }) => {
  return {
    event: eventName,
    application: {
      products: {
        productType: 'Indirect Loan Account',
        productName: 'AutoLoanInvitation',
      },
      applicationId: applicationNum,
      source: {
        language: 'EN',
        location: 'PR',
      },
    },
  };
};

export const inviFlowPayloads = async ({
  eventName,
  applicationNum,
  inviUserType,
  applicantDetails,
}) => {
  let inviInfoReqPayload = await basicPayload({ eventName, applicationNum });
  console.log('wasd', { inviUserType });
  if (inviUserType.toLowerCase() === 'primaryapplicant') {
    inviInfoReqPayload.application.applicants = { ...applicantDetails };
  } else if (inviUserType.toLowerCase() === 'coapplicant1') {
    inviInfoReqPayload.application.applicants = {};
    inviInfoReqPayload.application.applicants.coApplicants = [{ ...applicantDetails }];
  } else if (
    inviUserType.toLowerCase() === 'cosigner1' ||
    inviUserType.toLowerCase() === 'cosigner2'
  ) {
    inviInfoReqPayload.application.applicants = {};
    inviInfoReqPayload.application.applicants.coSigners = [{ ...applicantDetails }];
  }

  return inviInfoReqPayload;
};
