import axios from 'axios';
import axiosRetry from 'axios-retry';
import { Buffer } from 'buffer';
import SparkMD5 from 'spark-md5';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { imgExtension } from 'utils/utility';

import { extractAddress, uploadDocumentByPreSignedURL, uploadDocumentXL } from './uploadService';

axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition(error) {
    switch (error.response.status) {
      case 500:
      case 501:
      case 502:
      case 503:
      case 504:
      case 505:
        return true;
      default:
        return false;
    }
  },
});

export const getAccessToken = async () => {
  try {
    const tokenResponse = await axios.post(
      process.env.REACT_APP_COMMON_SERVICE_TOKEN_URL,
      {
        client_id: process.env.REACT_APP_COMMON_SERVICE_CLIENT_ID,
        grant_type: 'client_credentials',
        client_secret: process.env.REACT_APP_COMMON_SERVICE_CLIENT_SECRET,
      },
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    );

    const { access_token } = tokenResponse?.data;
    return access_token;
  } catch (e) {
    return '';
  }
};

export const getAddressLookupData = async (country, word, token) => {
  let addressLookupResponse = {};
  try {
    addressLookupResponse = await axios.get(
      `${UrlHelpers.commonComponentsAddressLookup}?ff=${word}&country=${country}&maxrecords=7`,
      {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      }
    );
    addressLookupResponse = addressLookupResponse?.data;
  } catch (e) {
    return addressLookupResponse;
  }
  return addressLookupResponse;
};

export const generateFileMD5 = (file) =>
  new Promise((resolve, reject) => {
    const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
    const chunkSize = 2097152;
    const chunks = Math.ceil(file.size / chunkSize);

    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      spark.append(e.target.result);
      currentChunk += 1;

      if (currentChunk < chunks) {
        loadNext();
      } else {
        const hash = spark.end();
        resolve(hash);
      }
    };

    fileReader.onerror = () => {
      reject();
    };

    function loadNext() {
      const start = currentChunk * chunkSize;
      const end = start + chunkSize >= file.size ? file.size : start + chunkSize;
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }
    loadNext();
  });

export const documentResponse = async ({
  fileMD5,
  format,
  name,
  idType,
  size,
  commonToken,
  setLoader,
  file,
  successCallback,
  failurecallback,
  typee,
  applicationId,
  setApiErr,
}) => {
  const fileMd5Base64 = Buffer.from(fileMD5, 'hex').toString('base64');
  const response = await uploadDocumentXL({
    format,
    name,
    idType,
    size,
    fileMd5Base64,
    commonToken,
    setLoader,
    applicationId,
    setApiErr,
  });
  if (response) {
    if (response.data) {
      setLoader(true);
      const documentId = response.data.documentId ? response.data.documentId : null;
      const preSignedUrl = response.data.preSignedUrl ? response.data.preSignedUrl : null;
      extractData({
        preSignedUrl,
        file,
        fileMd5Base64,
        setLoader,
        documentId,
        format,
        typee,
        commonToken,
        successCallback,
        failurecallback,
        setApiErr,
      });
    }
  } else {
    setLoader(false);
  }
};

const extractData = async ({
  preSignedUrl,
  file,
  fileMd5Base64,
  setLoader,
  documentId,
  format,
  typee,
  commonToken,
  successCallback,
  failurecallback,
  setApiErr,
}) => {
  if (preSignedUrl && documentId) {
    let resp = await uploadDocumentByPreSignedURL(
      preSignedUrl,
      file,
      fileMd5Base64,
      setLoader,
      setApiErr
    );
    if (resp && resp.status === 200 && typee !== 'Others') {
      const req = {
        documentId: documentId,
        format,
        billType: typee,
      };
      resp = await extractAddress(req, setLoader, commonToken, setApiErr);
      if (resp?.status === 200) {
        successCallback(resp?.data);
      } else {
        failurecallback(resp);
      }
    } else if (resp && resp.status === 200 && typee === 'Others') {
      successCallback({ documentId: documentId });
    }
  }
};
export const ocrFileUpload = async ({
  file,
  commonToken,
  typee,
  successCallback,
  failurecallback,
  setLoader,
  applicationId,
  setApiErr,
}) => {
  if (file && commonToken && typee) {
    const formData = new FormData();
    formData.append('file', file);

    const { name, type, size } = file;
    let format = imgExtension(type);

    if (size > 3145728) {
      const fileMD5 = await generateFileMD5(file);
      const idType = 'Home Adress Proof';
      if (fileMD5) {
        setLoader(true);
        await documentResponse({
          fileMD5,
          format,
          name,
          idType,
          size,
          commonToken,
          setLoader,
          file,
          successCallback,
          failurecallback,
          typee,
          applicationId,
          setApiErr,
        });
      } else {
        setLoader(false);
      }
    } else {
      try {
        setLoader(true);
        let response = await axios.post(UrlHelpers.commonComponentsOcr, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${commonToken}`,
          },
          params: {
            format,
            name,
            type: 'Home Adress Proof',
            source: 'Auto',
            eventType: 'UI',
            billType: typee,
            applicationId,
          },
        });
        response = response?.data;

        successCallback(response);
      } catch (e) {
        failurecallback(e);
      } finally {
        setLoader(false);
      }
    }
  }
};

export const handleTokenExpiry = async (e, setCommonToken) => {
  if (e.response?.status === 401) {
    const token = await getAccessToken();
    setCommonToken(token);
  }
};
export const emailValidation = async ({
  email,
  commonToken,
  setError,
  setLoader,
  failurecallback,
}) => {
  try {
    setLoader(true);
    let response = await axios.post(
      UrlHelpers.commonComponentsEmailVerification,
      [
        {
          email,
          eventType: 'UI',
          source: 'auto',
        },
      ],
      {
        headers: { Authorization: `Bearer ${commonToken}`, 'Content-Type': 'application/json' },
      }
    );
    response = response?.data;
    const { status, message } = response[0];
    let isValid = true;
    if (status !== 'Valid' && message === '* Email Domain Not Found') {
      isValid = false;

      setError(`email`, { type: 'custom', message: '* Invalid email domain' });
    } else if (status !== 'Valid') {
      isValid = false;

      setError(`email`, { type: 'custom', message: '* Invalid email' });
    }
    return isValid;
  } catch (e) {
    failurecallback(e);
  } finally {
    setLoader(false);
  }
};

export const phoneValidation = async ({
  mobile1,
  mobile2,
  commonToken,
  setError,
  setLoader,

  failurecallback,
  clearErrors,
}) => {
  const phoneNumber = mobile1 ? mobile1 : mobile2;
  const payload = [
    {
      phoneNumber,
      eventType: 'UI',
      source: 'auto',
    },
  ];

  try {
    setLoader(true);
    let response = await axios.post(UrlHelpers.commonComponentsPhoneVerification, payload, {
      headers: { Authorization: `Bearer ${commonToken}`, 'Content-Type': 'application/json' },
    });

    let mobileStatus = response?.data[0]?.status;
    let isValid = true;
    if (mobile1) {
      if (mobileStatus !== 'Valid') {
        isValid = false;
        setError(`mobile`, { type: 'custom', message: '* Invalid phone number' });
      } else {
        clearErrors(`mobile`);
      }
    } else if (mobile2) {
      if (mobileStatus !== 'Valid') {
        isValid = false;
        setError(`mobile2`, { type: 'custom', message: '* Invalid phone number' });
      } else {
        clearErrors(`mobile2`);
      }
    }
    return isValid;
  } catch (e) {
    failurecallback(e);
  } finally {
    setLoader(false);
  }
};

export const getAppAccessToken = async () => {
  try {
    const tokenResponse = await axios.post(
      process.env.REACT_APP_ACCESS_TOKEN_URL,
      {
        client_id: process.env.REACT_APP_CLIENT_ID,
        grant_type: 'client_credentials',
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
      },
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    );

    const { access_token } = tokenResponse?.data;
    return access_token;
  } catch (e) {
    return '';
  }
};
