/* eslint-disable react/prop-types */
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import moment from 'moment';

export const OffersListView = ({ offersList, setShowPromotion }) => {
  const TableHeading = [
    'Promotion title',
    'Promotion description',
    'Effective date',
    'Expiration date',
    'Grace period',
    'Promotion associated to dealers',
  ];
  const onOpen = (eachOffer) => {
    setShowPromotion(eachOffer);
  };
  return (
    <Table aria-label="applications-table" sx={{ border: '1px solid #F2F2F2' }}>
      <TableHead>
        <TableRow sx={{ backgroundColor: '#F2F2F2' }}>
          {TableHeading?.map((x) => {
            return (
              <TableCell
                key={x?.promotionId}
                sx={{
                  fontWeight: 700,
                  fontSize: '14px !important',
                }}
              >
                {x}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>

      <TableBody>
        {offersList?.length ? (
          offersList.map((eachOffer) => {
            const {
              promotionId,
              promotionTitle,
              promotionDescription,
              effectiveDate,
              expirationDate,
              gracePeriod,
              associatedDealers,
            } = eachOffer;
            console.log('associated dealers', associatedDealers);
            const commaSeperatedList = Object.values(JSON.parse(associatedDealers || '{}')).join(
              ','
            );
            return (
              <TableRow
                key={promotionId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => onOpen(eachOffer)}
                data-testid={`offer_${promotionId}`}
              >
                <TableCell sx={{ fontSize: '14px !important' }}>{promotionTitle}</TableCell>
                <TableCell sx={{ fontSize: '14px !important' }}>{promotionDescription}</TableCell>
                <TableCell sx={{ fontSize: '14px !important' }}>
                  {moment(effectiveDate).format('MM/DD/yyyy')}
                </TableCell>
                <TableCell sx={{ fontSize: '14px !important' }}>
                  {moment(expirationDate).format('MM/DD/yyyy')}
                </TableCell>

                <TableCell sx={{ fontSize: '14px !important' }}>{gracePeriod}</TableCell>
                <TableCell sx={{ fontSize: '14px !important' }}>{commaSeperatedList}</TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={12} sx={{ fontSize: '14px !important', textAlign: 'center' }}>
              No record/s found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
