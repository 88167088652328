/* eslint-disable react/prop-types */
import { yupResolver } from '@hookform/resolvers/yup';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import ApiClass from 'Api/ApiClient';
import DescendingSortIcon from 'assets/svg/DescendingSorting.svg';
import GlassesIcon from 'assets/svg/OperaGlasses.svg';
import SearchIcon from 'assets/svg/SearchIcon.svg';
import SettingsIcon from 'assets/svg/Settings.svg';
import AuthContext from 'context/AuthContext';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import manageClasses from 'styles/ManageDealers.module.scss';
import dashboardClasses from 'styles/applicationDashboard.module.scss';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import * as yup from 'yup';

import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomMultiSelect from 'components/CustomMultiSelect/CustomMultiSelect';

export const OffersFilters = ({
  offers,
  page,
  rowsPerPage,
  view,
  setView,
  promotionsFlag,
  setOffers,
}) => {
  let { rolebasedDealers } = useContext(AuthContext);
  const [selectedDealer, setSelectedDealer] = useState([]);

  const isAllSelected =
    rolebasedDealers?.length > 0 && selectedDealer?.length === rolebasedDealers?.length;
  useEffect(() => {
    setSelectedDealer(rolebasedDealers?.length ? rolebasedDealers.map((x) => x.dealerId) : []);
  }, [rolebasedDealers]);
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      effectiveDate: null,
      expirationDate: null,
      gracePeriod: '',
      dealers: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        gracePeriod: yup
          .string()
          .nullable()
          .test('', 'Invalid Format', (val) => {
            return val?.length ? /^\d+$/g.test(val) : true;
          }),
      })
    ),
  });

  const ApiClient = new ApiClass();
  const [searchText, setSearchText] = useState('');
  const [showClearBtn, setShowClearBtn] = useState('');
  const watchEffectiveDate = watch('effectiveDate');
  const watchExpirationDate = watch('expirationDate');
  const watchGracePeriod = watch('gracePeriod');

  const handleSearchtext = (e) => {
    setSearchText(e.target.value);
  };
  const onClickSearchIcon = async () => {
    const dealerList = rolebasedDealers.map((x) => x.dealerId);
    if (searchText) {
      let url = `${UrlHelpers.filterPromotions}?dealerIds=${dealerList}&searchText=${searchText}`;
      try {
        const resp = await ApiClient.get(`${url}`);
        setOffers(resp);
      } catch (e) {
        console.log('e', e);
      }
    } else {
      const resp = await ApiClient.get(`${UrlHelpers.filterPromotions}?dealerIds=${dealerList}`);
      setOffers(resp);
    }
  };

  const handleMultiSelctChange = (event) => {
    const value = event?.target?.value;
    if (value[value.length - 1] === 'all') {
      setSelectedDealer(
        selectedDealer?.length === rolebasedDealers?.length
          ? []
          : rolebasedDealers.map((x) => x.dealerId)
      );

      return;
    }
    let newValues = [];
    if (value?.length > 1 && value.includes('Select')) {
      newValues = value.filter((x) => x !== 'Select');
      setSelectedDealer(newValues);
    } else {
      setSelectedDealer(value);
    }
  };
  useEffect(() => {
    if (
      watchEffectiveDate ||
      watchExpirationDate ||
      watchGracePeriod ||
      selectedDealer.length !== rolebasedDealers.length
    ) {
      setShowClearBtn(true);
    } else {
      setShowClearBtn(false);
    }
  }, [watchEffectiveDate, watchExpirationDate, watchGracePeriod, selectedDealer]);

  const handleSearch = async () => {
    console.log('selectedDealer', selectedDealer);
    const searchDealers = selectedDealer?.length
      ? selectedDealer
      : rolebasedDealers.map((x) => x.dealerId);
    setSearchText('');
    let url = `${UrlHelpers.filterPromotions}?dealerIds=${searchDealers}${
      watchGracePeriod ? `&gracePeriod=${watchGracePeriod}` : ''
    }${
      watchEffectiveDate ? `&effectiveDate=${moment(watchEffectiveDate).format('MM/DD/yyyy')}` : ''
    }${
      watchExpirationDate
        ? `&expirationDate=${moment(watchExpirationDate).format('MM/DD/yyyy')}`
        : ''
    }`;

    try {
      const resp = await ApiClient.get(`${url}`);
      setOffers(resp);
    } catch (e) {
      console.log('e', e);
      setOffers([]);
    }
  };
  const clearSearch = async () => {
    reset();
    const dealerList = rolebasedDealers?.length ? rolebasedDealers.map((x) => x.dealerId) : [];
    setSelectedDealer(dealerList);
    const resp = await ApiClient.get(`${UrlHelpers.filterPromotions}?dealerIds=${dealerList}`);
    setOffers(resp);
  };

  const indexOfLastPost = page * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.childBorderLine}
      mt={1}
      sx={{
        width: '100%',
        border: '1px solid rgb(224 224 224)',
        borderRadius: '2px',
      }}
    >
      <Box className={dashboardClasses.filterOptions}>
        <Typography variant="subtitle2">
          {offers?.length > 0 &&
            `Showing ${indexOfFirstPost + 1} to ${
              offers?.length > indexOfLastPost ? indexOfLastPost : offers?.length
            } of ${offers?.length} entries`}
        </Typography>
        <Box sx={{ display: 'flex', gap: '10px' }} alignItems="center">
          <Box sx={{ display: 'flex', gap: '5px' }}>
            <OutlinedInput
              placeholder="Search"
              name="search"
              style={{ padding: '0 0 0 0' }}
              className={manageClasses.inputStyle}
              onChange={handleSearchtext}
              data-testid="search"
              value={searchText}
              endAdornment={
                <InputAdornment position="end" tabIndex="0">
                  <IconButton onClick={onClickSearchIcon} tabIndex="0" data-testid="show-hide">
                    <img src={SearchIcon} alt="Search" data-testid="searchIcon" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
          <Box sx={{ display: 'flex', gap: '5px' }}>
            <img src={SettingsIcon} />
            <Typography variant="subtitle2">Settings</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '5px' }}>
            <img src={DescendingSortIcon} />
            <Typography variant="subtitle2">Sort</Typography>
          </Box>

          {!promotionsFlag && (
            <Box sx={{ display: 'flex', gap: '5px' }} tabIndex="0">
              <img src={GlassesIcon} />
              <Typography
                variant="subtitle2"
                style={{ cursor: 'pointer' }}
                data-testid="advancedSearch"
                onClick={() => {
                  if (view === 'Grid') {
                    setView('List');
                  } else {
                    setView('Grid');
                  }
                }}
              >
                {view === 'Grid' ? 'List View' : 'Grid view'}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        display="flex"
        data-testid="offers-table"
        alignItems={errors.gracePeriod ? 'flex-start' : 'center'}
        mt={errors.gracePeriod ? '20px' : 0}
        gap={2}
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <FilterListIcon />
        <CustomDatePicker
          name="effectiveDate"
          label="Effective date"
          control={control}
          error={!!errors?.effectiveDate}
          errorText={errors?.effectiveDate?.message}
          placeHolder="Effective date"
          labelOff={true}
        />
        <CustomDatePicker
          name="expirationDate"
          label="Expiration date"
          control={control}
          error={!!errors?.expirationDate}
          errorText={errors?.expirationDate?.message}
          minDate={control.effectiveDate}
          placeHolder="Expiration date"
          labelOff={true}
        />
        <CustomInputBox
          control={control}
          errors={!!errors?.gracePeriod}
          errorText={errors?.gracePeriod?.message}
          placeholder="Grace period"
          label="Grace period"
          name="gracePeriod"
          flexDirection="column"
          labelOff={true}
          width={{ sm: '20%' }}
          maxLength={'2'}
          regex={/^\d+$/}
        />
        <Grid width={{ sm: '20%' }}>
          <CustomMultiSelect
            selected={selectedDealer}
            handleMultiSelctChange={handleMultiSelctChange}
            isAllSelected={isAllSelected}
            options={rolebasedDealers.map((x) => ({
              displayName: x.dealerName,
              value: x.dealerId,
            }))}
            label="Dealers"
            name="dealers"
          />
        </Grid>
        <Button
          sx={{ width: '90px', height: '30px' }}
          color="secondary"
          variant="contained"
          size="small"
          data-testid="apply"
          onClick={handleSubmit(handleSearch)}
        >
          Search
        </Button>
        {showClearBtn && (
          <Typography
            variant="subtitle2"
            style={{ cursor: 'pointer', display: 'flex' }}
            onClick={clearSearch}
            px={1}
            gap="2px"
            data-testid="clear"
            color="#000"
          >
            <Typography variant="subtitle2">✖</Typography>
            <Typography variant="subtitle2">Clear</Typography>
          </Typography>
        )}
      </Box>
    </Box>
  );
};
