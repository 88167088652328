import { Box, Grid, Typography } from '@mui/material';
import { payCalcOnLoadApi } from 'Services/PaymentCalcApi';
import AuthContext from 'context/AuthContext';
import moment from 'moment';
import EstimatedMonthlyPayment from 'pages/CreateApplication/FinancialTerms/EstimatedMonthlyPayment.js/EstimatedMonthlyPayment';
import PaymentCalculatorDetails from 'pages/CreateApplication/FinancialTerms/PaymentCalculatorDetails/PaymentCalculatorDetails';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import classes from 'styles/globalStyle.module.scss';
import { isApproved, numberToCurrency } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';
import ErrorAlert from 'components/ErrorAlert';

const DecisionDetailsTab = ({ appDetails, payCalcDetail }) => {
  console.log({ appDetails, payCalcDetail });
  const decision = JSON.parse(appDetails?.decisions || '{}')?.[0];
  const approvalTerms = JSON.parse(appDetails?.approvalTerms || '{}');
  const otherInformation = JSON.parse(appDetails?.otherInformation || '{}');
  const isLoanApproved = isApproved(decision?.loanStatus);
  const { rolebasedDealers, dealer, createAppFlag } = useContext(AuthContext);
  const [apiErr, setApiErr] = useState('');
  const [promotionsData, setPromotionsData] = useState([]);
  const isApplicationApproved = isApproved(appDetails?.loanStatus);

  const valuewithPercentage = (value) => {
    return value ? `${value}%` : '--';
  };
  useEffect(async () => {
    if (payCalcDetail?.formData?.promotion) {
      const onLoadData = await payCalcOnLoadApi({
        rolebasedDealers,
        dealer,
        createAppFlag,
        promotionId: payCalcDetail?.formData?.promotion,
      });
      setPromotionsData(onLoadData);
    }
  }, [payCalcDetail?.formData?.promotion]);

  const approvaltermDetails = () => {
    return (
      <>
        <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
        <Grid container>
          <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
            <Typography variant="subtitle2">Amount</Typography>
          </Grid>
          <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
            <Typography variant="subtitle2">
              {isLoanApproved ? numberToCurrency(approvalTerms?.amount) : '-'}
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
            <Typography variant="subtitle2">Cust rate</Typography>
          </Grid>
          <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
            <Typography variant="subtitle2">
              {isLoanApproved && approvalTerms?.custRate ? `${approvalTerms?.custRate}%` : '-'}
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
            <Typography variant="subtitle2">Term/payment</Typography>
          </Grid>
          <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
            <Typography variant="subtitle2">
              {isLoanApproved && approvalTerms?.term ? +approvalTerms?.term : '-'}
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
            <Typography variant="subtitle2">Repay method</Typography>
          </Grid>
          <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
            <Typography variant="subtitle2">
              {isLoanApproved ? approvalTerms?.repayMethod : '-'}
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} xs={6} p={2}>
            <Typography variant="subtitle2">Initial payment</Typography>
          </Grid>
          <Grid item lg={6} md={6} xs={6} p={2}>
            <Typography variant="subtitle2">
              {isLoanApproved ? numberToCurrency(approvalTerms?.payment) : '-'}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} xs={6}>
          <Box className={classes.tableOutline} sx={{ marginTop: '20px' }}>
            <Box
              display="flex"
              flexDirection="column"
              sx={{ backgroundColor: '#F2F2F2', borderBottom: '1px solid #DADADA' }}
              p={2}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
                Latest decision
              </Typography>
            </Box>
            <Grid container>
              <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography variant="subtitle2">Status/user</Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography variant="subtitle2">
                  {decision?.displayStatus}/{decision?.analyst?.id}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={6} p={2}>
                <Typography variant="subtitle2">Date & time</Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={6} p={2}>
                <Typography variant="subtitle2">
                  {moment(decision?.receivedDateTime?.replace('Z', '')).format(
                    'MM/DD/yyyy, h:mm A'
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.tableOutline} sx={{ marginTop: '20px' }}>
            <Box
              display="flex"
              flexDirection="column"
              sx={{ backgroundColor: '#F2F2F2', borderBottom: '1px solid #DADADA' }}
              p={2}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
                Approval terms
              </Typography>
            </Box>
            {approvaltermDetails()}
          </Box>
          {isApplicationApproved && (
            <>
              <EstimatedMonthlyPayment calculatedData={payCalcDetail} approvedApplication />
              <CustomCollapse heading="Payment Calculator Details">
                <PaymentCalculatorDetails
                  calculatedData={payCalcDetail}
                  promotionsData={promotionsData}
                  approvedApplication
                />
              </CustomCollapse>
            </>
          )}
          <Box className={classes.tableOutline} sx={{ marginTop: '20px' }}>
            <Box
              display="flex"
              flexDirection="column"
              sx={{ backgroundColor: '#F2F2F2', borderBottom: '1px solid #DADADA' }}
              p={2}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
                Other information
              </Typography>
            </Box>
            <Grid container>
              <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography variant="subtitle2">Grade</Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography variant="subtitle2">{otherInformation?.grade}</Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography variant="subtitle2">Bureau score</Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography variant="subtitle2">{otherInformation?.bureauScore || '--'}</Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography variant="subtitle2">PTI</Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography variant="subtitle2">
                  {valuewithPercentage(otherInformation?.pti)}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography variant="subtitle2">DTI</Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography variant="subtitle2">
                  {valuewithPercentage(otherInformation?.dti)}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography variant="subtitle2">LTV</Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography variant="subtitle2">
                  {valuewithPercentage(otherInformation?.ltv)}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={6} p={2}>
                <Typography variant="subtitle2">ODI</Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={6} p={2}>
                <Typography variant="subtitle2">
                  {valuewithPercentage(otherInformation?.odi)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} xs={6}>
          <Box sx={{ marginTop: '20px' }}>
            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              Application turndown reasons
            </Typography>
            <Box
              className={classes.tableOutline}
              sx={{ marginTop: '10px', height: '170px', borderRadius: '5px' }}
            >
              <Box p={2}>
                <Typography variant="subtitle2">
                  {appDetails?.applicationTurnDownReason && decision?.reasons ? (
                    decision?.reasons?.map((item) => {
                      return (
                        <Typography variant="subtitle2" key={item?.text}>
                          {item?.type === 'Turndown' ? item?.text : ''}
                        </Typography>
                      );
                    })
                  ) : (
                    <Typography variant="subtitle2">--</Typography>
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ marginTop: '20px' }}>
            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              Stipulations
            </Typography>
            <Box
              className={classes.tableOutline}
              sx={{ marginTop: '10px', height: '170px', borderRadius: '5px' }}
            >
              <Box p={2}>
                <Typography variant="subtitle2">
                  {isApproved(appDetails?.loanStatus) && decision?.stipulations ? (
                    decision?.stipulations?.map((item) => {
                      return (
                        <Typography variant="subtitle2" key={item?.text}>
                          {item?.text}
                        </Typography>
                      );
                    })
                  ) : (
                    <Typography variant="subtitle2">--</Typography>
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ marginTop: '20px' }}>
            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              Override reasons
            </Typography>
            <Box
              className={classes.tableOutline}
              sx={{ marginTop: '10px', height: '170px', borderRadius: '5px' }}
            >
              <Box p={2}>
                <Typography variant="subtitle2">
                  {appDetails?.loanStatus !== 'DECLINE' &&
                  appDetails?.loanStatus !== 'DECSCOR' &&
                  decision?.reasons ? (
                    decision?.reasons?.map((item) => {
                      return (
                        <Typography variant="subtitle2" key={item?.text}>
                          {item?.type === 'Override' ? item?.text : ''}
                        </Typography>
                      );
                    })
                  ) : (
                    <Typography variant="subtitle2">--</Typography>
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ marginTop: '20px' }}>
            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              Decision comments
            </Typography>
            <Box
              className={classes.tableOutline}
              sx={{ marginTop: '10px', height: '170px', borderRadius: '5px' }}
            >
              <Box p={2}>
                <Typography variant="subtitle2">{decision?.comment || '--'}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DecisionDetailsTab;

DecisionDetailsTab.propTypes = {
  appDetails: PropTypes.instanceOf(Object),
  payCalcDetail: PropTypes.instanceOf(Object),
};
DecisionDetailsTab.defaultProps = {
  appDetails: {},
  payCalcDetail: {},
};
