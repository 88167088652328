import { Grid } from '@mui/material';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { defaultFn } from 'utils/utility';

import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import CustomNumberFormat from 'components/CustomNumberFormat/CustomNumberFormat';
import CustomRadioButton from 'components/CustomRadioButton/CustomRadioButton';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

import constants from '../../utils/constants';
import { VehicleClass } from '../../utils/data';

const { program, term, contractDate, promotion, balloonPayment, rate } = constants;

const BasicVehicleDetail = ({ control, errors, optionsData, setValue, trigger, flow }) => {
  const { newUsedList, programList, termList, createAppFlag } = useContext(AuthContext);
  const { promotions } = optionsData;

  const promotionOptions = promotions?.map((x) => {
    const { promotionId, promotionLabel } = x;
    return { name: promotionLabel, value: promotionId };
  });

  const watchProgram = useWatch({
    control,
    name: 'programVal',
  });
  const watchPromotion = useWatch({
    control,
    name: 'promotion',
  });

  useEffect(() => {
    console.log('two-flow-BVD', flow);
    if (watchPromotion !== promotions[0]?.description && !flow) {
      console.log('three-flow', flow);
      setValue('rate', '');
    } else if (watchPromotion === promotions[0]?.description) {
      setValue('rate', promotions[0]?.rate?.toFixed(2));
      trigger('rate');
    }
    console.log('errors', errors);
  }, [watchPromotion]);

  const handleMinDate = () => {
    if (createAppFlag) {
      return new Date();
    } else {
      return new Date(new Date().setFullYear(new Date().getFullYear() - 5));
    }
  };
  return (
    <div>
      <Grid container my={0.5} rowSpacing={1} columnSpacing={4}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomSelectBox
            flexDirection="column"
            label={program}
            control={control}
            name="programVal"
            errors={!!errors?.programVal}
            errorText={errors?.programVal?.message}
            options={programList}
            defaultValue=""
            required
            displayEmpty
            isDisabled={createAppFlag}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomSelectBox
            flexDirection="column"
            label={promotion}
            control={control}
            name="promotion"
            errors={!!errors.promotion}
            errorText={errors?.promotion?.message}
            options={promotionOptions}
            defaultValue=""
            displayEmpty
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomRadioButton
            control={control}
            radioValues={newUsedList}
            label="New/used"
            required
            name="newOrUsed"
            dataTestId="newOrUsed"
            errors={!!errors?.newOrUsed}
            errorText={errors?.newOrUsed?.message}
            isdisabled={createAppFlag}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomRadioButton
            control={control}
            radioValues={VehicleClass}
            label="Vehicle class"
            required
            name="vehicleClass"
            errors={!!errors?.vehicleClass}
            errorText={errors?.vehicleClass?.message}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} display="flex">
          <Grid container columnSpacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label={term}
                control={control}
                name="term"
                errors={!!errors.term}
                errorText={errors?.term?.message}
                options={termList || []}
                defaultValue=""
                required
                displayEmpty
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              {/* <CustomSelectBox
                flexDirection="column"
                label={rate}
                control={control}
                name="rate"
                errors={!!errors.rate}
                errorText={errors?.rate?.message}
                options={rateOptions}
                defaultValue={
                  watchPromotion === promotions[0]?.description
                    ? `${promotions[0]?.rate.toFixed(2)} `
                    : ''
                }
                required
                displayEmpty
              /> */}
              <CustomNumberFormat
                name="rate"
                id="rate"
                dataTestId="rate"
                label={rate}
                control={control}
                error={!!errors?.rate}
                errorText={errors?.rate?.message}
                suffix={'%'}
                decimalScale={2}
                maxValue={100.0}
                setValue={setValue}
                required
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomDatePicker
            name="contractDate"
            label={contractDate}
            control={control}
            error={!!errors?.contractDate}
            errorText={errors?.contractDate?.message}
            minDate={handleMinDate()}
            maxDate={new Date(new Date().setMonth(new Date().getMonth() + 1))}
            required
            placeHolder="MM/DD/YYYY"
          />
        </Grid>

        {watchProgram === 'Oriental A+' && (
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="balloonPay"
              id="balloonPay"
              dataTestId="balloonPay"
              decimalScale={2}
              label={balloonPayment}
              control={control}
              error={!!errors?.balloonPay}
              errorText={errors?.balloonPay?.message}
              thousandSeparator={true}
              prefix={'$ '}
              required={watchProgram === 'Oriental A+'}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default BasicVehicleDetail;

BasicVehicleDetail.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  setValue: PropTypes.func,
  trigger: PropTypes.func,
  optionsData: PropTypes.instanceOf(Object),
  flow: PropTypes.string,
};
BasicVehicleDetail.defaultProps = {
  control: {},
  errors: {},
  optionsData: {},
  setValue: defaultFn,
  trigger: defaultFn,
  flow: '',
};
