import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import classes from 'styles/globalStyle.module.scss';

import Loader from 'components/Loader/Loader';

import ProfileAccountDetails from './ProfileAccountDetails/ProfileAccountDetails';
import { ProfilePersonalInformation } from './ProfilePersonalInformation/ProfilePersonalInformation';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function ProfileView() {
  const [value, setValue] = React.useState(0);
  const [loader, setLoader] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.profileView} sx={{ padding: '10px' }}>
      <Loader open={loader} />
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderColor: 'divider',
          width: '25%',
          '.MuiTabs-indicator': {
            right: 'unset',
          },
          '.MuiTab-root.Mui-selected': {
            borderRight: 'none',
            background: 'none',
          },
          '.MuiTab-root': {
            background:
              'var(--borders-and-background-gradient, linear-gradient(180deg, #FFF 0%, #F3F3F3 100%))',
            border: '1px solid #DADADA',
            borderColor: 'rgba(0, 0, 0, 0.12)',
          },
          '.MuiButtonBase-root ': {
            maxWidth: 'unset',
            width: '100%',
            textAlign: 'left',
          },
        }}
      >
        <Tab label="Personal information" data-testid="personal-det" {...a11yProps(0)} />
        <Tab label=" Account details" data-testid="account-det" {...a11yProps(1)} />
      </Tabs>
      <TabPanel
        value={value}
        index={0}
        sx={{ border: '1px solid #DADADA', borderColor: 'rgba(0, 0, 0, 0.12)', width: '75%' }}
      >
        <ProfilePersonalInformation setLoader={setLoader} />
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        sx={{ border: '1px solid #DADADA', borderColor: 'rgba(0, 0, 0, 0.12)', width: '75%' }}
      >
        <ProfileAccountDetails />
      </TabPanel>
    </Paper>
  );
}
