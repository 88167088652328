import { Grid } from '@mui/material';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { cityMapping, defaultFn } from 'utils/utility';

import { CustomAutoComplete } from 'components/CustomAutoComplete/CustomAutoComplete';
import CustomCollapse from 'components/CustomCollapse';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

const MailingAddress = ({
  control,
  errors,
  cityList,
  addressLookupData,
  addressLookupDataResponse,
  setValue,
  clearErrors,
  prefixName,
  couserIndex,
  setError,
  setPerCityList,
  setOldState,
}) => {
  const [watchPerAddress1, watchIsAddressSame, watchPerState] = useWatch({
    control,
    name: [`${prefixName}.perAddress1`, `${prefixName}.isAddrSame`, `${prefixName}.perState`],
  });

  const handleStateAddress = async (AddressLine1, State) => {
    setValue(`${prefixName}.perAddress1`, AddressLine1);
    setValue(`${prefixName}.perState`, State);
    setOldState(State);
  };
  const { cityZipList, stateList, stateCityList } = useContext(AuthContext);
  useEffect(async () => {
    let cityListt = [];
    console.log({ unikk: stateCityList });
    if (stateCityList?.length > 0) {
      cityListt = await cityMapping(stateCityList, watchPerState);
    }

    setPerCityList(cityListt || []);
  }, [watchPerState, stateCityList]);

  const handleStateChange = () => {
    setValue(`${prefixName}.perCity`, '');
    setValue(`${prefixName}.perZipcode`, '');
  };

  const handleAutoCompleteData = async (selectedObj) => {
    if (selectedObj && selectedObj?.Address) {
      let { AddressLine1, PostalCode, City, State } = selectedObj?.Address;
      await handleStateAddress(AddressLine1, State);
      const cityListt = await cityMapping(stateCityList, State);

      PostalCode = PostalCode?.split('-')?.[0];
      setValue(`${prefixName}.perZipcode`, PostalCode);
      if (State !== 'PR') {
        setValue(`${prefixName}.perCity`, City);
      } else {
        City = cityZipList.find((x) => x?.zipcode === PostalCode)?.city;
        City = cityListt.find((x) => x.value === City)?.value || '';

        if (!City) {
          setError(`${prefixName}.perCity`, {
            type: 'custom',
            message: 'Please select a city from the list and enter the zip code',
          });
        } else {
          setValue(`${prefixName}.perCity`, City);
          clearErrors([
            `${prefixName}.perAddress1`,
            `${prefixName}.perAddress2`,
            `${prefixName}.perZipcode`,
            `${prefixName}.perCity`,
            `${prefixName}.perState`,
          ]);
        }
      }
    } else {
      setValue(`${prefixName}.perAddress1`, '');
      setValue(`${prefixName}.perAddress2`, '');
      setValue(`${prefixName}.perZipcode`, '');
      setValue(`${prefixName}.perCity`, '');
      setValue(`${prefixName}.perState`, '');
    }
  };

  return (
    <>
      <CustomCollapse heading="Mailing address" open={!watchIsAddressSame} errors={errors}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomAutoComplete
              id="perAddress1"
              name={`${prefixName}.perAddress1`}
              flexDirection="column"
              control={control}
              regex={/^[a-z0-9A-ZáéíóúñüÁÉÍÓÚÑÜ¿¡,./-\s]*$/g}
              dataTestIdAutocomplete="autocomplete_address1"
              dataTestIdForLabel="perAddress1"
              dataTestIdForInput="addr1Input"
              required
              label="Address line 1"
              options={
                addressLookupData && watchPerAddress1 && watchPerAddress1?.length >= 6
                  ? addressLookupData
                  : []
              }
              handleChange={(_event, selectedValue) => {
                if (watchPerAddress1 && watchPerAddress1.length >= 6) {
                  const index = addressLookupData.indexOf(selectedValue);
                  const selectedObj = addressLookupDataResponse[index];
                  handleAutoCompleteData(selectedObj);
                }
              }}
              handleInputChange={(_event, newInputValue) => {
                console.log('new Input Value', newInputValue);
              }}
              // filterOptions={(options) => options}
              maxLength={40}
              autoFocus
              placeholder="Enter address line 1"
              fullWidth
              error={errors?.cousers?.[couserIndex]?.perAddress1}
              errorText={errors?.cousers?.[couserIndex]?.perAddress1?.message}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              name={`${prefixName}.perAddress2`}
              errors={!!errors?.cousers?.[couserIndex]?.perAddress2}
              errorText={errors?.cousers?.[couserIndex]?.perAddress2?.message}
              maxLength="20"
              regex={/^[A-Za-z0-9áéíóúñüÁÉÍÓÚÑÜ¿¡,./-\s]$/}
              placeholder="Enter address line 2"
              label="Address line 2"
              flexDirection="column"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Country"
              control={control}
              name={`${prefixName}.perCountry`}
              errors={!!errors?.cousers?.[couserIndex]?.perCountry}
              errorText={errors?.cousers?.[couserIndex]?.perCountry?.message}
              options={[
                {
                  name: 'United States',
                  value: 'USA',
                },
              ]}
              defaultValue="USA"
              required
              isDisabled
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="State"
              control={control}
              name={`${prefixName}.perState`}
              errors={!!errors?.cousers?.[couserIndex]?.perState}
              errorText={errors?.cousers?.[couserIndex]?.perState?.message}
              options={stateList}
              defaultValue="PR"
              required
              displayEmpty
              isValue
              valueChange={handleStateChange}
            />
          </Grid>

          {watchPerState === 'PR' && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="City"
                control={control}
                name={`${prefixName}.perCity`}
                errors={!!errors?.cousers?.[couserIndex]?.perCity}
                errorText={errors?.cousers?.[couserIndex]?.perCity?.message}
                options={cityList}
                defaultValue=""
                required
                displayEmpty
              />
            </Grid>
          )}
          {watchPerState !== 'PR' && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomInputBox
                control={control}
                errors={!!errors?.cousers?.[couserIndex]?.perCity}
                errorText={errors?.cousers?.[couserIndex]?.perCity?.message}
                placeholder="Enter city"
                label="City"
                name={`${prefixName}.perCity`}
                regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡\s]$/}
                maxLength="15"
                type="text"
                flexDirection="column"
                required
              />
            </Grid>
          )}
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              name={`${prefixName}.perZipcode`}
              errors={!!errors?.cousers?.[couserIndex]?.perZipcode}
              errorText={errors?.cousers?.[couserIndex]?.perZipcode?.message}
              placeholder="Enter zipcode"
              label="Zip code"
              regex={/^\d$/}
              maxLength="5"
              type="text"
              flexDirection="column"
              required
            />
          </Grid>
        </Grid>
      </CustomCollapse>
    </>
  );
};
export default MailingAddress;

MailingAddress.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  cityList: PropTypes.array,
  clearErrors: PropTypes.func,
  addressLookupData: PropTypes.instanceOf(Object),
  addressLookupDataResponse: [],
  setValue: PropTypes.func,
  setError: PropTypes.func,
  prefixName: PropTypes.string,
  couserIndex: PropTypes.number,
  isMounted: PropTypes.bool,
  setPerCityList: PropTypes.func,
  oldState: PropTypes.string,
  setOldState: PropTypes.func,
};
MailingAddress.defaultProps = {
  control: {},
  errors: {},
  cityList: [],
  addressLookupData: [],
  addressLookupDataResponse: [],
  setValue: defaultFn,
  setError: defaultFn,
  clearErrors: defaultFn,
  prefixName: '',
  couserIndex: 0,
  isMounted: false,
  setPerCityList: defaultFn,
  oldState: '',
  setOldState: defaultFn,
};
