import { Box, Button, Checkbox, Paper, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import GeneralInfo from 'pages/Lookup/components/GeneralInfo';
import PageHeading from 'pages/Lookup/components/PageHeading';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { numberToCurrency } from 'utils/utility';

import Loader from 'components/Loader/Loader';

import VehicleValueSummary from '../VehicleValueSummary/VehicleValueSummary';

const EquipmentAdjustment = ({
  data,
  productDetails,
  coApplicant,
  coSigner,
  reviewFlag,
  calculationState,
  completedSteps,
  viewAppFlow,
}) => {
  const ApiClient = new ApiClass();
  const {
    showSummary,
    setShowSummary,
    collateralInfo,
    lookupLinkClicked,
    setCreateAppLookup,
    setCreateAppFlag,
  } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);

  const [fields, setFields] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setSelectedRows([]);
  }, [data]);

  const handleCheckboxChange = (event, row) => {
    const selected = event.target.checked;
    if (selected) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter((r) => r.name !== row.name));
    }
  };

  const total = selectedRows?.reduce(
    (acc, eachObj) => {
      let totall = acc;
      totall.avg += eachObj?.avg;
      totall.resid12 += eachObj?.resid12;
      totall.resid24 += eachObj?.resid24;
      totall.resid48 += eachObj?.resid48;
      totall.resid60 += eachObj?.resid60;
      return totall;
    },
    { avg: 0, resid12: 0, resid24: 0, resid36: 0, resid48: 0, resid60: 0 }
  );

  const { year, make, model, series, style, state, mileage } = productDetails;

  const onNext = async () => {
    try {
      setLoader(true);
      const selectedCodes = selectedRows?.map((x) => x?.uoc).join();
      const resp = await ApiClient.post(
        UrlHelpers.lookUpDrillDownInfo,
        {
          year,
          make,
          model,
          style,
          series,
          state,
          mileage: mileage.toString(),
          adddeductcodes: selectedCodes,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      const dataa = resp?.used_vehicles?.used_vehicle_list[0];

      setShowSummary(true);
      setFields({
        data: dataa,
        productDetails,
        total,
      });
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  };
  return (
    <>
      <Paper sx={{ minHeight: '80vh', padding: '20px', marginBottom: '60px' }}>
        <PageHeading
          collateralType="Used"
          valueGuide="Black Book"
          showSummary={showSummary}
          setShowSummary={setShowSummary}
          setSelectedRows={setSelectedRows}
          setCreateAppLookup={() => {
            setCreateAppLookup('used');
            setCreateAppFlag(true);
          }}
        />
        <Box display="flex" className={classes.equipAdjustment}>
          {showSummary && (
            <VehicleValueSummary
              data={fields?.data}
              selectedRows={selectedRows}
              coApplicant={coApplicant}
              coSigner={coSigner}
              reviewFlag={reviewFlag}
              calculationState={calculationState}
              completedSteps={completedSteps}
              viewAppFlow={viewAppFlow}
            />
          )}
          {!showSummary && (
            <Box display="flex" flexDirection="column" gap={2} flexGrow="1">
              {loader && <Loader open={loader} />}
              <Typography sx={{ fontSize: '18px!important', fontWeight: '700' }}>
                Optional equipment
              </Typography>
              <Typography fontSize="14px!important">
                Select options for {year} {make} {model} {series} {style}
              </Typography>

              <Box
                display="flex"
                flexDirection="column"
                className={classes.tableOutline}
                role="table"
              >
                <Box display="flex" gap={2} sx={{ backgroundColor: '#F2F2F2' }} p={2} role="row">
                  <Typography
                    sx={{ flexGrow: 1, fontWeight: '700', marginLeft: '35px' }}
                    role="columnheader"
                  >
                    Description
                  </Typography>
                  <Typography
                    sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
                    role="columnheader"
                  >
                    Value (Avg)
                  </Typography>
                  <Typography
                    sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
                    role="columnheader"
                  >
                    12 Month
                  </Typography>
                  <Typography
                    sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
                    role="columnheader"
                  >
                    24 Month
                  </Typography>
                  <Typography
                    sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
                    role="columnheader"
                  >
                    36 Month
                  </Typography>
                  <Typography
                    sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
                    role="columnheader"
                  >
                    48 Month
                  </Typography>
                  <Typography
                    sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
                    role="columnheader"
                  >
                    60 Month
                  </Typography>
                </Box>

                <Box display="flex" flexDirection="column" className={classes.childBorderLine}>
                  {data?.map?.((x) => {
                    const { name } = x;
                    const partObj = (({ avg, resid12, resi24, resid36, resid48, resi60 }) => ({
                      avg,
                      resid12,
                      resi24,
                      resid36,
                      resid48,
                      resi60,
                    }))(x);
                    return (
                      <Box display="flex" gap={2} key={name} p={2} role="row">
                        <Checkbox
                          color="secondary"
                          checked={selectedRows.find((el) => el.name === name) || false}
                          onChange={(event) => handleCheckboxChange(event, x)}
                        />
                        <Typography sx={{ flexGrow: 1 }} role="cell">
                          {name}
                        </Typography>
                        {Object.keys(partObj).map((eachKey) => {
                          console.log('eachkey', eachKey);
                          return (
                            <Typography
                              key={eachKey}
                              role="cell"
                              sx={{ minWidth: '10%', maxWidth: '10%', overflowWrap: 'break-word' }}
                            >
                              {numberToCurrency(x[eachKey])}
                            </Typography>
                          );
                        })}
                      </Box>
                    );
                  })}
                  {data?.length === 0 && (
                    <Box p={2}>
                      <Typography textAlign="center" fontWeight="700">
                        No add deducts found for this vehicle
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Box display="flex" gap={2} sx={{ backgroundColor: '#F2F2F2' }} p={2} role="row">
                  <Typography
                    sx={{ flexGrow: 1, fontWeight: '700', marginLeft: '35px' }}
                    role="cell"
                  >
                    Total equipment adjustment
                  </Typography>
                  {Object.keys(total).map((eachKey) => (
                    <Typography
                      sx={{
                        minWidth: '10%',
                        maxWidth: '10%',
                        overflowWrap: 'break-word',
                        fontWeight: '700',
                      }}
                      key={eachKey}
                      role="cell"
                    >
                      {numberToCurrency(total[eachKey])}
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  onClick={onNext}
                  variant="contained"
                  color="secondary"
                  id="get-vehicle-value-btn"
                >
                  Get vehicle value
                </Button>
              </Box>
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="column"
            minWidth="25%"
            gap={2.5}
            mt={{ lg: showSummary ? '38px' : '70px' }}
            className={classes.tableOutline}
            maxHeight={showSummary ? '550px' : '600px'}
            backgroundColor="#f2f2f2"
            p={2.5}
          >
            <GeneralInfo
              productDetails={lookupLinkClicked ? collateralInfo?.productDetails : productDetails}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default EquipmentAdjustment;

EquipmentAdjustment.propTypes = {
  data: PropTypes.instanceOf(Object),
  productDetails: PropTypes.instanceOf(Object),
  coApplicant: PropTypes.instanceOf(Object),
  coSigner: PropTypes.instanceOf(Object),
  reviewFlag: PropTypes.bool,
  calculationState: PropTypes.string,
  completedSteps: PropTypes.array,
  viewAppFlow: PropTypes.bool,
};
EquipmentAdjustment.defaultProps = {
  data: {},
  productDetails: {},
  coApplicant: {},
  coSigner: [],
  reviewFlag: false,
  calculationState: '',
  completedSteps: [],
  viewAppFlow: false,
};
