import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import AuthContext from 'context/AuthContext';
import { Requirements } from 'pages/RegisterUser/ResetPassword/Requirements';
import { enrollTooltipStyles } from 'pages/RegisterUser/ResetPassword/Tooltipstyles';
import React, { useContext, useState } from 'react';
import { defaultFn, reqArray } from 'utils/utility';

import ChangePasswordModal from '../ChangePasswordModal/ChangePasswordModal';

const ProfileAccountDetails = () => {
  const useStyles = enrollTooltipStyles();
  const classes = useStyles();

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const { userID } = useContext(AuthContext);

  return (
    <>
      <Box>
        <Typography variant="h3" component="div">
          Account details
        </Typography>
      </Box>
      <ChangePasswordModal
        showChangePasswordModal={showChangePasswordModal}
        setShowChangePasswordModal={setShowChangePasswordModal}
      />
      <Grid container mx={3} justifyContent="center">
        <Grid item lg={5.75} md={5.75} sm={5.75} xs={12}>
          <form noValidate>
            {' '}
            <InputLabel
              htmlFor="username"
              aria-label="Username Required"
              sx={{ margin: '20px 0 10px 0' }}
            >
              User name
            </InputLabel>
            <OutlinedInput
              id="username"
              name="username"
              fullWidth="username"
              data-testid="username"
              inputProps={{ maxLength: 15 }}
              autoComplete="off"
              type="text"
              value={userID}
              disabled
            />
            <InputLabel
              htmlFor="currentPassword"
              aria-label="Current password Required"
              sx={{ margin: '20px 0 10px 0' }}
            >
              Current password
            </InputLabel>
            <Tooltip
              disableHoverListener
              placement={'bottom'}
              leaveTouchDelay={250}
              enterTouchDelay={50}
              leaveDelay={250}
              TransitionComponent={Zoom}
              arrow
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.arrow,
              }}
              title={
                <>
                  <Requirements
                    value={'XXXXXXXXX'}
                    requirements={reqArray}
                    onValidChange={defaultFn}
                  />
                  {
                    <>
                      <div>
                        <InfoIcon
                          style={{
                            width: '0.7em',
                            height: '0.7em',
                            color: '#f5821f',
                            marginRight: '5px',
                          }}
                        />
                        <span style={{ fontSize: '14px' }}>Note: Password is case sensitive</span>
                      </div>
                      <div>
                        <InfoIcon
                          style={{
                            width: '0.7em',
                            height: '0.7em',
                            color: '#f5821f',
                            marginRight: '5px',
                          }}
                        />
                        <span style={{ fontSize: '14px' }}>
                          Note: New password should not match last 10 passwords
                        </span>
                      </div>
                    </>
                  }
                </>
              }
            >
              <OutlinedInput
                id="currentPassword"
                name="currentPassword"
                fullWidth="currentPassword"
                inputProps={{ maxLength: 15, 'data-testid': 'currentPassword' }}
                autoComplete="off"
                type={'password'}
                value={'XXXXXXXXX'}
                disabled
              />
            </Tooltip>
            <Button
              variant="contained"
              color="secondary"
              sx={{ minWidth: 'auto', float: 'right', margin: '20px 0 10px 0' }}
              data-testid="editPassword"
              onClick={() => setShowChangePasswordModal(true)}
            >
              Change password
            </Button>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default ProfileAccountDetails;
