import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LockIcon from '@mui/icons-material/Lock';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { generateFileMD5 } from 'Services/commonComponentsApi';
import {
  uploadDocumentByPreSignedURL,
  uploadDocumentManually,
  uploadXlDocumentManually,
} from 'Services/uploadService';
import CameraIcon from 'assets/svg/cameraIcon.svg';
import { Buffer } from 'buffer';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useWatch } from 'react-hook-form';
import customClasses from 'styles/InformationRequest.module.scss';
import { defaultFn, imgExtension } from 'utils/utility';

import BootstrapDialogTitle, { BootstrapDialog } from 'components/CustomDialogBox/CustomDialogBox';
import CustomFileUploader from 'components/CustomFileUploader/CustomFileUploader';
import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

const FileUploadDialog = ({
  control,
  errors,
  setOpen,
  open,
  setError,
  clearErrors,
  setValue,
  setShowIDCamera,
  createAppApplicationId: applicationId,
  prefixName,
  couserIndex,
}) => {
  const [loader, setLoader] = useState(false);
  const [apiErr, setApiErr] = useState('');
  const fileTypes = ['JPG', 'PNG', 'PDF'];
  const [manualFile, applicantId] = useWatch({
    control,
    name: [`${prefixName}.manualFile`, `${prefixName}.applicantId`],
  });

  const handleChange = (filee) => {
    if (filee?.size > 10485760) {
      setError(`${prefixName}.manualFile`, {
        type: 'custom',
        message: 'The file exceeds the maximum upload size (10 MB). Click to upload again',
      });
    } else {
      clearErrors(`${prefixName}.manualFile`);
      setValue(`${prefixName}.manualFile`, filee);
    }
  };

  const handleClose = () => {
    setValue(`${prefixName}.manualFile`, '');
    setOpen(false);
  };

  const handleContinue = async () => {
    setLoader(true);
    const { name, type, size } = manualFile;

    let format = imgExtension(type);
    if (size > 3145728) {
      const fileMD5 = await generateFileMD5(manualFile);
      const fileMd5Base64 = Buffer.from(fileMD5, 'hex').toString('base64');
      const payload = {
        format,
        name,
        type: 'ID Proof',
        size,
        source: 'auto',
        eventType: 'UI',
        contentMD5: fileMd5Base64,
        applicantId,
        applicationId,
      };
      const resp = await uploadXlDocumentManually(
        payload,
        setLoader,
        setApiErr,
        setValue,
        `${prefixName}.identityDocId`
      );
      if (resp) {
        setLoader(true);
        const preSignedUrl = resp.preSignedUrl ? resp.preSignedUrl : null;
        await uploadDocumentByPreSignedURL(
          preSignedUrl,
          manualFile,
          fileMd5Base64,
          setLoader,
          setApiErr,
          setValue,
          `${prefixName}.identityDocId`
        );
        setValue(`${prefixName}.identityDocId`, resp?.documentId);
      }
    } else {
      const resp = await uploadDocumentManually(
        format,
        applicationId,
        applicantId,
        name,
        manualFile,
        setApiErr,
        'identity',
        setValue,
        `${prefixName}.identityDocId`
      );
      setValue(`${prefixName}.identityDocId`, resp?.documentId);

      setLoader(false);
    }

    setOpen(false);
  };
  return (
    <>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <Loader open={loader} />
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="h3" component="span">
            ID verification
          </Typography>
          <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
        </BootstrapDialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box display="flex" flexDirection="column" gap={5} px={5}>
            <Grid container sx={{ justifyContent: 'center' }}>
              <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
            </Grid>
            <Box display="flex" gap={0.5}>
              <LockIcon color="primary" />
              <Typography variant="subtitle2">
                There’s no need to worry, your documents are secure and will not be stored. Read
                more in review policy
              </Typography>
            </Box>
            <Box display="flex" gap={2}>
              <Box
                className={customClasses.fileuploadContainer}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  display="flex"
                  onClick={() => {
                    setShowIDCamera(true);
                    setOpen(false);
                  }}
                >
                  <img src={CameraIcon} /> &nbsp;
                  <Typography
                    variant="subtitle1"
                    component="p"
                    sx={{ fontWeight: '700' }}
                  ></Typography>
                  Camera
                </Button>
              </Box>
              <Box display="flex" flexDirection="column" width="50%">
                <CustomFileUploader
                  handleChange={handleChange}
                  control={control}
                  fileTypes={fileTypes}
                  setError={setError}
                  errors={!!errors?.cousers?.[couserIndex]?.manualFile}
                  errorText={errors?.cousers?.[couserIndex]?.manualFile?.message}
                  name={`${prefixName}.manualFile`}
                />
              </Box>
            </Box>
          </Box>
          {!errors?.cousers?.[couserIndex]?.manualFile?.message && manualFile && (
            <Box
              display="flex"
              gap={0.5}
              mt={2.5}
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <CheckCircleIcon htmlColor="green" />
              <Typography variant="" data-testid="filename">
                {manualFile?.name}
              </Typography>
            </Box>
          )}
          <Box display="flex" gap={0.5} justifyContent="center" alignItems="center" width="100%">
            <Typography component="p" color="red">
              {errors?.cousers?.[couserIndex]?.manualFile?.message}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            onClick={handleContinue}
            sx={{ maxHeight: '40px' }}
            disabled={errors?.cousers?.[couserIndex]?.manualFile?.message || !manualFile}
          >
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
FileUploadDialog.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
  setValue: PropTypes.func,
  file: PropTypes.string,
  setFile: PropTypes.func,
  setShowIDCamera: PropTypes.func,
  applicationId: PropTypes.string,
  userType: PropTypes.string,
  createAppApplicationId: PropTypes.string,
  prefixName: PropTypes.string,
  couserIndex: PropTypes.number,
};
FileUploadDialog.defaultProps = {
  control: {},
  errors: {},
  setOpen: defaultFn,
  open: false,
  setError: defaultFn,
  clearErrors: defaultFn,
  setValue: defaultFn,
  file: '',
  setFile: defaultFn,
  setShowIDCamera: defaultFn,
  applicationId: '',
  userType: '',
  createAppApplicationId: '',
  prefixName: '',
  couserIndex: 0,
};

export default FileUploadDialog;
