/* eslint-disable no-unused-vars */
import {
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ApiClass from 'Api/ApiClient';
import PreviewIcon from 'assets/svg/filePreview.svg';
import AuthContext from 'context/AuthContext';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { replaceNullValues } from 'utils/utility';

import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';
import PdfViewer from 'components/PdfViewer';

import PrintIcon from '../../assets/printIcon.svg';
import { TableCellTypography, TableHeaderTypography } from './ApplicationsTable';

export const PrintDocumentsModal = ({ applicationDetails }) => {
  const ApiClient = new ApiClass();
  const [loader, setLoader] = useState(false);
  const { generatedDoc } = useContext(AuthContext);
  const [printDocs, setPrintDocs] = useState([]);
  const [allDocs, setAllDocs] = useState([]);
  const [finalDocuments, setFinalDocuments] = useState([]);
  const [apiErr, setApiErr] = useState('');
  const [preview, setPreview] = useState(false);
  const [previewurl, setPreviewUrl] = useState('');
  const TableHeading = ['Document', 'Document Type', 'Upload Time', 'User', 'Action'];

  const [wholeDocs, setWholeDocs] = useState([]);

  useEffect(() => {
    setWholeDocs(generatedDoc);
    console.log({ generatedDoc });
  }, [generatedDoc]);
  useEffect(() => {
    console.log({ generatedDoc });
    let mergedArray = [...printDocs, ...generatedDoc];
    const docType = mergedArray?.map((doc) => parseInt(doc?.id));
    const uniqueDocTypes = [...new Set(docType)];
    const finalDoc = uniqueDocTypes?.map((id) => {
      return mergedArray?.find((x) => parseInt(x.id) === parseInt(id));
    });
    console.log({ mergedArray, docType, uniqueDocTypes, finalDoc });
    setFinalDocuments(finalDoc);
  }, [generatedDoc, printDocs, allDocs]);

  const getAllDocs = async () => {
    try {
      setLoader(true);
      let data = await ApiClient.get(`${UrlHelpers.dashboardDocument}`);
      if (JSON.parse(data?.['dashboard-document'])?.length) {
        let allDocsList = JSON.parse(data?.['dashboard-document']);
        setAllDocs(allDocsList);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(async () => {
    try {
      setLoader(true);
      const printDocsList = await ApiClient.get(
        `${UrlHelpers.getDocuments}?applicationId=${applicationDetails?.initAppId}&isAutoGenerateReq=true&docType=dashboard`,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      setApiErr('');
      setPrintDocs(printDocsList);
    } catch (error) {
      console.log('error', error);
      setApiErr(error?.response?.data);
    } finally {
      setLoader(false);
    }

    await getAllDocs();
  }, []);

  return (
    <Grid
      container
      sx={{
        overflowX: 'auto',
        width: '100%',
      }}
    >
      <Loader open={loader} />
      <Paper sx={{ width: '100%', boxShadow: 'none' }}>
        {preview && (
          <PdfViewer
            fileData={previewurl}
            handleClose={() => {
              setPreview(false);
            }}
            open={preview}
          />
        )}
        <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
        <Table aria-label="applications-table">
          <TableHead>
            <TableRow
              sx={{ backgroundColor: '#F2F2F2' }}
              className={classes.tableHeadingApplications}
            >
              {TableHeading?.map((x) => {
                return (
                  <TableCell key={x}>
                    <TableHeaderTypography>{x}</TableHeaderTypography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {finalDocuments?.map((eachDoc) => {
              console.log({ eachDoc });
              let urlsOBj;
              const generatedDocc = allDocs?.find((x) => {
                if (x?.id === parseInt(eachDoc?.id)) {
                  urlsOBj = eachDoc;
                  return x;
                }
              });
              console.log({ generatedDocc, wholeDocs, allDocs, urlsOBj, finalDocuments });
              if (generatedDocc?.displayName) {
                return (
                  <TableRow
                    key={eachDoc?.id}
                    className={classes.tableHeadingApplications}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      <TableCellTypography>
                        {replaceNullValues(generatedDocc?.displayName)}
                      </TableCellTypography>
                    </TableCell>
                    <TableCell sx={{ maxWidth: 200, wordBreak: 'break-word' }}>
                      <TableCellTypography>
                        <Typography
                          variant="p"
                          component="p"
                          // sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                          {replaceNullValues(generatedDocc?.documentType)}
                        </Typography>
                      </TableCellTypography>
                    </TableCell>
                    <TableCell>
                      <TableCellTypography>
                        {eachDoc?.createdDateTime
                          ? moment(eachDoc?.createdDateTime).format('MM/DD/yyyy, h:mm A')
                          : '--'}
                      </TableCellTypography>
                    </TableCell>
                    <TableCell sx={{ maxWidth: 150, wordBreak: 'break-word' }}>
                      <TableCellTypography>
                        {replaceNullValues(eachDoc?.createdByName)}
                      </TableCellTypography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="p"
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        {/* <Box> */}
                        <Link
                          color="secondary"
                          display="flex"
                          data-testid="deleteApplication"
                          sx={{ cursor: 'pointer' }}
                          target="_blank"
                          onClick={() => {
                            setPreviewUrl(urlsOBj?.url ? urlsOBj?.url : urlsOBj?.uri);
                            setPreview(true);
                          }}
                        >
                          <img src={PreviewIcon} />

                          <Typography variant="subtitle2" component="p" sx={{ marginLeft: '5px' }}>
                            Preview
                          </Typography>
                        </Link>
                        &ensp;&ensp;
                        <Link
                          color="secondary"
                          display="flex"
                          data-testid="deleteApplication"
                          sx={{ cursor: 'pointer' }}
                          target="_blank"
                          href={urlsOBj?.url ? urlsOBj?.url : urlsOBj?.uri}
                        >
                          <img src={PrintIcon} />

                          <Typography variant="subtitle2" component="p" sx={{ marginLeft: '5px' }}>
                            Print
                          </Typography>
                        </Link>
                        {/* </Box> */}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              }
            })}
            {finalDocuments?.length === 0 && (
              <TableRow>
                <TableCell colSpan={12} sx={{ fontSize: '14px !important', textAlign: 'center' }}>
                  No document available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </Grid>
  );
};

PrintDocumentsModal.propTypes = {
  applicationDetails: PropTypes.instanceOf(Object),
};
PrintDocumentsModal.defaultProps = {
  applicationDetails: {},
};
