import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Box,
  Button,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import moment from 'moment';
import TablePagination from 'pages/RegisterUser/TablePagination';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { handleFocusError } from 'utils/utility';
import * as yup from 'yup';

import CustomMultilineInput from 'components/CustomMultilineInput/CustomMultilineInput';
import Loader from 'components/Loader/Loader';

const CommentsTab = ({ appId, comments }) => {
  const [loader, setLoader] = useState(false);
  const ApiClient = new ApiClass();
  const { userID, fullName } = useContext(AuthContext);
  const [postMsg, setPostMsg] = useState('');
  const [commentList, setCommentList] = useState([]);

  const [page, setPage] = useState(1);
  const paginate = (_, pageNumber) => {
    setPage(pageNumber);
  };
  const rowsPerPage = 5;
  const indexOfLastPost = page * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const calcCommentList = (commentArr) => {
    return commentArr?.slice(indexOfFirstPost, indexOfLastPost);
  };
  const {
    control,
    handleSubmit,
    setValue,
    setFocus,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValue: {
      comment: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        comment: yup.string().required('* Mandatory Field'),
      })
    ),
  });
  useEffect(() => {
    handleFocusError({ errors, setFocus });
  }, [errors]);

  useEffect(() => {
    setCommentList(comments);
  }, [comments]);

  const TableHeading = ['User', 'Date', 'Comment'];

  const getComments = async () => {
    try {
      setLoader(true);

      const list = await ApiClient.get(`${UrlHelpers?.comments}?applicationId=${appId}`, {
        headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
      });
      console.log('list', list);
      if (list?.length) {
        setCommentList(list);
      }
    } catch (e) {
      console.log('e', e);
      setCommentList([]);
    } finally {
      setLoader(false);
    }
  };

  const postComment = async (data) => {
    try {
      setLoader(true);
      const id = await ApiClient.post(
        UrlHelpers?.comments,
        {
          applicationId: appId,
          comment: data?.comment,
          createdBy: userID,
        },
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );

      if (id) {
        setValue('comment', '');
        await getComments();
      }
    } catch (e) {
      console.log('e', e);
      setPostMsg(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later'
      );
    } finally {
      setLoader(false);
    }
  };
  return (
    <Box sx={{ padding: '10px' }}>
      <Loader open={loader} />
      <Paper sx={{ padding: '20px', background: '#F2F2F2' }}>
        <Box display="flex" alignItems="center" flexDirection="column">
          {postMsg && (
            <Alert
              severity={postMsg ? 'error' : 'success'}
              onClose={() => {
                setPostMsg('');
              }}
              sx={{ width: '70%' }}
              data-testid="post-failure-alert"
            >
              {postMsg}
            </Alert>
          )}
          <Box width="70%">
            <CustomMultilineInput
              control={control}
              errors={!!errors?.comment}
              errorText={errors?.comment?.message}
              placeholder="Enter your comments"
              label="Comment"
              name="comment"
              flexDirection="column"
              multiline
              resize="none"
              rows={6}
              maxLength={250}
            />
            <Box justifyContent="flex-end" display="flex" gap={2} mt={1}>
              <Link
                component="button"
                variant="subtitle2"
                color="secondary"
                sx={{ fontSize: '16px' }}
                onClick={() => {
                  setValue('comment', '');
                }}
                id="fresh-start"
              >
                Clear
              </Link>
              <Button variant="contained" color="secondary" onClick={handleSubmit(postComment)}>
                Post
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
      <Typography variant="h3" sx={{ padding: '20px 0' }}>
        Comments posted
      </Typography>
      <Box sx={{ padding: '15px' }}>
        <Typography variant="subtitle2">
          {commentList?.length > 0 &&
            `Showing ${indexOfFirstPost + 1} to ${
              commentList?.length > indexOfLastPost ? indexOfLastPost : commentList?.length
            } of ${commentList?.length} entries`}
        </Typography>
      </Box>
      <Table aria-label="applications-table" sx={{ border: '1px solid #F2F2F2' }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: '#F2F2F2' }}>
            {TableHeading?.map((x, index) => {
              return (
                <TableCell
                  key={x}
                  sx={{
                    fontWeight: 700,
                    fontSize: '14px !important',
                    width: index === 0 ? '50%' : 'auto',
                  }}
                >
                  {x}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {commentList?.length ? (
            calcCommentList(commentList).map((eachCmnt) => {
              const { commentId, comments } = eachCmnt;

              return (
                <TableRow
                  key={commentId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={{ fontSize: '14px !important' }}>{comments[0]?.from}</TableCell>
                  <TableCell sx={{ fontSize: '14px !important' }}>
                    {moment(comments[0]?.updatedDateTime).format('MM/DD/yyyy')}
                  </TableCell>

                  <TableCell sx={{ fontSize: '14px !important', wordBreak: 'break-all' }}>
                    {comments[0]?.comment}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={12} sx={{ fontSize: '14px !important', textAlign: 'center' }}>
                No record/s found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Box sx={{ margin: '15px' }}>
        <TablePagination
          postsPerPage={5}
          totalPosts={commentList?.length}
          paginate={paginate}
          page={page}
        />
      </Box>
    </Box>
  );
};

export default CommentsTab;

CommentsTab.propTypes = {
  appId: PropTypes.string,
  comments: PropTypes.array,
};
CommentsTab.defaultProps = {
  appId: '',
  comments: [],
};
