import { Grid } from '@mui/material';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { cityMapping, defaultFn } from 'utils/utility';

import { CustomAutoComplete } from 'components/CustomAutoComplete/CustomAutoComplete';
import CustomCollapse from 'components/CustomCollapse';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

const InviMailingAddress = ({
  control,
  errors,
  cityList,
  addressLookupData,
  addressLookupDataResponse,
  setValue,
  clearErrors,
  setError,
  isMounted,
  setPerCityList,
  oldState,
  setOldState,
}) => {
  const [watchPerAddress1, watchIsAddressSame, watchPerState] = useWatch({
    control,
    name: ['perAddress1', 'isAddrSame', 'perState'],
  });
  const { cityZipList, stateList, stateCityList } = useContext(AuthContext);
  console.log({ cityZipList });
  const handleStateAddress = async (AddressLine1, State) => {
    setValue('perAddress1', AddressLine1);
    setValue('perState', State);
    setOldState(State);
  };
  useEffect(async () => {
    let cityListt = [];
    if (stateCityList?.length > 0) {
      cityListt = await cityMapping(stateCityList, watchPerState);
      console.log({ cityListt });
    }

    if (isMounted && watchPerState !== oldState) {
      setValue('perCity', '');
      setValue('perZipcode', '');
    }
    setPerCityList(cityListt || []);
  }, [watchPerState, stateCityList]);
  const handleAutoCompleteData = async (selectedObj) => {
    if (selectedObj && selectedObj?.Address) {
      let { AddressLine1, PostalCode, City, State } = selectedObj?.Address;
      await handleStateAddress(AddressLine1, State);
      const cityListt = await cityMapping(stateCityList, State);
      PostalCode = PostalCode?.split('-')?.[0];
      setValue('perZipcode', PostalCode);

      if (State !== 'PR') {
        setValue('perCity', City);
      } else {
        City = cityZipList.find((x) => x?.zipcode === PostalCode)?.city;
        City = cityListt.find((x) => x.value === City)?.value || '';
        if (!City) {
          setError(`perCity`, {
            type: 'custom',
            message: 'Please select a city from the list and enter the zip code',
          });
        } else {
          setValue(`perCity`, City);
          clearErrors([`perAddress1`, `perZipcode`, `perCity`, `perState`]);
        }
      }
    } else {
      setValue('perAddress1', '');
      setValue('perAddress2', '');
      setValue('perZipcode', '');
      setValue('perCity', '');
      setValue('perState', '');
    }
  };
  return (
    <CustomCollapse heading="Mailing address" open={watchIsAddressSame} errors={errors}>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomAutoComplete
            id="perAddress1"
            name="perAddress1"
            flexDirection="column"
            control={control}
            regex={/^[a-zA-Z0-9áéíóúñüÁÉÍÓÚÑÜ¿¡.,/-\s]*$/g}
            dataTestIdAutocomplete="autocomplete_address1"
            dataTestIdForLabel="perAddress1"
            dataTestIdForInput="addr1Input"
            required
            label="Address line 1"
            options={
              addressLookupData && watchPerAddress1 && watchPerAddress1?.length >= 6
                ? addressLookupData
                : []
            }
            handleChange={(_event, selectedValue) => {
              if (watchPerAddress1 && watchPerAddress1.length >= 6) {
                const index = addressLookupData.indexOf(selectedValue);
                const selectedObj = addressLookupDataResponse[index];
                handleAutoCompleteData(selectedObj);
              }
            }}
            handleInputChange={(_event, newInputValue) => {
              console.log('new Input Value', newInputValue);
            }}
            // filterOptions={(options) => options}
            maxLength={40}
            autoFocus
            placeholder="Enter address line 1"
            fullWidth
            error={errors?.perAddress1}
            errorText={errors?.perAddress1?.message}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.perAddress2}
            errorText={errors?.perAddress2?.message}
            maxLength="20"
            regex={/^[A-Za-z0-9áéíóúñüÁÉÍÓÚÑÜ¿¡,./-\s]$/}
            placeholder="Enter address line 2"
            label="Address line 2"
            flexDirection="column"
            name="perAddress2"
          />
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomSelectBox
            flexDirection="column"
            label="State"
            control={control}
            name="perState"
            errors={!!errors?.perState}
            errorText={errors?.perState?.message}
            options={stateList}
            defaultValue=""
            required
            displayEmpty
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomSelectBox
            flexDirection="column"
            label="Country"
            control={control}
            name="perCountry"
            errors={!!errors?.perCountry}
            errorText={errors?.perCountry?.message}
            options={[
              {
                name: 'United States',
                value: 'USA',
              },
            ]}
            defaultValue=""
            required
            displayEmpty
            isDisabled
          />
        </Grid>
        {watchPerState === 'PR' && (
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="City"
              control={control}
              name="perCity"
              errors={!!errors?.perCity}
              errorText={errors?.perCity?.message}
              options={cityList}
              defaultValue=""
              required
              displayEmpty
            />
          </Grid>
        )}
        {watchPerState !== 'PR' && (
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.perCity}
              errorText={errors?.perCity?.message}
              placeholder="Enter city"
              label="City"
              name="perCity"
              regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡\s]$/}
              maxLength="15"
              type="text"
              flexDirection="column"
              required
            />
          </Grid>
        )}
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.perZipcode}
            errorText={errors?.perZipcode?.message}
            placeholder="Enter zipcode"
            label="Zip code"
            name="perZipcode"
            regex={/^\d$/}
            maxLength="5"
            type="text"
            flexDirection="column"
            required
          />
        </Grid>
      </Grid>
    </CustomCollapse>
  );
};
export default InviMailingAddress;

InviMailingAddress.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.bool,
  cityList: PropTypes.array,
  addressLookupData: PropTypes.array,
  addressLookupDataResponse: PropTypes.array,
  setValue: defaultFn,
  clearErrors: defaultFn,
  setError: defaultFn,
  isMounted: PropTypes.bool,
  setPerCityList: defaultFn,
  oldState: PropTypes.string,
  setOldState: defaultFn,
};
InviMailingAddress.defaultProps = {
  control: {},
  errors: false,
  cityList: [],
  addressLookupData: [],
  addressLookupDataResponse: [],
  setValue: defaultFn,
  clearErrors: defaultFn,
  setError: defaultFn,
  isMounted: false,
  setPerCityList: defaultFn,
  oldState: '',
  setOldState: defaultFn,
};
