/* eslint-disable */
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import AuthContext from 'context/AuthContext';
import { useContext } from 'react';
import classes from 'styles/globalStyle.module.scss';
import { numberToCurrency } from 'utils/utility';

export const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: 'clamp(600px, 70vw, 700px)',
    padding: '16px',
    minHeight: 'clamp(40px,50vh,100%)',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const OptionsDialog = ({ popupRows, handleCheckboxChange, selectedRows }) => {
  const { dialogOpen, setDialogOpen, setShowPage } = useContext(AuthContext);
  const handleClose = () => {
    if (selectedRows?.length && popupRows.find((i) => i.isChecked === true)) {
      setDialogOpen(false);
    } else {
      setShowPage('');
      setDialogOpen(false);
    }
  };
  const groupBy = (data, key) =>
    data.reduce((acc, item) => {
      // eslint-disable-next-line no-param-reassign

      const group = item[key];
      if (!acc[group]) {
        // eslint-disable-next-line no-param-reassign
        acc[group] = [];
      }
      acc[group].push(item);
      return acc;
    }, {});

  const groupedRows = groupBy(popupRows, 'category');
  console.log('popopopo', popupRows);

  return (
    <>
      <CustomDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={dialogOpen}
      >
        <Typography p={2} variant="subtitle2">
          Please select one of the required items below.
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            display: 'flex',
            gap: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            overflowY: 'initial',
          }}
        >
          <Box display="flex" flexDirection="column" className={classes.tableOutline} mt={2}>
            <Box
              display="flex"
              gap={2}
              sx={{ backgroundColor: '#f2f2f2' }}
              p={2}
              className={classes.borderTable}
            >
              <Typography
                variant="subtitle2"
                sx={{ flexGrow: 1, fontWeight: 700, paddingLeft: '30px' }}
              >
                Description
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
              >
                Invoice
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
              >
                MSRP
              </Typography>
            </Box>
            {Object.keys(groupedRows).map((category) => (
              <Box
                display="flex"
                flexDirection="column"
                key={category}
                className={classes.childBorderLine}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    backgroundColor: '#F9F9F9;',
                    padding: '16px',
                    paddingLeft: '40px',
                    fontWeight: 700,
                  }}
                  className={classes.borderTable}
                >
                  {category}
                </Typography>
                {groupedRows[category]?.map?.((x) => {
                  const { description, invoice, retail, seqNumber } = x;

                  return (
                    <Box display="flex" gap={2} key={seqNumber} p={2}>
                      <Checkbox
                        color="secondary"
                        checked={
                          selectedRows?.find((el) => el.seqNumber === seqNumber) ? true : false
                        }
                        onChange={(event) => handleCheckboxChange(event, x)}
                      />
                      <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                        {description}
                      </Typography>
                      <Typography variant="subtitle2" sx={{ minWidth: '15%', maxWidth: '15%' }}>
                        {numberToCurrency(invoice)}
                      </Typography>
                      <Typography variant="subtitle2" sx={{ minWidth: '15%', maxWidth: '15%' }}>
                        {numberToCurrency(retail)}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'right', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
            data-testid="cancelBtn"
          >
            Cancel
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default OptionsDialog;
