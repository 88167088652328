import { Alert, IconButton, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { defaultFn } from 'utils/utility';

const ErrorAlert = ({ apiErr, setApiErr, success, showMore, details }) => {
  const tabRef = useRef(null);
  const [dealerList, setDealerList] = useState([]);
  useEffect(() => {
    if (apiErr) {
      tabRef.current.focus();
    }
    if (showMore) {
      setDealerList(details?.dealers?.split(',').slice(0, 5));
    }
  }, [apiErr]);

  console.log('details?.dealers?.length ', details?.dealers, dealerList);
  const showDealers = () => {
    return (
      <Typography component="span" variant="subtitle2">
        {dealerList?.join(',')}
        {details?.dealers?.split(',')?.length > 5 && dealerList?.length === 5 && (
          <Typography
            component="span"
            variant="subtitle2"
            tabIndex="0"
            sx={{ cursor: 'pointer' }}
            onClick={showFullList}
          >
            ...more
          </Typography>
        )}
      </Typography>
    );
  };

  const showFullList = () => {
    setDealerList(details?.dealers?.split(','));
  };
  return (
    <>
      {!!apiErr && (
        <Alert
          sx={{
            marginTop: '8px',
            alignItems: 'center',
            fontSize: '14px !important',
          }}
          aria-label={apiErr}
          severity={success ? 'success' : 'error'}
          action={
            <IconButton
              ref={tabRef}
              aria-label={`${apiErr}-close`}
              color="inherit"
              size="small"
              data-testid="close-btn"
              onClick={() => {
                setApiErr('');
              }}
            >
              <GridCloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {typeof apiErr !== 'object' ? apiErr : apiErr?.message || apiErr?.Message}
          {showMore && (
            <>
              <Typography component="p" variant="subtitle2">
                Report name : {details?.reportName}
              </Typography>
              <Typography component="p" variant="subtitle2">
                Dealers : {showDealers()}
              </Typography>
              <Typography component="p" variant="subtitle2">
                Date range : {details?.dateRange}
              </Typography>
            </>
          )}
        </Alert>
      )}
    </>
  );
};

export default ErrorAlert;

ErrorAlert.propTypes = {
  apiErr: PropTypes.string,
  setApiErr: PropTypes.func,
  success: PropTypes.bool,
  showMore: PropTypes.bool,
  details: PropTypes.instanceOf(Object),
};
ErrorAlert.defaultProps = {
  apiErr: '',
  setApiErr: defaultFn,
  success: false,
  showMore: false,
  details: {},
};
