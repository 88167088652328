import PropTypes from 'prop-types';

const CustomStepIcon = (props) => {
  console.log('props custom icon', props);
  const { active, completed, icon } = props;
  let otherStepColors = active ? 'white' : '#F3F3F3';
  let stepColor = active ? '#661c69' : '#727578';
  return (
    <div
      style={{
        width: '36px',
        height: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: completed ? '#661c69' : otherStepColors,
        color: completed ? 'white' : stepColor,
        borderRadius: '50%',
        border: completed || active ? '3px solid #661c69' : '3px solid #D0D0D0',
      }}
    >
      {icon}
    </div>
  );
};

export default CustomStepIcon;

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.number,
  completed: PropTypes.bool,
};
CustomStepIcon.defaultProps = {
  active: false,
  icon: 0,
  completed: false,
};
