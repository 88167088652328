/* eslint-disable react/jsx-props-no-spreading */
import { Box, FormHelperText, InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { defaultFn } from 'utils/utility';

import classes from '../../styles/globalStyle.module.scss';

const CustomFileUpload = (props) => {
  const { control, label, name, register, errors, errorText } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <Box display="flex" alignItems="baseline" gap={2} my={0.5} px={2} sx={{ width: '60%' }}>
          <InputLabel htmlFor={name}>{label}</InputLabel>

          {/* // eslint-disable-next-line react/jsx-props-no-spreading */}
          <Box>
            <input
              type="file"
              className={classes.customUpload}
              id={name}
              name={name}
              data-testid={name}
              {...register(name)}
            />
            {errors && (
              <FormHelperText gap={2} className={classes.customSelectError}>
                {' '}
                {errorText}
              </FormHelperText>
            )}
          </Box>
        </Box>
      )}
    />
  );
};

export default CustomFileUpload;

CustomFileUpload.propTypes = {
  control: PropTypes.instanceOf(Object),
  classes: PropTypes.string,
  name: PropTypes.string,
  errors: PropTypes.bool,
  isDisabled: PropTypes.bool,
  errorText: PropTypes.string,
  label: PropTypes.string,
  register: PropTypes.func,
};
CustomFileUpload.defaultProps = {
  control: {},
  classes: '',
  name: '',
  label: '',
  errors: false,
  isDisabled: false,
  errorText: '',
  register: defaultFn,
};
