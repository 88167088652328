import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import LogoutPopup from 'assets/svg/LogoutPopup.svg';
import AuthContext from 'context/AuthContext';
import { useContext } from 'react';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';

const LogoutDialog = () => {
  const { isLogoutDialogOpen, setIsLogoutDialogOpen } = useContext(AuthContext);
  const ApiClient = new ApiClass();
  const handleClose = () => {
    setIsLogoutDialogOpen(false);
  };

  const handleLogout = async () => {
    setIsLogoutDialogOpen(false);
    try {
      const resp = await ApiClient.post(
        `${process.env.REACT_APP_BASE_END_POINT}/ob-idmautoapi/authentication/sessionclose`,
        {},
        {
          withCredentials: 'true',
          headers: {
            'Content-Type': 'application/json',
            appid: 'auto',
            'x-api-key': process.env.REACT_APP_X_API_KEY,
          },
        }
      );
      console.log({ resp });
    } catch (e) {
      console.log('e', e);
    } finally {
      sessionStorage.clear();
    }
    const cognitoConfig = JSON.parse(process.env.REACT_APP_CONFIG);
    window.location.replace(`${cognitoConfig.REDIRECTSIGNOUT}/?autoSignout=no`);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isLogoutDialogOpen}
      PaperProps={{ sx: { maxWidth: '700px' } }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid container justifyContent="space-between">
          <Grid
            item
            lg={5}
            md={5}
            sm={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box display="flex">
              <img src={LogoutPopup} style={{ width: '100%' }} />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} padding="30px 0px">
            <BootstrapDialogTitle
              display="flex"
              alignItems="center"
              onClose={handleClose}
              sx={{ width: '90%', padding: '0' }}
            >
              <Typography variant="h1">You are about to exit the application</Typography>
            </BootstrapDialogTitle>
            <Typography variant="subtitle1" sx={{ padding: '16px 0' }}>
              Do you really want to exit this application? Maybe there is still some interesting
              activity to be done
            </Typography>
            <DialogActions sx={{ justifyContent: 'flex-start', gap: '15px' }}>
              <Button variant="outlined" color="secondary" onClick={handleClose}>
                No, continue
              </Button>
              <Button variant="contained" color="secondary" onClick={handleLogout}>
                Yes
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default LogoutDialog;
