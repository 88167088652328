/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import ApiClass from 'Api/ApiClient';
import { LockReleaseApi } from 'Services/LockReleaseApi';
import Add from 'assets/svg/AddIcon.svg';
import Remove from 'assets/svg/RemoveIcon.svg';
import AuthContext from 'context/AuthContext';
import CreateApplicationContext from 'context/CreateApplicationContext';
import { useContext, useEffect, useState } from 'react';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { v4 as uuidv4 } from 'uuid';

import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

import ApplicationDialogHeading from '../InformationRequest/moduleUtilities/ApplicationDialogHeading';
import AddRemoveBtn from './AddRemoveBtn';

const CoAppDetailDialog = ({
  reset,
  setValue,
  control,
  errors,
  watch,
  setAddCoSigner,
  addCoSigner,
  coSignerContainer,
  setCosignerContainer,
  clearErrors,
  setCosignerEnabled,
  handleSubmit,
  fields,
  append,
  remove,
  replace,
}) => {
  const { showCoAppDet, setShowCoAppDet, setShowInviManual, setShowCoApp, setCreateAppSchema1 } =
    useContext(CreateApplicationContext);
  const [loader, setLoader] = useState(false);
  const [apiErr, setApiErr] = useState('');
  const ApiClient = new ApiClass();
  const {
    setOpenPopUp,
    setCreateAppLookup,
    setCreateAppFlag,
    setCreateAppLaunchType,
    setDealer,
    createAppApplicationId,
    userID,
    fullName,
  } = useContext(AuthContext);

  const watchApplicantOrSigner = watch('applicantOrSigner');
  const watchcosigner = watch('CoSigner');
  console.log('----watchcosigner', watchcosigner);
  console.log('----addCoSigner', addCoSigner);
  const handleClose = () => {
    setShowCoAppDet(false);
    setOpenPopUp(false);
    reset();
    setCreateAppLookup('');
    setCreateAppFlag(false);
    setDealer('');
    setCreateAppLaunchType('');
    LockReleaseApi({ appid: createAppApplicationId, fullName, userID });
  };
  const handleContinue = async (data) => {
    let cousersDetailPayload = {};
    let addCoApplicantCheckboxSelected;
    let addCoSignerCheckboxSelected;
    if (data?.CoAppFirstName) {
      addCoApplicantCheckboxSelected = true;
      cousersDetailPayload.coApplicants = [
        {
          applicantId: data?.CoAppId || uuidv4(),
          informationRequest: {
            participantInfo: {
              customerName: {
                firstName: data?.CoAppFirstName,
                lastName: data?.CoAppLastName,
              },
            },
          },
        },
      ];
    }
    if (data?.CoSigner[0]?.CoSignerName?.CoSignerFirstName) {
      addCoSignerCheckboxSelected = true;
      cousersDetailPayload.coSigners = data?.CoSigner?.map((x) => {
        return {
          applicantId: x?.CoSignerAppId || uuidv4(),
          informationRequest: {
            participantInfo: {
              customerName: {
                firstName: x?.CoSignerName?.CoSignerFirstName,
                lastName: x?.CoSignerName?.CoSignerLastName,
              },
            },
          },
        };
      });
    }

    try {
      setLoader(true);
      await ApiClient.post(
        UrlHelpers?.informationReqSave,

        {
          event: 'PreCoApplicantCoSignerInfoRequest',
          application: {
            applicationId: createAppApplicationId,
            products: {
              productType: 'Indirect Loan Account',
              productName: 'AutoLoan',
            },
            userId: userID,
            applicants: {
              ...cousersDetailPayload,
              addCoSignerCheckboxSelected,
              addCoApplicantCheckboxSelected,
            },
            source: {
              language: 'EN',
              location: 'PR',
            },
          },
        },
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );
      decideNext(data);
    } catch (e) {
      console.log('e', e);
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later.'
      );
    } finally {
      setLoader(false);
    }
  };

  const decideNext = (data) => {
    setShowCoAppDet(false);
    setShowInviManual(true);

    setCreateAppSchema1(data);
  };
  const handleBack = () => {
    setShowCoAppDet(false);
    setShowCoApp(true);
  };

  useEffect(async () => {
    await getSpecificDetailsApi();
  }, []);

  const getSpecificDetailsApi = async () => {
    try {
      setLoader(true);

      const getResp = await ApiClient.get(
        `${UrlHelpers.getSpecificDetails}?applicationId=${createAppApplicationId}&eventName=PreCoApplicantCoSignerInfoRequest&isLockRequired=true`,
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );

      console.log('getResp', getResp);
      const { PreCoApplicantCoSignerInfoRequest } = getResp;
      console.log('PreCoApplicantCoSignerInfoRequest', PreCoApplicantCoSignerInfoRequest);
      let coAppCoSigners = [];
      if (PreCoApplicantCoSignerInfoRequest?.addCoApplicantCheckboxSelected) {
        coAppCoSigners.push('coApplicant');
      }
      if (PreCoApplicantCoSignerInfoRequest?.addCoSignerCheckboxSelected) {
        coAppCoSigners.push('coSigner');
      }
      setValue('applicantOrSigner', coAppCoSigners);

      const cosigners = PreCoApplicantCoSignerInfoRequest?.coSigners?.map((x) => {
        return {
          CoSignerName: {
            CoSignerFirstName: x?.informationRequest?.participantInfo?.customerName?.firstName,
            CoSignerLastName: x?.informationRequest?.participantInfo?.customerName?.lastName,
          },
          CoSignerAppId: x?.applicantId,
        };
      });
      console.log('cosigners', cosigners);
      if (cosigners?.length) {
        replace([...cosigners]);
      }
      if (PreCoApplicantCoSignerInfoRequest?.coApplicants?.[0]?.applicantId) {
        setValue(
          'CoAppFirstName',
          PreCoApplicantCoSignerInfoRequest?.coApplicants?.[0]?.informationRequest?.participantInfo
            ?.customerName?.firstName
        );
        setValue(
          'CoAppLastName',
          PreCoApplicantCoSignerInfoRequest?.coApplicants?.[0]?.informationRequest?.participantInfo
            ?.customerName?.lastName
        );
        setValue('CoAppId', PreCoApplicantCoSignerInfoRequest?.coApplicants?.[0]?.applicantId);
      }
    } catch (e) {
      console.log('error', e);
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later.'
      );
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Dialog
        open={showCoAppDet}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-desc"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              minWidth: 'clamp(400px, 50vw, 800px) !important',
              minHeight: 'clamp(450px,60vh,100%) !important',
            },
          },
        }}
      >
        <Loader open={loader} />
        <ApplicationDialogHeading handleBack={handleBack} handleClose={handleClose} />
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
        >
          <Box display="flex" flexDirection="column" gap={1} px={5} mb={2.5}>
            <Grid container sx={{ justifyContent: 'center' }}>
              <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
            </Grid>
            <Typography variant="h4" textAlign="center" mt={3} id="dialog-desc">
              Ok! Add your co-applicant / co-signer details
            </Typography>

            {watchApplicantOrSigner?.includes('coApplicant') && (
              <Grid container>
                <Grid container gap={1} justifyContent="space-between">
                  <Grid item lg={12} md={12} sm={12}>
                    <Box className={classes.heading}>
                      <Typography
                        variant="h6"
                        component="h3"
                        my={2}
                        style={{ fontWeight: 700, margin: '0' }}
                      >
                        Co-applicant
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={5.5} md={6} sm={12}>
                    <CustomInputBox
                      control={control}
                      errors={!!errors?.CoAppFirstName}
                      errorText={errors?.CoAppFirstName?.message}
                      placeholder="Enter first name"
                      label="First name"
                      name="CoAppFirstName"
                      maxLength="15"
                      regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡\s]$/}
                      flexDirection="column"
                      required
                    />
                  </Grid>
                  <Grid item lg={5.5} md={6} sm={12}>
                    <CustomInputBox
                      control={control}
                      errors={!!errors?.CoAppLastName}
                      errorText={errors?.CoAppLastName?.message}
                      placeholder="Enter last name"
                      label="Last name"
                      name="CoAppLastName"
                      maxLength="25"
                      regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡\s]$/}
                      flexDirection="column"
                      required
                    />
                  </Grid>
                </Grid>
                {watchApplicantOrSigner?.includes('coApplicant') &&
                  !watchApplicantOrSigner?.includes('coSigner') && (
                    <Box sx={{ margin: '40px 0' }}>
                      <Link
                        component="button"
                        variant="subtitle2"
                        color="secondary"
                        aria-label={
                          !coSignerContainer && !watchApplicantOrSigner?.includes('coSigner')
                            ? 'Add co-signer(s)'
                            : 'Remove co-signer(s)'
                        }
                        data-testid="addRemoveBtn_testid"
                        onClick={() => {
                          setCosignerContainer(!coSignerContainer);
                          if (!coSignerContainer) {
                            setCosignerEnabled(true);
                            setValue('CoSigner', [
                              { CoSignerName: { CoSignerFirstName: '', CoSignerLastName: '' } },
                            ]);
                          } else {
                            remove(1);
                            setAddCoSigner(false);
                            clearErrors([
                              'CoSigner[0].CoSignerName.CoSignerFirstName',
                              'CoSigner[0].CoSignerName.CoSignerLastName',
                            ]);
                            setValue('CoSigner', [
                              { CoSignerName: { CoSignerFirstName: '', CoSignerLastName: '' } },
                            ]);
                            setCosignerEnabled(false);
                          }
                        }}
                      >
                        {!coSignerContainer && !watchApplicantOrSigner?.includes('coSigner')
                          ? AddRemoveBtn('Add co-signer(s)', Add)
                          : AddRemoveBtn('Remove co-signer(s)', Remove)}
                      </Link>
                    </Box>
                  )}
              </Grid>
            )}

            {(watchApplicantOrSigner?.includes('coSigner') || coSignerContainer) && (
              <Grid container>
                <Grid container gap={0.5} justifyContent="space-between">
                  <Grid item lg={12} md={12} sm={12}>
                    <Box className={classes.heading}>
                      <Typography
                        variant="h6"
                        component="h3"
                        my={2}
                        style={{ fontWeight: 700, margin: '0' }}
                      >
                        Co-signer(s)
                      </Typography>
                    </Box>
                  </Grid>
                  {fields.map((fieldss, index) => {
                    return (
                      <>
                        <Grid item lg={5.5} md={6} sm={12} key={fieldss.id}>
                          <CustomInputBox
                            control={control}
                            errors={
                              !!errors?.CoSigner?.[index]?.CoSignerName?.CoSignerFirstName || false
                            }
                            errorText={
                              errors?.CoSigner?.[index]?.CoSignerName?.CoSignerFirstName?.message ||
                              ''
                            }
                            maxLength="15"
                            regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡\s]$/}
                            placeholder="Enter first name"
                            label="First name"
                            name={`CoSigner[${index}].CoSignerName.CoSignerFirstName`}
                            flexDirection="column"
                            required
                          />
                        </Grid>
                        <Grid item lg={5.5} md={6} sm={12}>
                          <CustomInputBox
                            control={control}
                            errors={
                              !!errors?.CoSigner?.[index]?.CoSignerName?.CoSignerLastName || false
                            }
                            errorText={
                              errors?.CoSigner?.[index]?.CoSignerName?.CoSignerLastName?.message ||
                              ''
                            }
                            maxLength="25"
                            regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡\s]$/}
                            placeholder="Enter last name"
                            label="Last name"
                            name={`CoSigner[${index}].CoSignerName.CoSignerLastName`}
                            flexDirection="column"
                            required
                          />
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
                <Box sx={{ margin: '40px 0' }}>
                  <Link
                    component="button"
                    variant="subtitle2"
                    color="secondary"
                    aria-label={!addCoSigner ? 'Add Button' : 'Remove Button'}
                    data-testid="coSignerAddBtn"
                    onClick={() => {
                      setAddCoSigner(!addCoSigner);
                      if (!addCoSigner) {
                        append({ CoSignerFirstName: '', CoSignerLastName: '' });
                      } else if (addCoSigner) {
                        remove(1);
                      }
                    }}
                  >
                    {watchcosigner?.length === 1
                      ? AddRemoveBtn('Add', Add)
                      : AddRemoveBtn('Remove', Remove)}
                  </Link>
                </Box>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
            id="cancel-btn"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            sx={{ maxHeight: '40px' }}
            onClick={handleSubmit(handleContinue)}
            id="continue-btn"
          >
            {!watchApplicantOrSigner ? 'Skip & continue' : 'Continue'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CoAppDetailDialog;
