import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { Box, Button, Link, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { numberToCurrency } from 'utils/utility';

import Loader from 'components/Loader/Loader';

import ViewStandardEquipment from './ViewStandardEquipment';

const VehicleValueSummaryNew = ({
  equipmentAdjustment,
  uvc,
  residuals,
  uvcTieBreaker,
  basevehicleValue,
  selectedRows,
  coApplicant,
  coSigner,
  reviewFlag,
  calculationState,
  completedSteps,
  viewAppFlow,
}) => {
  const {
    setShowSummary,
    createAppFlag,
    pageData,
    setCollateralInfo,
    collateralInfo,
    setLookupSaved,
    createAppApplicationId,
    lookupLinkClicked,
    setLookupValue,
    createAppLookup,
    setOpenPopUp,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const ApiClient = new ApiClass();
  const [openModal, setOpenModal] = useState(false);
  const [categoryList, setcategoryList] = useState([]);
  const { invoice: equipmentInvoice = 0, msrp: equipmentMSRP = 0 } = equipmentAdjustment;
  const { invoice: baseInvoice = 0, msrp: baseMSRP = 0 } = basevehicleValue;

  useEffect(() => {
    const payload = {
      vehicleValues: {
        basevehicleValue: basevehicleValue,
        equipmentAdjustment: equipmentAdjustment,
        mileageAdjustment: '',
        selectedEquipmentAdjustment: selectedRows,
        residuals,
        totalMSRP,
        totalInvoice,
      },
      productDetails: pageData?.productDetails,
    };
    if (!createAppFlag && !lookupLinkClicked) {
      setLookupValue([{ ...payload }]);
    }
  }, [basevehicleValue]);

  useEffect(() => {
    if (createAppLookup === 'new') {
      const payload = {
        vehicleValues: {
          basevehicleValue: basevehicleValue,
          equipmentAdjustment: equipmentAdjustment,
          mileageAdjustment: '',
          selectedEquipmentAdjustment: selectedRows,
          residuals,
          totalMSRP,
          totalInvoice,
        },
        productDetails: pageData?.productDetails,
      };
      console.log('payload', payload);
      setOpenPopUp(true);
      setCollateralInfo({ ...payload });
    }
  }, [createAppLookup]);

  const summaryArray = [
    {
      name: 'Base vehicle value',
      invoice: lookupLinkClicked
        ? collateralInfo?.vehicleValues?.basevehicleValue?.invoice
        : baseInvoice,
      msrp: lookupLinkClicked ? collateralInfo?.vehicleValues?.basevehicleValue?.msrp : baseMSRP,
    },
    {
      name: 'Equipment adjustment',
      invoice: lookupLinkClicked
        ? collateralInfo?.vehicleValues?.equipmentAdjustment?.invoice
        : equipmentInvoice,
      msrp: lookupLinkClicked
        ? collateralInfo?.vehicleValues?.equipmentAdjustment?.msrp
        : equipmentMSRP,
    },
  ];

  const totalMSRP = summaryArray.reduce((acc, eachObj) => {
    let total = acc;
    total += eachObj.msrp;
    return total;
  }, 0);
  const totalInvoice = summaryArray.reduce((acc, eachObj) => {
    let total = acc;
    total += eachObj.invoice;
    return total;
  }, 0);

  const onViewStdEquipment = async () => {
    try {
      setLoader(true);
      const resp = await ApiClient.post(
        UrlHelpers.getStandardEquipment,
        {
          uvc,
          uvc_tie_breaker: uvcTieBreaker,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      const { category_list: categoryArr } = resp?.std_equip;
      setcategoryList(categoryArr);
      setOpenModal(true);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  };

  const onSaveValue = async () => {
    const payload = {
      vehicleValues: {
        basevehicleValue: basevehicleValue,
        equipmentAdjustment: equipmentAdjustment,
        mileageAdjustment: '',
        selectedEquipmentAdjustment: selectedRows,
        residuals,
        totalMSRP,
        totalInvoice,
      },
      productDetails: pageData?.productDetails,
    };

    if (!lookupLinkClicked) {
      setCollateralInfo({ ...payload });
      try {
        setLoader(true);
        const resp = await ApiClient.post(
          UrlHelpers.saveLookupValue,
          {
            applicationId: createAppApplicationId,
            type: 'vehicle',
            searchResult: JSON.stringify({ ...payload }),
          },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        console.log(resp);
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoader(false);
      }
    }
    setLookupSaved(true);
    navigate('/home/create-app', {
      state: {
        activeStep: 3,
        completedSteps,
        coApplicant,
        coSigner,
        reviewFlag,
        calculationState,
        viewAppFlow,
      },
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap={2.5} flexGrow="1" pb={5}>
      {loader && <Loader open={loader} />}
      <Box display="flex" justifyContent="space-between">
        <Typography sx={{ fontSize: '18px!important', fontWeight: 700 }}>Vehicle values</Typography>
        <Box display="flex" gap="2px">
          <LocalPrintshopOutlinedIcon color="secondary" />
          <Link
            component="button"
            variant="subtitle2"
            color="secondary"
            id="print-link"
            onClick={() => {
              window.print();
            }}
          >
            Print
          </Link>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ border: '1px solid #DADADA' }}
        className={classes.childBorderLine}
        role="table"
      >
        <Box display="flex" gap={2} sx={{ backgroundColor: '#f2f2f2' }} p={2} role="row">
          <Typography sx={{ flexGrow: 1 }} fontWeight="700" role="columnheader">
            Categories
          </Typography>
          <Typography sx={{ width: '15%', fontWeight: 700 }} role="columnheader">
            Invoice
          </Typography>
          <Typography sx={{ width: '15%', fontWeight: 700 }} role="columnheader">
            MSRP
          </Typography>
        </Box>
        {summaryArray?.map((eachObj) => {
          const { name, invoice, msrp } = eachObj;
          return (
            <Box
              display="flex"
              gap={2}
              p={2}
              key={`${name}-${invoice}-${msrp}`}
              className={classes.childBorderLine}
              role="row"
            >
              <Typography sx={{ flexGrow: 1 }} role="cell">
                {name}
              </Typography>
              <Typography sx={{ minWidth: '15%', maxWidth: '15%' }} role="cell">
                {numberToCurrency(invoice)}
              </Typography>
              <Typography sx={{ minWidth: '15%', maxWidth: '15%' }} role="cell">
                {numberToCurrency(msrp)}
              </Typography>
            </Box>
          );
        })}
        <Box display="flex" gap={2} sx={{ backgroundColor: '#f2f2f2' }} p={1} role="row">
          <Typography sx={{ flexGrow: 1, fontWeight: 700 }} role="cell">
            Total value
          </Typography>
          <Typography sx={{ width: '15%', fontWeight: 700 }} role="cell">
            {numberToCurrency(totalInvoice)}
          </Typography>
          <Typography sx={{ width: '15%', fontWeight: 700 }} role="cell">
            {numberToCurrency(totalMSRP)}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ border: '1px solid #DADADA' }}
        className={classes.childBorderLine}
        role="table"
      >
        <Typography
          role="rowgroup"
          sx={{ fontWeight: 700, backgroundColor: '#f2f2f2', padding: '8px' }}
        >
          Residuals
        </Typography>
        <Box display="flex" role="row" gap={{ sm: 2 }} sx={{ backgroundColor: '#f2f2f2' }} p={2}>
          <Typography role="columnheader" sx={{ flexGrow: 1 }} fontWeight="700"></Typography>
          <Typography role="columnheader" sx={{ width: '15%', fontWeight: 700 }}>
            12 Month
          </Typography>
          <Typography role="columnheader" sx={{ width: '15%', fontWeight: 700 }}>
            24 Month
          </Typography>
          <Typography role="columnheader" sx={{ width: '15%', fontWeight: 700 }}>
            36 Month
          </Typography>
          <Typography role="columnheader" sx={{ width: '15%', fontWeight: 700 }}>
            48 Month
          </Typography>
          <Typography role="columnheader" sx={{ width: '15%', fontWeight: 700 }}>
            60 Month
          </Typography>
        </Box>
        <Box display="flex" gap={{ sm: 2 }} role="row" p={2}>
          <Typography sx={{ flexGrow: 1 }} role="cell">
            Equipment adjustment
          </Typography>
          {Object.keys(
            lookupLinkClicked ? collateralInfo?.vehicleValues?.residuals || [] : residuals || []
          )?.map((eachKey) => (
            <Typography
              key={`${residuals[eachKey]}`}
              sx={{
                minWidth: '15%',
                maxWidth: '15%',
                overflowWrap: 'break-word',
              }}
              role="Cell"
            >
              {numberToCurrency(
                lookupLinkClicked
                  ? collateralInfo?.vehicleValues?.residuals[eachKey]
                  : residuals[eachKey]
              )}
            </Typography>
          ))}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
        <Box display="flex" alignItems="center">
          {!lookupLinkClicked && (
            <>
              <KeyboardArrowLeftIcon color="secondary" />
              <Link component="button" color="secondary" onClick={() => setShowSummary(false)}>
                Back
              </Link>
            </>
          )}
        </Box>
        <Box display="flex" alignSelf="flex-end" gap="20px">
          <Button
            onClick={onViewStdEquipment}
            variant="outlined"
            color="secondary"
            id="view-equipment"
          >
            View Standard Equipment
          </Button>
          {createAppFlag && (
            <Button onClick={onSaveValue} variant="contained" color="secondary" id="save-val-btn">
              Save value
            </Button>
          )}
        </Box>

        <ViewStandardEquipment
          openModal={openModal}
          setOpenModal={setOpenModal}
          categoryList={categoryList}
        />
      </Box>
    </Box>
  );
};

export default VehicleValueSummaryNew;

VehicleValueSummaryNew.propTypes = {
  basevehicleValue: PropTypes.instanceOf(Object),
  equipmentAdjustment: PropTypes.instanceOf(Object),
  residuals: PropTypes.instanceOf(Object),
  uvc: PropTypes.string,
  uvcTieBreaker: PropTypes.string,
  selectedRows: PropTypes.instanceOf(Array),
  coApplicant: PropTypes.instanceOf(Object),
  coSigner: PropTypes.instanceOf(Object),
  reviewFlag: PropTypes.bool,
  calculationState: PropTypes.string,
  completedSteps: PropTypes.array,
  viewAppFlow: PropTypes.bool,
};
VehicleValueSummaryNew.defaultProps = {
  basevehicleValue: {},
  equipmentAdjustment: {},
  uvc: '',
  uvcTieBreaker: '',
  residuals: {},
  selectedRows: [],
  coApplicant: {},
  coSigner: [],
  reviewFlag: false,
  calculationState: '',
  completedSteps: [],
  viewAppFlow: false,
};
