import { Box, Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import InvitationSuccessImage from 'assets/svg/InvitationSuccess.svg';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { defaultFn } from 'utils/utility';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';

const InvitationSuccess = ({ invitationSuccess, setInvitationSuccess }) => {
  const {
    invitationAppNumber,
    setOpenExitModal,
    setCreateAppFlag,
    setCreateAppLaunchType,
    setDealer,
  } = useContext(AuthContext);
  const handleClose = () => {
    setInvitationSuccess(false);
    setOpenExitModal(false);
    setCreateAppFlag(false);
    setDealer('');
    setCreateAppLaunchType('');
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={invitationSuccess}
      PaperProps={{ sx: { padding: '60px 30px', maxWidth: '700px' } }}
    >
      <DialogContent
        sx={{
          padding: '0 30px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid container justifyContent="space-between">
          <Grid
            item
            lg={5.5}
            md={5.5}
            sm={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box display="flex" gap={1}>
              <img src={InvitationSuccessImage} style={{ width: '100%' }} />
            </Box>
          </Grid>
          <Grid item lg={5.5} md={5.5} sm={12}>
            <BootstrapDialogTitle onClose={handleClose} sx={{ padding: '0' }}>
              <Typography variant="h3" component="h3" fontWeight={700}>
                Invitation is sent successfully
              </Typography>
            </BootstrapDialogTitle>
            <Typography variant="subtitle2" color="#909090" sx={{ padding: '16px 0' }}>
              Once the customer provides the details and submits the response, you will be requested
              to proceed with the application by filling out the remaining fields. You can find the
              application in my drafts. As a reference, kindly use the application number{' '}
              <Typography variant="span" fontWeight="700">
                {invitationAppNumber}
              </Typography>
            </Typography>

            <Button variant="contained" color="secondary" onClick={handleClose}>
              Ok
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default InvitationSuccess;

InvitationSuccess.propTypes = {
  invitationSuccess: PropTypes.bool,
  setInvitationSuccess: PropTypes.func,
};
InvitationSuccess.defaultProps = {
  invitationSuccess: false,
  setInvitationSuccess: defaultFn,
};
