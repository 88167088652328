import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useWatch } from 'react-hook-form';
import { defaultFn, nameMapping } from 'utils/utility';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';
import Loader from 'components/Loader/Loader';

const LookupSecondDialog = ({
  control,
  errors,
  isValid,
  handleSubmit,
  onNext,
  reset,
  clearErrors,
  loader,
}) => {
  const {
    showSecondaryPopup,
    setShowSecondaryPopup,
    setShowMainPopup,
    setShowInterPopup,
    yearList,
    makeList,
    modelList,
    subModelList,
    styleList,
    lookupStateList,
  } = useContext(AuthContext);

  const handleBack = () => {
    setShowSecondaryPopup(false);
    if (watchCollateralType === 'used') {
      setShowInterPopup(true);
    } else {
      setShowMainPopup(true);
    }
    clearErrors();
  };

  const handleClose = () => {
    reset();
    setShowSecondaryPopup(false);
  };

  const [
    watchCollateralType,
    watchValueGuide,
    watchyear,
    watchmake,
    watchModel,
    watchSubModel,
    watchlookupType,
    watchStyle,
  ] = useWatch({
    control,
    name: [
      'collateralType',
      'valueGuide',
      'year',
      'make',
      'model',
      'subModel',
      'lookuptype',
      'style',
      'vin',
      'state',
      'mileage',
    ],
  });
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="lookup-title"
      aria-describedby="general-info"
      open={showSecondaryPopup}
      sx={{ minHeight: '80%' }}
    >
      {loader && <Loader open={loader} />}
      <Box display="flex" gap={1}>
        <Button
          aria-label="back-button"
          data-testid="back"
          onClick={handleBack}
          sx={{
            cursor: 'pointer',
            padding: 0,
            height: 'auto',
            width: 'auto',
            minWidth: 'auto',
            minHeight: 'auto',
            position: 'absolute',
            top: '20px',
            left: '20px',
            color: 'black',
          }}
        >
          <KeyboardBackspaceIcon />
        </Button>
        <BootstrapDialogTitle width="100%" id="lookup2-dialog-title" onClose={handleClose}>
          <Typography variant="h3" id="lookup-title" ml={5}>
            Lookup
          </Typography>

          <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
        </BootstrapDialogTitle>
      </Box>
      <DialogContent>
        <Typography
          variant="h5"
          sx={{ fontWeight: 700 }}
          id="general-info"
        >{`${nameMapping[watchValueGuide]} - ${nameMapping[watchCollateralType]}`}</Typography>
        <Box display="flex" flexDirection="column" my={2.5} px={5}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 700 }}
            color="primary"
            id="general-info-desc"
          >
            General information
          </Typography>
          <Divider sx={{ my: 2 }} />
          {watchlookupType === 'manual' && (
            <Grid container item spacing={1}>
              <Grid container gap={0.3} justifyContent="space-between">
                <Grid item md={5.7} xs={12}>
                  <CustomSelectBox
                    flexDirection="column"
                    required
                    label="Year"
                    isDisabled={!yearList.length}
                    control={control}
                    name="year"
                    errors={!!errors?.year}
                    errorText={errors?.year?.message}
                    options={yearList}
                  />
                </Grid>
                <Grid item md={5.7} xs={12}>
                  <CustomSelectBox
                    flexDirection="column"
                    required
                    label="Make"
                    isDisabled={!watchyear}
                    control={control}
                    name="make"
                    options={makeList}
                  />
                </Grid>
                <Grid item md={5.7} xs={12}>
                  <CustomSelectBox
                    flexDirection="column"
                    required
                    label="Model"
                    isDisabled={!watchmake}
                    control={control}
                    name="model"
                    options={modelList}
                  />
                </Grid>
                <Grid item md={5.7} xs={12}>
                  <CustomSelectBox
                    flexDirection="column"
                    required
                    label="Sub model"
                    control={control}
                    name="subModel"
                    options={subModelList}
                    isDisabled={!watchModel}
                  />
                </Grid>
                {watchValueGuide === 'Distributor Data' && (
                  <Grid item md={5.7} xs={12}>
                    <CustomInputBox
                      flexDirection="column"
                      required
                      label="Mileage"
                      name="mileage"
                      maxLength="10"
                      regex={/^\d$/}
                      control={control}
                      errors={!!errors?.mileage}
                      errorText={errors?.mileage?.message}
                    />
                  </Grid>
                )}
              </Grid>
              {watchValueGuide !== 'Distributor Data' && (
                <Grid container gap={0.3} justifyContent="space-between">
                  <Grid item md={5.7} xs={12}>
                    <CustomSelectBox
                      flexDirection="column"
                      required
                      label="Trim"
                      control={control}
                      name="style"
                      options={styleList}
                      isDisabled={!watchSubModel}
                    />
                  </Grid>
                  <Grid item md={5.7} xs={12}>
                    <CustomInputBox
                      flexDirection="column"
                      required
                      label="Mileage"
                      name="mileage"
                      maxLength="10"
                      regex={/^\d$/}
                      control={control}
                      errors={!!errors?.mileage}
                      errorText={errors?.mileage?.message}
                      isDisabled={!watchStyle}
                    />
                  </Grid>
                  {watchCollateralType === 'used' && (
                    <Grid item md={5.7} xs={12}>
                      <CustomSelectBox
                        flexDirection="column"
                        required
                        label="State"
                        name="state"
                        control={control}
                        options={lookupStateList}
                        defaultValue="PR"
                        error={!!errors?.state}
                        errorText={errors?.state?.message}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', gap: '20px' }}>
        <Button onClick={handleClose} variant="outlined" id="lookup-cancel-btn" color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onNext)}
          disabled={!isValid}
          variant="contained"
          color="secondary"
          id="lookup-continue-btn"
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};
LookupSecondDialog.propTypes = {
  errors: PropTypes.instanceOf(Object),
  control: PropTypes.instanceOf(Object),
  isValid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onNext: PropTypes.func,
  reset: PropTypes.func,
  clearErrors: PropTypes.func,
  loader: PropTypes.bool,
};
LookupSecondDialog.defaultProps = {
  control: {},
  errors: {},
  isValid: false,
  handleSubmit: defaultFn,
  onNext: defaultFn,
  reset: defaultFn,
  clearErrors: defaultFn,
  loader: false,
};

export default LookupSecondDialog;
