import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import TriangleIcon from 'assets/svg/Icon-triangle.svg';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn } from 'utils/utility';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';
import Loader from 'components/Loader/Loader';

const EditSubmittedPopup = ({ open, setOpen, handleEdit, appID, setApiErr, userID, userName }) => {
  const ApiClient = new ApiClass();
  const handleClose = () => {
    setOpen(false);
  };

  const [loader, setLoader] = useState(false);
  const handleLock = async () => {
    try {
      setLoader(true);
      let resp = await ApiClient.get(
        `${UrlHelpers.getApplicationDetails}?applicationId=${appID}&isLockRequired=true`,
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: userName },
        }
      );
      if (resp) {
        handleEdit();
      }
    } catch (e) {
      console.log('e', e.response.data);
      if (
        e.response.status === 500 &&
        e.response.data?.includes('The application is currently being accessed')
      ) {
        setApiErr(e.response.data);
        setOpen(false);
      }
    } finally {
      setLoader(false);
    }
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{ sx: { padding: '20px', gap: '20px' } }}
    >
      <Loader open={loader} />
      <BootstrapDialogTitle onClose={handleClose} data-testid="discardClose" sx={{ padding: '0' }}>
        <Box display="flex" gap={1} pb={2} borderBottom="1px solid #E37617">
          <Typography variant="h3">Alert message</Typography>
        </Box>
      </BootstrapDialogTitle>
      <DialogContent sx={{ padding: '0' }}>
        <Box display="flex" gap={2.5}>
          <img src={TriangleIcon} width="48px" height="48px" />
          <Typography variant="subtitle1" color="#000000" fontWeight="700">
            If information related to decisioning is edited, application would be re-evaluated
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClose}
          sx={{ minWidth: '200px' }}
          id="delete-btn"
        >
          No, cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleLock}
          sx={{ minWidth: '200px' }}
          id="cancel-delete-btn"
          data-testid="ok-btn"
        >
          Ok, go ahead
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSubmittedPopup;

EditSubmittedPopup.propTypes = {
  handleEdit: PropTypes.func,
  setOpen: PropTypes.func,
  setApiErr: PropTypes.func,
  open: PropTypes.bool,
  appID: PropTypes.string,
  userID: PropTypes.string,
  userName: PropTypes.string,
};
EditSubmittedPopup.defaultProps = {
  handleEdit: defaultFn,
  setOpen: defaultFn,
  setApiErr: defaultFn,
  open: false,
  appID: '',
  userID: '',
  userName: '',
};
