/* eslint-disable react/prop-types */
import { Box, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import classes from '../../styles/globalStyle.module.scss';

const CustomSelectBox = (props) => {
  const {
    control,
    label,
    name,
    errors,
    isDisabled,
    errorText,
    options,
    defaultValue,
    required,
    flexDirection,
    displayEmpty,
    labelOff,
    isValue,
    valueChange,
    ...rest
  } = props;

  return (
    <>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field: { onChange, ...field } }) => (
          <Box
            display="flex"
            alignItems="center"
            gap={0.625}
            my={0.5}
            flexDirection={flexDirection}
            {...rest}
          >
            {!labelOff && (
              <InputLabel id={`custom-select-${label}`}>
                {required && <span style={{ color: 'red' }}>*&nbsp;</span>}
                {label}
              </InputLabel>
            )}

            <Select
              labelId={`custom-select-${label}`}
              id={`custom-select-${label}-id`}
              defaultValue={defaultValue}
              disabled={isDisabled ? true : false}
              inputProps={{ 'data-testid': name }}
              {...field}
              displayEmpty={displayEmpty}
              onChange={(e) => {
                onChange(e);
                isValue && valueChange(e);
              }}
            >
              {displayEmpty && <MenuItem value="">Select</MenuItem>}

              {options?.map((opt) => {
                const { name: namee, value, label: labell } = opt;
                return (
                  <MenuItem key={namee} value={value ? value : namee}>
                    {namee || labell}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        )}
      />
      {errors && (
        <FormHelperText gap={2} className={classes.customSelectError}>
          {' '}
          {errorText}
        </FormHelperText>
      )}
    </>
  );
};

export default CustomSelectBox;

CustomSelectBox.propTypes = {
  control: PropTypes.instanceOf(Object),
  options: PropTypes.array,
  name: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.bool,
  isDisabled: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  errorText: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  flexDirection: PropTypes.string,
  rest: PropTypes.instanceOf(Object),
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  labelOff: PropTypes.bool,
};
CustomSelectBox.defaultProps = {
  control: {},
  options: [],
  name: '',
  value: '',
  label: '',
  errors: false,
  isDisabled: false,
  errorText: '',
  defaultValue: '',
  required: false,
  displayEmpty: false,
  flexDirection: 'row',
  rest: {},
  inputRef: null,
  labelOff: false,
};
