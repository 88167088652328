import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material';
import ApiClass from 'Api/ApiClient';
import manualLogo from 'assets/svg/manual.svg';
import vinLogo from 'assets/svg/vin.svg';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn, nameMapping } from 'utils/utility';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';
import CustomToggleButtonGroup from 'components/CustomToggleButtonGroup/CustomToggleButtonGroup';
import Loader from 'components/Loader/Loader';

const LookupInterDialog = ({
  control,
  setValue,
  errors,
  onNext,
  isValid,
  reset,
  clearErrors,
  trigger,
  loader: initLoader,
}) => {
  const ApiClient = new ApiClass();
  const {
    showInterPopup,
    setShowMainPopup,
    setShowInterPopup,
    setShowSecondaryPopup,
    lookupStateList,
  } = useContext(AuthContext);
  const [vinDetail, setVinDetail] = useState([]);
  const [loader, setLoader] = useState(false);
  const [err, setErr] = useState('');
  const [showFirstPage, setShowFirstPage] = useState(true);

  const [watchCollateralType, watchValueGuide, watchlookupType, watchVin] = useWatch({
    control,
    name: ['collateralType', 'valueGuide', 'lookuptype', 'vin'],
  });

  useEffect(() => {
    if (watchVin) {
      setErr('');
    }
  }, [watchVin]);

  const handleBack = () => {
    console.log('into back call');
    if (!showFirstPage) {
      setShowFirstPage(true);
    } else {
      setShowInterPopup(false);
      setShowMainPopup(true);
    }
    clearErrors();
  };

  const handleClose = () => {
    reset();
    setShowInterPopup(false);
  };
  const handleContinue = () => {
    setShowInterPopup(false);
    if (watchCollateralType === 'used' && watchlookupType === 'manual') {
      setShowSecondaryPopup(true);
    } else {
      onNext();
    }
  };

  const toggleChange = (e, value) => {
    setValue('lookuptype', value);
  };

  const onVinSubmit = async () => {
    setErr('');
    try {
      setLoader(true);
      const resp = await ApiClient.post(
        `${UrlHelpers.lookUpVin}/${watchCollateralType}/vin`,
        { vin: watchVin },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      const vinDetails = resp?.used_vehicles?.used_vehicle_list;
      if (vinDetails.length > 0) {
        setVinDetail(vinDetails);
        setValue('year', vinDetails[0]?.model_year);
        setValue('make', vinDetails[0]?.make);
        setValue('model', vinDetails[0]?.model);
        setValue('subModel', vinDetails[0]?.series);
        setValue('style', vinDetails[0]?.style);
        setValue('state', 'PR');
        setShowFirstPage(false);
        trigger('year');
      } else {
        setErr('No data found');
      }
    } catch (e) {
      console.log('error fetching', e);
      setErr('No data found');
    } finally {
      setLoader(false);
    }
  };
  console.log({ vinDetail, errors, isValid });

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="lookup-title"
        aria-describedby="general-info"
        open={showInterPopup}
      >
        {(loader || initLoader) && <Loader open={loader || initLoader} />}
        <Box display="flex" gap={1}>
          <Button
            aria-label="back-button"
            data-testid="back"
            onClick={handleBack}
            sx={{
              cursor: 'pointer',
              padding: 0,
              color: 'black',
              height: 'auto',
              width: 'auto',
              minWidth: 'auto',
              minHeight: 'auto',
              position: 'absolute',
              top: '20px',
              left: '20px',
            }}
          >
            <KeyboardBackspaceIcon />
          </Button>
          <BootstrapDialogTitle width="100%" id="lookup-inter-dialog-title" onClose={handleClose}>
            <Typography variant="h3" id="lookup-title" ml={5}>
              Lookup
            </Typography>

            <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
          </BootstrapDialogTitle>
        </Box>
        <DialogContent>
          <Typography
            variant="h5"
            component="h4"
            sx={{ fontWeight: '700' }}
            id="general-info"
          >{`${nameMapping[watchValueGuide]} - ${nameMapping[watchCollateralType]}`}</Typography>
          <Box display="flex" flexDirection="column" gap={2.5} px={25} my={2.5}>
            {showFirstPage && (
              <>
                <Typography variant="h6" sx={{ fontWeight: '700' }}>
                  Lookup type
                </Typography>
                <CustomToggleButtonGroup
                  name="lookuptype"
                  exclusive={true}
                  control={control}
                  toggleChange={toggleChange}
                  toggleList={[
                    { valuee: 'vin', displayValue: 'VIN', logo: vinLogo },
                    { valuee: 'manual', displayValue: 'Manual', logo: manualLogo },
                  ]}
                />
              </>
            )}
          </Box>
          {watchlookupType === 'vin' && (
            <>
              <Box
                display="flex"
                gap={1}
                alignItems={err || !!errors?.vin ? 'center' : 'flex-end'}
                justifyContent="center"
              >
                <Box display="flex" flexDirection="column" width="70%">
                  <CustomInputBox
                    label="VIN"
                    name="vin"
                    control={control}
                    errors={!!errors?.vin || !!err}
                    errorText={errors?.vin?.message || err}
                    classes={classes.vinInput}
                    flexDirection="column"
                    minWidth="60%"
                    maxLength={17}
                    my={0}
                    regex={/^[A-HJ-NPR-Za-hj-npr-z0-9]*$/}
                  />
                </Box>

                <Button
                  onClick={onVinSubmit}
                  variant="contained"
                  color="secondary"
                  float="right"
                  disabled={!watchVin}
                  id="find-vehicle-btn"
                  // disabled={!watchVin || vinDetail.length > 0}
                >
                  Find vehicle
                </Button>
              </Box>

              {!showFirstPage && (
                <>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1.5}
                    justifyContent="center"
                    alignItems="center"
                    mt={2.5}
                  >
                    {!!errors?.year && !err && (
                      <FormHelperText gap={2} className={classes.customSelectError} id="year-error">
                        {' '}
                        {errors?.year?.message}
                      </FormHelperText>
                    )}
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      sx={{
                        minWidth: '80%',
                        borderBottom: '1px solid black',
                        paddingBottom: '8px',
                      }}
                    >
                      Vehicle Information
                    </Typography>
                    <Divider />
                    <Typography
                      variant="subtitle1"
                      sx={{ minWidth: '80%', fontWeight: 700 }}
                      id="vin details"
                    >
                      {`${vinDetail[0]?.model_year} ${vinDetail[0]?.make} ${vinDetail[0]?.model} ${vinDetail[0]?.series} ${vinDetail[0]?.style} `}
                    </Typography>

                    <Box display="flex" flexDirection="column" gap={1.5} sx={{ minWidth: '80%' }}>
                      <Box display="flex">
                        <Typography variant="subtitle2" sx={{ minWidth: '250px' }} id="year">
                          Year
                        </Typography>
                        <Typography variant="subtitle2" color="secondary">
                          {vinDetail[0]?.model_year}
                        </Typography>
                      </Box>
                      <Box display="flex">
                        <Typography variant="subtitle2" sx={{ minWidth: '250px' }} id="brand">
                          Make
                        </Typography>
                        <Typography variant="subtitle2" color="secondary">
                          {vinDetail[0]?.make}
                        </Typography>
                      </Box>
                      <Box display="flex">
                        <Typography variant="subtitle2" sx={{ minWidth: '250px' }} id="model">
                          Model
                        </Typography>
                        <Typography variant="subtitle2" color="secondary">
                          {vinDetail[0]?.model}
                        </Typography>
                      </Box>
                      <Box display="flex">
                        <Typography variant="subtitle2" sx={{ minWidth: '250px' }} id="series">
                          Sub model
                        </Typography>
                        <Typography variant="subtitle2" color="secondary">
                          {vinDetail[0]?.series}
                        </Typography>
                      </Box>
                      <Box display="flex">
                        <Typography variant="subtitle2" sx={{ minWidth: '250px' }} id="style">
                          Trim
                        </Typography>
                        <Typography variant="subtitle2" color="secondary">
                          {vinDetail[0]?.style}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider />
                    <Grid container item spacing={1} justifyContent="center">
                      <Grid item md={5.5} xs={12}>
                        <CustomSelectBox
                          flexDirection="column"
                          label="State"
                          name="state"
                          control={control}
                          options={lookupStateList}
                          defaultValue="PR"
                          errors={!!errors?.state}
                          errorText={errors?.state?.message}
                          minWidth="50%"
                        />
                      </Grid>
                      <Grid item md={5.5} xs={12}>
                        <CustomInputBox
                          flexDirection="column"
                          label="Mileage"
                          name="mileage"
                          control={control}
                          maxLength="10"
                          regex={/^\d$/}
                          errors={!!errors?.mileage}
                          errorText={errors?.mileage?.message}
                          minWidth="50%"
                          required
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
            id="cancel-btn"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            id="continue-btn"
            onClick={handleContinue}
            sx={{ maxHeight: '40px' }}
            disabled={
              !(
                (watchlookupType === 'vin' && vinDetail.length > 0 && isValid) ||
                watchlookupType === 'manual'
              )
            }
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
LookupInterDialog.propTypes = {
  setValue: PropTypes.func,
  control: PropTypes.instanceOf(Object),
  onNext: PropTypes.func,
  errors: PropTypes.instanceOf(Object),
  isValid: PropTypes.bool,
  reset: PropTypes.func,
  clearErrors: PropTypes.func,
  trigger: PropTypes.func,
  loader: PropTypes.bool,
};
LookupInterDialog.defaultProps = {
  control: {},
  setValue: defaultFn,
  errors: {},
  onNext: defaultFn,
  isValid: false,
  reset: defaultFn,
  clearErrors: defaultFn,
  trigger: defaultFn,
  loader: false,
};

export default LookupInterDialog;
