/* eslint-disable no-unused-vars */
import { Box, Paper, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import RegisterUserContext from 'context/RegisterUserContext';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import HorizontalLinearStepper from 'components/Stepper/Stepper';

import UserInformation from './UserInformation/UserInformation';
import UserProfile from './UserProfile/UserProfile';
import UserProfileSubmit from './UserProfileSubmit/UserProfileSubmit';
import UserStatusConfiguration from './UserStatusConfiguration/UserStatusConfiguration';

const RegisterUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(location?.state?.step || 1);
  const [formDetails, setFormDetails] = useState({
    ...location?.state?.eachData['1'],
    ...location?.state?.eachData['2'],
    ...location?.state?.eachData['3'],
    dealers: location?.state?.eachData['3']?.dealerList || [],
  });
  const [eachFormData, setEachFormData] = useState({
    1: location?.state?.eachData['1'] || {},
    2: location?.state?.eachData['2'] || {},
    3: location?.state?.eachData['3'] || {},
  });
  const [dealers, setDealers] = useState([]);
  const [securityProfiles, setSecurityProfiles] = useState([]);
  const [accessTypes, setAccessTypes] = useState([]);
  const [isFormFilled, setIsFormFilled] = useState(
    location?.state?.isViewProfile
      ? { 1: true, 2: true, 3: true }
      : { 1: false, 2: false, 3: false }
  );
  const [isReview, setIsReview] = useState(false);
  const [dealersTableDirty, setDealersTableDirty] = useState(false);
  const [formDirty, setFormDirty] = useState({});
  const [isUserIdValid, setIsUserIdValid] = useState(location?.state?.isViewProfile ? true : null);
  const [completedSteps, setCompletedSteps] = useState([]);
  const handleCancel = () => {
    setIsUserIdValid(null);
    navigate('/view-user-profile', { state: { profileData: location?.state?.profileData } });
  };
  const getStepComponent = {
    1: <UserInformation handleCancel={handleCancel} />,
    2: <UserStatusConfiguration handleCancel={handleCancel} />,
    3: <UserProfile handleCancel={handleCancel} />,
    4: <UserProfileSubmit handleCancel={handleCancel} />,
  };

  const steps = [
    'User information',
    'User status and configuration',
    'User profile',
    'Review & submit',
  ];
  const ApiClient = new ApiClass();

  const getDealers = async () => {
    try {
      return await ApiClient.get(`${UrlHelpers.dealers}?status=1`);
    } catch (e) {
      console.log('e', e);
    }
  };
  const getSecurityProfiles = async () => {
    try {
      return JSON.parse((await ApiClient.get(UrlHelpers.securityProfiles))['security-profile']);
    } catch (e) {
      console.log('e', e);
    }
  };
  const getAccessTypes = async () => {
    try {
      return JSON.parse((await ApiClient.get(UrlHelpers.accessTypes))['access-type']);
    } catch (e) {
      console.log('e', e);
    }
  };
  useEffect(async () => {
    setDealers(await getDealers());
    setAccessTypes(await getAccessTypes());
    setSecurityProfiles(await getSecurityProfiles());
  }, []);

  const regContextValue = useMemo(
    () => ({
      activeStep,
      setActiveStep,
      formDetails,
      setFormDetails,
      eachFormData,
      setEachFormData,
      isFormFilled,
      setIsFormFilled,
      dealers,
      securityProfiles,
      accessTypes,
      isReview,
      setIsReview,
      isUserIdValid,
      setIsUserIdValid,
      isViewProfile: location?.state?.isViewProfile,
      fullProfileData: location?.state?.data,
      dealersTableDirty,
      setDealersTableDirty,
      formDirty,
      setFormDirty,
      completedSteps,
      setCompletedSteps,
    }),
    [
      activeStep,
      setActiveStep,
      formDetails,
      setFormDetails,
      eachFormData,
      setEachFormData,
      isFormFilled,
      setIsFormFilled,
      dealers,
      securityProfiles,
      accessTypes,
      isReview,
      setIsReview,
      isUserIdValid,
      setIsUserIdValid,
      location,
      dealersTableDirty,
      setDealersTableDirty,
      formDirty,
      setFormDirty,
      completedSteps,
      setCompletedSteps,
    ]
  );
  console.log('completedSteps', completedSteps);
  return (
    <RegisterUserContext.Provider value={regContextValue}>
      <>
        <Box py={3} px={2} display="flex" justifyContent="space-between">
          <Typography variant="h3">
            {!location?.state?.isViewProfile ? 'Add user' : 'Edit User'}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Box sx={{ width: '70%' }}>
            <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
              {!location?.state?.isViewProfile && (
                <Typography variant="h4" component="p" py={5} textAlign="center">
                  Alright! Let’s create user profile in just 4 steps
                </Typography>
              )}
              <HorizontalLinearStepper
                steps={steps}
                activeStep={activeStep - 1}
                completedSteps={completedSteps}
              />
              {getStepComponent[activeStep]}
            </Paper>
          </Box>
        </Box>
      </>
    </RegisterUserContext.Provider>
  );
};

export default RegisterUser;
