import { Box, Paper, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import AuthContext from 'context/AuthContext';
import UserRequestDashboard from 'pages/Home/UserRequestDashboard';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';
import { bankerRoles } from 'utils/utility';

import ErrorAlert from 'components/ErrorAlert';
import TabPanel from 'components/TabPanel/TabPanel';

import { Applications } from './Applications';
import Drafts from './Drafts';
import { MyApplications } from './MyApplications';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ApplicationDashboard = () => {
  const { role } = useContext(AuthContext);
  const location = useLocation();
  const [tabVal, setTabValue] = useState(0);
  const [apiErr, setApiErr] = useState('');
  const [notifyAppId, setNotifyAppId] = useState('');
  const {
    viewUserRequest,
    applicationDetails,
    tabValue = 0,
    notifyAppIdState,
  } = location?.state || {};

  const roleCheck = role && role !== 'SALES REPRESENTATIVE' && role !== 'SALES REPRESENTATIVE 2';
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setNotifyAppId('');
  };
  useEffect(() => {
    setTabValue(tabValue);
    setNotifyAppId(notifyAppIdState);
  }, [location?.state]);
  console.log('applicationDetails', applicationDetails);

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Box sx={{ width: '98%' }}>
          <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
            <Box display="flex" flexDirection="column" className={classes.dummyHome} gap={2}>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="h3">Applications</Typography>
              </Box>
              <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
              <Box>
                <Box>
                  <Tabs value={tabVal} onChange={handleTabChange} aria-label="applications-tabs">
                    <Tab label="My loan applications" {...a11yProps(0)} />
                    <Tab
                      label={
                        role === bankerRoles?.master || role === bankerRoles?.employee || !roleCheck
                          ? 'My loan drafts'
                          : 'Loan drafts'
                      }
                      data-testid="drafts"
                      {...a11yProps(1)}
                    />
                    {roleCheck && (
                      <Tab
                        data-testid="applications"
                        label="All loan applications"
                        {...a11yProps(2)}
                      />
                    )}
                    {roleCheck && (
                      <Tab data-testid="userRequest" label="User request" {...a11yProps(2)} />
                    )}
                  </Tabs>
                </Box>
                <TabPanel value={tabVal} index={0}>
                  <MyApplications setApiErr={setApiErr} />
                </TabPanel>
                <TabPanel value={tabVal} index={1}>
                  <Drafts notifyAppId={notifyAppId} setApiErr={setApiErr} />
                </TabPanel>
                <TabPanel value={tabVal} index={2}>
                  <Applications />
                </TabPanel>
                {roleCheck && (
                  <TabPanel value={tabVal} index={3}>
                    <UserRequestDashboard
                      viewAll={true}
                      viewApprovalQueue={viewUserRequest}
                      route="applications"
                    />
                  </TabPanel>
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};
