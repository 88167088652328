/* eslint-disable no-unused-vars */
import MainApplicationContext from 'context/MainApplicationContext';
import { useContext } from 'react';

import Loader from 'components/Loader/Loader';

import CollateralInformation from './CollateralInformation/CollateralInformation';
import EmploymentInfoTabView from './EmploymentInformation/EmploymentInfoTabView';
import FinancialTerms from './FinancialTerms/FinancialTerms';
import InfoTabView from './InformationRequest/InfoReqTabView';
import ReviewAndSubmit from './ReviewAndSubmit/ReviewAndSubmit';

const RenderStep = () => {
  const { activeStep } = useContext(MainApplicationContext);
  switch (activeStep) {
    case 1: {
      return <InfoTabView />;
    }
    case 2: {
      return <EmploymentInfoTabView />;
    }
    case 3: {
      return <CollateralInformation />;
    }
    case 4: {
      return <FinancialTerms />;
    }
    case 5: {
      return <ReviewAndSubmit />;
    }

    default: {
      return <Loader open />;
    }
  }
};

export default RenderStep;
