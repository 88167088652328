import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import AgreementIcon from 'assets/svg/AgreementIcon.svg';
import AmountBabSubIcon from 'assets/svg/AmountBagSubIcon.svg';
import ApprovedSubIcon from 'assets/svg/ApprovedSubIcon.svg';
import DeclinedSubIcon from 'assets/svg/DeclinedSubIcon.svg';
import Dollar from 'assets/svg/Dollar.svg';
import Receipt from 'assets/svg/Receipt.svg';
import ThumbsUpIcon from 'assets/svg/ThumbsUpIcon.svg';
import Timer from 'assets/svg/Timer.svg';
import TimerSubIcon from 'assets/svg/TimerSubIcon.svg';
import TotalApplication from 'assets/svg/TotalApplication.svg';
import TotalApplicationSubIcon from 'assets/svg/TotalApplicationSubIcon.svg';
import WithdrawSubIcon from 'assets/svg/WithdrawSubIcon.svg';
import contact from 'assets/svg/contact.svg';
import moneyBag from 'assets/svg/moneyBag.svg';
import multipleFiles from 'assets/svg/multipleFiles.svg';
import success from 'assets/svg/success.svg';
import PropTypes from 'prop-types';
import classes from 'styles/KPIview.module.scss';

const KpiView = ({ kpiResponse }) => {
  const mockHomeCard = [
    {
      heading: 'Total applications',
      number: '0',
      key: 'totalApplicationsCount',
      icon: TotalApplication,
      subIcon: TotalApplicationSubIcon,
    },
    {
      heading: 'Approved',
      number: '0',
      key: 'totalApprovedCount',
      icon: Receipt,
      subIcon: ApprovedSubIcon,
    },
    {
      heading: 'Declined',
      number: '0',
      key: 'totalDeclinedCount',
      icon: Dollar,
      subIcon: DeclinedSubIcon,
    },
    {
      heading: 'Decision pending',
      number: '0',
      key: 'totalDecisionPendingCount',
      icon: Timer,
      subIcon: TimerSubIcon,
    },
    {
      heading: 'Withdrawn',
      number: '0',
      key: 'totalWithdrawnCount',
      icon: multipleFiles,
      subIcon: WithdrawSubIcon,
    },
    {
      heading: 'Contract verification',
      number: '0',
      key: 'totalContractVerificationAmount',
      icon: contact,
      subIcon: AgreementIcon,
    },
    {
      heading: 'Booked applications',
      number: '0',
      key: 'totalBookedCount',
      icon: success,
      subIcon: ThumbsUpIcon,
    },
    {
      heading: 'Amount booked',
      number: '0',
      key: 'totalBookedAmount',
      icon: moneyBag,
      subIcon: AmountBabSubIcon,
    },
  ];

  const updatedData = mockHomeCard?.map((x) => {
    const newNum = kpiResponse?.[x.key];
    return { ...x, number: newNum };
  });
  return (
    <Grid container className={classes.customCard} columnSpacing={2}>
      {updatedData.map((item) => {
        return (
          <Grid item lg={3} md={6} xs={6} key={item.key}>
            <Card className={classes.cardContainer}>
              <CardContent className={classes.cardContent}>
                <Typography
                  className={classes.cardHeading}
                  color="text.primary"
                  sx={{ display: 'flex' }}
                  variant="subtitle1"
                >
                  {item.heading} &nbsp; {item?.subIcon && <img src={item.subIcon} />}
                </Typography>
                <Box className={classes.cardNumberIcon}>
                  <Typography variant="h1" py={2} component="p" className={classes.cardNumber}>
                    {item?.key === 'totalContractVerificationAmount' ||
                    item?.key === 'totalBookedAmount'
                      ? '$ '
                      : ''}
                    {item.number}
                  </Typography>
                  <img src={item.icon} alt={`${item.heading} Icon`} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default KpiView;

KpiView.propTypes = {
  kpiResponse: PropTypes.instanceOf(Object),
};
KpiView.defaultProps = {
  kpiResponse: {},
};
