/* eslint-disable react/prop-types */
import { Box, Tab, Tabs } from '@mui/material';
import AuthContext from 'context/AuthContext';
import { useContext, useEffect, useState } from 'react';

import TabPanel from 'components/TabPanel/TabPanel';

import GeneratedDocuments from '../GeneratedDocuments';
import DocumentsAttached from './DocumentsAttached';

const ContractDocumentsSecond = ({ docMasterList, appId, initGeneratedDocs, getInfoReq }) => {
  const [value, setValue] = useState(0);
  const [wholeDocs, setWholeDocs] = useState([]);
  const { generatedDoc = [] } = useContext(AuthContext);
  useEffect(() => {
    setWholeDocs([...initGeneratedDocs, ...generatedDoc]);
  }, [initGeneratedDocs, generatedDoc]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `contract-docs-tab-${index}`,
      'aria-controls': `contract-docs-tabpanel-${index}`,
    };
  }
  return (
    <Box display="flex" sx={{ padding: '10px' }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          marginTop: '16px',
          borderColor: 'divider',
          width: '15%',
          '.MuiTabs-indicator': {
            right: 'unset',
          },
          '.MuiTab-root.Mui-selected': {
            borderRight: 'none',
            background: 'none',
          },
          '.MuiTab-root': {
            background:
              'var(--borders-and-background-gradient, linear-gradient(180deg, #FFF 0%, #F3F3F3 100%))',
            border: '1px solid #DADADA',
            borderColor: 'rgba(0, 0, 0, 0.12)',
          },
          '.MuiButtonBase-root ': {
            maxWidth: 'unset',
            width: '100%',
            textAlign: 'left',
          },
        }}
      >
        <Tab label="Generated documents" {...a11yProps(0)} />
        <Tab label="Documents attached" data-testid="account-det" {...a11yProps(1)} />
      </Tabs>
      <TabPanel
        value={value}
        index={0}
        isVertical
        sx={{ border: '1px solid #DADADA', borderColor: 'rgba(0, 0, 0, 0.12)', width: '85%' }}
      >
        <GeneratedDocuments docMasterList={docMasterList} wholeDocs={wholeDocs} />
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        isVertical
        sx={{ border: '1px solid #DADADA', borderColor: 'rgba(0, 0, 0, 0.12)', width: '85%' }}
      >
        <DocumentsAttached
          docMasterList={docMasterList}
          appId={appId}
          getInfoReq={getInfoReq}
          wholeDocs={wholeDocs}
        />
      </TabPanel>
    </Box>
  );
};

export default ContractDocumentsSecond;
