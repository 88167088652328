import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Alert,
  Box,
  Button,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import moment from 'moment/moment';
import {
  TableCellTypography,
  TableHeaderTypography,
} from 'pages/ApplicationDashboard/ApplicationsTable';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import manageClasses from 'styles/ManageDealers.module.scss';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import CustomSwitch from 'components/CustomSwitch/CustomSwitch';
import Loader from 'components/Loader/Loader';

import TablePagination from './TablePagination';

const UserProfileList = ({ profileSubmitted, tabChange }) => {
  const [alertClose, setAlertClose] = useState(true);
  const navigate = useNavigate();
  const [searchOption, setSearchOption] = useState('');
  const [searchText, setSearchText] = useState('');
  const { accessToken, role } = useContext(AuthContext);
  const rowsPerPage = 10;
  const ApiClient = new ApiClass();
  const [userList, setUserList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSwitchActive, setIsSwitchActive] = useState(false);
  const [page, setPage] = useState(1);
  const emptyDataMessage = 'No user/s available';
  // Change page
  const paginate = (_, pageNumber) => {
    setPage(pageNumber);
  };
  console.log(tabChange);

  useEffect(() => {
    if (tabChange === 'Changed') {
      setAlertClose(false);
    }
  }, [tabChange]);

  const indexOfLastPost = page * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const calcUserList = (userArr) => {
    return userArr.slice(indexOfFirstPost, indexOfLastPost);
  };

  const handleSeachOption = (e) => {
    setSearchOption(e.target.value);
  };
  const searchTermChange = (e) => {
    setSearchText(e.target.value);
  };
  const clearSearch = async () => {
    console.log('first----');
    setSearchOption('');
    setSearchText('');
    setUserList(await getUserList());
  };

  const searchUserBy = async ({ param, text }) => {
    try {
      setLoading(true);
      return (await ApiClient.get(`${UrlHelpers.searchUserBy}?${param}=${text}`))?.result[0]
        .userList;
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };
  const getUserList = async () => {
    setLoading(true);
    try {
      return (
        await ApiClient.post(
          UrlHelpers.fetchUserList,
          {},
          {
            headers: {
              appid: 'auto',
              // 'x-api-key': process.env.REACT_APP_X_API_KEY,
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + accessToken,
              RoleID: role,
            },
          }
        )
      )?.result[0]?.userList;
    } catch (e) {
      console.log('/........::::::::::::::::::::::::::::...');

      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };
  const search = async () => {
    let userListSearch = [];
    if (searchText) {
      setPage(1);

      setIsSwitchActive(false);
      userListSearch = await searchUserBy({ param: searchOption, text: searchText });
    } else if (isSwitchActive) {
      userListSearch = await searchUserBy({ param: 'status', text: 'Enabled' });
    } else {
      userListSearch = await getUserList();
    }
    setUserList(userListSearch);
  };

  const activeChange = async (e) => {
    setPage(1);
    let userActiveChangeList = [];
    setIsSwitchActive(e.target.checked);
    if (e.target.checked) {
      setSearchOption('');
      setSearchText('');
      userActiveChangeList = await searchUserBy({ param: 'status', text: 'Enabled' });
    } else {
      userActiveChangeList = await getUserList();
    }
    setUserList(userActiveChangeList);
  };
  useEffect(async () => {
    setUserList(await getUserList());
  }, []);

  return (
    <>
      {profileSubmitted === 'success' && alertClose && (
        <Alert
          severity={'success'}
          onClose={() => {
            setAlertClose(false);
          }}
          sx={{ height: '45px', width: '750px' }}
        >
          User profile has been submitted successfully. Profile activation might take 24 hours to be
          activated.
        </Alert>
      )}
      <Box
        display="flex"
        flexDirection="column"
        className={classes.childBorderLine}
        mt={1}
        sx={{
          width: '100%',
          border: '1px solid rgb(224 224 224)',
          borderRadius: '2px',
        }}
      >
        <Box sx={{ padding: '15px' }}>
          {userList ? (
            <TableCellTypography>
              {`Showing ${indexOfFirstPost + 1} to ${
                userList?.length > indexOfLastPost ? indexOfLastPost : userList?.length
              } of ${userList?.length || 0} entries`}
            </TableCellTypography>
          ) : (
            <>{'Showing 0 entries'}</>
          )}
        </Box>
        <Box
          sx={{ marginLeft: '10px', padding: '10px' }}
          display={'flex'}
          flexDirection={'row'}
          data-testid="user-table"
        >
          <Box display={'flex'} flexDirection={'column'} justifyContent={'space-around'}>
            <FilterListIcon />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-around'}
            sx={{ marginLeft: '10px' }}
          >
            <Typography>Active</Typography>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-around'}
            sx={{ marginLeft: '10px' }}
          >
            <CustomSwitch onChange={activeChange} checked={isSwitchActive} />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-around'}
            sx={{ marginLeft: '10px' }}
          >
            <Select
              id={'select'}
              defaultValue={''}
              inputProps={{ 'data-testid': 'searchOption' }}
              // {...field}
              value={searchOption}
              displayEmpty={true}
              sx={{ width: '200px', marginLeft: '10px', height: '30px' }}
              onChange={handleSeachOption}
            >
              <MenuItem value="">Select option</MenuItem>

              {[
                { name: 'User ID', value: 'userId' },
                { name: 'First name', value: 'firstName' },
                { name: 'Last name', value: 'lastName' },
              ].map((opt) => {
                const { name, value } = opt;
                return (
                  <MenuItem key={value} value={value || name}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-around'}
            sx={{ marginLeft: '10px' }}
          >
            <div className="search">
              <input
                placeholder="Enter your text"
                className={manageClasses.inputStyle}
                onChange={searchTermChange}
                data-testid="searchTerm"
                value={searchText}
                disabled={!searchOption}
              />
            </div>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-around'}
            sx={{ marginLeft: '10px' }}
          >
            <Button
              sx={{ width: '60px', height: '30px' }}
              color="secondary"
              variant="contained"
              size="small"
              // disabled={!searchText}
              onClick={search}
            >
              Search
            </Button>
          </Box>
          {searchText && (
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-around'}
              sx={{ marginLeft: '10px' }}
            >
              <Typography variant="subtitle2" style={{ cursor: 'pointer' }} onClick={clearSearch}>
                {' '}
                ✖ Clear
              </Typography>
            </Box>
          )}
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {/* <TableHead> */}
            <TableRow sx={{ backgroundColor: '#F2F2F2' }}>
              <TableCell sx={{ fontWeight: 'bold' }}>
                <TableHeaderTypography>User ID</TableHeaderTypography>
              </TableCell>
              {/* TableHeaderTypography */}
              <TableCell sx={{ fontWeight: 'bold' }}>
                <TableHeaderTypography>First name</TableHeaderTypography>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>
                <TableHeaderTypography>Last name</TableHeaderTypography>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>
                <TableHeaderTypography>User setup date</TableHeaderTypography>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>
                <TableHeaderTypography>Active</TableHeaderTypography>
              </TableCell>
            </TableRow>
            <TableBody>
              {/* </TableHead> */}
              {userList ? (
                <>
                  {calcUserList(userList).map((row) => (
                    <TableRow
                      key={`${row.userId}_${row.createdDate}`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <TableCellTypography>
                          <Link
                            component="button"
                            variant="subtitle2"
                            sx={{ color: '#0065CC', textDecoration: 'initial' }}
                            onClick={() => {
                              navigate('/view-user-profile', { state: { profileData: row } });
                            }}
                          >
                            {row.userId}
                          </Link>
                        </TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>{row.firstName}</TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>{row.lastName}</TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>
                          {moment(row.createdDate).format('MM/DD/YYYY')}
                        </TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>{row.active ? 'Yes' : 'No'}</TableCellTypography>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={12} sx={{ fontSiez: '14px !important', textAlign: 'center' }}>
                    {emptyDataMessage}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ margin: '15px' }}>
        <TablePagination
          postsPerPage={10}
          totalPosts={userList?.length || 0}
          paginate={paginate}
          boundaryCount={0}
          page={page}
        />
      </Box>
      <Loader open={loading} />
    </>
  );
};

export default UserProfileList;

UserProfileList.propTypes = {
  profileSubmitted: PropTypes.bool,
  tabChange: PropTypes.string,
};

UserProfileList.defaultProps = {
  profileSubmitted: false,
  tabChange: '',
};
