/* eslint-disable react/prop-types */
import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Print from 'assets/svg/Print.svg';
import PreviewIcon from 'assets/svg/filePreview.svg';
import { useState } from 'react';

import PdfViewer from 'components/PdfViewer';

const GeneratedDocuments = ({ docMasterList, wholeDocs }) => {
  const TableHeading = ['Document', 'Document type', 'Status', 'Action'];
  const [preview, setPreview] = useState(false);
  const [previewurl, setPreviewUrl] = useState('');

  return (
    <>
      {preview && (
        <PdfViewer
          fileData={previewurl}
          handleClose={() => {
            setPreview(false);
          }}
          open={preview}
        />
      )}
      <Typography variant="h6" fontWeight="700">
        Generated documents
      </Typography>
      <Box sx={{ overflowX: 'auto' }}>
        <Table
          aria-label="contract-documents-table"
          sx={{ border: '1px solid #F2F2F2', marginTop: '16px' }}
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: '#F2F2F2' }}>
              {TableHeading?.map((x) => {
                return (
                  <TableCell
                    key={x}
                    sx={{
                      fontWeight: 700,
                      fontSize: '14px !important',
                    }}
                  >
                    {x}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {docMasterList
              .filter((doc) => doc?.visible)
              .map((eachDoc) => {
                const { displayName, documentType, id } = eachDoc;
                const generatedDocc = wholeDocs?.find((x) => x?.id === id);

                return (
                  <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ fontSize: '14px !important' }}>{displayName}</TableCell>
                    <TableCell sx={{ fontSize: '14px !important' }}>{documentType}</TableCell>
                    <TableCell sx={{ fontSize: '14px !important' }}>
                      {generatedDocc?.uri ? 'Available' : ''}
                    </TableCell>

                    <TableCell sx={{ fontSize: '14px !important' }}>
                      {generatedDocc?.uri && (
                        <Typography variant="subtitle2" display="flex" gap={0.5}>
                          <Link
                            color="secondary"
                            display="flex"
                            data-testid="deleteApplication"
                            sx={{ cursor: 'pointer', alignItems: 'center' }}
                            onClick={() => {
                              window.open(generatedDocc?.uri);
                            }}
                          >
                            <img src={Print} style={{ textDecoration: 'none' }} />
                            &nbsp;
                            <Typography variant="subtitle2" component="p">
                              Print
                            </Typography>
                          </Link>
                          &nbsp; &nbsp;
                          <Link
                            color="secondary"
                            display="flex"
                            sx={{ cursor: 'pointer', alignItems: 'center' }}
                            onClick={() => {
                              setPreviewUrl(generatedDocc?.uri);
                              setPreview(true);
                            }}
                          >
                            <img src={PreviewIcon} style={{ textDecoration: 'none' }} />
                            &nbsp;
                            <Typography variant="subtitle2" component="p">
                              Preview{' '}
                            </Typography>
                          </Link>
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
export default GeneratedDocuments;
