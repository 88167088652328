import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import classes from 'styles/globalStyle.module.scss';
import { numberToCurrency } from 'utils/utility';

const DealerFundingInformation = ({ info }) => {
  return (
    <Box className={classes.tableOutline} sx={{ marginTop: '20px' }}>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ backgroundColor: '#F2F2F2', borderBottom: '1px solid #DADADA' }}
        p={2}
      >
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
            Dealer funding information
          </Typography>
        </Box>
      </Box>
      <Grid container>
        <Grid item lg={3} md={3} xs={5} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
          <Typography variant="subtitle2">Booked date</Typography>
        </Grid>
        <Grid item lg={9} md={9} xs={7} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
          <Typography variant="subtitle2">
            {info?.bookedDate ? moment(info?.bookedDate).format('MM/DD/yyyy') : '--'}
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} xs={5} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
          <Typography variant="subtitle2">Contract date</Typography>
        </Grid>
        <Grid item lg={9} md={9} xs={7} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
          <Typography variant="subtitle2">
            {info?.contractDate ? moment(info?.contractDate).format('MM/DD/yyyy') : '--'}
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} xs={5} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
          <Typography variant="subtitle2">Contract rate</Typography>
        </Grid>
        <Grid item lg={9} md={9} xs={7} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
          <Typography variant="subtitle2">
            {info?.contractRate ? `${info?.contractRate}%` : '--'}
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} xs={5} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
          <Typography variant="subtitle2">Contract payment</Typography>
        </Grid>
        <Grid item lg={9} md={9} xs={7} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
          <Typography variant="subtitle2">{numberToCurrency(info?.contractPayment)}</Typography>
        </Grid>
        <Grid item lg={3} md={3} xs={5} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
          <Typography variant="subtitle2">Booked user</Typography>
        </Grid>
        <Grid item lg={9} md={9} xs={7} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
          <Typography variant="subtitle2">{info?.bookedUser}</Typography>
        </Grid>
        <Grid item lg={3} md={3} xs={5} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
          <Typography variant="subtitle2">Contract amount</Typography>
        </Grid>
        <Grid item lg={9} md={9} xs={7} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
          <Typography variant="subtitle2">{numberToCurrency(info?.contractAmount)}</Typography>
        </Grid>
        <Grid item lg={3} md={3} xs={5} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
          <Typography variant="subtitle2">Contract term</Typography>
        </Grid>
        <Grid item lg={9} md={9} xs={7} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
          <Typography variant="subtitle2">
            {info?.contractTerm ? +info?.contractTerm : '--'}
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} xs={5} p={2}>
          <Typography variant="subtitle2">First payment date</Typography>
        </Grid>
        <Grid item lg={9} md={9} xs={7} p={2}>
          <Typography variant="subtitle2">
            {info?.firstPaymentDate ? moment(info?.firstPaymentDate).format('MM/DD/yyyy') : '--'}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

DealerFundingInformation.propTypes = {
  info: PropTypes.instanceOf(Object),
};
DealerFundingInformation.defaultProps = {
  info: {},
};

export default DealerFundingInformation;
