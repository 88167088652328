import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CircleIcon = (valuee, fieldValue) => {
  if (fieldValue === valuee || fieldValue?.includes(valuee)) {
    return (
      <CheckCircleIcon
        color="primary"
        sx={{ alignSelf: 'flex-start', height: '15px', width: '15px' }}
      />
    );
  }
};

export default CircleIcon;
