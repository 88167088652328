import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TriangleIcon from 'assets/svg/Icon-triangle.svg';
import PropTypes from 'prop-types';
import { defaultFn } from 'utils/utility';

const UserLockPopup = ({ userLockPopup, setUserLockPopup, setOpenInviPopup }) => {
  const handleClose = () => {
    setUserLockPopup(false);
  };
  const handleContinue = () => {
    setOpenInviPopup(true);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={userLockPopup}
      PaperProps={{ sx: { padding: '20px', gap: '20px' } }}
    >
      <Box pb={1.5}>
        <IconButton
          aria-label="close"
          data-testid="closebtn"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ padding: '0' }}>
        <Box display="flex" gap={2.5}>
          <img src={TriangleIcon} width="48px" height="48px" />
          <Typography variant="subtitle1" color="#000000" fontWeight="700">
            The invitation for applicant/s is in progress. Do you wish to continue?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
          data-testid="cancel-btn"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleContinue}
          data-testid="continue-btn"
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserLockPopup;

UserLockPopup.propTypes = {
  userLockPopup: PropTypes.bool,
  setUserLockPopup: PropTypes.func,
  setOpenInviPopup: PropTypes.func,
  handleNavigate: PropTypes.func,
};
UserLockPopup.defaultProps = {
  userLockPopup: false,
  setUserLockPopup: defaultFn,
  setOpenInviPopup: defaultFn,
  handleNavigate: defaultFn,
};
