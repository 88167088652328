import { Box, Paper, Typography } from '@mui/material';
import MainApplicationContext from 'context/MainApplicationContext';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';

import Loader from 'components/Loader/Loader';
import HorizontalLinearStepper from 'components/Stepper/Stepper';

import ApplicationApproved from './ApplicationApproved';
import ApplicationSubmitted from './ApplicationSubmitted';
import RenderStep from './RenderStep';
import SaveAsDraftDialog from './SaveAsDraft/SaveAsDraftDialog';

const CreateApplicationBase = () => {
  const location = useLocation();

  let {
    scannedData = {},
    primaryApplicant: primaryApplicantState = {},
    coApplicant: coApplicantState = {},
    coSigner: coSignerState = [],
    reviewFlag: reviewFlagState = false,
    activeStep: activeStepState = 0,
    calculationState: calculationsState = '',
    completedSteps: completedStepsState = [],
    userType: userTypeState,
    vouchedFlag = false,
    userDetails: userDetailsState = [],
    formFields: formFieldsState = [],
    tabvalue,
    savedApplicantIds: savedApplicantIdsVouched,
    initApplicantIds: initApplicantIdsVouched,
    draftFlag: draftFlagState,
    viewAppFlow: viewAppFlowState,
    idVerificationPendingText: idVerificationPendingState,
    unfilled: unfilledState = false,
    viewAppLoanStatus: viewAppLoanStatusState,
  } = location?.state || {};

  const [activeStep, setActiveStep] = useState(activeStepState);
  const [vouchedData, setVouchedData] = useState(scannedData);
  const [calculationState, setCalculationState] = useState(calculationsState);
  const [primaryApplicant, setPrimaryApplicant] = useState(primaryApplicantState);
  const [coApplicant, setCoApplicant] = useState(coApplicantState);
  const [coSigner, setCoSigner] = useState(coSignerState);
  const [userType, setUserType] = useState(userTypeState);
  const [userDetails, setUserDetails] = useState(userDetailsState);
  const [formLoader, setFormLoader] = useState(false);
  const [backFlag, setBackFlag] = useState(false);
  const [showVouched, setShowVouched] = useState(false);
  const [reviewFlag, setReviewFlag] = useState(reviewFlagState);

  const [showCollateralPopup, setShowCollateralPopup] = useState(false);
  const [completedSteps, setCompletedSteps] = useState(completedStepsState);
  const [vouchedReturnFlag, setVouchedReturnFlag] = useState(vouchedFlag);
  const [appSubmitted, setAppSubmitted] = useState(false);
  const [appApproved, setAppApproved] = useState(false);
  const [loanStatus, setLoanStatus] = useState('');
  const [formFields, setFormFields] = useState([...formFieldsState]);
  const [openDraftModal, setOpenDraftModal] = useState(false);
  const [infoTabValue] = useState(tabvalue);
  const [draftCheckFlag, setDraftCheckFlag] = useState(draftFlagState);
  const [caseReferenceId, setCaseReferenceId] = useState('');
  const [addUsertabValue, setAddUserTabValue] = useState(0);
  const [viewAppFlow, setViewAppFlow] = useState(viewAppFlowState);
  const [idVerificationPendingText, setIdVerificationPendingText] = useState(
    idVerificationPendingState
  );
  const [addedApplicantFlag, setAddedApplicantFlag] = useState(false);
  const [unfilled, setUnfilled] = useState(unfilledState);
  const [unfilledId, setUnfilledId] = useState('');
  const [viewAppLoanStatus, setViewAppLoanStatus] = useState(viewAppLoanStatusState);
  const steps = [
    'Basic information',
    'Employment information',
    'Collateral information',
    'Financing terms',
    'Review & submit',
  ];

  const mainContextValue = useMemo(
    () => ({
      activeStep,
      setActiveStep,
      scannedData: vouchedData,
      infoTabValue,
      draftCheckFlag,
      setDraftCheckFlag,
      setVouchedData,
      coApplicant,
      setCoApplicant,
      coSigner,
      setCoSigner,
      userType,
      setUserType,
      userDetails,
      setUserDetails,
      formLoader,
      setFormLoader,
      formFields,
      setFormFields,
      backFlag,
      setBackFlag,
      showVouched,
      setShowVouched,
      showCollateralPopup,
      setShowCollateralPopup,
      reviewFlag,
      setReviewFlag,

      calculationState,
      setCalculationState,
      completedSteps,
      setCompletedSteps,
      vouchedReturnFlag,
      setVouchedReturnFlag,
      setOpenDraftModal,
      appSubmitted,
      setAppSubmitted,
      appApproved,
      setAppApproved,
      loanStatus,
      setLoanStatus,
      caseReferenceId,
      setCaseReferenceId,
      primaryApplicant,
      setPrimaryApplicant,
      savedApplicantIdsVouched,
      initApplicantIdsVouched,
      addUsertabValue,
      setAddUserTabValue,
      viewAppFlow,
      setViewAppFlow,
      idVerificationPendingText,
      setIdVerificationPendingText,
      addedApplicantFlag,
      setAddedApplicantFlag,
      unfilled,
      setUnfilled,
      unfilledId,
      setUnfilledId,
      viewAppLoanStatus,
      setViewAppLoanStatus,
    }),
    [
      activeStep,
      setActiveStep,
      vouchedData,
      scannedData,
      infoTabValue,
      setVouchedData,
      coApplicant,
      setCoApplicant,
      coSigner,
      setCoSigner,
      userType,
      setUserType,
      userDetails,
      setUserDetails,
      formLoader,
      setFormLoader,
      formFields,
      setFormFields,
      backFlag,
      setBackFlag,
      showVouched,
      setShowVouched,
      showCollateralPopup,
      setShowCollateralPopup,
      reviewFlag,
      setReviewFlag,

      calculationState,
      setCalculationState,
      completedSteps,
      setCompletedSteps,
      vouchedReturnFlag,
      setVouchedReturnFlag,
      setOpenDraftModal,
      appSubmitted,
      setAppSubmitted,
      appApproved,
      setAppApproved,
      loanStatus,
      setLoanStatus,
      caseReferenceId,
      setCaseReferenceId,
      primaryApplicant,
      setPrimaryApplicant,
      savedApplicantIdsVouched,
      initApplicantIdsVouched,
      addUsertabValue,
      setAddUserTabValue,
      viewAppFlow,
      setViewAppFlow,
      idVerificationPendingText,
      setIdVerificationPendingText,
      addedApplicantFlag,
      setAddedApplicantFlag,
      unfilled,
      setUnfilled,
      unfilledId,
      setUnfilledId,
      viewAppLoanStatus,
      setViewAppLoanStatus,
    ]
  );
  console.log('main context ufilled flag', unfilled);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (viewAppFlow) {
        const result = confirm();
        if (!result) {
          event.preventDefault();
        }
        return result;
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [viewAppFlow]);
  return (
    <MainApplicationContext.Provider value={mainContextValue}>
      <>
        <Box py={3} px={2} display="flex" justifyContent="space-between">
          <Typography variant="h3">Create application</Typography>
        </Box>
        <Loader open={formLoader} />
        {appSubmitted && !appApproved && (
          <Box>
            <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
              <ApplicationSubmitted />
            </Paper>
          </Box>
        )}
        {appApproved && (
          <Box>
            <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
              <ApplicationApproved />
            </Paper>
          </Box>
        )}
        {!appSubmitted && !appApproved && (
          <>
            <SaveAsDraftDialog setOpenModal={setOpenDraftModal} openModal={openDraftModal} />

            <Box display="flex" justifyContent="center">
              <Box sx={{ width: { sm: '75%', xs: '100%' } }}>
                <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
                  <Typography variant="h4" component="p" py={5} textAlign="center">
                    Alright! Let’s create application in just 5 steps
                  </Typography>
                  <HorizontalLinearStepper
                    steps={steps}
                    activeStep={activeStep - 1}
                    completedSteps={completedSteps}
                  />

                  <RenderStep activeStep={activeStep} />
                </Paper>
              </Box>
            </Box>
          </>
        )}
      </>
    </MainApplicationContext.Provider>
  );
};

export default CreateApplicationBase;
