import { Box } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import { OffersFilters } from 'pages/Offers/OfferFilters';
import React, { useContext, useEffect, useState } from 'react';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import Loader from 'components/Loader/Loader';

import PromotionsTable from './PromotionsTable';

export const MyPromotions = () => {
  const {
    // userID,
    rolebasedDealers,
  } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);

  const [promotionData, setPromotionData] = useState([]);

  const rowsPerPage = 10;
  const ApiClient = new ApiClass();

  const CommaSeperatedDealerList = rolebasedDealers?.map((x) => x.dealerId)?.join(',') || '';
  console.log('CommaSeperatedDealerList', CommaSeperatedDealerList);

  useEffect(async () => {
    await getPromotions();
  }, []);

  const getPromotions = async () => {
    try {
      setLoader(true);
      console.log(CommaSeperatedDealerList);
      const resp = await ApiClient.get(
        `${UrlHelpers.payCalcOnLoad}?dealerIds=${CommaSeperatedDealerList}`,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (resp) {
        console.log('resp', resp);
        setPromotionData(resp);
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  };
  return (
    <Box>
      <Loader open={loader} />

      <OffersFilters
        offers={promotionData}
        setOffers={setPromotionData}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        promotionsFlag={true}
      />
      <PromotionsTable
        promotionData={promotionData}
        page={page}
        setPage={setPage}
        hasPagination
        emptyDataMessage="No promotions found"
        callbackApi={getPromotions}
      />
    </Box>
  );
};
