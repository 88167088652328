import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Button, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { defaultFn } from 'utils/utility';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';

const ApplicationDialogHeading = ({ handleClose, handleBack }) => {
  return (
    <Box display="flex" gap={1}>
      <Button
        aria-label="back-button"
        data-testid="back-btn"
        onClick={handleBack}
        sx={{
          cursor: 'pointer',
          padding: 0,
          color: 'black',
          height: 'auto',
          width: 'auto',
          minWidth: 'auto',
          minHeight: 'auto',
          position: 'absolute',
          top: '20px',
          left: '20px',
        }}
      >
        <KeyboardBackspaceIcon />
      </Button>
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        ariaLabel="Create application"
        width="100%"
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h3" component="span" ml={5}>
            &nbsp;Create application
          </Typography>
        </Box>
        <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
      </BootstrapDialogTitle>
    </Box>
  );
};

export default ApplicationDialogHeading;

ApplicationDialogHeading.propTypes = {
  handleClose: PropTypes.func,
  handleBack: PropTypes.func,
};
ApplicationDialogHeading.defaultProps = {
  handleClose: defaultFn,
  handleBack: defaultFn,
};
