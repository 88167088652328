import { getRes } from 'utils/utility';

import axios from './customAxios';

class ApiClient {
  async get(url, config = {}) {
    const response = await axios().get(url, config);
    return getRes(response);
  }

  async post(url, data = {}, config = {}) {
    const response = await axios().post(url, data, config);

    return getRes(response);
  }

  async put(url, data = {}, config = {}) {
    const response = await axios().put(url, data, config);
    return getRes(response);
  }
  async delete(url, config = {}) {
    const response = await axios().delete(url, config);
    return getRes(response);
  }
}

export default ApiClient;
