import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { numberToCurrency, replaceNullValues } from 'utils/utility';

const AppDetailPopup = ({ details, tabName }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const approvalTerms = JSON.parse(details?.approvalTerms || '{}');
  const dealerFundingInformation = JSON.parse(details?.dealerFundingInformation || '{}');
  const decision = JSON.parse(details?.decisions || '{}')?.[0];

  const onViewDetail = () => {
    const tab = tabName === 'All loan applications' ? 2 : 0;
    navigate('/applications/viewApp', {
      state: {
        applicationDetails: details,
        tabValAppDashboard: tab,
        path: `${location.pathname === '/home' ? 'home' : 'application'}`,
      },
    });
  };
  return (
    <Box display="flex" flexDirection="column" padding="8px 0px 20px">
      <Box display="flex" backgroundColor="#9090901A" padding={1.25}>
        <Box display="flex" flexDirection="column" width="50%" gap={1.25}>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Application ID:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {details?.appId}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Borrower name:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {details?.applicantName}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Occupation:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {details?.occupation}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" width="50%" gap={1.25}>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Gross monthly income:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {numberToCurrency(details?.grossMonthlyIncome)}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Residence status:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {details?.residentialStatus}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" padding={1.25}>
        <Box display="flex" flexDirection="column" width="50%" gap={1.25}>
          <Typography variant="subtitle1" fontWeight="700">
            Latest decision
          </Typography>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Status:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {details?.displayLoanStatus}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Date & time:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {decision?.receivedDateTime
                ? moment(decision?.receivedDateTime?.replace('Z', '')).format('MM/DD/yyyy, h:mm A')
                : '--'}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Product:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {details?.product}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Program:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {details?.program}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" width="50%" gap={1.25}>
          <Typography variant="subtitle1" fontWeight="700">
            Approval terms
          </Typography>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Amount:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {approvalTerms?.amount ? numberToCurrency(approvalTerms?.amount) : '--'}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Cust / buy rate:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {approvalTerms?.custRate ? `${approvalTerms?.custRate}%` : '--'} /
              {approvalTerms?.buyRate ? `${approvalTerms?.buyRate}%` : '--'}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Term / payment:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {replaceNullValues(approvalTerms?.term)} /{' '}
              {approvalTerms?.amount ? numberToCurrency(approvalTerms?.amount) : '--'}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Repay method:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {replaceNullValues(approvalTerms?.repayMethod)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" padding={1.25}>
        <Box display="flex" flexDirection="column" width="50%" gap={1.25}>
          <Typography variant="subtitle1" fontWeight="700">
            Dealer funding information
          </Typography>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Booked date:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {dealerFundingInformation?.bookedDate
                ? moment(dealerFundingInformation?.bookedDate).format('MM/DD/yyyy')
                : '--'}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Contract date:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {dealerFundingInformation?.contractDate
                ? moment(dealerFundingInformation?.contractDate).format('MM/DD/yyyy')
                : '--'}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Contract rate:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {dealerFundingInformation?.contractRate
                ? `${dealerFundingInformation?.contractRate}%`
                : '--'}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Contract payment:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {dealerFundingInformation?.contractPayment
                ? numberToCurrency(dealerFundingInformation?.contractPayment)
                : '--'}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" width="50%" gap={1.25}>
          <Typography sx={{ height: '20px' }}></Typography>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Booked user:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {replaceNullValues(dealerFundingInformation?.bookedUser)}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Contract amount:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {dealerFundingInformation?.contractAmount
                ? numberToCurrency(dealerFundingInformation?.contractAmount)
                : '--'}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              Contract term:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {dealerFundingInformation?.contractTerm
                ? `${dealerFundingInformation?.contractTerm} months`
                : '--'}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle2" flex="1">
              First payment date:
            </Typography>
            <Typography variant="subtitle2" flex="1" color="#661C69" fontWeight="700">
              {dealerFundingInformation?.firstPaymentDate
                ? moment(dealerFundingInformation?.firstPaymentDate).format('MM/DD/yyyy')
                : '--'}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Button
        color="secondary"
        variant="contained"
        sx={{ width: '20%', alignSelf: 'flex-end' }}
        onClick={onViewDetail}
      >
        View detail
      </Button>
    </Box>
  );
};

export default AppDetailPopup;

AppDetailPopup.propTypes = {
  details: PropTypes.instanceOf(Object),
  tabName: PropTypes.string,
};
AppDetailPopup.defaultProps = {
  details: {},
  tabName: '',
};
