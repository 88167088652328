import { Typography } from '@mui/material';
import MainApplicationContext from 'context/MainApplicationContext';
import { useContext } from 'react';

const ApplicationPageHeading = () => {
  const steps = {
    1: 'Basic information',
    2: 'Employment information',
    3: 'Collateral information',
    4: 'Financing terms',
    5: 'Review & submit',
  };
  const { activeStep = 1 } = useContext(MainApplicationContext);

  return (
    <Typography variant="h3" component="p" py={4} id={steps?.[activeStep]}>
      {steps[activeStep]}
    </Typography>
  );
};

export default ApplicationPageHeading;
