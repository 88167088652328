import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Button, Link } from '@mui/material';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { defaultFn } from 'utils/utility';

const CreateApplicationFooter = ({
  disabledFlag,
  onBackClick,
  onSaveContinue,
  isSubmit,
  onSaveDraft,
  draftDisabledFlag,
  saveDisabledFlag,
}) => {
  const { setOpenDiscardModal } = useContext(AuthContext);
  const saveText = isSubmit ? 'Submit' : 'Continue';
  const handleDiscardApplicationClick = () => {
    setOpenDiscardModal(true);
  };
  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center" sx={{ cursor: disabledFlag ? 'none' : 'pointer' }}>
        <KeyboardArrowLeftIcon
          sx={{ opacity: disabledFlag ? 0.5 : 1 }}
          color="secondary"
          disabled={disabledFlag}
        />
        <Link
          component="button"
          variant="subtitle2"
          color="secondary"
          disabled={disabledFlag}
          onClick={onBackClick}
          id="back-link"
        >
          Back
        </Link>
      </Box>
      <Box display="flex" gap={2} py={4}>
        <Link
          component="button"
          variant="subtitle2"
          color="secondary"
          sx={{ fontSize: '16px' }}
          onClick={handleDiscardApplicationClick}
          id="discard-app-btn"
        >
          Discard application
        </Link>

        <Button
          color="secondary"
          variant="outlined"
          onClick={onSaveDraft}
          disabled={draftDisabledFlag}
          id="save-as-draft-btn"
        >
          Save as draft
        </Button>

        <Button
          color="secondary"
          variant="contained"
          onClick={onSaveContinue}
          disabled={!!saveDisabledFlag}
          id="continue-btn"
        >
          {saveText}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateApplicationFooter;

CreateApplicationFooter.propTypes = {
  disabledFlag: PropTypes.bool,
  onBackClick: PropTypes.func,
  onSaveContinue: PropTypes.func,
  saveDisabledFlag: PropTypes.bool,
  isSubmit: PropTypes.bool,
  onSaveDraft: PropTypes.func,
  draftDisabledFlag: PropTypes.bool,
};
CreateApplicationFooter.defaultProps = {
  disabledFlag: false,
  saveDisabledFlag: false,
  onBackClick: defaultFn,
  onSaveContinue: defaultFn,
  isSubmit: false,
  onSaveDraft: defaultFn,
  draftDisabledFlag: false,
};
