import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { defaultFn, regexEmail } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';
import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomPhoneNumber from 'components/CustomPhoneNumber/CustomPhoneNumber';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';
import { SSN } from 'components/SSN';

const BasicInformation = ({
  control,
  errors,
  maritalStatusList,
  setValue,
  clearErrors,
  prefixName,
  couserIndex,
  trigger,
}) => {
  const [watchSSN, watchmobile1, watchUsertype] = useWatch({
    control,
    name: [`${prefixName}.ssn`, `${prefixName}.mobile`, `${prefixName}.userType`],
  });

  useEffect(() => {
    if (watchmobile1) {
      trigger(`${prefixName}.mobile2`);
    }
  }, [watchmobile1]);

  const handleKeyDownSSN = (value) => {
    setValue(`${prefixName}.ssn`, value);
    if (value.match(/^\d/)) {
      clearErrors(`${prefixName}.ssn`);
    }
    trigger(`${prefixName}.ssn`);
  };

  return (
    <div>
      <CustomCollapse heading="Demographic information" open={true} errors={errors}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.cousers?.[couserIndex]?.firstName}
              errorText={errors?.cousers?.[couserIndex]?.firstName?.message}
              maxLength="15"
              regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡\s]$/}
              placeholder="Enter first name"
              label="First name"
              name={`${prefixName}.firstName`}
              flexDirection="column"
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.cousers?.[couserIndex]?.middleName}
              errorText={errors?.cousers?.[couserIndex]?.middleName?.message}
              maxLength="20"
              regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡\s]$/}
              placeholder="Enter middle name"
              name={`${prefixName}.middleName`}
              label="Middle name"
              flexDirection="column"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.cousers?.[couserIndex]?.lastName}
              errorText={errors?.cousers?.[couserIndex]?.lastName?.message}
              maxLength="25"
              regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡\s]$/}
              placeholder="Enter last name"
              label="Last name"
              name={`${prefixName}.lastName`}
              flexDirection="column"
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.cousers?.[couserIndex]?.secondLastName}
              errorText={errors?.cousers?.[couserIndex]?.secondLastName?.message}
              maxLength="25"
              regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡.'\s]$/}
              placeholder="Enter second last name"
              label="Second last name"
              name={`${prefixName}.secondLastName`}
              flexDirection="column"
              required={watchUsertype === 'Applicant'}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomDatePicker
              name={`${prefixName}.dob`}
              label="Date of birth"
              control={control}
              error={!!errors?.cousers?.[couserIndex]?.dob}
              errorText={errors?.cousers?.[couserIndex]?.dob?.message}
              required
              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
              placeHolder="MM/DD/YYYY"
            />
          </Grid>
          <Grid item lg={4.5} md={6} sm={12} xs={12}>
            <SSN
              handleKeyDown={handleKeyDownSSN}
              ssn={watchSSN}
              errors={errors}
              couserIndex={couserIndex}
              prefixName={prefixName}
              trigger={trigger}
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.cousers?.[couserIndex]?.email}
              errorText={errors?.cousers?.[couserIndex]?.email?.message}
              placeholder="Enter email"
              label="Email"
              regex={regexEmail}
              maxLength={50}
              flexDirection="column"
              name={`${prefixName}.email`}
              required
            />
          </Grid>
          <Grid item lg={4.5} md={6} sm={12} xs={12}>
            <CustomPhoneNumber
              name={`${prefixName}.mobile`}
              label="Mobile"
              placeholder="Enter mobile"
              flexDirection="column"
              control={control}
              error={!!errors?.cousers?.[couserIndex]?.mobile}
              errorText={errors?.cousers?.[couserIndex]?.mobile?.message}
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              control={control}
              options={maritalStatusList}
              label="Marital status"
              required
              name={`${prefixName}.maritalStatus`}
              errors={!!errors?.cousers?.[couserIndex]?.maritalStatus}
              errorText={errors?.cousers?.[couserIndex]?.maritalStatus?.message}
            />
          </Grid>
          <Grid item lg={4.5} md={6} sm={12} xs={12}>
            <CustomPhoneNumber
              name={`${prefixName}.mobile2`}
              label="Alternate phone number"
              placeholder="Enter alternate phone number"
              flexDirection="column"
              control={control}
              error={!!errors?.cousers?.[couserIndex]?.mobile2}
              errorText={errors?.cousers?.[couserIndex]?.mobile2?.message}
            />
          </Grid>
        </Grid>
      </CustomCollapse>
    </div>
  );
};
export default BasicInformation;

BasicInformation.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  maritalStatusList: PropTypes.instanceOf(Object),
  setValue: PropTypes.func,
  clearErrors: PropTypes.func,
  prefixName: PropTypes.string,
  couserIndex: PropTypes.number,
  setError: PropTypes.func,
  setLoader: PropTypes.func,
  trigger: PropTypes.func,
};
BasicInformation.defaultProps = {
  control: {},
  errors: {},
  maritalStatusList: [],
  setValue: defaultFn,
  clearErrors: defaultFn,
  prefixName: '',
  couserIndex: 0,
  setError: defaultFn,
  setLoader: defaultFn,
  trigger: defaultFn,
};
