import { Box, Button, DialogActions, DialogContent, Divider, Typography } from '@mui/material';
import blackbookLogo from 'assets/svg/blackbook.svg';
import carNew from 'assets/svg/car-new.svg';
import carUsed from 'assets/svg/car-used.svg';
import distributordataLogo from 'assets/svg/distributordata.svg';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useWatch } from 'react-hook-form';
import { defaultFn } from 'utils/utility';

import BootstrapDialogTitle, { BootstrapDialog } from 'components/CustomDialogBox/CustomDialogBox';
import CustomToggleButtonGroup from 'components/CustomToggleButtonGroup/CustomToggleButtonGroup';
import Loader from 'components/Loader/Loader';

const LookupDialog = ({ control, setValue, reset, loader }) => {
  const {
    showMainPopup,
    setShowMainPopup,
    setShowSecondaryPopup,
    setShowInterPopup,
    setVehicleType,
  } = useContext(AuthContext);

  const [watchCollateralType, watchValueGuide] = useWatch({
    control,
    name: ['collateralType', 'valueGuide'],
  });

  console.log('in popup selected collateral values', watchCollateralType, watchValueGuide);
  const handleClose = () => {
    setShowMainPopup(false);
    reset();
  };

  const handleContinue = () => {
    setShowMainPopup(false);
    if (watchCollateralType === 'used' && watchValueGuide === 'Blackbook') {
      setShowInterPopup(true);
    } else {
      setShowSecondaryPopup(true);
    }
  };

  const toggleChange = (e, value) => {
    setValue('collateralType', value);
    if (value === 'used') {
      setValue('valueGuide', 'Blackbook');
    } else if (value === 'new') {
      setValue('valueGuide', 'Distributor Data');
    }
    setVehicleType(value);
  };

  const toggleValueGuide = (e, value) => {
    setValue('valueGuide', value);
  };
  const collateralList = [
    { valuee: 'new', displayValue: 'New', logo: carNew },
    { valuee: 'used', displayValue: 'Used', logo: carUsed },
  ];

  const valueGuideList = [
    { valuee: 'Blackbook', displayValue: 'Black Book', logo: blackbookLogo },
    { valuee: 'Distributor Data', displayValue: 'Distributor Data', logo: distributordataLogo },
  ];

  return (
    <>
      <BootstrapDialog onClose={handleClose} aria-labelledby="lookup-title" open={showMainPopup}>
        {loader && <Loader open={loader} />}
        <BootstrapDialogTitle id="lookup-dialog-title" onClose={handleClose}>
          <Typography variant="h3" component="span" id="lookup-title">
            Lookup
          </Typography>
          <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
        </BootstrapDialogTitle>
        <DialogContent id="lookup-desc">
          <Box display="flex" flexDirection="column" gap={2.5} px={5} mb={2.5}>
            <Typography variant="h4">Collateral type</Typography>

            <CustomToggleButtonGroup
              name="collateralType"
              control={control}
              toggleChange={toggleChange}
              exclusive={true}
              toggleList={collateralList}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={2.5} px={5} mb={2.5}>
            <Typography variant="h4">Value guide source</Typography>
            <CustomToggleButtonGroup
              name="valueGuide"
              control={control}
              exclusive={true}
              toggleChange={toggleValueGuide}
              toggleList={valueGuideList}
              disableCheck={watchCollateralType === 'used'}
              disableValue="Distributor Data"
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
            id="lookup-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            id="lookup-continue-btn"
            onClick={handleContinue}
            sx={{ maxHeight: '40px' }}
            disabled={!(watchValueGuide && watchCollateralType)}
          >
            Ok, continue
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
LookupDialog.propTypes = {
  setValue: PropTypes.func,
  reset: PropTypes.func,
  control: PropTypes.instanceOf(Object),
  loader: PropTypes.bool,
};
LookupDialog.defaultProps = {
  control: {},
  setValue: defaultFn,
  reset: defaultFn,
  loader: false,
};

export default LookupDialog;
