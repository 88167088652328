import ApiClass from 'Api/ApiClient';
import { UrlHelpers } from 'urlHelpers/urlHelper';

export const payCalcOnLoadApi = async ({
  rolebasedDealers,
  dealer,
  createAppFlag,
  promotionId,
}) => {
  try {
    let list =
      dealer && createAppFlag ? dealer : rolebasedDealers?.map((x) => x.dealerId)?.join(',') || '';
    const ApiClient = new ApiClass();
    let url = promotionId
      ? `${UrlHelpers?.payCalcOnLoad}/${promotionId}`
      : `${UrlHelpers?.payCalcOnLoad}?dealerIds=${list}`;
    return await ApiClient.get(url, {
      headers: { 'Content-Type': 'application/json' },
    });
  } catch (e) {
    console.log('err resp', e);
  }
};
