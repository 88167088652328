import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  Typography,
} from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn, numberToCurrency } from 'utils/utility';

import BootstrapDialogTitle, { BootstrapDialog } from 'components/CustomDialogBox/CustomDialogBox';
import Loader from 'components/Loader/Loader';

const ValueGuideLookupDialog = ({ setApiErr }) => {
  const {
    showCollateralPopup,
    setShowCollateralPopup,
    coApplicant,
    coSigner,
    reviewFlag,
    calculationState,
    completedSteps,
    viewAppFlow,
  } = useContext(MainApplicationContext);
  console.log('coApplicant, coSigner ', coApplicant, coSigner);
  const {
    setShowMainPopup,
    setCollateralInfo,
    setLookupSaved,
    createAppApplicationId,
    setLookupLinkClicked,
    setShowPage,
    setShowSummary,
    lookupValue,
    setOpenPopUp,
    setCreateAppLookup,
  } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setShowCollateralPopup(false);
  };

  const handleContinue = () => {
    const item = data?.[selectedOption];
    const { searchResult } = item;
    setCollateralInfo(JSON.parse(searchResult));
    setLookupSaved(true);
    setShowCollateralPopup(false);
    setApiErr('');
  };

  const ApiClient = new ApiClass();
  useEffect(async () => {
    setLookupLinkClicked(false);
    if (showCollateralPopup) {
      let lastLookup = lookupValue?.length
        ? [{ searchResult: JSON.stringify(...lookupValue) }]
        : [];

      try {
        setLoader(true);
        let resp = await ApiClient.get(
          `${UrlHelpers.saveLookupValue}?applicationId=${createAppApplicationId}&type=vehicle`,
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );

        resp = resp.filter((x) => x?.searchResult !== lastLookup[0]?.searchResult);
        if (lookupValue) {
          setData([...resp, ...lastLookup]);
        } else {
          setData([...resp]);
        }
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoader(false);
      }
    }
  }, [showCollateralPopup]);

  const onRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleLinkClick = (data) => {
    let { searchResult } = data;
    let parsedData = JSON.parse(searchResult);
    setCreateAppLookup('');
    setCollateralInfo(parsedData);
    setLookupLinkClicked(true);

    if (parsedData?.productDetails?.valueGuide === 'Distributor Data') {
      setShowPage('distributorDataResult');
    } else if (
      parsedData?.productDetails?.valueGuide === 'Blackbook' &&
      parsedData?.productDetails?.collateralType === 'used'
    ) {
      setShowSummary(true);
      setShowPage('equipmentAdjustment');
    } else {
      setShowSummary(true);
      setShowPage('equipmentAdjustmentNew');
    }
    navigate('/lookup', {
      state: {
        coApplicant: coApplicant,
        coSigner: coSigner,
        reviewFlag,
        calculationState,
        completedSteps,
        viewAppFlow,
      },
    });
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showCollateralPopup}
      >
        {loader && <Loader open={loader} />}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="h3" component="span">
            Value guide lookup
          </Typography>
          <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
        </BootstrapDialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
          <Typography variant="h6" fontWeight="700" component="span">
            Previous lookups
          </Typography>
          <Box>
            <Grid
              container
              display="grid"
              sx={{
                gridTemplateColumns: '5% 25% 15% 15% 25% 15%',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                height: '50px',
                background: '#F2F2F2',
                fontSize: '14px',
                fontWeight: '700',
                border: '1px solid #DADADA',
              }}
            >
              <Box></Box>
              <Box>Value source</Box>
              <Box>Vehicle value</Box>
              <Box>Options</Box>
              <Box>Mileage adjustment</Box>
              <Box>Total</Box>
            </Grid>
            <Grid
              container
              display="grid"
              sx={{
                gridTemplateColumns: '5% 25% 15% 15% 25% 15%',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontSize: '14px',
                border: '1px solid #DADADA',
                maxHeight: '300px',
                overflowY: 'scroll',
              }}
            >
              {data?.map?.((x, innerIndex) => {
                const { searchResult } = x;
                const { vehicleValues, productDetails } = JSON.parse(searchResult);
                return (
                  <>
                    <Box p={2}>
                      <FormControlLabel
                        key={searchResult}
                        value={innerIndex}
                        control={<Radio color="secondary" />}
                        checked={selectedOption == innerIndex}
                        onChange={(e) => onRadioChange(e)}
                        classes={{ label: classes.radioLabel }}
                        data-testid="radiotest"
                      />
                    </Box>
                    <Link
                      component="button"
                      variant="subtitle2"
                      onClick={() => handleLinkClick(x)}
                      color="secondary"
                      p={2}
                    >
                      Manual
                    </Link>
                    <Box p={2}>
                      {numberToCurrency(
                        productDetails?.collateralType === 'new' &&
                          (productDetails?.valueGuide === 'Blackbook' ||
                            productDetails?.valueGuide === 'Distributor Data')
                          ? vehicleValues?.basevehicleValue.msrp
                          : vehicleValues?.basevehicleValue
                      )}
                    </Box>
                    <Box p={2}>
                      {numberToCurrency(
                        productDetails?.collateralType === 'new' &&
                          productDetails?.valueGuide === 'Blackbook'
                          ? vehicleValues?.equipmentAdjustment.msrp
                          : vehicleValues?.equipmentAdjustment
                      )}
                    </Box>
                    <Box p={2}>{numberToCurrency(vehicleValues?.mileageAdjustment)}</Box>
                    <Box p={2}>
                      {numberToCurrency(
                        (productDetails?.collateralType === 'new' &&
                        (productDetails?.valueGuide === 'Blackbook' ||
                          productDetails?.valueGuide === 'Distributor Data')
                          ? vehicleValues?.basevehicleValue.msrp
                          : vehicleValues?.basevehicleValue) +
                          (productDetails?.collateralType === 'new' &&
                          productDetails?.valueGuide === 'Blackbook'
                            ? vehicleValues?.equipmentAdjustment.msrp
                            : vehicleValues?.equipmentAdjustment) +
                          vehicleValues?.mileageAdjustment
                      )}
                    </Box>
                  </>
                );
              })}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
            id="cancel-btn"
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            id="value-guide-lookup-btn"
            onClick={() => {
              setShowMainPopup(true);
              setShowCollateralPopup(false);
              setShowPage('loader');
              setOpenPopUp(false);
              setCreateAppLookup('');
            }}
            sx={{ maxHeight: '40px' }}
          >
            Value guide lookup
          </Button>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            onClick={handleContinue}
            sx={{ maxHeight: '40px' }}
            disabled={selectedOption === undefined}
            id="save-btn"
          >
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default ValueGuideLookupDialog;

ValueGuideLookupDialog.propTypes = {
  setApiErr: PropTypes.func,
};
ValueGuideLookupDialog.defaultProps = {
  setApiErr: defaultFn,
};
