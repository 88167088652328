import { makeStyles } from '@mui/styles';

export const enrollTooltipStyles = () => {
  return makeStyles(() => ({
    tooltip: {
      color: '#000',
      borderRadius: '5px',
      maxWidth: '300px',
      padding: '5px',
      font: 'normal normal 300 13px/14px Arial',
      letterSpacing: '0.09px',
      marginTop: '15px',
      // opacity: 1,
      background: 'white',
      border: '0.5px solid #E37617',
    },

    arrow: {
      fontSize: 18,
      width: 20,
      '&::before': {
        border: '0.5px solid #E37617',
        backgroundColor: '#FBF3EC',
        boxSizing: 'border-box',
      },
    },
  }));
};
