import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { defaultFn } from 'utils/utility';

import CircleIcon from './CircleIcon';

const CustomToggleButtonGroup = ({
  toggleChange,
  control,
  name,
  toggleList,
  disableCheck,
  disableValue,
  exclusive,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <>
            <ToggleButtonGroup
              exclusive={exclusive}
              aria-label={name}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '40px',
                maxHeight: 'none',
                '.MuiToggleButton-root': {
                  maxHeight: 'none',
                },
                '.MuiToggleButtonGroup-grouped': {
                  '&:disabled': {
                    border: '1px solid #E37617',
                    opacity: 0.4,
                  },
                  '&:not(:first-of-type)': {
                    borderLeft: '1px solid #E37617 !important',
                  },
                },
              }}
              {...field}
              onChange={toggleChange}
            >
              {toggleList.length > 0 &&
                toggleList.map((x) => {
                  const { logo, valuee, displayValue } = x;

                  return (
                    <ToggleButton
                      key={`${valuee}-button`}
                      value={valuee}
                      aria-label={`${name}-${displayValue}`}
                      aria-labelledby={displayValue}
                      disabled={disableCheck && displayValue === disableValue}
                      id={`${valuee}-button`}
                      data-testid={`${valuee}-button`}
                      sx={{
                        border: '1px solid #E37617',
                        borderRadius: '5px',
                        color: '#E37617',
                        backgroundColor: 'transparent',

                        height: '120px',
                        width: '120px',
                        '&:hover': {
                          backgroundColor: 'rgba(227, 118, 23, 0.05)',
                        },
                        '&:focus': {
                          border: '1px solid #E37617',
                          borderRadius: '5px',
                          color: '#E37617',
                        },
                        '&.Mui-selected': {
                          backgroundColor: 'rgba(227, 118, 23, 0.05)',

                          '&:hover': {
                            backgroundColor: 'rgba(227, 118, 23, 0.05)',
                          },
                        },
                        '&.Mui-disabled': {
                          border: '1px solid #E37617',
                          opacity: 0.4,
                        },
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={1.25}
                        width="128px"
                        alignItems="center"
                      >
                        <img src={logo} height="50px" width="50px" color="primary" />
                      </Box>

                      {CircleIcon(valuee, field.value)}
                    </ToggleButton>
                  );
                })}
            </ToggleButtonGroup>
            {toggleList.length > 0 && (
              <Box display="flex" justifyContent="center" gap="40px">
                {toggleList?.map((x) => {
                  const { displayValue } = x;
                  return (
                    <Typography
                      key={displayValue}
                      // aria-labelledby={displayValue}
                      variant="subtitle1"
                      color="primary"
                      textAlign="center"
                      sx={{
                        width: '120px',
                        opacity: disableCheck && disableValue === displayValue ? '0.4' : 1,
                      }}
                    >
                      {displayValue}
                    </Typography>
                  );
                })}
              </Box>
            )}
          </>
        );
      }}
    />
  );
};

export default CustomToggleButtonGroup;
CustomToggleButtonGroup.propTypes = {
  name: PropTypes.string,
  toggleList: PropTypes.instanceOf(Object),
  toggleChange: PropTypes.func,
  control: PropTypes.instanceOf(Object),
  disableCheck: PropTypes.bool,
  exclusive: PropTypes.bool,
  formats: PropTypes.array,
  disableValue: PropTypes.string,
};
CustomToggleButtonGroup.defaultProps = {
  name: '',
  toggleList: [],
  formats: [],
  toggleChange: defaultFn,
  control: {},
  disableCheck: false,
  exclusive: false,
  disableValue: '',
};
