import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { defaultFn } from 'utils/utility';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';

export const OptionLogicErrorPopup = ({ open, handleClose }) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{ sx: { maxWidth: '700px' } }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <BootstrapDialogTitle
            display="flex"
            alignItems="center"
            onClose={handleClose}
            sx={{ width: '90%', padding: '0' }}
          >
            <Typography variant="h1">Error in selected option</Typography>
          </BootstrapDialogTitle>
          <Typography variant="subtitle1" sx={{ padding: '16px 0' }}>
            The option is not available because of the previous selection. Please go back and select
            again. we havve disabled the last selected, as it is not valid.
          </Typography>
          <DialogActions sx={{ justifyContent: 'flex-end', gap: '15px' }}>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Ok
            </Button>
          </DialogActions>
        </div>
      </DialogContent>
    </Dialog>
  );
};

OptionLogicErrorPopup.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};
OptionLogicErrorPopup.defaultProps = {
  open: false,
  handleClose: defaultFn,
};
