import { Box, Grid, InputLabel, Typography } from '@mui/material';
import RegisterUserContext from 'context/RegisterUserContext';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import CustomCollapse from 'components/CustomCollapse';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

import DealerTable from './DealerTable';

const UserProfileForm = ({ control, errors }) => {
  const { securityProfiles, isViewProfile, dealerList } = useContext(RegisterUserContext);
  return (
    <div>
      <CustomCollapse heading="User status" open={true} errors={errors}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Security profile"
              control={control}
              name="securityProfile"
              errors={!!errors?.securityProfile}
              errorText={errors?.securityProfile?.message}
              options={securityProfiles?.map((profile) => ({
                name: profile.displayName,
                ...profile,
              }))}
              defaultValue=""
              required
              displayEmpty
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
              <InputLabel htmlFor={'Dealer'}>{'User type'}</InputLabel>
              <Typography sx={{ width: '100%', marginTop: '5px' }}>Dealer Portal</Typography>
            </Box>
          </Grid>
        </Grid>
      </CustomCollapse>
      <CustomCollapse heading="Dealer information" open={true}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
              <InputLabel htmlFor={'Dealer'}>
                <span style={{ color: 'red' }}></span> {'Dealer type'}
              </InputLabel>
              <Typography sx={{ width: '100%' }}>Dealer</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={4} marginTop={1}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
              <InputLabel htmlFor={'Dealer'}>
                {!isViewProfile && <span style={{ color: 'red' }}>*&nbsp;</span>} {'Dealer'}
              </InputLabel>
            </Box>
          </Grid>
        </Grid>
        <DealerTable isViewProfile={isViewProfile} dealerList={dealerList} />
      </CustomCollapse>
    </div>
  );
};

UserProfileForm.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  isViewProfile: PropTypes.bool,
  dealerList: PropTypes.array,
};
UserProfileForm.defaultProps = {
  control: {},
  errors: {},
  isViewProfile: false,
  dealerList: [],
};
export default UserProfileForm;
