import { Alert, Box, Paper, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { replaceNullValues } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';
import Loader from 'components/Loader/Loader';

export const ViewDealer = ({ dealer }) => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [dealerMap, setDealerMap] = useState([]);
  const [alertToggle, setAlertToggle] = useState(true);
  const ApiClient = new ApiClass();
  useEffect(async () => {
    try {
      setLoader(true);
      const resp = await ApiClient.get(`${UrlHelpers.dealers}/${dealer?.dealerId}`);

      console.log(resp);
      if (resp) {
        setData(resp);
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  }, []);
  useEffect(async () => {
    try {
      let dealerUiMappingg = await ApiClient.get(UrlHelpers.dealerUiMapping);

      dealerUiMappingg = JSON.parse(dealerUiMappingg?.['dealer-ui-mapping']);

      setDealerMap(dealerUiMappingg);
    } catch (e) {
      console.log('e', e);
    }
  }, []);
  const handleDisplayValues = (value1, value2) => {
    return replaceNullValues(value1?.find((x) => x?.value === value2)?.displayName);
  };
  return (
    <>
      {loader && <Loader open={loader} />}
      <Paper className={classes.infoRequestPaper}>
        {alertToggle && (
          <Alert
            variant="outlined"
            severity="info"
            onClose={() => {
              setAlertToggle(false);
            }}
          >
            <Box display="flex" flexDirection="column">
              <Box display="flex" gap="2px">
                <Typography variant="subtitle2" fontWeight="700">
                  last updated date:
                </Typography>
                <Typography variant="subtitle2">
                  {data?.lastUpdatedDate
                    ? moment(data?.lastUpdatedDate).format('MM/DD/yyyy')
                    : '--'}
                </Typography>
              </Box>
              <Box display="flex" gap="2px">
                <Typography variant="subtitle2" fontWeight="700">
                  Last updated user:
                </Typography>
                <Typography variant="subtitle2">{data?.lastUpdatedUser || '--'}</Typography>
              </Box>
            </Box>
          </Alert>
        )}
        <Box sx={{ width: '70%', margin: 'auto' }}>
          <CustomCollapse heading={'Dealer information'} open={true}>
            <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25}>
              <Typography variant="subtitle2">Code</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.dealerId)}
              </Typography>
              <Typography variant="subtitle2">Name</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.dealerName)}
              </Typography>
              <Typography variant="subtitle2">Type</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {handleDisplayValues(dealerMap[0]?.type, data?.type)}
              </Typography>

              <Typography variant="subtitle2">Dealer type</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {handleDisplayValues(dealerMap[0]?.dealerType, data?.dealerType)}
              </Typography>
              <Typography variant="subtitle2">Active</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.status === '1' ? 'Yes' : 'No')}
              </Typography>
              <Typography variant="subtitle2">Last inactivation date</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {data?.lastInactivationDate
                  ? moment(data?.lastInactivationDate).format('MM/DD/yyyy, h:mm A')
                  : '-'}
              </Typography>
              <Typography variant="subtitle2">Last reactivation date</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {data?.lastReactivationDate
                  ? moment(data?.lastReactivationDate).format('MM/DD/yyyy, h:mm A')
                  : '-'}
              </Typography>
              <Typography variant="subtitle2">Status comment</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.statusComment)}
              </Typography>
              <Typography variant="subtitle2">TIN</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.tin)}
              </Typography>
              <Typography variant="subtitle2">Dealer rating</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.originatorOrDealerRating)}
              </Typography>
              <Typography variant="subtitle2">Dealer effective date</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.originatorOrDealerEffectiveDate)}
              </Typography>
              <Typography variant="subtitle2">Previous dealer rating</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.previousOriginatorOrDealerRating)}
              </Typography>
              <Typography variant="subtitle2">Independent</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {data?.independent === '1' ? 'Yes' : 'No'}
              </Typography>
            </Box>
            <Typography variant="h6" fontWeight="700 !important" my="20px !important">
              Contact information
            </Typography>
            <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25}>
              <Typography variant="subtitle2">Address line 1</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.addressLine1)}
              </Typography>
              <Typography variant="subtitle2">Address line 2</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.addressLine2)}
              </Typography>
              <Typography variant="subtitle2">City</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.city)}
              </Typography>
              <Typography variant="subtitle2">State</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.state)}
              </Typography>
              <Typography variant="subtitle2">Country</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.country)}
              </Typography>
              <Typography variant="subtitle2">Zip code</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.zip)}
              </Typography>
              <Typography variant="subtitle2">Phone Number</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {data?.phoneNumber?.slice(0, 2) === '+1'
                  ? replaceNullValues(data?.phoneNumber?.slice(2))
                  : replaceNullValues(data?.phoneNumber)}
              </Typography>
              <Typography variant="subtitle2">Email</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.email)}
              </Typography>
            </Box>
          </CustomCollapse>
          <CustomCollapse heading={'Dealer products & groups'} open={true}>
            <Typography variant="h6" fontWeight="700 !important" my="20px !important">
              Dealer products
            </Typography>
            <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25}>
              <Typography variant="subtitle2">Product</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.product)}
              </Typography>
            </Box>
            <Typography variant="h6" fontWeight="700 !important" my="20px !important">
              Group A\assignments
            </Typography>
            <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25}>
              <Typography variant="subtitle2">Cost center</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.costCenter)}
              </Typography>
              <Typography variant="subtitle2">Mega dealer</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {handleDisplayValues(dealerMap[0]?.megaOriginator, data?.megaOriginator)}
              </Typography>
              <Typography variant="subtitle2">Region</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {handleDisplayValues(dealerMap[0]?.region, data?.region)}
              </Typography>
              <Typography variant="subtitle2">Territory</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {handleDisplayValues(dealerMap[0]?.territory, data?.territory)}
              </Typography>
              <Typography variant="subtitle2">Buying center</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {handleDisplayValues(dealerMap[0]?.buyingCenter, data?.buyingCenter)}
              </Typography>
              <Typography variant="subtitle2">Subsidiary buying center</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.subsidiaryBuyingCenter)}
              </Typography>
              <Typography variant="subtitle2">Service buying center</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {handleDisplayValues(dealerMap[0]?.serviceBuyingCenter, data?.serviceBuyingCenter)}
              </Typography>
              <Typography variant="subtitle2">Division</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {handleDisplayValues(dealerMap[0]?.division, data?.division)}
              </Typography>
              <Typography variant="subtitle2">Life insurance</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.lifeInsurance)}
              </Typography>
            </Box>
          </CustomCollapse>
          <CustomCollapse heading={'Other details'} open={true}>
            <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25}>
              <Typography variant="subtitle2">Auto approval</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {data?.autoApprove === '1' ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="subtitle2">Auto decline</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {data?.autoReject === '1' ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="subtitle2">Assigned team</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {handleDisplayValues(dealerMap[0]?.assignedTeam, data?.assignedTeam)}
              </Typography>
              <Typography variant="subtitle2">Language</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {handleDisplayValues(dealerMap[0]?.language, data?.language)}
              </Typography>
              <Typography variant="subtitle2">Auto decline team</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {handleDisplayValues(dealerMap[0]?.autoDeclineTeam, data?.autoDeclineTeam)}
              </Typography>
              <Typography variant="subtitle2">Last review date</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.lastReviewDate)}
              </Typography>
              <Typography variant="subtitle2">Next review date</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.nextReviewDate)}
              </Typography>
              <Typography variant="subtitle2">Dealer reserve plan</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {handleDisplayValues(dealerMap[0]?.dealerReservePlan, data?.dealerReservePlanTxt)}
              </Typography>
              <Typography variant="subtitle2">SLA risk time</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.slaAtRiskMinsNum)}
              </Typography>
              <Typography variant="subtitle2">SLA failed time</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.slaFailedMinsNum)}
              </Typography>
              <Typography variant="subtitle2">Used incentive</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.usedIncentive)}
              </Typography>
              <Typography variant="subtitle2">New incentive</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {replaceNullValues(data?.newIncentive)}
              </Typography>
            </Box>
          </CustomCollapse>
        </Box>
      </Paper>
    </>
  );
};

ViewDealer.propTypes = {
  dealer: PropTypes.instanceOf(Object),
};
ViewDealer.defaultProps = {
  dealer: {},
};
