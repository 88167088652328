import { Box, Grid, Link, Paper, TableHead, Tooltip, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import EditIcon from 'assets/svg/EditIcon.svg';
import DeleteIcon from 'assets/svg/deleteIcon.svg';
import SingleOfferModal from 'pages/Offers/SingleOfferModal';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';
import { defaultFn } from 'utils/utility';

import TablePagination from '../RegisterUser/TablePagination';
import DeletePromotionsDialog from './DeletePromotionsDialog';

export function TableCellTypography({ children }) {
  return <Typography variant="subtitle2">{children}</Typography>;
}

TableCellTypography.propTypes = {
  children: PropTypes.node,
};

TableCellTypography.defaultProps = {
  children: <></>,
};

export function TableHeaderTypography({ children }) {
  return (
    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
      {children}
    </Typography>
  );
}

TableHeaderTypography.propTypes = {
  children: PropTypes.node,
};

TableHeaderTypography.defaultProps = {
  children: <></>,
};

const PromotionsTable = ({
  promotionData,
  page,
  setPage,
  hasPagination,
  emptyDataMessage,
  callbackApi,
}) => {
  const navigate = useNavigate();
  const rowsPerPage = 10;
  const [showPromotion, setShowPromotion] = useState(null);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const paginate = (_, pageNumber) => {
    setPage(pageNumber);
  };
  const indexOfLastPost = page * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const calcUserList = (userArr) => {
    return Array.isArray(userArr) ? userArr?.slice(indexOfFirstPost, indexOfLastPost) : [];
  };

  const promotionsTableHeading = [
    'Promotion title',
    'Promotion description',
    'Effective date',
    'Expiration date',
    'Grace period',
    'Promotion associated to dealers',
    'Actions',
  ];

  const returnDealerName = (data) => {
    const dealerNameValues = Object.values(JSON.parse(data || '{}')).join(',');
    if (dealerNameValues.length > 25) {
      return dealerNameValues.slice(0, 25) + '...';
    } else return dealerNameValues;
  };
  const onOpen = (eachOffer) => {
    setShowPromotion(eachOffer);
  };

  return (
    <>
      {open && (
        <DeletePromotionsDialog open={open} setOpen={setOpen} id={id} callbackApi={callbackApi} />
      )}
      <Grid
        container
        sx={{
          overflowX: 'auto',
          width: '100%',
        }}
      >
        <Paper sx={{ width: '100%', boxShadow: 'none' }}>
          {showPromotion !== null && (
            <SingleOfferModal showPromotion={showPromotion} setShowPromotion={setShowPromotion} />
          )}
          <Table aria-label="applications-table">
            <TableHead>
              <TableRow
                sx={{ backgroundColor: '#F2F2F2' }}
                className={classes.tableHeadingApplications}
              >
                {promotionsTableHeading?.map((x) => {
                  return (
                    <TableCell key={x}>
                      <TableHeaderTypography>{x}</TableHeaderTypography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {calcUserList(promotionData)?.map((row) => (
                <TableRow
                  key={row.promotionId}
                  className={classes.tableHeadingApplications}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" onClick={() => onOpen(row)} tabIndex="0">
                    <Link
                      component="button"
                      variant="subtitle2"
                      sx={{
                        color: '#0065CC',
                        textDecoration: 'initial',
                        textAlign: 'left',
                      }}
                    >
                      {row?.promotionTitle}
                    </Link>
                  </TableCell>

                  <TableCell>
                    <TableCellTypography>{row?.promotionDescription}</TableCellTypography>
                  </TableCell>
                  <TableCell>
                    <TableCellTypography>{row?.effectiveDate}</TableCellTypography>
                  </TableCell>
                  <TableCell>
                    <TableCellTypography>{row?.expirationDate}</TableCellTypography>
                  </TableCell>
                  <TableCell>
                    <TableCellTypography>{row?.gracePeriod}</TableCellTypography>
                  </TableCell>
                  <TableCell>
                    <TableCellTypography>
                      <Tooltip title={row?.associatedDealers} placement="bottom" arrow>
                        {returnDealerName(row?.associatedDealers)}
                      </Tooltip>
                      {console.log(row.associatedDealers)}
                    </TableCellTypography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" display="flex">
                      <Link
                        color="secondary"
                        display="flex"
                        data-testid={`deleteApplication-${row?.promotionId}`}
                        onClick={() => {
                          setOpen(true);
                          setId(row.promotionId);
                        }}
                        sx={{ cursor: 'pointer' }}
                      >
                        <img src={DeleteIcon} />
                        &nbsp;
                        <Typography variant="subtitle2" component="p">
                          Delete
                        </Typography>
                      </Link>
                      &nbsp; &nbsp;
                      <Link
                        color="secondary"
                        display="flex"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          navigate('/create-promotion', {
                            state: { promotion: row, editFlag: true },
                          });
                        }}
                      >
                        <img src={EditIcon} />
                        &nbsp;
                        <Typography variant="subtitle2" component="p">
                          Edit
                        </Typography>
                      </Link>
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}

              {promotionData?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={12} sx={{ fontSize: '14px !important', textAlign: 'center' }}>
                    {emptyDataMessage}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Box sx={{ margin: '15px' }}>
        {hasPagination && (
          <TablePagination
            postsPerPage={10}
            totalPosts={promotionData?.length}
            paginate={paginate}
            page={page}
          />
        )}
      </Box>
    </>
  );
};

PromotionsTable.propTypes = {
  promotionData: PropTypes.instanceOf(Object),
  draftData: PropTypes.instanceOf(Object),
  page: PropTypes.number,
  hasPagination: PropTypes.bool,
  draftsTable: PropTypes.bool,
  setPage: PropTypes.func,
  emptyDataMessage: PropTypes.string,
  notifyAppId: PropTypes.string,
  callbackApi: PropTypes.func,
};

PromotionsTable.defaultProps = {
  promotionData: [],
  draftData: [],
  page: 1,
  setPage: defaultFn,
  hasPagination: false,
  draftsTable: false,
  emptyDataMessage: '',
  notifyAppId: '',
  callbackApi: defaultFn,
};

export default PromotionsTable;
