import * as yup from 'yup';

export const editDealerUserSchema = yup.object().shape({
  userId: yup.string(),
  firstName: yup
    .string()
    .nullable()
    .test('', 'Invalid Format', (val) => {
      return val?.length ? /^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\s]*$/g.test(val) : true;
    })
    .test('len', 'Maximum 25 characters can be entered', (val) => {
      console.log('abcdef', val);

      return val ? val?.length <= 25 : true;
    }),

  lastName: yup
    .string()
    .nullable()
    .test('', 'Invalid Format', (val) => {
      return val?.length ? /^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\s]*$/g.test(val) : true;
    })
    .test('len', 'Maximum 25 characters can be entered', (val) => {
      return val ? val?.length <= 25 : true;
    }),

  secondLastName: yup
    .string()
    .nullable()
    .test('len', 'Maximum 25 characters can be entered', (val) => (val ? val?.length <= 25 : true))
    .matches(/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡.'\s]*$/g, 'Invalid format'),
  email: yup
    .string()
    .required('* Mandatory field')
    .test('', 'Invalid Format', (val) => {
      return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(val);
    })
    .max(50, '* Invalid email length'),
  mobile: yup
    .string()
    .nullable()
    .test('', 'Invalid Format', (val) => {
      return val ? /^\d+$/g.test(val) : true;
    })
    .test('length', '* Mobile number should have 10 digits', (val) => {
      return val ? val?.length === 10 : true;
    }),
  includeSalesManager: yup.string(),
  includeReference: yup.string(),
});
