/* eslint-disable no-unused-vars */
import { Box, Button, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { currencyToNumber, defaultFn, numberToCurrency } from 'utils/utility';

import Loader from 'components/Loader/Loader';

import classes from '../../styles/resultPage.module.scss';
import Results from './Results';

const ResultPage = ({
  state,
  watch,
  getValues,
  apiErr,
  handleComputeError,
  setApiErr,
  errors,
  setIsPromotionExpired,
}) => {
  let values = state;

  const navigate = useNavigate();
  const ApiClient = new ApiClass();
  const [loader, setLoader] = useState(false);
  const [payScheduleFinance, setPayScheduleFinance] = useState([]);

  const {
    createAppApplicationId,
    setOpenPopUp,
    createAppLookup,
    setFinanceInfo,

    createAppFlag,
  } = useContext(AuthContext);

  useEffect(() => {
    if (values?.flowData?.data?.calculatedData?.paymentSchedules) {
      setPayScheduleFinance(values?.flowData?.data?.calculatedData?.paymentSchedules);
    } else {
      setPayScheduleFinance([]);
    }
  }, [createAppFlag]);
  let finalFormData = { ...values };
  finalFormData.formData.contractDate = moment(values?.formData?.contractDate).format('MM/DD/YYYY');
  const FinanceTermValues = {
    calculatedData: values?.data,
    formData: finalFormData?.formData,
    apr: values?.apr,
    baloonPayment: currencyToNumber(values?.baloonPayment),
  };
  console.log({ FinanceTermValues, testingOne: values });
  console.log({ checkone: values?.flowData?.flow, checktwo: apiErr, checkthree: errors });

  useEffect(() => {
    if (createAppLookup === 'calc' && values?.flowData?.flow !== 'financialTerms') {
      setOpenPopUp(true);

      setFinanceInfo({ ...FinanceTermValues });
    }
  }, [createAppLookup]);

  const handleSaveAndContinueError = async () => {
    const checkIsDisabled = await handleComputeError();
    if (checkIsDisabled && Object.entries(values?.flowData)?.length > 0) {
      setApiErr('Please click on calculate for deal terms with updated inputs');
      setIsPromotionExpired(false);
      return true;
    }
    return false;
  };
  const handleSaveApplicationDetails = async () => {
    const err = await handleSaveAndContinueError();
    console.log('unikk', { apiErr, err });
    if (Object.entries(values.formData).length < 2) {
      FinanceTermValues.formData = values?.flowData?.data?.formData;
      FinanceTermValues.calculatedData = values?.flowData?.data?.calculatedData;
      FinanceTermValues.apr = values?.flowData?.data?.apr;
      FinanceTermValues.baloonPayment = currencyToNumber(values?.flowData?.data?.baloonPayment);
    }

    console.log('final', FinanceTermValues);
    if (!err) {
      try {
        setLoader(true);
        let calculate = await ApiClient.post(
          UrlHelpers.financeTermsPayCalcSave,
          {
            applicationId: createAppApplicationId,
            type: 'FinanceTermPayCalc',
            searchResult: JSON.stringify(FinanceTermValues),
          },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        if (calculate) {
          setLoader(false);
          navigate('/home/create-app', {
            state: {
              activeStep: 4,
              coApplicant: values?.flowData?.coApplicant,
              coSigner: values?.flowData?.coSigner,
              calculationState: 'success',
              completedSteps: values?.flowData?.completedSteps,
              reviewFlag: values?.flowData?.reviewFlag,
              viewAppFlow: values?.flowData?.viewAppFlow,
              unfilled: values?.flowData?.unfilled,
            },
          });
        }
      } catch (e) {
        console.log('error fetching', e);
      } finally {
        setLoader(false);
      }
    }
  };
  const paymentSchedules = values?.data?.paymentSchedules
    ? values?.data?.paymentSchedules
    : payScheduleFinance;
  return (
    <>
      <div className={classes.resultContainer}>
        <Box className={classes.resultContent}>
          <Typography variant="subtitle1" component="h3" my={2} sx={{ fontWeight: 700 }}>
            Estimated monthly payment
          </Typography>
        </Box>

        <Results values={values} watch={watch} getValues={getValues} />
        <Loader open={loader} />

        <Box container mt={5}>
          <Typography
            variant="subtitle1"
            component="h3"
            my={2}
            color="#fff"
            sx={{ fontWeight: 700 }}
          >
            Payment schedule
          </Typography>

          <Table
            aria-label="payment-schedule-table"
            id="payment-schedule-table"
            className={classes.tableContainer}
          >
            <TableBody>
              <TableRow>
                <TableCell sx={{ padding: '10px' }} classes={{ root: classes.TableHeading }}>
                  No. of payments
                </TableCell>
                <TableCell sx={{ padding: '10px' }} classes={{ root: classes.TableHeading }}>
                  Payment amount
                </TableCell>
                <TableCell sx={{ padding: '10px' }} classes={{ root: classes.TableHeading }}>
                  Start date
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              {paymentSchedules?.map((row) => (
                <TableRow key={row?.startDate}>
                  <TableCell classes={{ root: classes.TableCell }}>{row?.noOfPayments}</TableCell>
                  <TableCell classes={{ root: classes.TableCell }}>
                    {' '}
                    {numberToCurrency(row?.paymentAmount?.toFixed?.(2))}
                  </TableCell>
                  <TableCell classes={{ root: classes.TableCell }}>
                    {moment(row?.startDate).format('MM/DD/yyyy')}
                  </TableCell>
                </TableRow>
              )) || (
                <TableRow>
                  <TableCell classes={{ root: classes.TableCell }}>--</TableCell>
                  <TableCell classes={{ root: classes.TableCell }}>--</TableCell>
                  <TableCell classes={{ root: classes.TableCell }}>--</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </div>
      {values?.flowData?.flow && (
        <Box display="flex" justifyContent="flex-end" my={3}>
          <Button
            variant="contained"
            id="calc-save-continue"
            color="secondary"
            disabled={paymentSchedules?.length === 0 || apiErr || Object.entries(errors).length}
            onClick={handleSaveApplicationDetails}
            data-testid="save-continue-to-application"
          >
            Save value & continue to application
          </Button>
        </Box>
      )}
    </>
  );
};

export default ResultPage;

ResultPage.propTypes = {
  state: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  setShowResults: PropTypes.func,
  setApiErr: PropTypes.func,
  watch: PropTypes.func,
  getValues: PropTypes.func,
  apiErr: PropTypes.bool,
  handleComputeError: PropTypes.func,
  setIsPromotionExpired: PropTypes.func,
};
ResultPage.defaultProps = {
  state: {},
  errors: {},
  setShowResults: defaultFn,
  watch: defaultFn,
  getValues: defaultFn,
  setApiErr: defaultFn,
  apiErr: false,
  handleComputeError: defaultFn,
  setIsPromotionExpired: defaultFn,
};
