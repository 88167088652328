export const valueGuideOpt = {
  collateralType: [
    {
      name: 'New',
      value: 'new',
      valueGuide: [
        {
          name: 'Distributor Data',
          value: 'Distributor Data',
        },
        {
          name: 'Black Book',
          value: 'Blackbook',
        },
      ],
    },
    {
      name: 'Used',
      value: 'used',
      valueGuide: [
        {
          name: 'Black Book',
          value: 'Blackbook',
          selected: true,
        },
      ],
    },
  ],
};
export const programOptions = [
  {
    label: 'Conventional',
    value: 'conventional',
  },
  {
    label: 'Oriental A+',
    value: 'oriental',
  },
];

export const loanPurposeOpt = [
  {
    name: 'New Loan',
    value: 'new',
  },
  {
    name: 'Refinance',
    value: 'refinance',
  },
  {
    name: 'Transfer',
    value: 'ttransfer',
  },
];
export const programOpt = {
  program: [
    {
      name: 'Oriental A+',
      value: 'oriental',
    },
    {
      name: 'Conventional',
      value: 'conventional',
    },
  ],
};
export const empStatusOpt = [
  {
    name: 'Housewife',
    value: 'housewife',
  },
  {
    name: 'Full-time employee',
    value: 'fullTimeEmployee',
  },
  {
    name: 'Part-time employee',
    value: 'partTimeEmployee',
  },
  {
    name: 'Bank employee',
    value: 'bankEmployee',
  },

  {
    name: 'Self-employed',
    value: 'selfEmployed',
  },
  {
    name: 'Retired',
    value: 'retired',
  },
  {
    name: 'Other',
    value: 'other',
  },
];

export const newUsed = [
  {
    label: 'New',
    value: 'new',
  },
  {
    label: 'Used',
    value: 'used',
  },
];

export const promotions = [
  {
    name: 'ACH2021',
    value: 'ACH2021',
  },
];

export const OccupationListEN = [
  'Attorney/ Notary',

  'Insurance Agent',

  'Surveyor',

  'Agronomist/ Farmer',

  'Adjuster',

  'Housewife',

  'Arquitect',

  'Artist',

  'Graphic Artist',

  'Auditor',

  'Banker',

  'Barber/ Stylist',

  'Bartender',

  'Lottery Ticket Seller',

  'Fireman',

  'Broker',

  'Trucker',

  'Chef',

  'Scientist/ Biologist/ Chemist',

  'Trader',

  'Financial Consultant',

  'Accountant/ CPA',

  'Contractor',

  'Real Estate Broker',

  'Dentist/ Orthodoncist',

  'Developer',

  'Unemployed',

  'Detective/ Investigator',

  'Business Owner',

  'Electrician',

  'Cleaning Employee',

  'State Government Employee',

  'Federal Government Employee',

  'Nurse',

  'Beautician',

  'Student',

  'Pharmacist',

  'Manager',

  'Security Guard/ Police',

  'Handyman',

  'Engineer',

  'Investigador/ Detective',

  'Jewelry',

  'Judge',

  'Teacher/ Professor',

  'Mechanic',

  'Doctor',

  'Messanger',

  'Soldier',

  'Pastor/ Priest',

  'Administrative Personnel',

  'Plumber',

  'Politician',

  'President/ VP/ Executive',

  'Computer Programmer',

  'Medical Propagandist',

  'Pshycologist/ Social Worker',

  'Public Relations',

  'Appraiser',

  'Medical Technician',

  'Seller',

  'Auto Dealer',

  'Veteran',

  'Veterinary',

  'Minor',

  'Artesan',

  'Singer',

  'Administrative Assistant',

  'Sportsman',

  'Director',

  'Waiter',

  'Clerk',

  'Operator',

  'Journalist',

  'Promotions',

  'Receptionist',

  'Retired/ Pensioned',

  'Secretary',

  'Customer Service Agent',

  'Supervisor',

  'Company Administrator',

  'Lottery Agent',

  'Farmer/ Fisherman',

  'Construction Employee',

  'Diplomatic',

  'Housekeeper',

  'Stewardess/ Model',

  'Butcher',

  'Carpenter',

  'Professor',

  'Chofer/ Taxi Driver',

  'Child Care',

  'Docker',

  'Floricultist',

  'Tourist Guide',

  'Tinsmith',

  'Independent',

  'Landscaping/ Maintenance',

  'Police/ Security',

  'Public Carrier',

  'Marketing Professional',

  'Art Professional',

  'Investor',

  'Welder',

  'Technician',

  'Nail Technician',

  'Captain',

  'Pilot',

  'Other',
];

export const OccupationListES = () => [
  'Abogado/Notario',

  'Agente de Seguros',

  'Agrimensor',

  'Agrónomo/ Agricultor',

  'Ajustador',

  'Ama de Casa',

  'Arquitecto',

  'Artista/ Artesano',

  'Artista Gráfico',

  'Auditor',

  'Banquero',

  'Barbero/ Estilista',

  'Bartender',

  'Billetero Lotería',

  'Bombero',

  'Broker',

  'Camionero/ Gruero',

  'Chef/ Cocinero',

  'Cientifíco/ Biólogo/ Químico',

  'Comerciante',

  'Consultor Financiero',

  'Contador/ CPA',

  'Contratista',

  'Corredor Real Estate',

  'Dentista/ Ortodoncista',

  'Desarrollador',

  'Desempleado',

  'Detective/ Investigador',

  'Dueño Negocio Propio',

  'Electricista',

  'Empleado de Limpieza',

  'Empleado Gobierno Estatal',

  'Empleado Gobierno Federal',

  'Enfermera',

  'Esteticista',

  'Estudiante',

  'Farmacéutico',

  'Gerente',

  'Guardia Seguridad/ Policia',

  'Handyman/ Obrero',

  'Ingeniero',

  'Investigador/ Detective',

  'Joyero',

  'Juez/ Fiscal',

  'Maestro/ Profesor',

  'Mecánico',

  'Médico',

  'Mensajero',

  'Militar',

  'Pastor/ Sacerdote',

  'Personal Administrativo',

  'Plomero',

  'Político',

  'Presidente/ VP/ Ejecutivo',

  'Programador Computadoras',

  'Propagandista Médico',

  'Psicólogo/ Trabajador Social',

  'Relacionista Público',

  'Tasador',

  'Tecnólogo Médico',

  'Vendedor',

  'Vendedor de Autos',

  'Veterano',

  'Veterinario',

  'Menor',

  'Artesano',

  'Artista/ Cantante',

  'Asistente Administrativa',

  'Deportista',

  'Director',

  'Mesero',

  'Oficinista',

  'Operador',

  'Periodista',

  'Promociones',

  'Recepcionista',

  'Retirado/ Pensionado',

  'Secretaria',

  'Servicio al Cliente',

  'Supervisor',

  'Administrador Empresa',

  'Agente Lotería',

  'Agricultor/ Pescador',

  'Empleado Construcción',

  'Diplomático',

  'Ama de Llaves',

  'Azafata/ Modelo',

  'Carnicero',

  'Carpintero',

  'Catedrático/ Profesor Universitario',

  'Chofer/ Taxista',

  'Cuido Niños',

  'Estibador',

  'Floricultor',

  'Guía Turístico',

  'Hojalatero',

  'Independiente',

  'Jardinero/ Mantenimiento',

  'Policía/ Seguridad',

  'Porteador Público',

  'Profesional Mercadeo',

  'Profesionales Arte',

  'Rentas/ Inversionista',

  'Soldador',

  'Técnico/ Diestro',

  'Técnico de Uñas',

  'Capitan',

  'Piloto',

  'Otro',
];

export const VehicleClass = [
  {
    label: 'Local',
    value: 'local',
  },
  {
    label: 'Import',
    value: 'import',
  },
];
export const vehicleDetails = {
  year: [
    {
      name: '2021',
      value: 2021,
      make: [
        {
          name: 'Kia',
          value: 'kia',
          model: [
            {
              name: 'Seltos',
              value: 'seltos',
              detail: [
                { name: 'SPL1-21', value: 'SPL1-21' },
                { name: 'SPS1-21', value: 'SPS1-21' },
              ],
            },
            {
              name: 'Sportage',
              value: 'sportage',
              detail: [
                { name: 'QLE1-21', value: 'QLE1-21' },
                { name: 'QLL1-21', value: 'QLL1-21' },
              ],
            },
          ],
        },
        {
          name: 'Honda',
          value: 'honda',
          model: [
            {
              name: 'CRV',
              value: 'crv',
              detail: [
                { name: 'RW1H2MEW', value: 'RW1H2MEW' },
                { name: 'RW3H2MEW', value: 'RW3H2MEW' },
              ],
            },
            {
              name: 'HRV',
              value: 'hrv',
              detail: [
                { name: 'RU5H1MEXW', value: 'RU5H1MEXW' },
                { name: 'RU5H3MEXW', value: 'RU5H3MEXW' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: '2022',
      value: 2022,
      make: [
        {
          name: 'Kia',
          value: 'kia',
          model: [
            {
              name: 'Seltos',
              value: 'seltos',
              detail: [
                { name: 'SPL1-22', value: 'SPL1-22' },
                { name: 'SPS1-22', value: 'SPS1-22' },
              ],
            },
            {
              name: 'Sportage',
              value: 'sportage',
              detail: [
                { name: 'QLE1-22', value: 'QLE1-22' },
                { name: 'QLL1-22', value: 'QLL1-22' },
              ],
            },
          ],
        },
      ],
    },
  ],
};
export const conventionalTerm = [
  {
    name: '24',
    value: 24,
  },
  {
    name: '36',
    value: 36,
  },
  {
    name: '48',
    value: 48,
  },
  {
    name: '60',
    value: 60,
  },
  {
    name: '72',
    value: 72,
  },
  {
    name: '84',
    value: 84,
  },
];
export const orientalTerm = [
  {
    name: '10',
    value: '10',
  },
  {
    name: '20',
    value: '20',
  },
  {
    name: '30',
    value: '30',
  },
];
export const creditLifeTypeMock = {
  'credit-life-type':
    '[{\n\t\t"displayName": "Single",\n\t\t"value": "Single",\n\t\t"locale": {\n\t\t\t"es": "Single"\n\t\t}\n\t},\n\t{\n\t\t"displayName": "Joint",\n\t\t"value": "Joint",\n\t\t"locale": {\n\t\t\t"es": "Joint"\n\t\t}\n\t}\n]',
};

export const vehicleInsuranceTypeMock = {
  'vehicle-insurance-type':
    '[{\n\t\t"displayName": "Single",\n\t\t"value": "Single",\n\t\t"locale": {\n\t\t\t"es": "Single"\n\t\t}\n\t},\n\t{\n\t\t"displayName": "Double",\n\t\t"value": "Double",\n\t\t"locale": {\n\t\t\t"es": "Double"\n\t\t}\n\t},\n\t{\n\t\t"displayName": "Full",\n\t\t"value": "Full",\n\t\t"locale": {\n\t\t\t"es": "Full"\n\t\t}\n\t}\n]',
};
