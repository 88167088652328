import { Grid, Paper, Typography } from '@mui/material';
import { getAccessToken, getAppAccessToken } from 'Services/commonComponentsApi';
import AuthContext from 'context/AuthContext';
import InvitationFlowContext from 'context/InvitationFlowContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classes from 'styles/InviInformationRequest.module.scss';
import globalClasses from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import Loader from 'components/Loader/Loader';
import SkipLink from 'components/SkipLink';
import HorizontalLinearStepper from 'components/Stepper/Stepper';

import { InvitationExpiryCheck, invitationStatusCheck } from './InviCommonUtilities';
import InviHeader from './InviHeader';
import InviRenderStep from './InviRenderStep';
import InvitationExpired from './InvitationExpired';
import ThankyouScreen from './ThankyouScreen/ThankyouScreen';

const InvitationFlow = () => {
  const location = useLocation();
  let {
    scannedData = {},
    activeStep: activeStepState = 0,
    completedSteps: completedStepsState = [],
    vouchedFlag = false,
    formFields: formFieldsState = {},
  } = location?.state || {};
  const steps = ['Basic information', 'Employment information', 'Your consent'];
  const [activeStep, setActiveStep] = useState(activeStepState);
  const [completedSteps, setCompletedSteps] = useState(completedStepsState);
  const [vouchedReturnFlag, setVouchedReturnFlag] = useState(vouchedFlag);
  const [vouchedData, setVouchedData] = useState(scannedData);
  const [isInviExpired, setIsInviExpired] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isInvitationFlowMounted, setIsInvitationFlowMounted] = useState(false);
  const [isGetDetailsDone, setIsGetDetailsDone] = useState(false);

  const [onBackClick, setOnBackClick] = useState(false);
  const [thankyouScreen, setThankyouScreen] = useState(false);
  const [formFields, setFormFields] = useState(formFieldsState);
  const [empInfoformFields, setEmpInfoFormFields] = useState({});
  const [getDetailsData, setGetDetailsData] = useState('{}');
  const [loader, setLoader] = useState(false);
  const [invitationExpiredHeading, setInvitationExpiredHeading] = useState('');
  const [invitationExpiredContent, setInvitationExpiredContent] = useState('');
  const {
    setCommonToken,
    setAccessToken,
    inviUserType,
    inviApplicationNum,
    inviApplicantId,
    accessToken,
    userID,
    fullName,
  } = useContext(AuthContext);

  const handleLandingPage = async ({ infoReqCondition, empInfoCondition }) => {
    if (!infoReqCondition) {
      setCompletedSteps([]);
      setActiveStep(1);
    } else if (infoReqCondition && !empInfoCondition) {
      setCompletedSteps([1]);
      setActiveStep(2);
    } else if (infoReqCondition && empInfoCondition) {
      setCompletedSteps([1, 2]);
      setActiveStep(3);
    }
  };

  const landingPageBasedOnUser = async ({ getDetails }) => {
    if (inviUserType === 'primaryApplicant') {
      await handleLandingPage({
        infoReqCondition: getDetails?.applicants?.informationRequest?.participantDetailInfo,
        empInfoCondition: getDetails?.applicants?.employmentInformation,
      });
    } else if (inviUserType.toLowerCase() === 'coapplicant1') {
      await handleLandingPage({
        infoReqCondition:
          getDetails?.applicants?.coApplicants[0]?.informationRequest?.participantDetailInfo,
        empInfoCondition: getDetails?.applicants?.coApplicants[0]?.employmentInformation,
      });
      console.log('coapp');
    } else if (
      inviUserType.toLowerCase() === 'cosigner1' ||
      inviUserType.toLowerCase() === 'cosigner2'
    ) {
      let i;
      if (inviApplicantId === getDetails?.applicants?.coSigners[0]?.applicantId) {
        i = 0;
      } else if (inviApplicantId === getDetails?.applicants?.coSigners[1]?.applicantId) {
        i = 1;
      }
      await handleLandingPage({
        infoReqCondition:
          getDetails?.applicants?.coSigners[i]?.informationRequest?.participantDetailInfo,
        empInfoCondition: getDetails?.applicants?.coSigners[i]?.employmentInformation,
      });
      console.log('cosign1');
    }
  };

  useEffect(async () => {
    let tokenResponse = await getAppAccessToken();
    console.log('tokenResponse', { tokenResponse });
    sessionStorage.setItem('accessToken', tokenResponse);
    sessionStorage.setItem('RoleId', 'Service Account');
    setAccessToken(tokenResponse);
    const token = await getAccessToken();
    setCommonToken(token);
  }, []);
  const handleGetDetails = async () => {
    let data;
    if (!isInviExpired) {
      data = await InvitationExpiryCheck({
        accessToken,
        setLoader,
        setIsInviExpired,
        setInvitationExpiredHeading,
        setInvitationExpiredContent,
        inviApplicationNum,
        isFromInviFlow: true,
        inviApplicantId,
        setIsGetDetailsDone,
        setGetDetailsData,
        userID,
        fullName,
      });
    }
    if (data) {
      let getDetails = JSON.parse(data?.applicationData || '{}');

      if (accessToken && !isInviExpired) {
        const invitationStatus = await invitationStatusCheck({
          accessToken: accessToken,
          setLoader,
          inviApplicationNum,
          userID,
          fullName,
        });
        const respAppNumber = invitationStatus?.[inviApplicationNum] || {};

        let user = Object.keys(respAppNumber)?.filter((x) => respAppNumber?.[x] === true);
        user?.forEach((x) => {
          if (x?.toString()?.includes(inviUserType)) {
            setIsInviExpired(true);
            setInvitationExpiredHeading('Invitation submitted');
            setInvitationExpiredContent(
              'The link is no longer active as the invitation is already submitted and the data is appended to the application.'
            );
            setIsFormSubmitted(true);
          }
        });
        await landingPageBasedOnUser({ getDetails });
      }
    }
  };
  useEffect(async () => {
    if (invitationExpiredHeading !== 'Invitation submitted') {
      await handleGetDetails();
      setIsInvitationFlowMounted(true);
    }
  }, [accessToken, invitationExpiredHeading]);
  const inviContextValue = useMemo(
    () => ({
      activeStep,
      setActiveStep,
      scannedData: vouchedData,
      completedSteps,
      setCompletedSteps,
      vouchedReturnFlag,
      setVouchedReturnFlag,
      vouchedData,
      setVouchedData,
      formFields,
      setFormFields,
      isInviExpired,
      setIsInviExpired,
      onBackClick,
      setOnBackClick,
      empInfoformFields,
      setEmpInfoFormFields,
      thankyouScreen,
      setThankyouScreen,
      getDetailsData,
      setGetDetailsData,
      invitationExpiredHeading,
      setInvitationExpiredHeading,
      isFormSubmitted,
      setIsFormSubmitted,
      isInvitationFlowMounted,
      setIsInvitationFlowMounted,
      isGetDetailsDone,
      setIsGetDetailsDone,
      invitationExpiredContent,
      setInvitationExpiredContent,
    }),
    [
      activeStep,
      setActiveStep,
      scannedData,
      completedSteps,
      setCompletedSteps,
      vouchedReturnFlag,
      setVouchedReturnFlag,
      vouchedData,
      setVouchedData,
      formFields,
      setFormFields,
      isInviExpired,
      setIsInviExpired,
      onBackClick,
      setOnBackClick,
      empInfoformFields,
      setEmpInfoFormFields,
      thankyouScreen,
      setThankyouScreen,
      getDetailsData,
      setGetDetailsData,
      invitationExpiredHeading,
      setInvitationExpiredHeading,
      isFormSubmitted,
      setIsFormSubmitted,
      isInvitationFlowMounted,
      setIsInvitationFlowMounted,
      isGetDetailsDone,
      setIsGetDetailsDone,
      invitationExpiredContent,
      setInvitationExpiredContent,
    ]
  );

  useEffect(() => {
    const handleBeforeUnload = () => {
      const token = `Bearer ${sessionStorage.getItem('accessToken')}`;
      console.log('token', token);
      const RoleId = sessionStorage.getItem('RoleId');
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', `${UrlHelpers?.lockRelease}/?appid=${inviApplicationNum}`, true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('userName', inviApplicantId);
      xhr.setRequestHeader('userid', 'guest');
      xhr.setRequestHeader('Authorization', token);
      xhr.setRequestHeader('RoleID', RoleId);
      xhr.setRequestHeader('appId', 'auto');
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            console.log('success');
          } else {
            console.log('failure');
          }
        }
      };
      try {
        xhr.send();
      } catch (e) {
        console.log('api failed ...', e);
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  const invitationHeading = () => {
    let heading = 'You have been invited as an additional participant to provide your information';
    if (inviUserType?.toLowerCase() === 'primaryapplicant') {
      heading = 'You have been invited as a participant to provide your information';
    }
    return heading;
  };
  return (
    <>
      <InvitationFlowContext.Provider value={inviContextValue}>
        <SkipLink />
        <InviHeader />
        <Loader open={loader} />
        <div id="main-content">
          {(isInviExpired || isFormSubmitted) && !thankyouScreen && (
            <InvitationExpired
              invitationExpiredHeading={invitationExpiredHeading}
              invitationExpiredContent={invitationExpiredContent}
            />
          )}
          {thankyouScreen && !isInviExpired && !isFormSubmitted && <ThankyouScreen />}
          {!isInviExpired && !thankyouScreen && !isFormSubmitted && (
            <Grid container sx={{ flexGrow: 1 }}>
              <Grid container className={classes.pageContainer}>
                <Paper className={[classes.paper, globalClasses.infoRequestPaper].join(' ')}>
                  <Grid container className={classes.invitationHeading} rowSpacing={1}>
                    <Grid item lg={12} md={12} sm={12}>
                      {activeStep === 3 ? (
                        <Typography variant="h3" component="h3">
                          Your consent
                        </Typography>
                      ) : (
                        <Typography variant="h3" component="h3">
                          Auto loan - Invitation
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12}>
                      {activeStep === 3 ? (
                        <Typography variant="subtitle1" component="h6" fontWeight={700}>
                          Before we engage in this transaction, you must understand your rights and
                          responsibilities and provide your consent.
                        </Typography>
                      ) : (
                        <Typography variant="subtitle1" component="h6" fontWeight={700}>
                          {invitationHeading()}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={8} md={8} sm={12}>
                      <HorizontalLinearStepper
                        steps={steps}
                        activeStep={activeStep - 1}
                        completedSteps={completedSteps}
                      />
                    </Grid>
                  </Grid>
                  <InviRenderStep activeStep={activeStep} />
                </Paper>
              </Grid>
            </Grid>
          )}
        </div>
      </InvitationFlowContext.Provider>
    </>
  );
};
export default InvitationFlow;
