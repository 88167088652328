import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { currencyToNumber } from 'utils';
import { defaultFn, regexCharNum } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomNumberFormat from 'components/CustomNumberFormat/CustomNumberFormat';
import CustomRadioButton from 'components/CustomRadioButton/CustomRadioButton';

import classes from '../../styles/globalStyle.module.scss';
import constants from '../../utils/constants';

const { amountFinanced } = constants;

const FinancedAmount = ({ control, errors, setValue }) => {
  const [
    watchSalePrice,
    watchCashDown,
    watchRebate,
    watchCarCare,
    watchTradeDept,
    watchTradeAllowance,
    watchOther,
    watchServiceContract,
    watchExtendedWarranty,
    watchPaintProtection,
    watchNewOrUsed,
    watchVehicleClass,
    watchWishToTradeIn,
  ] = useWatch({
    control,
    name: [
      'salePrice',
      'cashDown',
      'rebate',
      'carCare',
      'tradeDept',
      'tradeAllowance',
      'other',
      'serviceContract',
      'extendedWarranty',
      'paintProtection',
      'newOrUsed',
      'vehicleClass',
      'wishToTradeIn',
    ],
  });
  console.log('watchSalePrice', watchSalePrice);
  console.log('errors ---1', errors);
  useEffect(() => {
    if (
      watchSalePrice ||
      watchCashDown ||
      watchCarCare ||
      watchOther ||
      watchRebate ||
      watchServiceContract ||
      watchExtendedWarranty ||
      watchPaintProtection ||
      watchTradeAllowance ||
      watchTradeDept
    ) {
      const value =
        currencyToNumber(watchSalePrice) -
        (currencyToNumber(watchTradeAllowance) - currencyToNumber(watchTradeDept)) -
        currencyToNumber(watchCashDown) -
        currencyToNumber(watchRebate) +
        currencyToNumber(watchCarCare) +
        currencyToNumber(watchOther) +
        currencyToNumber(watchServiceContract) +
        currencyToNumber(watchExtendedWarranty) +
        currencyToNumber(watchPaintProtection);

      setValue('balance', value);
    }
  }, [
    watchSalePrice,
    watchCashDown,
    watchCarCare,
    watchOther,
    watchServiceContract,
    watchExtendedWarranty,
    watchPaintProtection,
    watchRebate,
    watchTradeAllowance,
    watchTradeDept,
    setValue,
  ]);

  useEffect(() => {
    if (
      (watchNewOrUsed === 'new' && watchVehicleClass) ||
      (watchNewOrUsed === 'used' && watchVehicleClass === 'import')
    ) {
      const value =
        (currencyToNumber(watchSalePrice) + currencyToNumber(watchPaintProtection)) * 0.005 +
        15 +
        11;
      setValue('registrationfee', value);
    } else if (watchNewOrUsed === 'used' && watchVehicleClass === 'local') {
      const value =
        (currencyToNumber(watchSalePrice) +
          currencyToNumber(watchOther) +
          currencyToNumber(watchPaintProtection)) *
          0.005 +
        15 +
        30;
      setValue('registrationfee', value);
    }
  }, [
    watchSalePrice,
    watchOther,
    watchPaintProtection,
    watchNewOrUsed,
    watchVehicleClass,
    setValue,
  ]);
  useEffect(() => {
    if (watchTradeAllowance || watchTradeDept) {
      const value = currencyToNumber(watchTradeAllowance) - currencyToNumber(watchTradeDept);
      setValue('netTrade', value?.toFixed(2));
    }
  }, [watchTradeAllowance, watchTradeDept, setValue]);

  useEffect(() => {
    if (watchWishToTradeIn === 'no') {
      setValue('tradeDept', '');
      setValue('tradeAllowance', '');
      setValue('netTrade', '');
      setValue('year', '');
      setValue('make', '');
      setValue('model', '');
    }
  }, [watchWishToTradeIn]);
  return (
    <div>
      <CustomCollapse heading={amountFinanced} open={true} errors={errors}>
        <Grid container my={0.5} columnSpacing={4} rowSpacing={1}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="salePrice"
              id="salePrice"
              dataTestId="salePrice"
              label="Sale price"
              placeholder="Enter sale price"
              decimalScale={2}
              control={control}
              error={!!errors?.salePrice}
              errorText={errors?.salePrice?.message}
              thousandSeparator={true}
              prefix={'$ '}
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="cashDown"
              id="cashDown"
              dataTestId="cashDown"
              placeholder="Enter cash down"
              label="Cash down"
              decimalScale={2}
              control={control}
              error={!!errors?.cashDown}
              errorText={errors?.cashDown?.message}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="rebate"
              id="rebate"
              dataTestId="rebate"
              placeholder="Enter rebate"
              label="Rebate"
              decimalScale={2}
              control={control}
              error={!!errors?.rebate}
              errorText={errors?.rebate?.message}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="extendedWarranty"
              id="extendedWarranty"
              dataTestId="extendedWarranty"
              placeholder="Enter extended warranty"
              label="Extended warranty"
              decimalScale={2}
              control={control}
              error={!!errors?.extendedWarranty}
              errorText={errors?.extendedWarranty?.message}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="carCare"
              id="carCare"
              dataTestId="carCare"
              placeholder="Enter car care"
              label="Car care"
              decimalScale={2}
              control={control}
              error={!!errors?.carCare}
              errorText={errors?.carCare?.message}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="paintProtection"
              id="paintProtection"
              dataTestId="paintProtection"
              placeholder="Enter paint protection"
              label="Paint protection"
              decimalScale={2}
              control={control}
              error={!!errors?.paintProtection}
              errorText={errors?.paintProtection?.message}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="serviceContract"
              id="serviceContract"
              dataTestId="serviceContract"
              placeholder="Enter service contract"
              label="Service contract"
              decimalScale={2}
              control={control}
              error={!!errors?.serviceContract}
              errorText={errors?.serviceContract?.message}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="other"
              label="Other"
              id="other"
              dataTestId="other"
              placeholder="Enter other"
              decimalScale={2}
              control={control}
              error={!!errors?.other}
              errorText={errors?.other?.message}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomRadioButton
              control={control}
              radioValues={[
                {
                  name: 'Yes',
                  value: 'yes',
                },
                {
                  name: 'No',
                  value: 'no',
                },
              ]}
              label="Do you wish to trade-in a vehicle ?"
              required
              name="wishToTradeIn"
              dataTestId="wishToTradeIn"
              errors={!!errors?.wishToTradeIn}
              errorText={errors?.wishToTradeIn?.message}
              displayInline
            />
          </Grid>
          {watchWishToTradeIn === 'yes' && (
            <>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomNumberFormat
                  name="tradeDept"
                  id="tradeDept"
                  dataTestId="tradeDept"
                  placeholder="Enter trade in debt"
                  label="Trade in debt"
                  decimalScale={2}
                  control={control}
                  error={!!errors?.tradeDept}
                  errorText={errors?.tradeDept?.message}
                  thousandSeparator={true}
                  prefix={'$ '}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomNumberFormat
                  name="tradeAllowance"
                  id="tradeAllowance"
                  dataTestId="tradeAllowance"
                  placeholder="Enter trade in allowance"
                  label="Trade in allowance"
                  decimalScale={2}
                  control={control}
                  error={!!errors?.tradeAllowance}
                  errorText={errors?.tradeAllowance?.message}
                  thousandSeparator={true}
                  prefix={'$ '}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomNumberFormat
                  name="netTrade"
                  id="netTrade"
                  dataTestId="netTrade"
                  label="Net trade-in"
                  decimalScale={2}
                  control={control}
                  error={!!errors?.netTrade}
                  errorText={errors?.netTrade?.message}
                  disabled
                  thousandSeparator={true}
                  prefix={'$ '}
                  // displayType="text"
                  className={classes.customNumberFormat}
                  allowNegative
                  allowEmptyFormatting
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomInputBox
                  control={control}
                  errors={!!errors?.year}
                  errorText={errors?.year?.message}
                  placeholder="Enter Year"
                  label="Trade-in vehicle year"
                  name="year"
                  flexDirection="column"
                  maxLength="4"
                  regex={/^\d$/}
                  required
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomInputBox
                  control={control}
                  errors={!!errors?.make}
                  errorText={errors?.make?.message}
                  placeholder="Enter Make"
                  label="Trade-in Vehicle Make"
                  name="make"
                  flexDirection="column"
                  maxLength="30"
                  regex={regexCharNum}
                  required
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomInputBox
                  control={control}
                  errors={!!errors?.model}
                  errorText={errors?.model?.message}
                  placeholder="Enter Model"
                  label="Trade-in vehicle model"
                  name="model"
                  flexDirection="column"
                  maxLength="30"
                  regex={regexCharNum}
                  required
                />
              </Grid>
            </>
          )}
        </Grid>
        <hr style={{ marginTop: '16px' }} />
        <Grid container columnSpacing={4} rowSpacing={1}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="balance"
              id="balance"
              dataTestId="balance"
              label="Balance"
              decimalScale={2}
              control={control}
              error={!!errors?.balance}
              errorText={errors?.balance?.message}
              disabled
              thousandSeparator={true}
              prefix={'$ '}
              allowEmptyFormatting
            />
          </Grid>
        </Grid>
      </CustomCollapse>
    </div>
  );
};

export default FinancedAmount;

FinancedAmount.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  setValue: PropTypes.func,
  makeList: PropTypes.array,
};
FinancedAmount.defaultProps = {
  control: {},
  errors: {},
  setValue: defaultFn,
  makeList: [],
};
