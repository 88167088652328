import { Box, Button, Paper, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { bankerRolesArray, defaultFn, numberToCurrency } from 'utils/utility';

import Loader from 'components/Loader/Loader';

import GeneralInfo from '../components/GeneralInfo';
import PageHeading from '../components/PageHeading';

const DistributorDataResult = ({
  data,
  valueGuide,
  collateralType,
  coApplicant,
  coSigner,
  reviewFlag,
  calculationState,
  completedSteps,
  viewAppFlow,
}) => {
  const {
    createAppFlag,
    setCollateralInfo,
    setLookupSaved,
    createAppApplicationId,
    collateralInfo,
    lookupLinkClicked,
    setLookupValue,
    setCreateAppLookup,
    createAppLookup,
    setOpenPopUp,
    setCreateAppFlag,
    role,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const ApiClient = new ApiClass();
  const [loader, setLoader] = useState(false);
  const productDetails = { ...data, valueGuide, collateralType };
  const { dealerCost = 0, msrp = 0 } = data || {};

  useEffect(() => {
    const payload = {
      vehicleValues: {
        basevehicleValue: { msrp, dealerCost },
        equipmentAdjustment: '',
        mileageAdjustment: '',
        selectedEquipmentAdjustment: [],
        totalMSRP: msrp,
        totalInvoice: dealerCost,
      },
      productDetails: productDetails,
    };
    if (!createAppFlag && !lookupLinkClicked) {
      setLookupValue([{ ...payload }]);
    }
  }, [data]);

  useEffect(() => {
    if (createAppLookup === 'dd') {
      const payload = {
        vehicleValues: {
          basevehicleValue: { msrp, dealerCost },
          equipmentAdjustment: '',
          mileageAdjustment: '',
          selectedEquipmentAdjustment: [],
          totalMSRP: msrp,
          totalInvoice: dealerCost,
        },
        productDetails: productDetails,
      };
      setOpenPopUp(true);
      setCollateralInfo({ ...payload });
    }
  }, [createAppLookup]);

  const onSaveValue = async () => {
    const payload = {
      vehicleValues: {
        basevehicleValue: { msrp, dealerCost },
        equipmentAdjustment: '',
        mileageAdjustment: '',
        selectedEquipmentAdjustment: [],
        totalMSRP: msrp,
        totalInvoice: dealerCost,
      },
      productDetails: productDetails,
    };

    if (!lookupLinkClicked) {
      setCollateralInfo({ ...payload });
      try {
        setLoader(true);
        const resp = await ApiClient.post(
          UrlHelpers.saveLookupValue,
          {
            applicationId: createAppApplicationId,
            type: 'vehicle',
            searchResult: JSON.stringify({ ...payload }),
          },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        console.log(resp);
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoader(false);
      }
    }
    setLookupSaved(true);
    navigate('/home/create-app', {
      state: {
        activeStep: 3,
        completedSteps,
        coApplicant,
        coSigner,
        reviewFlag,
        calculationState,
        viewAppFlow,
      },
    });
  };

  return (
    <Paper sx={{ minHeight: '80vh', padding: '20px' }}>
      {loader && <Loader open={loader} />}
      <PageHeading
        collateralType="New"
        valueGuide="Distributor Data"
        showSummary={true}
        setCreateAppLookup={() => {
          setCreateAppLookup('dd');
          setCreateAppFlag(true);
        }}
      />
      <Typography fontSize="18px !important" fontWeight="700" sx={{ margin: '16px 0' }}>
        Vehicle values
      </Typography>
      <Box className={classes.equipAdjustment} sx={{ gap: { md: '16px' } }}>
        <Box display="flex" flexDirection="column" gap={2} flexGrow="1">
          <Box display="flex" flexDirection="column" className={classes.tableOutline} role="table">
            <Box
              display="flex"
              justifyContent="space-between"
              p={2}
              sx={{ backgroundColor: '#F2F2F2' }}
              role="row"
            >
              <Typography
                role="columnheader"
                sx={{
                  width: { sm: '50%', xs: '25%' },
                  fontWeight: '700',
                  marginLeft: '35px',
                }}
              ></Typography>

              {bankerRolesArray.includes(role) && (
                <Typography fontWeight="700" minWidth="25%" role="columnheader">
                  Dealer cost
                </Typography>
              )}

              <Typography fontWeight="700" minWidth="25%" role="columnheader">
                MSRP
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              p={2}
              sx={{ borderBottom: '1px solid #f2f2f2' }}
              role="row"
            >
              <Typography sx={{ width: { sm: '50%', xs: '25%' }, marginLeft: '35px' }} role="cell">
                Base vehicle value
              </Typography>

              {bankerRolesArray.includes(role) && (
                <Typography role="cell" minWidth="25%" sx={{ overflowWrap: 'break-word' }}>
                  {numberToCurrency(
                    parseInt(
                      (lookupLinkClicked
                        ? collateralInfo?.vehicleValues?.basevehicleValue?.dealerCost
                        : dealerCost) || 0
                    )?.toFixed?.(2)
                  )}
                </Typography>
              )}

              <Typography role="cell" minWidth="25%" sx={{ overflowWrap: 'break-word' }}>
                {numberToCurrency(
                  parseInt(
                    (lookupLinkClicked
                      ? collateralInfo?.vehicleValues?.basevehicleValue?.msrp
                      : msrp) || 0
                  )?.toFixed?.(2)
                )}
              </Typography>
            </Box>
            {/* <Box display="flex" gap={2} p={2}>
              <Typography  sx={{ minWidth: '50%', marginLeft: '35px' }}>
                Equipment adjustment
              </Typography>

              <Typography  minWidth="25%">
                $0.00
              </Typography>

              <Typography  minWidth="25%">
                $0.00
              </Typography>
            </Box>
            <Box display="flex" gap={2} p={2} sx={{ backgroundColor: '#F2F2F2' }}>
              <Typography
                
                sx={{ minWidth: '50%', marginLeft: '35px', fontWeight: 700 }}
              >
                Total Values
              </Typography>

              <Typography  fontWeight="700" minWidth="25%">
                {numberToCurrency(parseInt(dealerCost || 0)?.toFixed?.(2))}
              </Typography>

              <Typography  fontWeight="700" minWidth="25%">
                {numberToCurrency(parseInt(msrp || 0)?.toFixed?.(2))}
              </Typography>
            </Box> */}
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Box>
              {createAppFlag && (
                <Button
                  onClick={onSaveValue}
                  variant="contained"
                  color="secondary"
                  id="save-val-btn"
                >
                  Save value
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={2.5}
          className={classes.tableOutline}
          maxHeight="450px"
          minWidth="25%"
          backgroundColor="#f2f2f2"
          p={2.5}
        >
          <GeneralInfo
            productDetails={lookupLinkClicked ? collateralInfo?.productDetails : productDetails}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default DistributorDataResult;

DistributorDataResult.propTypes = {
  data: PropTypes.instanceOf(Object),
  valueGuide: PropTypes.string,
  collateralType: PropTypes.string,
  setShowPage: PropTypes.func,
  coApplicant: PropTypes.instanceOf(Object),
  coSigner: PropTypes.instanceOf(Object),
  reviewFlag: PropTypes.bool,
  calculationState: PropTypes.string,
  completedSteps: PropTypes.array,
  viewAppFlow: PropTypes.bool,
};
DistributorDataResult.defaultProps = {
  data: {},
  valueGuide: '',
  collateralType: '',
  setShowPage: defaultFn,
  coApplicant: {},
  coSigner: [],
  reviewFlag: false,
  calculationState: '',
  completedSteps: [],
  viewAppFlow: false,
};
