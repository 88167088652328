import * as yup from 'yup';

import {
  dobFormatValidation,
  maxYearValidation,
  minYearValidation,
  validDOBValidation,
} from './validationFunctions/validationFunctions';

export const editDealerUserStatusSchema = yup.object().shape({
  isActive: yup.string(),
  maximizeTty: yup.string(),
  ssn: yup
    .string()
    .nullable()
    .test('', 'Invalid Format', (val) => {
      return val?.length ? /^\d+$/g.test(val) : true;
    })
    .test('length', '* Enter last 4 digits of SSN', (val) => {
      return val?.length ? val?.length === 4 : true;
    }),
  dob: yup
    .string()
    .nullable()
    .test('', 'Correct date format is mm/dd/yyyy', (val) => {
      console.log('hey', val);

      return val ? dobFormatValidation(val) : true;
    })
    .test('max length', 'Please enter a valid dob', (val) => {
      return val ? validDOBValidation(val) : true;
    })
    .test('age', 'Applicant must be 18 years or older', (val) => {
      return val ? minYearValidation(val) : true;
    })
    .test('age > 100', 'Age of the applicant should be less than 150 years', (val) => {
      return val ? maxYearValidation(val) : true;
    }),
  vendorNumber: yup
    .string()
    .nullable()
    .matches(/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\d\s]*$/, '* No Special Characters allowed')
    .test('len', 'Maximum 11 characters can be entered', (val) => (val ? val?.length <= 11 : true)),
});
