import { Box, Link, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import ApiClass from 'Api/ApiClient';
import AppSubmitted from 'assets/images/app-submitted.png';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import { useContext, useEffect, useState } from 'react';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { useInterval } from 'utils/useInterval';

const ApplicationSubmitted = () => {
  const { setAppApproved, setLoanStatus, caseReferenceId } = useContext(MainApplicationContext);
  const { createAppApplicationId } = useContext(AuthContext);
  const [progress, setProgress] = useState(0);
  const [pollTimeout, setPollTimeout] = useState(false);
  const [delay, setDelay] = useState(60000);
  const ApiClient = new ApiClass();

  useInterval(
    async () => {
      const resp = await ApiClient.get(
        `${UrlHelpers.loanStatus}?applicationId=${createAppApplicationId}`
      );
      if (
        resp.LoanStatus === 'APPROVE' ||
        resp.LoanStatus === 'APPSCOR' ||
        resp.LoanStatus === 'APPCOND' ||
        resp.LoanStatus === 'DECLINE' ||
        resp.LoanStatus === 'DECSCOR' ||
        resp.LoanStatus === 'PENDINGANALYST'
      ) {
        setLoanStatus(resp.LoanStatus);
        setDelay(null);
        setAppApproved(true);
      }
    },
    delay,
    setPollTimeout
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const crypto = window.crypto || window.msCrypto;
        let array = new Uint32Array(1);
        const diff = crypto.getRandomValues(array) * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (pollTimeout) {
      setLoanStatus('PENDINGANALYST');
      setAppApproved(true);
    }
  }, [pollTimeout]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <img src={AppSubmitted} height="315px" width="307px" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '30px',
          gap: '20px',
          textAlign: 'center',
        }}
      >
        <Typography variant="h1" sx={{ width: '662px' }}>
          Thank you! Your application has been submitted successfully
        </Typography>
        <Typography variant="subtitle1">
          Here is the copy of your application data for safekeeping.{' '}
          {caseReferenceId ? 'Application' : 'Ref'} ID{' '}
          <Link
            component="button"
            color="#1976D2"
            sx={{ textDecoration: 'none', fontSize: '16px' }}
          >
            {caseReferenceId ? caseReferenceId : createAppApplicationId}
          </Link>
        </Typography>
        <LinearProgress variant="determinate" value={progress} sx={{ width: '200px' }} />
        <Typography variant="h5" color="#222B45">
          Please allow us few minutes to process your application
        </Typography>
      </Box>
    </Box>
  );
};

export default ApplicationSubmitted;
