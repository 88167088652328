import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { LockReleaseApi } from 'Services/LockReleaseApi';
import TriangleIcon from 'assets/svg/Icon-triangle.svg';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { defaultFn } from 'utils/utility';

const ExitApplicationDialog = ({ handleNavigate, clickedMenu }) => {
  const {
    openExitModal,
    setOpenExitModal,
    setCreateAppFlag,
    setCreateAppApplicationId,
    setCreateAppLookup,
    setCreateAppLaunchType,
    setDealer,
    setDraftFlag,
    setPopupStep,
    createAppApplicationId,
    fullName,
    userID,
  } = useContext(AuthContext);
  const handleClose = () => {
    setOpenExitModal(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openExitModal}
      PaperProps={{ sx: { padding: '20px', gap: '20px' } }}
    >
      <Box pb={1.5}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ padding: '0' }}>
        <Box display="flex" gap={2.5}>
          <img src={TriangleIcon} width="48px" height="48px" />
          <Typography variant="subtitle1" color="#000000" fontWeight="700">
            You’re exiting the application, which can be accessed under drafts. Do you wish to exit?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={async () => {
            setCreateAppFlag(false);
            setDealer('');
            setOpenExitModal(false);
            setCreateAppLaunchType('');
            setCreateAppLookup('');
            setCreateAppApplicationId('');
            handleNavigate(clickedMenu);
            setDraftFlag(false);
            setPopupStep(0);
            await LockReleaseApi({ appid: createAppApplicationId, fullName, userID });
          }}
        >
          Yes, exit application
        </Button>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          No, continue application
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExitApplicationDialog;

ExitApplicationDialog.propTypes = {
  handleNavigate: PropTypes.func,
  clickedMenu: PropTypes.string,
};
ExitApplicationDialog.defaultProps = {
  handleNavigate: defaultFn,
  clickedMenu: '',
};
