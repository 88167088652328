/* eslint-disable react/prop-types */
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import classes from 'styles/globalStyle.module.scss';
import { defaultFn } from 'utils/utility';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';

import { OfferDetailedView } from './OfferDetailedView';

const SingleOfferModal = ({ showPromotion, setShowPromotion }) => {
  const handleClose = () => {
    setShowPromotion(null);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={!!showPromotion}
      PaperProps={{ sx: { padding: '20px' } }}
      classes={{ paper: classes.offerSlider }}
    >
      <BootstrapDialogTitle onClose={handleClose}>
        <Typography variant="h1" color="primary">
          Offers
        </Typography>
      </BootstrapDialogTitle>
      <DialogContent>
        <Box sx={{ flexGrow: 1 }}>
          <OfferDetailedView
            key={showPromotion?.promotionId}
            eachOffer={showPromotion}
            setOpenModal={setShowPromotion}
            isSlideView={false}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SingleOfferModal;

SingleOfferModal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
};
SingleOfferModal.defaultProps = {
  openModal: false,
  setOpenModal: defaultFn,
};
