const endPoint = process.env.REACT_APP_BASE_END_POINT;
const commonComponentsBaseUrl = process.env.REACT_APP_COMMON_SERVICE_URL;
let infoReqEndPoint = 'autoloanapi/application/save';
let wsEndPoint = process.env.REACT_APP_WEBSOCKET_BASE_URL;
export const UrlHelpers = {
  payCalcOnLoad: `${endPoint}/autoutilapi/onload`,
  payCalcCalculate: `${endPoint}/autoutilapi/calculate`,
  distributedDataUploadOnLoad: `${endPoint}/autoutilapi/lookup`,
  distributedDataUpload: `${endPoint}/autoutilapi/uploaddistributordata`,
  lookUpDrillDownInfo: `${endPoint}/autointgapi/colValue/lookup/used/drillDownInfo`,
  lookUpDrillDownInfoNew: `${endPoint}/autointgapi/colValue/lookup/new/drillDownInfo`,
  saveLookupValue: `${endPoint}/autoutilapi/lookupsearch`,
  lookUpState: `${endPoint}/autointgapi/colValue/lookup/used/state`,
  lookUpDropDownInfo: `${endPoint}/autointgapi/colValue/lookup/used/dropdownInfo`,
  lookUpDropDownInfoNew: `${endPoint}/autointgapi/colValue/lookup/new/dropdownInfo`,
  getColorsAndOptions: `${endPoint}/autointgapi/colValue/lookup/new/getColorsAndOption`,
  getStandardEquipment: `${endPoint}/autointgapi/colValue/lookup/new/standardEquipment`,
  cityLookup: `${endPoint}/autoutilapi/masterlookup?propertyName=country-state-city`,
  residentialStatusLookup: `${endPoint}/autoutilapi/masterlookup?propertyName=residential-status`,
  maritalStatusLookup: `${endPoint}/autoutilapi/masterlookup?propertyName=marital-status`,
  employmentStatusLookup: `${endPoint}/autoutilapi/masterlookup?propertyName=employment-status`,
  utilityBillLookup: `${endPoint}/autoutilapi/masterlookup?propertyName=utility-bill`,
  relationshipLookup: `${endPoint}/autoutilapi/masterlookup?propertyName=relationship`,
  industryLookup: `${endPoint}/autoutilapi/masterlookup?propertyName=industry`,
  occupationLookup: `${endPoint}/autoutilapi/masterlookup?propertyName=occupation`,
  cityZipCodeMapping: `${endPoint}/autoutilapi/masterlookup?propertyName=city-zipcode`,
  dealerUiMapping: `${endPoint}/autoutilapi/masterlookup?propertyName=dealer-ui-mapping`,
  lookUpVin: `${endPoint}/autointgapi/colValue/lookup`,
  distributedYear: `${endPoint}/autoutilapi/yearlookup`,
  distributedMakeList: `${endPoint}/autoutilapi/makelookup`,
  distributedModelList: `${endPoint}/autoutilapi/modellookup`,
  distributedSubModelList: `${endPoint}/autoutilapi/submodellookup`,
  applicationDraft: `${endPoint}/autoloanapi/application/draft`,
  applicationCreate: `${endPoint}/autoloanapi/application/create`,
  informationReqSave: `${endPoint}/${infoReqEndPoint}`,
  createApiSubmit: `${endPoint}/autoloanapi/application/submit`,
  getApplicationDetails: `${endPoint}/autoloanapi/application/getDetails`,
  getSpecificDetails: `${endPoint}/autoloanapi/application/getSpecificDetails`,
  discardApplication: `${endPoint}/autoloanapi/application/discard`,
  financeTermsPayCalcSave: `${endPoint}/autoutilapi/lookupsearch`,
  loanPurpose: `${endPoint}/autoutilapi/masterlookup?propertyName=loan-purpose`,
  program: `${endPoint}/autoutilapi/masterlookup?propertyName=program`,
  monthsDeferred: `${endPoint}/autoutilapi/masterlookup?propertyName=months-deferred`,
  creditLifeType: `${endPoint}/autoutilapi/masterlookup?propertyName=credit-life-type`,
  creditLife: `${endPoint}/autoutilapi/masterlookup?propertyName=credit-life`,
  vehicleInsuranceType: `${endPoint}/autoutilapi/masterlookup?propertyName=vehicle-insurance-type`,
  newUsed: `${endPoint}/autoutilapi/masterlookup?propertyName=new-used`,
  make: `${endPoint}/autoutilapi/masterlookup?propertyName=make`,
  securityProfiles: `${endPoint}/autoutilapi/masterlookup?propertyName=security-profile`,
  reportsList: `${endPoint}/autoutilapi/masterlookup?propertyName=reports-list`,
  generateReport: `${endPoint}/reportapi/application/report`,
  accessTypes: `${endPoint}/autoutilapi/masterlookup?propertyName=access-type`,
  masterlookup: `${endPoint}/autoutilapi/termlookup`,
  getDocuments: `${endPoint}/autoutilapi/document`,
  generatedPreSignedUrl: `${endPoint}/autoloanapi/application/contract/generatepresignurl`,
  refreshToken: `${endPoint}/ob-idmautoapi/authentication/refreshtoken`,
  commonComponentsAddressLookup: `${commonComponentsBaseUrl}/customer-info-validator/lookup/address`,
  commonComponentsExtractAddress: `${commonComponentsBaseUrl}/customer-info-validator/extract/address`,
  commonComponentsOcr: `${commonComponentsBaseUrl}/customer-info-validator/upload`,
  commonComponentsOcrUploadXl: `${commonComponentsBaseUrl}/customer-info-validator/uploadxl`,
  commonComponentsEmailVerification: `${commonComponentsBaseUrl}/customer-info-validator/email/validation`,
  commonComponentsPhoneVerification: `${commonComponentsBaseUrl}/customer-info-validator/phone/validation`,
  commonComponentsInviConsent: `${commonComponentsBaseUrl}/customer-info-validator/details/consent`,
  loanStatus: `${endPoint}/autoloanapi/application/poll`,
  enrollUserSubmit: `${endPoint}/ob-idmautoapi/authentication/enroll/dealer`,
  usernameAPI: `${endPoint}/ob-idmautoapi/authentication/username`,
  fetchUserList: `${endPoint}/ob-idmautoapi/authentication/dealers/fetchall`,
  searchUserBy: `${endPoint}/ob-idmautoapi/authentication/retrieveUser`,
  updateUserInfo: `${endPoint}/ob-idmautoapi/authentication/dealers/updatedealer/`,
  userRequestStatus: `${endPoint}/autouserapi/userrequest/`,
  dealerUserRequest: `${endPoint}/autouserapi/dashboard/userrequest`,
  newUserRequest: `${endPoint}/autouserapi/userrequest/createuser`,
  accessPolicy: `${endPoint}/ob-idmautoapi/authentication/getAccessPolicy`,
  dealers: `${endPoint}/autoutilapi/dealers`,
  dealerSpecificList: `${endPoint}/autoadminapi/users/fetchdealer/`,
  managerSalesList: `${endPoint}/autoadminapi/users/managersaleslist/`,
  userIdCheck: `${endPoint}/autoadminapi/users/validate/`,
  frequencyOpt: `${endPoint}/autoutilapi/masterlookup?propertyName=frequency-summary`,
  frequency: `${endPoint}/autoloanapi/dashboard/count`,
  uploadManually: `${endPoint}/autoutilapi/upload`,
  uploadXlmanually: `${endPoint}/autoutilapi/uploadxl`,
  fetchApplications: `${endPoint}/autoloanapi/dashboard/application`,
  fetchPromotions: `${endPoint}/autoutilapi/onload`,
  frequencyDetail: `${endPoint}/autoutilapi/masterlookup?propertyName=frequency-detail`,
  applicationLoanStatus: `${endPoint}/autoutilapi/masterlookup?propertyName=loan-status`,
  inviFlow: `${endPoint}/autoloanapi/application/invitation`,
  inviStatus: `${endPoint}/autoloanapi/application/inviteStatus`,
  approvalStatus: `${endPoint}/autoutilapi/masterlookup?propertyName=user-request-status`,
  contractDocument: `${endPoint}/autoutilapi/masterlookup?propertyName=contract-document`,
  dashboardDocument: `${endPoint}/autoutilapi/masterlookup?propertyName=dashboard-document`,
  invitationStatus: `${endPoint}/autoloanapi/application/inviteStatus`,
  dealerResetPassword: `${endPoint}/autoadminapi/users/reset-pwd`,
  wsNotification: `${wsEndPoint}/notification`,
  notification: `${endPoint}/notificationapi/notification`,
  comments: `${endPoint}/autoloanapi/application/comment`,
  createPromotion: `${endPoint}/autoutilapi/onload`,
  dealershipUserData: `${endPoint}/autoadminapi/users/dealership`,
  filterdealer: `${endPoint}/autoadminapi/users/filterdealer`,
  filterPromotions: `${endPoint}/autoutilapi/onload`,
  attachDocument: `${endPoint}/autoutilapi/attachment`,
  previewDoc: `${endPoint}/autoloanapi/application/contract/preview`,
  printPaymentCalc: `${endPoint}/autoutilapi/print?locale=en`,
  generateDocs: `${endPoint}/autoloanapi/application/contract/generate`,
  contractInfo: `${endPoint}/autoloanapi/application/contract/info`,
  contractXml: `${endPoint}/autoloanapi/application/contract/submit`,
  onLoadprofileData: `${endPoint}/ob-idmautoapi/self-service/viewuser`,
  uploadPhoto: `${endPoint}/ob-idmautoapi/self-service/updatephoto`,
  uploadEmailPhone: `${endPoint}/ob-idmautoapi/self-service/updatedevice`,
  uploadPassword: `${endPoint}/ob-idmautoapi/self-service/updatepassword`,
  lockRelease: `${endPoint}/autoloanapi/application/lock/release`,
  managerList: `${endPoint}/autoadminapi/users/managerslist`,
  salesList: `${endPoint}/autoadminapi/users/saleslist`,
  inviExpiry: `${endPoint}/autoloanapi/application/disableInvitations`,
};
