import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { defaultFn } from 'utils/utility';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';

const SaveAsDraftDialog = ({ openModal, setOpenModal }) => {
  let { setCreateAppFlag, setDealer } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      PaperProps={{ sx: { padding: '20px' } }}
    >
      <BootstrapDialogTitle onClose={handleClose}>
        <Box display="flex" gap={1}>
          <CheckCircleOutlineIcon color="success" />
          <Typography variant="h3">Application saved as draft successfully</Typography>
        </Box>
      </BootstrapDialogTitle>
      <DialogContent>
        <Typography variant="body1" color="#909090">
          Unless you click submit, your application will be saved as a draft and not submitted. View
          your application in my draft queue
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" gap={2}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setCreateAppFlag(false);
              setDealer('');
              navigate('/home');
            }}
          >
            Exit, go to dashboard
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Ok, continue with Application
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SaveAsDraftDialog;

SaveAsDraftDialog.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
};
SaveAsDraftDialog.defaultProps = {
  openModal: false,
  setOpenModal: defaultFn,
};
