import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Paper, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { approveRequestSchema } from 'schemaValidations/approveRequestSchema';
import classes from 'styles/globalStyle.module.scss';

import ApproveRequest from './ApproveRequest';
import StatusDialog from './StatusDialog';
import UserRequestRejectionDialog from './UserRequestRejectionDialog';
import ViewUserRequest from './ViewUserRequest';

const UserRequestStatus = () => {
  const { approveClicked } = useContext(AuthContext);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues: {
      userId: '',
      includeInSalesManager: '',
      includeInReference: '',
      active: '',
      maximizeTY: '',
      securityProfile: '',
      vendorNumber: '',
    },
    resolver: yupResolver(approveRequestSchema),
  });

  return (
    <>
      <Box py={3} px={2} display="flex" justifyContent="space-between">
        <Typography variant="h3">View user request</Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box sx={{ width: '70%' }}>
          <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
            <Grid id="1" container justifyContent="center" flexDirection="column">
              {approveClicked ? (
                <ApproveRequest control={control} errors={errors} handleSubmit={handleSubmit} />
              ) : (
                <ViewUserRequest />
              )}
              <UserRequestRejectionDialog />
              <StatusDialog />
            </Grid>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default UserRequestStatus;
