import { Box, Grid, Link } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AddIcon from 'assets/svg/AddIcon.svg';
import { fetchAddressLookup } from 'commonComponents/commonComponents';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import debounceLodash from 'lodash.debounce';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  EmploymentInformationSchema,
  FormatValidationSchema,
} from 'schemaValidations/EmploymentInformationSchema';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { currencyToNumber } from 'utils';
import { defaultFn, handleError, updateCompletedSteps } from 'utils/utility';

import CancelEditPopup from '../CancelEditPopup';
import CreateApplicationFooter from '../CreateApplicationFooter';
import AdditionalIncome from '../EmploymentInformation/AdditionalIncome/AdditionalIncome';
import EmpInfoForm from '../EmploymentInformation/EmpInfoForm/EmpInfoForm';
import { step2DropDownApis } from '../InformationRequest/moduleUtilities/dropdownApis';
import { submitApi, viewApplication } from '../InformationRequest/moduleUtilities/moduleUtilities';
import ReOpenApplicationFooter from '../ReOpenApplicationFooter';

const EmploymentInformation = ({
  control,
  prefixName,
  errors,
  getValues,
  setValue,
  clearErrors,
  couserIndex,
  dirtyFields,
  setError,
  handleSubmit,
  tabvalue,
  setTabValue,
  callDraft,
  setCallDraft,
  setApiErr,
  initcouserArray,
}) => {
  const navigate = useNavigate();
  const [addressLookupData, setAddressLookupData] = useState([]);
  const [addressLookupDataResponse, setAddressLookupDataResponse] = useState([]);
  const [openCancelAlert, setOpenCancelAlert] = useState(false);
  const [isMounted, setisMounted] = useState(false);
  const {
    commonToken,
    occupationList,
    setOccupationList,
    empStatusList,
    setEmpStatusList,
    cityList,
    setCityList,
    industryList,
    setIndustryList,
    createAppApplicationId,
    cityZipList,
    setCityZipList,
    userID,
    setCreateAppFlag,
    setCreateAppLaunchType,
    setDealer,
    fullName,
    stateList,
    setStateList,
    // stateCityList,
    setstateCityList,
  } = useContext(AuthContext);
  console.log('occupationList', occupationList);
  const {
    setActiveStep,
    setFormLoader,
    reviewFlag,
    setBackFlag,
    setOpenDraftModal,
    setDraftCheckFlag,
    completedSteps,
    setCompletedSteps,
    setAddUserTabValue,
    viewAppFlow,
    setAppSubmitted = defaultFn,
    setCaseReferenceId = defaultFn,
    addedApplicantFlag,
    setAddedApplicantFlag,

    setUnfilledId,
  } = useContext(MainApplicationContext);
  const inputRef = useRef(null);
  const ApiClient = new ApiClass();

  const [
    watchEmpAddressLine1,
    watchEmpAddressLine2,
    watchEmpCity,
    watchEmpState,
    watchEmpZipCode,
    showAdditionalIncome,
    watchCousersEmpinfo,
  ] = useWatch({
    control,
    name: [
      `${prefixName}.empAddressLine1`,
      `${prefixName}.empAddressLine2`,
      `${prefixName}.empCity`,
      `${prefixName}.empState`,
      `${prefixName}.empZipcode`,
      `${prefixName}.showAddInfo`,
      'cousersEmpinfo',
    ],
  });
  const isdraftDisabled = Object.keys(dirtyFields)?.length === 0;
  useEffect(async () => {
    await step2DropDownApis({
      setstateCityList,
      setStateList,
      occupationList,
      setOccupationList,
      empStatusList,
      setEmpStatusList,
      cityList,
      industryList,
      setIndustryList,
      cityZipList,
      setCityZipList,
    });
    if (Object.entries(errors).length === 0) {
      window.scroll({
        top: 131,
        left: 0,
        behavior: 'smooth',
      });
    }
    setisMounted(true);
  }, []);

  useEffect(async () => {
    console.log('tabvalue', tabvalue);
    if (callDraft && !viewAppFlow) {
      window.scroll({
        top: 431,
        left: 0,
        behavior: 'smooth',
      });
      await draftClick({ tabSwitch: true });
      setCallDraft(false);
    }
  }, [callDraft]);
  const debounceFunc5 = debounceLodash((address1, country, query5, commonTokenn, addressType) => {
    fetchAddressLookup({
      address1,
      country,
      query5,
      commonTokenn,
      addressType,
      setAddressLookupData,
      setAddressLookupDataResponse,
    });
  }, 500);

  const fetchAddress5 = useCallback(debounceFunc5, []);

  useEffect(() => {
    if (dirtyFields?.cousersEmpinfo?.[couserIndex]?.empAddressLine1 && commonToken) {
      let query5 = watchEmpAddressLine1;

      if (watchEmpAddressLine2) {
        query5 += ` ${watchEmpAddressLine2} `;
      }
      if (watchEmpState) {
        query5 += ` ${watchEmpState} `;
      }
      if (watchEmpCity) {
        query5 += ` ${watchEmpCity} `;
      }
      if (watchEmpZipCode) {
        query5 += ` ${watchEmpZipCode} `;
      }
      fetchAddress5(watchEmpAddressLine1, 'USA', query5, commonToken, 'res');
    }
  }, [watchEmpAddressLine1]);

  const handleAdditionalClick = () => {
    setValue(`${prefixName}.showAddInfo`, true);
  };

  const empObj = (index) => {
    const empObjj = {
      employmentInformation: {
        employmentInformationDetail: {
          employmentStatus: watchCousersEmpinfo[index]?.empStatus,
          employer: watchCousersEmpinfo[index]?.empName,
          address: {
            lineOne: watchCousersEmpinfo[index]?.empAddressLine1,
            lineTwo: watchCousersEmpinfo[index]?.empAddressLine2,
            addrCity: watchCousersEmpinfo[index]?.empCity,
            addrState: watchCousersEmpinfo[index]?.empState,
            addrCountry: 'United States',
            addrCountryCode: 'USA',
            zipCode: watchCousersEmpinfo[index]?.empZipcode,
          },
          jobTitle: watchCousersEmpinfo[index]?.jobTitle,
          occupation:
            occupationList?.length > 0 &&
            occupationList.find((x) => x.value === watchCousersEmpinfo[index]?.occupation)?.name,
          occupationCode: watchCousersEmpinfo[index]?.occupation,
          industry: watchCousersEmpinfo[index]?.industry,

          employmentTimePeriodYear: watchCousersEmpinfo[index]?.empYears,
          employmentTimePeriodMonth: watchCousersEmpinfo[index]?.empMonths,
          workPhone: watchCousersEmpinfo[index]?.mobile
            ? `${watchCousersEmpinfo[index]?.mobile}`
            : '',
          grossMonthlyIncome: watchCousersEmpinfo[index]?.monthlyIncome
            ? currencyToNumber(watchCousersEmpinfo[index]?.monthlyIncome)
            : '',
        },
      },
    };
    if (watchCousersEmpinfo[index]?.showAddInfo) {
      empObjj.employmentInformation.additionalIncomeDetail = {
        addlEmployerName: watchCousersEmpinfo[index]?.addEmpName,
        addlJobTitle: watchCousersEmpinfo[index]?.addJobTitle,
        addlOccupation:
          occupationList?.length > 0 &&
          occupationList.find((x) => x.value === watchCousersEmpinfo[index]?.addOccupation)?.name,

        addlOccupationCode: watchCousersEmpinfo[index]?.addOccupation,

        addlWorkPhone: watchCousersEmpinfo[index]?.mobile2
          ? watchCousersEmpinfo[index]?.mobile2
          : '',
        addlGrossMonthlyIncome: watchCousersEmpinfo[index]?.addMonthlyIncome
          ? currencyToNumber(watchCousersEmpinfo[index]?.addMonthlyIncome)
          : '',
      };
    }
    return empObjj;
  };
  const draftValidCheck = async (formValue) => {
    let isValid = false;
    try {
      isValid = FormatValidationSchema.isValidSync(formValue);
      console.log('isValid', isValid);
    } catch (err) {
      console.log('err in', err);
      return isValid;
    }
    return isValid;
  };

  const FormValidCheck = async (formValue) => {
    try {
      EmploymentInformationSchema.validateSync(formValue, { abortEarly: false });
      return { isValid: true, errors: {} };
    } catch (errors) {
      console.log('errors', errors);
      return {
        isValid: false,
        errors: errors?.inner?.reduce((acc, err) => ({ ...acc, [err.path]: err.message }), {}),
      };
    }
  };
  const draftClick = async ({ tabSwitch = false }) => {
    console.log('into draft click');
    const formValue = getValues();

    let isValid = false;
    try {
      isValid = await draftValidCheck(formValue);

      if (!isValid && !tabSwitch) {
        setApiErr(' Please enter the field completely and try saving again.');
      } else if (isValid) {
        setApiErr('');
        await handleEmpSubmit({}, { draft: true, tabSwitch });
      }
    } catch (err) {
      console.log('err in', err);
    }
  };

  const onCancelEditSubmitted = async () => {
    if (addedApplicantFlag) {
      setApiErr('Please complete employment information for the participant added');
    } else {
      setOpenCancelAlert(true);
    }
  };

  const onConfirmCancel = async () => {
    setCreateAppFlag(false);
    setDealer('');
    setOpenCancelAlert(false);
    await viewApplication({
      setLoader: setFormLoader,
      navigate,
      createAppApplicationId,
      userID,
      fullName,
    });
  };

  const updateSubmitted = async () => {
    await handleEmpSubmit({}, { updateFlag: true });
  };

  const continueSubmitted = async () => {
    await handleEmpSubmit({}, { updateFlag: false });
  };

  const handleEmpSubmit = async (
    data,
    { draft = false, tabSwitch = false, updateFlag = false }
  ) => {
    setFormLoader(true);
    setBackFlag(false);

    const primaryInfo = empObj(0);
    const applicantData = {
      applicants: {
        applicantId: watchCousersEmpinfo[0]?.applicantId,
        ...primaryInfo,
      },
    };
    const coappIndex = watchCousersEmpinfo?.findIndex((x) => x.userType === 'Co-applicant');
    if (coappIndex > -1) {
      const coappInfo = empObj(coappIndex);
      applicantData.applicants.coApplicants = [
        {
          applicantId: watchCousersEmpinfo[coappIndex]?.applicantId,
          ...coappInfo,
        },
      ];
    }
    const cosignerIndex = watchCousersEmpinfo?.findIndex((x) => x.userType === 'Co-signer 1');
    if (cosignerIndex > -1) {
      const cosignerInfo = empObj(cosignerIndex);
      applicantData.applicants.coSigners = [
        {
          applicantId: watchCousersEmpinfo[cosignerIndex]?.applicantId,
          ...cosignerInfo,
        },
      ];
      const cosignerIndex2 = watchCousersEmpinfo?.findIndex((x) => x.userType === 'Co-signer 2');
      const cosignerInfo2 = empObj(cosignerIndex2);
      if (cosignerIndex2 > -1) {
        applicantData.applicants.coSigners[1] = {
          applicantId: watchCousersEmpinfo[cosignerIndex2]?.applicantId,
          ...cosignerInfo2,
        };
      }
    }
    const payloadData = {
      event: 'EmploymentInformation',
      application: {
        products: {
          productType: 'Indirect Loan Account',
          productName: 'AutoLoanManual',
        },
        userId: userID,
        loanAppType: 'Personal',
        ...applicantData,
        applicationId: createAppApplicationId,
        createAppType: 'Manual',
        source: {
          language: 'EN',
          location: 'PR',
        },
      },
    };
    if (draft && !tabSwitch) {
      payloadData.application.landingUserType = watchCousersEmpinfo[tabvalue]?.userType;
    } else {
      payloadData.application.landingUserType = '';
    }
    await saveEmpDetails({ draft, payloadData, tabSwitch, updateFlag });
  };

  const saveEmpDetails = async ({ draft, payloadData, tabSwitch, updateFlag }) => {
    try {
      const endpoint = draft ? UrlHelpers.applicationDraft : UrlHelpers.informationReqSave;
      let calculate = await ApiClient.post(
        endpoint,
        { ...payloadData },
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );

      if (calculate && !draft && !reviewFlag) {
        updateCompletedSteps([1, 2], completedSteps, setCompletedSteps);
        setActiveStep(3);
      } else if (calculate && !draft && reviewFlag && !viewAppFlow) {
        setAddUserTabValue(0);
        updateCompletedSteps([1, 2, 3, 4], completedSteps, setCompletedSteps);
        setActiveStep(5);
      } else if (calculate && viewAppFlow && !draft) {
        await handleResubmitApplication({ updateFlag });
      } else if (calculate && draft && !tabSwitch) {
        console.log('draft success', calculate);
        setOpenDraftModal(true);
      }
    } catch (e) {
      console.log('unikk', e);
      if (e?.response?.status === 400) {
        await handleError({
          errResp: e?.response?.data,
          setTabValue,
          setError,
          user: 'cousersEmpinfo',
          setApiErr,
        });
      } else {
        setApiErr(
          e?.response?.data ||
            'We are unable to proceed due to a technical issue, please try again later'
        );
      }
    } finally {
      setFormLoader(false);
      console.log('in finally');
      setDraftCheckFlag(false);
    }
  };

  const onBackClick = async () => {
    if (viewAppFlow) {
      const allFormData = getValues();
      console.log('difference in struct', { initcouserArray, allFormData });
      let empInfo = {
        cousersEmpinfo: initcouserArray,
      };
      let { isValid, errors } = await FormValidCheck(empInfo);

      if (!isValid) {
        let findFaultyid = Object.keys(errors)[0].split('.')[0];

        setUnfilledId(getValues(`${findFaultyid}.applicantId`));
      } else {
        setUnfilledId('');
      }
      setActiveStep(1);
    } else {
      const formValue = getValues();

      let valid = await draftValidCheck(formValue);

      if (valid) {
        await draftClick({ tabSwitch: true });
        setActiveStep(1);
      } else {
        setApiErr('Please enter the field completely and try saving again.');
      }
    }
  };

  const handleResubmitApplication = async ({ updateFlag }) => {
    updateCompletedSteps([1, 2, 3, 4], completedSteps, setCompletedSteps);

    if (updateFlag) {
      let resp = await submitApi({
        createAppApplicationId,
        userID,
        setLoader: setFormLoader,
        setCaseReferenceId,
        setAppSubmitted,
        setCreateAppFlag,
        setDealer,
        setCreateAppLaunchType,
        setApiErr,
        fullName,
      });
      if (resp?.CaseReferenceId) {
        setAddedApplicantFlag(false);

        setUnfilledId('');
      }
    } else {
      setUnfilledId('');
      setAddedApplicantFlag(false);

      setActiveStep(5);
    }
  };

  return (
    <>
      <CancelEditPopup
        openModal={openCancelAlert}
        setOpenModal={setOpenCancelAlert}
        onConfirmCancel={onConfirmCancel}
      />
      <Grid container justifyContent="center">
        <Box sx={{ width: '80%' }}>
          <EmpInfoForm
            control={control}
            errors={errors}
            setError={setError}
            setValue={setValue}
            inputRef={inputRef}
            clearErrors={clearErrors}
            addressLookupData={addressLookupData}
            addressLookupDataResponse={addressLookupDataResponse}
            prefixName={prefixName}
            couserIndex={couserIndex}
            stateList={stateList}
            cityList={cityList}
            isMounted={isMounted}
            setCityList={setCityList}
          />
          {!showAdditionalIncome && (
            <Link
              component="button"
              variant="subtitle2"
              color="secondary"
              sx={{ padding: '40px 0' }}
              onClick={handleAdditionalClick}
            >
              <Box display="flex" alignItems="center">
                <img src={AddIcon} alt="Add Button" style={{ marginRight: '8px' }} /> Add additional
                income
              </Box>
            </Link>
          )}

          {showAdditionalIncome && (
            <AdditionalIncome
              control={control}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              prefixName={prefixName}
              couserIndex={couserIndex}
            />
          )}
        </Box>
      </Grid>

      {viewAppFlow ? (
        <ReOpenApplicationFooter
          onCancelEditSubmitted={onCancelEditSubmitted}
          updateSubmitted={handleSubmit(updateSubmitted)}
          continueSubmitted={handleSubmit(continueSubmitted)}
          backFlag={addedApplicantFlag}
          onBackClick={onBackClick}
        />
      ) : (
        <CreateApplicationFooter
          disabledFlag={reviewFlag}
          onBackClick={onBackClick}
          onSaveContinue={handleSubmit(handleEmpSubmit)}
          onSaveDraft={draftClick}
          draftDisabledFlag={isdraftDisabled}
        />
      )}
    </>
  );
};

export default EmploymentInformation;

EmploymentInformation.propTypes = {
  control: PropTypes.instanceOf(Object),
  prefixName: PropTypes.string,
  errors: PropTypes.instanceOf(Object),
  dirtyFields: PropTypes.instanceOf(Object),
  fields: PropTypes.instanceOf(Object),
  append: PropTypes.func,
  remove: PropTypes.func,
  tabvalue: PropTypes.number,
  handleSubmit: PropTypes.func,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  validateAll: PropTypes.func,
  setError: PropTypes.func,
  setLoader: PropTypes.func,
  savedApplicantIds: PropTypes.instanceOf(Object),
  trigger: PropTypes.func,
  callDraft: PropTypes.bool,
  setCallDraft: PropTypes.func,
  clearErrors: PropTypes.func,

  couserIndex: PropTypes.number,
  setApiErr: PropTypes.func,
  setTabValue: PropTypes.func,
  initcouserArray: PropTypes.array,
};
EmploymentInformation.defaultProps = {
  control: {},
  prefixName: '',
  errors: {},
  getValues: defaultFn,

  setValue: defaultFn,
  callDraft: false,

  setError: defaultFn,
  setLoader: defaultFn,
  showCamera: false,
  setCallDraft: defaultFn,
  clearErrors: defaultFn,
  trigger: defaultFn,
  couserIndex: 0,
  savedApplicantIds: [],
  validateAll: defaultFn,
  handleSubmit: defaultFn,
  setApiErr: defaultFn,
  setTabValue: defaultFn,
  initcouserArray: [],
};
