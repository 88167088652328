/* eslint-disable react/prop-types */
import { Box, Grid, Link, Typography } from '@mui/material';
import { numberToCurrency } from 'utils/utility';

const ApplicationApprovedDetails = ({
  handleDescriptionText,
  loanStatus,
  approvalTerms,
  isMobile,
  appCondData,
  caseReferenceId,
  decision,
  createAppApplicationId,
}) => {
  const bgColor = isMobile ? '' : '#F2F2F2';
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="subtitle1" sx={{ maxWidth: '752px' }}>
        {handleDescriptionText()}
      </Typography>
      {loanStatus === 'APPCOND' && (
        <>
          <Grid container sx={{ border: '1px solid #DADADA', maxWidth: '75%' }} my={3}>
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={6}
              p={1}
              sx={{ backgroundColor: '#F2F2F2', borderBottom: '1px solid #DADADA' }}
            >
              <Typography variant="subtitle2" display="flex" alignItems="start">
                Approved amount
              </Typography>
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={6}
              p={1}
              sx={{ backgroundColor: '#F2F2F2', borderBottom: '1px solid #DADADA' }}
            >
              <Typography
                variant="subtitle2"
                display="flex"
                alignItems="start"
                color="secondary"
                fontWeight={700}
              >
                {numberToCurrency(approvalTerms?.amount)}
              </Typography>
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={6}
              p={1}
              sx={{
                backgroundColor: bgColor,
                borderBottom: '1px solid #DADADA',
              }}
            >
              <Typography variant="subtitle2" display="flex" alignItems="start">
                Model
              </Typography>
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={6}
              p={1}
              sx={{
                backgroundColor: bgColor,
                borderBottom: '1px solid #DADADA',
              }}
            >
              <Typography
                variant="subtitle2"
                display="flex"
                alignItems="start"
                color="secondary"
                fontWeight={700}
              >
                {appCondData?.collateralInfo?.model}
              </Typography>
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={6}
              p={1}
              sx={{
                backgroundColor: `${isMobile ? '#F2F2F2' : ''}`,
                borderBottom: '1px solid #DADADA',
              }}
            >
              <Typography variant="subtitle2" display="flex" alignItems="start">
                Term
              </Typography>
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={6}
              p={1}
              sx={{
                backgroundColor: `${isMobile ? '#F2F2F2' : ''}`,
                borderBottom: '1px solid #DADADA',
              }}
            >
              <Typography
                variant="subtitle2"
                display="flex"
                alignItems="start"
                color="secondary"
                fontWeight={700}
              >
                {Math.ceil(approvalTerms?.term)}
              </Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={6} p={1} sx={{ borderBottom: '1px solid #DADADA' }}>
              <Typography variant="subtitle2" display="flex" alignItems="start">
                Make
              </Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={6} p={1} sx={{ borderBottom: '1px solid #DADADA' }}>
              <Typography
                variant="subtitle2"
                display="flex"
                alignItems="start"
                color="secondary"
                fontWeight={700}
              >
                {appCondData?.collateralInfo?.brand}
              </Typography>
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={6}
              p={1}
              sx={{ backgroundColor: '#F2F2F2', borderBottom: '1px solid #DADADA' }}
            >
              <Typography variant="subtitle2" display="flex" alignItems="start">
                Rate
              </Typography>
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={6}
              p={1}
              sx={{ backgroundColor: '#F2F2F2', borderBottom: '1px solid #DADADA' }}
            >
              <Typography
                variant="subtitle2"
                display="flex"
                alignItems="start"
                color="secondary"
                fontWeight={700}
              >
                {approvalTerms?.buyRate ? `${approvalTerms?.buyRate}%` : '--'}
              </Typography>
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={6}
              p={1}
              sx={{
                backgroundColor: bgColor,
                borderBottom: '1px solid #DADADA',
              }}
            >
              <Typography variant="subtitle2" display="flex" alignItems="start">
                Trim
              </Typography>
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={6}
              p={1}
              sx={{
                backgroundColor: bgColor,
                borderBottom: '1px solid #DADADA',
              }}
            >
              <Typography
                variant="subtitle2"
                display="flex"
                alignItems="start"
                color="secondary"
                fontWeight={700}
              >
                {appCondData?.collateralInfo?.subModel}
              </Typography>
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={6}
              p={1}
              sx={{
                backgroundColor: `${isMobile ? '#F2F2F2' : ''}`,
                borderBottom: `${isMobile ? '1px solid #DADADA' : ''}`,
              }}
            >
              <Typography variant="subtitle2" display="flex" alignItems="start">
                Year
              </Typography>
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={6}
              p={1}
              sx={{
                backgroundColor: `${isMobile ? '#F2F2F2' : ''}`,
                borderBottom: `${isMobile ? '1px solid #DADADA' : ''}`,
              }}
            >
              <Typography
                variant="subtitle2"
                display="flex"
                alignItems="start"
                color="secondary"
                fontWeight={700}
              >
                {appCondData?.collateralInfo?.year}
              </Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={6} p={1}>
              <Typography variant="subtitle2" display="flex" alignItems="start">
                New/Used
              </Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={6} p={1}>
              <Typography
                variant="subtitle2"
                display="flex"
                alignItems="start"
                color="secondary"
                fontWeight={700}
              >
                {appCondData?.collateralInfo?.vehicleNewOrOld === 'True' ? 'New' : 'Used'}
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" marginBottom="30px" gap="5%">
            <Box sx={{ border: '1px solid #DADADA', width: `${isMobile ? '100%' : '292px'}` }}>
              <Box
                sx={{
                  padding: '8px 10px',
                  backgroundColor: '#F2F2F2',
                  borderBottom: '1px solid #DADADA',
                }}
              >
                <Typography variant="subtitle2" fontWeight={700}>
                  Credit terms
                </Typography>
              </Box>
              <Box p={1} textAlign="start">
                {decision?.reasons?.map((item) => {
                  return (
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      fontWeight={700}
                      key={item?.text}
                    >
                      {item?.text}
                    </Typography>
                  );
                })}
              </Box>
            </Box>
            <Box sx={{ border: '1px solid #DADADA', width: `${isMobile ? '100%' : '292px'}` }}>
              <Box
                p={2}
                sx={{
                  padding: '8px 10px',
                  backgroundColor: '#F2F2F2',
                  borderBottom: '1px solid #DADADA',
                }}
              >
                <Typography variant="subtitle2" fontWeight={700}>
                  Stipulations
                </Typography>
              </Box>
              <Box p={1} textAlign="start">
                {decision?.stipulations?.map((item) => {
                  return (
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      fontWeight={700}
                      key={item?.text}
                    >
                      {item?.text}
                    </Typography>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </>
      )}
      <Box>
        Your {caseReferenceId ? 'application' : 'ref'} ID{' '}
        <Link component="button" color="#1976D2" sx={{ textDecoration: 'none', fontSize: '16px' }}>
          {caseReferenceId || createAppApplicationId}
        </Link>
      </Box>
    </Box>
  );
};

export default ApplicationApprovedDetails;
