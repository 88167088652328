import ApiClass from 'Api/ApiClient';
import { UrlHelpers } from 'urlHelpers/urlHelper';

export const dealershipUsersFetchAll = async ({ userID }) => {
  const ApiClient = new ApiClass();
  try {
    return ApiClient.post(`${UrlHelpers.dealershipUserData}/${userID}`, {
      headers: { 'Content-Type': 'application/json' },
    });
  } catch (e) {
    console.log('e', e);
  }
};
export const filterActiveDealershipuser = async ({ userID }) => {
  const ApiClient = new ApiClass();
  try {
    let url = `${UrlHelpers.filterdealer}?status=true`;

    return ApiClient.post(
      url,
      {
        userId: userID,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  } catch (e) {
    console.log('e', e);
  }
};
