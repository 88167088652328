import { Box, Checkbox, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import Delete from 'assets/svg/Delete.svg';
import SearchIcon from 'assets/svg/SearchIcon.svg';
import RegisterUserContext from 'context/RegisterUserContext';
import React, { useContext, useEffect, useState } from 'react';
import manageClasses from 'styles/ManageDealers.module.scss';
import classes from 'styles/globalStyle.module.scss';

const DealerTable = () => {
  const { formDetails, setFormDetails, dealers, setDealersTableDirty } =
    useContext(RegisterUserContext);
  const [searchText, setSearchText] = useState('');
  const [searchedDealers, setSearchedDealers] = useState(dealers);
  useEffect(() => {
    setSearchedDealers(dealers);
  }, [dealers]);
  const handleCheckboxChange = (event, row) => {
    setDealersTableDirty(true);
    const selected = event.target.checked;
    if (selected) {
      const chosenDealers = [...formDetails.dealers];
      setFormDetails({ ...formDetails, dealers: [...chosenDealers, row] });
    } else {
      const chosenDealers = [...formDetails.dealers];
      setFormDetails({
        ...formDetails,
        dealers: chosenDealers.filter((r) => r.dealerId !== row.dealerId),
      });
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    let arr = dealers?.filter((dealer) => {
      return (
        dealer?.dealerName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        dealer?.dealerId.includes(e.target.value)
      );
    });

    !e.target.value ? setSearchedDealers(dealers) : setSearchedDealers(arr);
  };

  const handleDelete = (row) => {
    setDealersTableDirty(true);
    const chosenDealers = [...formDetails.dealers];
    setFormDetails({
      ...formDetails,
      dealers: chosenDealers.filter((r) => r.dealerId !== row.dealerId),
    });
  };
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        className={classes.tableOutline}
        sx={{ marginTop: '5px' }}
      >
        <Box
          display="flex"
          gap={2}
          sx={{ alignItems: 'center', justifyContent: 'space-between' }}
          p={2}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
            Select dealers
          </Typography>
          <div className="search" style={{ width: '40%' }}>
            <OutlinedInput
              placeholder="Search by ID,Name"
              name="dealerSearch"
              className={manageClasses.inputStyle}
              onChange={handleSearch}
              data-testid="dealerSearch"
              value={searchText}
              maxLength={15}
              endAdornment={
                <InputAdornment position="end" tabIndex="0">
                  <img src={SearchIcon} alt="Search" />
                </InputAdornment>
              }
            />
          </div>
        </Box>
        <Box display="flex" gap={2} sx={{ backgroundColor: '#F2F2F2' }} p={2}>
          <Typography
            variant="subtitle2"
            sx={{ flexGrow: 1, fontWeight: '700', marginLeft: '35px', maxWidth: '38%' }}
          >
            Dealer ID
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
          >
            Dealer
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" className={classes.childBorderLine}>
          {searchedDealers
            ?.map((dealer) => ({
              dealer: dealer.dealerName,
              ...dealer,
            }))
            ?.map?.((x) => (
              <Box display="flex" key={x.dealerId} p={2}>
                <Checkbox
                  color="secondary"
                  checked={
                    formDetails.dealers.length > 0 &&
                    formDetails.dealers.some((el) => el.dealerId === x.dealerId)
                  }
                  onChange={(event) => handleCheckboxChange(event, x)}
                  data-testid={x.dealerId}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ flexGrow: 1, maxWidth: '40%', marginLeft: '15px' }}
                >
                  {x.dealerId}
                </Typography>
                <Typography
                  // key={index}
                  variant="subtitle2"
                  sx={{ overflowWrap: 'break-word' }}
                >
                  {x.dealer}
                </Typography>
              </Box>
            ))}
        </Box>
      </Box>
      <Box display="flex" justifyContent={'space-between'} gap={2} sx={{}} p={2}>
        <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
          Selected dealers
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" className={classes.tableOutline}>
        <Box display="flex" gap={2} sx={{ backgroundColor: '#F2F2F2' }} p={2}>
          <Typography variant="subtitle2" sx={{ flexGrow: 1, fontWeight: '700', maxWidth: '38%' }}>
            Dealer ID
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
          >
            Dealer
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" className={classes.childBorderLine}>
          {formDetails.dealers?.length > 0 &&
            formDetails.dealers?.map?.((x) => (
              <Box display="flex" key={x.dealerId} p={2}>
                <Typography variant="subtitle2" sx={{ flexGrow: 0.5, maxWidth: '40%' }}>
                  {x.dealerId}
                </Typography>
                <Typography variant="subtitle2" sx={{ flexGrow: 0.6, overflowWrap: 'break-word' }}>
                  {x.dealer || x.dealerName}
                </Typography>
                <img src={Delete} onClick={() => handleDelete(x)} />
              </Box>
            ))}
        </Box>
      </Box>
    </>
  );
};

export default DealerTable;
