import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Alert, Box, Button, Grid, Link, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import RegisterUserContext from 'context/RegisterUserContext';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';
import Loader from 'components/Loader/Loader';

const UserProfileSubmit = ({ handleCancel }) => {
  const [loader, setLoader] = useState(false);
  const [alertClose, setAlertClose] = useState(false);

  const { accessToken, role, setOpenDiscardModal, userName } = useContext(AuthContext);
  const {
    formDetails,
    setActiveStep,
    setIsReview,
    isViewProfile,
    fullProfileData,
    formDirty,
    dealersTableDirty,
  } = useContext(RegisterUserContext);
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const ApiClient = new ApiClass();
  const yesAndNo = {
    Yes: true,
    No: false,
  };
  const userInfoEdit = () => {
    setIsReview(true);
    setActiveStep(1);
  };
  const userStatusEdit = () => {
    setIsReview(true);
    setActiveStep(2);
  };
  const userProfileEdit = () => {
    setIsReview(true);
    setActiveStep(3);
  };
  const updateData = async () => {
    setAlertClose(false);

    const formData = {
      ...formDetails,
      userSetupDate: moment().format('MM/DD/YYYY'),
      timeZone: 'PR',
      userType: 'Dealer Portal',
      dealerType: 'dealer',
      status: 'active',
      lastInactivationDate: null,
      lastReActivationDate: null,
      lastLogin: null,
      editedBy: userName,
    };
    formData.dob = formDetails.dob ? moment(formDetails.dob).format('MM/DD/YYYY') : '';
    formData.dealers = formDetails?.dealers?.map(({ dealerId }) => dealerId);

    formData['includeReference'] = yesAndNo[formDetails['includeReference']];
    formData['includeSalesManager'] = yesAndNo[formDetails['includeSalesManager']];
    formData['isActive'] = yesAndNo[formDetails['isActive']];
    formData['maximizeTty'] = yesAndNo[formDetails['maximizeTty']];
    formData.phone = '+1' + formDetails.mobile;
    delete formData.dealerList;
    console.log('final formData');
    console.log(formData);
    const newObj = Object.keys(formDirty).reduce((a, v) => ({ ...a, [v]: formData[v] }), {
      editedBy: userName,
    });
    if (dealersTableDirty) {
      newObj.dealers = formData.dealers;
    }
    try {
      setLoader(true);
      const r = await ApiClient.post(
        UrlHelpers.updateUserInfo + fullProfileData.userId,
        { ...newObj },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      console.log(r);
      navigate('/view-user-profile', {
        state: { updateStatus: 'success', profileData: { userId: fullProfileData.userId } },
      });
    } catch (e) {
      console.log('error fetching', e);
    } finally {
      setLoader(false);
    }
  };
  const submitProfile = async () => {
    const formData = {
      ...formDetails,
      userSetupDate: moment().format('MM/DD/YYYY'),
      timeZone: 'PR',
      userType: 'Dealer Portal',
      dealerType: 'dealer',
      status: 'active',
      lastInactivationDate: null,
      lastReActivationDate: null,
      lastLogin: null,
    };
    formData.dob = moment(formDetails.dob).format('MM/DD/YYYY');
    formData.dealers = formDetails?.dealers?.map(({ dealerId }) => dealerId);

    formData['includeReference'] = yesAndNo[formDetails['includeReference']];
    formData['includeSalesManager'] = yesAndNo[formDetails['includeSalesManager']];
    formData['isActive'] = yesAndNo[formDetails['isActive']];
    formData['maximizeTty'] = yesAndNo[formDetails['maximizeTty']];
    formData.phone = '+1' + formDetails.mobile;
    console.log('final formData');
    console.log(formData);
    try {
      setLoader(true);
      const r = await ApiClient.post(
        `${UrlHelpers.enrollUserSubmit}`,
        { ...formData },
        {
          headers: {
            appid: 'auto',
            'x-api-key': process.env.REACT_APP_X_API_KEY,
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
            RoleID: role,
          },
        }
      );
      console.log(r);
      if (r.status.code === 200) {
        navigate('/manage-dealers', { state: { profileSubmitted: 'success', tabValue: 1 } });
      } else {
        setError(r.status);
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
      console.log('finally');
    }
  };
  const mobileNum =
    `(${formDetails?.mobile?.slice(0, 3)})` +
    `${formDetails?.mobile?.slice(3, 6)}` +
    '-' +
    `${formDetails?.mobile?.slice(6)}`;
  return (
    <>
      {loader && <Loader open={loader} />}
      <Typography variant="h3" component="p" pt={4} px={8}>
        Review & submit
      </Typography>
      <Grid container flexDirection="column" alignItems="center" justifyContent="center">
        {error?.code && (
          <Typography
            variant="body1"
            color="#D32F2F"
            style={{
              border: '1px solid #D32F2F',
              borderRadius: '3px',
              padding: '5px',
              marginTop: '10px',
            }}
          >
            {error?.code === 152 &&
              'We are unable to process your request. Please try again later.'}
            {error?.code === 150 && 'User ID already exists'}
          </Typography>
        )}
        {alertClose && (
          <Alert
            severity={'success'}
            onClose={() => {
              setAlertClose(false);
            }}
            sx={{ margin: '20px 0' }}
          >
            User profile has been updated successfully!
          </Alert>
        )}
        <Box sx={{ width: '70%' }}>
          <CustomCollapse
            heading="User information"
            open={true}
            editable={true}
            onEdit={userInfoEdit}
          >
            <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">User ID</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails.userId || '-'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">ID time zone</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails.timeZone || 'PR'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">User setup date</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {moment(formDetails.userSetupDate).format('MM/DD/YYYY') || '-'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">First name</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails.firstName || '-'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Last name</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails.lastName || '-'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Second last name</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails.secondLastName || '-'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Phone</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails?.mobile ? mobileNum : '--'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Email</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails.email || '-'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Include in sales manager?</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails?.includeSalesManager || 'No'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Include in reference?</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails?.includeReference || 'No'}
                </Typography>
              </Grid>
            </Grid>
          </CustomCollapse>
          <CustomCollapse
            heading="User status and configuration"
            open={true}
            editable={true}
            onEdit={userStatusEdit}
          >
            <Typography
              fontSize="18px !important"
              sx={{ fontWeight: 'bold', marginBottom: '10px' }}
            >
              User status
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Status</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails.status}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Password</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {'-'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Active</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails?.isActive || 'No'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Last inactivation date</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails?.lastInactivationDate
                    ? moment(formDetails.lastInactivationDate).format('MM/DD/yyyy, h:mm A')
                    : '-'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Last reactivation date</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails?.lastReActivationDate
                    ? moment(formDetails.lastReActivationDate).format('MM/DD/yyyy, h:mm A')
                    : '-'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Last login</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails?.lastLogin || '-'}
                </Typography>
              </Grid>
            </Grid>

            <Typography
              fontSize="18px !important"
              sx={{ fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}
            >
              User configuration
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Maximize TTY?</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails?.maximizeTty || 'No'}
                </Typography>
              </Grid>
            </Grid>

            <Typography
              fontSize="18px !important"
              sx={{ fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}
            >
              Miscellaneous Fields
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Date of birth</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails?.dob ? moment(formDetails.dob).format('MM/DD/YYYY') : ''}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">SSN (LAST 4 DIGITS)</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails.ssn || ''}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Vendor number</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ wordBreak: 'break-all' }}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails.vendorNumber || ''}
                </Typography>
              </Grid>
            </Grid>
          </CustomCollapse>
          <CustomCollapse
            heading="User profile"
            open={true}
            editable={true}
            onEdit={userProfileEdit}
          >
            <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Security profile</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails.securityProfile || ''}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">User type</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} fontWeight="700" color="#661C69">
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {'Dealer Portal'}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="h6" fontWeight="700" margin="20px 0">
              Dealer information
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Dealer type</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {'Dealer'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Dealer</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} fontWeight="700" color="#661C69">
                {formDetails.dealers?.length > 0 &&
                  formDetails.dealers?.map?.((x) => (
                    <Box display="flex" key={x.dealerId}>
                      <Typography variant="subtitle2" fontWeight="700" color="#661C69">
                        {x.dealerId}
                      </Typography>
                      <span>:&nbsp;</span>
                      <Typography variant="subtitle2" fontWeight="700" color="#661C69">
                        {x.dealer || x.dealerName}
                      </Typography>
                    </Box>
                  ))}
              </Grid>
            </Grid>
          </CustomCollapse>
        </Box>
      </Grid>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap={0.5} alignItems="center">
          <KeyboardArrowLeftIcon color="secondary" />
          <Link
            component="button"
            variant="subtitle2"
            color="secondary"
            onClick={() => {
              setActiveStep(3);
            }}
          >
            Back
          </Link>
        </Box>
        {!isViewProfile ? (
          <Box display="flex" gap={2} py={4} marginLeft={'auto'}>
            <Link
              component="button"
              onClick={() => {
                setOpenDiscardModal(true);
              }}
              variant="subtitle2"
              color="secondary"
              sx={{ fontSize: '16px' }}
              data-testid="discard-userprofilesubmit-application"
            >
              Discard application
            </Link>
            {/* <Button color="secondary" variant="outlined">
            Save as draft
          </Button> */}
            <Button
              color="secondary"
              variant="contained"
              onClick={submitProfile}
              // disabled={Object.entries(errors)?.length}
              data-testid="save-continue-userprofilesubmit"
            >
              Submit
            </Button>
          </Box>
        ) : (
          <Box display="flex" gap={2} py={4} marginLeft={'auto'}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              // sx={{ maxHeight: '40px' }}
              data-testid="cancel-userprofilesubmit-application"
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={updateData}
              // disabled={Object.entries(errors)?.length}
              data-testid="save-continue-userprofilesubmit"
            >
              Submit
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default UserProfileSubmit;

UserProfileSubmit.propTypes = {
  handleCancel: PropTypes.func,
};

UserProfileSubmit.defaultProps = {
  handleCancel: defaultFn,
};
