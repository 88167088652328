import { Divider, Toolbar, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import OrientalImage from 'assets/oriental.png';
import ProfileIcon from 'assets/svg/ProfileIcon.svg';
import PropTypes from 'prop-types';
import * as React from 'react';
import classes from 'styles/header.module.scss';
import { handleLanguageChange, handleLanguageClose, handleLanguageMenu } from 'utils/utility';

import { HeaderLanguageComponent } from 'components/CommonHeader/CommonHeader';

const InviHeader = () => {
  const [languageAnchor, setLanguageAnchor] = React.useState(null);
  const [selectedLanguage, setSelectedLanguage] = React.useState(1);
  const isLanguageOpen = Boolean(languageAnchor);
  const options = ['English', 'Espanol'];
  const isMobile = useMediaQuery('(max-width:599px)');
  const isMobileOptions = ['Eng', 'Esp'];

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ backgroundColor: 'white' }}>
          <Toolbar>
            <Box width="150px">
              {' '}
              <img
                src={OrientalImage}
                style={{ width: '100%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                alt="Dealer Portal Logo"
              />
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              px={2}
              className={classes.verticalDivider}
            />
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <HeaderLanguageComponent
                isLanguageOpen={isLanguageOpen}
                options={isMobile ? isMobileOptions : options}
                handleLanguageChange={handleLanguageChange}
                setLanguageAnchor={setLanguageAnchor}
                selectedLanguage={selectedLanguage}
                languageAnchor={languageAnchor}
                handleLanguageClose={handleLanguageClose}
                handleLanguageMenu={handleLanguageMenu}
                setSelectedLanguage={setSelectedLanguage}
              />

              <Box display="flex" alignItems="center" justifyContent="center" marginRight="5px">
                <Typography variant={isMobile ? 'body1' : 'subtitle'} component="p">
                  Hello Guest &nbsp;
                </Typography>
                <img src={ProfileIcon} />
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default InviHeader;

InviHeader.propTypes = {
  open: PropTypes.bool,
};
InviHeader.defaultProps = {
  open: false,
};
