/* eslint-disable react/prop-types */
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Button, Grid, IconButton, Link, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import CancelIcon from 'assets/svg/IconCancelCircle.svg';
import CheckIcon from 'assets/svg/IconCheckCircle.svg';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import CustomCollapse from 'components/CustomCollapse';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';
import Loader from 'components/Loader/Loader';

const ApproveRequest = ({ control, errors, handleSubmit }) => {
  const { setApproveClicked, setDialogOpen, accessToken, role, isUserIdValid, setIsUserIdValid } =
    useContext(AuthContext);
  const [alertToggle, setAlertToggle] = useState('flex');
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({});
  const [securityProfiles, setSecurityProfiles] = useState([]);
  const location = useLocation();
  const { reqId } = location?.state || {};

  const navigate = useNavigate();

  const ApiClient = new ApiClass();

  const [
    watchuserId,
    watchincludeInSalesManager,
    watchincludeInReference,
    watchactive,
    watchmaximizeTY,
    watchsecurityProfile,
    watchvendorNumber,
  ] = useWatch({
    control,
    name: [
      'userId',
      'includeInSalesManager',
      'includeInReference',
      'active',
      'maximizeTY',
      'securityProfile',
      'vendorNumber',
    ],
  });

  useEffect(async () => {
    if (!watchuserId) {
      setIsUserIdValid(null);
    }
    try {
      setLoader(true);
      let securityProfileList = await ApiClient.get(UrlHelpers.securityProfiles);
      console.log({ securityProfileList });
      securityProfileList = JSON.parse(securityProfileList?.['security-profile']);

      securityProfileList = securityProfileList.map((x) => {
        const { displayName: name, value } = x;
        return { name, value };
      });
      setSecurityProfiles(securityProfileList);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  }, []);

  const handleClose = () => {
    setAlertToggle('none');
  };

  const userIdCheck = async () => {
    let response = null;
    if (watchuserId && watchuserId.length > 6) {
      setLoader(true);
      try {
        response = await ApiClient.post(`${UrlHelpers.userIdCheck}${watchuserId}`);
        if (response?.statusCode === 200) {
          setIsUserIdValid(true);
        } else {
          setIsUserIdValid(false);
        }
      } catch (e) {
        setIsUserIdValid(null);
        console.log(e);
      } finally {
        setLoader(false);
      }
    } else {
      setIsUserIdValid(null);
    }
  };

  const submitAction = async () => {
    const payload = {
      userId: watchuserId,
      linkedUserId: watchuserId,
      includeSalesManager: watchincludeInSalesManager === 'Yes',
      includeReference: watchincludeInReference === 'Yes',
      isActive: watchactive === 'Yes',
      maximizeTty: watchmaximizeTY === 'Yes',
      team: 'DEALER',
      securityProfile: watchsecurityProfile,
      vendorNumber: watchvendorNumber,
    };
    try {
      setLoader(true);
      const resp = await ApiClient.post(
        `${UrlHelpers.userRequestStatus}status?crit=approve+${reqId}`,
        {
          ...payload,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
            RoleID: role,
          },
        }
      );
      if (resp?.status?.code === 200) {
        setDialogOpen(true);
      } else {
        setError(resp?.status);
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div>
      {loader && <Loader open={loader} />}
      <Typography variant="h3" py={2}>
        Additional required information
      </Typography>
      <Box sx={{ width: '70%', margin: 'auto' }}>
        <Box
          sx={{
            display: `${alertToggle}`,
            flexDirection: 'row',
            alignItems: 'flex-start',
            padding: '10px',
            justifyContent: 'space-between',
            background: '#F1F6FD',
            borderWidth: '0px 0px 0px 5px',
            borderStyle: 'solid',
            borderColor: '#0052EA',
            gap: '10px',
          }}
        >
          <Box display="flex" sx={{ gap: '10px' }}>
            <InfoOutlinedIcon sx={{ color: '#0052EA' }} />
            <Typography variant="subtitle2">
              To approve, please fill the additional information and submit the request
            </Typography>
          </Box>
          <IconButton
            data-testid="closeBtn"
            onClick={handleClose}
            sx={{ height: '16px', width: '16px' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <CustomCollapse heading="Basic information" open={true} errors={errors}>
          {error?.code === 152 && (
            <Typography
              variant="body1"
              color="#D32F2F"
              textAlign="center"
              style={{ border: '1px solid #D32F2F', borderRadius: '3px', padding: '5px' }}
            >
              We are unable to process your request. Please try again later.
            </Typography>
          )}
          <Grid container rowSpacing={1} columnSpacing={4} justifyContent="center">
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomInputBox
                control={control}
                errors={!!errors?.userId}
                errorText={errors?.userId?.message}
                placeholder="Enter user id"
                label="User ID"
                name="userId"
                flexDirection="column"
                required
                regex={/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\d\s]*$/}
                maxLength={15}
                onFocus={() => setIsUserIdValid(null)}
                onBlur={userIdCheck}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              style={{
                alignSelf: 'end',
              }}
            >
              {isUserIdValid !== null && (
                <Box
                  sx={{
                    padding: '12px',
                    border: `1px solid ${isUserIdValid ? '#128A08' : '#D71721'}`,
                    borderLeft: `5px solid ${isUserIdValid ? '#128A08' : '#D71721'}`,
                    color: `${isUserIdValid ? '#128A08' : '#D71721'}`,
                  }}
                >
                  <Typography variant="subtitle2" component="p" display="flex" alignItems="center">
                    <img src={isUserIdValid ? CheckIcon : CancelIcon} /> &nbsp; &nbsp;
                    <span>{isUserIdValid ? 'User ID available' : 'User ID not available'}</span>
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="Include in sales manager?"
                control={control}
                name="includeInSalesManager"
                errors={!!errors?.includeInSalesManager}
                errorText={errors?.includeInSalesManager?.message}
                options={[
                  {
                    name: 'Yes',
                    value: 'Yes',
                  },
                  {
                    name: 'No',
                    value: 'No',
                  },
                ]}
                defaultValue=""
                required
                displayEmpty
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="Include in reference?"
                control={control}
                name="includeInReference"
                errors={!!errors?.includeInReference}
                errorText={errors?.includeInReference?.message}
                options={[
                  {
                    name: 'Yes',
                    value: 'Yes',
                  },
                  {
                    name: 'No',
                    value: 'No',
                  },
                ]}
                defaultValue=""
                required
                displayEmpty
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="Active"
                control={control}
                name="active"
                errors={!!errors?.active}
                errorText={errors?.active?.message}
                options={[
                  {
                    name: 'Yes',
                    value: 'Yes',
                  },
                  {
                    name: 'No',
                    value: 'No',
                  },
                ]}
                defaultValue=""
                required
                displayEmpty
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="Maximize TTY"
                control={control}
                name="maximizeTY"
                errors={!!errors?.maximizeTY}
                errorText={errors?.maximizeTY?.message}
                options={[
                  {
                    name: 'Yes',
                    value: 'Yes',
                  },
                  {
                    name: 'No',
                    value: 'No',
                  },
                ]}
                defaultValue=""
                required
                displayEmpty
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="Security profile"
                control={control}
                name="securityProfile"
                errors={!!errors?.securityProfile}
                errorText={errors?.securityProfile?.message}
                options={securityProfiles}
                defaultValue=""
                required
                displayEmpty
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomInputBox
                control={control}
                errors={!!errors?.vendorNumber}
                errorText={errors?.vendorNumber?.message}
                placeholder="Enter vendor number"
                label="Vendor number"
                name="vendorNumber"
                flexDirection="column"
                maxLength="11"
              />
            </Grid>
          </Grid>
        </CustomCollapse>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
          <KeyboardArrowLeftIcon color="secondary" />
          <Link
            component="button"
            variant="subtitle2"
            color="secondary"
            onClick={() => {
              setApproveClicked(false);
            }}
          >
            Back
          </Link>
        </Box>
        <Box display="flex" gap={2} py={4} marginLeft={'auto'}>
          <Button
            component="button"
            onClick={() => {
              navigate('/home');
              setApproveClicked(false);
            }}
            variant="outlined"
            color="secondary"
            sx={{ fontSize: '16px' }}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSubmit(submitAction)}
            data-testid="approve-userreq"
            disabled={Object.entries(errors)?.length || !isUserIdValid}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </div>
  );
};

ApproveRequest.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
};
ApproveRequest.defaultProps = {
  control: {},
  errors: {},
};
export default ApproveRequest;
