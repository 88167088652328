import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import ApiClass from 'Api/ApiClient';
import { getAccessToken } from 'Services/commonComponentsApi';
import AuthContext from 'context/AuthContext';
import CreateApplicationContext from 'context/CreateApplicationContext';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MoreDetailDialogSchema } from 'schemaValidations/MoreDetailDialogSchema';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';
import CustomRadioButton from 'components/CustomRadioButton/CustomRadioButton';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';
import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

import {
  dealersRoleBased,
  getManagerList, // getManagerSalesList,
  getSalesList,
  popUpApis,
} from '../InformationRequest/moduleUtilities/dropdownApis';

const MoreDetailDialog = () => {
  const { showMoreDetail, setShowMoreDetail, setShowAppType, loanData, setLoanData } =
    useContext(CreateApplicationContext);
  const {
    setOpenPopUp,
    createAppApplicationId,
    setCreateAppApplicationId,
    loanPurposeList,
    setLoanPurposeList,
    programList,
    setProgramList,
    commonToken,
    setCommonToken,
    setProgram,
    accessToken,
    role,
    createAppLookup,
    setCreateAppLookup,
    collateralInfo,
    financeInfo,
    setLookupSaved,
    setCreateAppFlag,
    userID,
    rolebasedDealers,
    setRoleBasedDealers,
    createAppLaunchType,
    setCreateAppLaunchType,
    setDealer,
    dealer,
    fullName,
  } = useContext(AuthContext);

  const [popUpLoader, setPopUpLoader] = useState(false);
  const [initialApiLoader, setInitialApiLoader] = useState(false);
  const [managerList, setManagerList] = useState([]);
  const [salesReferenceList, setSalesReferenceList] = useState([]);
  const [apiErr, setApiErr] = useState('');

  const ApiClient = new ApiClass();
  const handleClose = () => {
    setShowMoreDetail(false);
    setOpenPopUp(false);
    setCreateAppFlag(false);
    setCreateAppLaunchType('');
    setCreateAppLookup('');
    setCreateAppApplicationId('');
    setDealer('');
  };
  const {
    control,
    watch,
    formState: { errors },
    setValue,
    handleSubmit,
    clearErrors,
  } = useForm({
    mode: 'all',
    defaultValues: {
      loanPurpose: '',
      program: '',
      dealer: '',
      manager: '',
      dealerSalesReference: '',
    },
    resolver: yupResolver(MoreDetailDialogSchema),
  });
  const watchDealer = watch('dealer');
  console.log('11111111', watchDealer);
  useEffect(async () => {
    setValue('dealer', dealer);
    if (!commonToken) {
      const token = await getAccessToken();

      setCommonToken(token);
    }

    let dealerList = await dealersRoleBased({
      rolebasedDealers,
      setRoleBasedDealers,
      role,
      userID,
    });
    if (dealerList?.length === 1) {
      setValue('dealer', dealerList[0]?.dealerId);
    }
    if (!loanPurposeList?.length || !programList?.length) {
      await popUpApis({
        loanPurposeList,
        setLoanPurposeList,
        programList,
        setProgramList,
        setInitialApiLoader,
        accessToken,
        role,
      });
    }
    if (Object.entries(loanData)?.length) {
      applyData(loanData);
    } else if (createAppApplicationId) {
      await getSpecificDetailsApi();
    }
    if (Object.entries(financeInfo)?.length) {
      setValue('program', financeInfo?.formData?.programVal);
    }
  }, []);

  useEffect(async () => {
    console.log('intooo useeffect dealer change', watchDealer);
    if (watchDealer) {
      setValue('manager', '');
      setValue('dealerSalesReference', '');
      console.log('intooo dealer set', watchDealer);
      setInitialApiLoader(true);
      console.log({ watchDealer });

      let managersList = await getManagerList(watchDealer);
      console.log({ checker: managersList });
      managersList = managersList?.list?.map((x) => {
        return setName(x);
      });
      setManagerList(managersList);
      if (managersList?.length === 1) {
        setValue('manager', managersList[0].value);
        clearErrors('manager');
      }

      let salesRefList = await getSalesList(watchDealer);
      salesRefList = salesRefList?.list?.map((x) => {
        return setName(x);
      });

      setSalesReferenceList(salesRefList);

      if (salesRefList?.length === 1) {
        setValue('dealerSalesReference', salesRefList[0].value);
        clearErrors('dealerSalesReference');
      }
      setInitialApiLoader(false);
    }
  }, [watchDealer]);

  const getSpecificDetailsApi = async () => {
    try {
      setInitialApiLoader(true);

      const getResp = await ApiClient.get(
        `${UrlHelpers.getSpecificDetails}?applicationId=${createAppApplicationId}&eventName=CreateLoanApplication&isLockRequired=true`,
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );

      console.log('getResp', getResp);
      const { CreateLoanApplication: loanInformation } = getResp;
      const loanDataa = {
        loanPurpose: loanInformation?.loanPurpose,
        program: loanInformation?.program,
        dealer: loanInformation?.dealerId,
        manager: loanInformation?.manager,
        dealerSalesReference: loanInformation?.dealerSalesReference,
      };
      if (Object.entries(loanDataa).length) applyData(loanDataa);
    } catch (e) {
      console.log('error', e);
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later.'
      );
    } finally {
      setInitialApiLoader(false);
    }
  };
  const setName = (x) => {
    if (x?.middleName) {
      return {
        name: `${x?.firstName} ${x?.lastName} ${x?.middleName}`,
        value: `${x.userId}`,
      };
    }
    return {
      name: `${x?.firstName} ${x?.lastName}`,
      value: `${x.userId}`,
    };
  };

  console.log('loandata', loanData);
  const applyData = async (formFieldss) => {
    console.log('formFields', formFieldss);
    Object.entries(formFieldss).forEach(([key, value]) => {
      setValue(key, value);
    });
  };

  useEffect(() => {
    if (loanPurposeList?.length) {
      setValue('loanPurpose', loanPurposeList[0]?.value);
    }
  }, [loanPurposeList]);

  const handleContinue = async (data) => {
    console.log('into submit');
    const { loanPurpose, program, dealer, manager, dealerSalesReference } = data;
    setDealer(dealer);
    setProgram(program);
    const formData = {
      loanInformation: {
        loanPurpose: loanPurpose,
        program: program,
        dealer: rolebasedDealers?.find((x) => x.dealerId === watchDealer)?.dealerName,
        dealerId: dealer,
        manager: manager,
        dealerSalesReference: dealerSalesReference,
      },
    };

    let createAppPayload = {
      event: 'CreateLoanApplication',
      application: {
        products: {
          productType: 'Indirect Loan Account',
          productName: 'AutoLoan',
        },
        userId: userID,
        ...formData,
        source: {
          language: 'EN',
          location: 'PR',
        },
        launchType: createAppLaunchType,
      },
    };
    if (createAppLookup === 'used' || createAppLookup === 'new' || createAppLookup === 'dd') {
      createAppPayload.application.type = 'vehicle';
      createAppPayload.application.searchResult = JSON.stringify(collateralInfo);
      setLookupSaved(true);
    }
    if (createAppLookup === 'calc') {
      createAppPayload.application.type = 'FinanceTermPayCalc';
      createAppPayload.application.searchResult = JSON.stringify(financeInfo);
    }
    if (createAppApplicationId) {
      createAppPayload.application.applicationId = createAppApplicationId;
    }
    try {
      setPopUpLoader(true);
      let dataa = await ApiClient.post(
        UrlHelpers.applicationCreate,

        { ...createAppPayload },
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );
      console.log('dataaaa', dataa);
      if (dataa) {
        setCreateAppApplicationId(dataa?.applicationId);

        setShowMoreDetail(false);
        setShowAppType(true);
        setLoanData(data);
      }
    } catch (e) {
      console.log('error fetching', e);

      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later.'
      );
    } finally {
      setPopUpLoader(false);
    }
  };

  return (
    <>
      <Dialog
        open={showMoreDetail}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-desc"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              minWidth: 'clamp(400px, 50vw, 800px) !important',
              minHeight: 'clamp(450px,60vh,100%) !important',
            },
          },
        }}
      >
        <Loader open={initialApiLoader} />
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="h3" component="span" id="dialog-title">
            Create application
          </Typography>
          <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
        </BootstrapDialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box display="flex" flexDirection="column" gap={2.5} px={5} mb={2.5}>
            <Grid container sx={{ justifyContent: 'center' }}>
              <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
            </Grid>
            <Typography variant="h4" textAlign="center" my={3} id="dialog-desc">
              Alright! Let&apos;s get started with few details
            </Typography>
            <Loader open={popUpLoader} />

            <Grid container gap={1} justifyContent="space-between" rowSpacing={2}>
              <Grid item lg={5.5} md={6} sm={12}>
                <CustomSelectBox
                  flexDirection="column"
                  label="Loan purpose"
                  control={control}
                  name="loanPurpose"
                  errors={!!errors?.loanPurpose}
                  errorText={errors?.loanPurpose?.message}
                  options={loanPurposeList}
                  isDisabled={loanPurposeList?.length}
                  required
                  displayEmpty
                />
              </Grid>
              <Grid item lg={5.5} md={6} sm={12}>
                <CustomRadioButton
                  control={control}
                  radioValues={programList}
                  label="Program"
                  required
                  name="program"
                  dataTestId="program"
                  errors={!!errors?.program}
                  errorText={errors?.program?.message}
                  displayInline
                  isdisabled={!!financeInfo?.formData?.programVal}
                />
              </Grid>
              <Grid item lg={5.5} md={6} sm={12}>
                <CustomSelectBox
                  flexDirection="column"
                  label="Dealer"
                  control={control}
                  name="dealer"
                  isDisabled={!!createAppApplicationId || !!financeInfo?.formData?.dealer}
                  errors={!!errors?.dealer}
                  errorText={errors?.dealer?.message}
                  options={rolebasedDealers?.map((dealers) => ({
                    name: dealers.dealerName,
                    value: dealers.dealerId,
                  }))}
                  defaultValue=""
                  required
                  displayEmpty
                />
              </Grid>
              <Grid item lg={5.5} md={6} sm={12}>
                <CustomSelectBox
                  flexDirection="column"
                  label="Manager"
                  control={control}
                  name="manager"
                  errors={!!errors?.manager}
                  errorText={errors?.manager?.message}
                  options={managerList}
                  defaultValue=""
                  required
                  displayEmpty
                />
              </Grid>
              <Grid item lg={5.5} md={6} sm={12}>
                <CustomSelectBox
                  flexDirection="column"
                  label="Sales person"
                  control={control}
                  name="dealerSalesReference"
                  errors={!!errors?.dealerSalesReference}
                  errorText={errors?.dealerSalesReference?.message}
                  options={salesReferenceList}
                  defaultValue=""
                  required
                  displayEmpty
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
            id="cancel-btn"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            sx={{ maxHeight: '40px' }}
            onClick={handleSubmit(handleContinue)}
            id="continue-btn"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MoreDetailDialog;
