import { Box, Grid, Link, Paper, TableHead } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ApiClass from 'Api/ApiClient';
import {
  TableCellTypography,
  TableHeaderTypography,
} from 'pages/ApplicationDashboard/ApplicationsTable';
import { SearchFilters } from 'pages/ApplicationDashboard/SearchFilters';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import Loader from 'components/Loader/Loader';

import TablePagination from '../RegisterUser/TablePagination';

const DealerProfiles = ({ setViewDealer, setDealer }) => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [searchOptions, setSearchOptions] = useState({
    dealerCode: '',
    dealerName: '',
    active: '1',
    dealerRating: '',
  });
  const [emptyDataMessage, setEmptyDataMessage] = useState('No dealer/s available');
  const ApiClient = new ApiClass();
  const rowsPerPage = 10;
  const indexOfLastPost = page * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const calcUserList = (userArr) => {
    return userArr?.slice(indexOfFirstPost, indexOfLastPost);
  };
  const fetchDealerProfiles = async () => {
    try {
      setLoader(true);
      const resp = await ApiClient.get(`${UrlHelpers.dealers}?status=1`);

      console.log(resp);
      if (resp) {
        setData(resp);
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(async () => {
    fetchDealerProfiles();
  }, []);

  const handleLinkClick = (dealerId, name) => {
    setDealer({ dealerId, name });
    setViewDealer(true);
  };

  const paginate = (_, pageNumber) => {
    setPage(pageNumber);
  };

  const tableHeading = ['Dealer code', 'Dealer name', 'Active', 'Dealer rating'];

  return (
    <>
      {loader && <Loader open={loader} />}
      <SearchFilters
        searchOptions={searchOptions}
        setSearchOptions={setSearchOptions}
        applicationData={data}
        setApplicationData={setData}
        setLoader={setLoader}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        flow="DealerProfiles"
        setEmptyDataMessage={setEmptyDataMessage}
        fetchDealerProfiles={fetchDealerProfiles}
      />
      <Grid
        container
        sx={{
          overflowX: 'auto',
          width: '100%',
        }}
      >
        <Paper sx={{ width: '100%', boxShadow: 'none' }}>
          <Table aria-label="applications-table">
            <TableHead>
              <TableRow
                sx={{ backgroundColor: '#F2F2F2' }}
                className={classes.tableHeadingApplications}
              >
                {tableHeading?.map((x) => {
                  return (
                    <TableCell key={x}>
                      <TableHeaderTypography>{x}</TableHeaderTypography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {calcUserList(data)?.map((row) => (
                <TableRow
                  key={row?.dealerId}
                  className={classes.tableHeadingApplications}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <TableCellTypography>
                      <Link
                        component="button"
                        variant="subtitle2"
                        sx={{ color: '#0065CC', textDecoration: 'initial', textAlign: 'left' }}
                        onClick={() => handleLinkClick(row?.dealerId, row?.dealerName)}
                      >
                        {row?.dealerId}
                      </Link>
                    </TableCellTypography>
                  </TableCell>
                  <TableCell>
                    <TableCellTypography>{row?.dealerName}</TableCellTypography>
                  </TableCell>
                  <TableCell>
                    <TableCellTypography>{row?.status === '1' ? 'Yes' : 'No'}</TableCellTypography>
                  </TableCell>
                  <TableCell>
                    <TableCellTypography>{row?.originatorOrDealerRating}</TableCellTypography>
                  </TableCell>
                </TableRow>
              ))}

              {data?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={12} sx={{ fontSiez: '14px !important', textAlign: 'center' }}>
                    {emptyDataMessage}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Box sx={{ margin: '15px' }}>
        <TablePagination
          postsPerPage={10}
          totalPosts={data?.length}
          paginate={paginate}
          page={page}
        />
      </Box>
    </>
  );
};

DealerProfiles.propTypes = {
  setDealer: PropTypes.func,
  setViewDealer: PropTypes.func,
};

DealerProfiles.defaultProps = {
  setDealer: () => {},
  setViewDealer: () => {},
};

export default DealerProfiles;
