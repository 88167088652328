import { Box, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import classes from 'styles/globalStyle.module.scss';
import { nameMapping } from 'utils/utility';

const GeneralInfo = ({ productDetails }) => {
  const { lookupStateList } = useContext(AuthContext);
  const {
    valueGuide,
    collateralType,
    year,
    make,
    model,
    series,
    subModel,
    style,
    state,
    mileage,
    vin,
  } = productDetails;
  const stateMapping = lookupStateList?.find((x) => x.value === state)?.name;
  console.log('stateMapping', { stateMapping, lookupStateList, state });
  return (
    <>
      <Typography variant="caption" sx={{ fontSize: '16px !important', fontWeight: '700' }}>
        General info
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        gap={1.5}
        className={classes.borderTable}
        sx={{ backgroundColor: 'white', border: '1px solid #DADADA' }}
        role="table"
      >
        <Box
          display="flex"
          sx={{ borderBottom: '1px solid #F2F2F2' }}
          p={1}
          id="collateral-type"
          role="row"
        >
          <Typography sx={{ minWidth: '150px' }} role="cell">
            Collateral type
          </Typography>
          <Typography fontWeight="700" row="cell">
            {nameMapping[collateralType]}
          </Typography>
        </Box>
        <Box
          display="flex"
          sx={{ borderBottom: '1px solid #F2F2F2' }}
          p={1}
          id="value-guide"
          role="row"
        >
          <Typography role="cell" sx={{ minWidth: '150px' }}>
            Value guide selection
          </Typography>
          <Typography role="cell" fontWeight="700">
            {nameMapping[valueGuide]}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={1.5}
        className={classes.borderTable}
        sx={{ minWidth: '70%', backgroundColor: 'white', border: '1px solid #DADADA' }}
        role="table"
      >
        {vin && (
          <Box role="row" display="flex" sx={{ borderBottom: '1px solid #F2F2F2' }} p={1} id="vin">
            <Typography role="cell" sx={{ minWidth: '150px' }}>
              VIN
            </Typography>
            <Typography role="cell" sx={{ fontWeight: 700 }}>
              {vin}
            </Typography>
          </Box>
        )}
        <Box display="flex" sx={{ borderBottom: '1px solid #F2F2F2' }} p={1} id="year" role="row">
          <Typography role="cell" sx={{ minWidth: '150px' }}>
            Year
          </Typography>
          <Typography role="cell" sx={{ fontWeight: 700 }}>
            {year}
          </Typography>
        </Box>
        <Box role="row" display="flex" sx={{ borderBottom: '1px solid #F2F2F2' }} p={1} id="brand">
          <Typography role="cell" sx={{ minWidth: '150px' }}>
            Make
          </Typography>
          <Typography role="cell" sx={{ fontWeight: 700 }}>
            {make}
          </Typography>
        </Box>
        <Box role="row" display="flex" sx={{ borderBottom: '1px solid #F2F2F2' }} p={1} id="model">
          <Typography role="cell" sx={{ minWidth: '150px' }}>
            Model
          </Typography>
          <Typography role="cell" sx={{ fontWeight: 700 }}>
            {model}
          </Typography>
        </Box>
        <Box
          role="row"
          display="flex"
          sx={{ borderBottom: '1px solid #F2F2F2' }}
          p={1}
          id="sub-model"
        >
          <Typography role="cell" sx={{ minWidth: '150px' }}>
            Sub model
          </Typography>
          <Typography role="cell" sx={{ fontWeight: 700 }}>
            {valueGuide === 'Distributor Data' ? subModel : series}
          </Typography>
        </Box>
        <Box
          role="row"
          display="flex"
          sx={{ borderBottom: '1px solid #F2F2F2' }}
          p={1}
          id="mileage"
        >
          <Typography role="cell" sx={{ minWidth: '150px' }}>
            Mileage
          </Typography>
          <Typography role="cell" sx={{ fontWeight: 700 }}>
            {+mileage}
          </Typography>
        </Box>
        {valueGuide !== 'Distributor Data' && (
          <>
            <Box
              role="row"
              display="flex"
              sx={{ borderBottom: '1px solid #F2F2F2' }}
              p={1}
              id="style"
            >
              <Typography role="cell" sx={{ minWidth: '150px' }}>
                Trim
              </Typography>
              <Typography role="cell" sx={{ fontWeight: 700 }}>
                {style}
              </Typography>
            </Box>
            {collateralType === 'used' && (
              <Box
                role="row"
                display="flex"
                sx={{ borderBottom: '1px solid #F2F2F2' }}
                p={1}
                id="state"
              >
                <Typography role="cell" sx={{ minWidth: '150px' }}>
                  State
                </Typography>
                <Typography role="cell" sx={{ fontWeight: 700 }}>
                  {stateMapping || state}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default GeneralInfo;

GeneralInfo.propTypes = {
  productDetails: PropTypes.instanceOf(Object),
};
GeneralInfo.defaultProps = {
  productDetails: {},
};
