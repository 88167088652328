import InvitationFlowContext from 'context/InvitationFlowContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { defaultFn } from 'utils/utility';

import Loader from 'components/Loader/Loader';

import InviConsent from './InviConsent/InviConsent';
import InviEmploymentInfo from './InviEmploymentInfo/InviEmploymentInfo';
import InviInformationRequest from './InviInformationRequest/InviInformationRequest';

const InviRenderStep = ({ invitationExpiryCheck }) => {
  const { activeStep } = useContext(InvitationFlowContext);
  switch (activeStep) {
    case 1: {
      return <InviInformationRequest invitationExpiryCheck={invitationExpiryCheck} />;
    }
    case 2: {
      return <InviEmploymentInfo />;
    }

    case 3: {
      return <InviConsent />;
    }

    default: {
      return <Loader open />;
    }
  }
};

export default InviRenderStep;
InviRenderStep.propTypes = {
  activeStep: PropTypes.number,
  invitationExpiryCheck: PropTypes.func,
};
InviRenderStep.defaultProps = {
  activeStep: 1,
  invitationExpiryCheck: defaultFn,
};
