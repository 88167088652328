import AuthContext from 'context/AuthContext';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

const OrientalSSO = () => {
  console.log('orientalsso');

  const { userType } = useContext(AuthContext);
  const cognitoConfig = JSON.parse(process.env.REACT_APP_CONFIG);
  console.log('cognito config', cognitoConfig);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = new useQuery();

  const loginUrl =
    'https://' +
    cognitoConfig.DOMAIN +
    '/oauth2/authorize?response_type=' +
    cognitoConfig.RESPONSETYPE +
    '&client_id=' +
    cognitoConfig.APP_CLIENT_ID +
    '&redirect_uri=' +
    cognitoConfig.REDIRECTSIGNIN;

  if (userType === 'banker') {
    window.location.href = loginUrl;
  } else if (userType === 'dealer') {
    if (query.get('autoSignout') === 'yes') {
      window.location.href = `${process.env.REACT_APP_IDM_URL}/?autoSignout=yes`;
    } else {
      console.log('intoooo dealer oriental sso', process.env.REACT_APP_IDM_URL);
      window.location.href = process.env.REACT_APP_IDM_URL;
    }
  }
  return null;
};

export default OrientalSSO;
