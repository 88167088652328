import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  Link,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import ApiClass from 'Api/ApiClient';
import ApprovedSubIcon from 'assets/svg/ApprovedSubIcon.svg';
import DeclinedSubIcon from 'assets/svg/DeclinedSubIcon.svg';
import DraftIcon from 'assets/svg/DraftIcon.svg';
import ThumbsUpIcon from 'assets/svg/ThumbsUpIcon.svg';
import TimerSubIcon from 'assets/svg/TimerSubIcon.svg';
import TotalApplicationSubIcon from 'assets/svg/TotalApplicationSubIcon.svg';
import WithdrawSubIcon from 'assets/svg/WithdrawSubIcon.svg';
import LeftArrow from 'assets/svg/leftArrow.svg';
import PenBkIcon from 'assets/svg/penbkIcon.svg';
import AuthContext from 'context/AuthContext';
import ReviewAndSubmit from 'pages/CreateApplication/ReviewAndSubmit/ReviewAndSubmit';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';
import Loader from 'components/Loader/Loader';
import TabPanel from 'components/TabPanel/TabPanel';

import printIcon from '../../assets/printIcon.svg';
import { ApplicationDetails } from './ApplicationDetails';
import CommentsTab from './CommentsTab';
import ContractGenerationTab from './ContractGeneration/ContractGenerationTab';
import DecisionDetailsTab from './DecisionDetailsTab';
import IdVerificationPopup from './IdVerificationPopup';
import OverviewTab from './OverviewTab';
import { PrintDocumentsModal } from './PrintDocumentsModal';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ViewApplication = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);
  const location = useLocation();
  let {
    applicationDetails,
    tabValue: tabState = 0,
    updateFlag: updateFlagState = false,
    path,
    tabValAppDashboard,
  } = location?.state || {};
  console.log('location state', applicationDetails);
  const ApiClient = new ApiClass();
  const {
    setCreateAppApplicationId,
    setDealer,
    fullName,
    userID,
    setCreateAppFlag,
    setGeneratedDoc,
  } = useContext(AuthContext);
  const [commentList, setCommentList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [tabVal, setTabValue] = useState(tabState);
  const [updateFlag, setUpdateFlag] = useState(updateFlagState);
  const [enableContractGen, setEnableContractGen] = useState(false);
  const [isIdVerified, setIsIdVerified] = useState(true);
  const [getInfoReq, setInfoReq] = useState({});
  const [idVerifiedAlertMessage, setIdVerifiedAlertMessage] = useState('');
  const [payCalcDetail, setPayCalcDetails] = useState([]);

  useEffect(async () => {
    if (Object.entries(applicationDetails)?.length) {
      setGeneratedDoc([]);
      setCreateAppApplicationId(applicationDetails?.initAppId);
      setDealer(applicationDetails?.initAppId?.split('-')?.[0]);
      setTabValue(tabState);
      if (
        !['DECLINE', 'PENDINGANALYST', 'Not received', 'DECSCOR'].includes(
          applicationDetails?.loanStatus
        )
      ) {
        await getSpecificDetailsApi();
      }
    }
  }, [applicationDetails]);
  const handleIDUploadStatus = async ({ informationRequest, coSigners, coApplicants }) => {
    let idUploadStatus = true;
    if (
      Object.entries(informationRequest)?.length &&
      !informationRequest?.participantDetailInfo?.manualUploadFile &&
      informationRequest?.participantDetailInfo?.vouchedIdStatus === 'false'
    ) {
      idUploadStatus = false;
    }
    if (
      Object.entries(coApplicants)?.length &&
      !coApplicants?.[0].informationRequest?.participantDetailInfo?.manualUploadFile &&
      coApplicants?.[0].informationRequest?.participantDetailInfo?.vouchedIdStatus === 'false'
    ) {
      idUploadStatus = false;
    }

    if (coSigners.length > 0) {
      coSigners.map((x) => {
        if (
          !x.informationRequest?.participantDetailInfo?.manualUploadFile &&
          x.informationRequest?.participantDetailInfo?.vouchedIdStatus === 'false'
        ) {
          idUploadStatus = false;
        }
      });
    }
    return idUploadStatus;
  };

  const getSpecificDetailsApi = async () => {
    try {
      setLoader(true);
      const getResp = await ApiClient.get(
        `${UrlHelpers.getSpecificDetails}?applicationId=${applicationDetails?.initAppId}&eventName=InformationRequest&eventType=contractGeneration`,
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );
      setInfoReq(getResp);
      if (Object.entries(getResp).length) {
        const {
          informationRequest,
          coSigners = [],
          coApplicants = {},
        } = getResp?.participantsDetailInformation;
        let idUploadStatus = await handleIDUploadStatus({
          informationRequest,
          coSigners,
          coApplicants,
        });

        // to check if state is PR

        setIsIdVerified(idUploadStatus);
        setIdVerifiedAlertMessage(
          'ID verification/upload is pending for this application. Please click on edit within application details'
        );
        setEnableContractGen(idUploadStatus);
        if (
          Object.entries(informationRequest)?.length &&
          !informationRequest?.participantDetailInfo?.isStatePR &&
          idUploadStatus
        ) {
          setIdVerifiedAlertMessage(
            ' Please upload a utility bill issued in PR within last 60 days to proceed. Please click on edit within application details tab'
          );
          setIsIdVerified(false);
          setEnableContractGen(false);
        }

        const parsedResp = JSON.parse(getResp?.approvedFinancialTerms);

        let formsData = {
          programVal: parsedResp?.paymentCalculatorDetails?.program,
          promotion: parsedResp?.paymentCalculatorDetails?.promotion,
          promotionLabel: parsedResp?.paymentCalculatorDetails?.promotionLabel,
          newOrUsed: parsedResp?.paymentCalculatorDetails?.newUsed,
          vehicleClass: parsedResp?.paymentCalculatorDetails?.vehicleClass,
          term: parsedResp?.paymentCalculatorDetails?.term,
          rate: parsedResp?.paymentCalculatorDetails?.rate,
          contractDate: parsedResp?.paymentCalculatorDetails?.contractDate,
          salePrice: parsedResp?.paymentCalculatorDetails?.salePrice,
          tradeDept: parsedResp?.paymentCalculatorDetails?.tradeInDebt,
          cashDown: parsedResp?.paymentCalculatorDetails?.cashDown,
          tradeAllowance: parsedResp?.paymentCalculatorDetails?.tradeInAllowance,
          rebate: parsedResp?.paymentCalculatorDetails?.rebate,
          carCare: parsedResp?.paymentCalculatorDetails?.carCare,
          extendedWarranty: parsedResp?.paymentCalculatorDetails?.extendedWarranty,
          serviceContract: parsedResp?.paymentCalculatorDetails?.serviceContract,
          paintProtection: parsedResp?.paymentCalculatorDetails?.paintProtection,
          other: parsedResp?.paymentCalculatorDetails?.other,
          year: parsedResp?.paymentCalculatorDetails?.tradeInVehicleYear,
          make: parsedResp?.paymentCalculatorDetails?.tradeInVehicleMake,
          model: parsedResp?.paymentCalculatorDetails?.tradeInVehicleModel,
          creditLife: parsedResp?.paymentCalculatorDetails?.creditLife,
          creditLifeType: parsedResp?.paymentCalculatorDetails?.creditLifeType,
          vehicleInsuranceType: parsedResp?.paymentCalculatorDetails?.vehicleInsuranceType,
          creditLifeAmount: parsedResp?.paymentCalculatorDetails?.creditLifeLifeInsuranceAmount,
          vehicleInsuranceAmount: parsedResp?.paymentCalculatorDetails?.vehicleInsuranceAmount,
          gapFee: parsedResp?.paymentCalculatorDetails?.gapFee,
          monthDeferred: parsedResp?.paymentCalculatorDetails?.monthsDeferred,
          balance: parsedResp?.paymentCalculatorDetails?.balance,
        };
        setPayCalcDetails({
          formData: formsData,
          calculatedData: {
            ...parsedResp?.estimatedMonthlyPayment,
          },
        });
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(async () => {
    console.log('intooo mount');
    if (applicationDetails?.initAppId && (tabVal === 0 || tabVal === 4)) {
      await getComments(applicationDetails?.initAppId);
    }
  }, [tabVal, location?.state]);

  const getComments = async (applnId) => {
    try {
      setLoader(true);

      const list = await ApiClient.get(`${UrlHelpers?.comments}?applicationId=${applnId}`);
      console.log('list', list);
      if (list?.length) {
        setCommentList(list);
      }
    } catch (e) {
      console.log('e', e);
      setCommentList([]);
    } finally {
      setLoader(false);
    }
  };

  const loanStatusMapping = {
    'Decision Pending': TimerSubIcon,
    Approved: ApprovedSubIcon,
    Declined: DeclinedSubIcon,
    Withdrawn: WithdrawSubIcon,
    'Contract Verification': TotalApplicationSubIcon,
    'Booked/Funded': ThumbsUpIcon,
    DRAFT: DraftIcon,
    'Booking Pending': PenBkIcon,
  };

  const handleBack = () => {
    if (path === 'home') {
      navigate('/home');
    } else {
      console.log('tab', tabValAppDashboard);
      navigate('/applications', {
        state: { tabValue: tabValAppDashboard },
      });
    }
  };
  const handleTabChange = (event, newValue) => {
    if (updateFlag) {
      setUpdateFlag(false);
    }
    setTabValue(newValue);
  };
  const editCallback = () => {
    setCreateAppFlag(true);
    navigate('/home/create-app', {
      state: {
        activeStep: 1,
        completedSteps: [1, 2, 3, 4, 5],
        userType: 'Applicant',
        reviewFlag: true,
        viewAppFlow: true,
        idVerificationPendingText: idVerifiedAlertMessage,
        viewAppLoanStatus: applicationDetails?.loanStatus,
      },
    });
  };
  return (
    <Box>
      <Loader open={loader} />
      <IdVerificationPopup
        open={!isIdVerified}
        setOpen={setIsIdVerified}
        handleEdit={editCallback}
        setIdVerifiedAlertMessage={setIdVerifiedAlertMessage}
        idVerifiedAlertMessage={idVerifiedAlertMessage}
      />
      <Dialog
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-desc"
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '100%',
            width: '80%',
            padding: '0px 20px',
          },
          '& .MuiDialogContent-root': {
            padding: '0 0 20px 0',
          },
        }}
      >
        <BootstrapDialogTitle
          id="modal-modal-title"
          variant="h6"
          component="h5"
          mb={3}
          sx={{
            width: '100%',
            borderBottom: '1px solid #e37617',
            color: '#000',
            fontWeight: 700,
            paddingBottom: '10px',
            padding: '16px 0 !important',
          }}
          onClose={handleModalClose}
        >
          <Typography variant="h3" component="span" id="dialog-title">
            Print Documents
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box display="flex" flexDirection="column" gap={2.5} mb={2.5}>
            <PrintDocumentsModal applicationDetails={applicationDetails} />
          </Box>
        </DialogContent>
      </Dialog>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={0.5}>
          <img src={LeftArrow} onClick={handleBack} tabIndex="0" style={{ cursor: 'pointer' }} />
          <Typography variant="h3">{`View application: App ID ${applicationDetails?.appId}`}</Typography>
        </Box>
        <Link
          component="button"
          variant="subtitle2"
          color="secondary"
          alignItems="center"
          display="flex"
          onClick={handleModalOpen}
        >
          <img src={printIcon} tabIndex="0" style={{ cursor: 'pointer' }} />
          <Typography sx={{ marginLeft: '5px' }} variant="subtitle2" color="secondary">
            Print Documents
          </Typography>
        </Link>
      </Box>
      <Box className={classes.viewApplication}>
        <Box
          display="flex"
          gap={1}
          sx={{ padding: '10px 20px 20px 20px', borderBottom: '1px solid #DADADA' }}
        >
          <Typography variant="subtitle1" fontWeight={700}>
            Status:
          </Typography>
          <img src={loanStatusMapping[applicationDetails?.displayLoanStatus]} />
          <Typography variant="subtitle1">{applicationDetails?.displayLoanStatus}</Typography>
        </Box>
        <Box>
          <ApplicationDetails appDetails={applicationDetails} />
        </Box>
      </Box>
      <Paper sx={{ padding: '20px' }}>
        <Box>
          <Tabs value={tabVal} onChange={handleTabChange} aria-label="applicationsDetails-tabs">
            <Tab label="Overview" {...a11yProps(0)} />
            <Tab label="Application details" data-testid="drafts" {...a11yProps(1)} />
            <Tab data-testid="applications" label="Decision details" {...a11yProps(2)} />
            <Tab
              label="Contract generation"
              data-testid="contractGen"
              disabled={!enableContractGen}
              {...a11yProps(3)}
            />
            <Tab label="Comments" data-testid="comments" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <TabPanel value={tabVal} index={0}>
          <OverviewTab appDetails={applicationDetails} commentList={commentList} />
        </TabPanel>
        <TabPanel value={tabVal} index={1}>
          <>
            {updateFlag && (
              <Alert
                severity="success"
                onClose={() => {
                  setUpdateFlag(false);
                }}
                sx={{ margin: '0 60px' }}
              >
                Application has been updated successfully
              </Alert>
            )}
            <ReviewAndSubmit viewAppFlow appID={applicationDetails?.initAppId} />
          </>
        </TabPanel>
        <TabPanel value={tabVal} index={2}>
          <DecisionDetailsTab appDetails={applicationDetails} payCalcDetail={payCalcDetail} />
        </TabPanel>
        <TabPanel value={tabVal} index={3}>
          <ContractGenerationTab appId={applicationDetails?.initAppId} getInfoReq={getInfoReq} />
        </TabPanel>
        <TabPanel value={tabVal} index={4}>
          <CommentsTab appId={applicationDetails?.initAppId} comments={commentList} />
        </TabPanel>
      </Paper>
    </Box>
  );
};
