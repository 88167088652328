/* eslint-disable react/prop-types */
import { Box, Dialog } from '@mui/material';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import classes from 'styles/globalStyle.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
function PdfViewer({ fileData, handleClose, open }) {
  const [numPages, setNumPages] = useState();
  const [typeErr, setTypeErr] = useState(false);
  function onDocumentLoadSuccess(pdf) {
    setNumPages(pdf?.numPages);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      classes={{ paper: classes.pdfviewerPaper }}
    >
      <Box>
        {!typeErr && (
          <Document
            file={fileData}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(e) => {
              if (e?.name === 'InvalidPDFException') {
                setTypeErr(true);
              }
            }}
          >
            {Array.from(new Array(numPages), (_el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            ))}
          </Document>
        )}
        {typeErr && <img src={fileData} alt="preview" width="100%" height="100%" />}
      </Box>
    </Dialog>
  );
}

export default PdfViewer;
