import { regexAddress, regexChar2, regexEmail2 } from 'utils/utility';
import * as yup from 'yup';

import { dob, dobnonMandatory, email, mobile2 } from './validationFunctions/validationFunctions';

export const InformationRequestSchema = yup.object().shape({
  cousers: yup.array().of(
    yup.object().shape({
      relationship: yup.string().when('userType', {
        is: (val) => val !== 'Applicant',
        then: yup.string().required('* Mandatory field'),
      }),
      firstName: yup
        .string()
        .required('* Mandatory field')
        .test('len', 'Maximum 15 characters can be entered', (val) => val?.length <= 15)
        .matches(regexChar2, 'Invalid format'),
      middleName: yup
        .string()
        .nullable()
        .test('len', 'Maximum 20 characters can be entered', (val) =>
          val ? val?.length <= 20 : true
        )
        .matches(regexChar2, 'Invalid format'),
      lastName: yup
        .string()
        .required('* Mandatory field')
        .matches(regexChar2, 'Last name should have alphabetic characters only')
        .when(['middleName', 'secondLastName'], {
          is: (middleName, secondLastName) => {
            return !middleName && !secondLastName;
          },
          then: yup
            .string()
            .test(
              'length check',
              ' Last name should be more than 1 character when middle name and second last name are not present',
              (val) => val?.length > 1
            ),
        })
        .test('len', 'Maximum 25 characters can be entered', (val) => val?.length <= 25),

      secondLastName: yup
        .string()
        .nullable()
        .test('len', 'Maximum 25 characters can be entered', (val) =>
          val ? val?.length <= 25 : true
        )
        .matches(/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡.'\s]*$/g, 'Invalid format')
        .when('userType', {
          is: (val) => val === 'Applicant',
          then: yup.string().required('* Mandatory field'),
        }),

      ...dob,
      ssn: yup
        .string()
        .required('* Mandatory field')
        .test('length', 'Social security number should have 9 digit', (val) =>
          val ? val?.length === 11 : true
        ),
      ...email,
      mobile: yup
        .string()
        .required('* Mandatory field')
        .matches(/^\d+$/g, 'Invalid format')
        .test('length', 'Mobile number should have 10 digits', (val) => {
          return val?.length === 10;
        }),

      maritalStatus: yup.string().required('* Mandatory field'),
      ...mobile2,
      resAddress1: yup
        .string()
        .max(40, 'Address line 1 should be 40 characters or less')
        .required('* Mandatory field')
        .matches(regexAddress, 'Invalid format'),
      resAddress2: yup
        .string()
        .nullable()
        .max(20, 'Address line 2 should be 20 characters or less')
        .matches(regexAddress, 'Invalid format'),
      resState: yup.string().required('* Mandatory field'),
      resCity: yup
        .string()
        .required('* Mandatory field')
        .test('len', 'Maximum 15 characters can be entered', (val) => val?.length <= 15)
        .matches(regexChar2, 'Invalid format'),
      resZipcode: yup
        .string()
        .required('* Mandatory field')
        .matches(/^\d+$/, 'Invalid format')
        .test('len', 'Please enter a valid zipcode', (val) => parseInt(val) !== 0)
        .test('len', '* Zipcode should have 5 digits', (val) => val?.length === 5),
      resUtilityBill: yup.string().when('utilitybillfile', {
        is: (val) => !!val,
        then: yup.string().required('Please select a utility bill'),
      }),
      utilitybillfile: yup.mixed().test('len', 'Please upload a file to proceed', function (val) {
        const { resUtilityBill } = this?.parent;
        return !(resUtilityBill && !val);
      }),

      perAddress1: yup.string().when('isAddrSame', {
        is: false,
        then: yup
          .string()
          .required('* Mandatory field')
          .max(40, 'Address line 1 should be 40 characters or less')
          .matches(regexAddress, 'Invalid format'),
      }),
      perAddress2: yup.string().when('isAddrSame', {
        is: false,
        then: yup
          .string()
          .nullable()
          .max(20, 'Address line 2 should be 20 characters or less')
          .matches(regexAddress, 'Invalid format'),
      }),
      perState: yup.string().when('isAddrSame', {
        is: false,
        then: yup.string().required('* Mandatory field'),
      }),

      perCity: yup.string().when('isAddrSame', {
        is: false,
        then: yup
          .string()
          .required('* Mandatory field')
          .test('len', 'Maximum 15 characters can be entered', (val) => val?.length <= 15)
          .matches(regexChar2, 'Invalid format'),
      }),
      perZipcode: yup.string().when('isAddrSame', {
        is: false,
        then: yup
          .string()
          .required('* Mandatory field')
          .matches(/^\d+$/, 'Invalid format')
          .test('len', 'Please enter a valid zipcode', (val) => parseInt(val) !== 0)
          .test('len', 'Zipcode should have 5 digits', (val) => val?.length === 5),
      }),

      residentStatus: yup.string().required('* Mandatory field'),
      monthlyPayment: yup.string().when('residentStatus', {
        is: (value) => value === 'Rent' || value === 'Homeowner',

        then: yup
          .string()
          .required('* Mandatory field')
          .test('check for zero', 'Please enter a valid value', (val) => {
            const value = val?.replace(/[^0-9.]/g, '');

            return parseInt(value) !== 0;
          }),
      }),
      residentYears: yup
        .string()
        .required('* Mandatory field')
        .matches(/^\d*$/, 'Invalid format')

        .test('year restriction', '* Invalid year', (val) => {
          return parseInt(val) <= 99;
        }),
      residentMonths: yup
        .string()
        .required('* Mandatory field')
        .matches(/^\d*$/, 'Invalid Format')

        .test('month restriction', 'Invalid month', (val) => {
          return parseInt(val) <= 11;
        })

        .when('residentYears', {
          is: (val) => parseInt(val) === 0,
          then: yup
            .string()
            .test('check for zero', 'Please enter a valid value', (val) => parseInt(val) !== 0),
        }),
    })
  ),
});

export const FormatValidationSchema = yup.object().shape({
  cousers: yup.array().of(
    yup.object().shape({
      firstName: yup
        .string()
        .test('len', 'Maximum 15 characters can be entered', (val) => (val?.length || 0) <= 15)
        .matches(regexChar2, 'Invalid format'),
      middleName: yup
        .string()
        .nullable()
        .test('len', 'Maximum 20 characters can be entered', (val) =>
          val ? val?.length <= 20 : true
        )
        .matches(regexChar2, 'Invalid format'),
      lastName: yup
        .string()
        .test('len', 'Maximum 25 characters can be entered', (val) => (val?.length || 0) <= 25)
        .matches(regexChar2, 'Invalid format')
        .when(['middleName', 'secondLastName'], {
          is: (middleName, secondLastName) => {
            return !middleName && !secondLastName;
          },
          then: yup
            .string()
            .test(
              'length check',
              ' Last name should be more than 1 character when middle name and second last name are not present',
              (val) => val?.length > 1
            ),
        }),

      secondLastName: yup
        .string()
        .nullable()
        .test('len', 'Maximum 25 characters can be entered', (val) =>
          val ? val?.length <= 25 : true
        )
        .matches(/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡.'\s]*$/g, 'Invalid format'),
      ...dobnonMandatory,

      ssn: yup
        .string()
        .test('length', 'Social security number should have 9 digit', (val) =>
          val ? val?.length === 11 : true
        ),

      email: yup
        .string()
        .test('email format', 'Invalid email format', (val) => {
          if (!val) {
            return true;
          }
          return yup.string().matches(regexEmail2).isValidSync(val);
        })

        .max(50, 'Invalid email length'),

      mobile: yup
        .string()
        .test('length', 'Mobile number should have 10 digits', (val) => {
          return val ? val?.length === 10 : true;
        })
        .test('mobile invalid', 'Invalid Format', (val) => {
          if (!val) {
            return true;
          }
          return yup.string().matches(/^\d+$/g).isValidSync(val);
        }),

      maritalStatus: yup.string(),
      mobile2: yup.string().nullable(),
      resAddress1: yup
        .string()
        .max(40, 'Address line 1 should be 40 characters or less')

        .matches(regexAddress, 'Invalid format'),
      resAddress2: yup
        .string()
        .nullable()
        .max(20, 'Address line 2 should be 20 characters or less')
        .matches(regexAddress, 'Invalid format'),

      resCountry: yup.string(),
      resState: yup.string(),
      resCity: yup
        .string()
        .nullable()
        .transform((v) => (v === '' ? null : v))
        .matches(regexChar2, 'Invalid format')
        .test('len', 'Maximum 15 characters can be entered', (val) =>
          val ? val?.length <= 15 : true
        ),
      resZipcode: yup
        .string()
        .test('zipcode invalid format', 'Invalid format', (val) => {
          if (!val) {
            return true;
          }
          return yup.string().matches(/^\d+$/).isValidSync(val);
        })
        .test('len', 'Please enter a valid zipcode', (val) => (val ? parseInt(val) !== 0 : true))
        .test('len', 'Zipcode should have 5 digits', (val) => (val ? val?.length === 5 : true)),
      perAddress1: yup.string().when('isAddrSame', {
        is: false,
        then: yup
          .string()

          .max(40, 'Address line 1 should be 40 characters or less')
          .matches(regexAddress, 'Invalid format'),
      }),
      perAddress2: yup.string().when('isAddrSame', {
        is: false,
        then: yup
          .string()
          .nullable()
          .max(20, 'Address line 2 should be 20 characters or less')
          .matches(regexAddress, 'Invalid format'),
      }),

      perCountry: yup.string().when('isAddrSame', {
        is: false,
        then: yup.string(),
      }),
      perState: yup.string().when('isAddrSame', {
        is: false,
        then: yup.string(),
      }),
      perCity: yup.string().when('isAddrSame', {
        is: false,
        then: yup
          .string()
          .nullable()
          .transform((v) => (v === '' ? null : v))
          .matches(regexChar2, 'Invalid format')
          .test('len', 'Maximum 15 characters can be entered', (val) => val?.length <= 15),
      }),
      perZipcode: yup.string().when('isAddrSame', {
        is: false,
        then: yup
          .string()
          .test('per zipcode invalid format', 'Invalid format', (val) => {
            if (!val) {
              return true;
            }
            return yup.string().matches(/^\d+$/).isValidSync(val);
          })
          .test('len', 'Please enter a valid zipcode', (val) => (val ? parseInt(val) !== 0 : true))
          .test('len', 'Zipcode should have 5 digits', (val) => (val ? val?.length === 5 : true)),
      }),

      residentStatus: yup.string(),
      monthlyPayment: yup.string().when('residentStatus', {
        is: (value) => value === 'Rent' || value === 'Homeowner',

        then: yup
          .string()

          .test('check for zero', 'Please enter a valid value', (val) => {
            const value = val?.replace(/[^0-9.]/g, '');

            return val ? parseInt(value) !== 0 : true;
          }),
      }),
      residentYears: yup
        .string()

        .matches(/^\d*$/, 'Invalid Format')
        .test('year restriction', 'Invalid year', (val) => {
          return val ? parseInt(val) <= 99 : true;
        }),
      residentMonths: yup
        .string()
        .matches(/^\d*$/, 'Invalid Format')
        .test('month restriction', 'Invalid month', (val) => {
          return val ? parseInt(val) <= 11 : true;
        })

        .when('residentYears', {
          is: (val) => parseInt(val) === 0,
          then: yup
            .string()
            .test('check for zero', 'Please enter a valid value', (val) =>
              val ? parseInt(val) !== 0 : true
            ),
        }),

      resUtilityBill: yup.string(),
      relationship: yup.string(),
    })
  ),
});
