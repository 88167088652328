import { Box, Button, Link, Typography, useMediaQuery } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import Approved from 'assets/images/approved.png';
import Rejected from 'assets/images/declined.png';
import Review from 'assets/images/review.png';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import Loader from 'components/Loader/Loader';

import ApplicationApprovedDetails from './ApplicationApprovedDetails';
import { viewApplication } from './InformationRequest/moduleUtilities/moduleUtilities';

const ApplicationApproved = () => {
  const { loanStatus, caseReferenceId, viewAppFlow } = useContext(MainApplicationContext);
  const { createAppApplicationId, userID, fullName } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [appCondData, setAppCondData] = useState({});
  const ApiClient = new ApiClass();
  const isMobile = useMediaQuery('(max-width:900px)');

  const approvalTerms = JSON.parse(appCondData?.approvalTerms || '{}');
  const decision = JSON.parse(appCondData?.decisions || '{}')?.[0];

  useEffect(async () => {
    if (loanStatus === 'APPCOND') {
      try {
        setLoader(true);
        const getResp = await ApiClient.get(
          `${UrlHelpers.getSpecificDetails}?applicationId=${createAppApplicationId}&eventName=viewApplication`,
          {
            headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
          }
        );
        setAppCondData(getResp);
      } catch (e) {
        console.log('error fetching', e);
      } finally {
        setLoader(false);
      }
    }
  }, []);

  const handleImg = () => {
    switch (loanStatus) {
      case 'APPROVE':
      case 'APPSCOR':
      case 'APPCOND':
        return <img src={Approved} height="324px" width="362px" />;
      case 'DECLINE':
      case 'DECSCOR':
        return <img src={Rejected} height="324px" width="362px" />;
      case 'PENDINGANALYST':
        return <img src={Review} height="324px" width="362px" />;
      default:
        break;
    }
  };

  const onViewApplication = async () => {
    const updateFlag = viewAppFlow;
    await viewApplication({
      setLoader,
      navigate,
      createAppApplicationId,
      updateFlag,
      userID,
      fullName,
    });
  };

  const handleStatusText = () => {
    switch (loanStatus) {
      case 'APPROVE':
      case 'APPSCOR':
        return 'Loan Approved!';
      case 'APPCOND':
        return 'Loan approved with conditions!';
      case 'DECLINE':
      case 'DECSCOR':
        return 'Loan Declined!';
      case 'PENDINGANALYST':
        return 'Application under review!';
      default:
        break;
    }
  };

  const handleDescriptionText = () => {
    switch (loanStatus) {
      case 'APPROVE':
      case 'APPSCOR':
        return 'Congratulations! Based on the details provided, your loan amount is approved.';
      case 'APPCOND':
        return 'Congratulations! Based on the details provided, your application is approved with certain conditions. Review them below';
      case 'DECLINE':
      case 'DECSCOR':
        return 'We regret to inform you that the auto loan application has been declined.';
      case 'PENDINGANALYST':
        return 'Thank you for submitting the application. We are currently reviewing your application. Please review the application status shortly.';
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Loader open={loader} />
      {handleImg()}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '30px',
          gap: '20px',
          textAlign: 'center',
        }}
      >
        <Typography fontSize="48px !important" fontWeight={700} color="#E37617">
          {handleStatusText()}
        </Typography>
        {loanStatus === 'PENDINGANALYST' ? (
          <Typography variant="subtitle1" sx={{ maxWidth: '752px' }}>
            {handleDescriptionText()}
            Your application ID{' '}
            <Link
              component="button"
              color="#1976D2"
              sx={{ textDecoration: 'none', fontSize: '16px' }}
            >
              {caseReferenceId || createAppApplicationId}
            </Link>
          </Typography>
        ) : (
          <ApplicationApprovedDetails
            handleDescriptionText={handleDescriptionText}
            loanStatus={loanStatus}
            approvalTerms={approvalTerms}
            isMobile={isMobile}
            appCondData={appCondData}
            caseReferenceId={caseReferenceId}
            decision={decision}
            createAppApplicationId={createAppApplicationId}
          />
        )}

        <Button variant="contained" color="secondary" onClick={onViewApplication}>
          View application
        </Button>
      </Box>
    </Box>
  );
};

export default ApplicationApproved;
