import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import LinkExpiredImage from 'assets/images/LinkExpiredImage.png';
import LinkExpiredMobile from 'assets/images/LinkExpiredMobile.png';
import PropTypes from 'prop-types';
import classes from 'styles/InviInformationRequest.module.scss';

const InvitationExpired = ({ invitationExpiredHeading, invitationExpiredContent }) => {
  const isMobile = useMediaQuery('(max-width:899px)');
  return (
    <>
      <Grid container>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ height: '90vh' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}
            className={classes.expiryImage}
          >
            <img
              src={isMobile ? LinkExpiredMobile : LinkExpiredImage}
              className={classes.expiryImage}
            />
            <Grid container className={classes.imageContent}>
              <Grid item sx={{ paddingRight: '10px' }}>
                <Typography
                  variant="h1"
                  color="primary"
                  component="p"
                  className={classes.imageHeading}
                >
                  {invitationExpiredHeading}
                </Typography>
                <Typography
                  variant="h5"
                  color="#000"
                  component="p"
                  sx={{ fontSize: '1.4vw !important' }}
                  className={classes.imageText}
                >
                  {invitationExpiredContent}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default InvitationExpired;

InvitationExpired.propTypes = {
  invitationExpiredHeading: PropTypes.string,
  invitationExpiredContent: PropTypes.string,
};
InvitationExpired.defaultProps = {
  invitationExpiredHeading: '',
  invitationExpiredContent: '',
};
