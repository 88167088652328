import { Box } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import { applicationFiltersApis } from 'pages/CreateApplication/InformationRequest/moduleUtilities/dropdownApis';
import React, { useContext, useEffect, useState } from 'react';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import Loader from 'components/Loader/Loader';

import ApplicationsTable from './ApplicationsTable';
import { SearchFilters } from './SearchFilters';

export const Applications = () => {
  const {
    userID,
    rolebasedDealers,
    frequencyDetailList,
    setFrequencyDetailList,
    loanStatusList,
    setLoanStatusList,
    setRoleBasedDealers,
    viewApprovalQueue,
    role,
  } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);

  const [page, setPage] = useState(1);
  const [emptyDataMessage, setEmptyDataMessage] = useState('No application/s available');
  const [searchOptions, setSearchOptions] = useState({
    frequency: '7_DAY',
    loanStatus: '',
    spName: '',
    createdBy: '',
    vehicleName: '',
  });
  const [searchAnchor, setSearchAnchor] = useState(false);
  const [applicationData, setApplicationData] = useState([]);
  const rowsPerPage = 10;
  const ApiClient = new ApiClass();

  useEffect(async () => {
    if (viewApprovalQueue) {
      await applicationFiltersApis({
        frequencyDetailList,
        setFrequencyDetailList,
        loanStatusList,
        setLoanStatusList,
        flow: 'Applications',
        setLoader,
        rolebasedDealers,
        setRoleBasedDealers,
        userID,
        role,
      });
    }
  }, []);

  useEffect(async () => {
    if (rolebasedDealers?.length) {
      await fetchApplications();
    }
  }, [rolebasedDealers]);
  const fetchApplications = async () => {
    try {
      setLoader(true);

      const CommaSeperatedDealerListt = rolebasedDealers?.map((x) => x.dealerId)?.join(',') || '';
      const resp = await ApiClient.get(
        `${UrlHelpers.fetchApplications}?frequency=7_DAY&dealerId=${CommaSeperatedDealerListt}&appStatus=SUBMITTED`,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (resp) {
        setApplicationData(resp?.applicationList);
      }
      return resp;
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  };

  return (
    <Box>
      <Loader open={loader} />
      <SearchFilters
        searchOptions={searchOptions}
        setSearchOptions={setSearchOptions}
        frequencyDetailList={frequencyDetailList}
        loanStatusList={loanStatusList}
        applicationData={applicationData}
        setApplicationData={setApplicationData}
        setLoader={setLoader}
        myAppFlag={false}
        userID={userID}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        searchAnchor={searchAnchor}
        setSearchAnchor={setSearchAnchor}
        dealerList={rolebasedDealers}
        viewApprovalQueue={viewApprovalQueue}
        flow="Applications"
        setEmptyDataMessage={setEmptyDataMessage}
        fetchApplications={fetchApplications}
      />
      <ApplicationsTable
        applicationsData={applicationData}
        page={page}
        setPage={setPage}
        hasPagination
        emptyDataMessage={emptyDataMessage}
        tabName="All loan applications"
      />
    </Box>
  );
};
