import { Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const VouchedFlow = () => {
  const location = useLocation();
  const [vouchedloaded, setVouchedLoaded] = useState(false);
  const {
    activeStep,
    userType,
    formFields,
    tabvalue,
    userDetails,
    flow,
    savedApplicantIds,
    initApplicantIds,
    viewAppFlow,
    unfilled,
    reviewFlag,
  } = location?.state;

  const navigate = useNavigate();
  const { Vouched } = window;
  let vouched;
  useEffect(() => {
    if (Vouched) {
      setVouchedLoaded(true);
    }
  }, [Vouched]);

  const vouchedTiggerEnd = () => {
    vouched?.unmount?.('#vouched-element');
  };

  const vouchedData = async (scannedData) => {
    const { result, id, status } = scannedData;
    if (flow === 'invitationFlow') {
      navigate('/invi-flow', {
        state: {
          scannedData: { ...result, jobId: id, status: status },
          activeStep,
          vouchedFlag: true,
          userDetails,
          formFields,
        },
      });
    } else {
      navigate('/home/create-app', {
        state: {
          vouchedFlag: true,
          scannedData: { ...result, jobId: id, status: status },
          savedApplicantIds,
          initApplicantIds,
          activeStep,
          userType,
          formFields,
          tabvalue,
          viewAppFlow,
          unfilled,
          reviewFlag,
        },
      });
    }
  };

  useEffect(() => {
    if (vouchedloaded) {
      (() => {
        vouched = Vouched({
          appId: process.env.REACT_APP_VOUCHED_APP_PUBLIC_KEY,
          type: 'idv',
          id: process.env.REACT_APP_VOUCHED_ID_UPLOAD_MODE,
          face: process.env.REACT_APP_VOUCHED_SELFIE_UPLOAD_MODE,
          crossDevice: true,
          crossDeviceQRCode: true,
          includeBarcode: process.env.REACT_APP_VOUCHED_BARCODE_STATE === 'true',
          liveness:
            process.env.REACT_APP_NODE_ENV === 'sandbox' || process.env.REACT_APP_NODE_ENV === 'qa'
              ? 'distance'
              : 'orientation',
          survey: false,
          locale: 'en',
          disableCssBaseline: true,
          stepTitles: {
            FrontId: 'ID',
            Face: 'Selfie',
            Done: 'Finished',
          },
          content: {
            progressIndicatorVouching: 'Verifying',
            lowerSuccess: '',
            lowerIdInstructions: 'Please take a photo of your ID now',
            lowerFaceInstructions:
              "Please use a current, clear picture of yourself that doesn't include:",

            lowestFaceInstructions: `<span class="vouched_lowestFaceInstructions"><div style="max-width: 400px; margin-bottom: 10px" class="vouched_faceText"><ul style="text-align: left; margin: 0 30px; padding: 0"><li class="vouched_faceText">Sunglasses, hats or other facial obstructions</li><li class="vouched_faceText">Other people (e.g., a family photo)</li><li class="vouched_faceText">Pictures of a picture</li></ul></div></span>`,

            upperFailure: 'Try Again',
            verifyFail: 'Sorry for the inconvenience',
            cameraButton: 'Take a Photo',
            qrDesktopInstructions: 'You can {qrDesktopLink} if you have a good computer camera.',

            qrDesktopLink: 'finish the verification on your desktop',

            lowerFailure: '',
            cameraButtonLabelUploadFace: 'Upload from Photo Library',

            surveyButtonLabel: 'Continue',
            cameraFaceButton: 'Take a Photo',
            crossDeviceInstructions:
              "Please provide a picture of your driver's license or passport. Follow the instructions below to continue the verification process on your phone. <br/> <br/>1. Open the Camera App or QR Reader on your phone<br/> 2. Hold the device so the QR Code appears in the viewfinder <br/> 3. Click on the notifications to open the verification link<br/><br/>",

            review: 'Thank you for providing your information.',
            upperIdInstructions: `<span class='vouched_idText' style="margin:0">Please provide a picture of a current (non-expired) government issued ID </span><span class='vouched_idText' style="margin:0">(e.g., driver's license, passport).</span>`,

            retryMessageInvalidIdPhoto: 'We could not recognize the ID',
          },
          onInit: () => {
            const handoffElement = document.getElementById('vouched_handoffLink');
            handoffElement.addEventListener('click', () => {
              document.getElementById('screen1-back-button').style.display = 'none';
            });
            if (document.getElementById('screen1-back-button').style.display === 'block') {
              document.getElementById('screen1-back-button').style.display = 'none';
            } else {
              document.getElementById('screen1-back-button').style.display = 'block';
            }
            document.body.className = 'vouched-body-style';
          },
          onCamera: () => {
            document.body.className = 'vouched-body-style';
          },
          onDone: (job) => {
            // document.getElementById('screen1-back-button').style.display = 'none';
            vouchedTiggerEnd();
            vouchedData(job);
          },
          onSurveyDone: () => {
            document.body.className = 'body-style';
            //leadGenerationContext.vouched?.unmount('#vouched-element');
            vouchedTiggerEnd();
          },
          onSubmit: () => {
            const element = document.getElementById('companyLogo');
            if (element) {
              element.src = '/oriental-bank.png';
              element.style.maxWidth = '150px';
              element.style.display = 'flex';
              element.style.alignSelf = 'center';
              element.style.marginBottom = '15px';
              element.style.marginTop = '18px';
            }
          },
          theme: {
            name: 'verbose',
            baseColor: '#661C69',
            logo: {
              src: '/oriental-bank.png',
              style: {
                'max-width': 150,
                display: 'flex',
                'align-self': 'center',
                'margin-bottom': '15px',
                'margin-top': '18px',
              },
            },
            handoffLinkColor: '#661C69',
            iconBackground: '#f5821f',
          },
        });

        vouched.mount('#vouched-element');
      })();
    }
  }, [vouchedloaded]);

  const onBackClick = (e) => {
    e.preventDefault();
    vouchedTiggerEnd();
    if (flow === 'invitationFlow') {
      navigate('/invi-flow', {
        state: {
          activeStep,
          vouchedFlag: true,
          userDetails,
          formFields,
          // watchCoApplicant,
          // watchCoSigner,
          // userType,
        },
      });
    } else {
      navigate('/home/create-app', {
        state: {
          vouchedFlag: true,
          activeStep,
          userType,
          formFields,
          tabvalue,
          savedApplicantIds,
          initApplicantIds,
          unfilled,
          viewAppFlow,
          reviewFlag,
        },
      });
    }
  };

  return (
    <>
      <div id="vouched-element" style={{ height: '100%' }} />
      <Link component="button" variant="subtitle2" color="secondary" onClick={onBackClick}>
        Back
      </Link>
    </>
  );
};

export default VouchedFlow;
