import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import { handleTokenExpiry, ocrFileUpload } from 'Services/commonComponentsApi';
import CameraIcon from 'assets/svg/cameraIcon.svg';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import customClasses from 'styles/InformationRequest.module.scss';
import { cityMapping, defaultFn } from 'utils/utility';

import CameraUpload from 'components/CameraUpload/CameraUpload';
import { CustomAutoComplete } from 'components/CustomAutoComplete/CustomAutoComplete';
import CustomCollapse from 'components/CustomCollapse';
import CustomFileUploader from 'components/CustomFileUploader/CustomFileUploader';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

const ResidentialAddress = ({
  control,
  errors,
  addressLookupData,
  addressLookupDataResponse,
  setValue,
  getValues,
  cityList,
  setError,
  setLoader,
  showCamera,
  setShowCamera,
  clearErrors,
  utilityBillList,
  isResidentAddressOpen,
  prefixName,
  couserIndex,
  setApiErr,
  stateList,
  setResCityList,
  setOldState,
  setOldPerState,
}) => {
  const [
    watchResAddress1,
    watchResAddress2,
    watchResCity,
    watchResZipcode,
    watchResUtilityBill,
    watchUtilityBillFile,
    showUtility,
    ocrDocumentId,
    watchDocumentList,
    isAddrSame,
    isAddressCopiedFromApplicant,
    watchResState,
    watchPrimaryUtilityBill,
  ] = useWatch({
    control,
    name: [
      `${prefixName}.resAddress1`,
      `${prefixName}.resAddress2`,
      `${prefixName}.resCity`,
      `${prefixName}.resZipcode`,
      `${prefixName}.resUtilityBill`,
      `${prefixName}.utilitybillfile`,
      `${prefixName}.showUtility`,
      `${prefixName}.ocrDocumentId`,
      `${prefixName}.ocrDocumentIdList`,
      `${prefixName}.isAddrSame`,
      `${prefixName}.isAddressCopiedFromApplicant`,
      `${prefixName}.resState`,
      `cousers[0].resUtilityBill`,
    ],
  });

  const { commonToken, cityZipList, setCommonToken, createAppApplicationId, stateCityList } =
    useContext(AuthContext);

  const { viewAppFlow } = useContext(MainApplicationContext);

  const [ocrFlag, setOcrflag] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const fileTypes = ['JPG', 'PNG', 'PDF'];

  const handleChange = (filee) => {
    console.log({ filee });
    if (filee?.size > 10485760) {
      setError(`${prefixName}.utilitybillfile`, {
        type: 'custom',
        message: 'The file exceeds the maximum upload size (10 MB). Click to upload again',
      });
    } else if (!watchResUtilityBill) {
      setError(`${prefixName}.resUtilityBill`, {
        type: 'custom',
        message: 'Please select a utility bill',
      });
    } else {
      setValue(`${prefixName}.utilitybillfile`, filee);

      setOcrflag(true);
      clearErrors([`${prefixName}.utilitybillfile`, `${prefixName}.resUtilityBill`]);
    }
  };
  useEffect(async () => {
    let cityListt = [];
    if (stateCityList?.length > 0) {
      cityListt = await cityMapping(stateCityList, watchResState);
    }

    setResCityList(cityListt || []);
  }, [watchResState, stateCityList]);

  const handleStateChange = (e) => {
    console.log('e', e);
    if (
      viewAppFlow &&
      prefixName === 'cousers[0]' &&
      watchPrimaryUtilityBill === 'Others' &&
      e.target.value === 'PR'
    ) {
      setValue(`cousers[0].utilitybillfile`, '');
      setValue(`cousers[0].resUtilityBill`, '');
      clearErrors([`${prefixName}.utilitybillfile`, `${prefixName}.resUtilityBill`]);
    }
    setValue(`${prefixName}.resCity`, '');
    setValue(`${prefixName}.resZipcode`, '');
  };
  useEffect(() => {
    if (isAddrSame) {
      setValue(`${prefixName}.perAddress1`, getValues(`${prefixName}.resAddress1`));
      setValue(`${prefixName}.perAddress2`, getValues(`${prefixName}.resAddress2`));
      setValue(`${prefixName}.perCountry`, getValues(`${prefixName}.resCountry`));
      setValue(`${prefixName}.perState`, getValues(`${prefixName}.resState`));
      setOldPerState(getValues(`${prefixName}.resState`));
      setValue(`${prefixName}.perCity`, getValues(`${prefixName}.resCity`));
      setValue(`${prefixName}.perZipcode`, getValues(`${prefixName}.resZipcode`));
      clearErrors([
        `${prefixName}.perAddress1`,
        `${prefixName}.perAddress2`,
        `${prefixName}.perCountry`,
        `${prefixName}.perState`,
        `${prefixName}.perCity`,
        `${prefixName}.perZipcode`,
      ]);
    }
  }, [
    watchResState,
    watchResAddress1,
    watchResAddress2,
    watchResCity,
    watchResZipcode,
    isAddrSame,
  ]);

  useEffect(() => {
    setIsMounted(true);
    if (isAddressCopiedFromApplicant) {
      setValue(`${prefixName}.resAddress1`, getValues(`cousers[0].resAddress1`));
      setValue(`${prefixName}.resAddress2`, getValues(`cousers[0].resAddress2`));
      setValue(`${prefixName}.resZipcode`, getValues(`cousers[0].resZipcode`));
      setValue(`${prefixName}.resCity`, getValues(`cousers[0].resCity`));
      setValue(`${prefixName}.resState`, getValues(`cousers[0].resState`));
      clearErrors([
        `${prefixName}.resAddress1`,
        `${prefixName}.resCity`,
        `${prefixName}.resZipcode`,
        `${prefixName}.resState`,
      ]);
    }
  }, []);

  useEffect(() => {
    if (ocrFlag) {
      clearErrors(`${prefixName}.resUtilityBill`);
      clearErrors(`${prefixName}.utilitybillfile`);
      setValue(`${prefixName}.utilitybillfile`, '');
    }
  }, [watchResUtilityBill]);

  useEffect(() => {
    if (watchResUtilityBill && watchUtilityBillFile && ocrFlag) {
      clearErrors(`${prefixName}.resUtilityBill`);
      clearErrors(`${prefixName}.utilitybillfile`);
      ocrFileUpload({
        file: watchUtilityBillFile,
        commonToken,
        typee: watchResUtilityBill,
        successCallback: ocrSuccess,
        failurecallback: ocrFailure,
        setLoader,
        applicationId: createAppApplicationId,
        setApiErr,
      });
    }
  }, [watchUtilityBillFile]);

  useEffect(() => {
    setValue(`${prefixName}.resCountry`, 'USA');
    setOcrflag(true);
  }, []);

  useEffect(() => {
    const addressLine1 = getValues(`cousers[0].resAddress1`);
    const addressLine2 = getValues(`cousers[0].resAddress2`);
    const city = getValues(`cousers[0].resCity`);
    const state = getValues(`cousers[0].resState`);
    const zipcode = getValues(`cousers[0].resZipcode`);
    if (
      (watchResAddress1 !== addressLine1 ||
        watchResAddress2 !== addressLine2 ||
        watchResCity !== city ||
        watchResZipcode !== zipcode ||
        watchResState !== state) &&
      prefixName !== 'cousers[0]' &&
      isMounted
    ) {
      setValue(`${prefixName}.isAddressCopiedFromApplicant`, false);
    }
  }, [watchResAddress1, watchResAddress2, watchResCity, watchResZipcode, watchResState, isMounted]);

  const handleStateAddress = async (AddressLine1, State) => {
    setValue(`${prefixName}.resAddress1`, AddressLine1);
    setValue(`${prefixName}.resState`, State);
    setOldState(State);
  };
  const handleAutoCompleteData = async (selectedObj) => {
    if (selectedObj && selectedObj?.Address) {
      let { AddressLine1, PostalCode, City, State } = selectedObj?.Address;
      console.log({ watchResState, cityZipList, cityList });
      await handleStateAddress(AddressLine1, State);

      const cityListt = await cityMapping(stateCityList, State);
      console.log({ checking: cityListt, stateCityList, cityZipList });
      PostalCode = PostalCode?.split('-')?.[0];
      setValue(`${prefixName}.resZipcode`, PostalCode);
      if (State !== 'PR') {
        setValue(`${prefixName}.resCity`, City);
      } else {
        City = cityZipList.find((x) => x?.zipcode === PostalCode)?.city;
        City = cityListt?.find((x) => x?.value === City)?.value || '';

        if (!City) {
          setError(`${prefixName}.resCity`, {
            type: 'custom',
            message: 'Please select a city from the list and enter the zip code',
          });
        } else {
          setValue(`${prefixName}.resCity`, City);

          clearErrors([
            `${prefixName}.resAddress1`,
            `${prefixName}.resZipcode`,
            `${prefixName}.resCity`,
            `${prefixName}.resState`,
          ]);
        }
      }

      clearErrors(`${prefixName}.resAddress1`);
    } else {
      setValue(`${prefixName}.resAddress1`, '');
      setValue(`${prefixName}.resAddress2`, '');
      setValue(`${prefixName}.resZipcode`, '');
      setValue(`${prefixName}.resCity`, '');
      setValue(`${prefixName}.resState`, '');
    }
  };

  const ocrSuccess = (response) => {
    const { AddressLine1, AddressLine2, Country, City, ZipCode, documentId } = response;
    const documentList = watchDocumentList || [];
    if (documentId) {
      setValue(`${prefixName}.ocrDocumentIdList`, [...documentList, documentId]);
      setValue(`${prefixName}.ocrDocumentId`, documentId);

      if (AddressLine1) {
        setValue(`${prefixName}.resAddress2`, AddressLine2);
        setValue(`${prefixName}.resState`, Country);
        setValue(`${prefixName}.resZipcode`, ZipCode);
        setValue(`${prefixName}.resAddress1`, AddressLine1);
        setValue(`${prefixName}.resCity`, City);
        clearErrors([
          `${prefixName}.resAddress1`,
          `${prefixName}.resCity`,
          `${prefixName}.resZipcode`,
          `${prefixName}.resState`,
        ]);
      }
    } else {
      setValue(`${prefixName}.ocrDocumentId`, '');
    }
  };

  const ocrFailure = (e) => {
    setValue(`${prefixName}.ocrDocumentId`, '');
    setValue(`${prefixName}.utilitybillfile`, '');
    handleTokenExpiry(e, setCommonToken);
    if (e?.response?.data?.message) {
      setError(`${prefixName}.utilitybillfile`, {
        type: 'custom',
        message: e?.response?.data?.message,
      });
    } else {
      setApiErr(
        'We are unable to proceed due to a technical issue, please save the application as draft and try again later.'
      );
    }
  };

  const handleCheckbox = (e) => {
    setValue(`${prefixName}.isAddressCopiedFromApplicant`, e.target.checked);
    if (e.target.checked) {
      setValue(`${prefixName}.resAddress1`, getValues(`cousers[0].resAddress1`));
      setValue(`${prefixName}.resAddress2`, getValues(`cousers[0].resAddress2`));
      setValue(`${prefixName}.resZipcode`, getValues(`cousers[0].resZipcode`));
      setValue(`${prefixName}.resCity`, getValues(`cousers[0].resCity`));
      setValue(`${prefixName}.resState`, getValues(`cousers[0].resState`));
      clearErrors([
        `${prefixName}.resAddress1`,
        `${prefixName}.resCity`,
        `${prefixName}.resZipcode`,
        `${prefixName}.resState`,
      ]);
    } else {
      setValue(`${prefixName}.resAddress1`, '');
      setValue(`${prefixName}.resAddress2`, '');
      setValue(`${prefixName}.resZipcode`, '');
      setValue(`${prefixName}.resCity`, '');
      setValue(`${prefixName}.resState`, '');
    }
  };

  return (
    <div>
      <CustomCollapse
        heading="Residential address"
        mailButton
        checked={isAddrSame}
        open={isResidentAddressOpen}
        prefixName={prefixName}
        setValue={setValue}
        errors={errors}
      >
        {showCamera && (
          <CameraUpload
            showCamera={showCamera}
            setShowCamera={setShowCamera}
            clearErrors={clearErrors}
            prefixName={prefixName}
            setValue={setValue}
          />
        )}
        {couserIndex !== 0 && (
          <Box display="flex" sx={{ margin: '10px 0' }}>
            <Checkbox
              color="secondary"
              checked={isAddressCopiedFromApplicant}
              onChange={(e) => handleCheckbox(e)}
              inputProps={{
                'aria-labelledby': 'same-as-applicant-id',
              }}
            />
            <Typography variant="subtitle2" component="p" m={0.5} id="same-as-applicant-id">
              Same as applicant&apos;s address
            </Typography>
          </Box>
        )}
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomAutoComplete
              id={`${prefixName}.resAddress1`}
              name={`${prefixName}.resAddress1`}
              flexDirection="column"
              regex={/^[a-z0-9A-ZáéíóúñüÁÉÍÓÚÑÜ¿¡,./-\s]*$/g}
              control={control}
              dataTestIdAutocomplete="autocomplete_address1"
              dataTestIdForLabel="resAddress1"
              dataTestIdForInput="addr1Input"
              required
              label="Address line 1"
              options={
                addressLookupData && watchResAddress1 && watchResAddress1?.length >= 6
                  ? addressLookupData
                  : []
              }
              handleChange={(_event, selectedValue) => {
                if (
                  watchResAddress1 &&
                  watchResAddress1.length >= 6 &&
                  !isAddressCopiedFromApplicant
                ) {
                  const index = addressLookupData.indexOf(selectedValue);
                  const selectedObj = addressLookupDataResponse[index];
                  handleAutoCompleteData(selectedObj);
                }
              }}
              maxLength={40}
              autoFocus
              placeholder="Enter address line 1"
              fullWidth
              error={!!errors?.cousers?.[couserIndex]?.resAddress1}
              errorText={errors?.cousers?.[couserIndex]?.resAddress1?.message}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.cousers?.[couserIndex]?.resAddress2}
              errorText={errors?.cousers?.[couserIndex]?.resAddress2?.message}
              maxLength="20"
              regex={/^[A-Za-z0-9áéíóúñüÁÉÍÓÚÑÜ¿¡,./-\s]$/}
              placeholder="Enter address line 2"
              label="Address line 2"
              flexDirection="column"
              name={`${prefixName}.resAddress2`}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Country"
              options={[
                {
                  name: 'United States',
                  value: 'USA',
                },
              ]}
              defaultValue="USA"
              control={control}
              name={`${prefixName}.resCountry`}
              errors={!!errors?.cousers?.[couserIndex]?.resCountry}
              errorText={errors?.cousers?.[couserIndex]?.resCountry?.message}
              required
              isDisabled
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="State"
              options={stateList}
              // defaultValue="PR"
              control={control}
              name={`${prefixName}.resState`}
              errors={!!errors?.cousers?.[couserIndex]?.resState}
              errorText={errors?.cousers?.[couserIndex]?.resState?.message}
              required
              displayEmpty
              isValue
              valueChange={(e) => handleStateChange(e)}
            />
          </Grid>

          {watchResState === 'PR' && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="City"
                control={control}
                name={`${prefixName}.resCity`}
                errors={!!errors?.cousers?.[couserIndex]?.resCity}
                errorText={errors?.cousers?.[couserIndex]?.resCity?.message}
                options={cityList}
                defaultValue=""
                required
                displayEmpty
              />
            </Grid>
          )}
          {watchResState !== 'PR' && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomInputBox
                control={control}
                errors={!!errors?.cousers?.[couserIndex]?.resCity}
                errorText={errors?.cousers?.[couserIndex]?.resCity?.message}
                placeholder="Enter city"
                label="City"
                name={`${prefixName}.resCity`}
                regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡\s]$/}
                maxLength="15"
                type="text"
                flexDirection="column"
                required
              />
            </Grid>
          )}
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.cousers?.[couserIndex]?.resZipcode}
              errorText={errors?.cousers?.[couserIndex]?.resZipcode?.message}
              placeholder="Enter zipcode"
              label="Zip code"
              name={`${prefixName}.resZipcode`}
              regex={/^\d$/}
              maxLength="5"
              type="text"
              flexDirection="column"
              required
            />
          </Grid>

          {showUtility && (
            <>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomSelectBox
                  control={control}
                  options={utilityBillList}
                  label="Utility bill"
                  flexDirection="column"
                  name={`${prefixName}.resUtilityBill`}
                  errors={!!errors?.cousers?.[couserIndex]?.resUtilityBill}
                  errorText={errors?.cousers?.[couserIndex]?.resUtilityBill?.message}
                  displayEmpty
                />
              </Grid>

              <Grid container item>
                <Box mb={1}>
                  <Typography variant="subtitle2" fontWeight="700">
                    Upload proof of address
                  </Typography>
                </Box>
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="space-between"
                  mt={1.25}
                >
                  <Grid item lg={5.75} md={5.75} sm={12} xs={12}>
                    <Box
                      className={customClasses.uploadContainer}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        display="flex"
                        onClick={() => setShowCamera(true)}
                        id="camera-btn"
                      >
                        <img src={CameraIcon} /> &nbsp;
                        <Typography
                          variant="subtitle1"
                          component="p"
                          sx={{ fontWeight: '700' }}
                        ></Typography>
                        Camera
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item lg={5.75} md={5.75} sm={12} xs={12}>
                    <CustomFileUploader
                      handleChange={handleChange}
                      name={`${prefixName}.utilitybillfile`}
                      control={control}
                      fileTypes={fileTypes}
                      setError={setError}
                    />
                  </Grid>
                  {ocrDocumentId &&
                    watchUtilityBillFile &&
                    !errors?.cousers?.[couserIndex]?.utilitybillfile &&
                    showUtility && (
                      <Box
                        display="flex"
                        gap={0.5}
                        mt={2.5}
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                      >
                        <CheckCircleIcon htmlColor="green" />
                        <Typography variant="">{watchUtilityBillFile?.name}</Typography>
                      </Box>
                    )}
                  <Box
                    display="flex"
                    gap={0.5}
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    mt={2.5}
                  >
                    <Typography component="p" color="red">
                      {errors?.cousers?.[couserIndex]?.utilitybillfile?.message}
                    </Typography>
                  </Box>
                </Grid>{' '}
              </Grid>
            </>
          )}
        </Grid>
      </CustomCollapse>
    </div>
  );
};
export default ResidentialAddress;

ResidentialAddress.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  addressLookupData: PropTypes.instanceOf(Object),
  addressLookupDataResponse: [],
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  cityList: PropTypes.array,
  resCityList: PropTypes.array,
  stateList: PropTypes.array,
  isResidentAddressOpen: PropTypes.bool,
  setError: PropTypes.func,
  setLoader: PropTypes.func,
  setShowCamera: PropTypes.func,
  ocrCameraFile: PropTypes.string,
  clearErrors: PropTypes.func,
  utilityBillList: PropTypes.instanceOf(Object),
  showCamera: PropTypes.bool,
  ocrFileName: PropTypes.string,
  prefixName: PropTypes.string,
  couserIndex: PropTypes.number,
  setApiErr: PropTypes.func,
  setResCityList: PropTypes.func,
  isMounted: PropTypes.bool,
  oldState: PropTypes.string,
  setOldState: PropTypes.func,
  setOldPerState: PropTypes.func,
};
ResidentialAddress.defaultProps = {
  control: {},
  errors: {},
  isResidentAddressOpen: false,
  addressLookupData: [],
  resCityList: [],
  addressLookupDataResponse: [],
  setValue: defaultFn,
  getValues: defaultFn,
  cityList: [],
  stateList: [],
  setError: defaultFn,
  setLoader: defaultFn,
  showCamera: false,
  setShowCamera: defaultFn,
  ocrCameraFile: '',
  clearErrors: defaultFn,
  utilityBillList: [],
  ocrFileName: '',
  prefixName: '',
  couserIndex: 0,
  setApiErr: defaultFn,
  setResCityList: defaultFn,
  isMounted: false,
  oldState: '',
  setOldState: defaultFn,
  setOldPerState: defaultFn,
};
