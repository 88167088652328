import ApiClass from 'Api/ApiClient';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { getEmpInfoObj, getInfoReqObj, handleError } from 'utils/utility';

export const InvitationExpiryCheck = async ({
  accessToken,
  setLoader,
  setIsInviExpired,
  setInvitationExpiredHeading,
  inviApplicationNum,
  setInvitationExpiredContent,
  isFromInviFlow,
  setIsGetDetailsDone,
  inviApplicantId,
  setGetDetailsData,
}) => {
  const ApiClient = new ApiClass();
  if (accessToken) {
    try {
      setLoader(true);
      const resp = await ApiClient.get(
        `${UrlHelpers.getApplicationDetails}?applicationId=${inviApplicationNum}&isLockRequired=true`,
        {
          headers: {
            'Content-Type': 'application/json',
            userid: 'guest',
            userName: inviApplicantId,
          },
        }
      );

      setIsInviExpired(resp?.InvitationExpired);
      if (resp?.InvitationExpired) {
        setInvitationExpiredHeading('Invitation expired');
        setInvitationExpiredContent(
          'The application that you are trying to access is no longer available. Please reach out to the dealer'
        );
      }
      if (resp && isFromInviFlow) {
        setGetDetailsData(resp);
        setIsGetDetailsDone(true);
      }

      return resp;
    } catch (e) {
      console.log('e', e.response.data);
      setIsInviExpired(true);
      setInvitationExpiredHeading('Invitation expired');
      setInvitationExpiredContent(`${e?.response?.data}.`);
    } finally {
      setLoader(false);
    }
  }
};
export const frameGetDetails = async ({
  getDetailsData,
  setValue,
  inviUserType,
  inviApplicantId,
  pageName,
}) => {
  const {
    informationRequest = {},
    employmentInformation = {},
    coSigners = [],
    coApplicants = [],
    applicantId,
  } = getDetailsData?.applicants || {};
  console.log({ getDetailsData });
  const couserArray = [];
  const displayObject =
    pageName === 'InformationReq' ? informationRequest || {} : employmentInformation || {};
  if (Object.entries(displayObject)?.length && inviUserType?.toLowerCase() === 'primaryapplicant') {
    await getPrimaryApplicant(displayObject, applicantId, couserArray, pageName);
  } else if (
    Object.entries(coApplicants)?.length &&
    inviUserType?.toLowerCase() === 'coapplicant1'
  ) {
    await getCoApplicantDetails(coApplicants, couserArray, pageName);
  } else if (
    coSigners.length > 0 &&
    (inviUserType?.toLowerCase() === 'cosigner1' || inviUserType?.toLowerCase() === 'cosigner2')
  ) {
    await getCosignerDetails(coSigners, couserArray, inviApplicantId, pageName);
  }
  const applyFormFields = async (formFieldss) => {
    Object.entries(formFieldss).forEach(([key, value]) => {
      setValue(key, value);
    });
  };

  await applyFormFields(couserArray[0] || []);
};
const getPrimaryApplicant = async (displayObject, applicantId, couserArray, pageName) => {
  const infoData = pageName
    ? getInfoReqObj(applicantId, displayObject)
    : getEmpInfoObj(applicantId, displayObject);
  const primaryAppt = {
    userType: 'Applicant',
    ...infoData,
  };
  couserArray.push(primaryAppt);
};
const getCoApplicantDetails = async (coApplicants, couserArray, pageName) => {
  const { applicantId, informationRequest, employmentInformation } = coApplicants?.[0];
  const infoData = pageName
    ? getInfoReqObj(applicantId, informationRequest)
    : getEmpInfoObj(applicantId, employmentInformation);

  const coapplicant = {
    userType: `Co-applicant`,
    ...infoData,
    // relationship: informationRequest?.participantDetailInfo?.relationshipWithOwner,
  };
  if (pageName) {
    coapplicant.relationship = informationRequest?.participantDetailInfo?.relationshipWithOwner;
  }

  couserArray.push(coapplicant);
};
const getCosignerDetails = async (coSigners, couserArray, inviApplicantId, pageName) => {
  coSigners.map((x, index) => {
    if (x.applicantId === inviApplicantId) {
      const { applicantId, informationRequest, employmentInformation } = x;
      const infoData = pageName
        ? getInfoReqObj(applicantId, informationRequest)
        : getEmpInfoObj(applicantId, employmentInformation);

      const cosigner = {
        userType: `Co-signer ${index + 1}`,
        ...infoData,
        // relationship: informationRequest?.participantDetailInfo?.relationshipWithOwner,
      };
      if (pageName) {
        cosigner.relationship = informationRequest?.participantDetailInfo?.relationshipWithOwner;
      }
      couserArray.push(cosigner);
    }
  });
};
export const exportFilesAsZip = (blobs) => {
  const zip = JSZip();

  blobs.forEach((blob, index) => {
    const fileType = blob.type.split('/')[1];
    zip.file(
      blob.fileName ? `${blob.fileName}.${fileType}` : `Consent File ${index + 1}.${fileType}`,
      blob
    );
  });
  zip.generateAsync({ type: 'blob' }).then((zipFile) => {
    const fileName = `Consents.zip`;
    return FileSaver.saveAs(zipFile, fileName);
  });
};

export const invitationStatusCheck = async ({ accessToken, setLoader, inviApplicationNum }) => {
  const ApiClient = new ApiClass();

  if (accessToken) {
    try {
      setLoader(true);
      const resp = await ApiClient.get(
        `${UrlHelpers.invitationStatus}?applicationId=${inviApplicationNum}`,
        {
          headers: { 'Content-Type': 'application/json', userid: 'guest', userName: 'guest' },
        }
      );

      return resp;
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  }
};
const handleRegex = async () => {
  return / of (\w+-\w+-\w+-\w+-\w+)/g;
};

const displayErrorMessage = async ({ resp, matchedVal, ids, setError, user }) => {
  let idUser = {};
  let response = resp;
  ids?.forEach((id, index) => {
    idUser[id] = matchedVal[index];
  });
  for (let key in idUser) {
    response = response.replace(key, idUser[key]);
  }
  handleError({ errResp: response, setError, user });
};
export const handleValidationResponse = async ({
  resp,
  details,
  setApiErr,
  inviEmpInfoPage,
  setError,
  user,
}) => {
  const regex = await handleRegex();
  const matches = resp?.match(regex);
  let ids;
  let matchedVal = [];
  if (matches) {
    ids = matches?.map((x) => x?.split(' ')[2]);
  }
  console.log({ ids });
  if (details && ids) {
    const applicant = {};

    applicant[details?.applicants?.applicantId] = 'Applicant';
    applicant[details?.applicants?.coApplicants?.[0]?.applicantId] = 'Co-applicant';
    applicant[details?.applicants?.coSigners?.[0]?.applicantId] = 'Co-signer 1';
    applicant[details?.applicants?.coSigners?.[1]?.applicantId] = 'Co-signer 2';

    matchedVal = ids?.map((val) => applicant[val]);
  }
  console.log({ matchedVal });
  await displayErrorMessage({ inviEmpInfoPage, resp, matchedVal, setApiErr, ids, setError, user });
};
