import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import ApiClass from 'Api/ApiClient';
import RightArrow from 'assets/svg/rightArrow.svg';
import AuthContext from 'context/AuthContext';
import moment from 'moment';
import {
  dealersRoleBased,
  homeApis,
} from 'pages/CreateApplication/InformationRequest/moduleUtilities/dropdownApis';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { KpiSchema } from 'schemaValidations/KpiSchema';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { checkAccess, parseJwt } from 'utils';

import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import CustomMultiSelect from 'components/CustomMultiSelect/CustomMultiSelect';
import Loader from 'components/Loader/Loader';

import { BankerApplicationDashboard } from './BankerApplicationDashboard';
import CustomizedHomeMenu from './CustomizedHomeMenu';
import KpiView from './KpiView';

const Home = () => {
  let {
    idToken,
    setOpenPopUp,
    setCreateAppFlag,
    setLookupSaved,
    frequencyList,
    setFrequencyList,
    accessPolicy,
    role,
    setRole,
    setCreateAppApplicationId,
    setUserID,
    userID,
    setuserName,
    setFinanceInfo,
    viewApprovalQueue,
    setViewApprovalQueue,
    setCreateAppLaunchType,
    setPopupStep,
    rolebasedDealers,
    setRoleBasedDealers,
    userType,
    fullName,
    setFullName,
    setDealer,
  } = useContext(AuthContext);
  const location = useLocation();
  const { tabValue = 0 } = location?.state || {};
  const navigate = useNavigate();
  const [isRestricted, setIsRestricted] = useState(false);
  const [transition, setTransition] = useState(undefined);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [alignment, setAlignment] = useState('1#MONTH');
  const [isAllowed, setIsAllowed] = useState(false);
  const [viewKPI, setViewKPI] = useState(false);
  const [kpiResponse, setKpiResponse] = useState({});

  const [isDisabled, setIsDisabled] = useState(false);
  const [applicationData, setApplicationData] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState([]);
  const allowedRolesForKpiView = ['DEALER', 'DEALER 2', 'MANAGER', 'MANAGER 2'];
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      startDate: '',
      endDate: '',
    },
    resolver: yupResolver(KpiSchema),
  });
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    setValue('startDate', null);
    setValue('endDate', null);
    clearErrors(['startDate', 'endDate']);
  };
  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  useEffect(() => {
    if (watchStartDate || watchEndDate) {
      setAlignment('');
    }
    if ((watchStartDate && watchEndDate) || alignment) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [watchStartDate, watchEndDate, alignment]);
  useEffect(() => {
    setValue('endDate', null);
    clearErrors('endDate');
  }, [watchStartDate]);

  const handleKpiCalculation = async (data) => {
    const ApiClient = new ApiClass();

    let frequencyData = alignment?.replace('#', '_');
    let startDate = moment(data?.startDate).format('MM/DD/YYYY');
    let endDate = moment(data?.endDate).format('MM/DD/YYYY');
    let dealerIdparam = selectedDealer?.length ? `&dealerId=${selectedDealer}` : '';
    let flowBasedparam = selectedDealer ? dealerIdparam : `&createdBy=${userID}`;
    let url = data?.startDate
      ? `${UrlHelpers.frequency}?fromDate=${startDate}&toDate=${endDate}${flowBasedparam}`
      : `${UrlHelpers.frequency}?frequency=${frequencyData}${flowBasedparam}`;
    console.log('url', url);

    try {
      setLoader(true);
      const resp = await ApiClient.get(url, {
        headers: { 'Content-Type': 'application/json' },
      });

      console.log({ resp });
      if (resp) {
        setKpiResponse(resp);
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(async () => {
    const parsedToken = parseJwt(idToken);
    let { name, given_name, family_name } = parsedToken;
    const roleID = parsedToken?.['custom:RoleID'];
    const userId = parsedToken?.['cognito:username'];

    if ((Array.isArray(roleID) || roleID?.includes('[')) && roleID?.length > 0) {
      setIsRestricted(true);
    } else if (roleID) {
      sessionStorage.setItem('RoleId', roleID);
      setRole(roleID);
    }
    if (name) {
      setuserName(name);
    }
    if (given_name && family_name) {
      setFullName(`${given_name} ${family_name}`);
    }
    if (userId) {
      setUserID(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(async () => {
    if (userID && Object.entries(accessPolicy)?.length) {
      if (viewKPI) {
        handleKpiCalculation({ startDate: watchStartDate, endDate: watchEndDate });
      }
    }
  }, [userID, accessPolicy, viewKPI, selectedDealer?.length]);

  useEffect(async () => {
    if (userID && Object.entries(accessPolicy)?.length) {
      const ApiClient = new ApiClass();
      try {
        setLoader(true);
        const resp = await ApiClient.get(
          `${UrlHelpers.fetchApplications}?frequency=1_MONTH${
            userType === 'banker' ? '' : `&createdBy=${userID}`
          }&limit=10&appStatus=SUBMITTED`,
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );

        console.log({ resp });
        if (resp?.applicationList) {
          setApplicationData(resp?.applicationList);
        }
      } catch (e) {
        console.log('apii errrrrrr', e);
      } finally {
        setLoader(false);
      }
    }
  }, [userID, accessPolicy]);

  useEffect(async () => {
    if (Object.entries(accessPolicy)?.length) {
      let allowed = await checkAccess(accessPolicy, 'DASHBOARD', 'New-USER-REQUEST');
      setIsAllowed(allowed);
      let viewqueue = await checkAccess(accessPolicy, 'DASHBOARD', 'APPROVAL-QUEUE');
      setViewApprovalQueue(viewqueue);
      let viewkpi = await checkAccess(accessPolicy, 'DASHBOARD', 'KPI-VIEW');
      setViewKPI(viewkpi);
      if (viewkpi) {
        console.log('intoooo kpi api');
        await homeApis({ frequencyList, setFrequencyList, setLoader });
      }
    }
  }, [accessPolicy]);
  const isAllSelected =
    rolebasedDealers?.length > 0 && selectedDealer?.length === rolebasedDealers?.length;
  useEffect(async () => {
    if (userID && role && rolebasedDealers?.length === 0) {
      await dealersRoleBased({ rolebasedDealers, setRoleBasedDealers, role, userID });
    }
  }, [userID, role]);

  useEffect(() => {
    if (userType) {
      setSelectedDealer(rolebasedDealers?.length ? rolebasedDealers?.map((x) => x.dealerId) : []);
    } else {
      setSelectedDealer([]);
    }
  }, [rolebasedDealers]);

  const handleClose = () => {
    navigate('/login', { state: { userType } });
  };

  const handleSnackBarClick = (Transition) => () => {
    console.log('Transition', Transition);
    setTransition(() => Transition);
    setSnackBarOpen(true);
  };

  const handleMinDate = () => {
    const today = new Date();
    const minDate = new Date(today);
    minDate.setFullYear(today.getFullYear() - 1);
    return minDate.setDate(today.getDate() + 1);
  };

  const handleMultiSelctChange = (event) => {
    const value = event?.target?.value;
    if (value[value.length - 1] === 'all') {
      setSelectedDealer(
        selectedDealer?.length === rolebasedDealers?.length
          ? []
          : rolebasedDealers?.map((x) => x.dealerId)
      );

      return;
    }
    let newValues = [];
    if (value?.length > 1 && value.includes('Select')) {
      newValues = value.filter((x) => x !== 'Select');
      setSelectedDealer(newValues);
    } else {
      setSelectedDealer(value);
    }
  };

  return (
    <Grid display="flex" flexDirection="column" className={classes.dummyHome} gap={2}>
      {isRestricted && (
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open
          PaperProps={{ sx: { padding: '20px' } }}
        >
          <DialogContent>
            <Typography variant="body1" color="#909090">
              User has multiple roles. Please reach to admin to get your role updated
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Grid display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">
          Welcome,{' '}
          <Typography variant="span" color="primary" onClick={handleSnackBarClick()}>
            {fullName}
          </Typography>
        </Typography>
        <Box display="flex" gap="10px">
          {isAllowed ? (
            <>
              <CustomizedHomeMenu
                transition={transition}
                snackBarOpen={snackBarOpen}
                setSnackBarOpen={setSnackBarOpen}
              />
            </>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpenPopUp(true);
                setCreateAppFlag(true);
                setLookupSaved(false);
                setCreateAppApplicationId('');
                setFinanceInfo({});
                setCreateAppLaunchType('dashboard');
                setPopupStep(0);
                setDealer('');
              }}
            >
              Create application
            </Button>
          )}
        </Box>
      </Grid>

      <Loader open={loader} />
      {viewKPI && (
        <>
          {' '}
          <Grid container sx={{ alignItems: 'flex-start' }} columnSpacing={1} rowSpacing={2}>
            <Grid item lg={2.5} md={2.5} xs={4}>
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                sx={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                <Typography variant="subtitle2" component="p" mr={2} color="#000">
                  Period
                </Typography>

                {frequencyList?.map((x) => {
                  return (
                    <ToggleButton key={x.value} value={x?.value} sx={{ width: '107px' }}>
                      {x?.displayName}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </Grid>
            {allowedRolesForKpiView?.includes(role) && (
              <Grid item lg={2.5} md={2.5} xs={4}>
                <Grid container alignItems="center" columnSpacing={1}>
                  <Grid item lg={4} md={4} xs={4}>
                    <Typography variant="subtitle2" component="p" textAlign="center">
                      Dealer name
                    </Typography>
                  </Grid>
                  <Grid item lg={7} md={7} xs={4}>
                    <CustomMultiSelect
                      selected={selectedDealer}
                      handleMultiSelctChange={handleMultiSelctChange}
                      isAllSelected={isAllSelected}
                      options={rolebasedDealers?.map((x) => ({
                        displayName: x.dealerName,
                        value: x.dealerId,
                      }))}
                      label="demo-multiple-name-label"
                      name="demo-multiple-name"
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item>
              <Grid container alignItems="flex-start" columnSpacing={1}>
                <Grid item lg={4} md={4} xs={4}>
                  <CustomDatePicker
                    name="startDate"
                    control={control}
                    error={!!errors?.startDate}
                    errorText={errors?.startDate?.message}
                    minDate={handleMinDate()}
                    maxDate={new Date()}
                    placeHolder="Start date"
                    noGap
                    noMargin
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={4}>
                  <CustomDatePicker
                    name="endDate"
                    control={control}
                    error={!!errors?.endDate}
                    errorText={errors?.endDate?.message}
                    // minDate={watchStartDate}
                    minDate={watchStartDate || handleMinDate()}
                    maxDate={new Date()}
                    placeHolder="End date"
                    noGap
                    noMargin
                  />
                </Grid>
                <Grid item lg={1} md={1} xs={4}>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ minWidth: 'auto' }}
                    onClick={handleSubmit(handleKpiCalculation)}
                    disabled={isDisabled}
                    aria-label="Go"
                    data-testid="kpiResults"
                  >
                    <img src={RightArrow} alt="Right Arrow" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <KpiView kpiResponse={kpiResponse} />
        </>
      )}
      {role && (
        <BankerApplicationDashboard
          applicationsData={applicationData}
          viewApprovalQueue={viewApprovalQueue}
          redirectTabValue={tabValue}
        />
      )}
    </Grid>
  );
};

export default Home;
