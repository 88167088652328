import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn } from 'utils/utility';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';
import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

const DeletePromotionsDialog = ({ open, setOpen, id, callbackApi }) => {
  const [loader, setLoader] = useState(false);
  const [apiErr, setApiErr] = useState('');
  const ApiClient = new ApiClass();
  const { userID, fullName } = useContext(AuthContext);
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    console.log('delete clicked');
    try {
      setLoader(true);
      await ApiClient.delete(
        `${UrlHelpers.fetchPromotions}/${id}`,

        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );
      callbackApi();
      setOpen(false);
    } catch (e) {
      console.log('e', e);
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later'
      );
    } finally {
      setLoader(false);
    }
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{ sx: { padding: '20px', gap: '20px' } }}
    >
      <BootstrapDialogTitle onClose={handleClose} data-testid="discardClose" sx={{ padding: '0' }}>
        <Box display="flex" gap={1} pb={2} borderBottom="1px solid #E37617">
          <Typography variant="h3">Delete Promotion ?</Typography>
        </Box>
      </BootstrapDialogTitle>
      <DialogContent sx={{ padding: '0' }}>
        <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
        {loader && <Loader open={loader} />}
        <Typography variant="subtitle2" fontWeight={700} sx={{ padding: '4px 0' }}>
          Are you sure you want to delete this promotion?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDelete}
          sx={{ minWidth: '200px' }}
          id="delete-btn"
        >
          Yes, Delete
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
          sx={{ minWidth: '200px' }}
          id="cancel-delete-btn"
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePromotionsDialog;

DeletePromotionsDialog.propTypes = {
  id: PropTypes.string,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  callbackApi: PropTypes.func,
};
DeletePromotionsDialog.defaultProps = {
  id: '',
  setOpen: defaultFn,
  open: false,
  callbackApi: defaultFn,
};
