import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import CustomCollapse from 'components/CustomCollapse';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

import { userLabel } from './moduleUtilities';

const RelationWithOwner = ({
  control,
  errors,
  relationshipList,
  prefixName,
  couserIndex,
  userType,
  flow,
}) => {
  const heading = userLabel(userType);
  return (
    <CustomCollapse
      heading={flow === 'inviFlow' ? heading : 'Relationship with applicant'}
      open={true}
      errors={errors}
    >
      <Grid
        item
        lg={flow === 'inviFlow' ? 12 : 6}
        md={flow === 'inviFlow' ? 12 : 6}
        sm={12}
        xs={12}
      >
        <CustomSelectBox
          flexDirection="column"
          label="Relationship with applicant"
          control={control}
          errors={
            flow === 'inviFlow'
              ? !!errors?.relationship
              : !!errors?.cousers?.[couserIndex]?.relationship
          }
          errorText={
            flow === 'inviFlow'
              ? errors?.relationship?.message
              : errors?.cousers?.[couserIndex]?.relationship?.message
          }
          options={relationshipList}
          defaultValue=""
          required
          displayEmpty
          name={flow === 'inviFlow' ? 'relationship' : `${prefixName}.relationship`}
        />
      </Grid>
    </CustomCollapse>
  );
};

export default RelationWithOwner;

RelationWithOwner.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  userType: PropTypes.string,
  flow: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  relationshipList: PropTypes.instanceOf(Object),
  prefixName: PropTypes.string,
  couserIndex: PropTypes.number,
};
RelationWithOwner.defaultProps = {
  control: {},
  errors: {},
  userType: '',
  flow: '',
  inputRef: null,
  relationshipList: [],
  prefixName: '',
  couserIndex: 0,
};
