import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ApiClass from 'Api/ApiClient';
import TriangleIcon from 'assets/svg/Icon-triangle.svg';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn } from 'utils/utility';

import Loader from 'components/Loader/Loader';

const InviVoidPopup = ({ openInviPopup, setOpenInviPopup, handleNavigate }) => {
  const ApiClient = new ApiClass();

  const [loader, setLoader] = useState(false);
  const { userID, fullName, createAppApplicationId } = useContext(AuthContext);
  const handleClose = () => {
    setOpenInviPopup(false);
  };
  const handleInviExpiry = async () => {
    try {
      setLoader(true);
      let invitationExpired = await ApiClient.put(
        `${UrlHelpers.inviExpiry}/?applicationId=${createAppApplicationId}`,
        {},
        {
          headers: { 'Content-Type': 'application/json', userId: userID, userName: fullName },
        }
      );
      console.log('termlist', invitationExpired);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
    handleNavigate();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openInviPopup}
      PaperProps={{ sx: { padding: '20px', gap: '20px' } }}
    >
      <Loader open={loader} />
      <Box pb={1.5}>
        <IconButton
          aria-label="close"
          data-testid="closebtn"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ padding: '0' }}>
        <Box display="flex" gap={2.5}>
          <img src={TriangleIcon} width="48px" height="48px" />
          <Typography variant="subtitle1" color="#000000" fontWeight="700">
            The pending invitations if any would be void, do you wish to proceed?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
        <Button variant="contained" color="secondary" onClick={handleInviExpiry}>
          Yes, proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviVoidPopup;

InviVoidPopup.propTypes = {
  setOpenInviPopup: PropTypes.func,
  openInviPopup: PropTypes.bool,
  handleNavigate: PropTypes.func,
};
InviVoidPopup.defaultProps = {
  setOpenInviPopup: defaultFn,
  openInviPopup: false,
  handleNavigate: defaultFn,
};
