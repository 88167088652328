import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn } from 'utils/utility';

import Loader from 'components/Loader/Loader';

import { dealersRoleBased } from '../CreateApplication/InformationRequest/moduleUtilities/dropdownApis';
import ApplicationsTable from './ApplicationsTable';
import { SearchFilters } from './SearchFilters';

const Drafts = ({ notifyAppId, setApiErr }) => {
  const {
    rolebasedDealers,
    setRoleBasedDealers,
    userID,
    role,
    openDiscardModal,
    createAppFlag,
    viewApprovalQueue,
  } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const [draftData, setDraftData] = useState([]);
  console.log('draftData', draftData);
  const [page, setPage] = useState(1);
  const [searchOptions, setSearchOptions] = useState({
    refID: '',
    applicantName: '',
    vehicleName: '',
    createDate: null,
  });
  const [emptyDataMessage, setEmptyDataMessage] = useState('No application/s available');
  const rowsPerPage = 10;
  useEffect(async () => {
    if (userID) {
      await dealersRoleBased({ rolebasedDealers, setRoleBasedDealers, role, userID });
    }
  }, []);

  useEffect(async () => {
    if (rolebasedDealers && !openDiscardModal) {
      console.log('into fetch application');
      await fetchApplications();
    }
  }, [rolebasedDealers, openDiscardModal, createAppFlag]);

  useEffect(() => {
    if (notifyAppId && draftData?.length) {
      let elIndex = draftData.findIndex((x) => x.applicationId === notifyAppId);
      console.log('elIndex', { elIndex, rowsPerPage });
      if (elIndex > -1) {
        setPage(Math.ceil((elIndex + 1) / rowsPerPage));
      }
    }
  }, [notifyAppId, draftData]);

  const fetchApplications = async () => {
    const ApiClient = new ApiClass();
    try {
      setLoader(true);

      const CommaSeperatedDealerListt = rolebasedDealers?.map((x) => x.dealerId)?.join(',') || '';

      const resp = await ApiClient.get(
        `${UrlHelpers.fetchApplications}?frequency=7_DAY${
          viewApprovalQueue || role === 'SALES REPRESENTATIVE' || role === 'SALES REPRESENTATIVE 2'
            ? `&createdBy=${userID}`
            : `&dealerId=${CommaSeperatedDealerListt}`
        }&appStatus=DRAFT`,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      console.log('into fetch application resp', resp);
      if (resp) {
        setDraftData(resp?.applicationList);
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  };
  return (
    <>
      <Loader open={loader} />
      <SearchFilters
        searchOptions={searchOptions}
        setSearchOptions={setSearchOptions}
        applicationData={draftData}
        setApplicationData={setDraftData}
        setLoader={setLoader}
        // myAppFlag={false}
        userID={userID}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        dealerList={rolebasedDealers}
        flow="Drafts"
        setEmptyDataMessage={setEmptyDataMessage}
        draftFetchApplications={fetchApplications}
        viewApprovalQueue={viewApprovalQueue}
      />
      <ApplicationsTable
        draftData={draftData}
        page={page}
        setPage={setPage}
        hasPagination
        draftsTable
        emptyDataMessage={emptyDataMessage}
        notifyAppId={notifyAppId}
        setApiErr={setApiErr}
      />
    </>
  );
};

export default Drafts;

Drafts.propTypes = {
  notifyAppId: PropTypes.string,
  setApiErr: PropTypes.func,
};

Drafts.defaultProps = {
  notifyAppId: '',
  setApiErr: defaultFn,
};
