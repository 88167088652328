import { Box, FormHelperText, InputLabel, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { defaultFn } from 'utils/utility';

import globalClasses from '../../styles/globalStyle.module.scss';

const NumberFormatCustom = (Props) => {
  return <NumberFormat thousandSeparator={true} prefix={'$ '} {...Props} />;
};
const CustomNumberFormat = (props) => {
  const {
    control,
    label,
    name,
    error,
    errorText,
    id,
    prefix,
    disabled,
    suffix,
    thousandSeparator,
    allowNegative,
    decimalScale,
    defaultValue,
    dataTestId,
    inputProps,

    maxLength,

    setValue,
    maxValue,
    fixedDecimalScale,
    displayType,
    allowEmptyFormatting,
    className,
    required,
    placeholder,
    noLabel,
  } = props;
  const handleternary = (condition, success, failure) => {
    if (condition) {
      return success;
    } else {
      return failure;
    }
  };
  let placeholderr = handleternary(placeholder, placeholder, 'Enter value');
  let labell = handleternary(label, label, noLabel);
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            gap={0.5}
            my={0.5}
            className={className}
          >
            <InputLabel htmlFor={name}>
              {required && <span style={{ color: 'red' }}> *&nbsp;</span>}
              {label}
            </InputLabel>
            <TextField
              {...field}
              InputProps={{
                inputComponent: NumberFormatCustom,
                disableUnderline: true,
              }}
              id={id}
              variant="outlined"
              size="medium"
              className={globalClasses.numberFormat}
              inputProps={{
                'data-testid': dataTestId,
                maxLength,
                placeholder: placeholderr,
                disabled: disabled,
                suffix: suffix,
                thousandSeparator: thousandSeparator,
                allowNegative: allowNegative,
                decimalScale: decimalScale,
                defaultValue: defaultValue,
                prefix: prefix,
                'aria-label': labell,
                displayType: displayType,
                fixedDecimalScale: fixedDecimalScale,
                allowLeadingZeros: false,
                allowEmptyFormatting: allowEmptyFormatting,
                isAllowed: (values) => {
                  {
                    const { value, floatValue } = values;

                    if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
                      return true;
                    }
                    if (value.charAt(0) === '0') {
                      if (value.charAt(1) && value.charAt(1) != '.') {
                        return false;
                      }
                    }
                    if (maxValue) {
                      return floatValue <= maxValue;
                    } else {
                      return true;
                    }
                  }
                },
                onBlur: (e) => {
                  if (setValue) {
                    return setValue(name, parseFloat(e.target.value).toFixed(2));
                  }
                  return setValue(name, e?.target?.value);
                },

                ...inputProps,
              }}
            />
          </Box>
        )}
      />
      {error && (
        <FormHelperText gap={2} className={globalClasses.customSelectError}>
          {' '}
          {errorText}
        </FormHelperText>
      )}
    </>
  );
};

export default CustomNumberFormat;

CustomNumberFormat.propTypes = {
  control: PropTypes.instanceOf(Object),
  name: PropTypes.string,
  error: PropTypes.bool,
  setValue: PropTypes.func,
  errorText: PropTypes.string,
  noLabel: PropTypes.string,
  label: PropTypes.string,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,

  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prefix: PropTypes.string,
  disabled: PropTypes.bool,
  suffix: PropTypes.string,
  placeholder: PropTypes.string,
  thousandSeparator: PropTypes.bool,
  allowNegative: PropTypes.bool,
  decimalScale: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataTestId: PropTypes.string,
  inputProps: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  fixedDecimalScale: PropTypes.bool,
  allowEmptyFormatting: PropTypes.bool,
  displayType: PropTypes.string,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
};
CustomNumberFormat.defaultProps = {
  control: {},
  name: '',
  label: '',
  error: false,
  errorText: '',
  id: '',
  prefix: '',
  disabled: false,
  suffix: '',
  placeholder: '',
  noLabel: '',
  thousandSeparator: false,
  allowNegative: false,
  allowEmptyFormatting: false,
  decimalScale: '',
  defaultValue: '',
  dataTestId: '',
  inputProps: {},
  className: '',
  maxValue: 0,
  fixedDecimalScale: false,
  displayType: '',
  required: false,
  setValue: defaultFn,
  maxLength: 15,
};
