import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { Box, Button, Link, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { numberToCurrency } from 'utils/utility';

import Loader from 'components/Loader/Loader';

const VehicleValueSummary = ({
  data,
  selectedRows,
  coApplicant,
  coSigner,
  reviewFlag,
  calculationState,
  completedSteps,
  viewAppFlow,
}) => {
  const {
    setShowSummary,
    createAppFlag,
    pageData,
    collateralInfo,
    setCollateralInfo,
    setLookupSaved,
    createAppApplicationId,
    lookupLinkClicked,
    setLookupValue,
    setOpenPopUp,
    createAppLookup,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const ApiClient = new ApiClass();
  const [loader, setLoader] = useState(false);
  console.log('data', data);
  useEffect(() => {
    const payload = {
      vehicleValues: {
        basevehicleValue: data?.base_retail_clean,
        equipmentAdjustment: data?.add_deduct_retail_clean || 0,
        mileageAdjustment: data?.mileage_retail_clean || 0,
        selectedEquipmentAdjustment: selectedRows,
        priceIncludes: data?.price_includes,
        originalMsrp: data?.msrp,
        totalMSRP: totalFullObj[0]?.Retail.clean,
      },
      productDetails: {
        ...pageData?.productDetails,
        vin: data?.vin,
        cylinders: +data?.cylinders,
        doors: +data?.ext_doors,
      },
    };
    if (!createAppFlag && !lookupLinkClicked) {
      setLookupValue([{ ...payload }]);
    }
  }, [data]);

  useEffect(() => {
    if (createAppLookup === 'used') {
      const payload = {
        vehicleValues: {
          basevehicleValue: data?.base_retail_clean,
          equipmentAdjustment: data?.add_deduct_retail_clean || 0,
          mileageAdjustment: data?.mileage_retail_clean,
          selectedEquipmentAdjustment: selectedRows,
          priceIncludes: data?.price_includes,
          originalMsrp: data?.msrp,
          totalMSRP: totalFullObj[0]?.Retail.clean,
        },
        productDetails: {
          ...pageData?.productDetails,
          vin: data?.vin,
          cylinders: +data?.cylinders,
          doors: +data?.ext_doors,
        },
      };
      console.log('payload', payload);
      setOpenPopUp(true);
      setCollateralInfo({ ...payload });
    }
  }, [createAppLookup]);
  const vehicleSummary = [
    // {
    //   name: 'Wholesale',
    //   data: [
    //     {
    //       name: 'Base vehicle value',
    //       xClean:
    //         data?.base_whole_xclean != null
    //           ? data?.base_whole_xclean
    //           : collateralInfo?.vehicleValues?.basevehicleValue || 0,
    //       // clean: data?.base_whole_clean,
    //       // avg: data?.base_whole_avg,
    //       // rough: data?.base_whole_rough,
    //     },

    //     {
    //       name: 'Equipment adjustment',
    //       xClean:
    //         data?.add_deduct_whole_xclean != null
    //           ? data?.add_deduct_whole_xclean
    //           : (collateralInfo?.vehicleValues?.equipmentAdjustment.constructor !== Object &&
    //               collateralInfo?.vehicleValues?.equipmentAdjustment) ||
    //             0,
    //       // clean: data?.add_deduct_whole_clean || 0,
    //       // avg: data?.add_deduct_whole_avg || 0,
    //       // rough: data?.add_deduct_whole_rough || 0,
    //     },

    //     {
    //       name: 'Mileage adjustment',
    //       xClean:
    //         data?.mileage_whole_xclean != null
    //           ? data?.mileage_whole_xclean
    //           : collateralInfo?.vehicleValues?.mileageAdjustment || 0,
    //       // clean: data?.mileage_whole_clean,
    //       // avg: data?.mileage_whole_avg,
    //       // rough: data?.mileage_whole_rough,
    //     },
    //   ],
    // },
    {
      name: 'Retail',
      data: [
        {
          name: 'Base vehicle value',
          // xClean: data?.base_retail_xclean,
          clean:
            data?.base_retail_clean != null
              ? data?.base_retail_clean
              : collateralInfo?.vehicleValues?.basevehicleValue || 0,
          // avg: data?.base_retail_avg,
          // rough: data?.base_retail_rough,
        },

        {
          name: 'Equipment adjustment',
          // xClean: data?.add_deduct_retail_xclean || 0,
          clean:
            data?.add_deduct_retail_clean != null
              ? data?.add_deduct_retail_clean
              : (collateralInfo?.vehicleValues?.equipmentAdjustment.constructor !== Object &&
                  collateralInfo?.vehicleValues?.equipmentAdjustment) ||
                0,
          // avg: data?.add_deduct_retail_avg || 0,
          // rough: data?.add_deduct_retail_rough || 0,
        },

        {
          name: 'Mileage adjustment',
          // xClean: data?.mileage_retail_xclean,
          clean:
            data?.mileage_retail_clean != null
              ? data?.mileage_retail_clean
              : collateralInfo?.vehicleValues?.mileageAdjustment || 0,
          // avg: data?.mileage_retail_avg,
          // rough: data?.mileage_retail_rough,
        },
      ],
    },
    // {
    //   name: 'Trade In',
    //   data: [
    //     {
    //       name: 'Base vehicle value',

    //       clean: data?.base_tradein_clean,
    //       avg: data?.base_tradein_avg,
    //       rough: data?.base_tradein_rough,
    //     },

    //     {
    //       name: 'Equipment adjustment',

    //       clean: data?.add_deduct_tradein_clean || 0,
    //       avg: data?.add_deduct_tradein_avg || 0,
    //       rough: data?.add_deduct_tradein_rough || 0,
    //     },

    //     {
    //       name: 'Mileage adjustment',

    //       clean: data?.mileage_tradein_clean,
    //       avg: data?.mileage_tradein_avg,
    //       rough: data?.mileage_tradein_rough,
    //     },
    //   ],
    // },
  ];
  console.log('vehicleSummary', vehicleSummary);
  const totalFullObj = vehicleSummary.map((x) => {
    const totalcalc = x?.data?.reduce(
      (acc, eachObj) => {
        let totall = acc;
        totall.clean += +eachObj?.clean || 0;
        return totall;
      },
      { clean: 0 }
    );

    return {
      [x.name]: totalcalc,
    };
  });

  console.log('total', totalFullObj[0]);

  const onSaveValue = async () => {
    const payload = {
      vehicleValues: {
        basevehicleValue: data?.base_retail_clean,
        equipmentAdjustment: data?.add_deduct_retail_clean || 0,
        mileageAdjustment: data?.mileage_retail_clean,
        selectedEquipmentAdjustment: selectedRows,
        priceIncludes: data?.price_includes,
        originalMsrp: data?.msrp,
        totalMSRP: totalFullObj[0]?.Retail.clean,
      },
      productDetails: {
        ...pageData?.productDetails,
        vin: data?.vin,
        cylinders: +data?.cylinders,
        doors: +data?.ext_doors,
      },
    };

    if (!lookupLinkClicked) {
      setCollateralInfo({ ...payload });
      try {
        setLoader(true);
        const resp = await ApiClient.post(
          UrlHelpers.saveLookupValue,
          {
            applicationId: createAppApplicationId,
            type: 'vehicle',
            searchResult: JSON.stringify({ ...payload }),
          },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        console.log(resp);
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoader(false);
      }
    }
    setLookupSaved(true);
    navigate('/home/create-app', {
      state: {
        activeStep: 3,
        completedSteps,
        coApplicant,
        coSigner,
        reviewFlag,
        calculationState,
        viewAppFlow,
      },
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap={2.5} flexGrow="1" pb={5}>
      {loader && <Loader open={loader} />}
      <Box display="flex" justifyContent="space-between">
        <Typography variant="caption" sx={{ fontSize: '18px', fontWeight: 700 }}>
          Vehicle values
        </Typography>
        <Box display="flex" gap="2px">
          <LocalPrintshopOutlinedIcon color="secondary" />
          <Link
            component="button"
            variant="subtitle2"
            color="secondary"
            id="print-btn"
            onClick={() => {
              window.print();
            }}
          >
            Print
          </Link>
        </Box>
      </Box>

      {vehicleSummary.map((x, index) => {
        return (
          <Box
            display="flex"
            flexDirection="column"
            key={x?.name}
            className={classes.tableOutline}
            role="table"
          >
            <Typography
              sx={{
                backgroundColor: '#F2F2F2',
                borderBottom: '1px solid f2f2f2',
                padding: '10px',
                fontWeight: 700,
              }}
              role="row"
            >
              {x.name}
            </Typography>
            <Box display="flex" flexDirection="column" className={classes.childBorderLine}>
              <Box
                display="flex"
                gap={2}
                sx={{ backgroundColor: '#f9f9f9' }}
                p={2}
                className={classes.childBorderLine}
                role="row"
              >
                <Typography
                  sx={{ flexGrow: 1, fontWeight: 700 }}
                  aria-label="description"
                  role="columnheader"
                ></Typography>

              
                  <Typography
                    sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
                    role="columnheader"
                  >
                    Clean
                  </Typography>
            
              </Box>
              {x?.data?.map((eachObj) => {
                const { name, clean } = eachObj;
                return (
                  <Box display="flex" gap={2} p={2} key={`${name}-${clean}`} role="row">
                    <Typography sx={{ flexGrow: 1 }} role="cell">
                      {name}
                    </Typography>
                    {x.name !== 'Trade In' && (
                      <Typography sx={{ minWidth: '15%', maxWidth: '15%' }} role="cell">
                        {numberToCurrency(clean)}
                      </Typography>
                    )}
                  </Box>
                );
              })}
              <Box display="flex" gap={2} sx={{ backgroundColor: '#f9f9f9' }} p={2} role="row">
                <Typography sx={{ flexGrow: 1, fontWeight: 700 }} role="cell">
                  Total value
                </Typography>
                {x.name !== 'Trade In' && (
                  <Typography
                    sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
                    role="cell"
                  >
                    {numberToCurrency(totalFullObj?.[index]?.[x.name]?.clean)}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        );
      })}

      <Box
        display="flex"
        flexDirection="column"
        gap={1.25}
        className={classes.tableOutline}
        role="table"
      >
        <Typography
          sx={{
            backgroundColor: '#F2F2F2',
            borderBottom: '1px solid f2f2f2',
            padding: '10px',
            fontWeight: 700,
          }}
          role="columnheader"
        >
          Miscellaneous
        </Typography>

        <Box
          display="flex"
          className={classes.borderTable}
          p={2}
          justifyContent="space-between"
          role="row"
        >
          <Typography sx={{ minWidth: '200px' }} role="cell">
            Price includes :
          </Typography>
          <Typography sx={{ minWidth: '10%' }} role="cell">
            {data?.price_includes != null
              ? data?.price_includes
              : collateralInfo?.vehicleValues?.priceIncludes}
          </Typography>
        </Box>
        <Box display="flex" p={2} justifyContent="space-between" role="row">
          <Typography sx={{ minWidth: '200px' }} role="cell">
            MSRP original
          </Typography>
          <Typography sx={{ minWidth: '10%' }} role="cell">
            {numberToCurrency(
              data?.msrp != null ? data?.msrp : collateralInfo?.vehicleValues?.originalMsrp
            )}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
        <Box display="flex" alignItems="center">
          {!lookupLinkClicked && (
            <>
              <KeyboardArrowLeftIcon color="secondary" />
              <Link
                component="button"
                color="secondary"
                id="back-btn"
                onClick={() => setShowSummary(false)}
              >
                Back
              </Link>
            </>
          )}
        </Box>
        <Box>
          {createAppFlag && (
            <Button onClick={onSaveValue} variant="contained" color="secondary" id="save-val-btn">
              Save value
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default VehicleValueSummary;

VehicleValueSummary.propTypes = {
  data: PropTypes.instanceOf(Object),
  selectedRows: PropTypes.instanceOf(Array),
  coApplicant: PropTypes.instanceOf(Object),
  coSigner: PropTypes.instanceOf(Object),
  reviewFlag: PropTypes.bool,
  calculationState: PropTypes.string,
  completedSteps: PropTypes.array,
  viewAppFlow: PropTypes.bool,
};
VehicleValueSummary.defaultProps = {
  data: {},
  selectedRows: [],
  coApplicant: {},
  coSigner: [],
  reviewFlag: false,
  calculationState: '',
  completedSteps: [],
  viewAppFlow: false,
};
