/* eslint-disable no-unused-vars */
import { Box, FormHelperText, Grid, InputLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import globalClasses from 'styles/globalStyle.module.scss';
import { defaultFn } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';
import CustomNumberFormat from 'components/CustomNumberFormat/CustomNumberFormat';
import CustomRadioButton from 'components/CustomRadioButton/CustomRadioButton';

const ResidentStatus = ({
  control,
  errors,
  residentStatusList,
  setError,
  setValue,
  clearErrors,
  prefixName,
  couserIndex,
}) => {
  const [watchResidentStatus, watchResidentYear, watchResidentMonth, watchdob] = useWatch({
    control,
    name: [
      `${prefixName}.residentStatus`,
      `${prefixName}.residentYears`,
      `${prefixName}.residentMonths`,
      `${prefixName}.dob`,
    ],
  });

  useEffect(() => {
    if (
      watchResidentStatus &&
      watchResidentStatus !== 'Rent' &&
      watchResidentStatus !== 'Homeowner'
    ) {
      setValue(`${prefixName}.monthlyPayment`, '');
      clearErrors(`${prefixName}.monthlyPayment`);
    }
  }, [watchResidentStatus]);
  useEffect(() => {
    const dateofBirth = watchdob;
    if (dateofBirth) {
      let a = new Date().getFullYear() * 12 + new Date().getMonth();
      let b = new Date(dateofBirth).getFullYear() * 12 + new Date(dateofBirth).getMonth();
      let c = a - b;
      let ageYears = c / 12;
      let ageMonth = c % 12;
      if (
        parseInt(watchResidentYear) > parseInt(ageYears) ||
        (parseInt(watchResidentYear) === parseInt(ageYears) &&
          parseInt(watchResidentMonth) > parseInt(ageMonth))
      ) {
        setError(`${prefixName}.residenceTimeErr`, {
          type: 'custom',
          message: 'Time at residence cannot be greater than the age of the applicant',
        });
      } else {
        clearErrors(`${prefixName}.residenceTimeErr`);
      }
    }
  }, [watchResidentYear, watchResidentMonth, watchdob]);

  return (
    <div>
      <CustomCollapse heading="Resident status" open={true} errors={errors}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomRadioButton
              control={control}
              radioValues={residentStatusList}
              label="Resident status"
              required
              name={`${prefixName}.residentStatus`}
              errors={!!errors?.cousers?.[couserIndex]?.residentStatus}
              errorText={errors?.cousers?.[couserIndex]?.residentStatus?.message}
            />
          </Grid>
          {/* //Alquiler for rent */}
          {(watchResidentStatus === 'Rent' || watchResidentStatus === 'Homeowner') && (
            <Grid item lg={6} md={6} sm={12} xs={12} mr={1}>
              <CustomNumberFormat
                name={`${prefixName}.monthlyPayment`}
                id={`${prefixName}.monthlyPayment`}
                dataTestId="monthlyPayment"
                label="Monthly payment"
                decimalScale={2}
                control={control}
                error={!!errors?.cousers?.[couserIndex]?.monthlyPayment}
                errorText={errors?.cousers?.[couserIndex]?.monthlyPayment?.message}
                thousandSeparator={true}
                prefix={'$ '}
                required={watchResidentStatus === 'Rent' || watchResidentStatus === 'Homeowner'}
              />
            </Grid>
          )}
          <Grid item lg={7.5} md={7.5} sm={7.5} xs={12} container alignItems="flex-start">
            <InputLabel>
              <span style={{ color: 'red' }}>*&nbsp;</span>Time at residence
            </InputLabel>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              spacing={0.5}
              alignItems="flex-start"
            >
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                display="flex"
                alignItems="center"
                sx={{
                  gap: '16px',
                }}
              >
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center">
                    <CustomNumberFormat
                      name={`${prefixName}.residentYears`}
                      id={`${prefixName}.residentYears`}
                      dataTestId="residentYears"
                      placeholder="0"
                      control={control}
                      error={!!errors?.cousers?.[couserIndex]?.residentYears}
                      maxLength={2}
                      noLabel="Years"
                    />

                    <Typography variant="subtitle2" component="p" pl={0.5}>
                      Years
                    </Typography>
                  </Box>
                  {!!errors?.cousers?.[couserIndex]?.residentYears && (
                    <FormHelperText gap={2} className={globalClasses.customSelectError}>
                      {' '}
                      {errors?.cousers?.[couserIndex]?.residentYears?.message}
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                display="flex"
                alignItems="center"
                sx={{
                  gap: '16px',
                }}
              >
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center">
                    <CustomNumberFormat
                      name={`${prefixName}.residentMonths`}
                      id={`${prefixName}.residentMonths`}
                      dataTestId="residentMonths"
                      placeholder="0"
                      control={control}
                      error={!!errors?.cousers?.[couserIndex]?.residentMonths}
                      maxLength={2}
                      noLabel="Months"
                    />
                    <Typography variant="subtitle2" component="p" pl={0.5}>
                      Months
                    </Typography>
                  </Box>
                  {!!errors?.cousers?.[couserIndex]?.residentMonths && (
                    <FormHelperText gap={2} className={globalClasses.customSelectError}>
                      {' '}
                      {errors?.cousers?.[couserIndex]?.residentMonths?.message}
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
            </Grid>
            <InputLabel>
              <span style={{ color: '#d32f2f' }}>
                {' '}
                {errors?.cousers?.[couserIndex]?.residenceTimeErr?.message}
              </span>
            </InputLabel>
          </Grid>
        </Grid>
      </CustomCollapse>
    </div>
  );
};
export default ResidentStatus;

ResidentStatus.propTypes = {
  control: PropTypes.instanceOf(Object),
  setValue: PropTypes.func,
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
  errors: PropTypes.instanceOf(Object),
  residentStatusList: PropTypes.instanceOf(Object),
  prefixName: PropTypes.string,
  couserIndex: PropTypes.number,
};
ResidentStatus.defaultProps = {
  control: {},
  errors: {},
  setValue: defaultFn,
  setError: defaultFn,
  clearErrors: defaultFn,
  residentStatusList: [],
  prefixName: '',
  couserIndex: 0,
};
