import * as yup from 'yup';

export const lookupSchema = (program, createAppFlag) => {
  console.log('creteapp', createAppFlag);
  return yup
    .object()
    .shape({
      collateralType: yup.string().required('Collateral type is empty'),
      valueGuide: yup.string().required('value guide required'),
      vin: yup.string().when('lookuptype', {
        is: 'vin',
        then: yup
          .string()
          .required('* Mandatory field')
          .test('len', 'VIN should be 17 characters in length', (val) =>
            val ? val?.length === 17 : true
          )
          .matches(
            /^[A-HJ-NPR-Za-hj-npr-z0-9]*$/,
            "VIN shouldn't contain I, O, Q, space or special characters"
          ),
      }),
      year: yup
        .string()
        .required('Year required')
        .when('collateralType', {
          is: (val) => val === 'used' && program === 'Conventional' && createAppFlag,
          then: yup
            .string()
            .test(
              '',
              'Please select a vehicle which is 10 years old or less for a Conventional loan type',
              (val) => {
                let Age = new Date().getFullYear() - parseInt(val);
                const vehicleAge = Age === 0 ? 'current' : Age?.toString();

                return parseInt(vehicleAge) <= 10 || vehicleAge === 'current';
              }
            ),
        })
        .when('collateralType', {
          is: (val) => val === 'used' && program === 'Oriental A+' && createAppFlag,
          then: yup
            .string()

            .test(
              '',
              'Please select a vehicle which is 4 years old or less for an Oriental A+ loan type',
              (val) => {
                let Age = new Date().getFullYear() - parseInt(val);
                const vehicleAge = Age === 0 ? 'current' : Age?.toString();

                return parseInt(vehicleAge) <= 4 || vehicleAge === 'current';
              }
            ),
        }),

      make: yup.string().required('Make required'),

      model: yup.string().required('Model required'),

      subModel: yup.string().required('Sub model required'),

      style: yup.string().when('valueGuide', {
        is: 'Blackbook',
        then: yup.string().required('Style required'),
      }),

      mileage: yup
        .string()
        .transform((value) => (value ? value : '0'))
        .matches(/^\d*$/g, 'Only numeric values are accepted for mileage')

        .test('validate mileage', 'Please enter a valid mileage', (val) => !isNaN(val) && +val > 0),
      otherwise: yup.string(),
    })
    .required();
};
