import { Box, Checkbox, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import classes from 'styles/globalStyle.module.scss';
import { defaultFn, numberToCurrency } from 'utils/utility';

const Residuals = ({
  year,
  make,
  model,
  series,
  style,
  addDeductList,
  total,
  selectedRows,
  handleCheckboxChange,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="caption" fontSize="14px!important">
        Select options for {year} {make} {model} {series} {style} - Spec{' '}
      </Typography>
      <Box display="flex" flexDirection="column" className={classes.tableOutline} role="table">
        <Box
          display="flex"
          gap={2}
          sx={{ backgroundColor: '#F2F2F2' }}
          p={2}
          className={classes.borderTable}
          role="row"
        >
          <Typography
            sx={{ flexGrow: 1, fontWeight: '700', marginLeft: '35px' }}
            role="columnheader"
          >
            Description
          </Typography>

          <Typography
            sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
            role="columnheader"
          >
            12 Month
          </Typography>
          <Typography
            sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
            role="columnheader"
          >
            24 Month
          </Typography>
          <Typography
            role="columnheader"
            sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
          >
            36 Month
          </Typography>
          <Typography
            role="columnheader"
            sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
          >
            48 Month
          </Typography>
          <Typography
            role="columnheader"
            sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
          >
            60 Month
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" className={classes.childBorderLine}>
          {addDeductList?.map?.((x) => {
            const { description } = x;
            const partObj = (({
              residual_12,
              residual_24,
              residual_36,
              residual_48,
              residual_60,
            }) => ({
              residual_12,
              residual_24,
              residual_36,
              residual_48,
              residual_60,
            }))(x);
            return (
              <Box display="flex" gap={2} key={description} p={2} role="row">
                <Box display="flex" alignItems="center" flexGrow="1" gap={1}>
                  <Checkbox
                    color="secondary"
                    checked={selectedRows.find((el) => el.description === description)}
                    onChange={(event) => handleCheckboxChange(event, x)}
                  />
                  <Typography sx={{ width: '30%' }} role="cell">
                    {description}
                  </Typography>
                </Box>
                {Object.keys(partObj).map((eachKey) => (
                  <Typography
                    key={eachKey}
                    sx={{
                      width: '10%',
                      overflowWrap: 'break-word',
                    }}
                    role="cell"
                  >
                    {numberToCurrency(x[eachKey])}
                  </Typography>
                ))}
              </Box>
            );
          })}
        </Box>

        <Box
          display="flex"
          gap={2}
          sx={{ backgroundColor: '#F2F2F2' }}
          p={2}
          className={classes.borderTable}
          id="total-residual-adjustment"
          role="row"
        >
          <Typography
            role="cell"
            sx={{ width: '30%', fontWeight: '700', marginLeft: '35px', flexGrow: '1' }}
          >
            Total equipment adjustment
          </Typography>
          {Object.keys(total).map((eachKey) => (
            <Typography
              role="cell"
              sx={{
                width: '10%',
                overflowWrap: 'break-word',
                fontWeight: '700',
              }}
              key={eachKey}
            >
              {numberToCurrency(total[eachKey])}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Residuals;

Residuals.propTypes = {
  year: PropTypes.string,
  make: PropTypes.string,
  model: PropTypes.string,
  series: PropTypes.string,
  style: PropTypes.string,
  total: PropTypes.instanceOf(Object),
  addDeductList: PropTypes.instanceOf(Object),
  selectedRows: PropTypes.instanceOf(Object),
  handleCheckboxChange: PropTypes.func,
};
Residuals.defaultProps = {
  year: '',
  make: '',
  model: '',
  series: '',
  style: '',
  total: {},
  addDeductList: [],
  selectedRows: [],
  handleCheckboxChange: defaultFn,
};
