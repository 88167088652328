import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import styledclass from 'styles/globalStyle.module.scss';
import { numberToCurrency } from 'utils/utility';

export const FinancingDetails = ({ monthlyPayData, calculatedData }) => {
  console.log({ financingDetails: calculatedData, monthlyPayData });
  const paymentSchedule = monthlyPayData?.paymentSchedules || monthlyPayData?.paymentSchedule;
  return (
    <>
      <TableContainer>
        <Table aria-label="financing-info-table" id="financing-info">
          <TableBody>
            <TableRow>
              <TableCell classes={{ root: styledclass.tableHeadingList }}>Financed fees</TableCell>
              <TableCell classes={{ root: styledclass.currencyValue }}>
                {numberToCurrency(monthlyPayData?.financedFees)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell classes={{ root: styledclass.tableHeadingList }}>
                Registration fees
              </TableCell>
              <TableCell classes={{ root: styledclass.currencyValue }}>
                {numberToCurrency(monthlyPayData?.registrationFees)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell classes={{ root: styledclass.tableHeadingList }}>
                Amount financed
              </TableCell>
              <TableCell classes={{ root: styledclass.currencyValue }}>
                {numberToCurrency(monthlyPayData?.amountFinanced)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell classes={{ root: styledclass.tableHeadingList }}>
                Balance amount finance
              </TableCell>
              <TableCell classes={{ root: styledclass.currencyValue }}>
                {monthlyPayData?.balance
                  ? numberToCurrency(monthlyPayData?.balance)
                  : numberToCurrency(calculatedData?.calculatedData?.balanceAmountFinance)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell classes={{ root: styledclass.tableHeadingList }}>Net trade in</TableCell>
              <TableCell classes={{ root: styledclass.currencyValue }}>
                {numberToCurrency(monthlyPayData?.netTradeIn)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell classes={{ root: styledclass.tableHeadingList }}>Finance charge</TableCell>
              <TableCell classes={{ root: styledclass.currencyValue }}>
                {numberToCurrency(monthlyPayData?.financeCharge)}
              </TableCell>
            </TableRow>
            {calculatedData?.formData?.programVal !== 'Conventional' && (
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Balloon payment
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {monthlyPayData?.baloonPayment
                    ? numberToCurrency(monthlyPayData?.baloonPayment)
                    : numberToCurrency(calculatedData?.calculatedData?.baloonPayment)}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell classes={{ root: styledclass.tableHeadingList }}>
                Non-financed fees
              </TableCell>
              <TableCell classes={{ root: styledclass.currencyValue }}>
                {numberToCurrency(monthlyPayData?.nonFinancedFees)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell classes={{ root: styledclass.tableHeadingList }}>APR</TableCell>
              <TableCell classes={{ root: styledclass.currencyValue }}>
                {calculatedData?.formData?.rate} %
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={5} id="payment-schedule-info">
        <Typography variant="h6" my={2} sx={{ fontWeight: 700 }}>
          Payment schedule
        </Typography>
        <Table aria-label="simple table" className={styledclass.tableContainer}>
          <TableBody>
            <TableRow>
              <TableCell classes={{ root: styledclass.TableHeading }}>No. of payments</TableCell>
              <TableCell classes={{ root: styledclass.TableHeading }}>Payment amount</TableCell>
              <TableCell classes={{ root: styledclass.TableHeading }}>Start date</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            {paymentSchedule?.map((row) => (
              <TableRow key={`${row?.noOfPayments}-${row.paymentAmount}`}>
                <TableCell sx={{ fontSize: '14px!important' }}>{row?.noOfPayments}</TableCell>
                <TableCell sx={{ fontSize: '14px!important' }}>
                  {row?.paymentAmount
                    ? numberToCurrency(row?.paymentAmount?.toFixed?.(2))
                    : numberToCurrency((+row?.amount)?.toFixed?.(2))}
                </TableCell>
                <TableCell sx={{ fontSize: '14px!important' }}>
                  {moment(row?.startDate).format('MM/DD/yyyy')}
                </TableCell>
              </TableRow>
            )) || (
              <TableRow>
                <TableCell sx={{ fontSize: '14px!important' }}>--</TableCell>
                <TableCell sx={{ fontSize: '14px!important' }}>--</TableCell>
                <TableCell sx={{ fontSize: '14px!important' }}>--</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default FinancingDetails;

FinancingDetails.propTypes = {
  monthlyPayData: PropTypes.instanceOf(Object),
  calculatedData: PropTypes.instanceOf(Object),
};
FinancingDetails.defaultProps = {
  calculatedData: {},
  monthlyPayData: {},
};
