import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import CloseIconHeading from 'assets/svg/closeIconHeading.svg';
import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';
import { AdvanceSearchSchema } from 'schemaValidations/AdvanceSearchSchema';
import classes from 'styles/header.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn } from 'utils/utility';

import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomPhoneNumber from 'components/CustomPhoneNumber/CustomPhoneNumber';
import { SSN } from 'components/SSN';

const AdvanceSearch = ({ toggleDrawer, setLoader, setApplicationData, setEmptyDataMessage }) => {
  const {
    control,
    watch,
    formState: { errors },
    setValue,
    reset,
    trigger,
    clearErrors,
    handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues: {
      appId: '',
      firstName: '',
      lastName: '',
      secondLastName: '',
      ssn: '',
      mobile: '',
    },
    resolver: yupResolver(AdvanceSearchSchema),
  });
  const ApiClient = new ApiClass();

  const [watchSSN] = useWatch({
    control,
    name: ['ssn'],
  });
  const watchAll = watch();
  const handleSearch = async (data) => {
    const { appId, firstName, lastName, secondLastName, ssn, mobile } = data;

    const mob = mobile ? `&phoneSearchTxt=${mobile}` : '';
    try {
      setLoader(true);
      const resp = await ApiClient.get(
        `${UrlHelpers.fetchApplications}?frequency=3_MONTH&appStatus=SUBMITTED${
          appId ? `&remoteRefSearchTxt=${appId}` : ''
        }${firstName ? `&fNameSearchTxt=${firstName}` : ''}${
          lastName ? `&lNameSearchTxt=${lastName}` : ''
        }${secondLastName ? `&slNameSearchTxt=${secondLastName}` : ''}${
          ssn ? `&ssnSearchTxt=${ssn?.replaceAll('-', '')}` : ''
        }${mob}`,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (resp) {
        setApplicationData(resp?.applicationList);
        if (resp.applicationList.length === 0) {
          setEmptyDataMessage('No record/s found');
        }
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
    toggleDrawer('search', false);
  };
  const handleKeyDownSSN = (value) => {
    setValue('ssn', value);
    if (value.match(/^\d/)) {
      clearErrors('ssn');
    }
    trigger(`ssn`);
  };
  console.log({ errors });
  const clearSearch = () => {
    reset();
    toggleDrawer('search', false);
  };

  return (
    <>
      <Grid container>
        <Box className={classes.settingHeading}>
          <Typography variant="h2" color="default">
            Advanced search
          </Typography>
          <Typography
            variant="h2"
            color="default"
            data-testid="advanceSearchClose"
            onClick={clearSearch}
          >
            <img src={CloseIconHeading} />
          </Typography>
        </Box>
      </Grid>
      <Grid container>
        <Grid item lg={12} md={12} xs={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.appId}
            errorText={errors?.appId?.message}
            placeholder="Enter app id"
            label="App ID"
            name="appId"
            maxLength="9"
            regex={/^\d*$/}
            flexDirection="column"
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.firstName}
            errorText={errors?.firstName?.message}
            placeholder="Enter first name"
            label="First name"
            name="firstName"
            maxLength="15"
            regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡\s]$/}
            flexDirection="column"
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.lastName}
            errorText={errors?.lastName?.message}
            placeholder="Enter last name"
            label="Last name"
            name="lastName"
            maxLength="25"
            regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡\s]$/}
            flexDirection="column"
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.secondLastName}
            errorText={errors?.secondLastName?.message}
            maxLength="25"
            regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡.'\s]$/}
            placeholder="Enter second last name"
            label="Second last name"
            name="secondLastName"
            flexDirection="column"
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <SSN handleKeyDown={handleKeyDownSSN} ssn={watchSSN} errors={errors} flow="dashboard" />
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <CustomPhoneNumber
            name="mobile"
            label="Mobile"
            flexDirection="column"
            placeholder="Enter mobile"
            control={control}
            error={!!errors?.mobile}
            errorText={errors?.mobile?.message}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', margin: '20px 0' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}>
          <Button
            sx={{ width: '60px', height: '30px' }}
            color="secondary"
            variant="outlined"
            size="small"
            onClick={clearSearch}
          >
            Cancel
          </Button>
          <Button
            sx={{ width: '60px', height: '30px' }}
            color="secondary"
            variant="contained"
            size="small"
            data-testid="search"
            onClick={handleSubmit(handleSearch)}
            disabled={!Object.keys(watchAll).length}
          >
            Search
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default AdvanceSearch;

AdvanceSearch.propTypes = {
  toggleDrawer: PropTypes.func,
  setLoader: PropTypes.func,
  setApplicationData: PropTypes.func,
  setEmptyDataMessage: PropTypes.func,
};

AdvanceSearch.defaultProps = {
  toggleDrawer: defaultFn,
  setLoader: defaultFn,
  setApplicationData: defaultFn,
  setEmptyDataMessage: defaultFn,
};
