/* eslint-disable no-unused-vars */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import ApiClass from 'Api/ApiClient';
import { generateFileMD5 } from 'Services/commonComponentsApi';
import axios from 'axios';
import { Buffer } from 'buffer';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn } from 'utils/utility';

import BootstrapDialogTitle, { BootstrapDialog } from 'components/CustomDialogBox/CustomDialogBox';
import CustomFileUploader from 'components/CustomFileUploader/CustomFileUploader';
import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

const DocumentAttachDialog = ({
  setOpen,
  open,
  wholeDocs,
  docMasterList,
  appId,
  genUrlList,
  setGenUrlList,
  callBack,
  generatePreSignedUrls,
}) => {
  console.log('genUrlList', genUrlList);
  const ApiClient = new ApiClass();

  const fileTypes = ['PDF'];
  const [apiErr, setApiErr] = useState('');
  const { userID, fullName } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const [retryFlag, setRetryFlag] = useState(false);
  const [allDocs, setAllDocs] = useState([]);
  const dropdownRefs = useRef([]);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValue: {
      attachedFile: '',
      documentType: '',
    },
  });

  const watchFile = watch('attachedFile');

  console.log('watchFile', watchFile);

  const handleClose = async () => {
    setOpen(false);
  };

  const handleChange = (filee) => {
    clearErrors('attachedFile');
    setApiErr('');
    const multiFiles = Object.values(filee);
    console.log('multiFiles', multiFiles);

    const totaluploadedFiles = watchFile?.length > 0 ? [...watchFile, ...multiFiles] : multiFiles;
    if (hasDuplicates(totaluploadedFiles)) {
      setError('attachedFile', {
        type: 'custom',
        message: 'Duplicates exist in the uploaded list',
      });
    } else {
      let visibleDocs = docMasterList
        ?.filter((x) => x.visible)
        ?.map((each) => wholeDocs?.find((x) => x.id === each.id));

      if (retryFlag) {
        let fileArr =
          multiFiles?.map((x) => ({ fileName: x?.name, file: x, documentType: '1' })) || [];
        setAllDocs([...allDocs, ...fileArr]);
      } else {
        setValue('attachedFile', totaluploadedFiles);
      }
    }
  };

  const hasDuplicates = (files) => {
    return (
      files.filter((file, index) => files.findIndex((x) => x.name === file.name) !== index).length >
      0
    );
  };

  const removeFile = (removeFileObj) => {
    if (apiErr) {
      setApiErr('');
    }
    const removedList = watchFile.filter((x) => x?.name !== removeFileObj?.name);
    console.log('removedList', removedList);
    setValue('attachedFile', removedList);
    let urlMapping = [...genUrlList];
    let removeFileIndex = urlMapping.findIndex((x) => x?.fileName === removeFileObj?.name);
    if (removeFileIndex > -1) {
      urlMapping[removeFileIndex].fileName = '';
    }
    setGenUrlList(urlMapping);
  };
  console.log('allDocs----state', allDocs);
  const attachSecondTry = async () => {
    if (allDocs?.filter((x) => x.documentType)?.length === allDocs?.length) {
      let { isSuccess, allDocsFinal } = await uploadToS3(true);
      if (isSuccess) {
        const typeMissed = allDocsFinal?.filter(
          (x) => x?.message === 'Unable to recognize the document type'
        );
        const mapNameandType = typeMissed?.map((x) => `${x?.docId}:${x?.documentType}`);
        const allDocsmapping = allDocsFinal?.map((x) => {
          return {
            docId: x.docId,
            docName: x.fileName,
          };
        });
        try {
          setLoader(true);
          const response = await ApiClient.post(
            `${UrlHelpers.getDocuments}?documentType=${mapNameandType.join(
              ','
            )}&applicationId=${appId}`,
            allDocsmapping,
            {
              headers: {
                'Content-Type': 'application/json',
                userid: userID,
                userName: fullName,
              },
            }
          );
          console.log('response', response);
          setOpen(false);
          callBack();
        } catch (e) {
          console.log('e', e);

          if (typeof e?.response?.data === 'object') {
            console.log('doc status', e?.response?.data);
            await handleAttachErr(e?.response?.data);
          } else {
            setApiErr(
              e?.response?.data ||
                'We are unable to proceed due to a technical issue, please try again later.'
            );
          }
        } finally {
          setLoader(false);
        }
      }
    } else {
      const firstMissingType = allDocs?.findIndex((x) => !x.documentType);
      console.log('first missing type', firstMissingType, dropdownRefs);
      setTimeout(() => {
        dropdownRefs.current[firstMissingType]?.focus();
      }, 0);

      setApiErr(
        'Please select the document below from the drop-down values for corresponding document/s to proceed'
      );
    }
  };

  const handleContinue = async () => {
    if (retryFlag) {
      await attachSecondTry();
    } else {
      // const formData = new FormData();
      // watchFile?.forEach((x) => formData.append('content', x));
      const isSuccess = await uploadToS3();
      if (isSuccess) {
        let fileExistArray = genUrlList
          ?.filter((x) => x.fileName)
          ?.map((y) => {
            return {
              docId: y.uuid,
              docName: y.fileName,
            };
          });
        try {
          setLoader(true);
          const response = await ApiClient.post(
            `${UrlHelpers.getDocuments}?applicationId=${appId}`,
            fileExistArray,
            {
              headers: {
                'Content-Type': 'application/json',
                userid: userID,
                userName: fullName,
              },
            }
          );
          console.log('response', response);
          setOpen(false);
          callBack();
        } catch (e) {
          console.log('e', e);

          if (typeof e?.response?.data === 'object' && e?.response?.status === 400) {
            console.log('doc status', e?.response?.data);
            await handleAttachErr(e?.response?.data);
          } else {
            setApiErr(
              e?.response?.data?.message ||
                e?.response?.data ||
                'We are unable to proceed due to a technical issue, please try again later.'
            );
          }
        } finally {
          setLoader(false);
        }
      }
    }
  };

  const uploadToS3 = async (retryFlag = false) => {
    const totaluploadedFiles = retryFlag ? allDocs : watchFile || [];
    let finalList = [];
    let visibleDocs = docMasterList
      ?.filter((x) => x.visible)
      ?.map((each) => wholeDocs?.find((x) => x.id === each.id));
    if (totaluploadedFiles?.length > genUrlList?.length) {
      setApiErr(
        'Number of files exceeded, please try removing the redundant documents and try again'
      );
      return false;
    } else {
      setApiErr('');
      setLoader(true);
      let urlmappingwithFile = genUrlList.filter((x) => x?.fileName);
      let urlMappingwoFile = genUrlList.filter((x) => !x?.fileName);
      console.log('1.urlmappingwithFile', urlmappingwithFile);
      console.log('2.watchFile', watchFile);
      let checkaddedFiles = [];
      if (retryFlag) {
        checkaddedFiles = allDocs.filter(
          (x) => !urlmappingwithFile.find((y) => x.fileName === y.fileName)
        );
      } else {
        checkaddedFiles = watchFile.filter(
          (x) => !urlmappingwithFile.find((y) => x.name === y.fileName)
        );
      }
      console.log('checkaddedFiles', checkaddedFiles);
      for (let i = 0; i < checkaddedFiles?.length; i++) {
        console.log('genUrlList?.[i]', urlMappingwoFile?.[i]);

        const url = urlMappingwoFile?.[i]?.url;
        // const fileMD5 = await generateFileMD5(checkaddedFiles[i]);
        // const fileMd5Base64 = Buffer.from(fileMD5, 'hex').toString('base64');
        try {
          let response = await axios.put(
            url,
            // `${url}&X-Amz-MetaData=${watchFile[i]?.name}`,
            checkaddedFiles[i],
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          if (retryFlag) urlMappingwoFile[i].fileName = checkaddedFiles[i]?.fileName;
          else urlMappingwoFile[i].fileName = checkaddedFiles[i]?.name;
          console.log('resp', response);
        } catch (e) {
          console.log('e', e);
        }
      }
      setGenUrlList([...urlmappingwithFile, ...urlMappingwoFile]);
      finalList = [...urlmappingwithFile, ...urlMappingwoFile];
      console.log('urlMappingwoFile', urlMappingwoFile);
      if (retryFlag) {
        let wholeCopy = [...allDocs];
        const withid = wholeCopy.filter((x) => x.docId);
        let withoutid = wholeCopy.filter((x) => !x.docId);
        withoutid = withoutid.map((x) => {
          let filematch = urlMappingwoFile.find((y) => y.fileName === x.fileName);
          if (x?.fileName === filematch?.fileName) {
            return {
              ...x,
              docId: filematch.uuid,
            };
          }
        });
        if (withoutid?.length) {
          console.log('combined', [...withid, ...withoutid]);
          setAllDocs([...withid, ...withoutid]);
        }
        finalList = [...withid, ...withoutid];
      }
      setLoader(false);
      return { isSuccess: true, allDocsFinal: finalList };
    }
    // try {
    //   setLoader(true);
    //   await axios.put(url, watchFile[0], {
    //     headers: {
    //       'Content-Type': 'application/pdf',
    //       'Content-Length': watchFile[0].length,
    //     },
    //   });
    //   // await fetch(
    //   //   'https://digital-auto-utility-data-sandbox.s3.us-east-1.amazonaws.com/put-access-test/07d2f7fd-4c40-47cf-ad00-df8fc91c69ca.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECwaCXVzLWVhc3QtMSJHMEUCIFzfTyyF6La6ZkarkQiPnpQuurm7%2BrYWWecutmh9FJqhAiEAojfh2PXTUMPNTjrfFDkmSDkc3vizviTe%2FwSEGm%2BEJG0q8gMIRBABGgwwNzE2NzQ0MjA1NTAiDE%2B%2B1QTG15lyRtZ5BSrPAyCSXDo%2B1yg1%2FjD71uuGtORt4J%2BIDd1TiUK3EXOtgpbKMht0ad8KtGlp0mAiXz6tXm17Erk%2Fp3oh3BRJ4PDDty47YcQJUz%2F1lXAecYJDj9eos%2FqhevMG88v8b6dWtuNk0xnp20uIVnwKmaO%2Bk%2BwXz8hgoEG%2Bmdn7RzsaQykzICoe5tGlOGBbbVyiUlwc0fFNc3iab1ps%2BFkV30P9KA2O47tpOKqlprZU1nmKcyA4HjjepMpe%2BFiJyjZmFNV164bMQgXwHOnRLGP%2FNos2Z8pM3SCxKg6KBZ5qDd52kD3dgKOQgwKmYaH0b%2FfQBeyRchOSGoN70ZQF0FEyBHjIa23dLPcdjiyMuVCK1g6QMy3sjrAaAO2r283AOrVCt8ue%2FXkuMUeKxE3GMqZGcKOBD79qqG8dB05A7YEEUEnq%2FthIsFQPJ0zTeM2BQZmd1Z7%2BIHFz7A5G9XvuA6pAlGq4AlFD8lSEsXFg%2BAmmya2ZAMcItUS0sBVeLyVaObuIGKpjfvDI4xnWLt5X5S8A8WqOLPW6E8ggs7oxInt45MvEZJjmdDtUHYrcK8yb6rvtAzoFI8PqmKWKqU0zCU0kbGPA13jOOjDceMnCnJZdF2nwWT44cywwt9H1rwY6lAIJxW%2BXbddn8%2Fuel%2BOZK39zxKkxniXr7LfIayEPWIaW91TLAlv4561muAMSicXLPaB02FfDDOAwaqSyTyI5Ioo0M2GESqny5t0iH%2FHe2XB9faCP7rTVtDRnCaEOBhvOm0uxlcz2%2FuysQy6oVwFzaqlIW%2BtHdxa2DuUkg%2B03B4SN740nPsB7Z2ZgoqIxqYwQcangjp18fWIHAfKChbIq9Mqkyx%2B9dWgtJ0aA1KuQnt2NKnX9yj19ybmUc0NHrFR4t8LBjTbkxWew3Z6KLSjN89YPj%2FKJ0qU%2FYegXr72%2Bw1wTfG%2F8pXkJH2JKoVu8vZim6wJrEDNyUbmURcJy%2FXY1ruFW0uEwoDECrFk83%2FXQ628bhrRwHz0%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240322T111802Z&X-Amz-SignedHeaders=host&X-Amz-Expires=14400&X-Amz-Credential=ASIARBMBA3VDMDIBOF7P%2F20240322%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=6add4ffb146b9efcd32d13ade9d27754ae8c9cd4892bfbd0b209f4f87752e93e',
    //   //   {
    //   //     method: 'PUT',

    //   //     headers: {
    //   //       'Content-Type': 'application/pdf',
    //   //       'Content-Length': watchFile[0].length,
    //   //     },

    //   //     body: watchFile[0],
    //   //   }
    //   // );
    // } catch (e) {
    //   console.log('err in get docs api', e);

    //   setApiErr(
    //     e?.response?.data ||
    //       'We are unable to proceed due to a technical issue, please try again later'
    //   );
    // } finally {
    //   setLoader(false);
    //   window.open(url);
    // }
  };

  const handleAttachErr = async (docList) => {
    setRetryFlag(true);
    let alreadyExist = docList.filter((x) => x.message === 'Duplicate document type found');
    if (alreadyExist?.length > 0) {
      alreadyExist = alreadyExist?.map((x) => x?.fileName);
      setApiErr(`Duplicate document type found ${alreadyExist.join(',')}`);
    }
    const mergedArray = docList.map((x) => {
      const macthingObjIndex = genUrlList.findIndex((y) => y.fileName === x.fileName);
      return {
        ...x,
        file: macthingObjIndex > -1 ? genUrlList[macthingObjIndex] : null,
      };
    });

    setAllDocs(mergedArray);
  };

  const handledocumentType = (e, index) => {
    const allDocss = [...allDocs];
    const getDoc = allDocss[index];
    getDoc.documentType = e?.target?.value;
    setAllDocs(allDocss);
  };

  const removeMSFile = (each) => {
    const allDocsCopy = [...allDocs];
    const fileRemovedCopy = allDocsCopy.filter((x) => x.fileName !== each?.fileName);
    setAllDocs(fileRemovedCopy);
    let urlMapping = [...genUrlList];
    let removeFileIndex = urlMapping.findIndex((x) => x?.fileName === each?.fileName);
    if (removeFileIndex > -1) {
      urlMapping[removeFileIndex].fileName = '';
    }
    setGenUrlList(urlMapping);
  };

  return (
    <>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="h3" component="span">
            Attach document
          </Typography>
          <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
        </BootstrapDialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Loader open={loader} />
          <Box display="flex" flexDirection="column" px={5}>
            <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />

            <CustomFileUploader
              handleChange={handleChange}
              control={control}
              fileTypes={fileTypes}
              setError={setError}
              errors={!!errors?.attachedFile}
              errorText={errors?.attachedFile?.message}
              fileType="Max: 20MB supporting file type: PDF."
              fileTypeErr="Only PDF files are allowed. Click to upload again"
              name="attachedFile"
              isMultiple
            />
          </Box>
          <Box display="flex" gap={0.5} justifyContent="center" alignItems="center" width="100%">
            <Typography component="p" color="red">
              {errors?.attachedFile?.message}
            </Typography>
          </Box>
          <Box display="flex" gap={1} flexWrap="wrap" mt={2}>
            {!retryFlag &&
              watchFile?.map((x) => {
                console.log('file x', x);
                return (
                  <Chip
                    key={x?.name}
                    label={
                      <Box display="flex" alignItems="center">
                        {/* <CheckCircleIcon htmlColor="green" /> */}

                        <Typography variant="" data-testid="filename">
                          {x?.name}
                        </Typography>
                      </Box>
                    }
                    onDelete={() => removeFile(x)}
                    variant="outlined"
                  />
                );
              })}
            {retryFlag &&
              allDocs?.length > 0 &&
              allDocs.map((each, index) => {
                const { isSuccess = false, message = '', documentType = '' } = each || {};
                console.log('documentType', documentType);
                return (
                  <>
                    {message === 'Unable to recognize the document type' && (
                      <Select
                        name="documentType"
                        inputProps={{
                          'data-testid': 'documentType',

                          ref: (element) => {
                            dropdownRefs.current[index] = element;
                          },
                        }}
                        displayEmpty={true}
                        sx={{
                          maxWidth: '50%',
                          alignItems: 'center',
                          marginLeft: '5px',
                          height: '30px',
                        }}
                        onChange={(e) => handledocumentType(e, index)}
                        defaultValue=""
                        value={documentType || ''}
                      >
                        {docMasterList?.map((opt) => {
                          const { displayName, id } = opt;
                          return (
                            <MenuItem key={id} value={id}>
                              {displayName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                    <Chip
                      label={
                        <Box display="flex" alignItems="center">
                          {isSuccess && <CheckCircleIcon htmlColor="green" />}

                          <Typography variant="" data-testid="filename">
                            {each?.fileName}
                          </Typography>
                        </Box>
                      }
                      onDelete={() => removeMSFile(each)}
                      variant="outlined"
                      maxWidth="50%"
                    />
                  </>
                );
              })}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            onClick={handleContinue}
            sx={{ maxHeight: '40px' }}
            disabled={
              (retryFlag && !allDocs?.length) || (!retryFlag && (!watchFile || !watchFile?.length))
            }
          >
            Attach
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
DocumentAttachDialog.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  docMasterList: PropTypes.array,
  appId: PropTypes.string,
  callBack: PropTypes.func,
  setTypeMissedDocs: PropTypes.func,
  setTypeFoundDocs: PropTypes.func,
  wholeDocs: PropTypes.array,
  genUrlList: PropTypes.array,
  setGenUrlList: PropTypes.func,
  generatePreSignedUrls: PropTypes.func,
};
DocumentAttachDialog.defaultProps = {
  open: false,
  setpen: defaultFn,
  docMasterList: [],
  appId: '',
  callBack: defaultFn,
  setTypeMissedDocs: defaultFn,
  setTypeFoundDocs: defaultFn,
  wholeDocs: [],
  genUrlList: [],
  setGenUrlList: defaultFn,
  generatePreSignedUrls: defaultFn,
};

export default DocumentAttachDialog;
