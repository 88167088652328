import ApiClass from 'Api/ApiClient';
import moment from 'moment';
import { UrlHelpers } from 'urlHelpers/urlHelper';

export const userLabel = (userType) => {
  let heading;

  if (userType === '' || userType.toLowerCase() === 'primaryapplicant') {
    heading = 'Applicant';
  }
  if (userType?.toLowerCase() === 'coapplicant1') {
    heading = 'Co-applicant';
  } else if (userType?.toLowerCase() === 'cosigner1') {
    heading = 'Co-signer 1';
  } else if (userType?.toLowerCase() === 'cosigner2') {
    heading = 'Co-signer 2';
  }
  return heading;
};

export const viewApplication = async ({
  setLoader,
  navigate,
  createAppApplicationId,
  updateFlag,
  userID,
  fullName,
}) => {
  const ApiClient = new ApiClass();
  try {
    setLoader(true);
    const resp = await ApiClient.get(
      `${UrlHelpers.getSpecificDetails}?applicationId=${createAppApplicationId}&eventName=viewApplication`,
      {
        headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
      }
    );
    navigate('/applications/viewApp', {
      state: {
        applicationDetails: { ...resp, initAppId: createAppApplicationId },
        tabValue: 1,
        updateFlag,
      },
    });
  } catch (err) {
    console.log('e', err);
  } finally {
    setLoader(false);
  }
};

export const submitApi = async ({
  createAppApplicationId,
  userID,
  setLoader,
  setCaseReferenceId,
  setAppSubmitted,
  setCreateAppFlag,
  setDealer,
  setCreateAppLaunchType,
  setApiErr,
  fullName,
}) => {
  let resp;
  const ApiClient = new ApiClass();
  const formData = {
    consentSubmit: true,
    consentSubmittedTimeStamp: moment().format(),
  };

  let reviewSubmitPayload = {
    event: 'ReviewAndSubmit',
    application: {
      products: {
        productType: 'Indirect Loan Account',
        productName: 'AutoLoanManual',
      },

      applicationId: createAppApplicationId,

      userId: userID,
      source: {
        language: 'EN',
        location: 'PR',
      },
      consents: { ...formData },
    },
  };

  try {
    const endpoint = UrlHelpers.createApiSubmit;
    setLoader(true);
    resp = await ApiClient.post(
      endpoint,
      {
        ...reviewSubmitPayload,
      },
      {
        headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
      }
    );
    if (resp) {
      console.log('submitted successfully', resp);
      setCaseReferenceId(resp?.CaseReferenceId);
      setAppSubmitted(true);
      setCreateAppFlag(false);
      setDealer('');
      setCreateAppLaunchType('');
    }
  } catch (e) {
    if (e?.response?.status === 400) {
      setApiErr(e?.response?.data);
    } else {
      if (e?.response?.data?.responseCode === 1020) {
        setApiErr(
          "Application submission isn't successful due to a validation error / s.Please reach out to the bank."
        );
      } else if (e?.response?.responseCode === 1025) {
        setApiErr('Dealer not found in ECM. Please contact dealer support');
      } else {
        setApiErr(
          'Due to a technical issue, we are unable to fetch the decision. Please check the decision status in sometime.'
        );
      }
    }
  } finally {
    setLoader(false);
  }
  return resp;
};
