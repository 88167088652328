import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, IconButton, Link, Typography } from '@mui/material';
import CloseIconHeading from 'assets/svg/closeIconHeading.svg';
import DismissAllIcon from 'assets/svg/dismissAllIcon.svg';
import Notification from 'assets/svg/notification.svg';
import OrangeDotIcon from 'assets/svg/orangeDotIcon.svg';
import moment from 'moment';
import PropTypes from 'prop-types';
import classes from 'styles/header.module.scss';
import { defaultFn, notificationEventMapping } from 'utils/utility';

const HomeNotification = ({
  toggleDrawer,
  notificationList,
  setNotificationList,
  callBack,
  openNotification,
  onNotifyClose,
}) => {
  return (
    <>
      <Grid container>
        <Box className={classes.settingHeading}>
          <Typography variant="h2" color="default">
            Notifications
          </Typography>
          <Typography
            variant="h2"
            color="default"
            data-testid="notificationClose"
            tabIndex={0}
            aria-label="close-notification"
            sx={{ cursor: 'pointer' }}
            onClick={async () => {
              toggleDrawer('notification', false);
              await callBack();
            }}
          >
            <img src={CloseIconHeading} />
          </Typography>
        </Box>
      </Grid>
      <Box className={classes.notificationBox}>
        {!!notificationList?.length &&
          notificationList?.map((each) => {
            return (
              <>
                <Box className={classes.notificationCard} key={each.notificationId}>
                  <Box className={classes.NotificationTopPanel}>
                    <Box display="flex">
                      {each?.status === 1 && (
                        <div>
                          <img src={OrangeDotIcon} />
                        </div>
                      )}
                      <Typography
                        variant="subtitle2"
                        className={classes.notificationHeading}
                        px={1.2}
                      >
                        {notificationEventMapping[each?.eventName]}
                      </Typography>
                    </Box>

                    <IconButton
                      onClick={() => onNotifyClose(each?.notificationId)}
                      data-testid="notification-close"
                      aria-label="notification-close"
                      id={`${each.notificationId}-notify-close`}
                      sx={{ width: '8px', height: '9px' }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box
                    className={classes.notificationBody}
                    tabIndex={0}
                    onClick={async () => {
                      toggleDrawer('notification', false);
                      await openNotification({ notificationObj: each });
                      await callBack();
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Typography variant="subtitle2">{each?.displayText}</Typography>
                    <Typography className={classes.notificationTime}>
                      {moment(each?.createdDateTime).fromNow()}
                    </Typography>
                  </Box>
                </Box>
              </>
            );
          })}

        {!!notificationList?.length && (
          <Box
            style={{
              position: 'sticky',
              bottom: 0,
              background: 'white',
              padding: '20px',
              width: '100%',
            }}
          >
            <Link
              color="secondary"
              underline="none"
              variant="outlined"
              onClick={async () => {
                setNotificationList([]);
                await onNotifyClose('');
              }}
              data-testid="dismissAllButton"
              sx={{ float: 'right', margin: '20px 0', cursor: 'pointer' }}
              tabIndex={0}
            >
              <img src={DismissAllIcon} /> &nbsp; Dismiss all
            </Link>
          </Box>
        )}

        {!notificationList?.length && (
          <>
            <Box className={classes.noNotificationContainer}>
              <img src={Notification} />
              <Typography variant="h5" className={classes.noNotificationHeading}>
                No notifications yet
              </Typography>
              <Typography variant="subtitle1" className={classes.noNotificationBody}>
                When you get notifications, they’ll show up here
              </Typography>
              <Button variant="contained" color="secondary" onClick={callBack}>
                Refresh
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
export default HomeNotification;

HomeNotification.propTypes = {
  toggleDrawer: PropTypes.func,
  rows: PropTypes.array,
  notificationList: PropTypes.array,
  setNotificationList: PropTypes.func,
  callBack: PropTypes.func,
  openNotification: PropTypes.func,
  onNotifyClose: PropTypes.func,
};

HomeNotification.defaultProps = {
  toggleDrawer: defaultFn,
  rows: [],
  notificationList: [],
  setNotificationList: defaultFn,
  callBack: defaultFn,
  openNotification: defaultFn,
  onNotifyClose: defaultFn,
};
