/* eslint-disable react/prop-types */
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Alert, Box, Button, Grid, Typography } from '@mui/material';
import IdentityImage from 'assets/svg/Identity.svg';
import MainApplicationContext from 'context/MainApplicationContext';
import { useContext, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import customClasses from 'styles/InformationRequest.module.scss';

import CameraUpload from 'components/CameraUpload/CameraUpload';
import FileUploadDialog from 'components/FileUploadDialog/FileUploadDialog';

const IdVerification = ({
  control,
  errors,
  setValue,
  setError,
  prefixName,
  showIDCamera,
  setShowIDCamera,
  clearErrors,
  createAppApplicationId,
  couserIndex,
  tabvalue,
  savedApplicantIds,
  initApplicantIds,
}) => {
  const [openFileUpload, setOpenFileUpload] = useState(false);
  const [verifyAlert, setVerifyAlert] = useState(false);
  const { viewAppFlow, unfilled, reviewFlag } = useContext(MainApplicationContext);
  const navigate = useNavigate();

  const [manualFile, idVerificationStatus, cousers, userType] = useWatch({
    control,
    name: [
      `${prefixName}.manualFile`,
      `${prefixName}.idVerificationStatus`,
      `cousers`,
      `${prefixName}.userType`,
    ],
  });

  console.log('cousers', cousers);

  useEffect(() => {
    if (idVerificationStatus === 'success') {
      setVerifyAlert(true);
    }
  }, [idVerificationStatus]);

  return (
    <>
      {idVerificationStatus !== 'success' && (
        <>
          <Grid container justifyContent="center">
            {showIDCamera && (
              <Box sx={{ width: '100%' }}>
                <CameraUpload
                  setShowIDCamera={setShowIDCamera}
                  showIDCamera={showIDCamera}
                  clearErrors={clearErrors}
                  prefixName={prefixName}
                  setValue={setValue}
                  setOpenFileUpload={setOpenFileUpload}
                />
              </Box>
            )}
            <Grid container className={customClasses.identityBox}>
              <Grid item lg={4} md={4} sm={3} display="flex" alignItems="flex-end">
                <img src={IdentityImage} />
              </Grid>
              <Grid item lg={8} md={8} sm={9} px={3}>
                <Typography
                  variant="subtitle1"
                  component="p"
                  sx={{ fontWeight: '700', paddingTop: '25px' }}
                >
                  Verify your identity
                </Typography>
                <Typography variant="subtitle2" component="p" py={4}>
                  We&apos;ll need to verify the identity of the applicant to get proceed with new
                  application. Please provide an image of its current government-issued photo ID.
                  The name on the ID should exactly match the name on this application.
                </Typography>
                <FileUploadDialog
                  control={control}
                  errors={errors}
                  open={openFileUpload}
                  setOpen={setOpenFileUpload}
                  setError={setError}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  createAppApplicationId={createAppApplicationId}
                  prefixName={prefixName}
                  couserIndex={couserIndex}
                  showIDCamera={showIDCamera}
                  setShowIDCamera={setShowIDCamera}
                />
                <Box display="flex" justifyContent="space-between" pb={2} gap={2}>
                  <>
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ maxWidth: '200px' }}
                      onClick={() => setOpenFileUpload(true)}
                      id="upload-manually"
                    >
                      Upload manually
                    </Button>
                  </>

                  <Button
                    variant="outlined"
                    color="secondary"
                    id="start-verification"
                    sx={{ maxWidth: '200px' }}
                    onClick={() =>
                      navigate('/vouched', {
                        state: {
                          activeStep: 1,
                          formFields: cousers,
                          savedApplicantIds,
                          initApplicantIds,
                          userType,
                          tabvalue,
                          viewAppFlow,
                          unfilled,
                          reviewFlag,
                        },
                      })
                    }
                  >
                    Start ID verification
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {idVerificationStatus === 'failed' && !manualFile && (
        <Typography
          variant="subtitle2"
          component="p"
          color="red"
          textAlign="center"
          sx={{ marginTop: '8px' }}
        >
          The ID verification service is unavailable. Please upload the ID manually and enter the
          details
        </Typography>
      )}

      {idVerificationStatus === 'expired' && !manualFile && (
        <Typography
          variant="subtitle2"
          component="p"
          color="red"
          textAlign="center"
          sx={{ marginTop: '8px', width: '90%' }}
        >
          * Expired ID
        </Typography>
      )}
      <Box sx={{ width: '70%' }} mt={2.5}>
        {verifyAlert && (
          <Alert
            severity="success"
            onClose={() => {
              setVerifyAlert(false);
            }}
          >
            ID verification done successfully!
          </Alert>
        )}
        <Typography variant="subtitle2" component="p" color="red" textAlign="center">
          {errors?.cousers?.[couserIndex]?.manualFile?.message}
        </Typography>
        {manualFile && idVerificationStatus !== 'success' && (
          <Box display="flex" gap={0.5} justifyContent="center" mt={1}>
            <CheckCircleOutlineOutlinedIcon htmlColor="#128A08" />
            <Typography variant="subtitle2" component="p" textAlign="center">
              {manualFile?.name}
            </Typography>
          </Box>
        )}
        <Box sx={{ width: '60%' }} display="flex" alignItems="center" mt={2.5}>
          <Typography variant="subtitle2" component="p" pr={1} sx={{ fontWeight: '700' }}>
            ID verification status:{' '}
          </Typography>
          <Box display="flex" alignItems="center">
            {idVerificationStatus === 'success' ? (
              <CheckCircleOutlineOutlinedIcon htmlColor="#128A08" />
            ) : (
              <CancelOutlinedIcon htmlColor="#D71721" />
            )}
            &nbsp;
            <Typography
              variant="subtitle2"
              component="p"
              color={idVerificationStatus === 'success' ? '#128A08' : '#D71721'}
            >
              {idVerificationStatus === 'success' ? 'Verified' : 'Not verified'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default IdVerification;
