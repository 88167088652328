import { Grid, Snackbar } from '@mui/material';
import PropTypes from 'prop-types';
import { defaultFn } from 'utils/utility';

const ErrorSnackbar = ({ snackBarOpen, setSnackBarOpen }) => {
  const handleSnackbarClose = () => {
    setSnackBarOpen(false);
  };

  return (
    <Grid container>
      <Snackbar
        open={snackBarOpen}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        message="Something went wrong. Please try again later"
      />
    </Grid>
  );
};
export default ErrorSnackbar;

ErrorSnackbar.propTypes = {
  snackBarOpen: PropTypes.bool,
  setSnackBarOpen: PropTypes.func,
};
ErrorSnackbar.defaultProps = {
  snackBarOpen: false,

  setSnackBarOpen: defaultFn,
};
