import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { defaultFn } from 'utils/utility';

import classes from '../../styles/resultPage.module.scss';

const Results = ({ values, watch }) => {
  const { createAppFlag } = useContext(AuthContext);
  const [calculatedData, setCalculatedData] = useState({});
  const createData = (name, value) => {
    return { name, value };
  };
  const defaultValues = (value1, value2) => {
    if (value1 || value2) {
      return value1 || value2;
    } else if (parseInt(value1) === 0) {
      return value1;
    } else if (parseInt(value2) === 0) {
      return value2;
    }
    return '--';
  };
  const defaultPrefixValues = (value, value2, prefix) => {
    if (((value || value2) && prefix) || parseInt(value) === 0 || parseInt(value2) === 0) {
      return prefix;
    }
    return '';
  };

  const watchProgram = watch('programVal');

  useEffect(() => {
    if (values?.flowData?.data) {
      setCalculatedData(values?.flowData?.data);
    } else {
      setCalculatedData({});
    }
  }, [createAppFlag]);
  console.log('CalculatedData - resultpage', calculatedData);
  console.log('values', values);

  const rows = [
    createData(
      'Amount financed',
      <NumberFormat
        value={defaultValues(
          values?.data?.amountFinanced?.toFixed(2),
          calculatedData?.calculatedData?.amountFinanced?.toFixed(2)
        )}
        thousandSeparator={true}
        displayType="text"
        prefix={defaultPrefixValues(
          values?.data?.amountFinanced,
          calculatedData?.calculatedData?.amountFinanced,
          '$'
        )}
        className={classes.results}
        isNumericString={true}
      />
    ),

    createData(
      'Financed fees',
      <NumberFormat
        value={defaultValues(
          values?.data?.financedFees?.toFixed(2),
          calculatedData?.calculatedData?.financedFees?.toFixed(2)
        )}
        displayType="text"
        thousandSeparator={true}
        prefix={defaultPrefixValues(
          values?.data?.financedFees,
          calculatedData?.calculatedData?.financedFees,
          '$'
        )}
        className={classes.results}
        isNumericString={true}
      />
    ),
    createData(
      'Balloon payment',
      <NumberFormat
        value={
          watchProgram === 'Oriental A+'
            ? calculatedData &&
              defaultValues(values?.formData?.balloonPay, calculatedData?.baloonPayment?.toFixed(2))
            : '--'
        }
        thousandSeparator={true}
        displayType="text"
        prefix={
          watchProgram === 'Oriental A+'
            ? calculatedData &&
              defaultPrefixValues(values?.formData?.balloonPay, calculatedData?.baloonPayment, '$')
            : ''
        }
        className={classes.results}
        isNumericString={true}
      />
    ),
    createData(
      'APR',
      <NumberFormat
        value={calculatedData && defaultValues(values?.formData?.rate, calculatedData?.apr)}
        displayType="text"
        suffix={
          calculatedData && defaultPrefixValues(values?.formData?.rate, calculatedData?.apr, '%')
        }
        className={classes.results}
        isNumericString={true}
      />
    ),
    createData(
      'Registration fees',
      <NumberFormat
        value={defaultValues(
          values?.data?.registrationFees?.toFixed(2),
          calculatedData?.calculatedData?.registrationFees?.toFixed(2)
        )}
        thousandSeparator={true}
        displayType="text"
        prefix={defaultPrefixValues(
          values?.data?.registrationFees,
          calculatedData?.calculatedData?.registrationFees,
          '$'
        )}
        className={classes.results}
        isNumericString={true}
      />
    ),
    createData(
      'Net trade in',
      <NumberFormat
        value={defaultValues(
          values?.data?.netTradeIn?.toFixed(2),
          calculatedData?.calculatedData?.netTradeIn?.toFixed(2)
        )}
        thousandSeparator={true}
        displayType="text"
        prefix={defaultPrefixValues(
          values?.data?.netTradeIn,
          calculatedData?.calculatedData?.netTradeIn,
          '$'
        )}
        className={classes.results}
        isNumericString={true}
      />
    ),

    createData(
      'Finance charge',

      <NumberFormat
        value={defaultValues(
          values?.data?.financeCharge?.toFixed(2),
          calculatedData?.calculatedData?.financeCharge?.toFixed(2)
        )}
        thousandSeparator={true}
        displayType="text"
        prefix={defaultPrefixValues(
          values?.data?.financeCharge,
          calculatedData?.calculatedData?.financeCharge,
          '$'
        )}
        className={classes.results}
        isNumericString={true}
      />
    ),
    createData(
      'Deferred balance (Total payments)',
      <NumberFormat
        value={defaultValues(
          values?.data?.deferredBalance?.toFixed(2),
          calculatedData?.calculatedData?.deferredBalance?.toFixed(2)
        )}
        thousandSeparator={true}
        displayType="text"
        prefix={defaultPrefixValues(
          values?.data?.deferredBalance,
          calculatedData?.calculatedData?.deferredBalance,
          '$'
        )}
        className={classes.results}
        isNumericString={true}
      />
    ),
  ];
  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12}>
        <Table
          aria-label="calc-result-table"
          id="calc-result-table"
          className={classes.tableContainer}
        >
          <TableBody>
            {rows?.map((row) => (
              <TableRow key={row?.name}>
                <TableCell
                  component="th"
                  scope="row"
                  classes={{ root: classes.TableCell }}
                  sx={{ width: '60%' }}
                >
                  {row?.name}
                </TableCell>
                <TableCell classes={{ root: classes.TableCell }}>{row?.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default Results;

Results.propTypes = {
  values: PropTypes.instanceOf(Object),
  watch: PropTypes.func,
  getValues: PropTypes.func,
};
Results.defaultProps = {
  values: {},
  watch: defaultFn,
  getValues: defaultFn,
};
