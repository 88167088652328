import { Box, Button, Link, Typography } from '@mui/material';
import noImage from 'assets/images/promotion-no-image.png';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { defaultFn } from 'utils/utility';

export const OfferDetailedView = ({
  eachOffer,
  setOpenModal = defaultFn,
  allOffers,
  isSlideView,
}) => {
  const navigate = useNavigate();
  return (
    <Box display="flex" justifyContent="space-between" key={eachOffer?.promotionId}>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        width="60%"
        sx={{ wordBreak: 'break-all' }}
      >
        <Typography variant="h1" color="secondary">
          {eachOffer?.promotionTitle}
        </Typography>
        <Typography variant="body1">{eachOffer?.promotionDescription}</Typography>
        <Box display="flex" gap={2}>
          <Typography variant="body1">
            Valid Upto{' '}
            <Typography color="primary" component="span">
              {eachOffer?.expirationDate}
            </Typography>{' '}
          </Typography>
          <Typography variant="body1">
            <Typography
              color="white"
              component="span"
              sx={{ backgroundColor: '#F28023', padding: '2px' }}
            >
              {eachOffer?.promotionCount}
            </Typography>{' '}
            <Typography color="primary" component="span">
              Used this offer
            </Typography>{' '}
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="secondary"
          data-testid="calculate-payment"
          onClick={() => {
            setOpenModal(false);
            navigate('/paymentCalculator');
          }}
          sx={{ width: 'max-content' }}
        >
          Calculate your payment
        </Button>

        {isSlideView && (
          <Link
            component="button"
            variant="subtitle2"
            color="secondary"
            sx={{ fontSize: '16px', width: 'max-content' }}
            data-testid="fresh-start"
            onClick={() => {
              setOpenModal(false);
              navigate('/offers', { state: { offerList: allOffers } });
            }}
            id="fresh-start"
          >
            View all offers &gt;
          </Link>
        )}
      </Box>

      <Box
        height="310px"
        alignSelf="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={eachOffer?.promotionImage || noImage}
          width="300"
          height="200"
          data-testid="image-id"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = noImage;
          }}
        />
      </Box>
    </Box>
  );
};

OfferDetailedView.propTypes = {
  eachOffer: PropTypes.instanceOf(Object),
  setOpenModal: PropTypes.func,
  allOffers: PropTypes.array,
  isSlideView: PropTypes.bool,
};
OfferDetailedView.defaultProps = {
  eachOffer: {},
  setOpenModal: defaultFn,
  allOffers: [],
  isSlideView: false,
};
