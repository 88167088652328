import { Box, Button, Typography } from '@mui/material';
import valueLookupUtility from 'assets/images/value-lookup-utility.png';
import MainApplicationContext from 'context/MainApplicationContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import classes from 'styles/globalStyle.module.scss';

const CollateralInformationLookupDialog = ({ apiErr }) => {
  const { setShowCollateralPopup } = useContext(MainApplicationContext);
  return (
    <Box
      width="631px"
      height="262px"
      sx={{
        background: 'linear-gradient(180deg, #FFFFFF 0%, #F3F3F3 100%)',
        display: 'flex',
        border: apiErr ? '1px solid red' : '1px solid #909090',
      }}
    >
      <img src={valueLookupUtility} width="185px" height="207px" style={{ margin: '31px' }} />
      <Box className={classes.collateralDialog}>
        <Typography variant="h3" color="#E37617">
          Value lookup tool
        </Typography>
        <Typography fontSize="14px !important">
          This tool helps to show the vehicle information and value on the screen from the
          collateral input data. Click on the button below.{' '}
        </Typography>
        <Button
          sx={{
            background: '#E37617',
            borderRadius: '5px',
            width: '177px',
            height: '40px',
            color: '#000000',
          }}
          id="value-lookup-btn"
          onClick={() => setShowCollateralPopup(true)}
        >
          Value lookup
        </Button>
      </Box>
    </Box>
  );
};

export default CollateralInformationLookupDialog;

CollateralInformationLookupDialog.propTypes = {
  apiErr: PropTypes.string,
};
CollateralInformationLookupDialog.defaultProps = {
  apiErr: '',
};
