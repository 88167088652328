import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import ContractDocuments from './ContractDocuments';
import ContractInfo from './ContractInfo';

const ContractGenerationtab = ({ appId, getInfoReq }) => {
  const [tab, setTab] = useState('0');
  const [disableContractDocs, setDisableContractDocs] = useState(false);
  const handleChange = (event, val) => {
    console.log('event val', { event, val });
    if (val) {
      setTab(val);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <ToggleButtonGroup
        color="primary"
        value={tab}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        sx={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        <ToggleButton value="0">Contract info</ToggleButton>
        <ToggleButton value="1" disabled={disableContractDocs}>
          Contract documents
        </ToggleButton>
      </ToggleButtonGroup>
      {tab === '0' ? (
        <ContractInfo appId={appId} setDisableContractDocs={setDisableContractDocs} />
      ) : (
        <ContractDocuments appId={appId} getInfoReq={getInfoReq} />
      )}
    </Box>
  );
};

export default ContractGenerationtab;

ContractGenerationtab.propTypes = {
  appId: PropTypes.string,
  getInfoReq: PropTypes.instanceOf(Object),
};
ContractGenerationtab.defaultProps = {
  appId: '',
  getInfoReq: {},
};
