import { Box, Grid, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import classes from 'styles/globalStyle.module.scss';
import { defaultFn, numberToCurrency } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';

const CollateralInfoSection = ({
  collateralInformation,
  viewAppFlow,
  setOpenPopup,
  setLandingUser,
  setLandingStep,
}) => {
  const { setReviewFlag, setActiveStep } = useContext(MainApplicationContext);
  const { lookupStateList, setCollateralInfo } = useContext(AuthContext);

  const onEdit = () => {
    setCollateralInfo({});
    if (viewAppFlow) {
      setOpenPopup(true);
      setLandingUser('');
      setLandingStep(3);
    } else {
      setReviewFlag(true);
      setActiveStep(3);
    }
  };

  const stateMapping =
    lookupStateList?.find((x) => x.value === collateralInformation?.productDetails?.state)?.name ||
    collateralInformation?.productDetails?.state;
  const total =
    +collateralInformation?.vehicleValues?.baseVehicleValue +
    +collateralInformation?.vehicleValues?.equipmentAdjustment +
    +collateralInformation?.vehicleValues?.mileageAdjustment;
  return (
    <>
      <CustomCollapse
        heading="Collateral information"
        open={viewAppFlow ? false : true}
        editable={true}
        onEdit={onEdit}
      >
        <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25} id="collateral-basic-info">
          <Typography variant="subtitle2">New</Typography>
          <Typography variant="subtitle2" className={classes.currencyValue}>
            {collateralInformation?.productDetails?.vehicleNewOrOld === 'True' ? 'Yes' : 'No'}
          </Typography>
          <Typography variant="subtitle2">Vehicle type</Typography>
          <Typography variant="subtitle2" className={classes.currencyValue}>
            {collateralInformation?.productDetails?.vehicleType}
          </Typography>
          <Typography variant="subtitle2">Year</Typography>
          <Typography variant="subtitle2" className={classes.currencyValue}>
            {collateralInformation?.productDetails?.year}
          </Typography>
          <Typography variant="subtitle2">Make</Typography>
          <Typography variant="subtitle2" className={classes.currencyValue}>
            {collateralInformation?.productDetails?.brand}
          </Typography>
          <Typography variant="subtitle2">Model</Typography>
          <Typography variant="subtitle2" className={classes.currencyValue}>
            {collateralInformation?.productDetails?.model}
          </Typography>
          <Typography variant="subtitle2">Sub model</Typography>
          <Typography variant="subtitle2" className={classes.currencyValue}>
            {collateralInformation?.productDetails?.subModel}
          </Typography>
          {collateralInformation?.productDetails?.style && (
            <>
              <Typography variant="subtitle2">Trim</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {collateralInformation?.productDetails?.style}
              </Typography>
            </>
          )}
          {collateralInformation?.productDetails?.state && (
            <>
              <Typography variant="subtitle2">State</Typography>
              <Typography variant="subtitle2" className={classes.currencyValue}>
                {stateMapping}
              </Typography>
            </>
          )}
          <Typography variant="subtitle2">Mileage</Typography>
          <Typography variant="subtitle2" className={classes.currencyValue}>
            {+collateralInformation?.productDetails?.mileage || '--'}
          </Typography>
          <Typography variant="subtitle2">VIN</Typography>
          <Typography variant="subtitle2" className={classes.currencyValue}>
            {collateralInformation?.productDetails?.vin || '--'}
          </Typography>
        </Box>
        <Box id="vehicle-value-info">
          <Typography
            variant="h6"
            sx={{
              fontWeight: 700,
              padding: '15px 0',
            }}
          >
            {' '}
            Vehicle values
          </Typography>
          {collateralInformation?.productDetails?.valueGuide === 'Distributor Data' && (
            <Grid
              container
              rowSpacing={1}
              columnSpacing={2}
              display="grid"
              sx={{
                border: '1px solid #DADADA',
                gridTemplateColumns: '70% 30%',
                marginLeft: '0px',
              }}
            >
              <Box sx={{ backgroundColor: '#F2F2F2' }}></Box>
              <Box p={2} sx={{ backgroundColor: '#F2F2F2' }}>
                <Typography fontSize="14px !important" fontWeight="700">
                  MSRP
                </Typography>
              </Box>
              <Box p={2}>
                <Typography fontSize="14px !important">Base vehicle value</Typography>
              </Box>
              <Box p={2}>
                <Typography fontSize="14px !important">{`${numberToCurrency(
                  collateralInformation?.vehicleValues?.baseVehicleValue
                )}`}</Typography>
              </Box>
            </Grid>
          )}

          {collateralInformation?.productDetails?.valueGuide === 'Black Book' && (
            <Grid
              container
              rowSpacing={1}
              columnSpacing={2}
              display="grid"
              sx={{
                border: '1px solid #DADADA',
                gridTemplateColumns: '70% 30%',
                marginLeft: '0px',
              }}
            >
              <Box p={2} sx={{ backgroundColor: '#F2F2F2' }}>
                <Typography fontSize="14px !important" fontWeight="700">
                  {collateralInformation?.productDetails?.vehicleNewOrOld === 'True'
                    ? 'Categories'
                    : 'Retail'}
                </Typography>
              </Box>
              <Box sx={{ backgroundColor: '#F2F2F2' }}></Box>
              <Box sx={{ borderBottom: '1px solid #DADADA' }}></Box>
              <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography fontSize="14px !important" fontWeight={700}>
                  {collateralInformation?.productDetails?.vehicleNewOrOld === 'True'
                    ? 'MSRP'
                    : 'Clean'}
                </Typography>
              </Box>
              <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography fontSize="14px !important">Base vehicle value</Typography>
              </Box>
              <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography fontSize="14px !important">
                  {numberToCurrency(collateralInformation?.vehicleValues?.baseVehicleValue)}
                </Typography>
              </Box>
              {collateralInformation?.vehicleValues?.equipmentAdjustment != 0 && (
                <>
                  <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                    <Typography fontSize="14px !important" fontWeight={700}>
                      Equipment adjustment
                    </Typography>
                  </Box>
                  <Box sx={{ borderBottom: '1px solid #DADADA' }}></Box>
                  {collateralInformation?.vehicleValues?.selectedEquipmentAdjustment?.map((x) => {
                    return (
                      <>
                        <Box
                          key={`${x.name}-${x.retail}`}
                          p={2}
                          sx={{ borderBottom: '1px solid #DADADA' }}
                        >
                          <Typography fontSize="14px !important">
                            {x.name || x.description}
                          </Typography>
                        </Box>
                        <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                          <Typography fontSize="14px !important">
                            {numberToCurrency(x.xclean || x.retail)}
                          </Typography>
                        </Box>
                      </>
                    );
                  })}
                </>
              )}
              {collateralInformation?.vehicleValues?.mileageAdjustment != 0 && (
                <>
                  <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                    <Typography fontSize="14px !important" fontWeight={700}>
                      Mileage adjustment
                    </Typography>
                  </Box>
                  <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                    <Typography fontSize="14px !important">
                      {numberToCurrency(collateralInformation?.vehicleValues?.mileageAdjustment)}
                    </Typography>
                  </Box>
                </>
              )}
              <Box p={2} sx={{ backgroundColor: '#F2F2F2' }}>
                <Typography fontSize="14px !important" fontWeight={700}>
                  Total
                </Typography>
              </Box>
              <Box p={2} sx={{ backgroundColor: '#F2F2F2' }}>
                <Typography fontSize="14px !important" fontWeight={700}>
                  {numberToCurrency(total)}
                </Typography>
              </Box>
            </Grid>
          )}
        </Box>
      </CustomCollapse>
    </>
  );
};

export default CollateralInfoSection;
CollateralInfoSection.propTypes = {
  collateralInformation: PropTypes.instanceOf(Object),
  viewAppFlow: PropTypes.bool,
  setOpenPopup: PropTypes.func,
  setLandingUser: PropTypes.func,
  setLandingStep: PropTypes.func,
};
CollateralInfoSection.defaultProps = {
  collateralInformation: {},
  viewAppFlow: false,
  setOpenPopup: defaultFn,
  setLandingUser: defaultFn,
  setLandingStep: defaultFn,
};
