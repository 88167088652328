import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Grid,
  Link,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ApiClass from 'Api/ApiClient';
import NoReportsImage from 'assets/images/NoReports.png';
import ExportIcon from 'assets/svg/Export.svg';
import InfoCircle from 'assets/svg/Icon-info-circle.svg';
import AuthContext from 'context/AuthContext';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { KpiSchema } from 'schemaValidations/KpiSchema';
import dashboardClasses from 'styles/applicationDashboard.module.scss';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import CustomMultiSelect from 'components/CustomMultiSelect/CustomMultiSelect';
import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

import printIcon from '../../assets/printIcon.svg';

const Reports = () => {
  const { rolebasedDealers, userID } = useContext(AuthContext);
  const location = useLocation();
  const {
    notificationId: notificationFlagState = '',
    errorFlag: errorFlagState = '',
    notificationObj,
  } = location?.state || {};

  const {
    control,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      startDate: '',
      endDate: '',
    },
    resolver: yupResolver(KpiSchema),
  });
  const [reportsList, setReportsList] = useState([]);
  const [userReports, setUserReports] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState([]);
  const [loader, setLoader] = useState(false);
  const [reportName, setReportName] = useState('');
  const [reportType, setReportType] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [noReports, setNoReports] = useState(false);
  const [apiErr, setApiErr] = useState('');
  const [exportUrl, setExportUrl] = useState({});
  const [generateFlag, setGenerateFlag] = useState(false);
  const [errorFlag, setErrorFlag] = useState('');
  const isOpen = Boolean(anchorEl);

  const ApiClient = new ApiClass();
  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  const formatList = ['.PDF', '.CSV'];

  const handleClose = () => {
    setAnchorEl(null);
  };
  const isAllSelected =
    rolebasedDealers?.length > 0 && selectedDealer?.length === rolebasedDealers?.length;
  console.log('report name and type', reportName, reportType);
  const fetchUserReports = async () => {
    try {
      setLoader(true);
      return await ApiClient.get(`${UrlHelpers.generateReport}s?dealerUser=${userID}`);
    } catch (e) {
      if (
        e?.response?.data === 'No reports found for the dealer. ' ||
        e?.response?.data === "Report Source can't be blank or null"
      ) {
        setNoReports(true);
      } else {
        setApiErr(e?.response?.data);
      }
    } finally {
      setLoader(false);
    }
  };

  useEffect(async () => {
    if (!reportsList?.length) {
      try {
        setLoader(true);
        let reportsList = await ApiClient.get(UrlHelpers.reportsList);
        reportsList = JSON.parse(reportsList?.['reports-list']);

        reportsList = reportsList?.map((x) => {
          const { displayName, value } = x;
          return { name: displayName, value };
        });
        setReportsList(reportsList || []);
      } catch (e) {
        console.log('error fetching', e);
      } finally {
        setLoader(false);
      }
    }
    await getReports();
    setGenerateFlag(false);
    setErrorFlag(errorFlagState);
  }, [notificationFlagState]);

  const getReports = async () => {
    const resp = await fetchUserReports();
    if (!resp?.length) {
      setNoReports(true);
    }
    setUserReports(resp);
  };

  useEffect(() => {
    trigger('endDate');
  }, [watchStartDate]);

  const handleMultiSelctChange = (event) => {
    const value = event?.target?.value;
    if (value[value.length - 1] === 'all') {
      setSelectedDealer(
        selectedDealer?.length === rolebasedDealers?.length
          ? []
          : rolebasedDealers.map((x) => x.dealerId)
      );

      return;
    }
    let newValues = [];
    if (value?.length > 1 && value.includes('Select')) {
      newValues = value.filter((x) => x !== 'Select');
      setSelectedDealer(newValues);
    } else {
      setSelectedDealer(value);
    }
  };

  const handleMinDate = () => {
    const today = new Date();
    const minDate = new Date(today);
    minDate.setFullYear(today.getFullYear() - 1);
    return minDate.setDate(today.getDate() + 1);
  };

  const generateReports = async () => {
    setGenerateFlag(false);
    setApiErr('');
    setErrorFlag('');
    let startDate = moment(watchStartDate).format('MM/DD/YYYY');
    let endDate = moment(watchEndDate).format('MM/DD/YYYY');
    const dealerNames = selectedDealer.map((i) => {
      const dealers = rolebasedDealers.find((d) => d.dealerId === i);
      return dealers ? dealers.dealerName : null;
    });
    const payload = {
      reportType: reportType,
      reportFormat: 'PDF',
      reportDataSource: reportName,
      dealer: selectedDealer.join(','),
      dealerNames: dealerNames.join(','),
      startDate: startDate,
      endDate: endDate,
      dealerUser: userID,
      currentDateTime: moment(new Date()).format('MM/DD/yyyy, kk:mm:ss'),
    };
    try {
      setLoader(true);
      await ApiClient.post(UrlHelpers.generateReport, payload, {
        headers: { 'Content-Type': 'application/json' },
      });
      setGenerateFlag(true);
    } catch (e) {
      setApiErr(e?.response?.data);
    } finally {
      setLoader(false);
    }
  };

  const handleChange = (e) => {
    const repName = reportsList.find((item) => item.value === e.target.value);
    setReportName(repName?.name);
    setReportType(e.target.value);
  };

  const printPdf = (base64) => {
    let byteCharacters = window.atob(base64);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: 'application/pdf;base64' });

    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const handleExportMenu = async (event, item) => {
    setAnchorEl(event.currentTarget);
    try {
      setLoader(true);
      let response = await ApiClient.get(
        `${UrlHelpers.generateReport}/document/${item?.documentId}`,
        {},
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      setExportUrl(response);
    } catch (e) {
      setApiErr(e?.response?.data);
    } finally {
      setLoader(false);
    }
  };

  const handleExport = (format) => {
    let key;
    switch (format) {
      case '.PDF':
        key = 's3KeyPdf';
        break;
      case '.CSV':
        key = 's3KeyCsv';
        break;
      case '.XLS':
        key = 's3KeyXlsx';
        break;
    }
    window.open(exportUrl[`${key}`], '_blank');
    setAnchorEl(null);
  };

  return (
    <Box display="flex" justifyContent="center">
      <Loader open={loader} />
      <Box sx={{ width: '98%' }}>
        <Typography variant="h3">Reports</Typography>
        <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
          <Grid container>
            <Box className={dashboardClasses.reportFilterInput}>
              <Select
                id={'selectReport'}
                name="reportName"
                defaultValue={'Select'}
                inputProps={{ 'data-testid': 'reportSearch' }}
                displayEmpty={true}
                sx={{ width: '100%', marginLeft: '5px', height: '40px' }}
                onChange={handleChange}
              >
                <MenuItem value="Select" sx={{ display: 'none' }}>
                  Select report name
                </MenuItem>
                {reportsList?.map((opt) => {
                  const { name, value } = opt;
                  return (
                    <MenuItem key={value} value={value}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
              <CustomMultiSelect
                selected={selectedDealer}
                handleMultiSelctChange={handleMultiSelctChange}
                isAllSelected={isAllSelected}
                options={rolebasedDealers.map((x) => ({
                  displayName: x.dealerName,
                  value: x.dealerId,
                }))}
                label="multiple-dealer-select"
                name="multiple-dealer-select"
                isStyled
                className={dashboardClasses.multiSelectReports}
              />

              <Box width="100%">
                <CustomDatePicker
                  name="startDate"
                  control={control}
                  error={!!errors?.startDate}
                  errorText={errors?.startDate?.message}
                  minDate={handleMinDate()}
                  maxDate={new Date()}
                  placeHolder="Start date"
                  noGap
                  noMargin
                />
              </Box>
              <Box width="100%">
                <CustomDatePicker
                  name="endDate"
                  control={control}
                  error={!!errors?.endDate}
                  errorText={errors?.endDate?.message}
                  minDate={watchStartDate || handleMinDate()}
                  maxDate={new Date()}
                  placeHolder="End date"
                  noGap
                  noMargin
                />
              </Box>
              <Button
                sx={{ width: '90px', height: '40px' }}
                color="secondary"
                variant="contained"
                size="small"
                onClick={generateReports}
                data-testid="generateBtn"
                disabled={!reportName || !selectedDealer.length || !watchStartDate || !watchEndDate}
              >
                Generate
              </Button>
            </Box>
          </Grid>
          <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
          {errorFlag && (
            <ErrorAlert
              apiErr={
                notificationObj.displayText === 'Report not available for requested selection'
                  ? 'Report not available for requested selection'
                  : 'Error occurred while generating report:'
              }
              showMore
              details={{
                reportName: notificationObj?.sourceName,
                dealers: JSON.parse(notificationObj?.otherInformation || '{}')?.dealers,
                dateRange: JSON.parse(notificationObj?.otherInformation || '{}')?.dateRange,
              }}
              setApiErr={setErrorFlag}
            />
          )}
          {generateFlag && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                padding: '10px',
                justifyContent: 'space-between',
                background: 'rgba(25, 118, 210, 0.05)',
                borderWidth: '1px 1px 1px 5px',
                borderStyle: 'solid',
                borderColor: 'rgba(25, 118, 210, 0.5)',
                gap: '10px',
              }}
              my={2.5}
            >
              <Box display="flex" alignItems="center" gap="5px">
                <img src={InfoCircle} width="20px" height="20px" />
                <Typography fontSize="14px !important">
                  Report generation in progress. We will notify you when the report is ready for
                  download
                </Typography>
              </Box>
            </Box>
          )}

          {userReports?.length || !noReports ? (
            <Table
              aria-label="reports-table"
              sx={{ border: '1px solid #F2F2F2', marginTop: '16px' }}
            >
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: '#F2F2F2',
                  }}
                >
                  <TableCell width="30%">
                    <Typography variant="subtitle1" fontWeight={700}>
                      Report name
                    </Typography>
                  </TableCell>
                  <TableCell width="50%">
                    <Typography variant="subtitle1" fontWeight={700}>
                      Description
                    </Typography>
                  </TableCell>
                  <TableCell width="20%">
                    <Typography variant="subtitle1" fontWeight={700}>
                      Actions
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {userReports?.length > 0 &&
                  userReports?.map((item) => {
                    return (
                      <TableRow
                        key={item?.documentId}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell sx={{ fontSize: '14px !important' }}>
                          {item?.reportName}
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: '14px !important' }}
                        >{`${item?.startDate} to ${item?.endDate}`}</TableCell>

                        <TableCell sx={{ fontSize: '14px !important' }}>
                          <Box display="flex" flexWrap="wrap">
                            <img src={printIcon} alt="Print" />
                            <Link
                              sx={{ marginLeft: '2px', cursor: 'pointer' }}
                              component="button"
                              variant="subtitle2"
                              color="secondary"
                              onClick={() => printPdf(item?.base64Encoded)}
                              data-testid="print"
                            >
                              Print
                            </Link>
                            &nbsp; &nbsp;
                            <Link
                              color="secondary"
                              display="flex"
                              sx={{ cursor: 'pointer', alignItems: 'center' }}
                              onClick={(e) => handleExportMenu(e, item)}
                            >
                              <img src={ExportIcon} alt="Export" />
                              &nbsp; Export{' '}
                            </Link>
                            <Menu
                              anchorEl={anchorEl}
                              open={isOpen}
                              onClose={handleClose}
                              classes={{ root: classes.imgPadding }}
                            >
                              {formatList?.map((i) => {
                                return (
                                  <MenuItem
                                    variant="subtitle2"
                                    key={i}
                                    classes={{ root: classes.imgPadding }}
                                    onClick={() => handleExport(i)}
                                  >
                                    {i}
                                  </MenuItem>
                                );
                              })}
                            </Menu>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          ) : (
            <Box display="flex" justifyContent="center">
              <img src={NoReportsImage} style={{ scale: '75%' }} />
            </Box>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default Reports;
