import { Box, Button, Checkbox, FormControlLabel, Grid, useMediaQuery } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import { handleTokenExpiry } from 'Services/commonComponentsApi';
import InvitationFlowImage from 'assets/svg/InvitationFlowImage.svg';
import EyeIcon from 'assets/svg/eye.svg';
import axios from 'axios';
import AuthContext from 'context/AuthContext';
import InvitationFlowContext from 'context/InvitationFlowContext';
import { useContext, useEffect, useState } from 'react';
import classes from 'styles/InviInformationRequest.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

import { InvitationExpiryCheck, exportFilesAsZip } from '../InviCommonUtilities';
import { inviFlowPayloads } from '../InviFlowPayloads';

const InviConsent = () => {
  const [isConsentGiven, setIsConsentGiven] = useState(false);
  const {
    setActiveStep,
    setCompletedSteps,
    setThankyouScreen,
    setOnBackClick,
    setGetDetailsData,
    setIsInviExpired,
    setInvitationExpiredHeading,
    setInvitationExpiredContent,
    setIsGetDetailsDone,
    isInvitationFlowMounted,
    isInviExpired,
    isFormSubmitted,
    isGetDetailsDone,
  } = useContext(InvitationFlowContext);
  const {
    commonToken,
    setCommonToken,
    inviApplicantId,
    inviUserType,
    accessToken,
    inviApplicationNum,
    userID,
    fullName,
  } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const [linkedConsents, setLinkedConsents] = useState([]);
  const [consentText, setConsentText] = useState('');
  const [linksArray, setLinksArray] = useState([]);
  const [apiErr, setApiErr] = useState(false);
  const ApiClient = new ApiClass();
  const isMobile = useMediaQuery('(max-width:899px)');
  const consentApi = async () => {
    if (!isInviExpired && !isFormSubmitted) {
      let response = await axios.post(
        UrlHelpers.commonComponentsInviConsent,
        {
          productKey: 'PR|Indirect Loan Account|AutoLoanInvitation',
          language: 'EN',
          source: 'auto',
          eventType: 'UI',
          applicationId: inviApplicationNum,
        },
        {
          headers: {
            Authorization: `Bearer ${commonToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log({ response });
      if (response?.data && response?.status === 200) {
        setLinkedConsents(response?.data[0]?.linkedConsents);
        let linkedConsent = response?.data[0]?.linkedConsents;
        setLoader(true);
        let displayData = await axios.get(response?.data[0]?.preSignedUrl);
        console.log('displayData', displayData?.data);
        const openingBrace = displayData?.data?.indexOf('{');
        console.log({ openingBrace });
        if (openingBrace !== -1) {
          let consentDisplayText = displayData?.data?.substring(0, openingBrace);
          setConsentText(consentDisplayText);
        }

        let consentLinksArray = displayData?.data?.match(/([^{]+(?=}}))/g);

        let matchingValues = linkedConsent?.filter((x) => consentLinksArray?.includes(x.name));
        let links = matchingValues?.map(
          (x) => `
  <a href="${x?.preSignedUrl}" id="${x?.name}" target="_blank" class="consent-link">${x?.name}
  <span>
  <img src=${EyeIcon} alt="Eye_Icon"/>
  </span>
  </a>       
 
  `
        );

        setLinksArray(links);
      }
    }
  };
  useEffect(async () => {
    if (commonToken && isInvitationFlowMounted) {
      try {
        setLoader(true);
        if (!isGetDetailsDone) {
          await InvitationExpiryCheck({
            accessToken,
            setLoader,
            setIsInviExpired,
            setInvitationExpiredHeading,
            setInvitationExpiredContent,
            inviApplicantId,
            inviApplicationNum,
            userID,
            fullName,
          });
        }
        await consentApi();
      } catch (e) {
        console.log(e);
        handleTokenExpiry(e, setCommonToken);
      } finally {
        setLoader(false);
      }
    }
  }, [commonToken, isInvitationFlowMounted]);
  useEffect(async () => {
    const policyLinks = document.getElementById('arrayLinks');
    if (!policyLinks.innerHTML) {
      linksArray.forEach((x) => (policyLinks.innerHTML += x));
    }
  }, [linksArray]);

  const handleSubmit = async () => {
    await InvitationExpiryCheck({
      accessToken,
      setLoader,
      setIsInviExpired,
      setInvitationExpiredHeading,
      setInvitationExpiredContent,
      inviApplicationNum,
      inviApplicantId,
      userID,
      fullName,
    });
    let applicantDetails = {
      consentOnInvitationSubmit: isConsentGiven,
      applicantId: inviApplicantId,
    };
    const inviConsentPayload = await inviFlowPayloads({
      eventName: 'ParticipantSubmitInvitation',
      applicationNum: inviApplicationNum,
      applicantDetails,
      inviUserType: inviUserType,
    });
    try {
      setLoader(true);
      let dataa = await ApiClient.post(
        UrlHelpers.informationReqSave,

        { ...inviConsentPayload },
        {
          headers: { 'Content-Type': 'application/json', userid: 'guest', userName: 'guest' },
        }
      );
      if (dataa) {
        setOnBackClick(false);
        setThankyouScreen(true);
        setIsGetDetailsDone(false);
        setGetDetailsData({});
      }
    } catch (e) {
      console.log('error fetching', e);
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later'
      );
    } finally {
      setLoader(false);
    }
  };
  const handleDownloadAll = async () => {
    const responseBlobs = [];
    for (const item of linkedConsents) {
      if (item?.preSignedUrl) {
        const resp = await axios.get(item?.preSignedUrl, {
          responseType: 'blob',
        });
        resp.data.fileName = linkedConsents.name;
        responseBlobs.push(resp.data);
        console.log('consent resp', resp.data);
      }
    }
    exportFilesAsZip(responseBlobs);
  };
  return (
    <>
      <Loader open={loader} />
      <Grid container>
        <Grid item lg={8} md={8} sm={12}>
          <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={8} md={8} sm={12} className={classes.contentContainer}>
          <FormControlLabel
            sx={{
              margin: 0,
              '.MuiFormControlLabel-label': {
                fontSize: '14px !important',
              },

              alignItems: 'flex-start',
              padding: '30px 30px 20px',
              width: '100%',
            }}
            control={
              <Checkbox
                checked={isConsentGiven}
                onChange={() => setIsConsentGiven(!isConsentGiven)}
                name="coApplicantInvite"
                color="secondary"
                sx={{ paddingRight: '8px' }}
                inputProps={{ 'data-testid': 'consent' }}
              />
            }
            label={consentText}
          />
          <Box id="arrayLinks" className={classes.links}></Box>

          <Button
            component="p"
            variant="outlined"
            color="secondary"
            onClick={handleDownloadAll}
            target="_blank"
            sx={{ borderRadius: '56px', fontWeight: '700', margin: '0 30px 30px' }}
          >
            Download all
          </Button>
        </Grid>
        {!isMobile && (
          <Grid item lg={4} md={4} sm={12}>
            <img src={InvitationFlowImage} style={{ width: '100%' }} />
          </Grid>
        )}
      </Grid>
      <Grid lg={8} md={8} sm={12} className={classes.footerIcons}>
        <Button
          component="button"
          color="secondary"
          variant="outlined"
          className={classes.buttonStyle}
          onClick={() => {
            setActiveStep(2);
            setCompletedSteps([1]);
          }}
        >
          Back
        </Button>
        <Button
          component="button"
          color="secondary"
          variant="contained"
          className={classes.buttonStyle}
          sx={{ marginLeft: '8px' }}
          disabled={!isConsentGiven}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Grid>
    </>
  );
};
export default InviConsent;
