import * as yup from 'yup';

import { dobFormatValidation } from './validationFunctions/validationFunctions';

export const contractInfoSchema = yup.object().shape({
  collateralColor: yup
    .string()
    .required('* Mandatory field')
    .test('len', 'Maximum 20 characters can be entered', (val) => val?.length <= 20)
    .matches(/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ\s]*$/, 'Invalid format'),
  vehicleLicensePlate: yup
    .string()
    .required('* Mandatory field')
    .test('len', 'Maximum 15 characters can be entered', (val) => val?.length <= 15)
    .matches(/^[a-z0-9A-ZáéíóúñüÁÉÍÓÚÑÜ¿¡]*$/, 'Invalid format'),
  payment: yup.string().required('* Mandatory field'),
  accountNumber: yup
    .string()
    .required('* Mandatory field')
    .required('* Mandatory field')
    .test('len', 'Maximum 20 characters can be entered', (val) => val?.length <= 20)
    .matches(/^[A-Za-z0-9]*$/, 'Invalid format'),
  accountType: yup.string().required('* Mandatory field'),
  routingNumber: yup.string().when('payment', {
    is: 'ACH',
    then: yup
      .string()
      .required('* Mandatory field')
      .required('* Mandatory field')
      .test('len', 'Maximum 20 characters can be entered', (val) => val?.length <= 20)
      .matches(/^\d*$/, 'Invalid format'),
  }),
  bank: yup.string().when('payment', {
    is: 'ACH',
    then: yup
      .string()
      .required('* Mandatory field')
      .required('* Mandatory field')
      .test('len', 'Maximum 15 characters can be entered', (val) => val?.length <= 15)
      .matches(/^[A-Za-z0-9\s]*$/, 'Invalid format'),
  }),
  vinNumber: yup
    .string()
    .required('* Mandatory field')
    .test('len', 'VIN should be 17 characters in length', (val) =>
      val ? val?.length === 17 : true
    )
    .matches(
      /^[A-HJ-NPR-Za-hj-npr-z0-9]*$/,
      "VIN shouldn't contain I, O, Q, space or special characters"
    ),
  cylinders: yup
    .string()
    .required('* Mandatory field')
    .matches(/^\d*$/, 'Invalid format')
    .test('len', 'Number of cylinders cannot be less than 1', (val) => (val ? +val >= 1 : true))
    .test('len', 'Number of cylinders cannot be greater than 9', (val) => (val ? +val <= 9 : true)),
  doors: yup
    .string()
    .required('* Mandatory field')
    .matches(/^\d*$/, 'Invalid format')
    .test('len', 'Number of doors cannot be less than 1', (val) => (val ? +val >= 1 : true))
    .test('len', 'Number of doors cannot be greater than 9', (val) => (val ? +val <= 9 : true)),
  drivingLicenseId: yup
    .string()
    .required('* Mandatory field')
    .test('len', 'Driving license number should be 20 characters in length', (val) =>
      val ? val?.length <= 20 : true
    )
    .matches(/^[A-Za-z0-9\s]*$/, 'Invalid format'),
  licenseExpirationDate: yup
    .string()

    .nullable()
    .required('* Mandatory field')
    .test('', 'Correct date format is mm/dd/yyyy', (value) => {
      return dobFormatValidation(value);
    }),
  licenseIssueState: yup
    .string()

    .nullable()
    .required('* Mandatory field'),
});
