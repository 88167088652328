import { Box, Drawer } from '@mui/material';
import AdvanceSearch from 'pages/ApplicationDashboard/AdvanceSearch';
import PropTypes from 'prop-types';
import { defaultFn } from 'utils/utility';

import HomeNotification from './HomeNotification';

export const DrawerContent = ({
  settingAnchor,
  filterAnchor,
  notificationAnchor,
  toggleDrawer,
  anchorPosition,
  searchAnchor,
  setLoader,
  setApplicationData,
  notificationList,
  setNotificationList,
  callBack,
  setEmptyDataMessage,
  openNotification,
  onNotifyClose,
}) => (
  <Drawer
    anchor={anchorPosition || 'right'}
    open={settingAnchor || filterAnchor || notificationAnchor || searchAnchor}
    // onClose={toggleDrawer('setting', false, setSettingAnchor, setFilterAnchor)}
    sx={{
      '.MuiDrawer-paper': {
        color: 'rgba(0, 0, 0, 1)',
      },
    }}
  >
    <Box sx={{ width: '372px', padding: '0px 20px' }} role="presentation">
      {notificationAnchor && (
        <HomeNotification
          toggleDrawer={toggleDrawer}
          notificationList={notificationList}
          setNotificationList={setNotificationList}
          callBack={callBack}
          openNotification={openNotification}
          onNotifyClose={onNotifyClose}
        />
      )}
      {searchAnchor && (
        <AdvanceSearch
          toggleDrawer={toggleDrawer}
          setLoader={setLoader}
          setApplicationData={setApplicationData}
          setEmptyDataMessage={setEmptyDataMessage}
        />
      )}
    </Box>
  </Drawer>
);

DrawerContent.propTypes = {
  toggleDrawer: PropTypes.func,
  settingAnchor: PropTypes.bool,
  filterAnchor: PropTypes.bool,
  notificationAnchor: PropTypes.bool,
  searchAnchor: PropTypes.bool,
  setRows: PropTypes.func,
  setFilterAnchor: PropTypes.func,
  setSettingAnchor: PropTypes.func,
  setColumns: PropTypes.func,
  anchorPosition: PropTypes.string,
  setLoader: PropTypes.func,
  setApplicationData: PropTypes.func,
  notificationList: PropTypes.array,
  setNotificationList: PropTypes.func,
  callBack: PropTypes.func,
  setEmptyDataMessage: PropTypes.func,
  openNotification: PropTypes.func,
  onNotifyClose: PropTypes.func,
};

DrawerContent.defaultProps = {
  toggleDrawer: defaultFn,
  settingAnchor: false,
  filterAnchor: false,
  notificationAnchor: false,
  searchAnchor: false,
  anchorPosition: '',
  setRows: defaultFn,
  setColumns: defaultFn,
  setFilterAnchor: defaultFn,
  setSettingAnchor: defaultFn,
  setLoader: defaultFn,
  setApplicationData: defaultFn,
  notificationList: [],
  setNotificationList: defaultFn,
  callBack: defaultFn,
  setEmptyDataMessage: defaultFn,
  openNotification: defaultFn,
  onNotifyClose: defaultFn,
};
