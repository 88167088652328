/* eslint-disable react/prop-types */
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Box, Checkbox, IconButton, Link, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import { payCalcOnLoadApi } from 'Services/PaymentCalcApi';
import Print from 'assets/svg/Print.svg';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import EditSubmittedPopup from 'pages/ApplicationDashboard/EditSubmittedPopup';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn } from 'utils/utility';

import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

import CreateApplicationFooter from '../CreateApplicationFooter';
import ApplicationPageHeading from '../InformationRequest/moduleUtilities/ApplicationPageHeading';
import CollateralInfoSection from './CollateralInfoSection';
import EmploymentInformationSection from './EmploymentInformationSection';
import FinancialTermsSection from './FinancialTermsSection';
import InformationRequestSection from './InformationRequestSection';

const ReviewAndSubmit = ({ viewAppFlow, appID }) => {
  const {
    createAppApplicationId,
    setCreateAppFlag,
    userID,
    setCreateAppLaunchType,
    setDealer,
    fullName,
    rolebasedDealers,
    dealer,
    createAppFlag,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const {
    setCompletedSteps = defaultFn,
    setAppSubmitted = defaultFn,
    setCaseReferenceId = defaultFn,
    setOpenDraftModal = defaultFn,
    setActiveStep = defaultFn,
    viewAppFlow: updateAndSubmit,
  } = useContext(MainApplicationContext);
  const [initApiErr, setInitApiErr] = useState(false);
  const [apiErr, setApiErr] = useState('');
  const [err, setErr] = useState(false);
  const [loader, setLoader] = useState(false);
  const [pageData, setPageData] = useState({});
  const [selected, setSelected] = useState({ one: false });
  const [openPopup, setOpenPopup] = useState(false);

  const [landingUser, setLandingUser] = useState('');
  const [landingStep, setLandingStep] = useState(1);
  const [alertToggle, setAlertToggle] = useState(true);
  const [promotionsData, setPromotionsData] = useState([]);

  const ApiClient = new ApiClass();

  const handleChange = (key) => {
    let current = selected[key];
    setSelected({ ...selected, [key]: !current });
  };

  const onBackClick = () => {
    setActiveStep(4);
    submitApi({ draft: true, backFlag: true });
  };

  useEffect(async () => {
    setCompletedSteps([1, 2, 3, 4]);
    try {
      setLoader(true);
      const resp = await ApiClient.get(
        `${UrlHelpers.getApplicationDetails}?applicationId=${createAppApplicationId}${
          viewAppFlow ? '' : '&isLockRequired=true'
        }`,
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );
      console.log('resp', resp);
      const parsedResp = JSON.parse(resp?.applicationData);
      setPageData(parsedResp);
      const getSavedConsent = parsedResp?.consents?.consentSubmit || false;
      setSelected({ one: getSavedConsent });
    } catch (e) {
      console.log('e', e);
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later'
      );
      setInitApiErr(true);
    } finally {
      setLoader(false);
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);
  const draftClick = () => {
    submitApi({ draft: true });
  };

  const submitApi = async ({ draft = false, backFlag = false }) => {
    if (selected?.one || draft) {
      setErr(false);
      const formData = {
        consentSubmit: selected?.one ? true : false,
        consentSubmittedTimeStamp: moment().format(),
      };

      let reviewSubmitPayload = {
        event: 'ReviewAndSubmit',
        application: {
          products: {
            productType: 'Indirect Loan Account',
            productName: 'AutoLoanManual',
          },

          applicationId: createAppApplicationId,

          userId: userID,
          source: {
            language: 'EN',
            location: 'PR',
          },
          consents: { ...formData },
        },
      };

      await callSubmitApi({ draft, reviewSubmitPayload, backFlag });
    } else {
      setErr(true);
    }
  };

  const callSubmitApi = async ({ draft, reviewSubmitPayload, backFlag }) => {
    try {
      const endpoint = draft ? UrlHelpers.applicationDraft : UrlHelpers.createApiSubmit;
      setLoader(true);
      const resp = await ApiClient.post(
        endpoint,
        {
          ...reviewSubmitPayload,
        },
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );
      if (resp && !draft) {
        console.log('submitted successfully', resp);
        setCaseReferenceId(resp?.CaseReferenceId);
        setAppSubmitted(true);
        setCreateAppFlag(false);
        setDealer('');
        setCreateAppLaunchType('');
      } else if (resp && draft && !backFlag) {
        setOpenDraftModal(true);
      }
    } catch (e) {
      if (e?.response?.status === 400) {
        setApiErr(e?.response?.data);
      } else {
        if (e?.response?.data?.responseCode === 1020) {
          setApiErr(
            "Application submission isn't successful due to a validation error / s.Please reach out to the bank."
          );
        } else if (e?.response?.responseCode === 1025) {
          setApiErr('Dealer not found in ECM. Please contact dealer support');
        } else {
          setApiErr(
            'Due to a technical issue, we are unable to fetch the decision. Please check the decision status in sometime.'
          );
        }
      }
    } finally {
      setLoader(false);
    }
  };
  const {
    applicants: {
      informationRequest = {},
      employmentInformation = {},
      coSigners = [],
      coApplicants = [],
    } = {},

    collateralInformation = {},
    financialTerms = {},
  } = pageData || {};
  useEffect(async () => {
    if (financialTerms?.paymentCalculatorDetails?.promotion) {
      const onLoadData = await payCalcOnLoadApi({
        rolebasedDealers,
        dealer,
        createAppFlag,
        promotionId: financialTerms?.paymentCalculatorDetails?.promotion,
      });
      setPromotionsData(onLoadData);
    }
  }, [financialTerms?.paymentCalculatorDetails?.promotion]);

  console.log('get response', {
    informationRequest,
    employmentInformation,
    coSigners,
    coApplicants,
    collateralInformation,
    financialTerms,
  });

  const printAction = () => {
    let pdf = document.getElementById('printForm');
    let opt = {
      margin: 45,
      filename: `${createAppApplicationId}.pdf`,
      jsPDF: { unit: 'px', format: 'a4', orientation: 'portrait', hotfixes: ['px_scaling'] },
    };
    html2pdf(pdf, opt);
  };

  const editCallback = () => {
    if (viewAppFlow) {
      setCreateAppFlag(true);
      navigate('/home/create-app', {
        state: {
          activeStep: landingStep,
          completedSteps: [1, 2, 3, 4, 5],
          userType: landingUser,
          reviewFlag: true,
          viewAppFlow: viewAppFlow,
        },
      });
    }
  };
  return (
    <div className={classes.reviewAndSubmit}>
      <Loader open={loader} />
      <EditSubmittedPopup
        open={openPopup}
        setOpen={setOpenPopup}
        handleEdit={editCallback}
        appID={appID}
        setApiErr={setApiErr}
        userID={userID}
        userName={fullName}
      />
      <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
      {updateAndSubmit && alertToggle && !initApiErr && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            padding: '10px',
            justifyContent: 'space-between',
            background: 'rgba(239, 171, 57, 0.05)',
            borderWidth: '1px 1px 1px 5px',
            borderStyle: 'solid',
            borderColor: 'rgba(239, 171, 57, 0.5)',
            gap: '10px',
            marginTop: '15px',
          }}
        >
          <Box display="flex" alignItems="center" gap="5px">
            <WarningAmberRoundedIcon sx={{ color: '#EFAB3980' }} />
            <Typography fontSize="14px !important">
              Please submit the application with the updated information
            </Typography>
          </Box>
          <IconButton
            data-testid="closeBtn"
            onClick={() => setAlertToggle(false)}
            sx={{ height: '16px', width: '16px' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      {!viewAppFlow && !initApiErr && (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <ApplicationPageHeading />
          <div>
            <img src={Print} alt="Print" />
            <Link
              sx={{ marginLeft: '2px' }}
              component="button"
              variant="subtitle2"
              color="secondary"
              onClick={printAction}
            >
              Print
            </Link>
          </div>
        </div>
      )}
      {!initApiErr && (
        <div id="printForm">
          <InformationRequestSection
            informationRequest={informationRequest}
            label="Applicant"
            viewAppFlow={viewAppFlow}
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            setLandingUser={setLandingUser}
            setLandingStep={setLandingStep}
          />
          {coApplicants?.length > 0 &&
            coApplicants?.map((x) => {
              return (
                <InformationRequestSection
                  key={x?.applicantId}
                  informationRequest={x?.informationRequest}
                  label="Co-applicant"
                  viewAppFlow={viewAppFlow}
                  openPopup={openPopup}
                  setOpenPopup={setOpenPopup}
                  setLandingUser={setLandingUser}
                  setLandingStep={setLandingStep}
                />
              );
            })}

          {coSigners?.length > 0 &&
            coSigners?.map((x, index) => {
              return (
                <InformationRequestSection
                  key={x?.applicantId}
                  informationRequest={x?.informationRequest}
                  label={`Co-signer ${index + 1}`}
                  viewAppFlow={viewAppFlow}
                  openPopup={openPopup}
                  setOpenPopup={setOpenPopup}
                  setLandingUser={setLandingUser}
                  setLandingStep={setLandingStep}
                />
              );
            })}

          <EmploymentInformationSection
            employmentInformation={employmentInformation}
            label="Applicant"
            viewAppFlow={viewAppFlow}
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            setLandingUser={setLandingUser}
            setLandingStep={setLandingStep}
          />
          {coApplicants?.length > 0 &&
            coApplicants?.map((x) => {
              return (
                <EmploymentInformationSection
                  key={x?.applicantId}
                  employmentInformation={x?.employmentInformation}
                  label="Co-applicant"
                  viewAppFlow={viewAppFlow}
                  openPopup={openPopup}
                  setOpenPopup={setOpenPopup}
                  setLandingUser={setLandingUser}
                  setLandingStep={setLandingStep}
                />
              );
            })}

          {coSigners?.length > 0 &&
            coSigners?.map((x, index) => {
              return (
                <EmploymentInformationSection
                  key={x?.applicantId}
                  employmentInformation={x?.employmentInformation}
                  label={`Co-signer ${index + 1}`}
                  viewAppFlow={viewAppFlow}
                  openPopup={openPopup}
                  setOpenPopup={setOpenPopup}
                  setLandingUser={setLandingUser}
                  setLandingStep={setLandingStep}
                />
              );
            })}

          <CollateralInfoSection
            collateralInformation={collateralInformation}
            viewAppFlow={viewAppFlow}
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            setLandingUser={setLandingUser}
            setLandingStep={setLandingStep}
          />
          <FinancialTermsSection
            financialTerms={financialTerms}
            viewAppFlow={viewAppFlow}
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            setLandingUser={setLandingUser}
            setLandingStep={setLandingStep}
            promotionsData={promotionsData}
          />
        </div>
      )}
      {!viewAppFlow && !initApiErr && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            backgroundColor="#9090901A"
            my={1.25}
            p={1.75}
            border={!selected?.one && err ? '1px solid red' : 'none'}
          >
            <Box display="flex" gap={1}>
              <Checkbox
                color="primary"
                checked={selected?.one}
                onChange={() => handleChange('one')}
                inputProps={{ 'aria-labelledby': 'submit-consent' }}
                id="consent-checkbox"
              />
              <Typography variant="subtitle2" color="#000000" id="submit-consent">
                By clicking the SUBMIT button, I hereby certify that all the information entered
                into the portal, in connection with this submittal, accurately reflects the
                information we collected from the applicant(s) regarding an application of credit.
              </Typography>
            </Box>
          </Box>
          <CreateApplicationFooter
            onBackClick={onBackClick}
            isSubmit={true}
            onSaveDraft={draftClick}
            onSaveContinue={submitApi}
          />
        </>
      )}
    </div>
  );
};

export default ReviewAndSubmit;
