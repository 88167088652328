import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn } from 'utils/utility';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';
import Loader from 'components/Loader/Loader';

import { OfferDetailedView } from './OfferDetailedView';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function OfferSlider({ openModal, setOpenModal }) {
  let { rolebasedDealers } = useContext(AuthContext);
  const ApiClient = new ApiClass();
  const [activeStep, setActiveStep] = useState(0);

  const [loader, setLoader] = useState(false);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const [offers, setOffers] = useState([]);
  const [allOffers, setAllOffers] = useState([]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  useEffect(async () => {
    const CommaSeperatedDealerListt = rolebasedDealers?.map((x) => x.dealerId)?.join(',') || '';
    try {
      setLoader(true);
      let onLoadData = await ApiClient.get(
        `${UrlHelpers?.payCalcOnLoad}?dealerIds=${CommaSeperatedDealerListt}`,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      console.log('onLoadData', onLoadData);
      if (onLoadData?.length) {
        setOffers(onLoadData?.slice(0, 5));
        setAllOffers(onLoadData);
      }
    } catch (e) {
      console.log('error fetching', e);
    } finally {
      setLoader(false);
    }
  }, []);
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      PaperProps={{ sx: { padding: '20px' } }}
      classes={{ paper: classes.offerSlider }}
    >
      <Loader open={loader} />
      <BootstrapDialogTitle onClose={handleClose}>
        <Typography variant="h1" color="primary">
          Offers
        </Typography>
      </BootstrapDialogTitle>
      <DialogContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
          <IconButton
            onClick={handleBack}
            disabled={activeStep === 0}
            sx={{ color: 'white' }}
            data-testid="prev-btn"
            aria-label="carousel-previous"
          >
            <KeyboardArrowLeft color="white" sx={{ backgroundColor: '#661c69' }} fontSize="large" />
          </IconButton>
          <AutoPlaySwipeableViews
            axis="x"
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {offers?.map((eachOffer) => {
              return (
                <OfferDetailedView
                  key={eachOffer?.promotionId}
                  eachOffer={eachOffer}
                  setOpenModal={setOpenModal}
                  allOffers={allOffers}
                  isSlideView
                />
              );
            })}
          </AutoPlaySwipeableViews>

          <IconButton
            onClick={handleNext}
            disabled={activeStep === offers?.length - 1}
            sx={{ color: 'white' }}
            data-testid="next-btn"
            aria-label="carousel-next"
          >
            <KeyboardArrowRight
              color="white"
              sx={{ backgroundColor: '#661c69' }}
              fontSize="large"
            />
          </IconButton>
        </Box>
        <MobileStepper
          steps={offers?.length}
          position="static"
          activeStep={activeStep}
          sx={{ justifyContent: 'center' }}
        />
      </DialogContent>
    </Dialog>
  );
}

export default OfferSlider;

OfferSlider.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
};
OfferSlider.defaultProps = {
  openModal: false,
  setOpenModal: defaultFn,
};
