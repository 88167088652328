import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Button, Checkbox, Grid, InputLabel, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { bankerRoles, ternaryCondition } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';
import Loader from 'components/Loader/Loader';

const ViewUserRequestStyles = makeStyles(() => ({
  rejectBtn: {
    background: '#D71721',
    color: '#FFFFFF',
    borderRadius: '5px',
    height: '40px',
    '&:hover': {
      background: '#D71721',
      color: '#FFFFFF',
    },
  },
  approveBtn: {
    background: '#128A08',
    color: '#FFFFFF',
    borderRadius: '5px',
    height: '40px',
    '&:hover': {
      background: '#128A08',
      color: '#FFFFFF',
    },
  },
}));

const ViewUserRequest = () => {
  const { setRejectClicked, setApproveClicked, accessToken, role } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const [authorizeCheck, setAuthorizeCheck] = useState(false);
  const [phoneWithCountryCode, setPhoneWithCountryCode] = useState(false);
  const [phoneWithoutCountryCode, setPhoneWithoutCountryCode] = useState(false);
  const [data, setData] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const { reqId, route } = location?.state || {};
  const handleCheckboxChange = (e) => {
    setAuthorizeCheck(e.target.checked);
  };
  const ApiClient = new ApiClass();

  const classes = ViewUserRequestStyles();
  useEffect(async () => {
    setPhoneWithCountryCode(
      await ternaryCondition(
        data.phoneUser,
        `(${data.phoneUser?.slice(2, 5)}) ` +
          data.phoneUser?.slice(5, 8) +
          '-' +
          data.phoneUser?.slice(8),
        '--'
      )
    );
    setPhoneWithoutCountryCode(
      await ternaryCondition(
        data.phoneUser,
        `(${data.phoneUser?.slice(0, 3)}) ` +
          data.phoneUser?.slice(3, 6) +
          '-' +
          data.phoneUser?.slice(8),
        '--'
      )
    );
  }, [data]);
  useEffect(async () => {
    try {
      setLoader(true);
      const resp = await ApiClient.get(`${UrlHelpers.userRequestStatus}id/${reqId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
          RoleID: role,
        },
      });

      const { data } = resp;

      setData({ ...data });
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  }, [reqId]);

  return (
    <>
      {loader && <Loader open={loader} />}
      <Box sx={{ width: '70%', margin: 'auto' }}>
        <CustomCollapse heading="Information about dealer" open={true}>
          <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">Dealer</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {data.dealer}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">Dealer number</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {data.dealerNumber}
              </Typography>
            </Grid>
          </Grid>
        </CustomCollapse>
        <CustomCollapse heading="Information about user" open={true}>
          <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">First name</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {data.firstName}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">Last name</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {data.lastName}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">Second last name</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {data?.secondLastName}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">Birth date</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {data.dob}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">SSN (LAST 4 DIGITS)</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {data.ssn}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">Phone</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {data.phoneUser?.slice(0, 2) === '+1'
                  ? phoneWithCountryCode
                  : phoneWithoutCountryCode}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">Email</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {data.email}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">Access type</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {data.accessTypeDealer}
              </Typography>
            </Grid>
          </Grid>
        </CustomCollapse>
        <CustomCollapse heading="Comment" open={true}>
          <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">Comment</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {data?.commentsDealer}
              </Typography>
            </Grid>
            {data.approvalStatus === 'REJECTED' && (
              <>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography fontSize="14px !important">Rejection Comment</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                    {data?.commentsBanker}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </CustomCollapse>
        {(role === bankerRoles?.employee || role === bankerRoles?.master) &&
          data.approvalStatus === 'PENDING' && (
            <>
              <Box
                sx={{
                  height: '50px',
                  backgroundColor: '#E6E6E6',
                  // opacity: '0.1',
                  display: 'flex',
                  margin: '10px 0',
                  padding: '10px',
                }}
              >
                <Box display="flex" justifyContent={'flex-start'}>
                  <Box display="flex" justifyContent={'space-around'} sx={{ gap: '10px' }}>
                    <Checkbox
                      color="primary"
                      onChange={(event) => handleCheckboxChange(event)}
                      checked={authorizeCheck}
                      data-testid="authorizeCheckbox"
                      inputProps={{ 'aria-label': 'authorize-user-creation' }}
                    />
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection={'column'}
                      justifyContent={'center'}
                    >
                      <Typography variant="subtitle2">I authorize the creation of user</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Grid container rowSpacing={1} columnSpacing={4}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={0.625}
                    my={0.5}
                    flexDirection={'column'}
                  >
                    <InputLabel>Name</InputLabel>
                    <Typography sx={{ width: '100%' }}>{data?.nameRequester}</Typography>
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={0.625}
                    my={0.5}
                    flexDirection={'column'}
                  >
                    <InputLabel>Date</InputLabel>
                    <Typography sx={{ width: '100%' }}>{moment().format('MM/DD/YYYY')}</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="center" sx={{ margin: '10px 0' }}>
                <Box display="flex" sx={{ gap: '10px' }}>
                  <Button
                    variant="contained"
                    className={classes.rejectBtn}
                    onClick={() => setRejectClicked(true)}
                    // disabled={authorizeCheck}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.approveBtn}
                    onClick={() => setApproveClicked(true)}
                    disabled={!authorizeCheck}
                  >
                    Approve
                  </Button>
                </Box>
              </Box>
            </>
          )}
      </Box>
      <Box display="flex" alignItems="left" sx={{ cursor: 'pointer', margin: '20px 0' }}>
        <KeyboardArrowLeftIcon color="secondary" />
        <Link
          component="button"
          variant="subtitle2"
          color="secondary"
          onClick={() => {
            if (route === 'applications') {
              navigate('/applications', { state: { tabValue: 3 } });
            } else {
              navigate('/home', { state: { tabValue: 1 } });
            }
          }}
        >
          Back
        </Link>
      </Box>
    </>
  );
};

export default ViewUserRequest;
