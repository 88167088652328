import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn } from 'utils/utility';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';
import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

import { Requirements } from './Requirements';
import { enrollTooltipStyles } from './Tooltipstyles';

const useStyles = enrollTooltipStyles();

const ResetPasswordModal = ({
  openResetDialog,
  setOpenResetDialog,
  resetPwdDetails,
  setIsPasswordResetSuccess,
}) => {
  const classes = useStyles();
  const { userName } = useContext(AuthContext);
  const ApiClient = new ApiClass();
  const [formData, setFormData] = useState({
    newPassword: '',
    reenterPassword: '',
  });
  const [formErrors, setFormErrors] = useState({
    newPassword: '',
    reenterPassword: '',
  });
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [reenterPasswordValid, setReenterPasswordValid] = useState(false);
  const [apiErr, setApiErr] = useState('');

  const [passwordToggle, setPasswordToggle] = useState({
    newPassword: true,
    reenterPassword: true,
  });

  const handleChangePwd = async () => {
    const resetPasswordPayload = {
      newPassword: formData?.reenterPassword,
      autoReset: true,
      enforceChangeOnNextLogon: true,
      emailNotification: true,
      email: resetPwdDetails?.email,
      name: resetPwdDetails?.userName,
      editedBy: userName,
    };
    setPasswordToggle({
      newPassword: true,
      reenterPassword: true,
    });
    try {
      setLoading(true);
      const getResp = await ApiClient.post(
        `${UrlHelpers.dealerResetPassword}/${resetPwdDetails?.userId}`,

        { ...resetPasswordPayload },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (getResp?.toLowerCase() === 'success') {
        setOpenResetDialog(false);
        setIsPasswordResetSuccess(true);
        setFormData({
          newPassword: '',
          reenterPassword: '',
        });
        setFormErrors({
          newPassword: '',
          reenterPassword: '',
        });
        setApiErr('');
      }
    } catch (e) {
      console.log('error', e);
      const regex = /\[.*?\]\s*(.*)/;
      const match = regex.exec(e?.response?.data?.messages[0]?.message);
      const message = match ? match[1] : '';
      setApiErr(message);
    } finally {
      setLoading(false);
    }
  };

  const reqArray = [
    {
      validator: (val) => val.length > 7 && val.length < 16,
      text: 'Password must be a minimum of 8 characters and a maximum of 15 characters',
      reqSatisfied: false,
    },
    {
      validator: (val) =>
        (/[A-Z]{1}/.test(val) || /[a-zÑñÁáÉéÍíÓóÚúü]{1}/.test(val)) &&
        /(\D*\d)/.test(val) &&
        !/[><\\']/.test(val) &&
        /^(?!.*(.)\1\1.*).*$/i.test(val),
      text: "Password must at least contain one letter and one number. Cannot contain the special characters of \\, <, >, '. Cannot repeat more than 2 the same characters in a row.",
      reqSatisfied: false,
    },
    {
      validator: (val) => !/\s/.test(val),
      text: 'Cannot include spaces',
      reqSatisfied: false,
    },
  ];

  const reenterArray = [
    {
      validator: (val) => val === formData.newPassword,
      text: 'Passwords must be the same',
      reqSatisfied: false,
    },
  ];
  const passwordIconHandler = (passname, e) => {
    e.preventDefault();
    setPasswordToggle({
      ...passwordToggle,
      [passname]: !passwordToggle[passname],
    });
  };

  useEffect(() => {
    setSubmitDisabled(
      !formData?.newPassword ||
        !formData?.reenterPassword ||
        !reenterPasswordValid ||
        !passwordValid
    );
  }, [formData, passwordValid, reenterPasswordValid]);
  useEffect(() => {
    if (
      formData?.newPassword !== formData?.reenterPassword &&
      formData?.newPassword?.length &&
      formData?.reenterPassword?.length
    ) {
      setFormErrors({
        ...formErrors,
        reenterPassword: 'Passwords must be the same',
      });
    } else if (formData?.newPassword?.length === formData?.reenterPassword?.length) {
      setFormErrors({ ...formErrors, reenterPassword: '' });
    }
  }, [formData.newPassword, formData.reenterPassword]);

  const onFormData = (e) => {
    if (e.target.name === 'newPassword') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
      setPasswordValid(
        !reqArray.map((req) => req.validator(e.target.value)).some((val) => val === false)
      );
      setReenterPasswordValid(e.target.value === formData.reenterPassword);
    } else if (e.target.name === 'reenterPassword') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
      setReenterPasswordValid(e.target.value === formData.newPassword);
    } else {
      reenterArray[0].validator();
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleClose = () => {
    setOpenResetDialog(false);
    setFormData({});
    setFormErrors({});
    setSubmitDisabled(false);
    setPasswordValid(false);
    setReenterPasswordValid(false);
    setApiErr('');
  };
  return (
    <Dialog
      open={openResetDialog}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            minWidth: 'clamp(400px, 50vw, 800px) !important',
            minHeight: 'clamp(450px,60vh,100%) !important',
          },
        },
      }}
    >
      <Loader open={loading} />
      <BootstrapDialogTitle
        id="customized-dialog-title"
        data-testid="on-close"
        onClose={handleClose}
      >
        <Typography variant="h3" component="span">
          Reset password
        </Typography>
        <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
      </BootstrapDialogTitle>
      <Grid container sx={{ width: '80%' }}>
        <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
      </Grid>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', width: '70%', margin: 'auto' }}
      >
        <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <InputLabel
            htmlFor="newPassword"
            aria-label="New Password Required"
            sx={{ margin: '20px 0 10px 0' }}
          >
            New password
          </InputLabel>
          <Tooltip
            disableHoverListener
            placement={'bottom'}
            leaveTouchDelay={250}
            enterTouchDelay={50}
            leaveDelay={250}
            TransitionComponent={Zoom}
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            title={
              <>
                <Requirements
                  value={formData.newPassword || ''}
                  requirements={reqArray}
                  onValidChange={() => {}}
                />
                {
                  <>
                    <div>
                      <InfoOutlinedIcon
                        style={{
                          width: '0.7em',
                          height: '0.7em',
                          color: '#EFAB39',
                          marginRight: '5px',
                        }}
                      />
                      <span style={{ fontSize: '14px' }}>Note: Password is case sensitive</span>
                    </div>
                    <div>
                      <InfoOutlinedIcon
                        style={{
                          width: '0.7em',
                          height: '0.7em',
                          color: '#EFAB39',
                          marginRight: '5px',
                        }}
                      />
                      <span style={{ fontSize: '14px' }}>
                        Note: New password should not match last 10 passwords
                      </span>
                    </div>
                  </>
                }
              </>
            }
          >
            <OutlinedInput
              id="newPassword"
              name="newPassword"
              onChange={onFormData}
              placeholder="Enter new password"
              fullWidth="newPassword"
              data-testid="newPassword"
              inputProps={{ maxLength: 15 }}
              autoComplete="off"
              type={passwordToggle.newPassword ? 'password' : 'text'}
              value={formData.newPassword}
              endAdornment={
                <InputAdornment
                  position="end"
                  style={{
                    marginLeft: '0',
                    marginRight: '-7px',
                    maxWidth: '35px',
                  }}
                >
                  <IconButton
                    aria-label="show/hide icon"
                    onClick={(e) => passwordIconHandler('newPassword', e)}
                    tabIndex="0"
                    data-testid="hidePasswordIcon"
                  >
                    {passwordToggle.newPassword === false ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Tooltip>
          <span
            style={{
              font: 'normal normal normal 14px/16px Arial',
              letterSpacing: '0.1px',
              color: '#DF0000',
              opacity: 1,
            }}
            role="alert"
          >
            {formErrors.newPassword}
          </span>
          <InputLabel
            htmlFor="reenterPassword"
            aria-label="Confirm Password Required"
            sx={{ margin: '20px 0 10px 0' }}
          >
            Confirm password
          </InputLabel>
          <Tooltip
            disableHoverListener
            placement={'bottom'}
            leaveTouchDelay={250}
            enterTouchDelay={50}
            leaveDelay={250}
            TransitionComponent={Zoom}
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            title={
              <>
                <Requirements
                  value={formData.reenterPassword || ''}
                  requirements={reenterArray}
                  onValidChange={() => {}}
                />
                {
                  <>
                    <InfoOutlinedIcon
                      style={{
                        width: '0.7em',
                        height: '0.7em',
                        color: '#EFAB39',
                        marginRight: '5px',
                      }}
                    />
                    <span style={{ fontSize: '14px' }}>Note: Password is case sensitive</span>
                  </>
                }
              </>
            }
          >
            <OutlinedInput
              id="reenterPassword"
              name="reenterPassword"
              onChange={onFormData}
              placeholder={'Confirm new password'}
              fullWidth
              inputProps={{ maxLength: 15 }}
              autoComplete="off"
              type={passwordToggle.reenterPassword ? 'password' : 'text'}
              value={formData.reenterPassword}
              endAdornment={
                <InputAdornment
                  position="end"
                  style={{
                    marginLeft: '0',
                    marginRight: '-7px',
                    maxWidth: '35px',
                  }}
                >
                  <IconButton
                    onClick={(e) => passwordIconHandler('reenterPassword', e)}
                    tabIndex="0"
                    data-testid="reenterPasswordIcon"
                    aria-label="show/hide icon"
                  >
                    {passwordToggle.reenterPassword === false ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Tooltip>

          <span
            style={{
              font: 'normal normal normal 14px/16px Arial',
              letterSpacing: '0.1px',
              color: '#DF0000',
              opacity: 1,
            }}
            role="alert"
          >
            {formErrors.reenterPassword}
          </span>
          <Grid
            container
            justifyContent="space-between"
            columnSpacing={2}
            sx={{ marginTop: '30px' }}
          >
            <Grid item lg={6} md={6} sm={12}>
              <Button
                type="submit"
                variant="outlined"
                color="secondary"
                onClick={() => handleClose()}
                sx={{ width: '100%' }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                onClick={() => handleChangePwd()}
                disabled={submitDisabled}
                sx={{ width: '100%' }}
              >
                Change password
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ResetPasswordModal;

ResetPasswordModal.propTypes = {
  invitationExpiryCheck: PropTypes.func,
  value: PropTypes.string,
  requirement: PropTypes.instanceOf(Object),
  openResetDialog: PropTypes.bool,
  setOpenResetDialog: PropTypes.func,
  resetPwdDetails: PropTypes.instanceOf(Object),
  setIsPasswordResetSuccess: PropTypes.func,
};
ResetPasswordModal.defaultProps = {
  invitationExpiryCheck: defaultFn,
  value: '',
  requirement: {},
  openResetDialog: false,
  setOpenResetDialog: defaultFn,
  resetPwdDetails: {},
  setIsPasswordResetSuccess: defaultFn,
};
