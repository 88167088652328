import { Alert, Box, Button, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';

import { MyPromotions } from './MyPromotions';

function Promotions() {
  const navigate = useNavigate();
  const location = useLocation();
  const [alertState, setAlertState] = useState(location?.state?.msg);

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Box sx={{ width: '98%' }}>
          <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
            <Box display="flex" flexDirection="column" className={classes.dummyHome} gap={2}>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="h3">Promotions</Typography>
                <Button
                  sx={{ width: '154px', height: '40px', fontSize: '16px', padding: 0 }}
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    navigate('/create-promotion');
                  }}
                  data-testid="createPromotion"
                >
                  Create promotion
                </Button>
              </Box>
              {alertState && (
                <Alert
                  severity={'success'}
                  onClose={() => {
                    setAlertState(false);
                  }}
                  sx={{ height: '45px', width: '305px' }}
                >
                  {alertState}
                </Alert>
              )}
              <Box>
                <MyPromotions />
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
}

export default Promotions;
