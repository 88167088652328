/* eslint-disable react/prop-types */
import { Grid } from '@mui/material';
import AuthContext from 'context/AuthContext';
import { useContext } from 'react';
import { useWatch } from 'react-hook-form';

import CustomCollapse from 'components/CustomCollapse';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomNumberFormat from 'components/CustomNumberFormat/CustomNumberFormat';
import CustomPhoneNumber from 'components/CustomPhoneNumber/CustomPhoneNumber';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

const AdditionalIncome = ({
  control,
  errors,

  setValue,
  clearErrors,
  prefixName,
  couserIndex,
}) => {
  const { occupationList } = useContext(AuthContext);
  const showAddInfo = useWatch({
    control,
    name: `${prefixName}.showAddInfo`,
  });

  console.log('showAddInfo', showAddInfo);
  return (
    <>
      <CustomCollapse
        heading="Additional income"
        open={showAddInfo}
        removeButton
        clearErrorsAdditionalInfo={clearErrors}
        setValue={setValue}
        prefixName={prefixName}
        errors={errors}
      >
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              regex={/^[a-z0-9A-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\s]*$/}
              maxLength="50"
              placeholder="Enter name"
              label="Name"
              flexDirection="column"
              required
              name={`${prefixName}.addEmpName`}
              errors={!!errors?.cousersEmpinfo?.[couserIndex]?.addEmpName}
              errorText={errors?.cousersEmpinfo?.[couserIndex]?.addEmpName?.message}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              placeholder="Enter job title"
              label="Job title"
              // regex={/^[a-zA-Záéíóúñü¿¡\s]*$/g}
              maxLength="30"
              flexDirection="column"
              required
              name={`${prefixName}.addJobTitle`}
              errors={!!errors?.cousersEmpinfo?.[couserIndex]?.addJobTitle}
              errorText={errors?.cousersEmpinfo?.[couserIndex]?.addJobTitle?.message}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              control={control}
              options={occupationList}
              placeholder="Enter occupation"
              label="Occupation"
              required
              name={`${prefixName}.addOccupation`}
              errors={!!errors?.cousersEmpinfo?.[couserIndex]?.addOccupation}
              errorText={errors?.cousersEmpinfo?.[couserIndex]?.addOccupation?.message}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <CustomPhoneNumber
              label="Work phone"
              flexDirection="column"
              control={control}
              required
              name={`${prefixName}.mobile2`}
              error={!!errors?.cousersEmpinfo?.[couserIndex]?.mobile2}
              errorText={errors?.cousersEmpinfo?.[couserIndex]?.mobile2?.message}
              placeholder="Enter work phone"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              id={`${prefixName}.addMonthlyIncome`}
              dataTestId="addMonthlyIncome"
              placeholder="Enter value"
              label="Gross monthly income"
              decimalScale={2}
              control={control}
              thousandSeparator={true}
              prefix={'$ '}
              required
              name={`${prefixName}.addMonthlyIncome`}
              error={!!errors?.cousersEmpinfo?.[couserIndex]?.addMonthlyIncome}
              errorText={errors?.cousersEmpinfo?.[couserIndex]?.addMonthlyIncome?.message}
            />
          </Grid>
        </Grid>
      </CustomCollapse>
    </>
  );
};
export default AdditionalIncome;
