import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import classes from 'styles/globalStyle.module.scss';

const LatestComments = ({ comments }) => {
  return (
    <Box className={classes.tableOutline} sx={{ marginTop: '20px' }}>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ backgroundColor: '#F2F2F2', borderBottom: '1px solid #DADADA' }}
        p={2}
      >
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
            Latest comments
          </Typography>
        </Box>
      </Box>
      <Grid container>
        <Grid item lg={4} md={4} xs={3} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
          <Typography variant="subtitle2" fontWeight={700}>
            User
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} xs={4} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
          <Typography variant="subtitle2" fontWeight={700}>
            Date
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} xs={5} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
          <Typography variant="subtitle2" fontWeight={700}>
            Comment
          </Typography>
        </Grid>
        {comments.slice(0, 3).map((eachCmnt) => {
          const { comments } = eachCmnt;
          return (
            <>
              <Grid item lg={4} md={4} xs={3} p={2}>
                <Typography variant="subtitle2" sx={{ wordWrap: 'break-word' }}>
                  {comments[0]?.from}
                </Typography>
              </Grid>
              <Grid item lg={2} md={2} xs={4} p={2}>
                <Typography variant="subtitle2">
                  {moment(comments[0]?.updatedDateTime).format('MM/DD/yyyy')}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={5} p={2}>
                <Typography variant="subtitle2" sx={{ wordBreak: 'break-all' }}>
                  {comments[0]?.comment}
                </Typography>
              </Grid>
            </>
          );
        })}
      </Grid>
    </Box>
  );
};

LatestComments.propTypes = {
  comments: PropTypes.instanceOf(Object),
};
LatestComments.defaultProps = {
  comments: [],
};

export default LatestComments;
