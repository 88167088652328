import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Grid, InputLabel, Typography } from '@mui/material';
import { handleTokenExpiry, ocrFileUpload } from 'Services/commonComponentsApi';
import CloudUpload from 'assets/svg/CloudUpload.svg';
import InfoIcon from 'assets/svg/InfoIcon.svg';
import CameraIcon from 'assets/svg/cameraIcon.svg';
import UploadIcon from 'assets/svg/uploadIcon.svg';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Controller, useWatch } from 'react-hook-form';
import customClasses from 'styles/InformationRequest.module.scss';
import { cityMapping, defaultFn } from 'utils/utility';

import CameraUpload from 'components/CameraUpload/CameraUpload';
import { CustomAutoComplete } from 'components/CustomAutoComplete/CustomAutoComplete';
import CustomCollapse from 'components/CustomCollapse';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';
import CustomSwitch from 'components/CustomSwitch/CustomSwitch';

const InviResidentialAddress = ({
  control,
  errors,
  addressLookupData,
  isMailResidentSame,
  setIsMailResidentSame,
  addressLookupDataResponse,
  setValue,
  setError,
  clearErrors,
  showCamera,
  setShowCamera,
  cityList,
  setLoader,
  // setOcrDocumentId,
  stateList,
  inviUserType,
  setApiErr,
  setResCityList,
  setOldPerState,
  vouchedReturnFlag,
}) => {
  const [
    watchResAddress1,
    watchResAddress2,
    watchResCity,
    watchResZipcode,
    watchResUtilityBill,
    watchUtilitybillfile,
    watchShowUtility,
    watchDocumentList,
    watchResState,
  ] = useWatch({
    control,
    name: [
      'resAddress1',
      'resAddress2',
      'resCity',
      'resZipcode',
      'resUtilityBill',
      'utilitybillfile',
      'showUtility',
      'ocrDocumentIdList',
      'resState',
    ],
  });
  const {
    cityZipList,
    utilityBillList,
    commonToken,
    stateCityList,
    inviApplicationNum,
    setCommonToken,
  } = useContext(AuthContext);
  const fileTypes = ['JPG', 'PNG', 'PDF'];

  const [ocrChangeFlag, setOcrChangeflag] = useState(false);
  const handleChange = (filee) => {
    clearErrors('utilitybillfile');
    setOcrChangeflag(true);
    setValue('utilitybillfile', filee);
  };

  useEffect(() => {
    if (inviUserType) {
      setValue('utilitybillfile', '');
    }
  }, [inviUserType]);

  useEffect(() => {
    if (ocrChangeFlag) {
      clearErrors(['utilitybillfile', 'resUtilityBill']);
      setValue('utilitybillfile', '');
    }
  }, [watchResUtilityBill]);

  useEffect(() => {
    if (watchResUtilityBill && watchUtilitybillfile && ocrChangeFlag) {
      clearErrors(['utilitybillfile', 'resUtilityBill']);
      ocrFileUpload({
        file: watchUtilitybillfile,
        commonToken,
        typee: watchResUtilityBill,
        successCallback: ocrSuccess,
        failurecallback: ocrFailure,
        setLoader,
        applicationId: inviApplicationNum,
        setApiErr,
      });
    }
  }, [watchUtilitybillfile]);

  useEffect(async () => {
    if (watchResUtilityBill && !watchUtilitybillfile) {
      setError('utilitybillfile', {
        type: 'custom',
        message: 'Please upload the address proof file to proceed',
      });
    } else if (watchResUtilityBill === '') {
      setValue('utilitybillfile', '');
      clearErrors(['utilitybillfile', 'resUtilityBill']);
    } else if (watchUtilitybillfile && !watchResUtilityBill) {
      setError('resUtilityBill', { type: 'custom', message: 'Please select a utility bill' });
    } else {
      clearErrors(['utilitybillfile', 'resUtilityBill']);
    }
  }, [watchResUtilityBill, watchUtilitybillfile]);
  const handleStateAddress = async (AddressLine1, State) => {
    setValue('resAddress1', AddressLine1);
    setValue('resState', State);
  };
  useEffect(async () => {
    let cityListt = [];
    if (stateCityList?.length > 0) {
      cityListt = await cityMapping(stateCityList, watchResState);
    }

    setResCityList(cityListt || []);
  }, [watchResState, stateCityList]);
  useEffect(() => {
    setValue('isAddrSame', isMailResidentSame, watchResCity);
    if (isMailResidentSame) {
      setValue('perAddress1', watchResAddress1);
      setValue('perAddress2', watchResAddress2);
      setValue('perCity', watchResCity);
      setValue('perZipcode', watchResZipcode);
      setValue('perState', watchResState);
      setOldPerState(watchResState);
    }
    clearErrors(['perAddress2', 'perAddress1', 'perCountry', 'perState', 'perCity', 'perZipcode']);
  }, [
    isMailResidentSame,
    watchResAddress1,
    watchResAddress2,
    watchResCity,
    watchResZipcode,
    vouchedReturnFlag,
  ]);
  const handleAutoCompleteData = async (selectedObj) => {
    if (selectedObj && selectedObj?.Address) {
      let { AddressLine1, PostalCode, City, State } = selectedObj?.Address;
      await handleStateAddress(AddressLine1, State);

      PostalCode = PostalCode?.split('-')?.[0];
      setValue('resZipcode', PostalCode);
      const cityListt = await cityMapping(stateCityList, State);

      if (State !== 'PR') {
        setValue('resCity', City);
      } else {
        City = cityZipList.find((x) => x?.zipcode === PostalCode)?.city;
        City = cityListt.find((x) => x.value === City)?.value || '';

        clearErrors('resAddress1');
        if (!City) {
          setError('resCity', {
            type: 'custom',
            message: 'Please select a city from the list and enter the zip code',
          });
        } else {
          setValue('resCity', City);
          clearErrors(['resAddress1', 'resZipcode', 'resCity']);
        }
      }
    } else {
      setValue('resAddress1', '');
      setValue('resAddress2', '');
      setValue('resZipcode', '');
      setValue('resCity', '');
      setValue('resState', '');
    }
  };

  const ocrSuccess = (response) => {
    const { AddressLine1, AddressLine2, City, ZipCode, documentId } = response;

    if (documentId) {
      setValue(`ocrDocumentIdList`, [...watchDocumentList, documentId]);
      setValue('ocrDocumentId', documentId);
      if (AddressLine1) {
        setValue('resAddress1', AddressLine1);
        setValue('resAddress2', AddressLine2);
        setValue('resCity', City);
        setValue('resZipcode', ZipCode);
        clearErrors(['resAddress1', 'resCity', 'resZipcode']);
      }
    }
  };

  const ocrFailure = (e) => {
    setValue('ocrDocumentId', '');
    setValue('utilitybillfile', '');
    handleTokenExpiry(e, setCommonToken);
    if (e?.response?.data?.message) {
      setError('utilitybillfile', { type: 'custom', message: e?.response?.data?.message });
    } else {
      setError('utilitybillfile', {
        type: 'custom',
        message:
          'The auto address capture service is unavailable. Please upload the file and enter the details manually',
      });
    }
  };

  const handleStateChange = () => {
    setValue(`resCity`, '');
    setValue('resZipcode', '');
  };

  return (
    <>
      <CustomCollapse heading="Residential address" open={true} errors={errors}>
        {showCamera && (
          <CameraUpload
            showCamera={showCamera}
            setShowCamera={setShowCamera}
            setValue={setValue}
            clearErrors={clearErrors}
            inviFlow
          />
        )}
        <Grid container rowSpacing={1} columnSpacing={1}>
          <Grid item lg={12} md={12} sm={12} display="flex" justifyContent="space-between">
            <Typography variant="subtitle2" component="p">
              Mailing address same as the residential address
            </Typography>
            <CustomSwitch
              checked={isMailResidentSame}
              onChange={() => setIsMailResidentSame(!isMailResidentSame)}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomAutoComplete
              id="resAddress1"
              name="resAddress1"
              flexDirection="column"
              regex={/^[a-zA-Z0-9áéíóúñüÁÉÍÓÚÑÜ¿¡.,/-\s]*$/g}
              control={control}
              dataTestIdAutocomplete="autocomplete_address1"
              dataTestIdForLabel="resAddress1"
              dataTestIdForInput="addr1Input"
              required
              label="Address line 1"
              options={
                addressLookupData && watchResAddress1 && watchResAddress1?.length >= 6
                  ? addressLookupData
                  : []
              }
              handleChange={(_event, selectedValue) => {
                if (watchResAddress1 && watchResAddress1.length >= 6) {
                  const index = addressLookupData.indexOf(selectedValue);
                  const selectedObj = addressLookupDataResponse[index];
                  handleAutoCompleteData(selectedObj);
                }
              }}
              maxLength={40}
              autoFocus
              placeholder="Enter address line 1"
              fullWidth
              error={errors?.resAddress1}
              errorText={errors?.resAddress1?.message}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.resAddress2}
              errorText={errors?.resAddress2?.message}
              maxLength="20"
              regex={/^[A-Za-z0-9áéíóúñüÁÉÍÓÚÑÜ¿¡,./-\s]$/}
              placeholder="Enter address line 2"
              label="Address line 2"
              flexDirection="column"
              name="resAddress2"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="State"
              control={control}
              name="resState"
              errors={!!errors?.resState}
              errorText={errors?.resState?.message}
              options={stateList}
              defaultValue=""
              required
              displayEmpty
              isValue
              valueChange={handleStateChange}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Country"
              control={control}
              name="resCountry"
              errors={!!errors?.resCountry}
              errorText={errors?.resCountry?.message}
              options={[
                {
                  name: 'United States',
                  value: 'USA',
                },
              ]}
              defaultValue=""
              required
              displayEmpty
              isDisabled
            />
          </Grid>
          {watchResState === 'PR' && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="City"
                control={control}
                name="resCity"
                errors={!!errors?.resCity}
                errorText={errors?.resCity?.message}
                options={cityList}
                defaultValue=""
                required
                displayEmpty
              />
            </Grid>
          )}
          {watchResState !== 'PR' && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomInputBox
                control={control}
                errors={!!errors?.resCity}
                errorText={errors?.resCity?.message}
                placeholder="Enter city"
                label="City"
                name="resCity"
                regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡\s]$/}
                maxLength="15"
                type="text"
                flexDirection="column"
                required
              />
            </Grid>
          )}
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.resZipcode}
              errorText={errors?.resZipcode?.message}
              placeholder="Enter zipcode"
              label="Zip code"
              name="resZipcode"
              regex={/^\d$/}
              maxLength="5"
              type="text"
              flexDirection="column"
              required
            />
          </Grid>

          {watchShowUtility && (
            <>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomSelectBox
                  control={control}
                  options={utilityBillList || []}
                  label="Utility bill"
                  flexDirection="column"
                  name="resUtilityBill"
                  errors={!!errors?.resUtilityBill}
                  errorText={errors?.resUtilityBill?.message}
                  displayEmpty
                />
              </Grid>
              <Grid
                item
                container
                lg={12}
                md={12}
                sm={12}
                xs={12}
                display="flex"
                justifyContent="space-between"
                rowSpacing={1}
              >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <>
                    <Box display="flex" alignItems="center">
                      <InputLabel sx={{ color: '#000', width: 'unset' }}>
                        Upload home address proof
                      </InputLabel>
                      &nbsp;
                      <img src={InfoIcon} />
                    </Box>
                    <Box
                      className={customClasses.uploadContainer}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ background: 'transparent' }}
                    >
                      <img src={CloudUpload} />

                      <Typography variant="subtitle2" component="p" sx={{ padding: '10px 0' }}>
                        or
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          width: '100%',
                          padding: '0 20%',
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="secondary"
                          display="flex"
                          sx={{
                            borderRadius: '56px',
                          }}
                          onClick={() => {
                            setShowCamera(true);
                          }}
                        >
                          <img src={CameraIcon} /> &nbsp;
                          <Typography
                            variant="subtitle1"
                            component="p"
                            sx={{ fontWeight: '700' }}
                          ></Typography>
                          Camera
                        </Button>

                        <Controller
                          name="utilitybillfile"
                          control={control}
                          render={({ field }) => (
                            <FileUploader
                              handleChange={handleChange}
                              inputProps={{
                                'data-testid': 'utilitybillfile',
                              }}
                              name="file"
                              types={fileTypes}
                              onTypeError={(err) => {
                                console.log('err', err);

                                setError('utilitybillfile', {
                                  type: 'custom',
                                  message:
                                    'Only files with the following formats are allowed: PNG, JPG, PDF. Click to upload again',
                                });
                              }}
                              maxSize={10.5}
                              onSizeError={(err) => {
                                console.log('err', err);

                                setError('utilitybillfile', {
                                  type: 'custom',
                                  message:
                                    'The file exceeds the maximum upload size (10 MB). Click to upload again',
                                });
                              }}
                              {...field}
                            >
                              <>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  display="flex"
                                  sx={{ borderRadius: '56px' }}
                                >
                                  <img src={UploadIcon} /> &nbsp;
                                  <Typography
                                    variant="subtitle1"
                                    component="p"
                                    sx={{ fontWeight: '700' }}
                                  ></Typography>
                                  Upload
                                </Button>
                              </>
                            </FileUploader>
                          )}
                        />
                      </Box>

                      {/* <Typography component="p" color="red">
              {errors && errorText}
            </Typography> */}
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      sx={{ padding: '10px 0 0 0 ' }}
                    >
                      <Typography variant="body1" color="#808285" component="p">
                        Supported file types: PNG, JPG, PDF.
                      </Typography>
                      <Typography variant="body1" color="#808285" component="p">
                        Maximum: 10MB
                      </Typography>
                    </Box>
                  </>
                </Grid>
                {watchUtilitybillfile && !errors?.utilitybillfile?.message && watchShowUtility && (
                  <Box
                    display="flex"
                    gap={0.5}
                    mt={2.5}
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                  >
                    <CheckCircleIcon htmlColor="green" />
                    <Typography variant="">{watchUtilitybillfile?.name}</Typography>
                  </Box>
                )}
                <Box
                  display="flex"
                  gap={0.5}
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  mt={2.5}
                >
                  <Typography component="p" color="red">
                    {errors?.utilitybillfile?.message}
                  </Typography>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </CustomCollapse>
    </>
  );
};
export default InviResidentialAddress;

InviResidentialAddress.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.bool,
  isMailResidentSame: PropTypes.bool,
  setIsMailResidentSame: PropTypes.func,
  addressLookupData: PropTypes.array,
  addressLookupDataResponse: PropTypes.array,
  stateList: PropTypes.array,
  setValue: PropTypes.func,
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
  showCamera: PropTypes.bool,
  setShowCamera: PropTypes.func,
  cityList: PropTypes.array,
  setLoader: PropTypes.func,
  setOcrDocumentId: PropTypes.string,
  inviUserType: PropTypes.string,
  ocrDocList: PropTypes.array,
  setOcrDocList: PropTypes.func,
  setApiErr: PropTypes.func,
  setResCityList: PropTypes.func,
  isMounted: PropTypes.bool,
  vouchedReturnFlag: PropTypes.bool,
  oldState: PropTypes.string,
  setOldState: PropTypes.func,
  setOldPerState: PropTypes.func,
};
InviResidentialAddress.defaultProps = {
  control: {},
  errors: false,
  addressLookupData: [],
  isMailResidentSame: false,
  setIsMailResidentSame: defaultFn,
  addressLookupDataResponse: [],
  stateList: [],
  setValue: defaultFn,
  setError: defaultFn,
  clearErrors: defaultFn,
  showCamera: false,
  setShowCamera: defaultFn,
  cityList: [],
  setLoader: defaultFn,
  setOcrDocumentId: '',
  inviUserType: '',
  ocrDocList: [],
  setOcrDocList: defaultFn,
  setApiErr: defaultFn,
  isMounted: false,
  vouchedReturnFlag: false,
  oldState: '',
  setOldState: defaultFn,
  setOldPerState: defaultFn,
  setResCityList: defaultFn,
};
