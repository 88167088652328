import { Box, Button, Paper, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import LeftArrow from 'assets/svg/leftArrow.svg';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';

import TabPanel from 'components/TabPanel/TabPanel';

import DealerProfiles from './DealerProfiles';
import UserProfileList from './UserProfileList';
import { ViewDealer } from './ViewDealer';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ManageDealers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabVal, setTabValue] = useState(location?.state?.tabValue || 0);
  const [viewDealer, setViewDealer] = useState(false);
  const [dealer, setDealer] = useState({});
  const [tabChange, setTabChange] = useState('');

  const handleTabChange = (_event, newValue) => {
    setTabChange('Changed');
    setTabValue(newValue);
  };

  const handleBack = () => {
    setViewDealer(false);
  };

  return (
    <>
      {viewDealer ? (
        <Box>
          <Box display="flex" alignItems="center" sx={{ gap: '5px' }}>
            <img src={LeftArrow} onClick={handleBack} data-testid="back-btn" />
            <Typography variant="h3">{`View Dealer: ${dealer?.name}`}</Typography>
          </Box>
          <Box sx={{ width: '70%', margin: 'auto', alignSelf: 'center' }}>
            <ViewDealer dealer={dealer} />
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="center">
          <Box sx={{ width: '98%' }}>
            <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
              <Box display="flex" flexDirection="column" className={classes.dummyHome} gap={2}>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography variant="h3">Manage dealers</Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate('/register-user')}
                    sx={{ height: '40px' }}
                    data-testid="new-application"
                  >
                    Add user
                  </Button>
                </Box>
                <Box>
                  <Box>
                    <Tabs value={tabVal} onChange={handleTabChange} aria-label="basic tabs example">
                      <Tab label="Dealer profiles" {...a11yProps(0)} />
                      <Tab label="User profiles" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                  <TabPanel value={tabVal} index={0}>
                    <DealerProfiles setViewDealer={setViewDealer} setDealer={setDealer} />
                  </TabPanel>
                  <TabPanel value={tabVal} index={1}>
                    <UserProfileList profileSubmitted={location?.state?.profileSubmitted} tabChange={tabChange} />
                  </TabPanel>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
      )}
    </>
  );
};
