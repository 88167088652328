import { Grid } from '@mui/material';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useWatch } from 'react-hook-form';
import { defaultFn, regexAddress } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomNumberFormat from 'components/CustomNumberFormat/CustomNumberFormat';
import CustomPhoneNumber from 'components/CustomPhoneNumber/CustomPhoneNumber';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

const InviAdditionalIncome = ({
  control,
  errors,

  setValue,
  clearErrors,
}) => {
  const { occupationList } = useContext(AuthContext);
  const showAddInfo = useWatch({
    control,
    name: `showAddInfo`,
  });
  return (
    <>
      <CustomCollapse
        heading="Additional income"
        removeButton
        clearErrorsAdditionalInfo={clearErrors}
        open={showAddInfo}
        setValue={setValue}
        flow="inviFlow"
        errors={errors}
      >
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.addEmpName}
              errorText={errors?.addEmpName?.message}
              regex={regexAddress}
              maxLength="50"
              placeholder="Enter name"
              label="Name"
              name="addEmpName"
              flexDirection="column"
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.addJobTitle}
              errorText={errors?.addJobTitle?.message}
              placeholder="Enter job title"
              label="Job title"
              // regex={/^[a-zA-Záéíóúñü¿¡\s]*$/g}
              maxLength="30"
              name="addJobTitle"
              flexDirection="column"
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              control={control}
              options={occupationList}
              errors={!!errors?.addOccupation}
              errorText={errors?.addOccupation?.message}
              placeholder="Enter occupation"
              label="Occupation"
              name="addOccupation"
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomPhoneNumber
              name="mobile2"
              label="Work phone"
              flexDirection="column"
              control={control}
              error={!!errors?.mobile2}
              errorText={errors?.mobile2?.message}
              required
              placeholder="Enter work phone"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="addMonthlyIncome"
              id="addMonthlyIncome"
              dataTestId="addMonthlyIncome"
              placeholder="Enter value"
              label="Gross monthly income"
              decimalScale={2}
              control={control}
              error={!!errors?.addMonthlyIncome}
              errorText={errors?.addMonthlyIncome?.message}
              thousandSeparator={true}
              prefix={'$ '}
              required
            />
          </Grid>
        </Grid>
      </CustomCollapse>
    </>
  );
};
export default InviAdditionalIncome;

InviAdditionalIncome.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  setValue: PropTypes.func,
  clearErrors: PropTypes.func,
};
InviAdditionalIncome.defaultProps = {
  control: {},
  errors: {},
  setValue: defaultFn,
  clearErrors: defaultFn,
};
