import { regexAddress, regexChar2, regexCharNum } from 'utils/utility';
import * as yup from 'yup';

export const EmploymentInformationSchema = yup.object().shape({
  cousersEmpinfo: yup.array().of(
    yup.object().shape({
      empStatus: yup.string().required('* Mandatory field'),
      empName: yup
        .string()
        .required('* Mandatory field')
        .max(50, 'Employer name should be 50 characters or less')
        .matches(regexCharNum, 'Invalid format'),
      empAddressLine1: yup
        .string()
        .nullable()
        .max(40, 'Employer Address line 1 should be 40 characters or less')
        .matches(regexAddress, 'Invalid format'),
      empAddressLine2: yup
        .string()
        .nullable()
        .test('len', 'Employer Address line 2 should be 20 characters or less', (val) =>
          val ? val?.length <= 20 : true
        )
        .matches(regexAddress, 'Invalid format'),

      empCity: yup
        .string()
        .nullable()
        .transform((v) => (v === '' ? null : v))
        .matches(regexChar2, 'Invalid format')
        .test('len', 'Maximum 15 characters can be entered', (val) =>
          val ? val?.length <= 15 : true
        ),
      empZipcode: yup
        .string()
        .nullable()
        .transform((v) => (v === '' ? null : v))
        .matches(/^\d+$/, 'Invalid format')
        .test('len', 'Please enter a valid zipcode', (val) => (val ? parseInt(val) !== 0 : true))
        .test('len', 'Zipcode should have 5 digits', (val) => (val ? val?.length === 5 : true)),
      jobTitle: yup
        .string()
        .required('* Mandatory field')
        .max(30, 'Job title should be 30 characters or less')
        .matches(regexChar2, 'Invalid format'),
      occupation: yup.string().nullable().required('* Mandatory field'),
      industry: yup.string().required('* Mandatory field'),
      // .matches(regexChar2, 'Invalid format'),
      empYears: yup
        .string()
        .required('* Mandatory field')
        .matches(/^\d*$/, 'Invalid format')
        .test('month restriction', '* Invalid year', (val) => {
          return parseInt(val) <= 99;
        }),
      empMonths: yup
        .string()
        .required('* Mandatory field')
        .matches(/^\d*$/, 'Invalid Format')
        .test('year restriction', 'Invalid month', (val) => {
          return parseInt(val) <= 11;
        })
        .when('empYears', {
          is: (val) => parseInt(val) === 0,
          then: yup
            .string()
            .test('check for zero', 'Please enter a valid value', (val) => parseInt(val) !== 0),
        }),
      monthlyIncome: yup
        .string()
        .nullable()
        .required('* Mandatory field')
        .transform((value) => {
          // Remove the % sign and any non-numeric characters
          return value ? value.replace(/[^0-9.]/g, '') : null;
        }),
      mobile: yup
        .string()
        .required('* Mandatory field')
        .matches(/^\d+$/g, 'Invalid format')
        .test('length', 'Work phone should have 10 digits', (val) => {
          return val?.length === 10;
        }),

      addEmpName: yup.string().when('showAddInfo', {
        is: true,
        then: yup
          .string()
          .required('* Mandatory field')
          .max(50, 'Employer name should be 50 characters or less')
          .matches(regexCharNum, 'Invalid format'),
      }),
      addJobTitle: yup.string().when('showAddInfo', {
        is: true,
        then: yup
          .string()
          .required('* Mandatory field')
          .max(30, 'Job title should be 30 characters or less')
          .matches(regexChar2, 'Invalid format'),
      }),
      addOccupation: yup.string().when('showAddInfo', {
        is: true,
        then: yup.string().nullable().required('* Mandatory field'),
      }),
      mobile2: yup.string().when('showAddInfo', {
        is: true,
        then: yup
          .string()
          .required('* Mandatory field')
          .matches(/^\d+$/g, 'Invalid format')
          .test('length', 'Work phone should have 10 digits', (val) => {
            return val?.length === 10;
          }),
      }),

      addMonthlyIncome: yup.string().when('showAddInfo', {
        is: true,
        then: yup
          .string()
          .nullable()
          .required('* Mandatory field')
          .transform((value) => {
            // Remove the % sign and any non-numeric characters
            return value ? value.replace(/[^0-9.]/g, '') : null;
          }),
      }),
    })
  ),
});

export const FormatValidationSchema = yup.object().shape({
  cousersEmpinfo: yup.array().of(
    yup.object().shape({
      empStatus: yup.string(),
      empName: yup
        .string()
        .nullable()
        .max(50, 'Employer name should be 50 characters or less')
        .matches(regexCharNum, 'Invalid format'),
      empAddressLine1: yup
        .string()
        .max(40, 'Employer Address line 1 should be 40 characters or less')
        .matches(regexAddress, 'Invalid format'),
      empAddressLine2: yup
        .string()
        .nullable()
        .test('len', 'Employer Address line 2 should be 20 characters or less', (val) =>
          val ? val?.length <= 20 : true
        )
        .matches(regexAddress, 'Invalid format'),
      empCountry: yup.string(),
      empState: yup.string(),
      empCity: yup
        .string()
        .nullable()
        .transform((v) => (v === '' ? null : v))
        .matches(regexChar2, 'Invalid format')
        .test('len', 'Maximum 15 characters can be entered', (val) =>
          val ? val?.length <= 15 : true
        ),
      empZipcode: yup
        .string()
        .test('len', 'Please enter a valid zipcode', (val) => (val ? parseInt(val) !== 0 : true))
        .test('len', 'Zipcode should have 5 digits', (val) => (val ? val?.length === 5 : true)),
      jobTitle: yup
        .string()
        .max(30, 'Job title should be 30 characters or less')
        .matches(regexChar2, 'Invalid format'),
      occupation: yup.string().nullable(),
      industry: yup.string(),
      // .matches(regexChar2, 'Invalid format'),
      empYears: yup.string().test('month restriction', 'Invalid year', (val) => {
        return val ? parseInt(val) <= 99 : true;
      }),
      empMonths: yup
        .string()
        .test('year restriction', 'Invalid month', (val) => {
          return val ? parseInt(val) <= 11 : true;
        })
        .when('empYears', {
          is: (val) => parseInt(val) === 0,
          then: yup
            .string()
            .test('check for zero', 'Please enter a valid value', (val) =>
              val ? parseInt(val) !== 0 : true
            ),
        }),
      monthlyIncome: yup
        .string()
        .nullable()
        .transform((value) => {
          // Remove the % sign and any non-numeric characters
          return value ? value.replace(/[^0-9.]/g, '') : null;
        }),
      mobile: yup
        .string()
        .nullable()
        .transform((v) => (v === '' ? null : v))
        .matches(/^\d+$/g, 'Invalid format')
        .test('length', 'Work phone should have 10 digits', (val) => {
          return val ? val?.length === 10 : true;
        }),

      addEmpName: yup.string().when('showAddInfo', {
        is: true,
        then: yup
          .string()
          .test('name length limit', 'Employer name should be 50 characters or less', (val) => {
            return val ? val?.length <= 50 : true;
          })
          .matches(regexCharNum, 'Invalid format'),
      }),
      addJobTitle: yup.string().when('showAddInfo', {
        is: true,
        then: yup
          .string()
          .test('name length limit', 'Job title should be 30 characters or less', (val) => {
            return val ? val?.length <= 30 : true;
          })

          .matches(regexChar2, 'Invalid format'),
      }),
      addOccupation: yup.string().nullable(),
      mobile2: yup.string().when('showAddInfo', {
        is: true,
        then: yup
          .string()
          .nullable()
          .transform((v) => (v === '' ? null : v))
          .matches(/^\d+$/g, 'Invalid format')
          .test('length', 'Work phone should have 10 digits', (val) => {
            return val ? val?.length === 10 : true;
          }),
      }),

      addMonthlyIncome: yup.string().when('showAddInfo', {
        is: true,
        then: yup
          .string()
          .nullable()
          .transform((value) => {
            // Remove the % sign and any non-numeric characters
            return value ? value.replace(/[^0-9.]/g, '') : null;
          }),
      }),
    })
  ),
});
