import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Avatar,
  Badge,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Tooltip, // alpha,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ApiClass from 'Api/ApiClient';
import LogoutIcon from 'assets/svg/LogoutIcon.svg';
import NotificationIcon from 'assets/svg/NotificationIcon.svg';
import QuestionsIcon from 'assets/svg/QuestionsIcon.svg';
import peopleIcon from 'assets/svg/people.svg';
import profileSettingIcon from 'assets/svg/profileSettingIcon.svg';
import AuthContext from 'context/AuthContext';
import { DrawerContent } from 'pages/Home/HeaderDrawer';
import NotificationSnackBar from 'pages/Home/NotificationSnackbar';
import { draftExpiry, statusTransition } from 'pages/Home/notificationRouting';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import classes from 'styles/header.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { parseJwt } from 'utils';
import { handleLanguageChange, handleLanguageClose, handleLanguageMenu } from 'utils/utility';

import { HeaderLanguageComponent } from 'components/CommonHeader/CommonHeader';
import ErrorSnackbar from 'components/ErrorSnackbar';
import Loader from 'components/Loader/Loader';

const Header = () => {
  const ApiClient = new ApiClass();
  const {
    userType,
    idToken,
    userID,
    role,
    setCreateAppApplicationId,
    setProgram,
    setDealer,
    setVehicleType,
    setCreateAppLaunchType,
    setCollateralInfo,
    setDraftFlag,
    setCreateAppFlag,
    setOpenPopUp,
    setPopupStep,
    setIsLogoutDialogOpen,
    viewApprovalQueue,
    fullName,
    accessToken,
    setAccessToken,
    setAccessTokenFromIdToken,
    setGeneratedDoc,
    profilePic,
    setProfilePic,
    accessTokenFromIdToken,
  } = useContext(AuthContext);
  const [userName, setUserName] = useState('');
  const [avatarName, setAvatarName] = useState('');
  const [notificationList, setNotificationList] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackBarOpen] = useState(false);
  useEffect(() => {
    const parsedToken = parseJwt(idToken);
    let { name, accessToken: accessTokenn } = parsedToken;
    if (accessTokenn) {
      setAccessTokenFromIdToken(accessTokenn);
      sessionStorage.setItem('accessTokenFromIdToken', accessTokenn);
    }
    if (name) {
      setUserName(name);
      let a = name?.split(' ');
      let firstName = a[0]?.split('');
      let lastName = a[1]?.split('');
      let avatarContent = !lastName ? 'B' : firstName?.[0] + lastName?.[0];

      setAvatarName(avatarContent);
    }
  }, []);

  const logOut = async () => {
    setIsLogoutDialogOpen(true);
  };

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationAnchor, setNotificationAnchor] = useState(false);
  const isOpen = Boolean(anchorEl);
  const [languageAnchor, setLanguageAnchor] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(0);
  const isLanguageOpen = Boolean(languageAnchor);
  const [loader, setLoader] = useState(false);
  const options = ['English', 'Espanol'];
  const [socket, setSocket] = useState(null);
  const [snooze, setSnooze] = useState(false);
  console.log('socket', socket);
  const [alertmsg, setAlertMsg] = useState('');
  const tokenRefreshTimer = process.env.REACT_APP_REFRESH_TOKEN_TIMER;
  const accessTokenFromIdTokens = sessionStorage.getItem('accessTokenFromIdToken');
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleDrawer = (anchorType, anchorState) => {
    if (anchorType === 'notification') {
      setNotificationAnchor(anchorState);
    }
  };

  const getNotifications = async () => {
    try {
      setLoader(true);
      const resp = await ApiClient.get(`${UrlHelpers.notification}/?limit=20`, {
        headers: { 'Content-Type': 'application/json', userId: userID, appId: 'auto' },
      });

      console.log('into notification', { resp });
      if (resp?.length) {
        setNotificationList(resp);
      }
    } catch (e) {
      console.log('err in get notification api', e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(async () => {
    const webSocket = new WebSocket(UrlHelpers?.wsNotification);
    if (userID) {
      console.log('into ws open');
      initWebSocketsEvents(webSocket);
      await getNotifications();
    }

    return () => {
      console.log('into ws close');
      webSocket.close();
    };
  }, [userID]);

  useEffect(async () => {
    if (Object.entries(alertmsg)?.length) {
      setSnackBarOpen(true);
      await getNotifications();
    }
  }, [alertmsg]);

  const initWebSocketsEvents = (webSocket) => {
    let ws = webSocket;
    ws.onopen = function () {
      console.log('connection is opened');
      let loginObj = {
        message: {
          channel: 'notification-channel',
          userId: userID,
          action: 'LOGIN',
        },
        action: 'onmessage',
      };
      let pingObj = {
        action: 'onmessage',
        message: { action: 'PING', userId: userID, channel: 'notification-channel' },
      };

      ws.send(JSON.stringify(loginObj));
      setInterval(() => {
        ws.send(JSON.stringify(pingObj));
      }, 120000);
    };

    ws.onmessage = async (evt) => {
      let message = evt.data;
      console.log('response:', message);
      let response = JSON.parse(message);
      console.log('response body', response?.body);
      if (response?.body) {
        try {
          let msgObj = JSON.parse(response?.body);
          if (msgObj.type === 'contractGenerate') {
            await setGeneratedDoc((prev) => [...prev, msgObj.value]);
            console.log('response body ', [msgObj.value]);
          } else {
            setAlertMsg(msgObj);
          }
        } catch (e) {
          console.log('e', e);
        }
      }
    };

    ws.onclose = function () {
      console.log('Connection is closed...');
    };

    ws.onerror = function (error) {
      console.error(error);
      console.log('Error occurred, check the console!', error);
    };
    setSocket(ws);
  };
  useEffect(() => {
    if (!viewApprovalQueue && accessTokenFromIdToken) {
      onLoadApiProfilePic();
    }
  }, [accessTokenFromIdToken]);
  const onLoadApiProfilePic = async () => {
    try {
      setLoader(true);
      let onLoadProfileData = await ApiClient.get(`${UrlHelpers?.onLoadprofileData}`, {
        headers: {
          appid: 'auto',
          roleid: role,
          user: accessTokenFromIdTokens,
          'Content-Type': 'application/json',
        },
      });
      if (onLoadProfileData) {
        setProfilePic(onLoadProfileData?.picture);
      }
    } catch (e) {
      console.log('error fetching', e);

      // setApiErr(e?rrorHandlingMessage[e?.response?.data?.errorCode || 'defaultCode']?.message);
    } finally {
      setLoader(false);
    }
  };

  const openNotification = async ({ snackbar = false, notificationObj }) => {
    let eventName, applicationId, referenceId;

    if (snackbar) {
      eventName = alertmsg?.eventName;
      applicationId = alertmsg?.applicationId;
      referenceId = alertmsg?.referenceId;
      setSnackBarOpen(false);
      setSnooze(false);
      await onNotifyClose(alertmsg.notificationId);
    } else {
      eventName = notificationObj?.eventName;
      applicationId = notificationObj?.applicationId;
      referenceId = notificationObj?.referenceId;
      await onNotifyClose(notificationObj.notificationId);
    }
    navigateRoute({ eventName, applicationId, referenceId, snackbar, notificationObj });
  };

  const navigateRoute = async ({
    eventName,
    applicationId,
    referenceId,
    snackbar,
    notificationObj,
  }) => {
    switch (eventName) {
      case 'UPDATE_APP_STATUS':
        {
          let viewResp = await statusTransition({ applicationId, setLoader, userID, fullName });
          if (viewResp.success) {
            navigate('/applications/viewApp', {
              state: { applicationDetails: { ...viewResp, initAppId: applicationId } },
            });
          } else {
            setErrorSnackBarOpen(true);
          }
        }
        break;
      case 'DISCARD_APPLICATION':
        {
          const resp = await draftExpiry({
            applicationId,
            setLoader,
            setCreateAppApplicationId,
            setProgram,
            setDealer,
            setVehicleType,
            setCreateAppLaunchType,
            setCollateralInfo,
            setDraftFlag,
            setCreateAppFlag,
            setOpenPopUp,
            setPopupStep,
            userID,
            fullName,
          });
          console.log('resp', resp);
          if (resp?.navigateFlag) {
            const { activeStep = 1, userType, completedSteps, draftFlag, draftOpen } = resp;
            navigate('/home/create-app', {
              state: {
                activeStep,
                completedSteps,
                userType,
                draftFlag,
                draftOpen,
              },
            });
          } else if (!resp?.success) {
            setErrorSnackBarOpen(true);
          }
        }
        break;
      case 'PARTICIPANT_SUBMIT_INVITATION':
        navigate('/applications', {
          state: { notifyAppIdState: applicationId, tabValue: 1 },
        });
        break;
      case 'COMMENT_ADDED':
        {
          let viewResp = await statusTransition({ applicationId, setLoader, userID, fullName });
          if (viewResp.success) {
            navigate('/applications/viewApp', {
              state: { applicationDetails: { ...viewResp, initAppId: applicationId }, tabValue: 4 },
            });
          }
        }
        break;
      case 'NEW_USER_REQUEST_REJECT':
      case 'NEW_USER_REQUEST_APPROVED':
        navigate('/user-request', { state: { reqId: referenceId } });
        break;
      case 'NEW_USER_REQUEST':
        navigate('/user-request', { state: { reqId: referenceId } });
        break;
      case 'AUTO REPORTS':
        navigate('/reports', {
          state: {
            notificationId: snackbar ? alertmsg?.notificationId : notificationObj?.notificationId,
            errorFlag: referenceId === 'NA' ? 'error' : '',
            notificationObj: snackbar ? alertmsg : notificationObj,
          },
        });
        break;
    }
  };

  const onNotifyClose = async (id) => {
    if (id) {
      let removedList = notificationList.filter((x) => x.notificationId !== id);
      setNotificationList(removedList);
    }
    let markReadUrl = id
      ? `${UrlHelpers.notification}/?notificationId=${id}`
      : UrlHelpers.notification;
    try {
      setLoader(true);
      await ApiClient.put(
        markReadUrl,
        {},
        {
          headers: { 'Content-Type': 'application/json', userId: userID },
        }
      );
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (snooze && !snackBarOpen) {
      const interval = setInterval(() => {
        setSnackBarOpen(true);
      }, 300000);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
  }, [snooze]);

  useEffect(() => {
    if (accessToken && userID) {
      console.log('intoooo 1');
      const timer = setInterval(async () => {
        try {
          console.log('into timer func');
          const resp = await ApiClient.get(UrlHelpers.refreshToken, {
            headers: {
              'Content-Type': 'application/json',
              user: userID,
              appId: 'auto',
            },
          });
          console.log('refrsh token resp', resp);
          if (resp?.accessToken) {
            sessionStorage.setItem('accessToken', resp?.accessToken);
            setAccessToken(resp?.accessToken);

            const parsedToken = parseJwt(resp?.idToken);
            let { accessToken: accessTokenn } = parsedToken;
            if (accessTokenn) {
              console.log({ accessTokenn });
              sessionStorage.setItem('accessTokenFromIdToken', accessTokenn);

              setAccessTokenFromIdToken(accessTokenn);
            }
          }
        } catch (e) {
          console.log('err in getting refresh token', e);
        }
      }, tokenRefreshTimer * 1000);
      return () => clearInterval(timer);
    }
  }, [userID, accessToken]);
  const dealershipUserAccess = ['DEALER', 'DEALER 2', 'MANAGER', 'MANAGER 2'];
  return (
    <>
      <ErrorSnackbar snackBarOpen={errorSnackbarOpen} setSnackBarOpen={setErrorSnackBarOpen} />
      <NotificationSnackBar
        snackBarOpen={snackBarOpen}
        setSnackBarOpen={setSnackBarOpen}
        alertmsg={alertmsg}
        onOpen={() => openNotification({ snackbar: true })}
        setSnooze={setSnooze}
        getNotifications={getNotifications}
      />
      <Grid sx={{ flexGrow: 1 }}>
        <Loader open={loader} />
        <AppBar position="static" sx={{ backgroundColor: 'white' }}>
          <Toolbar>
            {userType === 'dealer' && (
              <>
                {' '}
                <Typography variant="h2" component="div" className={classes.portalHeading}>
                  Dealer Portal
                </Typography>
              </>
            )}
            {userType === 'banker' && (
              <>
                {' '}
                <Typography variant="h2" component="div" className={classes.portalHeading}>
                  Digital Portal
                </Typography>
              </>
            )}
            <DrawerContent
              notificationAnchor={notificationAnchor}
              toggleDrawer={toggleDrawer}
              notificationList={notificationList}
              setNotificationList={setNotificationList}
              callBack={getNotifications}
              openNotification={({ notificationObj }) =>
                openNotification({ snackbar: false, notificationObj })
              }
              onNotifyClose={onNotifyClose}
            />
            <HeaderLanguageComponent
              isLanguageOpen={isLanguageOpen}
              options={options}
              handleLanguageChange={handleLanguageChange}
              setLanguageAnchor={setLanguageAnchor}
              selectedLanguage={selectedLanguage}
              languageAnchor={languageAnchor}
              handleLanguageClose={handleLanguageClose}
              handleLanguageMenu={handleLanguageMenu}
              setSelectedLanguage={setSelectedLanguage}
            />

            <Button color="inherit" className={classes.headerIcons}>
              <img src={QuestionsIcon} alt="Dealer Portal Logo" />
            </Button>
            <Button
              color="inherit"
              className={classes.headerIcons}
              onClick={() => toggleDrawer('notification', true)}
              data-testid="notificationIcon"
            >
              <Tooltip
                title={`You have ${
                  notificationList?.filter((x) => x.status === 1)?.length
                } new notification(s)`}
                componentsProps={{
                  tooltip: {
                    sx: {
                      width: '125px',

                      textAlign: 'center',
                      lineHeight: '16px',
                    },
                  },
                }}
                placement="bottom"
                arrow
              >
                {notificationList?.filter((x) => x.status === 1)?.length ? (
                  <Badge
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    color="primary"
                    sx={{
                      '.MuiBadge-badge': {
                        margin: '5px 5px 0 0',
                      },
                    }}
                    variant="dot"
                  >
                    <img src={NotificationIcon} alt="Dealer Portal Logo" />
                  </Badge>
                ) : (
                  <img src={NotificationIcon} alt="Dealer Portal Logo" />
                )}
              </Tooltip>
            </Button>

            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              px={2}
              className={classes.verticalDivider}
            />

            <Box>
              <Button
                id="demo-customized-button"
                aria-controls={isOpen ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isOpen ? 'true' : undefined}
                disableElevation
                onClick={handleClick}
                data-testid="profileMenu"
                endIcon={<KeyboardArrowDownIcon />}
                sx={{ color: '#404040' }}
              >
                <Box className={classes.avatarBorder}>
                  <Avatar alt={userName} src={profilePic}>
                    {avatarName}
                  </Avatar>
                </Box>
              </Button>
              <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
                <MenuItem
                  variant="subtitle2"
                  disableRipple
                  classes={{ root: classes.imgPadding }}
                  onClick={() => {
                    setAnchorEl(null);
                    console.log({ viewApprovalQueue });
                    if (!viewApprovalQueue) {
                      navigate('/profile-view');
                    }
                  }}
                >
                  <img src={profileSettingIcon} />
                  Profile settings
                </MenuItem>
                {dealershipUserAccess?.includes(role) && (
                  <MenuItem
                    variant="subtitle2"
                    disableRipple
                    classes={{ root: classes.imgPadding }}
                    onClick={() => {
                      setAnchorEl(null);
                      navigate('/dealership-users');
                    }}
                  >
                    <img src={peopleIcon} />
                    Dealership users
                  </MenuItem>
                )}

                {(!userType || userType === 'dealer') && (
                  <MenuItem
                    variant="subtitle2"
                    onClick={logOut}
                    disableRipple
                    className={classes.imgPadding}
                  >
                    <img src={LogoutIcon} />
                    Logout
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
      </Grid>
    </>
  );
};

export default Header;

Header.propTypes = {
  open: PropTypes.bool,
};
Header.defaultProps = {
  open: false,
};
