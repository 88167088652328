import { yupResolver } from '@hookform/resolvers/yup';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import RegisterUserContext from 'context/RegisterUserContext';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { enrollDealerUserProfileSchema } from 'schemaValidations/enrollDealerUserProfileSchema';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn, handleFocusError, updateCompletedSteps } from 'utils/utility';

import Loader from 'components/Loader/Loader';

import UserProfileForm from '../UserProfileForm/UserProfileForm';

const UserProfile = ({ handleCancel }) => {
  const { setOpenDiscardModal, userName } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);

  const ApiClient = new ApiClass();
  const navigate = useNavigate();
  const {
    control,
    watch,
    handleSubmit,
    setFocus,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: 'all',

    defaultValues: {
      securityProfile: '',
    },
    resolver: yupResolver(enrollDealerUserProfileSchema),
  });
  const {
    activeStep,
    setActiveStep,
    formDetails,
    setFormDetails,
    eachFormData,
    setEachFormData,
    isFormFilled,
    setIsFormFilled,
    isReview,
    setIsReview,
    isViewProfile,
    fullProfileData,
    dealersTableDirty,
    formDirty,
    setFormDirty,
    completedSteps,
    setCompletedSteps,
  } = useContext(RegisterUserContext);
  const [watchsecurityProfile] = watch(['securityProfile']);
  useEffect(() => {
    handleFocusError({ errors, setFocus });
  }, [errors]);
  const continueNext = () => {
    const formObj = {
      securityProfile: watchsecurityProfile,
      dealerList: formDetails?.dealers?.map((d) => d.dealerId) || [],
    };
    setFormDirty({ ...formDirty, ...dirtyFields });
    setFormDetails({ ...formDetails, ...formObj });
    const eachData = { ...eachFormData };
    eachData['3'] = { ...eachData['3'], ...formObj };
    setEachFormData(eachData);

    setActiveStep(activeStep + 1);
  };
  const updateData = async () => {
    const formObject = {
      securityProfile: watchsecurityProfile,
      dealers: formDetails?.dealers?.map((d) => d.dealerId) || [],
    };
    const newObj = Object.keys(dirtyFields).reduce((a, v) => ({ ...a, [v]: formObject[v] }), {
      editedBy: userName,
    });
    if (dealersTableDirty) {
      newObj.dealers = formObject.dealers;
    }
    try {
      setLoader(true);
      const r = await ApiClient.post(
        UrlHelpers.updateUserInfo + fullProfileData.userId,
        { ...newObj },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      console.log(r);
      navigate('/view-user-profile', {
        state: { updateStatus: 'success', profileData: { userId: fullProfileData.userId } },
      });
    } catch (e) {
      console.log('error fetching', e);
    } finally {
      setLoader(false);
    }
  };
  const nextStep = () => {
    const formObj = {
      securityProfile: watchsecurityProfile,
    };
    setFormDetails({ ...formDetails, ...formObj });
    const eachData = { ...eachFormData };
    eachData['3'] = formObj;
    setEachFormData(eachData);
    const formFilled = isFormFilled;
    formFilled['3'] = true;
    setIsFormFilled(formFilled);
    if (isReview) {
      setIsReview(false);
      setActiveStep(4);
    } else {
      setActiveStep(activeStep + 1);
    }
    updateCompletedSteps([1, 2, 3], completedSteps, setCompletedSteps);
  };
  const applyFormFields = async (formFieldss) => {
    Object.entries(formFieldss).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  useEffect(() => {
    if (isFormFilled['3'] === true) {
      applyFormFields(eachFormData['3']);
    }
  }, []);
  console.log(formDetails.dealers);
  return (
    <>
      {loader && <Loader open={loader} />}
      <Typography variant="h3" component="p" pt={4} px={8}>
        User profile
      </Typography>
      <Grid container justifyContent="center">
        <Box sx={{ width: '70%' }}>
          <UserProfileForm
            dealerList={eachFormData['3'].dealerList}
            isViewProfile={isViewProfile}
            control={control}
            errors={errors}
          />
        </Box>
      </Grid>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap={0.5} alignItems="center">
          <KeyboardArrowLeftIcon color="secondary" />
          <Link
            component="button"
            variant="subtitle2"
            color="secondary"
            onClick={() => {
              setActiveStep(2);
            }}
          >
            Back
          </Link>
        </Box>
        {!isViewProfile ? (
          <Box display="flex" gap={2} py={4} marginLeft={'auto'}>
            <Link
              component="button"
              onClick={() => {
                setOpenDiscardModal(true);
              }}
              variant="subtitle2"
              color="secondary"
              sx={{ fontSize: '16px' }}
              data-testid="discard-userprofile-application"
            >
              Discard application
            </Link>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSubmit(nextStep)}
              disabled={
                (formDetails?.dealers && formDetails?.dealers.length < 1) ||
                Object.entries(errors)?.length
              }
              data-testid="save-continue-userprofile"
            >
              Continue
            </Button>
          </Box>
        ) : (
          <Box display="flex" gap={2} py={4} marginLeft={'auto'}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              // sx={{ maxHeight: '40px' }}
              data-testid="cancel-userinfo-button"
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSubmit(updateData)}
              disabled={Object.entries(errors)?.length}
              data-testid="save-update-view-userprofile"
            >
              Update
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSubmit(continueNext)}
              disabled={Object.entries(errors)?.length}
              data-testid="save-continue-view-userprofile"
            >
              Continue
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default UserProfile;

UserProfile.propTypes = {
  handleCancel: PropTypes.func,
};

UserProfile.defaultProps = {
  handleCancel: defaultFn,
};
