import { Grid } from '@mui/material';
import AuthContext from 'context/AuthContext';
import { dealersRoleBased } from 'pages/CreateApplication/InformationRequest/moduleUtilities/dropdownApis';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { defaultFn } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';
import CustomNumberFormat from 'components/CustomNumberFormat/CustomNumberFormat';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

import constants from '../../utils/constants';

const { insurance } = constants;
const Insurance = ({ control, errors, setValue, handleVehicleInsuranceAmtChange }) => {
  const [watchCreditLife, watchVehicleInsuranceType, watchIsInsuranceOpen] = useWatch({
    control,
    name: ['creditLife', 'vehicleInsuranceType', 'isInsuranceOpen'],
  });
  const {
    rolebasedDealers,
    setRoleBasedDealers,
    role,
    userID,
    dealer,
    createAppLaunchType,
    creditLifeTypeList,
    creditLifeList,
    vehicleInsuranceTypeList,
  } = useContext(AuthContext);
  console.log({ watchIsInsuranceOpen });
  useEffect(async () => {
    await dealersRoleBased({ rolebasedDealers, setRoleBasedDealers, role, userID });
    setValue('dealer', dealer);
  }, []);
  return (
    <div>
      <CustomCollapse heading={insurance} open={watchIsInsuranceOpen} errors={errors}>
        <Grid container my={0.5} columnSpacing={4} rowSpacing={1}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Credit life"
              control={control}
              name="creditLife"
              errors={!!errors?.creditLife}
              errorText={errors?.creditLife?.message}
              options={creditLifeList}
              defaultValue=""
              displayEmpty
              // isDisabled
            />
          </Grid>
          {watchCreditLife?.toLowerCase() === 'yes' && (
            <>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomSelectBox
                  flexDirection="column"
                  label="Credit life type"
                  control={control}
                  name="creditLifeType"
                  errors={!!errors?.creditLifeType}
                  errorText={errors?.creditLifeType?.message}
                  options={creditLifeTypeList}
                  defaultValue=""
                  required={watchCreditLife?.toLowerCase() === 'yes'}
                  displayEmpty
                />
              </Grid>
            </>
          )}
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              name="vehicleInsuranceType"
              label="Vehicle insurance type"
              control={control}
              errors={!!errors.vehicleInsuranceType}
              errorText={errors?.vehicleInsuranceType?.message}
              options={vehicleInsuranceTypeList}
              defaultValue="single"
              displayEmpty
              isValue
              valueChange={handleVehicleInsuranceAmtChange}
            />
          </Grid>

          {/* Fields on left */}
          {watchCreditLife?.toLowerCase() === 'yes' && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomNumberFormat
                name="creditLifeAmount"
                label="Credit life insurance amount"
                id="creditLifeAmount"
                dataTestId="creditLifeAmount"
                placeholder="Enter value"
                control={control}
                error={!!errors?.creditLifeAmount}
                errorText={errors?.creditLifeAmount?.message}
                thousandSeparator={true}
                required={watchCreditLife?.toLowerCase() === 'yes'}
                disabled
                prefix={'$ '}
                decimalScale={2}
                defaultValue={'0'}
              />
            </Grid>
          )}

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              id="vehicleInsuranceAmount"
              dataTestId="vehicleInsuranceAmount"
              placeholder="Enter value"
              name="vehicleInsuranceAmount"
              label="Vehicle insurance amount"
              control={control}
              error={!!errors?.vehicleInsuranceAmount}
              errorText={errors?.vehicleInsuranceAmount?.message}
              disabled={watchVehicleInsuranceType?.toLowerCase() !== 'double'}
              decimalScale={2}
              thousandSeparator={true}
              prefix={'$ '}
              required={watchVehicleInsuranceType}
              defaultValue={'0'}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              id="gapFee"
              dataTestId="gapFee"
              placeholder="Enter gap fee"
              name="gapFee"
              label="Gap fee"
              decimalScale={2}

              control={control}
              error={!!errors?.gapFee}
              errorText={errors?.gapFee?.message}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>
          {createAppLaunchType !== 'dashboard' && watchCreditLife?.toLowerCase() === 'yes' && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="Dealer"
                control={control}
                name="dealer"
                errors={!!errors?.dealer}
                errorText={errors?.dealer?.message}
                options={rolebasedDealers?.map((dealers) => ({
                  name: dealers.dealerName,
                  value: dealers.dealerId,
                }))}
                defaultValue=""
                required={watchCreditLife?.toLowerCase() === 'yes'}
                displayEmpty
                isDisabled={createAppLaunchType === 'dashboard' || createAppLaunchType === 'lookup'}
              />
            </Grid>
          )}
        </Grid>
      </CustomCollapse>
    </div>
  );
};

export default Insurance;

Insurance.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  watch: PropTypes.func,
  watchIsInsuranceOpen: PropTypes.bool,
  setValue: PropTypes.func,
  trigger: PropTypes.func,
  handleVehicleInsuranceAmtChange: PropTypes.func,
  clearErrors: PropTypes.func,
};
Insurance.defaultProps = {
  control: {},
  errors: {},
  watch: defaultFn,
  setValue: defaultFn,
  trigger: defaultFn,
  clearErrors: defaultFn,
  handleVehicleInsuranceAmtChange: defaultFn,
  watchIsInsuranceOpen: false,
};
