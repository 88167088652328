import { regexChar2, regexEmail2 } from 'utils/utility';
import * as yup from 'yup';

export const enrollDealerUserSchema = yup.object().shape({
  userId: yup
    .string()
    .required('* Mandatory field')
    .matches(/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\d]*$/, 'Invalid format')
    .test(
      'len',
      'Minimum 7 characters and maximum 15 characters can be entered',
      (val) => val?.length >= 7 && val?.length <= 15
    )
    .matches(/d*[a-zA-Z]/, '* Must contain 1 letter '),
  firstName: yup
    .string()
    .required('* Mandatory field')
    .test('len', 'Maximum 25 characters can be entered', (val) => val?.length <= 25)
    .matches(regexChar2, 'Invalid format'),

  lastName: yup
    .string()
    .required('* Mandatory field')
    .test('len', 'Maximum 25 characters can be entered', (val) => val?.length <= 25)
    .matches(regexChar2, 'Invalid format'),

  secondLastName: yup
    .string()
    .nullable()
    .test('len', 'Maximum 25 characters can be entered', (val) => (val ? val?.length <= 25 : true))
    .matches(/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡.'\s]*$/g, 'Invalid format'),
  email: yup
    .string()
    .required('* Mandatory field')
    .matches(regexEmail2, '*Invalid email format')
    .max(50, '* Invalid email length'),
  mobile: yup
    .string()
    .required('* Mandatory field')
    .test('length', '* Mobile number should have 10 digits', (val) => {
      return val?.length === 10;
    })
    .matches(/^\d+$/g, 'Invalid format'),

  includeSalesManager: yup.string().required('* Mandatory field').nullable(),
  includeReference: yup.string().required('* Mandatory field').nullable(),
});
