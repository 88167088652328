/* eslint-disable react/prop-types */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import ApiClass from 'Api/ApiClient';
import { LockReleaseApi } from 'Services/LockReleaseApi';
import CoApplicant from 'assets/svg/coApplicant.svg';
import CoSigner from 'assets/svg/coSigner.svg';
import AuthContext from 'context/AuthContext';
import CreateApplicationContext from 'context/CreateApplicationContext';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CoAppDialogSchema } from 'schemaValidations/CreateApplicationSchema';
import globalClasses from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { v4 as uuidv4 } from 'uuid';

import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomToggleButtonGroup from 'components/CustomToggleButtonGroup/CustomToggleButtonGroup';
import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

import ApplicationDialogHeading from '../InformationRequest/moduleUtilities/ApplicationDialogHeading';

const CoAppDialog = ({
  reset,
  control1,
  getValues,
  setValue1,
  watch,
  setCosignerEnabled,
  setCosignerContainer,
}) => {
  const {
    showCoApp,
    setShowCoApp,
    setShowCoAppDet,
    setShowInviManual,
    setShowAppType,
    setIsSkipped,
    setPrimaryApplicantData,
  } = useContext(CreateApplicationContext);
  const [loader, setLoader] = useState(false);
  const [apiErr, setApiErr] = useState('');
  const {
    createAppApplicationId,
    setOpenPopUp,
    setCreateAppLookup,
    setCreateAppFlag,
    setCreateAppLaunchType,
    setDealer,
    userID,
    fullName,
  } = useContext(AuthContext);
  const watchApplicantOrSigner = watch('applicantOrSigner');
  const ApiClient = new ApiClass();
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues: {
      primaryAppId: '',
      primaryAppFirstName: '',
      primaryAppLastName: '',
    },
    resolver: yupResolver(CoAppDialogSchema),
  });

  useEffect(async () => {
    await getSpecificDetailsApi();
  }, []);

  const getSpecificDetailsApi = async () => {
    try {
      setLoader(true);

      const getResp = await ApiClient.get(
        `${UrlHelpers.getSpecificDetails}?applicationId=${createAppApplicationId}&eventName=PreApplicantInfoRequest&isLockRequired=true`,
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );

      const { PreApplicantInfoRequest } = getResp;
      setValue('primaryAppId', PreApplicantInfoRequest?.applicantId || uuidv4());
      setValue(
        'primaryAppFirstName',
        PreApplicantInfoRequest?.informationRequest?.participantInfo?.customerName?.firstName
      );
      setValue(
        'primaryAppLastName',
        PreApplicantInfoRequest?.informationRequest?.participantInfo?.customerName?.lastName
      );
      let couserStatus = [];
      if (PreApplicantInfoRequest?.addCoSignerCheckboxSelected) {
        couserStatus.push('coSigner');
      }
      if (PreApplicantInfoRequest?.addCoApplicantCheckboxSelected) {
        couserStatus.push('coApplicant');
      }
      setValue1('applicantOrSigner', couserStatus);
    } catch (e) {
      console.log('error', e);
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later.'
      );
    } finally {
      setLoader(false);
    }
  };

  const toggleChange = (e, value) => {
    const values = getValues('applicantOrSigner');

    if (!values.includes(value)) {
      values.push(value);
    }
    setValue1('applicantOrSigner', value);
  };

  useEffect(() => {
    if (watchApplicantOrSigner.includes('coSigner')) {
      setCosignerEnabled(true);
    } else if (!watchApplicantOrSigner.includes('coSigner')) {
      setCosignerEnabled(false);
      setCosignerContainer(false);
    }
  }, [watchApplicantOrSigner]);
  const handleClose = () => {
    setShowCoApp(false);
    setOpenPopUp(false);
    reset();
    setCreateAppLookup('');
    setCreateAppFlag(false);
    setDealer('');
    setCreateAppLaunchType('');
    LockReleaseApi({ appid: createAppApplicationId, fullName, userID });
  };

  const handleContinue = async (data) => {
    try {
      setLoader(true);
      await ApiClient.post(
        UrlHelpers?.informationReqSave,

        {
          event: 'PreApplicantInfoRequest',
          application: {
            applicationId: createAppApplicationId,
            products: {
              productType: 'Indirect Loan Account',
              productName: 'AutoLoan',
            },
            userId: userID,
            applicants: {
              applicantId: data?.primaryAppId,
              informationRequest: {
                participantInfo: {
                  customerName: {
                    firstName: data?.primaryAppFirstName,
                    lastName: data?.primaryAppLastName,
                  },
                },
              },
              addCoSignerCheckboxSelected: watchApplicantOrSigner?.includes('coSigner'),
              addCoApplicantCheckboxSelected: watchApplicantOrSigner?.includes('coApplicant'),
            },
            source: {
              language: 'EN',
              location: 'PR',
            },
          },
        },
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );
      decideNext(data);
    } catch (e) {
      console.log('e', e);
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later.'
      );
    } finally {
      setLoader(false);
    }
  };

  const decideNext = (data) => {
    setPrimaryApplicantData(data);
    if (
      !watchApplicantOrSigner.includes('coSigner') &&
      !watchApplicantOrSigner.includes('coApplicant')
    ) {
      setIsSkipped(true);
      setShowInviManual(true);
      setValue1('CoAppFirstName', '');
      setValue1('CoAppLastName', '');
      setValue1('CoSigner', [{ CoSignerName: { CoSignerFirstName: '', CoSignerLastName: '' } }]);
    } else if (
      !watchApplicantOrSigner.includes('coSigner') &&
      watchApplicantOrSigner.includes('coApplicant')
    ) {
      setIsSkipped(false);
      setShowCoAppDet(true);
      setValue1('CoSigner', [{ CoSignerName: { CoSignerFirstName: '', CoSignerLastName: '' } }]);
    } else if (
      watchApplicantOrSigner.includes('coSigner') &&
      !watchApplicantOrSigner.includes('coApplicant')
    ) {
      setIsSkipped(false);
      setShowCoAppDet(true);
      setValue1('CoAppFirstName', '');
      setValue1('CoAppLastName', '');
    } else {
      setIsSkipped(false);
      setShowCoAppDet(true);
    }
    setShowCoApp(false);
  };
  const handleBack = () => {
    setShowCoApp(false);
    setShowAppType(true);
  };
  const applicantOrSignerList = [
    { valuee: 'coApplicant', displayValue: 'Co-applicant', logo: CoApplicant },
    { valuee: 'coSigner', displayValue: 'Co-signer', logo: CoSigner },
  ];
  return (
    <>
      <Dialog
        open={showCoApp}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              minWidth: 'clamp(400px, 50vw, 800px) !important',
              minHeight: 'clamp(450px,60vh,100%) !important',
            },
          },
        }}
      >
        <Loader open={loader} />
        <ApplicationDialogHeading handleBack={handleBack} handleClose={handleClose} />
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
        >
          <Box>
            <Grid container sx={{ justifyContent: 'center' }}>
              <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
            </Grid>
            <Typography
              className={globalClasses.heading}
              sx={{ fontSize: '16px !important', fontWeight: '700' }}
            >
              Applicant
            </Typography>
            <Grid container gap={1} justifyContent="space-between">
              <Grid item lg={5.5} md={5.5} sm={11}>
                {' '}
                <CustomInputBox
                  control={control}
                  errors={!!errors?.primaryAppFirstName}
                  errorText={errors?.primaryAppFirstName?.message}
                  placeholder="Enter first name"
                  label="First name"
                  name="primaryAppFirstName"
                  maxLength="15"
                  regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡\s]$/}
                  flexDirection="column"
                  required
                />
              </Grid>
              <Grid item lg={5.5} md={5.5} sm={11}>
                {' '}
                <CustomInputBox
                  control={control}
                  errors={!!errors?.primaryAppLastName}
                  errorText={errors?.primaryAppLastName?.message}
                  placeholder="Enter last name"
                  label="Last name"
                  name="primaryAppLastName"
                  maxLength="25"
                  regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡\s]$/}
                  flexDirection="column"
                  required
                />
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" flexDirection="column" gap={2.5} px={5} mb={2.5}>
            <Typography variant="h4" textAlign="center" my={3}>
              Do you want to add a co-applicant / co-signer?
            </Typography>

            <CustomToggleButtonGroup
              name="applicantOrSigner"
              control={control1}
              toggleChange={toggleChange}
              toggleList={applicantOrSignerList}
              exclusive={false}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
            id="cancel-btn"
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="secondary"
            autoFocus
            sx={{ maxHeight: '40px' }}
            onClick={handleSubmit(handleContinue)}
            id="continue-btn"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CoAppDialog;
