import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';
import Loader from 'components/Loader/Loader';

const DiscardApplicationDialog = () => {
  const {
    openDiscardModal,
    setOpenDiscardModal,
    setCreateAppFlag,
    createAppApplicationId,
    setCreateAppApplicationId,
    userID,
    setCreateAppLaunchType,
    createAppFlag,
    draftFlag,
    setDraftFlag,
    setDealer,
    setPopupStep,
    fullName,
  } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const ApiClient = new ApiClass();
  const navigate = useNavigate();
  const location = useLocation();
  const handleClose = () => {
    setOpenDiscardModal(false);
  };
  let createApplicationFlag = createAppFlag;

  const handleDiscard = async () => {
    if (location.pathname === '/register-user') {
      navigate('/manage-dealers', { state: { tabValue: 1 } });
    } else if (location.pathname === '/new-user-request') {
      navigate('/home');
    } else {
      try {
        setLoader(true);
        await ApiClient.put(
          UrlHelpers.discardApplication,

          {
            applicationId: createAppApplicationId,
            updatedBy: userID,
          },
          {
            headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
          }
        );
        setCreateAppFlag(false);
        setCreateAppApplicationId('');
        setCreateAppLaunchType('');

        setDraftFlag(false);
        setDealer('');
        if (createApplicationFlag) {
          navigate('/home');
        }
      } catch (e) {
        console.log('e', e);
      } finally {
        setPopupStep(0);
        setLoader(false);
      }
    }
    setOpenDiscardModal(false);
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openDiscardModal}
      PaperProps={{ sx: { padding: '20px', gap: '20px' } }}
    >
      <BootstrapDialogTitle onClose={handleClose} data-testid="discardClose" sx={{ padding: '0' }}>
        <Box display="flex" gap={1} pb={2} borderBottom="1px solid #E37617">
          <Typography variant="h3">
            {draftFlag ? `Delete Application` : `Discard application`}
          </Typography>
        </Box>
      </BootstrapDialogTitle>
      <DialogContent sx={{ padding: '0' }}>
        {loader && <Loader open={loader} />}
        <Typography variant="subtitle2" fontWeight={700} sx={{ padding: '4px 0' }}>
          Are you sure you want to {draftFlag ? `delete` : `discard`} this application?
        </Typography>
        <Typography variant="body1" color="#909090">
          The application will be totally removed if you choose to accept the deletion of the
          entered data
        </Typography>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDiscard}
          sx={{ minWidth: '200px' }}
          id="delete-btn"
        >
          {draftFlag ? `Yes, Delete` : `Yes, Discard application`}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
          sx={{ minWidth: '200px' }}
          id="cancel-delete-btn"
        >
          {draftFlag ? `No` : `No, continue application`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscardApplicationDialog;
