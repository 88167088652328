import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { defaultFn } from 'utils/utility';

import StyledMenu from './StyledMenu';

function CustomizedHomeMenu() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  let {
    setOpenPopUp,
    setCreateAppFlag,
    setLookupSaved,
    setCreateAppApplicationId,
    setFinanceInfo,
    setCreateAppLaunchType,
    setPopupStep,
    setDealer,
  } = useContext(AuthContext);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPopupStep(0);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const newApplication = () => {
    navigate('/new-user-request');
    handleClose();
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        color="secondary"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        data-testid="manage-btn"
      >
        Create
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          sx={{ height: '40px' }}
          onClick={() => {
            setOpenPopUp(true);
            setCreateAppFlag(true);
            setLookupSaved(false);
            setCreateAppApplicationId('');
            setFinanceInfo({});
            setCreateAppLaunchType('dashboard');
            setDealer('');
          }}
          data-testid="new-user-request"
          disableRipple
        >
          New application
        </MenuItem>
        <MenuItem
          onClick={newApplication}
          sx={{ height: '40px' }}
          data-testid="new-application"
          disableRipple
        >
          New user request
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

export default CustomizedHomeMenu;

CustomizedHomeMenu.propTypes = {
  transition: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  snackBarOpen: PropTypes.bool,
  setSnackBarOpen: PropTypes.func,
};

CustomizedHomeMenu.defaultProps = {
  transition: undefined,
  snackBarOpen: false,
  setSnackBarOpen: defaultFn,
};
