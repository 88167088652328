import { Box, Paper, Typography } from '@mui/material';
import TablePagination from 'pages/RegisterUser/TablePagination';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';

import { OfferCard } from './OfferCard';
import { OffersFilters } from './OfferFilters';
import { OffersListView } from './OffersListView';
import SingleOfferModal from './SingleOfferModal';

const ViewOffers = () => {
  const location = useLocation();

  const [allOffers, setAllOffers] = useState(location?.state?.offerList || []);
  const [page, setPage] = useState(1);
  const [view, setView] = useState('List');
  const [showPromotion, setShowPromotion] = useState(null);
  const rowsPerPage = 12;
  const paginate = (_, pageNumber) => {
    setPage(pageNumber);
  };
  const indexOfLastPost = page * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const calcOfferList = (offerList) => {
    return offerList?.slice(indexOfFirstPost, indexOfLastPost);
  };

  return (
    <>
      <Box display="flex" justifyContent="center">
        {showPromotion !== null && (
          <SingleOfferModal showPromotion={showPromotion} setShowPromotion={setShowPromotion} />
        )}
        <Box sx={{ width: '98%' }}>
          <Typography variant="h3">Offers</Typography>
          <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
            <OffersFilters
              offers={allOffers}
              setOffers={setAllOffers}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              view={view}
              setView={setView}
            />
            {view === 'Grid' ? (
              <Box display="flex" gap={2} flexWrap="wrap" mt={2.5}>
                {calcOfferList(allOffers)?.map((x) => (
                  <OfferCard offer={x} key={x.promotionId} setShowPromotion={setShowPromotion} />
                ))}
              </Box>
            ) : (
              <OffersListView
                offersList={calcOfferList(allOffers)}
                setShowPromotion={setShowPromotion}
              />
            )}

            <Box sx={{ margin: '15px' }}>
              <TablePagination
                postsPerPage={12}
                totalPosts={allOffers?.length}
                paginate={paginate}
                page={page}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default ViewOffers;
