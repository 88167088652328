import { Box, Grid, Link, Paper, TableHead, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ApiClass from 'Api/ApiClient';
import ApprovedSubIcon from 'assets/svg/ApprovedSubIcon.svg';
import DeclinedSubIcon from 'assets/svg/DeclinedSubIcon.svg';
import TimerSubIcon from 'assets/svg/TimerSubIcon.svg';
import AuthContext from 'context/AuthContext';
import {
  TableCellTypography,
  TableHeaderTypography,
} from 'pages/ApplicationDashboard/ApplicationsTable';
import { SearchFilters } from 'pages/ApplicationDashboard/SearchFilters';
import { applicationFiltersApis } from 'pages/CreateApplication/InformationRequest/moduleUtilities/dropdownApis';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn } from 'utils/utility';

import Loader from 'components/Loader/Loader';

import TablePagination from '../RegisterUser/TablePagination';

const UserRequestDashboard = ({ viewAll, route }) => {
  const {
    rolebasedDealers,
    setRoleBasedDealers,
    userID,
    accessToken,
    role,
    frequencyDetailList,
    viewApprovalQueue,
  } = useContext(AuthContext);
  const [userRequestsData, setUserRequestsData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [approvalStatusList, setApprovalStatusList] = useState([]);
  const [emptyDataMessage, setEmptyDataMessage] = useState('No application/s available');
  const [page, setPage] = useState(1);
  const [searchOptions, setSearchOptions] = useState({
    frequency: '7_DAY',
    dealerId: viewApprovalQueue ? '' : rolebasedDealers?.[0]?.dealerId,
    approvalStatus: 'PENDING',
    firstName: '',
    lastName: '',
    requestorId: '',
  });

  const navigate = useNavigate();
  const ApiClient = new ApiClass();
  const rowsPerPage = 10;
  const indexOfLastPost = page * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const calcUserList = (userArr) => {
    return userArr?.slice(indexOfFirstPost, indexOfLastPost);
  };

  const reqStatus = (val) => {
    switch (val) {
      case 'PENDING':
        return (
          <Typography variant="subtitle2" component="p" display="flex" alignItems="center">
            <img src={TimerSubIcon} />
            &nbsp; &nbsp; <span>{val}</span>
          </Typography>
        );
      case 'APPROVED':
        return (
          <Typography variant="subtitle2" component="p" display="flex" alignItems="center">
            <img src={ApprovedSubIcon} /> &nbsp; &nbsp;
            <span>{val}</span>
          </Typography>
        );
      case 'REJECTED':
        return (
          <Typography variant="subtitle2" component="p" display="flex" alignItems="center">
            <img src={DeclinedSubIcon} /> &nbsp; &nbsp; <span>{val}</span>
          </Typography>
        );
      default:
        return val;
    }
  };

  const paginate = (_, pageNumber) => {
    setPage(pageNumber);
  };

  const fetchUserRequestData = async () => {
    if (viewApprovalQueue && !viewAll) {
      try {
        setLoader(true);
        const resp = await ApiClient.get(
          `${UrlHelpers.dealerUserRequest}?userType=${
            viewApprovalQueue ? 'banker' : 'dealer'
          }&approvalStatus=PENDING&limit=10`
        );
        setUserRequestsData(resp?.userRequestList);
        return resp;
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoader(false);
      }
    } else if (viewAll) {
      const CommaSeperatedDealerListt = rolebasedDealers?.map((x) => x.dealerId)?.join(',') || '';
      try {
        setLoader(true);
        const resp = await ApiClient.get(
          `${UrlHelpers.dealerUserRequest}?userType=${
            viewApprovalQueue ? 'banker' : 'dealer'
          }&frequency=7_DAY&dealerNumber=${CommaSeperatedDealerListt}&approvalStatus=PENDING`
        );
        console.log(resp);
        setUserRequestsData(resp?.userRequestList);
        return resp;
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoader(false);
      }
    } else {
      try {
        setLoader(true);
        const resp = await ApiClient.get(
          `${UrlHelpers.dealerUserRequest}?userType=all&requesterId=${userID}&approvalStatus=PENDING&limit=10`
        );
        console.log(resp);
        setUserRequestsData(resp.userRequestList);
        return resp;
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoader(false);
      }
    }
  };
  useEffect(async () => {
    if (userID && viewAll) {
      await applicationFiltersApis({
        rolebasedDealers,
        setRoleBasedDealers,
        frequencyDetailList,
        setLoader,
        flow: 'UserRequest',
        userID,
        role,
        approvalStatusList,
        setApprovalStatusList,
      });
    }

    await fetchUserRequestData();
  }, [accessToken, role]);

  const handleLinkClick = (requestId) => {
    navigate('/user-request', { state: { reqId: requestId, route: route } });
  };
  const tableHeading = [
    'Dealer code',
    'Dealer name',
    'Requestor ID',
    'First name',
    'Last name',
    'Access type',
    'Status',
    'Comment',
  ];
  return (
    <>
      {loader && <Loader open={loader} />}
      {viewAll && (
        <SearchFilters
          searchOptions={searchOptions}
          setSearchOptions={setSearchOptions}
          frequencyDetailList={frequencyDetailList}
          applicationData={userRequestsData}
          setApplicationData={setUserRequestsData}
          setLoader={setLoader}
          userID={userID}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          dealerList={rolebasedDealers}
          flow="UserRequest"
          approvalStatusList={approvalStatusList}
          viewApprovalQueue={viewApprovalQueue}
          setEmptyDataMessage={setEmptyDataMessage}
          fetchUserRequestData={fetchUserRequestData}
        />
      )}
      <Grid
        container
        sx={{
          overflowX: 'auto',
          width: '100%',
        }}
      >
        <Paper sx={{ width: '100%', boxShadow: 'none' }}>
          <Table aria-label="applications-table">
            <TableHead>
              <TableRow
                sx={{ backgroundColor: '#F2F2F2' }}
                className={classes.tableHeadingApplications}
              >
                {tableHeading?.map((x) => {
                  return (
                    <TableCell key={x}>
                      <TableHeaderTypography>{x}</TableHeaderTypography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {calcUserList(userRequestsData)?.map((row) => (
                <TableRow
                  key={`${row.requestId} ${row.dealerNumber}`}
                  className={classes.tableHeadingApplications}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <TableCellTypography>
                      <Link
                        component="button"
                        variant="subtitle2"
                        sx={{ color: '#0065CC', textDecoration: 'initial', textAlign: 'left' }}
                        onClick={() => handleLinkClick(row?.requestId)}
                      >
                        {row?.dealerNumber}
                      </Link>
                    </TableCellTypography>
                  </TableCell>
                  <TableCell>
                    <TableCellTypography>{row?.dealer}</TableCellTypography>
                  </TableCell>
                  <TableCell>
                    <TableCellTypography>{row?.requesterId}</TableCellTypography>
                  </TableCell>
                  <TableCell>
                    <TableCellTypography>{row?.firstName}</TableCellTypography>
                  </TableCell>
                  <TableCell>
                    <TableCellTypography>{row?.lastName}</TableCellTypography>
                  </TableCell>
                  <TableCell>
                    <TableCellTypography>{row?.accessTypeDealer}</TableCellTypography>
                  </TableCell>
                  <TableCell width="200">
                    <TableCellTypography display="flex" alignItems="center">
                      {reqStatus(row?.approvalStatus)}
                    </TableCellTypography>
                  </TableCell>
                  <TableCell>
                    <TableCellTypography>
                      {row?.approvalStatus === 'REJECTED'
                        ? row?.commentsBanker
                        : row?.commentsDealer}
                    </TableCellTypography>
                  </TableCell>
                </TableRow>
              ))}
              {!userRequestsData?.length && (
                <TableRow>
                  <TableCell colSpan={12} sx={{ fontSize: '14px !important', textAlign: 'center' }}>
                    {emptyDataMessage}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Box sx={{ margin: '15px' }}>
        {viewAll && (
          <TablePagination
            postsPerPage={10}
            totalPosts={userRequestsData?.length}
            paginate={paginate}
            page={page}
          />
        )}
      </Box>
    </>
  );
};

UserRequestDashboard.propTypes = {
  viewAll: PropTypes.bool,
  viewApprovalQueue: PropTypes.bool,
  emptyDataMessage: PropTypes.string,
  setEmptyDataMessage: PropTypes.func,
  route: PropTypes.string,
};

UserRequestDashboard.defaultProps = {
  viewAll: false,
  viewApprovalQueue: false,
  emptyDataMessage: '',
  setEmptyDataMessage: defaultFn,
  route: '',
};

export default UserRequestDashboard;
