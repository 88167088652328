import { Box, Grid, InputLabel, Typography } from '@mui/material';
import CancelIcon from 'assets/svg/IconCancelCircle.svg';
import CheckIcon from 'assets/svg/IconCheckCircle.svg';
import RegisterUserContext from 'context/RegisterUserContext';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useWatch } from 'react-hook-form';
import { defaultFn } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomPhoneNumber from 'components/CustomPhoneNumber/CustomPhoneNumber';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

const BasicInformation = ({
  control,
  errors,
  userIdCheck,
  isUserIdValid,
  setIsUserIdValid,
  isViewProfile,
}) => {
  const { formDetails } = useContext(RegisterUserContext);
  const [watchuserId] = useWatch({
    control,
    name: ['userId'],
  });
  return (
    <div>
      <CustomCollapse heading="Basic information" open={true} errors={errors}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {isViewProfile ? (
              <Box gap="5px" display="flex" flexDirection="column">
                <Typography variant="subtitle2" fontWeight={700}>
                  User ID
                </Typography>
                <Typography variant="subtitle2">{formDetails?.userId}</Typography>
              </Box>
            ) : (
              <CustomInputBox
                control={control}
                errors={!!errors?.userId}
                errorText={errors?.userId?.message}
                placeholder="Enter user id"
                label="User ID"
                name="userId"
                flexDirection="column"
                required
                regex={/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\d]*$/}
                maxLength={15}
                onFocus={() => setIsUserIdValid(null)}
                onBlur={userIdCheck}
              />
            )}
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            style={{
              alignSelf: 'end',
            }}
          >
            {(!isViewProfile || formDetails?.userId !== watchuserId) && isUserIdValid !== null && (
              <Box
                sx={{
                  padding: '12px',
                  border: `1px solid ${isUserIdValid ? '#128A08' : '#D71721'}`,
                  borderLeft: `5px solid ${isUserIdValid ? '#128A08' : '#D71721'}`,
                  color: `${isUserIdValid ? '#128A08' : '#D71721'}`,
                }}
              >
                <Typography variant="subtitle2" component="p" display="flex" alignItems="center">
                  <img src={isUserIdValid ? CheckIcon : CancelIcon} /> &nbsp; &nbsp;
                  <span>{isUserIdValid ? 'User ID available' : 'User ID not available'}</span>
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.firstName}
              errorText={errors?.firstName?.message}
              placeholder="Enter first name"
              label="First name"
              name="firstName"
              flexDirection="column"
              required={!isViewProfile}
              regex={/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\s]*$/g}
              maxLength={25}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.lastName}
              errorText={errors?.lastName?.message}
              placeholder="Enter last name"
              label="Last name"
              name="lastName"
              flexDirection="column"
              required={!isViewProfile}
              regex={/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\s]*$/g}
              maxLength={25}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.secondLastName}
              errorText={errors?.secondLastName?.message}
              placeholder="Enter second last name"
              label="Second last name"
              name="secondLastName"
              flexDirection="column"
              regex={/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡.'\s]*$/g}
              maxLength={25}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <></>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.email}
              errorText={errors?.email?.message}
              placeholder="Enter email"
              label="Email"
              name="email"
              flexDirection="column"
              maxLength={50}
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomPhoneNumber
              name="mobile"
              placeholder="Enter phone"
              label="Phone"
              flexDirection="column"
              control={control}
              error={!!errors?.mobile}
              errorText={errors?.mobile?.message}
              required={!isViewProfile}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
              <InputLabel>User setup date</InputLabel>
              <Typography sx={{ width: '100%' }}>
                {isViewProfile ? moment(formDetails.userSetupDate).format('MM/DD/YYYY') : ''}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
              <InputLabel>Time zone</InputLabel>
              <Typography sx={{ width: '100%' }}>Puerto Rico</Typography>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Include in sales manager?"
              control={control}
              name="includeSalesManager"
              errors={!!errors?.includeSalesManager}
              errorText={errors?.includeSalesManager?.message}
              options={[
                {
                  name: 'Yes',
                  value: 'Yes',
                },
                {
                  name: 'No',
                  value: 'No',
                },
              ]}
              defaultValue=""
              required={!isViewProfile}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Include in reference?"
              control={control}
              name="includeReference"
              errors={!!errors?.includeReference}
              errorText={errors?.includeReference?.message}
              options={[
                {
                  name: 'Yes',
                  value: 'Yes',
                },
                {
                  name: 'No',
                  value: 'No',
                },
              ]}
              defaultValue=""
              required={!isViewProfile}
            />
          </Grid>
        </Grid>
      </CustomCollapse>
    </div>
  );
};

BasicInformation.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  setIsUserIdValid: PropTypes.func,
  userIdCheck: PropTypes.func,
  isUserIdValid: PropTypes.bool,
  isViewProfile: PropTypes.bool,
};
BasicInformation.defaultProps = {
  control: {},
  errors: {},
  userIdCheck: defaultFn,
  isUserIdValid: false,
  setIsUserIdValid: defaultFn,
  isViewProfile: false,
};
export default BasicInformation;
