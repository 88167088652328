import { Box, FormHelperText, InputLabel, OutlinedInput, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import globalClasses from 'styles/globalStyle.module.scss';

const CustomPhoneNumber = (props) => {
  const {
    control,
    label,
    name,
    error,
    errorText,
    isDisabled,
    required,
    classes,
    flexDirection,
    placeholder,
    ...rest
  } = props;

  const handleInput = (event) => {
    let charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={0.625}
      my={0.5}
      flexDirection={flexDirection}
      {...rest}
    >
      <InputLabel htmlFor={name}>
        {required && <span style={{ color: 'red' }}>*&nbsp;</span>} {label}
      </InputLabel>
      <Box display="flex" sx={{ borderRadius: '2px', width: '99%' }} alignSelf="flex-start">
        <Controller
          id={name}
          name={name}
          control={control}
          render={({ field: { ref, ...field } }) => {
            return (
              <TextField
                variant="outlined"
                type="tel"
                disabled={isDisabled ? true : false}
                {...field}
                classes={{ root: classes }}
                inputProps={{
                  id: name,
                  'data-testid': name,
                  maxLength: '10',
                  placeholder: placeholder,
                }}
                onKeyPress={handleInput}
                InputProps={{
                  component: OutlinedInput,
                  classes: { notchedOutline: globalClasses.removeBorder },
                }}
                placeholder={placeholder}
                inputRef={ref}
              />
            );
          }}
        />
      </Box>
      <Box display="flex" gap={2} sx={{ alignSelf: 'flex-start' }}>
        {error && (
          <FormHelperText gap={2} className={globalClasses.customSelectError}>
            {' '}
            {errorText}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export default CustomPhoneNumber;

CustomPhoneNumber.propTypes = {
  control: PropTypes.instanceOf(Object),
  classes: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  isDisabled: PropTypes.bool,
  required: PropTypes.bool,
  errorText: PropTypes.string,
  label: PropTypes.string,
  flexDirection: PropTypes.string,
  placeholder: PropTypes.string,
  rest: PropTypes.instanceOf(Object),
};
CustomPhoneNumber.defaultProps = {
  control: {},
  classes: '',
  name: '',
  label: '',
  error: false,
  isDisabled: false,
  required: false,
  errorText: '',
  placeholder: '',
  flexDirection: 'row',
  rest: {},
};
