import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import { Requirements } from 'pages/RegisterUser/ResetPassword/Requirements';
import { enrollTooltipStyles } from 'pages/RegisterUser/ResetPassword/Tooltipstyles';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn, errorHandlingMessage, reqArray } from 'utils/utility';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';
import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

const ChangePasswordModal = ({ showChangePasswordModal, setShowChangePasswordModal }) => {
  const useStyles = enrollTooltipStyles();
  const ApiClient = new ApiClass();
  const classes = useStyles();
  const [passwordValid, setPasswordValid] = useState(false);
  const [apiErr, setApiErr] = useState('');
  const [isApiSuccess, setIsApiSuccess] = useState(false);
  const [reenterPasswordValid, setReenterPasswordValid] = useState(false);
  const [loader, setLoader] = useState(false);
  const { role, accessTokenFromIdToken } = useContext(AuthContext);

  const [passwordToggle, setPasswordToggle] = useState({
    currentPassword: true,
    newPassword: true,
    reenterPassword: true,
  });
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    reenterPassword: '',
  });
  const [formErrors, setFormErrors] = useState({
    currentPassword: '',
    newPassword: '',
    reenterPassword: '',
  });
  const [submitDisabled, setSubmitDisabled] = useState(false);
  useEffect(() => {
    setSubmitDisabled(
      !formData?.newPassword ||
        !formData?.reenterPassword ||
        !reenterPasswordValid ||
        !passwordValid
    );
  }, [formData, passwordValid, reenterPasswordValid]);
  const reenterArray = [
    {
      validator: (val) => val === formData.newPassword,
      text: 'Passwords must be the same',
      reqSatisfied: false,
    },
  ];
  console.log({ formData, formErrors });
  const passwordIconHandler = (passname, e) => {
    e.preventDefault();
    setPasswordToggle({
      ...passwordToggle,
      [passname]: !passwordToggle[passname],
    });
  };
  useEffect(() => {
    if (
      formData?.newPassword !== formData?.reenterPassword &&
      formData?.newPassword?.length &&
      formData?.reenterPassword?.length
    ) {
      setFormErrors({
        ...formErrors,
        reenterPassword: 'Passwords must be the same',
      });
    } else if (formData?.newPassword?.length === formData?.reenterPassword?.length) {
      setFormErrors({ ...formErrors, reenterPassword: '' });
    }
  }, [formData.newPassword, formData.reenterPassword]);

  const onFormData = (e) => {
    if (e.target.name === 'newPassword') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
      setPasswordValid(
        !reqArray.map((req) => req.validator(e.target.value)).some((val) => val === false)
      );
      setReenterPasswordValid(e.target.value === formData.reenterPassword);
    } else if (e.target.name === 'reenterPassword') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
      setReenterPasswordValid(e.target.value === formData.newPassword);
    } else {
      reenterArray[0].validator();
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleClose = () => {
    setShowChangePasswordModal(false);
    setApiErr('');
    setFormData({
      currentPassword: '',
      newPassword: '',
      reenterPassword: '',
    });
  };
  const handleChangePassword = async () => {
    setPasswordToggle({
      currentPassword: true,
      newPassword: true,
      reenterPassword: true,
    });
    try {
      setLoader(true);
      let passwordChange = await ApiClient.post(
        `${UrlHelpers?.uploadPassword}`,

        {
          oldPassword: formData?.currentPassword,
          newPassword: formData?.newPassword,
        },
        {
          headers: {
            appid: 'auto',

            roleid: role,
            user: accessTokenFromIdToken,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log({ passwordChange });
      if (passwordChange?.messages[0]?.code === '200') {
        setIsApiSuccess(true);
        setApiErr('Password changed successfully');
        setFormData({
          currentPassword: '',
          newPassword: '',
          reenterPassword: '',
        });
      }
    } catch (e) {
      console.log('error fetching', e);
      setIsApiSuccess(false);
      setApiErr(errorHandlingMessage[e?.response?.data?.errorCode || 'defaultCode']?.message);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Dialog
        open={showChangePasswordModal}
        PaperProps={{
          sx: {
            minWidth: 'clamp(400px, 50vw, 800px) !important',
            minHeight: 'clamp(450px,60vh,100%) !important',
          },
        }}
      >
        <Loader open={loader} />
        <BootstrapDialogTitle
          id="customized-dialog-title"
          data-testid="close-modal"
          role="close-modal"
          onClose={handleClose}
          sx={{ paddingBottom: '0' }}
        >
          <Typography variant="h3" component="span">
            Reset password
          </Typography>
          <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
        </BootstrapDialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
        >
          <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} success={isApiSuccess} />
          <Box display="flex" flexDirection="column" px={5} my={2}>
            <Grid container justifyContent="center">
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <InputLabel
                  htmlFor="currentPassword"
                  aria-label="Current password Required"
                  sx={{ margin: '10px 0' }}
                >
                  Old password
                </InputLabel>
                <Tooltip
                  disableHoverListener
                  placement={'bottom'}
                  leaveTouchDelay={250}
                  enterTouchDelay={50}
                  leaveDelay={250}
                  TransitionComponent={Zoom}
                  arrow
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.arrow,
                  }}
                  title={
                    <>
                      <Requirements
                        value={formData.currentPassword || ''}
                        requirements={reqArray}
                        onValidChange={() => {}}
                      />
                      {
                        <>
                          <div>
                            <InfoOutlinedIcon
                              style={{
                                width: '0.7em',
                                height: '0.7em',
                                color: '#EFAB39',
                                marginRight: '5px',
                              }}
                            />
                            <span style={{ fontSize: '14px' }}>
                              Note: Password is case sensitive
                            </span>
                          </div>
                          <div>
                            <InfoOutlinedIcon
                              style={{
                                width: '0.7em',
                                height: '0.7em',
                                color: '#EFAB39',
                                marginRight: '5px',
                              }}
                            />
                            <span style={{ fontSize: '14px' }}>
                              Note: New password should not match last 10 passwords
                            </span>
                          </div>
                        </>
                      }
                    </>
                  }
                >
                  <OutlinedInput
                    id="currentPassword"
                    name="currentPassword"
                    onChange={onFormData}
                    placeholder="Enter your old password"
                    fullWidth="currentPassword"
                    data-testid="currentPassword"
                    inputProps={{ maxLength: 15 }}
                    autoComplete="off"
                    type={passwordToggle.currentPassword ? 'password' : 'text'}
                    value={formData.currentPassword}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        style={{
                          marginLeft: '0',
                          marginRight: '-7px',
                          maxWidth: '35px',
                        }}
                      >
                        <IconButton
                          aria-label="show/hide icon"
                          onClick={(e) => passwordIconHandler('currentPassword', e)}
                          tabIndex="0"
                          data-testid="hidePasswordIcon"
                        >
                          {passwordToggle.currentPassword === false ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Tooltip>
                <span
                  style={{
                    font: 'normal normal normal 14px/16px Arial',
                    letterSpacing: '0.1px',
                    color: '#DF0000',
                    opacity: 1,
                  }}
                  role="alert"
                >
                  {formErrors.currentPassword}
                </span>
                <InputLabel
                  htmlFor="newPassword"
                  aria-label="New Password Required"
                  sx={{ margin: '10px 0' }}
                >
                  New password
                </InputLabel>
                <Tooltip
                  disableHoverListener
                  placement={'bottom'}
                  leaveTouchDelay={250}
                  enterTouchDelay={50}
                  leaveDelay={250}
                  TransitionComponent={Zoom}
                  arrow
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.arrow,
                  }}
                  title={
                    <>
                      <Requirements
                        value={formData.newPassword || ''}
                        requirements={reqArray}
                        onValidChange={() => {}}
                      />
                      {
                        <>
                          <div>
                            <InfoOutlinedIcon
                              style={{
                                width: '0.7em',
                                height: '0.7em',
                                color: '#EFAB39',
                                marginRight: '5px',
                              }}
                            />
                            <span style={{ fontSize: '14px' }}>
                              Note: Password is case sensitive
                            </span>
                          </div>
                          <div>
                            <InfoOutlinedIcon
                              style={{
                                width: '0.7em',
                                height: '0.7em',
                                color: '#EFAB39',
                                marginRight: '5px',
                              }}
                            />
                            <span style={{ fontSize: '14px' }}>
                              Note: New password should not match last 10 passwords
                            </span>
                          </div>
                        </>
                      }
                    </>
                  }
                >
                  <OutlinedInput
                    id="newPassword"
                    name="newPassword"
                    onChange={onFormData}
                    placeholder="Enter your new password"
                    fullWidth="newPassword"
                    data-testid="newPassword"
                    inputProps={{ maxLength: 15 }}
                    autoComplete="off"
                    type={passwordToggle.newPassword ? 'password' : 'text'}
                    value={formData.newPassword}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        style={{
                          marginLeft: '0',
                          marginRight: '-7px',
                          maxWidth: '35px',
                        }}
                      >
                        <IconButton
                          onClick={(e) => passwordIconHandler('newPassword', e)}
                          tabIndex="0"
                          data-testid="hidePasswordIcon"
                          aria-label="show/hide icon"
                        >
                          {passwordToggle.newPassword === false ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Tooltip>
                <span
                  style={{
                    font: 'normal normal normal 14px/16px Arial',
                    letterSpacing: '0.1px',
                    color: '#DF0000',
                    opacity: 1,
                  }}
                  role="alert"
                >
                  {formErrors.newPassword}
                </span>
                <InputLabel
                  htmlFor="reenterPassword"
                  aria-label="Confirm Password Required"
                  sx={{ margin: '10px 0' }}
                >
                  Confirm password
                </InputLabel>
                <Tooltip
                  disableHoverListener
                  placement={'bottom'}
                  leaveTouchDelay={250}
                  enterTouchDelay={50}
                  leaveDelay={250}
                  TransitionComponent={Zoom}
                  arrow
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.arrow,
                  }}
                  title={
                    <>
                      <Requirements
                        value={formData.reenterPassword || ''}
                        requirements={reenterArray}
                        onValidChange={() => {}}
                      />
                      {
                        <>
                          <InfoOutlinedIcon
                            style={{
                              width: '0.7em',
                              height: '0.7em',
                              color: '#EFAB39',
                              marginRight: '5px',
                            }}
                          />
                          <span style={{ fontSize: '14px' }}>Note: Password is case sensitive</span>
                        </>
                      }
                    </>
                  }
                >
                  <OutlinedInput
                    sx={{
                      opacity: 1,
                    }}
                    id="reenterPassword"
                    name="reenterPassword"
                    onChange={onFormData}
                    placeholder={'Re-Enter your new password'}
                    fullWidth
                    data-testid="reenterPassword"
                    inputProps={{ maxLength: 15 }}
                    autoComplete="off"
                    type={passwordToggle.reenterPassword ? 'password' : 'text'}
                    value={formData.reenterPassword}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        style={{
                          marginLeft: '0',
                          marginRight: '-7px',
                          maxWidth: '35px',
                        }}
                      >
                        <IconButton
                          aria-label="show/hide icon"
                          onClick={(e) => passwordIconHandler('reenterPassword', e)}
                          tabIndex="0"
                          data-testid="reenterPasswordIcon"
                        >
                          {passwordToggle.reenterPassword === false ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Tooltip>

                <span
                  style={{
                    font: 'normal normal normal 14px/16px Arial',
                    letterSpacing: '0.1px',
                    color: '#DF0000',
                    opacity: 1,
                  }}
                  role="alert"
                >
                  {formErrors.reenterPassword}
                </span>
                <Box my={2}>
                  <Grid container spacing={1}>
                    <Grid item lg={6} md={6} sm={6}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleClose}
                        sx={{ maxHeight: '40px', width: '100%' }}
                        id="cancel-btn"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6}>
                      <Button
                        variant="contained"
                        color="secondary"
                        autoFocus
                        sx={{ maxHeight: '40px', width: '100%' }}
                        onClick={handleChangePassword}
                        data-testid="continue-btn"
                        disabled={submitDisabled}
                      >
                        Change password
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Typography variant="subtitle2" component="p" textAlign="center" padding="0 20px">
                    We promise to keep your personal information private and secure. To learn more,
                    please see our <Link color="secondary"> privacy policy.</Link>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChangePasswordModal;

ChangePasswordModal.propTypes = {
  showChangePasswordModal: PropTypes.func,
  setShowChangePasswordModal: PropTypes.func,
};
ChangePasswordModal.defaultProps = {
  showChangePasswordModal: defaultFn,
  setShowChangePasswordModal: defaultFn,
};
