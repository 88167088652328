import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import TriangleIcon from 'assets/svg/Icon-triangle.svg';
import PropTypes from 'prop-types';
import { defaultFn } from 'utils/utility';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';

const IdVerificationPopup = ({
  open,
  setOpen,
  handleEdit,
  setIdVerifiedAlertMessage,
  idVerifiedAlertMessage,
}) => {
  const handleClose = () => {
    setOpen(true);
    setIdVerifiedAlertMessage(
      idVerifiedAlertMessage?.includes('utility')
        ? 'Utility bill upload should be completed to proceed'
        : idVerifiedAlertMessage
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{ sx: { padding: '20px', gap: '20px' } }}
    >
      <BootstrapDialogTitle onClose={handleClose} data-testid="discardClose" sx={{ padding: '0' }}>
        <Box display="flex" gap={1} pb={2} borderBottom="1px solid #E37617">
          <Typography variant="h3">Alert message</Typography>
        </Box>
      </BootstrapDialogTitle>
      <DialogContent sx={{ padding: '0' }}>
        <Box display="flex" gap={2.5}>
          <img src={TriangleIcon} width="48px" height="48px" />
          <Typography variant="subtitle1" color="#000000" fontWeight="700">
            {idVerifiedAlertMessage}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClose}
          sx={{ minWidth: '200px' }}
          id="delete-btn"
          data-testid="closeBtn"
        >
          No, cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleEdit}
          sx={{ minWidth: '200px' }}
          id="cancel-delete-btn"
        >
          Ok, go ahead
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IdVerificationPopup;

IdVerificationPopup.propTypes = {
  handleEdit: PropTypes.func,
  setAlert: PropTypes.func,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  setIdVerifiedAlertMessage: PropTypes.func,
  idVerifiedAlertMessage: PropTypes.string,
};
IdVerificationPopup.defaultProps = {
  handleEdit: defaultFn,
  setAlert: defaultFn,
  setOpen: defaultFn,
  open: false,
  setIdVerifiedAlertMessage: defaultFn,
  idVerifiedAlertMessage: '',
};
