/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import { handleTokenExpiry, phoneValidation } from 'Services/commonComponentsApi';
import AddIcon from 'assets/svg/AddIcon.svg';
import InvitationFlowImage from 'assets/svg/InvitationFlowImage.svg';
import { fetchAddressLookup } from 'commonComponents/commonComponents';
import AuthContext from 'context/AuthContext';
import InvitationFlowContext from 'context/InvitationFlowContext';
import debounceLodash from 'lodash.debounce';
import { step2DropDownApis } from 'pages/CreateApplication/InformationRequest/moduleUtilities/dropdownApis';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormatValidationSchema } from 'schemaValidations/EmploymentInformationSchema';
import { InviEmploymentInfoSchema } from 'schemaValidations/InvitationFlowSchema';
import classes from 'styles/InviInformationRequest.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { currencyToNumber } from 'utils';
import { handleFocusError, removeNullValues } from 'utils/utility';

import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

import {
  InvitationExpiryCheck,
  frameGetDetails,
  handleValidationResponse,
} from '../InviCommonUtilities';
import { inviFlowPayloads } from '../InviFlowPayloads';
import InviAdditionalIncome from './InviAdditionalIncome/InviAdditionalIncome';
import InviEmpInfoForm from './InviEmpInfoForm/InviEmpInfoForm';

const InviEmploymentInfo = () => {
  const [addressLookupData, setAddressLookupData] = useState([]);
  const [addressLookupDataResponse, setAddressLookupDataResponse] = useState([]);
  const [timeAtResidenceError, setTimeAtResidenceError] = useState('');
  const [loader, setLoader] = useState(false);
  const [apiErr, setApiErr] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const ApiClient = new ApiClass();
  const isMobile = useMediaQuery('(max-width:899px)');
  const {
    occupationList,
    setOccupationList,
    empStatusList,
    setEmpStatusList,
    cityList,
    setCityList,
    industryList,
    setIndustryList,
    cityZipList,
    setCityZipList,
    commonToken,
    inviApplicationNum,
    inviApplicantId,
    inviUserType,
    setCommonToken,
    accessToken,
    stateList,
    setStateList,
    // stateCityList,
    setstateCityList,
  } = useContext(AuthContext);
  const {
    setOnBackClick,

    setActiveStep,
    setCompletedSteps,
    formFields,
    empInfoformFields,
    setEmpInfoFormFields,
    setGetDetailsData,
    setIsInviExpired,
    setInvitationExpiredHeading,
    setInvitationExpiredContent,
    isGetDetailsDone,
    getDetailsData,
    setIsGetDetailsDone,
  } = useContext(InvitationFlowContext);
  const {
    control,
    watch,
    setValue,
    clearErrors,
    handleSubmit,
    setError,
    setFocus,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: 'all',
    defaultValues: {
      empStatus: '',
      empName: '',
      empAddressLine1: '',
      empAddressLine2: '',
      empCountry: 'USA',
      empState: '',
      empCity: '',
      empZipcode: '',
      jobTitle: '',
      occupation: '',
      industry: '',
      empYears: '',
      empMonths: '',
      monthlyIncome: '',
      mobile: '',

      showAddInfo: false,

      addEmpName: '',
      addJobTitle: '',
      addOccupation: '',
      mobile2: '',
      addMonthlyIncome: '',
    },
    resolver: yupResolver(InviEmploymentInfoSchema),
  });
  const watchAll = watch();
  const [
    watchmobile1,
    watchmobile2,
    watchEmpAddressLine1,
    watchEmpAddressLine2,
    watchEmpCity,
    watchEmpState,
    watchEmpZipCode,
    watchEmpYears,
    watchEmpMonths,
    watchShowAddInfo,
  ] = watch([
    'mobile',
    'mobile2',
    'empAddressLine1',
    'empAddressLine2',
    'empCity',
    'empState',
    'empZipcode',
    'empYears',
    'empMonths',
    'showAddInfo',
  ]);
  const inputRef = useRef(null);
  useEffect(() => {
    handleFocusError({ errors, setFocus });
    console.log({ qwerty: errors });
  }, [errors]);

  useEffect(async () => {
    await step2DropDownApis({
      setstateCityList,
      setStateList,
      occupationList,
      setOccupationList,
      empStatusList,
      setEmpStatusList,
      cityList,
      industryList,
      setIndustryList,
      cityZipList,
      setCityZipList,
    });
    setIsMounted(true);
  }, []);
  const applyFormFields = async (formFieldss) => {
    Object.entries(formFieldss).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  const failurecallback = (e) => {
    handleTokenExpiry(e, setCommonToken);
  };
  const debounceFunc5 = debounceLodash((address1, country, query5, commonTokenn, addressType) => {
    fetchAddressLookup({
      address1,
      country,
      query5,
      commonTokenn,
      addressType,
      setAddressLookupData,
      setAddressLookupDataResponse,
    });
  }, 500);

  const fetchAddress5 = useCallback(debounceFunc5, []);
  useEffect(() => {
    if (dirtyFields?.empAddressLine1 && commonToken) {
      let query5 = watchEmpAddressLine1;

      if (watchEmpAddressLine2) {
        query5 += ` ${watchEmpAddressLine2} `;
      }
      if (watchEmpState) {
        query5 += ` ${watchEmpState} `;
      }
      if (watchEmpCity) {
        query5 += ` ${watchEmpCity} `;
      }
      if (watchEmpZipCode) {
        query5 += ` ${watchEmpZipCode} `;
      }
      fetchAddress5(watchEmpAddressLine1, 'USA', query5, commonToken, 'res');
    }
  }, [watchEmpAddressLine1]);

  useEffect(() => {
    if (Object.entries(formFields)?.length) {
      const dateofBirth = formFields?.dob;
      if (dateofBirth) {
        let a = new Date().getFullYear() * 12 + new Date().getMonth();
        let b = new Date(dateofBirth).getFullYear() * 12 + new Date(dateofBirth).getMonth();
        let c = a - b;
        let ageYears = c / 12;
        let ageMonth = c % 12;
        if (
          parseInt(watchEmpYears) > parseInt(ageYears) ||
          (parseInt(watchEmpYears) === parseInt(ageYears) &&
            parseInt(watchEmpMonths) > parseInt(ageMonth))
        ) {
          setTimeAtResidenceError(
            'Employment Time Period cannot be greater than the age of the applicant'
          );
        } else {
          setTimeAtResidenceError('');
        }
      }
    }
  }, [watchEmpYears, watchEmpMonths]);

  const handleAdditionalClick = () => {
    setValue('showAddInfo', true);
  };
  useEffect(() => {
    if (empInfoformFields) {
      applyFormFields(empInfoformFields);
    }
  }, []);
  const handleEmpDetailsSubmit = async (data, { draft = false }) => {
    let detailsData;
    if (!draft) {
      detailsData = await InvitationExpiryCheck({
        accessToken,
        setLoader,
        setIsInviExpired,
        setInvitationExpiredHeading,
        setInvitationExpiredContent,
        inviApplicationNum,
        inviApplicantId,
      });
    }
    setEmpInfoFormFields(watchAll);
    let isFormValid;
    if (!Object.entries(errors).length && !timeAtResidenceError && !draft) {
      isFormValid = await phoneValidation({
        mobile1: watchmobile1,
        mobile2: watchmobile2,
        commonToken: commonToken,
        setError: setError,
        setLoader: setLoader,
        failurecallback,
        clearErrors,
      });
    }
    const {
      empStatus,
      empName,
      empAddressLine1,
      empAddressLine2,
      empCountry,
      empState,
      empCity,
      empZipcode,
      showAddInfo,
      jobTitle,
      occupation,
      industry,
      empYears,
      empMonths,
      monthlyIncome,
      mobile,
      addEmpName,
      addJobTitle,
      addOccupation,
      mobile2,
      addMonthlyIncome,
    } = data;
    let applicantDetails = {
      applicantId: inviApplicantId,
      employmentInformation: {
        employmentInformationDetail: {
          employmentStatus: empStatus,
          employer: empName,
          address: {
            lineOne: empAddressLine1,
            lineTwo: empAddressLine2,
            addrCity: empCity,
            addrState: empState,
            addrCountry: 'United States',
            addrCountryCode: empCountry,
            zipCode: empZipcode,
          },
          jobTitle: jobTitle,
          occupation:
            occupationList?.length > 0 && occupationList.find((x) => x.value === occupation)?.name,
          occupationCode: occupation,
          industry: industry,
          employmentTimePeriodYear: empYears,
          employmentTimePeriodMonth: empMonths,
          workPhone: removeNullValues(mobile),
          grossMonthlyIncome: monthlyIncome ? currencyToNumber(monthlyIncome) : '',
        },
      },
    };

    if (showAddInfo) {
      applicantDetails.employmentInformation.additionalIncomeDetail = {
        addlEmployerName: addEmpName,
        addlJobTitle: addJobTitle,
        addlOccupation:
          occupationList?.length > 0 && occupationList.find((x) => x.value === addOccupation)?.name,

        addlOccupationCode: addOccupation,
        addlWorkPhone: removeNullValues(mobile2),
        addlGrossMonthlyIncome: monthlyIncome ? currencyToNumber(addMonthlyIncome) : '',
      };
    }
    const inviEmpInfoPayload = await inviFlowPayloads({
      eventName: 'EmploymentInformationInvitation',
      applicationNum: inviApplicationNum,
      applicantDetails,
      inviUserType: inviUserType,
    });
    await callEmpApi({ isFormValid, draft, inviEmpInfoPayload, detailsData });
  };
  const handleApiError = async (e, detailsData) => {
    if (e.response.status === 400) {
      await handleValidationResponse({
        resp: e?.response?.data,
        details: JSON.parse(detailsData?.applicationData || '{}'),
        setApiErr,
        inviUserType,
        inviEmpInfoPage: true,
        setError,
        user: 'inviFlow',
      });
    } else {
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later'
      );
    }
  };
  const callEmpApi = async ({ isFormValid, draft, inviEmpInfoPayload, detailsData }) => {
    if (isFormValid || draft) {
      try {
        setLoader(true);
        let dataa = await ApiClient.post(
          draft ? UrlHelpers.applicationDraft : UrlHelpers.informationReqSave,

          { ...inviEmpInfoPayload },
          {
            headers: { 'Content-Type': 'application/json', userid: 'guest', userName: 'guest' },
          }
        );
        console.log('unik-3', { dataa, draft });
        if (dataa) {
          if (draft) {
            setOnBackClick(true);
            setCompletedSteps([]);
            setActiveStep(1);
            setGetDetailsData({});
          } else {
            setOnBackClick(false);
            setCompletedSteps([1, 2]);
            setActiveStep(3);
            setGetDetailsData({});
          }
        }
      } catch (e) {
        console.log('unik-1', e);
        handleApiError(e, detailsData);
      } finally {
        setLoader(false);
      }
    }
  };
  useEffect(async () => {
    let data = '{}';
    if (!isGetDetailsDone) {
      data = await InvitationExpiryCheck({
        accessToken,
        setLoader,
        setIsInviExpired,
        setInvitationExpiredHeading,
        setInvitationExpiredContent,
        inviApplicationNum,
        inviApplicantId,
      });
    }
    console.log({ data });
    const parsedData = isGetDetailsDone ? getDetailsData?.applicationData : data?.applicationData;
    await frameGetDetails({
      getDetailsData: JSON.parse(parsedData || '{}'),
      setValue: setValue,
      inviUserType,
      inviApplicantId,
    });
  }, [accessToken]);

  const draftValidCheck = async (formValue) => {
    let isValid = false;
    try {
      isValid = FormatValidationSchema.isValidSync(formValue);
    } catch (err) {
      console.log('unik-2', err);
      return isValid;
    }
    return isValid;
  };

  const handleBackClick = async () => {
    setIsGetDetailsDone(false);

    const formValue = getValues();
    let valid = await draftValidCheck(formValue);

    if (valid) {
      await handleEmpDetailsSubmit(formValue, { draft: true });
      setActiveStep(1);
    } else {
      setApiErr(' Please enter the field completely and try saving again.');
    }
  };
  console.log({ watchShowAddInfo });
  return (
    <>
      <Loader open={loader} />
      <Grid container>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={8} md={8} sm={12} xs={12} className={classes.contentContainer}>
          <Typography variant="h3" component="h3" sx={{ padding: '30px 30px 0' }}>
            Employment information
          </Typography>
          <InviEmpInfoForm
            control={control}
            errors={errors}
            inputRef={inputRef}
            addressLookupData={addressLookupData}
            addressLookupDataResponse={addressLookupDataResponse}
            clearErrors={clearErrors}
            setError={setError}
            setValue={setValue}
            timeAtResidenceError={timeAtResidenceError}
            stateList={stateList}
            cityList={cityList}
            isMounted={isMounted}
            setCityList={setCityList}
          />
          {!watchShowAddInfo && (
            <Link
              component="button"
              variant="subtitle2"
              color="secondary"
              sx={{ padding: '0px 25px' }}
              data-testid="add-income"
              onClick={handleAdditionalClick}
            >
              <Box display="flex" alignItems="center">
                <img src={AddIcon} alt="Add Button" style={{ marginRight: '8px' }} /> Add additional
                income
              </Box>
            </Link>
          )}
          <Grid padding="0 25px 25px">
            {watchShowAddInfo && (
              <InviAdditionalIncome
                control={control}
                errors={errors}
                setValue={setValue}
                clearErrors={clearErrors}
              />
            )}
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <img src={InvitationFlowImage} style={{ width: '100%' }} />
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item lg={8} md={8} sm={12} xs={12} className={classes.footerIcons}>
          <Button
            color="secondary"
            variant="outlined"
            className={classes.buttonStyle}
            onClick={handleBackClick}
          >
            Back
          </Button>
          <Button
            color="secondary"
            variant="contained"
            className={classes.buttonStyle}
            sx={{ margin: '0 0 0 10px' }}
            onClick={handleSubmit(handleEmpDetailsSubmit)}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default InviEmploymentInfo;
