import { regexChar2 } from 'utils/utility';
import * as yup from 'yup';

export const AdvanceSearchSchema = yup.object().shape({
  appId: yup
    .string()
    .test('mobile invalid', 'It should contain only numeric characters', (val) => {
      return val ? yup.string().matches(/^\d*$/).isValidSync(val) : true;
    })
    .test('length', 'It should be numeric between 1 and 9 characters', (val) => {
      return val ? val?.length <= 9 : true;
    }),

  firstName: yup
    .string()
    .nullable()
    .max(15, 'Maximum 15 characters can be entered')
    .matches(regexChar2, 'Invalid format'),

  lastName: yup
    .string()
    .nullable()
    .test('len', 'Maximum 25 characters can be entered', (val) => val?.length <= 25)
    .matches(regexChar2, 'Invalid format'),
  secondLastName: yup
    .string()
    .nullable()
    .test('len', 'Maximum 25 characters can be entered', (val) => (val ? val?.length <= 25 : true))
    .matches(/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡.'\s]*$/g, 'Invalid format'),
  ssn: yup
    .string()
    .nullable()
    .test('length', '* Social security number should have 9 digit', (val) =>
      val ? val?.length === 11 : true
    ),
  mobile: yup
    .string()
    .test('length', '* Mobile number should have 10 digits', (val) => {
      return val ? val?.length === 10 : true;
    })
    .test('mobile invalid', 'Invalid Format', (val) => {
      return val ? yup.string().matches(/^\d+$/g).isValidSync(val) : true;
    }),
});
