/* eslint-disable react/prop-types */
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Button, Link } from '@mui/material';

const ReOpenApplicationFooter = ({
  onCancelEditSubmitted,
  backFlag,
  updateSubmitted,
  continueSubmitted,
  onBackClick,
}) => {
  return (
    <Box display="flex" justifyContent="space-between">
      {backFlag && (
        <Box display="flex" alignItems="center">
          <KeyboardArrowLeftIcon color="secondary" />
          <Link
            component="button"
            variant="subtitle2"
            color="secondary"
            id="back-link"
            data-testid="back-testid"
            onClick={onBackClick}
          >
            Back
          </Link>
        </Box>
      )}
      <Box display="flex" gap={2} py={4} marginLeft="auto">
        <Button
          color="secondary"
          variant="outlined"
          onClick={onCancelEditSubmitted}
          id="cancel-btn"
        >
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={updateSubmitted} id="update-btn">
          Submit
        </Button>
        <Button color="secondary" variant="contained" onClick={continueSubmitted} id="continue-btn">
          Update & continue
        </Button>
      </Box>
    </Box>
  );
};

export default ReOpenApplicationFooter;
