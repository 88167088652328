/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ApiClass from 'Api/ApiClient';
import Print from 'assets/svg/Print.svg';
import RemoveIcon from 'assets/svg/RemoveIcon.svg';
import PreviewIcon from 'assets/svg/filePreview.svg';
import AuthContext from 'context/AuthContext';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';
import PdfViewer from 'components/PdfViewer';

import DocumentAttachDialog from './DocumentAttachDialog';

const DocumentsAttached = ({ docMasterList, appId, getInfoReq, wholeDocs }) => {
  const { userID, fullName } = useContext(AuthContext);

  const ApiClient = new ApiClass();
  const TableHeading = ['Document', 'Document type', 'Upload time', 'User', 'Action'];

  const [loader, setLoader] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [attachDoc, setAttachDoc] = useState(false);
  const [apiErr, setApiErr] = useState('');
  const [mappedIds, setMappedIds] = useState([]);
  const [preview, setPreview] = useState(false);
  const [previewurl, setPreviewUrl] = useState('');
  const [genUrlList, setGenUrlList] = useState([]);
  useEffect(async () => {
    await getDocuments();
    await mapApplicantIds();
  }, []);

  const generatePreSignedUrls = async () => {
    try {
      setLoader(true);
      console.log('url', `${UrlHelpers.generatedPreSignedUrl}?applicationId=${appId}`);
      const urlList = await ApiClient.get(
        `${UrlHelpers.generatedPreSignedUrl}?applicationId=${appId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            userid: userID,
            userName: fullName,
          },
        }
      );
      console.log('resp', urlList);
      if (urlList && Object.entries(urlList)?.length) {
        const genUrls =
          Object.entries(urlList).map(([key, value]) => {
            return {
              uuid: key,
              url: value,
            };
          }) || [];
        setGenUrlList(genUrls);
        return genUrls;
      }
    } catch (e) {
      console.log('error in generating url', e);
      setGenUrlList([]);
      return [];
    } finally {
      setLoader(false);
    }
  };
  const mapApplicantIds = async () => {
    const {
      applicantId,
      coSigners = [],
      coApplicants = {},
    } = getInfoReq?.participantsDetailInformation;
    let arr = [];
    arr.push({ label: 'Applicant', id: applicantId });

    if (Object.entries(coApplicants)?.length) {
      arr.push({ label: 'Co-applicant', id: coApplicants?.[0]?.applicantId });
    }
    if (coSigners.length > 0) {
      coSigners.map((x, index) => {
        arr.push({ label: `Co-signer${index + 1}`, id: x?.applicantId });
      });
    }
    setMappedIds(arr);
  };

  const filterbyKey = (masterlist, arr, key) => {
    const findDocsId = masterlist
      ?.filter((x) => x.type === 'IDENTITY_DOC' || x.type === 'UTILITY_DOC')
      ?.map((x) => x?.id);
    let idDocs = arr?.filter((x) => findDocsId.includes(+x?.documentType));
    let otherdocs = arr?.filter((x) => !findDocsId.includes(+x?.documentType));
    const unique = {};
    otherdocs = otherdocs?.filter((x) => {
      if (!unique[x[key]]) {
        unique[x[key]] = true;
        return true;
      }
      return false;
    });

    return [...idDocs, ...otherdocs];
  };

  const attachDocumentToOriginate = async () => {
    let visibleDocs = docMasterList
      ?.filter((x) => x.visible)
      ?.map((each) => wholeDocs?.find((x) => x.id === each.id));
    if (documentList?.length < visibleDocs?.length) {
      setApiErr('Please upload all the contract documents to proceed further');
    } else {
      try {
        setLoader(true);
        await ApiClient.post(
          `${UrlHelpers.attachDocument}?applicationId=${appId}`,
          {},
          {
            headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
          }
        );

        await contractXml();
      } catch (e) {
        setApiErr(
          e?.response?.data?.Message ||
            e?.response?.data ||
            'We are unable to proceed due to a technical issue, please try again later'
        );
      } finally {
        setLoader(false);
      }
    }
  };

  const contractXml = async () => {
    try {
      setLoader(true);
      await ApiClient.post(
        `${UrlHelpers.contractXml}?appid=${appId}`,
        {},
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );
      await getDocuments();
      setApiErr('Documents submitted successfully');
    } catch (e) {
      setApiErr(
        e?.response?.data?.Message ||
          e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later'
      );
    } finally {
      setLoader(false);
    }
  };

  const getDocuments = async () => {
    try {
      setLoader(true);
      const getDocs = await ApiClient.get(`${UrlHelpers.getDocuments}?applicationId=${appId}`, {
        headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
      });
      let resp = filterbyKey(docMasterList, getDocs, 'documentType');

      setDocumentList(resp);
    } catch (e) {
      setDocumentList([]);
      setApiErr(
        e?.response?.data?.message ||
          e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later'
      );
    } finally {
      setLoader(false);
    }
  };

  const removeDoc = async (docId) => {
    try {
      setLoader(true);
      await ApiClient.delete(`${UrlHelpers.getDocuments}?documentId=${docId}`, {
        headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
      });
      await getDocuments();
    } catch (e) {
      console.log('err in get docs api', e);
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later'
      );
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Loader open={loader} />
      {preview && (
        <PdfViewer
          fileData={previewurl}
          handleClose={() => {
            setPreview(false);
          }}
          open={preview}
        />
      )}
      <Box my={1}>
        <ErrorAlert
          apiErr={apiErr}
          setApiErr={setApiErr}
          success={apiErr === 'Documents submitted successfully' ? true : false}
        />
      </Box>
      {attachDoc && (
        <DocumentAttachDialog
          open={attachDoc}
          setOpen={setAttachDoc}
          docMasterList={docMasterList}
          appId={appId}
          callBack={getDocuments}
          genUrlList={genUrlList}
          setGenUrlList={setGenUrlList}
          generatePreSignedUrls={generatePreSignedUrls}
        />
      )}
      <Typography variant="h6" fontWeight="700">
        Documents attached
      </Typography>
      <Box sx={{ overflowX: 'auto' }}>
        <Table
          aria-label="contract-documents-table"
          sx={{ border: '1px solid #F2F2F2', marginTop: '16px' }}
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: '#F2F2F2' }}>
              {TableHeading?.map((x) => {
                return (
                  <TableCell
                    key={x}
                    sx={{
                      fontWeight: 700,
                      fontSize: '14px !important',
                    }}
                  >
                    {x}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {documentList?.length > 0 &&
              documentList.map((eachDoc) => {
                let {
                  documentType,
                  updatedByName,
                  updatedDateTime,
                  isProcessed,
                  documentId,
                  applicantId,
                  url,
                } = eachDoc;
                const findDoc = docMasterList.find((x) => x.id == documentType);
                const docName =
                  (findDoc?.type === 'IDENTITY_DOC' || findDoc?.type === 'UTILITY_DOC') &&
                  mappedIds?.length > 1
                    ? `${findDoc?.displayName}(${
                        mappedIds?.find((x) => x?.id === applicantId)?.label
                      })`
                    : findDoc?.displayName;
                return (
                  <TableRow
                    key={documentId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{ fontSize: '14px !important' }}>{docName}</TableCell>
                    <TableCell sx={{ fontSize: '14px !important' }}>
                      {findDoc?.documentType}
                    </TableCell>
                    <TableCell sx={{ fontSize: '14px !important' }}>
                      {moment(updatedDateTime).format('MM/DD/yyyy, h:mm A')}
                    </TableCell>
                    <TableCell sx={{ fontSize: '14px !important' }}>{updatedByName}</TableCell>
                    <TableCell sx={{ fontSize: '14px !important' }}>
                      <Box display="flex" gap={2}>
                        <Link
                          color="secondary"
                          display="flex"
                          data-testid="printappln"
                          onClick={() => {
                            window.open(url);
                          }}
                          sx={{ cursor: 'pointer', textDecoration: 'none' }}
                        >
                          <img src={Print} />
                          &nbsp;
                          <Typography variant="subtitle2" component="p">
                            Print
                          </Typography>
                        </Link>
                        <Link
                          color="secondary"
                          display="flex"
                          data-testid="previewappln"
                          onClick={() => {
                            setPreviewUrl(url);
                            setPreview(true);
                          }}
                          sx={{ cursor: 'pointer', textDecoration: 'none' }}
                        >
                          <img src={PreviewIcon} />
                          &nbsp;
                          <Typography variant="subtitle2" component="p">
                            Preview
                          </Typography>
                        </Link>
                        {!isProcessed &&
                          findDoc?.type != 'IDENTITY_DOC' &&
                          findDoc?.type != 'UTILITY_DOC' && (
                            <Link
                              color="secondary"
                              display="flex"
                              data-testid="deleteApplication"
                              onClick={() => removeDoc(documentId)}
                              sx={{ cursor: 'pointer', textDecoration: 'none' }}
                            >
                              <img src={RemoveIcon} />
                              &nbsp;
                              <Typography variant="subtitle2" component="p">
                                Remove
                              </Typography>
                            </Link>
                          )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Box>
      <Box display="flex" gap={1} justifyContent="flex-end">
        <Button
          onClick={async () => {
            setApiErr('');
            setAttachDoc(true);
            await generatePreSignedUrls();
          }}
          color="secondary"
          variant="contained"
          sx={{ float: 'right', marginTop: '16px' }}
        >
          Attach document
        </Button>
        <Button
          onClick={attachDocumentToOriginate}
          color="secondary"
          variant="contained"
          sx={{ float: 'right', marginTop: '16px' }}
          disabled={documentList.filter((x) => !x.isProcessed)?.length === 0}
        >
          Submit documents
        </Button>
      </Box>
    </>
  );
};

export default DocumentsAttached;
