import { Box, Card } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import noImage from 'assets/images/promotion-no-image.png';
import PropTypes from 'prop-types';
import * as React from 'react';
import { defaultFn } from 'utils/utility';

export const OfferCard = ({ offer, setShowPromotion }) => {
  const onShow = () => {
    setShowPromotion(offer);
  };
  return (
    <Card
      sx={{ width: { xs: '100%', sm: '45%', md: '24%' }, display: 'flex', flexDirection: 'column' }}
      data-testid="offer-card"
      onClick={onShow}
    >
      <Box
        height="200px"
        alignSelf="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={offer?.promotionImage || noImage}
          alt="offer-image"
          width="300"
          height="200"
          data-testid="offer-image"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = noImage;
          }}
        />
      </Box>

      <CardContent>
        <Typography gutterBottom variant="h5" component="div" color="secondary">
          {offer?.promotionTitle}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {offer?.promotionDescription}
        </Typography>
        <Box display="flex" gap={2}>
          <Typography variant="body1">
            Valid Upto{' '}
            <Typography color="primary" component="span">
              {offer?.expirationDate}
            </Typography>{' '}
          </Typography>
          <Typography variant="body1">
            <Typography
              color="white"
              component="span"
              sx={{ backgroundColor: '#F28023', padding: '2px' }}
            >
              {offer?.promotionCount}
            </Typography>{' '}
            <Typography color="primary" component="span">
              Used this offer
            </Typography>{' '}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

OfferCard.propTypes = {
  offer: PropTypes.instanceOf(Object),
  setShowPromotion: PropTypes.func,
};
OfferCard.defaultProps = {
  offer: {},
  setShowPromotion: defaultFn,
};
