import { regexAddress, regexChar2, regexCharNum } from 'utils/utility';
import * as yup from 'yup';

import { dob, email, mobile2 } from './validationFunctions/validationFunctions';

export const InviInformationRequestSchema = (inviUserType) => {
  return yup.object().shape({
    relationship: yup.string().test('check relationship', '* Mandatory field', (val) => {
      console.log('inviUserType', inviUserType);
      console.log('val', !val);
      console.log("inviUserType !== 'primaryApplicant'", inviUserType !== 'primaryApplicant');
      return !(!val && inviUserType !== 'primaryApplicant');
    }),
    firstName: yup
      .string()
      .required('* Mandatory field')
      .test('len', 'Maximum 15 characters can be entered', (val) => val?.length <= 15)
      .matches(regexChar2, 'Invalid format'),
    middleName: yup
      .string()
      .nullable()
      .test('len', 'Maximum 20 characters can be entered', (val) =>
        val ? val?.length <= 20 : true
      )
      .matches(regexChar2, 'Invalid format'),
    lastName: yup
      .string()
      .required('* Mandatory field')
      .matches(regexChar2, 'Last name should have alphabetic characters only')
      .when(['middleName', 'secondLastName'], {
        is: (middleName, secondLastName) => {
          return !middleName && !secondLastName;
        },
        then: yup
          .string()
          .test(
            'length check',
            'Last name should be more than 1 character when middle name and second last name are not present',
            (val) => val?.length > 1
          ),
      })
      .test('len', 'Maximum 25 characters can be entered', (val) => val?.length <= 25),

    secondLastName: yup
      .string()
      .nullable()
      .test('len', 'Maximum 25 characters can be entered', (val) =>
        val ? val?.length <= 25 : true
      )
      .matches(/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡.'\s]*$/g, 'Invalid format')
      .test('check second last name', '* Mandatory field', (val) => {
        console.log('inviUserType', inviUserType);
        console.log('val', !val);
        console.log("inviUserType !== 'primaryApplicant'", inviUserType !== 'primaryApplicant');
        return !(!val && inviUserType === 'primaryApplicant');
      }),
    ssn: yup
      .string()
      .required('* Mandatory field')
      // .matches(/^[0-9]+$/g, 'Invalid format')
      .test('length', 'Social security number should have 9 digit', (val) =>
        val ? val?.length === 11 : true
      ),
    ...dob,
    mobile: yup
      .string()
      .required('* Mandatory field')
      .matches(/^\d+$/g, 'Invalid format')
      .test('length', 'Mobile number should have 10 digits', (val) => {
        return val?.length === 10;
      }),

    ...mobile2,

    ...email,

    maritalStatus: yup.string().required('* Mandatory field'),

    // numberOfDependents: yup.string().max(2, '* Exceeds maximum number of dependents allowed'),
    resAddress1: yup
      .string()
      .max(40, 'Address line 1 should be 40 characters or less')
      .required('* Mandatory field')
      .matches(regexAddress, 'Invalid format'),
    resAddress2: yup
      .string()
      .nullable()
      .max(20, 'Address line 2 should be 20 characters or less')
      .matches(regexAddress, 'Invalid format'),

    resCountry: yup.string().required('* Mandatory field'),
    resState: yup.string().required('* Mandatory field'),
    resCity: yup
      .string()
      .required('* Mandatory field')
      .test('len', 'Maximum 15 characters can be entered', (val) => val?.length <= 15)
      .matches(regexChar2, 'Invalid format'),
    resZipcode: yup
      .string()
      .required('* Mandatory field')
      .matches(/^\d+$/, 'Invalid format')
      .test('len', 'Please enter a valid zipcode', (val) => parseInt(val) !== 0)
      .test('len', 'Zipcode should have 5 digits', (val) => val?.length === 5),
    perAddress1: yup.string().when('isAddrSame', {
      is: false,
      then: yup
        .string()
        .required('* Mandatory field')
        .max(40, 'Address line 1 should be 40 characters or less')
        .matches(regexAddress, 'Invalid format'),
    }),
    perAddress2: yup.string().when('isAddrSame', {
      is: false,
      then: yup
        .string()
        .nullable()
        .max(20, 'Address line 2 should be 20 characters or less')
        .matches(regexAddress, 'Invalid format'),
    }),

    perCountry: yup.string().when('isAddrSame', {
      is: false,
      then: yup.string().required('* Mandadtory Field'),
    }),
    perState: yup.string().when('isAddrSame', {
      is: false,
      then: yup.string().required('* Mandadtory Field'),
    }),
    perCity: yup.string().when('isAddrSame', {
      is: false,
      then: yup
        .string()
        .required('* Mandadtory Field')
        .test('len', 'Maximum 15 characters can be entered', (val) => val?.length <= 15)
        .matches(regexChar2, 'Invalid format'),
    }),
    perZipcode: yup.string().when('isAddrSame', {
      is: false,
      then: yup
        .string()
        .required('* Mandatory field')
        .matches(/^\d+$/, 'Invalid format')
        .test('len', 'Please enter a valid zipcode', (val) => parseInt(val) !== 0)
        .test('len', 'Zipcode should have 5 digits', (val) => val?.length === 5),
    }),

    residentStatus: yup.string().required('* Mandatory field'),
    monthlyPayment: yup.string().when('residentStatus', {
      is: (value) => value === 'Rent' || value === 'Homeowner',

      then: yup
        .string()
        .required('* Mandatory field')
        .test('check for zero', 'Please enter a valid value', (val) => {
          const value = val?.replace(/[^0-9.]/g, '');
          console.log('val', val);
          console.log('value', value);
          return parseInt(value) !== 0;
        }),
    }),
    residentYears: yup
      .string()
      .required('* Mandatory field')
      .matches(/^\d*$/, 'Invalid Format')

      .test('year restriction', '* Invalid year', (val) => {
        console.log('years', val);

        return parseInt(val) <= 99;
      }),
    residentMonths: yup
      .string()
      .required('* Mandatory field')
      .matches(/^\d*$/, 'Invalid Format')

      .test('month restriction', 'Invalid month', (val) => {
        return parseInt(val) <= 11;
      })

      .when('residentYears', {
        is: (val) => parseInt(val) === 0,
        then: yup
          .string()
          .test('check for zero', 'Please enter a valid value', (val) => parseInt(val) !== 0),
      }),

    resUtilityBill: yup.string().when('utilitybillfile', {
      is: (val) => !!val,
      then: yup.string().required('Please select a utility bill'),
    }),
    utilitybillfile: yup.mixed().test('len', 'Please upload a file to proceed', function (val) {
      const { resUtilityBill } = this?.parent;
      return !(resUtilityBill && !val);
    }),
  });
};

export const InviEmploymentInfoSchema = yup.object().shape({
  empStatus: yup.string().required('* Mandatory field'),
  empName: yup
    .string()
    .required('* Mandatory field')
    .max(50, 'Employer name should be 50 characters or less')
    .matches(regexCharNum, 'Invalid format'),
  empAddressLine1: yup
    .string()
    .nullable()
    .max(40, 'Employer Address line 1 should be 40 characters or less')
    .matches(/^[a-z0-9A-ZáéíóúñüÁÉÍÓÚÑÜ¿¡,./-\s]*$/g, 'Invalid format'),
  empAddressLine2: yup
    .string()
    .nullable()
    .test('len', '* Employer Address line 2 should be 20 characters or less', (val) =>
      val ? val?.length <= 20 : true
    )
    .matches(/^[a-z0-9A-ZáéíóúñüÁÉÍÓÚÑÜ¿¡,./-\s]*$/, 'Invalid format'),
  empCountry: yup.string(),
  empState: yup.string(),
  empCity: yup
    .string()
    .nullable()
    .transform((v) => (v === '' ? null : v))
    .matches(regexChar2, 'Invalid format')
    .test('len', 'Maximum 15 characters can be entered', (val) => (val ? val?.length <= 15 : true)),
  empZipcode: yup
    .string()
    .nullable()
    .transform((v) => (v === '' ? null : v))
    .matches(/^\d+$/, 'Invalid format')
    .test('len', 'Please enter a valid zipcode', (val) => (val ? parseInt(val) !== 0 : true))
    .test('len', 'Zipcode should have 5 digits', (val) => (val ? val?.length === 5 : true)),
  jobTitle: yup
    .string()
    .required('* Mandatory field')
    .max(30, 'Job title should be 30 characters or less')
    .matches(regexChar2, 'Invalid format'),
  occupation: yup.string().nullable().required('* Mandatory field'),
  industry: yup.string().required('* Mandatory field'),
  // .matches(regexChar2, 'Invalid format'),
  empYears: yup
    .string()
    .required('* Mandatory field')
    .matches(/^\d*$/, 'Invalid Format')
    .test('month restriction', 'Invalid year', (val) => {
      return parseInt(val) <= 99;
    }),
  empMonths: yup
    .string()
    .required('* Mandatory field')
    .matches(/^\d*$/, 'Invalid Format')
    .test('year restriction', 'Invalid month', (val) => {
      return parseInt(val) <= 11;
    })
    .when('empYears', {
      is: (val) => parseInt(val) === 0,
      then: yup
        .string()
        .test('check for zero', 'Please enter a valid value', (val) => parseInt(val) !== 0),
    }),
  monthlyIncome: yup
    .string()
    .nullable()
    .required('* Mandatory field')
    .transform((value) => {
      // Remove the % sign and any non-numeric characters
      return value ? value.replace(/[^0-9.]/g, '') : null;
    }),
  mobile: yup
    .string()
    .required('* Mandatory field')
    .matches(/^\d+$/g, 'Invalid format')
    .test('length', 'Work phone should have 10 digits', (val) => {
      return val?.length === 10;
    }),

  addEmpName: yup.string().when('showAddInfo', {
    is: true,
    then: yup
      .string()
      .required('* Mandatory field')
      .max(50, 'Employer name should be 50 characters or less')
      .matches(regexCharNum, 'Invalid format'),
  }),
  addJobTitle: yup.string().when('showAddInfo', {
    is: true,
    then: yup
      .string()
      .required('* Mandatory field')
      .max(30, 'Job title should be 30 characters or less')
      .matches(regexChar2, 'Invalid format'),
  }),
  addOccupation: yup.string().when('showAddInfo', {
    is: true,
    then: yup.string().nullable().required('* Mandatory field'),
  }),
  mobile2: yup.string().when('showAddInfo', {
    is: true,
    then: yup
      .string()
      .required('* Mandatory field')
      .matches(/^\d+$/g, 'Invalid format')
      .test('length', 'Work phone should have 10 digits', (val) => {
        return val?.length === 10;
      }),
  }),

  addMonthlyIncome: yup.string().when('showAddInfo', {
    is: true,
    then: yup
      .string()
      .nullable()
      .required('* Mandatory field')
      .transform((value) => {
        // Remove the % sign and any non-numeric characters
        return value ? value.replace(/[^0-9.]/g, '') : null;
      }),
  }),
});
