import ApiClass from 'Api/ApiClient';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { bankerRoles } from 'utils/utility';

export const step1DropDownApis = async ({
  stateCityList,
  setstateCityList,
  setStateList,
  cityZipList,
  setCityZipList,
  residentStatusList,
  setResidentStatusList,
  maritalStatusList,
  setMaritalStatusList,
  relationshipList,
  setRelationShipList,
  utilityBillList,
  setUtilityBillList,
}) => {
  const ApiClient = new ApiClass();

  await stateListApi({ stateCityList, setstateCityList, setStateList });
  if (!residentStatusList?.length) {
    try {
      let residentStatusListt = await ApiClient.get(UrlHelpers.residentialStatusLookup);

      residentStatusListt = JSON.parse(residentStatusListt?.['residential-status']);

      residentStatusListt = residentStatusListt.map((x) => {
        const { displayName, value } = x;
        return { label: displayName, value };
      });
      setResidentStatusList(residentStatusListt);
    } catch (e) {
      console.log('e', e);
    }
  }
  if (!maritalStatusList?.length) {
    try {
      let maritalStatusListt = await ApiClient.get(UrlHelpers.maritalStatusLookup);

      maritalStatusListt = JSON.parse(maritalStatusListt?.['marital-status']);

      maritalStatusListt = maritalStatusListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setMaritalStatusList(maritalStatusListt);
    } catch (e) {
      console.log('e', e);
    }
  }

  if (!relationshipList?.length) {
    try {
      let relationshipListt = await ApiClient.get(UrlHelpers.relationshipLookup);

      relationshipListt = JSON.parse(relationshipListt?.['relationship']);

      relationshipListt = relationshipListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setRelationShipList(relationshipListt);
    } catch (e) {
      console.log('e', e);
    }
  }
  if (!utilityBillList?.length) {
    try {
      let utilityBillListt = await ApiClient.get(UrlHelpers.utilityBillLookup);

      utilityBillListt = JSON.parse(utilityBillListt?.['utility-bill']);

      utilityBillListt = utilityBillListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setUtilityBillList(utilityBillListt);
    } catch (e) {
      console.log('e', e);
    }
  }
  await cityZipListApi({ cityZipList, setCityZipList });
};

export const stateListApi = async ({ stateCityList, setstateCityList, setStateList }) => {
  const ApiClient = new ApiClass();
  if (!stateCityList?.length) {
    try {
      const resp = await ApiClient.get(UrlHelpers.cityLookup);
      let cityListt = JSON.parse(resp?.['country-state-city'])[0];
      setstateCityList([cityListt]);

      const statesData = cityListt?.states?.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });

      setStateList(statesData);
    } catch (e) {
      console.log('e', e);
    }
  }
};
const cityZipListApi = async ({ cityZipList, setCityZipList }) => {
  const ApiClient = new ApiClass();
  if (!cityZipList?.length) {
    try {
      let cityZipListt = await ApiClient.get(UrlHelpers.cityZipCodeMapping);

      cityZipListt = JSON.parse(cityZipListt?.['city-zipcode']);

      cityZipListt = cityZipListt.map((x) => {
        const { displayName, value } = x;
        return { zipcode: displayName, city: value };
      });
      setCityZipList(cityZipListt);
    } catch (e) {
      console.log('e', e);
    }
  }
};
export const step2DropDownApis = async ({
  setstateCityList,
  setStateList,
  stateCityList,
  occupationList,
  setOccupationList,
  empStatusList,
  setEmpStatusList,
  industryList,
  setIndustryList,
  cityZipList,
  setCityZipList,
}) => {
  const ApiClient = new ApiClass();
  if (!stateCityList?.length) {
    try {
      const resp = await ApiClient.get(UrlHelpers.cityLookup);
      let cityListt = JSON.parse(resp?.['country-state-city'])[0];
      setstateCityList([cityListt]);
      console.log({ cityListt });
      const statesData = cityListt?.states?.map((x) => {
        return { name: x?.displayName, value: x?.value };
      });
      setStateList(statesData);
    } catch (e) {
      console.log('e', e);
    }
  }
  await empStatusListApi({ empStatusList, setEmpStatusList });

  if (!occupationList?.length) {
    try {
      let occupationListt = await ApiClient.get(UrlHelpers.occupationLookup);

      occupationListt = JSON.parse(occupationListt?.['occupation']);

      occupationListt = occupationListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setOccupationList(occupationListt);
    } catch (e) {
      console.log('e', e);
    }
  }

  await industryListApi({ industryList, setIndustryList });
  await cityZipListApi({ cityZipList, setCityZipList });
};

export const industryListApi = async ({ industryList, setIndustryList }) => {
  const ApiClient = new ApiClass();
  if (!industryList?.length) {
    try {
      let industryListt = await ApiClient.get(UrlHelpers.industryLookup);

      industryListt = JSON.parse(industryListt?.['industry']);

      industryListt = industryListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setIndustryList(industryListt);
    } catch (e) {
      console.log('e', e);
    }
  }
};

export const empStatusListApi = async ({ empStatusList, setEmpStatusList }) => {
  const ApiClient = new ApiClass();
  if (!empStatusList?.length) {
    try {
      let employmentStatusListt = await ApiClient.get(UrlHelpers.employmentStatusLookup);

      employmentStatusListt = JSON.parse(employmentStatusListt?.['employment-status']);

      employmentStatusListt = employmentStatusListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setEmpStatusList(employmentStatusListt);
    } catch (e) {
      console.log('e', e);
    }
  }
};
export const popUpApis = async ({
  loanPurposeList,
  setLoanPurposeList,
  // programList,
  setProgramList,
  setInitialApiLoader,
  accessToken,
  role,
}) => {
  const ApiClient = new ApiClass();

  if (!loanPurposeList?.length) {
    try {
      setInitialApiLoader(true);
      let loanPurposeListt = await ApiClient.get(UrlHelpers.loanPurpose, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
          RoleID: role,
        },
      });

      loanPurposeListt = JSON.parse(loanPurposeListt?.['loan-purpose']);

      loanPurposeListt = loanPurposeListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setLoanPurposeList(loanPurposeListt);
    } catch (e) {
      console.log('e', e);
    } finally {
      setInitialApiLoader(false);
    }
  }

  try {
    setInitialApiLoader(true);
    let programListt = await ApiClient.get(UrlHelpers.program, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
        RoleID: role,
      },
    });

    programListt = JSON.parse(programListt?.['program']);

    programListt = programListt.map((x) => {
      const { displayName, value } = x;
      return { label: displayName, value };
    });
    setProgramList(programListt);
  } catch (e) {
    console.log('e', e);
  } finally {
    setInitialApiLoader(false);
  }
};

const handlePaycalcIncuranceApi = async ({
  creditLifeTypeList,
  setCreditLifeTypeList,
  creditLifeList,
  setCreditLifeList,
  vehicleInsuranceTypeList,
  setVehicleInsuranceTypeList,
}) => {
  const ApiClient = new ApiClass();
  if (!creditLifeTypeList?.length) {
    try {
      let creditLifeTypeListt = await ApiClient.get(UrlHelpers.creditLifeType);

      creditLifeTypeListt = JSON.parse(creditLifeTypeListt?.['credit-life-type']);

      creditLifeTypeListt = creditLifeTypeListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setCreditLifeTypeList(creditLifeTypeListt);
    } catch (e) {
      console.log('e', e);
    }
  }
  if (!creditLifeList?.length) {
    try {
      let creditLifeListt = await ApiClient.get(UrlHelpers.creditLife);
      console.log({ creditLifeListt });
      creditLifeListt = JSON.parse(creditLifeListt?.['credit-life']);

      creditLifeListt = creditLifeListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setCreditLifeList(creditLifeListt);
    } catch (e) {
      console.log('e', e);
    }
  }
  if (!vehicleInsuranceTypeList?.length) {
    try {
      let vehicleInsuranceTypeListt = await ApiClient.get(UrlHelpers.vehicleInsuranceType);

      vehicleInsuranceTypeListt = JSON.parse(vehicleInsuranceTypeListt?.['vehicle-insurance-type']);

      vehicleInsuranceTypeListt = vehicleInsuranceTypeListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setVehicleInsuranceTypeList(vehicleInsuranceTypeListt);
    } catch (e) {
      console.log('e', e);
    }
  }
};
export const payCalcApis = async ({
  newUsedList,
  setNewUsedList,
  monthsDeferredList,
  setMonthsDeferredList,
  setProgramList,
  makeList,
  setMakeList,
  creditLifeTypeList,
  setCreditLifeTypeList,
  creditLifeList,
  setCreditLifeList,
  vehicleInsuranceTypeList,
  setVehicleInsuranceTypeList,
}) => {
  const ApiClient = new ApiClass();

  if (!makeList.length) {
    try {
      let makeListt = await ApiClient.get(UrlHelpers.make);

      makeListt = JSON.parse(makeListt?.['make']);

      makeListt = makeListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setMakeList(makeListt);
    } catch (e) {
      console.log('e', e);
    }
  }

  try {
    let programListt = await ApiClient.get(UrlHelpers.program);

    programListt = JSON.parse(programListt?.['program']);

    programListt = programListt.map((x) => {
      const { displayName, value } = x;
      return { name: displayName, value };
    });
    setProgramList(programListt);
  } catch (e) {
    console.log('e', e);
  }
  if (!newUsedList.length) {
    try {
      let newUsedListt = await ApiClient.get(UrlHelpers.newUsed);

      newUsedListt = JSON.parse(newUsedListt?.['new-used']);

      newUsedListt = newUsedListt.map((x) => {
        const { displayName, value } = x;
        return { label: displayName, value };
      });
      setNewUsedList(newUsedListt);
    } catch (e) {
      console.log('e', e);
    }
  }
  if (!monthsDeferredList?.length) {
    try {
      let monthsDeferredListt = await ApiClient.get(UrlHelpers.monthsDeferred);

      monthsDeferredListt = JSON.parse(monthsDeferredListt?.['months-deferred']);

      monthsDeferredListt = monthsDeferredListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setMonthsDeferredList(monthsDeferredListt);
    } catch (e) {
      console.log('e', e);
    }
  }

  await handlePaycalcIncuranceApi({
    creditLifeTypeList,
    setCreditLifeTypeList,
    creditLifeList,
    setCreditLifeList,
    vehicleInsuranceTypeList,
    setVehicleInsuranceTypeList,
  });
};

export const getSpecificDealers = async (userID) => {
  const ApiClient = new ApiClass();
  try {
    const resp = await ApiClient.get(UrlHelpers.dealerSpecificList + userID);
    return resp?.dealerList;
  } catch (e) {
    console.log('e', e);
  }
};

export const getDealers = async () => {
  const ApiClient = new ApiClass();
  try {
    return await ApiClient.get(`${UrlHelpers.dealers}?status=1`);
  } catch (e) {
    console.log('e', e);
    return [];
  }
};

export const getManagerSalesList = async (dealerId) => {
  const ApiClient = new ApiClass();
  try {
    return await ApiClient.get(`${UrlHelpers.managerSalesList}${dealerId}`);
  } catch (e) {
    console.log('e', e);
    return [];
  }
};
export const getManagerList = async (dealerId) => {
  const ApiClient = new ApiClass();
  try {
    console.log({ dealerId, checker: `${UrlHelpers.managerList}/${dealerId}` });
    return await ApiClient.get(`${UrlHelpers.managerList}/${dealerId}`);
  } catch (e) {
    console.log('e', e);
    return [];
  }
};
export const getSalesList = async (dealerId) => {
  const ApiClient = new ApiClass();
  try {
    return await ApiClient.get(`${UrlHelpers.salesList}/${dealerId}`);
  } catch (e) {
    console.log('e', e);
    return [];
  }
};

export const homeApis = async ({ frequencyList, setFrequencyList, setLoader }) => {
  const ApiClient = new ApiClass();
  if (!frequencyList?.length) {
    try {
      setLoader(true);
      let frequencyListt = await ApiClient.get(UrlHelpers.frequencyOpt);

      frequencyListt = JSON.parse(frequencyListt?.['frequency-summary']);

      frequencyListt = frequencyListt.map((x) => {
        const { displayName, value } = x;
        return { displayName, value };
      });
      setFrequencyList(frequencyListt);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  }
};

export const applicationFiltersApis = async ({
  frequencyDetailList,
  setFrequencyDetailList,
  loanStatusList,
  setLoanStatusList,
  rolebasedDealers,
  setRoleBasedDealers,
  setLoader,
  userID,
  flow,
  role,
  approvalStatusList,
  setApprovalStatusList,
}) => {
  const ApiClient = new ApiClass();
  if ((flow === 'Applications' || flow === 'UserRequest') && !rolebasedDealers?.length) {
    await dealersRoleBased({ rolebasedDealers, setRoleBasedDealers, role, userID });
  }
  if (!frequencyDetailList?.length) {
    try {
      setLoader(true);
      let frequencyDetailListt = await ApiClient.get(UrlHelpers.frequencyDetail, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      frequencyDetailListt = JSON.parse(frequencyDetailListt?.['frequency-detail']);

      frequencyDetailListt = frequencyDetailListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setFrequencyDetailList(frequencyDetailListt);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  }

  if (flow !== 'UserRequest' && !loanStatusList?.length) {
    try {
      setLoader(true);
      let loanStatusListt = await ApiClient.get(UrlHelpers.applicationLoanStatus, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      loanStatusListt = JSON.parse(loanStatusListt?.['loan-status']);

      loanStatusListt = loanStatusListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setLoanStatusList(loanStatusListt);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  }
  if (flow === 'UserRequest' && !approvalStatusList?.length) {
    try {
      setLoader(true);
      let approvalStatusListt = await ApiClient.get(UrlHelpers.approvalStatus, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      approvalStatusListt = JSON.parse(approvalStatusListt?.['user-request-status']);
      approvalStatusListt = approvalStatusListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setApprovalStatusList(approvalStatusListt);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  }
};

export const dealersRoleBased = async ({ rolebasedDealers, setRoleBasedDealers, role, userID }) => {
  console.log({ role, userID, rolebasedDealers });
  let dealerList = rolebasedDealers;
  if (!rolebasedDealers?.length && role !== bankerRoles?.master && role !== bankerRoles?.employee) {
    dealerList = await getSpecificDealers(userID);
    setRoleBasedDealers(dealerList);
  } else if (
    !rolebasedDealers?.length &&
    (role == bankerRoles?.master || role == bankerRoles?.employee)
  ) {
    dealerList = await getDealers();
    setRoleBasedDealers(dealerList);
  }
  return dealerList;
};

export const InviStep1DropdownApis = async ({
  maritalStatusList,
  setstateCityList,
  setStateList,
  setMaritalStatusList,
  stateCityList,
  residentStatusList,
  setResidentStatusList,
  relationshipList,
  setRelationShipList,
}) => {
  const ApiClient = new ApiClass();

  if (!stateCityList?.length) {
    try {
      const resp = await ApiClient.get(UrlHelpers.cityLookup);
      let cityListt = JSON.parse(resp?.['country-state-city'])[0];

      setstateCityList([cityListt]);
      const statesData = cityListt?.states?.map((x) => {
        return { name: x?.displayName, value: x?.value };
      });
      setStateList(statesData);
    } catch (e) {
      console.log('e', e);
    }
  }
  if (!residentStatusList?.length) {
    try {
      let residentStatusListt = await ApiClient.get(UrlHelpers.residentialStatusLookup);

      residentStatusListt = JSON.parse(residentStatusListt?.['residential-status']);

      residentStatusListt = residentStatusListt.map((x) => {
        const { displayName, value } = x;
        return { label: displayName, value };
      });
      setResidentStatusList(residentStatusListt);
    } catch (e) {
      console.log('e', e);
    }
  }

  if (!relationshipList?.length) {
    try {
      let relationshipListt = await ApiClient.get(UrlHelpers.relationshipLookup);

      relationshipListt = JSON.parse(relationshipListt?.['relationship']);

      relationshipListt = relationshipListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setRelationShipList(relationshipListt);
    } catch (e) {
      console.log('e', e);
    }
  }
  await maritalStatusList({ setMaritalStatusList, maritalStatusList });
};

export const maritalStatusListApi = async ({ setMaritalStatusList, maritalStatusList }) => {
  const ApiClient = new ApiClass();
  if (!maritalStatusList?.length) {
    try {
      let maritalStatusListt = await ApiClient.get(UrlHelpers.maritalStatusLookup);

      maritalStatusListt = JSON.parse(maritalStatusListt?.['marital-status']);

      maritalStatusListt = maritalStatusListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      console.log({ maritalStatusListt });
      setMaritalStatusList(maritalStatusListt);
    } catch (e) {
      console.log('e', e);
    }
  }
};
