import { Box } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import { applicationFiltersApis } from 'pages/CreateApplication/InformationRequest/moduleUtilities/dropdownApis';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn } from 'utils/utility';

import Loader from 'components/Loader/Loader';

import ApplicationsTable from './ApplicationsTable';
import { SearchFilters } from './SearchFilters';

export const MyApplications = ({ setApiErr }) => {
  const {
    userID,
    rolebasedDealers,
    setRoleBasedDealers,
    frequencyDetailList,
    setFrequencyDetailList,
    loanStatusList,
    setLoanStatusList,
  } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [searchOptions, setSearchOptions] = useState({
    frequency: '7_DAY',
    loanStatus: '',
    spName: '',
    createdBy: '',
    vehicleName: '',
  });
  const [applicationData, setApplicationData] = useState([]);
  const [searchAnchor, setSearchAnchor] = useState(false);
  const [emptyDataMessage, setEmptyDataMessage] = useState('No application/s available');
  const rowsPerPage = 10;
  const ApiClient = new ApiClass();

  useEffect(async () => {
    await applicationFiltersApis({
      frequencyDetailList,
      setFrequencyDetailList,
      loanStatusList,
      setLoanStatusList,
      flow: 'Applications',
      setLoader,
      rolebasedDealers,
      setRoleBasedDealers,
      userID,
    });
  }, []);
  const fetchApplication = async () => {
    try {
      setLoader(true);
      const resp = await ApiClient.get(
        `${UrlHelpers.fetchApplications}?frequency=7_DAY&createdBy=${userID}&appStatus=SUBMITTED`,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (resp) {
        setApplicationData(resp?.applicationList);
      }
      return resp;
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  };
  useEffect(async () => {
    fetchApplication();
  }, [userID]);

  return (
    <Box>
      <Loader open={loader} />
      <SearchFilters
        searchOptions={searchOptions}
        setSearchOptions={setSearchOptions}
        frequencyDetailList={frequencyDetailList}
        loanStatusList={loanStatusList}
        applicationData={applicationData}
        setApplicationData={setApplicationData}
        setLoader={setLoader}
        myAppFlag
        userID={userID}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        searchAnchor={searchAnchor}
        setSearchAnchor={setSearchAnchor}
        flow="MyApplications"
        setEmptyDataMessage={setEmptyDataMessage}
        fetchMyApplications={fetchApplication}
      />
      <ApplicationsTable
        applicationsData={applicationData}
        page={page}
        setPage={setPage}
        hasPagination
        emptyDataMessage={emptyDataMessage}
        setApiErr={setApiErr}
        tabName="My loan applications"
      />
    </Box>
  );
};
MyApplications.propTypes = {
  setApiErr: PropTypes.func,
};

MyApplications.defaultProps = {
  setApiErr: defaultFn,
};
