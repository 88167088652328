/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import EditIcon from 'assets/svg/Edit-icon.svg';
import RemoveIcon from 'assets/svg/RemoveIcon.svg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import downArrow from '../assets/downArrow.svg';
import rightArrow from '../assets/rightArrow.svg';
import classes from '../styles/globalStyle.module.scss';
import CustomSwitch from './CustomSwitch/CustomSwitch';

const CustomCollapse = ({
  heading,
  children,
  mailButton,
  checked,
  open,
  removeButton,
  editable,
  onEdit,
  payCalcButton,
  monthlyPayData,
  reviewFlag,
  completedSteps,
  valueLookupButton,
  setShowCollateralPopup,
  clearErrorsAdditionalInfo,
  setValue,
  prefixName,
  flow,
  viewAppFlow,
  unfilled,
  errors,
}) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(open);
  const navigate = useNavigate();

  const handleClick = () => {
    setIsCollapseOpen(!isCollapseOpen);
  };

  useEffect(() => {
    setIsCollapseOpen(open);
  }, [errors, open]);
  return (
    <div className={classes.collapsableDiv}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.heading}
        gap={4}
      >
        <Button
          onClick={handleClick}
          style={{ padding: '0' }}
          aria-label={isCollapseOpen ? `${heading} Expanded` : `${heading} Collapsed`}
          id={`${heading}-collapse-btn`}
        >
          {isCollapseOpen ? (
            <img src={downArrow} alt="Expand less" />
          ) : (
            <img src={rightArrow} data-testid="ExpandMoreIcon" alt="Expand more" />
          )}
          <Grid container justifyContent="space-between">
            <Typography variant="h6" component="h3" my={2} style={{ fontWeight: 700 }} id={heading}>
              &nbsp;&nbsp;{heading}
            </Typography>
          </Grid>
        </Button>
        {mailButton && (
          <Typography
            variant="subtitle2"
            component="span"
            my={2}
            classes={{ root: classes.customToggleText }}
          >
            Mailing address same as the residential address
            <FormGroup>
              <FormControlLabel
                sx={{ margin: '0' }}
                control={
                  <CustomSwitch
                    ariaLabel="Mailing address same as the residential address"
                    sx={{ m: 1 }}
                    checked={checked}
                    onChange={() => {
                      setValue(`${prefixName}.isAddrSame`, !checked);
                    }}
                    defaultChecked
                  />
                }
              />
            </FormGroup>
          </Typography>
        )}
        {removeButton && (
          <Link
            component="button"
            variant="subtitle2"
            color="secondary"
            id="add-income-remove-btn"
            onClick={() => {
              if (flow === 'inviFlow') {
                setValue(`showAddInfo`, false);

                clearErrorsAdditionalInfo([
                  `addEmpName`,
                  `addJobTitle`,
                  `addOccupation`,
                  `mobile2`,
                  `addMonthlyIncome`,
                ]);
                setValue(`addEmpName`, '');
                setValue(`addJobTitle`, '');
                setValue(`addOccupation`, '');
                setValue(`mobile2`, '');
                setValue(`addMonthlyIncome`, '');
              } else {
                setValue(`${prefixName}.showAddInfo`, false);

                clearErrorsAdditionalInfo([
                  `${prefixName}.addEmpName`,
                  `${prefixName}.addJobTitle`,
                  `${prefixName}.addOccupation`,
                  `${prefixName}.mobile2`,
                  `${prefixName}.addMonthlyIncome`,
                ]);
                setValue(`${prefixName}.addEmpName`, '');
                setValue(`${prefixName}.addJobTitle`, '');
                setValue(`${prefixName}.addOccupation`, '');
                setValue(`${prefixName}.mobile2`, '');
                setValue(`${prefixName}.addMonthlyIncome`, '');
              }
            }}
          >
            <Box display="flex" alignItems="center">
              <img src={RemoveIcon} alt="Remove Button" style={{ marginRight: '8px' }} />
              Remove
            </Box>
          </Link>
        )}
        {payCalcButton && (
          <Button
            variant="contained"
            color="primary"
            sx={{ maxHeight: '40px' }}
            onClick={() =>
              navigate('/paymentCalculator', {
                state: {
                  flow: 'financialTerms',
                  data: monthlyPayData,
                  reviewFlag,
                  completedSteps,
                  viewAppFlow,
                  unfilled,
                },
              })
            }
          >
            Calculate
          </Button>
        )}
        {valueLookupButton && (
          <Button
            sx={{
              background: '#E37617',
              borderRadius: '5px',
              width: '177px',
              height: '40px',
              color: '#000000',
            }}
            onClick={() => setShowCollateralPopup(true)}
          >
            Value lookup
          </Button>
        )}
        {editable && (
          <Link
            component="button"
            variant="subtitle2"
            color="secondary"
            aria-label="Edit"
            onClick={onEdit}
          >
            <Box display="flex" alignItems="center">
              <img
                src={EditIcon}
                alt="Edit Button"
                data-testid="edit-icon"
                style={{ marginRight: '8px' }}
              />
              Edit
            </Box>
          </Link>
        )}
      </Box>
      <Collapse in={isCollapseOpen} timeout="auto" id={`${heading}-collapsable`}>
        {children}
      </Collapse>
    </div>
  );
};

export default CustomCollapse;
