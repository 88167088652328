/* eslint-disable react/prop-types */
import { Box, Button, FormLabel, Grid, Typography } from '@mui/material';
import InfoIcon from 'assets/svg/InfoIcon.svg';
import UploadContainerIcon from 'assets/svg/UploadContainerIcon.svg';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';

const UploadContainer = ({ required, label, activeStep, formFields }) => {
  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12}>
        <Box display="flex" alignItems="center">
          <FormLabel sx={{ color: '#000', width: 'unset' }}>
            {required && <span style={{ color: 'red' }}>*&nbsp;</span>} {label}
          </FormLabel>
          &nbsp;
          <img src={InfoIcon} />
        </Box>
        <Box width="100%" className={classes.uploadContentContainer} sx={{ marginTop: '10px' }}>
          <img src={UploadContainerIcon} />
          <Typography
            variant="subtitle2"
            component="p"
            sx={{ padding: '10px 0', textAlign: 'center' }}
          >
            By clicking below, you will navigate to an ID verification process
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() =>
              navigate('/vouched', {
                state: {
                  flow: 'invitationFlow',
                  activeStep: activeStep,
                  formFields: formFields,
                },
              })
            }
            sx={{ maxWidth: '200px' }}
          >
             Start ID verification
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UploadContainer;
UploadContainer.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  flow: PropTypes.string,
};
UploadContainer.defaultProps = {
  required: false,
  label: '',
  flow: '',
};
