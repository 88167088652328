/* eslint-disable react/prop-types */

/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ApiClass from 'Api/ApiClient';
import PreviewIcon from 'assets/svg/filePreview.svg';
import AuthContext from 'context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';
import PdfViewer from 'components/PdfViewer';

import ContractDocumentsSecond from './ContractDocumentsSecond';

const ContractDocuments = ({ appId, getInfoReq }) => {
  const { userID, fullName } = useContext(AuthContext);
  const ApiClient = new ApiClass();
  const TableHeading = ['Document', 'Required?', 'Action'];
  const [showGenerated, setShowGenerated] = useState(false);

  const [preview, setPreview] = useState(false);
  const [loader, setLoader] = useState(false);
  const [docList, setDocList] = useState([]);
  const [previewurl, setPreviewUrl] = useState('');
  const [apiErr, setApiErr] = useState('');
  const [initGeneratedDocs, setInitGeneratedDocs] = useState([]);
  useEffect(async () => {
    let masterDocs = [];
    try {
      setLoader(true);
      const resp = await ApiClient.get(`${UrlHelpers.contractDocument}`);

      console.log('into contract docs', { resp });
      if (JSON.parse(resp?.['contract-document'])?.length) {
        setDocList(JSON.parse(resp?.['contract-document']));
        masterDocs = JSON.parse(resp?.['contract-document']);
      }
    } catch (e) {
      console.log('err in get contract docs api', e);
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later'
      );
    } finally {
      setLoader(false);
    }
    await getDocuments(masterDocs);
  }, []);

  const getDocuments = async (masterDocs) => {
    try {
      setLoader(true);
      const getDocs = await ApiClient.get(
        `${UrlHelpers.getDocuments}?applicationId=${appId}&isAutoGenerateReq=true`,
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );
      const findIdDocs = masterDocs
        .filter((x) => x.type === 'IDENTITY_DOC' || x.type === 'UTILITY_DOC')
        .map((x) => x?.id);

      if (
        getDocs?.length > 0 &&
        getDocs?.filter((x) => !findIdDocs.includes(+x?.documentType)).length > 0
      ) {
        await onGenerate();
        setShowGenerated(true);
      } else {
        setShowGenerated(false);
      }
    } catch (e) {
      console.log('err in get docs api', e);
      setApiErr(
        e?.response?.data?.message ||
          e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later'
      );
    } finally {
      setLoader(false);
    }
  };

  const onPreview = async (val, lang) => {
    try {
      setLoader(true);
      const resp = await ApiClient.get(
        `${UrlHelpers.previewDoc}?documentTemplate=${val}&locale=${lang}`
      );

      setPreviewUrl(resp?.presignedUri);
      setPreview(true);
      console.log('resp', resp);
    } catch (e) {
      console.log('err in get contract docs api', e);
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later'
      );
    } finally {
      setLoader(false);
    }
  };

  const onGenerate = async () => {
    try {
      setLoader(true);
      const resp = await ApiClient.get(`${UrlHelpers.generateDocs}?appid=${appId}`, {
        headers: { 'Content-Type': 'application/json', userid: userID },
      });

      setShowGenerated(true);
      setInitGeneratedDocs(resp);
      console.log('resp-generated docs', resp);
    } catch (e) {
      console.log('err in get contract docs api', e);
      setApiErr(
        e?.response?.data?.Message ||
          e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later'
      );
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Loader open={loader} />
      <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
      {!showGenerated && (
        <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
          {preview && (
            <PdfViewer
              fileData={previewurl}
              handleClose={() => {
                setPreview(false);
              }}
              open={preview}
            />
          )}{' '}
          <Table
            aria-label="contract-documents-table"
            sx={{ border: '1px solid #F2F2F2', width: '70%' }}
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: '#F2F2F2' }}>
                {TableHeading?.map((x, index) => {
                  return (
                    <TableCell
                      key={x}
                      sx={{
                        fontWeight: 700,
                        fontSize: '14px !important',
                        width: index === 0 ? '50%' : 'auto',
                      }}
                    >
                      {x}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {docList
                .filter((doc) => doc?.visible)
                .map((eachDoc) => {
                  const { displayName, value, required, language } = eachDoc;

                  return (
                    <TableRow
                      key={value}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell sx={{ fontSize: '16px !important' }}>{displayName}</TableCell>
                      <TableCell sx={{ fontSize: '14px !important' }}>{required}</TableCell>

                      <TableCell sx={{ fontSize: '14px !important' }}>
                        <Link
                          color="secondary"
                          display="flex"
                          data-testid="deleteApplication"
                          onClick={() => onPreview(value, language)}
                          sx={{ cursor: 'pointer', textDecoration: 'none' }}
                        >
                          <img src={PreviewIcon} />
                          &nbsp;
                          <Typography variant="subtitle2" component="p">
                            Preview
                          </Typography>
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <Box display="flex" justifyContent="flex-end" width="70%" mt={2}>
            <Button onClick={onGenerate} color="secondary" variant="contained">
              Generate
            </Button>
          </Box>
        </Box>
      )}
      {showGenerated && (
        <ContractDocumentsSecond
          docMasterList={docList}
          appId={appId}
          initGeneratedDocs={initGeneratedDocs}
          getInfoReq={getInfoReq}
        />
      )}
    </>
  );
};

export default ContractDocuments;
