import { regexNumeric } from 'utils/utility';
import * as yup from 'yup';

export const CreatePromotionSchema = yup.object().shape({
  effectiveDate: yup
    .string()
    .nullable()
    .required('* Mandatory Field')
    .test(
      'len',
      'Effective date should be current or future date. It cannot be in the past',
      function (val) {
        return !val || new Date(val).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
      }
    ),
  expirationDate: yup
    .string()
    .nullable()
    .required('* Mandatory Field')
    .test('len', 'Expiration date cannot be in the past', function (val) {
      return !val || new Date(val).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
    })
    .when('effectiveDate', {
      is: (val) => !!val,
      then: yup
        .string()
        .nullable()

        .test('len', 'Date should be greater than start Date', function (val) {
          const { effectiveDate } = this?.parent;
          return !val || new Date(val) >= new Date(effectiveDate);
        })

        .test('len', 'Expiration date cannot be the same as Effective date', function (val) {
          const { effectiveDate } = this?.parent;
          return (
            !val ||
            new Date(val).setHours(0, 0, 0, 0) !== new Date(effectiveDate).setHours(0, 0, 0, 0)
          );
        }),
    }),
  promotionLabel: yup
    .string()
    .required('* Mandatory Field')
    .matches(/^[a-z0-9A-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\s,'!@$%&*()\-./"\\]*$/, 'Invalid format')
    .test('len', 'Maximum 20 characters can be entered', (val) => (val ? val?.length <= 20 : true)),
  promotionTitle: yup
    .string()
    .required('* Mandatory Field')
    .matches(/^[a-z0-9A-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\s,'!@$%&*()\-./"\\]*$/, 'Invalid format')
    .test('len', 'Maximum 20 characters can be entered', (val) => (val ? val?.length <= 20 : true)),
  promotionDescription: yup
    .string()
    .required('* Mandatory Field')
    .matches(/^[a-z0-9A-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\s,'!@$%&*()\-./"\\]*$/, 'Invalid format')
    .test('len', 'Maximum 50 characters can be entered', (val) => (val ? val?.length <= 50 : true)),
  gracePeriod: yup.string().required('* Mandatory Field').matches(regexNumeric, 'Invalid format'),
});
