import ApiClass from 'Api/ApiClient';
import axios from 'axios';
import { UrlHelpers } from 'urlHelpers/urlHelper';

const ApiClient = new ApiClass();

export const uploadDocumentManually = async (
  format,
  appId,
  applicantId,
  fileName,
  file,
  setApiErr,
  type = 'identity',
  setValue,
  valueName
) => {
  let response = null;
  const formData = new FormData();
  formData.append('file', file);

  try {
    response = await ApiClient.post(
      `${UrlHelpers.uploadManually}?format=${format}&applicationId=${appId}&applicantId=${applicantId}&name=${fileName}&type=${type} `,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    console.log('response', response);
  } catch (e) {
    setApiErr(
      e?.response?.data?.message ||
        e?.response?.data ||
        'We are unable to proceed due to a technical issue, please try again later.'
    );
    setValue(valueName, '');
    return response;
  }
  return response;
};

export const uploadXlDocumentManually = async (
  request,
  setLoader,
  setApiErr,
  setValue,
  valueName
) => {
  let response = null;
  try {
    response = await ApiClient.post(UrlHelpers.uploadXlmanually, request, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (e) {
    setLoader(false);
    setApiErr(
      e?.response?.data?.message ||
        e?.response?.data ||
        'We are unable to proceed due to a technical issue, please try again later.'
    );
    setValue(valueName, '');
    return response;
  }
  setLoader(false);
  return response;
};

export const uploadDocumentXL = async ({
  format,
  name,
  idType,
  size,
  fileMd5Base64,
  commonToken,
  setLoader,
  applicationId,
  setApiErr,
}) => {
  let response = null;
  const requestBody = {
    format,
    name,
    type: idType,
    size,
    source: 'Auto',
    eventType: 'UI',
    contentMD5: fileMd5Base64,
    applicationId,
  };
  try {
    response = await axios.post(UrlHelpers.commonComponentsOcrUploadXl, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${commonToken}`,
      },
    });
  } catch (e) {
    setApiErr(
      e?.response?.data?.message ||
        e?.response?.data ||
        'We are unable to proceed due to a technical issue, please try again later.'
    );
    setLoader(false);
    return response;
  }
  setLoader(false);
  return response;
};

export const uploadDocumentByPreSignedURL = async (
  preSignedUrl,
  file,
  fileMd5Base64,
  setLoader,
  setApiErr,
  setValue,
  valueName
) => {
  let response = null;
  try {
    response = await axios.put(preSignedUrl, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-MD5': fileMd5Base64,
      },
    });
  } catch (e) {
    setLoader(false);
    setApiErr(
      e?.response?.data?.message ||
        e?.response?.data ||
        'We are unable to proceed due to a technical issue, please try again later.'
    );
    setValue(valueName, '');
    return response;
  }
  setLoader(false);
  return response;
};

export const extractAddress = async (request, setLoader, token) => {
  setLoader(true);
  let response = null;
  try {
    response = await axios.post(UrlHelpers.commonComponentsExtractAddress, request, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    setLoader(false);

    return e;
  }
  setLoader(false);
  return response;
};
