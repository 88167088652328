import ApiClass from 'Api/ApiClient';
import { UrlHelpers } from 'urlHelpers/urlHelper';

export const LockReleaseApi = async ({ appid, fullName, userID }) => {
  const ApiClient = new ApiClass();
  try {
    let lockReleaseResp = await ApiClient.put(
      `${UrlHelpers?.lockRelease}/?appid=${appid}`,
      {},
      {
        headers: { 'Content-Type': 'application/json', userName: fullName, userid: userID },
      }
    );
    console.log({ lockReleaseResp });
    return lockReleaseResp;
  } catch (e) {
    console.log('error fetching', e);
  }
};
