import React, { useEffect } from 'react';

import { Requirement } from './Requirement';

export const Requirements = ({ value, requirements, onValidChange }) => {
  useEffect(() => {
    onValidChange(requirements.every((r) => r.validator(value)));
  }, [value, requirements, onValidChange]);

  return requirements.map((r) => (
    <Requirement key={r.text} value={value} requirement={r} onValidChange={onValidChange} />
  ));
};
