import { Alert, Box, Button, Paper, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import LeftArrow from 'assets/svg/leftArrow.svg';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { ternaryCondition } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';
import Loader from 'components/Loader/Loader';

import ResetPasswordModal from './ResetPassword/ResetPasswordModal';

const ViewUserProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userInformationData, setUserInformationData] = useState([]);
  const [fullRawData, setFullRawData] = useState(null);
  const [updateAlert, setUpdateAlert] = useState(true);
  const [userStatusConfigurationData, setUserStatusConfigurationData] = useState([]);
  const [, setUserProfileData] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [alertClose, setAlertClose] = useState(true);
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false);

  const ApiClient = new ApiClass();
  const getDealers = async () => {
    try {
      return await ApiClient.get(`${UrlHelpers.dealers}?status=1`);
    } catch (e) {
      console.log('e', e);
    }
  };
  console.log('fullRawData', fullRawData);

  const fetchUserData = async () => {
    try {
      console.log('/........llllllllll......................');
      console.log(userInformationData);
      // https://api-dt-{{env}}.orientalbank.com/ob-idmautoapi/authentication/retrieveUser?firstName=Akash

      // https://api-dt-{{env}}.orientalbank.com/ob-idmautoapi/authentication/retrieveUser?status=Enabled

      // https://api-dt-{{env}}.orientalbank.com/ob-idmautoapi/authentication/retrieveUser?lastName=Phadtare

      // https://api-dt-{{env}}.orientalbank.com/ob-idmautoapi/authentication/retrieveUser?userId=akash250
      console.log('location.state.................');
      console.log(location.state);
      return (
        await ApiClient.get(
          `${UrlHelpers.searchUserBy}?userId=${location.state.profileData.userId}`
        )
      )?.result[0].userList[0];
    } catch (e) {
      console.log('/........::::::::::::::::::::::::::::...');

      console.log('error', e);
    }
  };
  const getFilteredDealers = async (fullData) => {
    const fullDealers = await getDealers();
    const dealerIdList = fullData?.dealers || [];
    const filterDealers = [];
    for (const dealer of dealerIdList) {
      for (const obj of fullDealers) {
        if (obj.dealerId === dealer) {
          filterDealers.push(obj);
        }
      }
    }
    return filterDealers;
  };
  useEffect(async () => {
    // userId
    try {
      setLoader(true);
      const fullData = await fetchUserData();
      const phoneWithCountryCode = await ternaryCondition(
        fullData?.phone,
        `(${fullData.phone?.slice(2, 5)}) ` +
          fullData.phone?.slice(5, 8) +
          '-' +
          fullData.phone?.slice(8),
        '--'
      );
      const phoneWithoutCountryCode = await ternaryCondition(
        fullData?.phone,
        `(${fullData.phone?.slice(0, 3)}) ` +
          fullData.phone?.slice(3, 6) +
          '-' +
          fullData.phone?.slice(8),
        '--'
      );
      setFullRawData(fullData);
      setDealers(await getFilteredDealers(fullData));
      setUserInformationData([
        {
          left: 'User ID',
          right: fullData.userId,
        },
        {
          left: 'ID time zone',
          right: fullData.timeZone,
        },
        {
          left: 'User setup date',
          right: moment(fullData.createdDate).format('MM/DD/yyyy'),
        },
        {
          left: 'First name',
          right: fullData.firstName,
        },
        {
          left: 'Last name',
          right: fullData.lastName,
        },
        {
          left: 'Second last name',
          right: fullData.secondLastName,
        },
        {
          left: 'Phone',
          right:
            fullData.phone?.slice(0, 2) === '+1' ? phoneWithCountryCode : phoneWithoutCountryCode,
        },
        {
          left: 'Email',
          right: fullData.email,
        },
        {
          left: 'Include in sales manager?',
          right: fullData.includInSalesManager ? 'Yes' : 'No',
        },
        {
          left: 'Include in reference?',
          right: fullData.includeInReference ? 'Yes' : 'No',
        },
      ]);
      setUserStatusConfigurationData([
        {
          left: 'Active',
          right: fullData.active ? 'Yes' : 'No',
        },
        {
          left: 'Last inactivation date',
          right: fullData.lastInactivationDate
            ? moment(fullData.lastInactivationDate).format('MM/DD/yyyy, h:mm A')
            : '-',
        },
        {
          left: 'Last reactivation date',
          right: fullData.lastReActivationDate
            ? moment(fullData.lastReActivationDate).format('MM/DD/yyyy, h:mm A')
            : '-',
        },
        {
          left: 'Last login',
          right: fullData.lastLoginDate ? moment(fullData.lastLoginDate).format('MM/DD/yyyy') : '-',
        },
      ]);
      setUserProfileData([
        {
          left: 'Active',
          right: fullData.active ? 'Yes' : 'No',
        },
        {
          left: 'Active',
          right: fullData.active ? 'Yes' : 'No',
        },
      ]);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  }, [isPasswordResetSuccess]);
  const onEdit = (step) => {
    navigate('/register-user', {
      state: {
        step,
        data: fullRawData,
        isViewProfile: true,
        profileData: location.state.profileData,
        eachData: {
          1: {
            email: fullRawData.email,
            userId: fullRawData.userId,
            firstName: fullRawData.firstName || '',
            lastName: fullRawData.lastName || '',
            secondLastName: fullRawData.secondLastName,
            includeSalesManager: fullRawData.includInSalesManager ? 'Yes' : 'No',
            includeReference: fullRawData.includeInReference ? 'Yes' : 'No',
            userSetupDate: fullRawData.createdDate,
            mobile:
              fullRawData.phone?.slice(0, 2) === '+1'
                ? fullRawData.phone?.slice(2)
                : fullRawData.phone || '',
          },
          2: {
            isActive: fullRawData.active ? 'Yes' : 'No',
            maximizeTty: fullRawData.userCreditReport ? 'Yes' : 'No',
            ssn: fullRawData.ssn || '',
            dob: fullRawData.dob || '',
            vendorNumber: fullRawData?.vendorNumber,
            lastLoginDate: fullRawData.lastLoginDate || '',
            lastReActivationDate: fullRawData.lastReActivationDate || '',
            lastInactivationDate: fullRawData.lastInactivationDate || '',
          },
          3: {
            securityProfile: fullRawData.userSecurityProfile || '',
            dealerList: dealers,
          },
        },
        isFormFilled: { 1: true, 2: true, 3: true },
      },
    });
  };
  return (
    <>
      <ResetPasswordModal
        openResetDialog={openResetDialog}
        setOpenResetDialog={setOpenResetDialog}
        isPasswordResetSuccess={isPasswordResetSuccess}
        setIsPasswordResetSuccess={setIsPasswordResetSuccess}
        resetPwdDetails={{
          userName: fullRawData?.firstName,
          email: fullRawData?.email,
          userId: fullRawData?.userId,
        }}
      />
      {fullRawData && (
        <Box>
          <Box display="flex" alignItems="center" sx={{ gap: '5px' }}>
            <img
              src={LeftArrow}
              data-testid="left-arrow"
              onClick={() =>
                navigate('/manage-dealers', {
                  state: { tabValue: 1 },
                })
              }
            />
            <Typography variant="h3">View user profile</Typography>
          </Box>
          <Box sx={{ width: '70%', margin: 'auto', alignSelf: 'center' }}>
            <Paper className={classes.infoRequestPaper}>
              {updateAlert && (
                <Alert
                  variant="outlined"
                  severity="info"
                  onClose={() => {
                    setUpdateAlert(false);
                  }}
                >
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" gap="2px">
                      <Typography variant="subtitle2" fontWeight="700">
                        last updated date:
                      </Typography>
                      <Typography variant="subtitle2">
                        {fullRawData?.editedTimestamp
                          ? new Date(+fullRawData?.editedTimestamp)?.toLocaleDateString('en-US')
                          : '--'}
                      </Typography>
                    </Box>
                    <Box display="flex" gap="2px">
                      <Typography variant="subtitle2" fontWeight="700">
                        Last updated user:
                      </Typography>
                      <Typography variant="subtitle2">{fullRawData?.editedBy || '--'}</Typography>
                    </Box>
                    <Box display="flex" gap="2px" mt={2}>
                      <Typography variant="subtitle2" fontWeight="700">
                        Updated fields:
                      </Typography>
                      <Typography variant="subtitle2" sx={{ overflowWrap: 'anywhere' }}>
                        {fullRawData?.editedFields?.join(',') || '--'}
                      </Typography>
                    </Box>
                  </Box>
                </Alert>
              )}
              {location.state.updateStatus === 'success' && alertClose && (
                <Alert
                  severity={'success'}
                  onClose={() => {
                    setAlertClose(false);
                  }}
                  sx={{ margin: '20px 0' }}
                >
                  User profile has been updated successfully!
                </Alert>
              )}
              <Box sx={{ width: '70%', margin: 'auto' }}>
                <CustomCollapse
                  heading={'User information'}
                  open={true}
                  editable={true}
                  onEdit={() => onEdit(1)}
                >
                  <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25}>
                    {userInformationData.map((info) => (
                      <>
                        <Typography variant="subtitle2">{info.left}</Typography>
                        <Typography variant="subtitle2" className={classes.currencyValueNoCaps}>
                          {info.right}
                        </Typography>
                      </>
                    ))}
                  </Box>
                </CustomCollapse>
                <CustomCollapse
                  heading={'User status and configuration'}
                  open={true}
                  editable={true}
                  onEdit={() => onEdit(2)}
                >
                  {isPasswordResetSuccess && (
                    <Alert
                      variant="outlined"
                      severity="success"
                      onClose={() => {
                        setIsPasswordResetSuccess(false);
                      }}
                      className={classes.successAlert}
                    >
                      <Typography variant="subtitle2">Password changed successfully</Typography>
                    </Alert>
                  )}
                  <Typography variant="h6" fontWeight="700 !important" my="20px !important">
                    User status
                  </Typography>
                  <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25}>
                    <Typography variant="subtitle2">Status</Typography>
                    <Typography variant="subtitle2" className={classes.currencyValue}>
                      {fullRawData.active ? 'Active' : 'Inactive'}
                    </Typography>
                    <Box display="flex" flexDirection="column" justifyContent="center">
                      <Typography variant="subtitle2">Password</Typography>
                    </Box>

                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                      <Box display="flex" flexDirection="column" justifyContent="center">
                        <Typography variant="subtitle2" className={classes.currencyValue}>
                          {'●●●●●●●●'}
                        </Typography>
                      </Box>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => setOpenResetDialog(true)}
                        sx={{ maxHeight: '40px', fontSize: '13px', width: '200px' }}
                      >
                        {isPasswordResetSuccess ? 'Change password' : 'Reset password'}
                      </Button>
                    </Box>
                    {userStatusConfigurationData.map((info) => (
                      <>
                        <Typography variant="subtitle2">{info.left}</Typography>
                        <Typography variant="subtitle2" className={classes.currencyValue}>
                          {info.right}
                        </Typography>
                      </>
                    ))}
                  </Box>
                  <Typography variant="h6" fontWeight="700 !important" my="20px !important">
                    User configuration
                  </Typography>
                  <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25}>
                    <Typography variant="subtitle2">Maximize TTY?</Typography>
                    <Typography variant="subtitle2" className={classes.currencyValue}>
                      {fullRawData.userCreditReport ? 'Yes' : 'No'}
                    </Typography>
                  </Box>
                  <Typography variant="h6" fontWeight="700 !important" my="20px !important">
                    Miscellaneous fields
                  </Typography>
                  <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25}>
                    <Typography variant="subtitle2">Date of birth</Typography>
                    <Typography variant="subtitle2" className={classes.currencyValue}>
                      {fullRawData.dob ? moment(fullRawData.dob).format('MM/DD/yyyy') : '-'}
                    </Typography>
                    <Typography variant="subtitle2">SSN (LAST 4 DIGITS)</Typography>
                    <Typography variant="subtitle2" className={classes.currencyValue}>
                      {fullRawData.ssn || '-'}
                    </Typography>
                    <Typography variant="subtitle2">Vendor number</Typography>
                    <Typography variant="subtitle2" className={classes.currencyValue}>
                      {fullRawData.vendorNumber || '-'}
                    </Typography>
                  </Box>
                </CustomCollapse>
                <CustomCollapse
                  heading={'User profile'}
                  open={true}
                  editable={true}
                  onEdit={() => onEdit(3)}
                >
                  <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25}>
                    <Typography variant="subtitle2">Security profile</Typography>
                    <Typography variant="subtitle2" className={classes.currencyValue}>
                      {fullRawData.userSecurityProfile || '-'}
                    </Typography>
                    <Typography variant="subtitle2">User type</Typography>
                    <Typography variant="subtitle2" className={classes.currencyValue}>
                      {'Dealer Portal'}
                    </Typography>
                  </Box>
                  <Typography variant="h6" fontWeight="700 !important" my="20px !important">
                    Dealer information
                  </Typography>
                  <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25}>
                    <Typography variant="subtitle2">Dealer type</Typography>
                    <Typography variant="subtitle2" className={classes.currencyValue}>
                      {'Dealer'}
                    </Typography>
                    <Typography variant="subtitle2">Dealer</Typography>
                    <Typography variant="subtitle2" className={classes.currencyValue}>
                      {dealers?.length > 0 &&
                        dealers?.map?.((x) => (
                          <Box display="flex" key={x.dealerId}>
                            <Typography variant="subtitle2" fontWeight="700" color="#661C69">
                              {x.dealerId}
                            </Typography>
                            <span>:&nbsp;</span>
                            <Typography variant="subtitle2" fontWeight="700" color="#661C69">
                              {x.dealerName}
                            </Typography>
                          </Box>
                        ))}
                    </Typography>
                  </Box>
                </CustomCollapse>
              </Box>
            </Paper>
          </Box>
        </Box>
      )}
      {loader && <Loader open={loader} />}
    </>
  );
};

export default ViewUserProfile;
