import {
  Box,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import AuthContext from 'context/AuthContext';
import {
  TableCellTypography,
  TableHeaderTypography,
} from 'pages/ApplicationDashboard/ApplicationsTable';
import { SearchFilters } from 'pages/ApplicationDashboard/SearchFilters';
import TablePagination from 'pages/RegisterUser/TablePagination';
import { useContext, useEffect, useState } from 'react';
import classes from 'styles/globalStyle.module.scss';

import Loader from 'components/Loader/Loader';

import { dealershipUsersFetchAll, filterActiveDealershipuser } from './DealershipFetchAllApi';

const DealershipUsers = () => {
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [emptyDataMessage, setEmptyDataMessage] = useState('No application/s available');
  const [dealershipUsersData, setDealershipUsersData] = useState([]);
  const [searchOptions, setSearchOptions] = useState({
    searchOption: '',
    searchText: '',
  });
  const { userID } = useContext(AuthContext);
  const rowsPerPage = 10;
  const indexOfLastPost = page * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  useEffect(async () => {
    try {
      setLoader(true);
      let resp = await filterActiveDealershipuser({ userID });
      if (resp) {
        setDealershipUsersData(resp);
      }
    } catch (e) {
      console.log('err', e);
    } finally {
      setLoader(false);
    }
  }, []);
  console.log('loader status', loader);
  const paginate = (_, pageNumber) => {
    setPage(pageNumber);
  };
  const emptyValueHandle = (val) => {
    if (val) return val;
    else return '-';
  };
  const calcUserList = (userArr) => {
    return userArr?.slice(indexOfFirstPost, indexOfLastPost);
  };
  const DealerShipUserHeading = [
    'User ID',
    'First name',
    'Last name',
    'Second last name',
    'Active status',
  ];

  return (
    <>
      <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
        <Box display="flex" flexDirection="column" className={classes.dummyHome}>
          <Loader open={loader} />
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h3" my={2}>
              Dealership users
            </Typography>
          </Box>

          <SearchFilters
            searchOptions={searchOptions}
            setSearchOptions={setSearchOptions}
            applicationData={dealershipUsersData}
            setApplicationData={setDealershipUsersData}
            setLoader={setLoader}
            myAppFlag
            userID={userID}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            flow="dealershipUsers"
            setEmptyDataMessage={setEmptyDataMessage}
            fetchDealerShipUsers={dealershipUsersFetchAll}
          />

          <Grid
            container
            sx={{
              overflowX: 'auto',
              width: '100%',
              borderLeft: '1px solid #DADADA',
              borderRight: '1px solid #DADADA',
              borderBottom: '1px solid #DADADA',
            }}
          >
            <Paper sx={{ width: '100%', boxShadow: 'none' }}>
              <Table aria-label="applications-table">
                <TableHead>
                  <TableRow
                    sx={{ backgroundColor: '#F2F2F2' }}
                    className={classes.tableHeadingApplications}
                  >
                    {DealerShipUserHeading?.map((x) => {
                      return (
                        <TableCell key={x}>
                          <TableHeaderTypography>{x}</TableHeaderTypography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {calcUserList(dealershipUsersData)?.map((row) => (
                    <TableRow
                      key={row.applicationId}
                      className={classes.tableHeadingApplications}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>
                        <TableCellTypography>
                          <Link
                            component="button"
                            variant="subtitle2"
                            sx={{
                              color: '#0065CC',
                              textDecoration: 'initial',
                              textAlign: 'left',
                            }}
                          >
                            {emptyValueHandle(row?.username)}
                          </Link>
                        </TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>
                          {emptyValueHandle(row?.firstname)}
                        </TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>{emptyValueHandle(row?.lastname)}</TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>
                          {emptyValueHandle(row?.secondLastName)}
                        </TableCellTypography>
                      </TableCell>
                      <TableCell>
                        {console.log('----', emptyValueHandle(row?.active))}
                        <TableCellTypography>
                          {emptyValueHandle(row?.active) === true ? 'Yes' : 'No'}
                        </TableCellTypography>
                      </TableCell>
                    </TableRow>
                  ))}
                  {dealershipUsersData?.length === 0 && (
                    <TableRow>
                      <TableCell
                        colSpan={12}
                        sx={{ fontSize: '14px !important', textAlign: 'center' }}
                      >
                        {emptyDataMessage}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Box sx={{ margin: '15px' }}>
            <TablePagination
              postsPerPage={10}
              totalPosts={dealershipUsersData?.length}
              paginate={paginate}
              page={page}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
};
export default DealershipUsers;
