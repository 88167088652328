import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import { emailValidation, handleTokenExpiry, phoneValidation } from 'Services/commonComponentsApi';
import InvitationFlowImage from 'assets/svg/InvitationFlowImage.svg';
import { fetchAddressLookup } from 'commonComponents/commonComponents';
import AuthContext from 'context/AuthContext';
import InvitationFlowContext from 'context/InvitationFlowContext';
import debounceLodash from 'lodash.debounce';
import moment from 'moment';
import RelationWithOwner from 'pages/CreateApplication/InformationRequest/moduleUtilities/RelationWithOwner';
import { step1DropDownApis } from 'pages/CreateApplication/InformationRequest/moduleUtilities/dropdownApis';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InviInformationRequestSchema } from 'schemaValidations/InvitationFlowSchema';
import classes from 'styles/InviInformationRequest.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { currencyToNumber } from 'utils';
import { cityMapping, defaultFn, handleFocusError } from 'utils/utility';

import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

import {
  InvitationExpiryCheck,
  frameGetDetails,
  handleValidationResponse,
} from '../InviCommonUtilities';
import { inviFlowPayloads } from '../InviFlowPayloads';
import InviBasicInformation from './InviBasicInformation';
import InviMailingAddress from './InviMailingAddress';
import InviResidentStatus from './InviResidentStatus';
import InviResidentialAddress from './InviResidentialAddress';
import UploadContainer from './UploadContainer';

const InviInformationRequest = () => {
  const [isMailResidentSame, setIsMailResidentSame] = useState(true);
  const [loader, setLoader] = useState(false);
  const [verifyAlert, setVerifyAlert] = useState(false);

  const [jobId, setJobId] = useState('');
  const [addressLookupData, setAddressLookupData] = useState([]);
  const [addressLookupDataResponse, setAddressLookupDataResponse] = useState([]);
  const [mailAddressLookupData, setMailAddressLookupData] = useState([]);
  const [mailAddressLookupDataResponse, setMailAddressLookupDataResponse] = useState([]);
  const [showCamera, setShowCamera] = useState(false);
  const [timeAtResidenceError, setTimeAtResidenceError] = useState('');
  const [apiErr, setApiErr] = useState('');
  const [resCityList, setResCityList] = useState([]);
  const [perCityList, setPerCityList] = useState([]);
  const [oldResState, setOldResState] = useState('');
  const [oldPerState, setOldPerState] = useState('');
  const ApiClient = new ApiClass();
  const {
    cityList,
    setCityList,
    cityZipList,
    setCityZipList,
    residentStatusList,
    setResidentStatusList,
    maritalStatusList,
    setMaritalStatusList,
    relationshipList,
    setRelationShipList,
    utilityBillList,
    setUtilityBillList,

    commonToken,
    inviApplicationNum,
    inviApplicantId,
    inviUserType,
    accessToken,
    setCommonToken,
    userID,
    fullName,
    stateList,
    setStateList,
    stateCityList,
    setstateCityList,
  } = useContext(AuthContext);
  const {
    scannedData = {},
    vouchedReturnFlag,
    formFields,
    setCompletedSteps,
    setActiveStep,
    setIsInviExpired,
    setGetDetailsData,
    setFormFields,
    setVouchedReturnFlag,
    setInvitationExpiredHeading,
    setInvitationExpiredContent,
    isInvitationFlowMounted,
    isGetDetailsDone,
    getDetailsData,
  } = useContext(InvitationFlowContext);
  const inputRef = useRef(null);
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    setError,
    setFocus,
    register,
    clearErrors,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: 'all',
    defaultValues: {
      manualFile: '',
      relationship: '',
      firstName: '',
      middleName: '',
      lastName: '',
      secondLastName: '',
      gender: '',
      dob: '',
      mobile: '',
      mobile2: '',
      ssn: '',
      email: '',
      maritalStatus: '',
      isAddrSame: true,
      resAddress1: '',
      resAddress2: '',
      resCountry: 'USA',
      resState: '',
      resCity: '',
      resZipcode: '',
      resUtilityBill: '',
      utilitybillfile: '',
      ocrDocumentIdList: [],
      ocrDocumentId: '',
      ocrCameraFile: '',
      resProofCam: '',
      resProofFile: '',
      coApplicant: {},
      coSigner: [],
      perAddress1: '',
      perAddress2: '',
      perCountry: 'USA',
      perState: '',
      perCity: '',
      perZipcode: '',
      perUtilityBill: '',
      perProofCam: '',
      perProofFile: '',
      residentStatus: '',
      monthlyPayment: '',
      residentYears: '',
      residentMonths: '',
      idVerificationStatus: '',
      drivingLicenseId: '',
      licenseExpirationDate: '',
      licenseIssueState: '',
    },
    resolver: yupResolver(InviInformationRequestSchema(inviUserType)),
  });
  const isMobile = useMediaQuery('(max-width:899px)');
  const [isMounted, setIsMounted] = useState(false);

  const watchAll = watch();
  const [
    watchLastName,
    watchresAddress1,
    watchresAddress2,
    watchresState,
    watchresCity,
    watchresZipcode,
    watchEmail,
    watchmobile1,
    watchmobile2,
    watchMiddleName,
    watchSecondLastName,
    isAddrSame,
    watchIdVerificationStatus,
    // watchperState,
    watchperAddress1,
    watchperAddress2,
    watchperCity,
    watchperZipcode,
  ] = watch([
    'lastName',
    'resAddress1',
    'resAddress2',
    'resState',
    'resCity',
    'resZipcode',

    'email',
    'mobile',
    'mobile2',
    'middleName',
    'secondLastName',
    'isAddrSame',
    'idVerificationStatus',
    // 'perState',
    'perAddress1',
    'perAddress2',
    'perCity',
    'perZipcode',
  ]);

  console.log('watchSecondLastName', watchSecondLastName);
  const applyFormFields = async (formFieldss) => {
    Object.entries(formFieldss).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  useEffect(async () => {
    if (Object.entries(errors)?.length > 0) {
      handleFocusError({ errors, setFocus });
    }
    console.log({ wasd: errors });
  }, [errors]);

  const handleAddressFromVouched = async ({ barDataObj }) => {
    if (Object.keys(barDataObj).length > 0) {
      if (barDataObj?.streetAddress2?.length > 20) {
        setError('resAddress2', {
          type: 'custom',
          message: 'Address line 2 should be 20 characters or less',
        });
        if (getValues('isAddrSame')) {
          setError('perAddress2', {
            type: 'custom',
            message: 'Address line 2 should be 20 characters or less',
          });
        }
      }
    }
    setValue(`resAddress1`, barDataObj?.streetAddress);
    setValue(`resAddress2`, barDataObj.streetAddress2);
    let { city, postalCode, state } = barDataObj;
    const stateMapping = stateList?.find((x) => x.value === state)?.value;
    setValue(`resState`, stateMapping);
    if (stateMapping === 'PR') {
      let cityList = await cityMapping(stateCityList, 'PR');
      city = cityList?.find((x) => x.value?.toLowerCase() === city?.toLowerCase())?.value;
    }

    setValue(`resCity`, city);

    setValue(`resZipcode`, postalCode);

    if (isAddrSame) {
      setValue('perAddress1', barDataObj?.streetAddress);
      setValue('perAddress2', barDataObj.streetAddress2);
      setValue('perCity', city);
      setValue('perZipcode', postalCode);
      setValue('perState', stateMapping);
    }
  };

  const applyScannedData = async (scannedData1) => {
    let isValid = true;
    isValid =
      scannedData?.expireDate &&
      new Date(scannedData?.expireDate) >= new Date().setHours(0, 0, 0, 0);
    if (!isValid) {
      setValue('idVerificationStatus', 'expired');
    } else {
      const barDataObj = await validVouched({ scannedData1 });
      await handleAddressFromVouched({ barDataObj });
    }
    inputRef?.current?.focus();
  };

  const validVouched = async ({ scannedData1 }) => {
    setValue('idVerificationStatus', 'success');
    setVerifyAlert(true);
    const {
      firstName,
      middleName,
      lastName,
      gender,
      dob,
      birthDate,
      jobId: jobIdd,
      status,
      type,
      id,
      expireDate,
      state,
      country,
    } = scannedData1;
    let vouchedResponse = {};
    let barDataObj = {};
    if (jobIdd && status === 'completed') {
      let apiResp = null;
      apiResp = await ApiClient.get(`${process.env.REACT_APP_VOUCHED_MS_BARCODE}?jobId=${jobIdd}`);
      if (apiResp) {
        vouchedResponse = apiResp?.items[0];
        barDataObj = vouchedResponse?.result?.barcodeData;
      }
    }
    if (type === 'drivers-license') {
      setValue('drivingLicenseId', id);
      setValue('licenseExpirationDate', expireDate);
      setValue('licenseIssueState', country === 'PR' ? country : state);
    }
    setJobId(jobIdd);
    setValue('firstName', firstName);
    setValue('middleName', middleName);

    let splitLastName = lastName?.split(' ');
    if (splitLastName?.length > 1) {
      setValue('lastName', splitLastName[0]);
      setValue('secondLastName', splitLastName[1]);
    } else {
      setValue('lastName', lastName);
    }
    setValue('resUtilityBill', '');
    clearErrors('resUtilityBill');
    if (gender.gender === 'woman') setValue('gender', 'Female');
    else if (gender.gender === 'man') setValue('gender', 'Male');
    setValue('dob', dob || birthDate || barDataObj?.birthDate);
    clearErrors('utilitybillfile');
    return barDataObj;
  };
  const handleDataOnload = async (data) => {
    const parsedData = isGetDetailsDone ? getDetailsData?.applicationData : data?.applicationData;
    await frameGetDetails({
      getDetailsData: JSON.parse(parsedData || '{}'),
      setValue: setValue,
      inviUserType,
      inviApplicantId,
      pageName: 'InformationReq',
    });
  };

  useEffect(async () => {
    if (commonToken && accessToken && isInvitationFlowMounted) {
      await step1DropDownApis({
        cityList,
        setstateCityList,
        setStateList,
        setCityList,
        cityZipList,
        setCityZipList,
        residentStatusList,
        setResidentStatusList,
        maritalStatusList,
        setMaritalStatusList,
        relationshipList,
        setRelationShipList,
        utilityBillList,
        setUtilityBillList,
      });

      setIsMounted(true);
    }
  }, [commonToken, accessToken, isInvitationFlowMounted]);

  useEffect(async () => {
    if (isMounted) {
      if (Object.entries(formFields)?.length && vouchedReturnFlag) {
        await applyFormFields(formFields);

        if (scannedData && scannedData?.success) {
          applyScannedData(scannedData);
          setVerifyAlert(true);
        }
      } else {
        let data;
        if (!isGetDetailsDone) {
          data = await InvitationExpiryCheck({
            accessToken,
            setLoader,
            setIsInviExpired,
            setInvitationExpiredHeading,
            setInvitationExpiredContent,
            inviApplicationNum,
            inviApplicantId,
            userID,
            fullName,
          });
        }
        await handleDataOnload(data);
      }
    }
  }, [scannedData, formFields, accessToken, getDetailsData, isMounted]);

  useEffect(() => {
    if (watchLastName) {
      trigger('lastName');
    }
  }, [watchMiddleName, watchSecondLastName]);

  const failurecallback = (e) => {
    handleTokenExpiry(e, setCommonToken);
  };

  const handleInfoReqSubmit = async (data) => {
    let detailsData = await InvitationExpiryCheck({
      accessToken,
      setLoader,
      setIsInviExpired,
      setInvitationExpiredHeading,
      setInvitationExpiredContent,
      inviApplicationNum,
      userID,
      fullName,
      inviApplicantId,
    });
    let validFlag1 = await emailValidation({
      email: watchEmail,
      commonToken,
      setError,
      setLoader,
      failurecallback,
    });
    let validFlag2 = await phoneValidation({
      mobile1: watchmobile1,
      mobile2: watchmobile2,
      commonToken: commonToken,
      setError: setError,
      setLoader: setLoader,
      failurecallback,
      clearErrors,
    });
    const {
      firstName,
      lastName,
      middleName,
      secondLastName,
      gender,
      dob,
      mobile,
      mobile2,
      ssn,
      email,
      maritalStatus,
      relationship,
      resAddress1,
      resAddress2,
      resCountry,
      resState,
      resCity,
      resZipcode,
      resUtilityBill,
      utilitybillfile,
      ocrDocumentId,
      ocrDocumentIdList,
      perAddress1,
      perAddress2,
      perCountry,
      perState,
      perCity,
      perZipcode,
      residentStatus,
      monthlyPayment,
      residentYears,
      residentMonths,
      idVerificationStatus,
      drivingLicenseId,
      licenseExpirationDate,
      licenseIssueState,
    } = data;

    let applicantDetails = {
      applicantId: inviApplicantId,
      informationRequest: {
        participantInfo: {
          customerName: {
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
            secondLastName: secondLastName,
          },
          contacts: {
            mobile: mobile || '',
            alternatePhoneNumber: mobile2 || '',
            applicantEmail: email,
          },
        },
        participantDetailInfo: {
          gender: gender,
          dateOfBirth: moment(dob).format('MM/DD/YYYY'),
          maritalStatus: maritalStatus,
          socialSecurityNumber: ssn?.replaceAll('-', ''),
          addresses: [
            {
              addrType: 'Home address',
              lineOne: resAddress1,
              lineTwo: resAddress2,
              addrCity: resCity,
              addrState: resState,
              addrCountry: 'United States',
              addrCountryCode: resCountry,
              zipCode: resZipcode,
              utilityBill: resUtilityBill,
            },
            {
              addrType: 'Mailing address',
              lineOne: perAddress1,
              lineTwo: perAddress2,
              addrCity: perCity,
              addrState: perState,
              addrCountry: 'United States',
              addrCountryCode: perCountry,
              zipCode: perZipcode,
            },
          ],
          residentialStatus: residentStatus,
          timeOfResidenceYears: residentYears,
          timeOfResidenceMonths: residentMonths,
          monthlyPayment: currencyToNumber(monthlyPayment),
          relationshipWithOwner: relationship,
          vouchedIdStatus: idVerificationStatus === 'success',
          vouchedJobId: idVerificationStatus === 'success' ? jobId : '',
          ocrFlag: !!resUtilityBill,
          ocrDocumentIdList: ocrDocumentIdList,
          ocrDocumentId: resUtilityBill ? ocrDocumentId : '',
          ocrFileName: utilitybillfile?.name,
          drivingLicenseId,
          licenseExpirationDate,
          licenseIssueState,
        },
      },
    };

    const inviInfoReqPayload = await inviFlowPayloads({
      eventName: 'InformationRquestInvitation',
      applicationNum: inviApplicationNum,
      applicantDetails,
      inviUserType: inviUserType,
    });
    await callSaveApi({ validFlag1, validFlag2, inviInfoReqPayload, detailsData });
  };

  const callSaveApi = async ({ validFlag1, validFlag2, inviInfoReqPayload, detailsData }) => {
    if (validFlag1 && validFlag2) {
      try {
        setLoader(true);
        let dataa = await ApiClient.post(
          UrlHelpers.informationReqSave,

          { ...inviInfoReqPayload },
          {
            headers: { 'Content-Type': 'application/json', userid: 'guest', userName: 'guest' },
          }
        );

        if (dataa) {
          setCompletedSteps([1]);
          setActiveStep(2);
          setGetDetailsData({});
          setFormFields(watchAll);
        }
      } catch (e) {
        if (e.response.status === 400) {
          await handleValidationResponse({
            resp: e?.response?.data,
            details: JSON.parse(detailsData?.applicationData || '{}'),
            setApiErr,
            inviUserType,
            setError,
            user: 'inviFlow',
          });
        } else {
          setApiErr(
            e?.response?.data ||
              'We are unable to proceed due to a technical issue, please try again later'
          );
        }
      } finally {
        setLoader(false);
        setVouchedReturnFlag(false);
      }
    }
  };

  const debounceFunc5 = debounceLodash((address1, country, query5, commonTokenn, addressType) => {
    fetchAddressLookup({
      address1,
      country,
      query5,
      commonTokenn,
      addressType,
      setAddressLookupData,
      setAddressLookupDataResponse,
      setMailAddressLookupData,
      setMailAddressLookupDataResponse,
    });
  }, 500);

  const fetchAddress5 = useCallback(debounceFunc5, []);

  useEffect(() => {
    if (
      (dirtyFields?.resAddress1 ||
        dirtyFields?.resAddress2 ||
        dirtyFields?.resCity ||
        dirtyFields?.resZipcode) &&
      isMounted
    ) {
      setValue('showUtility', true);
    } else {
      setValue('showUtility', false);
    }
  }, [watchresAddress1, watchresAddress2, watchresCity, watchresZipcode]);

  useEffect(() => {
    if (dirtyFields?.resAddress1 && commonToken) {
      let query5 = watchresAddress1;

      if (watchresAddress2) {
        query5 += ` ${watchresAddress2} `;
      }
      if (watchresState) {
        query5 += ` ${watchresState} `;
      }
      if (watchresCity) {
        query5 += ` ${watchresCity} `;
      }
      if (watchresZipcode) {
        query5 += ` ${watchresZipcode} `;
      }
      fetchAddress5(watchresAddress1, 'USA', query5, commonToken, 'res');
    }
  }, [watchresAddress1]);
  useEffect(() => {
    if (watchperAddress1 && commonToken && !isAddrSame) {
      let query5 = watchperAddress1;

      if (watchperAddress2) {
        query5 += ` ${watchperAddress2} `;
      }
      query5 += ` PR `;
      if (watchperCity) {
        query5 += ` ${watchperCity} `;
      }
      if (watchperZipcode) {
        query5 += ` ${watchperZipcode} `;
      }
      fetchAddress5(watchperAddress1, 'USA', query5, commonToken, 'mail');
    }
  }, [watchperAddress1]);

  useEffect(() => {
    if (watchIdVerificationStatus === 'success') {
      setVerifyAlert(true);
    }
  }, [watchIdVerificationStatus]);

  const uploadStatusHandler = (data) => {
    return (
      <Typography variant="subtitle2" component="p">
        {data}
      </Typography>
    );
  };

  const vouchedContainer = () => {
    return (
      <>
        <Grid item lg={6} md={6} sm={12} sx={{ padding: '30px 0' }}>
          {watchIdVerificationStatus !== 'success' && (
            <UploadContainer
              label="Upload ID or passport"
              activeStep={1}
              formFields={watchAll}
              flow="inviFlow"
            />
          )}
          {verifyAlert && (
            <Alert
              severity={watchIdVerificationStatus == 'success' ? 'success' : 'error'}
              onClose={() => {
                setVerifyAlert(false);
              }}
              sx={{ margin: '20px 0' }}
            >
              {watchIdVerificationStatus == 'success' &&
                uploadStatusHandler('  ID verification done successfully!')}
              {watchIdVerificationStatus === 'expired' && uploadStatusHandler('* Expired ID')}
              {watchIdVerificationStatus !== 'success' &&
                watchIdVerificationStatus !== 'expired' &&
                uploadStatusHandler('The ID verification service is unavailable.')}
            </Alert>
          )}
        </Grid>
      </>
    );
  };

  return (
    <>
      <Loader open={loader} />
      <Grid container>
        <Grid item lg={8} md={8} sm={12}>
          <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={8} md={8} sm={12} className={classes.contentContainer}>
          <Typography variant="h3" component="h3" sx={{ padding: '30px 30px 0' }}>
            Basic information
          </Typography>
          <Grid container sx={{ padding: '0 25px 25px ' }} columnSpacing={5}>
            {isMobile && vouchedContainer()}
            <Grid item lg={6} md={6} sm={12}>
              {inviUserType !== 'primaryApplicant' && (
                <RelationWithOwner
                  control={control}
                  errors={errors}
                  userType={inviUserType}
                  inputRef={inputRef}
                  relationshipList={relationshipList}
                  flow="inviFlow"
                />
              )}
              <InviBasicInformation
                control={control}
                errors={errors}
                setValue={setValue}
                clearErrors={clearErrors}
                maritalStatusList={maritalStatusList}
                trigger={trigger}
                inviUserType={inviUserType}
              />
              <InviResidentialAddress
                control={control}
                errors={errors}
                inviUserType={inviUserType}
                isMailResidentSame={isMailResidentSame}
                setIsMailResidentSame={setIsMailResidentSame}
                addressLookupData={addressLookupData}
                addressLookupDataResponse={addressLookupDataResponse}
                setValue={setValue}
                cityList={resCityList}
                register={register}
                setError={setError}
                clearErrors={clearErrors}
                setLoader={setLoader}
                showCamera={showCamera}
                setShowCamera={setShowCamera}
                utilityBillList={utilityBillList}
                isMounted={isMounted}
                setApiErr={setApiErr}
                stateList={stateList}
                oldState={oldResState}
                setOldState={setOldResState}
                setOldPerState={setOldPerState}
                setResCityList={setResCityList}
                vouchedReturnFlag={vouchedReturnFlag}
              />
              {!isMailResidentSame && (
                <InviMailingAddress
                  control={control}
                  errors={errors}
                  cityList={perCityList}
                  addressLookupData={mailAddressLookupData}
                  addressLookupDataResponse={mailAddressLookupDataResponse}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  setError={setError}
                  setPerCityList={setPerCityList}
                  isMounted={isMounted}
                  oldState={oldPerState}
                  setOldState={setOldPerState}
                />
              )}
              <InviResidentStatus
                control={control}
                errors={errors}
                residentStatusList={residentStatusList}
                watch={watch}
                setValue={setValue}
                clearErrors={clearErrors}
                setTimeAtResidenceError={setTimeAtResidenceError}
                timeAtResidenceError={timeAtResidenceError}
                getValues={getValues}
              />
            </Grid>

            {!isMobile && vouchedContainer()}
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid item lg={4} md={4} sm={12}>
            <img src={InvitationFlowImage} style={{ width: '100%' }} />
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item lg={8} md={8} sm={12} xs={12} className={classes.footerIcons}>
          <Button
            color="secondary"
            variant="contained"
            className={classes.buttonStyle}
            onClick={handleSubmit(handleInfoReqSubmit)}
            // disabled={Object.entries(errors)?.length || timeAtResidenceError}
            data-testid="continue-btn"
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default InviInformationRequest;

InviInformationRequest.propTypes = {
  invitationExpiryCheck: PropTypes.func,
};
InviInformationRequest.defaultProps = {
  invitationExpiryCheck: defaultFn,
};
