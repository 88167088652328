/* eslint-disable react/prop-types */
import { Box, FormHelperText, Grid, InputLabel, Typography } from '@mui/material';
import { useEffect } from 'react';
import globalClasses from 'styles/globalStyle.module.scss';

import CustomCollapse from 'components/CustomCollapse';
import CustomNumberFormat from 'components/CustomNumberFormat/CustomNumberFormat';
import CustomRadioButton from 'components/CustomRadioButton/CustomRadioButton';

const InviResidentStatus = ({
  control,
  errors,
  residentStatusList,
  setValue,
  watch,
  clearErrors,
  timeAtResidenceError,
  setTimeAtResidenceError,
}) => {
  const [watchResidentStatus, watchResidentYear, watchResidentMonth, watchdob] = watch([
    'residentStatus',
    'residentYears',
    'residentMonths',
    'dob',
  ]);

  useEffect(() => {
    if (
      watchResidentStatus &&
      watchResidentStatus !== 'Rent' &&
      watchResidentStatus !== 'Homeowner'
    ) {
      console.log('watchResidentStatus', watchResidentStatus);
      setValue('monthlyPayment', '');
      clearErrors('monthlyPayment');
    }
  }, [watchResidentStatus]);
  useEffect(() => {
    const dateofBirth = watchdob;
    if (dateofBirth) {
      let a = new Date().getFullYear() * 12 + new Date().getMonth();
      let b = new Date(dateofBirth).getFullYear() * 12 + new Date(dateofBirth).getMonth();
      let c = a - b;
      let ageYears = c / 12;
      let ageMonth = c % 12;
      if (
        parseInt(watchResidentYear) > parseInt(ageYears) ||
        (parseInt(watchResidentYear) === parseInt(ageYears) &&
          parseInt(watchResidentMonth) > parseInt(ageMonth))
      ) {
        setTimeAtResidenceError(
          'Time at residence cannot be greater than the age of the applicant'
        );
      } else {
        setTimeAtResidenceError('');
      }
    }
  }, [watchResidentYear, watchResidentMonth, watchdob]);

  return (
    <CustomCollapse heading="Resident status" open={true} errors={errors}>
      <Grid container rowSpacing={1} columnSpacing={1} alignItems="flex-start">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomRadioButton
            control={control}
            radioValues={residentStatusList}
            label="Resident status"
            required
            name="residentStatus"
            errors={!!errors?.residentStatus}
            errorText={errors?.residentStatus?.message}
          />
        </Grid>
        {(watchResidentStatus === 'Rent' || watchResidentStatus === 'Homeowner') && (
          <Grid item lg={12} md={12} sm={12} xs={12} mr={1}>
            <CustomNumberFormat
              name="monthlyPayment"
              id="monthlyPayment"
              dataTestId="monthlyPayment"
              label="Monthly payment"
              decimalScale={2}
              control={control}
              error={!!errors?.monthlyPayment}
              errorText={errors?.monthlyPayment?.message}
              thousandSeparator={true}
              prefix={'$ '}
              required={watchResidentStatus === 'Rent' || watchResidentStatus === 'Homeowner'}
            />
          </Grid>
        )}
        <Grid item lg={12} md={12} sm={12} xs={12} container alignItems="flex-start">
          <InputLabel htmlFor="time_at_residence">
            <span style={{ color: 'red' }}>*&nbsp;</span>Time at residence
          </InputLabel>
          <Grid item lg={12} md={12} sm={12} xs={12} container spacing={1} alignItems="flex-start">
            <Grid
              item
              lg={5.5}
              md={5.5}
              sm={3}
              xs={4}
              display="flex"
              alignItems="center"
              sx={{
                gap: '16px',
              }}
            >
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center" spacing={1}>
                  <CustomNumberFormat
                    name="residentYears"
                    id="residentYears"
                    dataTestId="residentYears"
                    placeholder="0"
                    control={control}
                    error={!!errors?.residentYears}
                    maxLength={2}
                  />

                  <Typography variant="subtitle2" component="p" px={0.5}>
                    Years
                  </Typography>
                </Box>
                {!!errors?.residentYears && (
                  <FormHelperText gap={2} className={globalClasses.customSelectError}>
                    {' '}
                    {errors?.residentYears?.message}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={3}
              xs={4}
              display="flex"
              alignItems="center"
              sx={{
                gap: '16px',
              }}
            >
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center">
                  <CustomNumberFormat
                    name="residentMonths"
                    id="residentMonths"
                    dataTestId="residentMonths"
                    placeholder="0"
                    control={control}
                    error={!!errors?.residentMonths}
                    maxLength={2}
                  />
                  <Typography variant="subtitle2" component="p" px={0.5}>
                    Months
                  </Typography>
                </Box>
                {!!errors?.residentMonths && (
                  <FormHelperText gap={2} className={globalClasses.customSelectError}>
                    {' '}
                    {errors?.residentMonths?.message}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
          </Grid>
          <InputLabel>
            <span style={{ color: '#d32f2f' }}>{timeAtResidenceError}</span>
          </InputLabel>
        </Grid>
      </Grid>
    </CustomCollapse>
  );
};
export default InviResidentStatus;
