import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, Link, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DraftLanding from 'assets/svg/draftLanding.svg';
import PropTypes from 'prop-types';
import { ReverseStepMapping, defaultFn } from 'utils/utility';

const CheckLandingPopup = ({
  landingPopup,
  setLandingPopup,
  handleNavigate,
  handleFromStart,
  landingStep,
}) => {
  const handleClose = () => {
    setLandingPopup(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={landingPopup}
      PaperProps={{ sx: { padding: '20px', gap: '20px' } }}
    >
      <Box pb={1.5}>
        <IconButton
          aria-label="close"
          data-testid="closebtn"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ padding: '0' }}>
        <Box display="flex" gap={2.5} flexDirection="column" alignItems="center">
          <img src={DraftLanding} width="261px" height="170px" />
          <Typography variant="h3" color="#000000" fontWeight="700">
            Welcome Back
          </Typography>
          <Typography variant="subtitle2" color="#000000">
            {`You were working on the ${ReverseStepMapping[landingStep]}. Lets keep going!`}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ display: 'flex', gap: '20px', justifyContent: 'center', flexDirection: 'column' }}
      >
        <Button variant="contained" color="secondary" onClick={handleNavigate} id="resume">
          Pick up where you left off
        </Button>
        <Link
          component="button"
          variant="subtitle2"
          color="secondary"
          sx={{ fontSize: '16px' }}
          onClick={() => handleFromStart(landingStep)}
          id="fresh-start"
        >
          No, start from beginning
        </Link>
      </DialogActions>
    </Dialog>
  );
};

export default CheckLandingPopup;

CheckLandingPopup.propTypes = {
  setLandingPopup: PropTypes.func,
  landingPopup: PropTypes.bool,
  handleNavigate: PropTypes.func,
  handleFromStart: PropTypes.func,
  landingStep: PropTypes.number,
};
CheckLandingPopup.defaultProps = {
  setLandingPopup: defaultFn,
  landingPopup: false,
  handleNavigate: defaultFn,
  handleFromStart: defaultFn,
  landingStep: 1,
};
