import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import styledclass from 'styles/globalStyle.module.scss';
import { numberToCurrency } from 'utils/utility';

const PaymentCalculatorDetails = ({
  calculatedData,
  reviewScreen,
  promotionsData,
  approvedApplication,
}) => {
  console.log({ calculatedData, reviewScreen, promotionsData });

  return (
    <>
      <Box>
        {!approvedApplication && (
          <Typography
            variant="h6"
            sx={{
              fontWeight: 700,
              padding: reviewScreen ? '30px 0px 10px' : '30px 10px 10px',
            }}
          >
            Payment calculator details
          </Typography>
        )}
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Program</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.programVal || calculatedData?.formData?.program}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Promotion</TableCell>
                <TableCell
                  classes={{ root: styledclass.currencyValue }}
                  sx={{ textTransform: promotionsData?.promotionLabel && 'none !important' }}
                >
                  {promotionsData?.promotionLabel || '--'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>New / used</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.newOrUsed === 'True' ? 'New' : 'Used'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Vehicle class
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.vehicleClass}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Term</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.term}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Rate</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.rate} %
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Contract date
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {moment(calculatedData?.formData?.contractDate).format('MM/DD/yyyy') ===
                  'Invalid date'
                    ? calculatedData?.formData?.contractDate
                    : moment(calculatedData?.formData?.contractDate).format('MM/DD/yyyy')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Sale price</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.salePrice)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography
            variant="h6"
            component="h3"
            sx={{ fontWeight: '700', padding: reviewScreen ? '20px 0 10px' : '20px 10px' }}
          >
            Itemization of amount financed
          </Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Trade in debt
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.tradeDept)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Cash down</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.cashDown)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Trade in allowance
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.tradeAllowance)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Rebate</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.rebate)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Net trade-in</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.calculatedData?.netTradeIn)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Car care</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.carCare)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Extended warranty
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.extendedWarranty) || 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Service contract
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.serviceContract) || 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Paint protection
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.paintProtection)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Other</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.other)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Trade-in vehicle year
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.year || '--'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Trade-in vehicle make
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.make || '--'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Trade-in vehicle model
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.model || '--'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Balance</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.calculatedData?.balance
                    ? numberToCurrency(calculatedData?.calculatedData?.balance)
                    : numberToCurrency(calculatedData?.formData?.balance)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Typography
            variant="h6"
            component="h3"
            sx={{ fontWeight: '700', padding: reviewScreen ? '20px 0px 10px' : '20px 10px' }}
          >
            Insurance
          </Typography>

          <Table>
            <TableBody>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Credit life</TableCell>
                <TableCell
                  classes={{ root: styledclass.currencyValue }}
                  sx={{ textTransform: 'capitalize !important' }}
                >
                  {calculatedData?.formData?.creditLife}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Credit life type
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.creditLifeType || '--'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Vehicle insurance type
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.vehicleInsuranceType || '--'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Credit life insurance amount
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.creditLifeAmount)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Vehicle insurance amount
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.vehicleInsuranceAmount)}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Gap fee</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.gapFee)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Typography
            variant="h6"
            component="h3"
            sx={{ fontWeight: '700', padding: reviewScreen ? '20px 0px 10px' : '20px 10px' }}
          >
            Fees
          </Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Registration fees
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.calculatedData?.registrationFees)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography
            variant="h6"
            component="h3"
            sx={{ fontWeight: '700', padding: reviewScreen ? '20px 0px 10px' : '20px 10px' }}
          >
            Deferred payment
          </Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Months deferred
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.monthDeferred || 0}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default PaymentCalculatorDetails;

PaymentCalculatorDetails.propTypes = {
  calculatedData: PropTypes.instanceOf(Object),
  promotionsData: PropTypes.array,
  reviewScreen: PropTypes.bool,
  approvedApplication: PropTypes.bool,
};
PaymentCalculatorDetails.defaultProps = {
  calculatedData: {},
  reviewScreen: false,
  approvedApplication: false,
  promotionsData: [],
};
