import { yupResolver } from '@hookform/resolvers/yup';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import {
  makeLists,
  modelLists,
  subModelLists,
  yearLists,
} from 'pages/Lookup/DistributorDataResult/FilterDetailsService';
import LookupDialog from 'pages/Lookup/LookupDialog/LookupDialog';
import LookupInterDialog from 'pages/Lookup/LookupInterDialog/LookupInterDialog';
import LookupSecondDialog from 'pages/Lookup/LookupSecondDialog/LookupSecondDialog';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { lookupSchema } from 'schemaValidations/lookupSchema';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import Loader from 'components/Loader/Loader';

const LookupCommon = ({
  coApplicant,
  coSigner,
  reviewFlag,
  calculationState,
  completedSteps,
  viewAppFlow,
}) => {
  const navigate = useNavigate();
  const {
    showMainPopup,
    showInterPopup,
    showSecondaryPopup,
    setShowSecondaryPopup,
    // setStateList,
    setLookupStateList,
    yearList,
    setYearList,
    makeList,
    setMakeList,
    modelList,
    setModelList,
    subModelList,
    setSubModelList,
    setStyleList,
    setVinDetail,
    distributorData,
    setDistributorResult,
    newVehicleDetails,
    setNewVehicleDetails,
    usedVehicleDetails,
    setUsedVehicleDetails,
    newDistributorDetails,
    setNewDistributorDetails,
    setShowSummary,
    setPageData,
    setShowPage,
    program,
    createAppFlag,
    setVehicleYear,
    setVehicleType,
    setLookupLinkClicked,
  } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);

  const {
    control,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
    clearErrors,
    handleSubmit,
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      collateralType: '',
      valueGuide: '',
      make: '',
      year: '',
      model: '',
      style: '',
      subModel: '',
      lookuptype: '',
      mileage: '',
      vin: '',
      state: 'PR',
    },
    resolver: yupResolver(lookupSchema(program, createAppFlag)),
  });

  const [
    watchCollateralType,
    watchValueGuide,
    watchlookupType,
    watchyear,
    watchmake,
    watchModel,
    watchSubModel,
    watchStyle,
    watchState,
    watchMileage,
  ] = watch([
    'collateralType',
    'valueGuide',
    'lookuptype',
    'year',
    'make',
    'model',
    'subModel',
    'style',
    'state',
    'mileage',
  ]);
  const ApiClient = new ApiClass();
  useEffect(async () => {
    try {
      setLoader(true);
      const resp = await ApiClient.get(UrlHelpers.lookUpState);

      const statesData = resp.states?.state_list?.map((x) => {
        return { name: x?.name, value: x?.abbreviation };
      });
      setLookupStateList(statesData);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  }, []);
  const newBlackbookFn = async () => {
    if (newVehicleDetails.length === 0) {
      try {
        setLoader(true);
        const resp = await ApiClient.post(
          UrlHelpers.lookUpDropDownInfoNew,
          { vehicleclass: 'All' },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        const respData = resp.drilldown?.class_list[0]?.year_list;

        const firsttwo = respData.slice(0, 3);
        setNewVehicleDetails(firsttwo);
        setYearList(firsttwo);
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoader(false);
      }
    } else {
      setYearList(newVehicleDetails);
    }
  };
  const usedBlackBookFn = async () => {
    if (usedVehicleDetails.length === 0) {
      try {
        setLoader(true);
        const resp = await ApiClient.post(
          UrlHelpers.lookUpDropDownInfo,
          { vehicleclass: 'All' },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        const respData = resp.drilldown?.class_list[0]?.year_list;
        setUsedVehicleDetails(respData);

        setYearList(respData);
      } catch (e) {
        console.log('dropdown api error', e);
      } finally {
        setLoader(false);
      }
    } else {
      setYearList(usedVehicleDetails);
    }
  };

  const newDistributorDataFn = async () => {
    if (newDistributorDetails.length === 0) {
      try {
        const response = await yearLists(setLoader);
        const yearsList =
          response?.map?.((x) => {
            const { year } = x;
            return { name: year, value: year };
          }) || [];
        setYearList(yearsList);
        setNewDistributorDetails(yearsList);
      } catch (e) {
        console.log('dropdown api error', e);
      }
    } else {
      setYearList(newDistributorDetails);
    }
  };

  useEffect(async () => {
    if (watchlookupType === 'manual' || watchCollateralType === 'new') {
      setValue('make', '');
      setValue('model', '');
      setValue('subModel', '');
      setValue('style', '');
    }
    if (watchValueGuide === 'Distributor Data' && watchyear) {
      let response = await makeLists(setLoader, watchyear);
      const makeListOpt =
        response?.map?.((x) => {
          const { make } = x;
          return { name: make, value: make };
        }) || [];
      setMakeList(makeListOpt);
      setValue('make', makeListOpt?.length === 1 ? makeListOpt[0]?.value : '');
    } else if (
      watchValueGuide === 'Blackbook' &&
      (watchlookupType === 'manual' || watchCollateralType === 'new')
    ) {
      const opt = yearList?.find((x) => x?.name === watchyear)?.make_list || [];
      setValue('make', opt?.length === 1 ? opt[0]?.value : '');
      setMakeList(opt);
    }
  }, [watchyear]);

  useEffect(async () => {
    if (watchlookupType === 'manual' || watchCollateralType === 'new') {
      setValue('model', '');
      setValue('subModel', '');
      setValue('style', '');
    }
    if (watchValueGuide === 'Distributor Data' && watchyear && watchmake) {
      let response = await modelLists(setLoader, watchyear, watchmake);
      const modelListOpt =
        response?.map?.((x) => {
          const { model } = x;
          return { name: model, value: model };
        }) || [];
      setModelList(modelListOpt);
      setValue('model', modelListOpt?.length === 1 ? modelListOpt[0]?.name : '');
    } else if (
      watchValueGuide === 'Blackbook' &&
      (watchlookupType === 'manual' || watchCollateralType === 'new')
    ) {
      await validateMakeAndGetModel();
    }
  }, [makeList, watchmake]);

  const validateMakeAndGetModel = async () => {
    if (watchmake) {
      const modelResponse = await getModelList();
      if (modelResponse) {
        setModelList(modelResponse);
        setValue('model', modelResponse?.length === 1 ? modelResponse[0]?.name : '');
      }
    }
  };
  const getModelList = async () => {
    const serviceUrl =
      watchCollateralType === 'new'
        ? UrlHelpers.lookUpDropDownInfoNew
        : UrlHelpers.lookUpDropDownInfo;
    try {
      setLoader(true);
      const resp = await ApiClient.post(
        serviceUrl,
        { vehicleclass: 'All', year: watchyear, make: watchmake },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      return resp.drilldown?.class_list[0]?.year_list[0].make_list[0].model_list;
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  };
  useEffect(async () => {
    if (watchlookupType === 'manual' || watchCollateralType === 'new') {
      setValue('subModel', '');
      setValue('style', '');
    }
    if (watchValueGuide === 'Distributor Data' && watchyear && watchmake && watchModel) {
      let response = await subModelLists(setLoader, watchyear, watchmake, watchModel);
      const subModelListOpt =
        response?.map?.((x) => {
          const { subModel } = x;
          return { name: subModel, value: subModel };
        }) || [];
      setDistributorResult(response);
      setSubModelList(subModelListOpt);
      setValue('subModel', subModelListOpt?.length === 1 ? subModelListOpt[0]?.name : '');
      trigger('subModel');
    } else if (
      watchValueGuide === 'Blackbook' &&
      (watchlookupType === 'manual' || watchCollateralType === 'new')
    ) {
      const opt = modelList.find((x) => x.name === watchModel)?.series_list || [];
      setValue('subModel', opt?.length === 1 ? opt[0]?.name : '');
      setSubModelList(opt);
    }
  }, [modelList, watchModel]);

  useEffect(() => {
    if (watchlookupType === 'manual' || watchCollateralType === 'new') {
      setValue('style', '');
    }
    if (
      watchValueGuide === 'Blackbook' &&
      (watchlookupType === 'manual' || watchCollateralType === 'new')
    ) {
      let opt = subModelList.find((x) => x.name === watchSubModel)?.style_list || [];
      setStyleList(opt);

      setValue('style', opt?.length === 1 ? opt[0]?.name : '');
      if (opt?.length === 1) {
        trigger('style');
      }
    }
  }, [subModelList, watchSubModel]);
  console.log('----errors----', { errors, isValid });
  useEffect(async () => {
    if (watchCollateralType === 'new') {
      setValue('lookuptype', 'manual');
      if (watchValueGuide === 'Blackbook') {
        await newBlackbookFn();
      } else if (watchValueGuide === 'Distributor Data') {
        await newDistributorDataFn();
      }
    } else if (watchCollateralType === 'used') {
      setValue('lookuptype', '');
      if (watchValueGuide === 'Distributor Data') {
        setValue('valueGuide', '');
      }
      await usedBlackBookFn();
    }
  }, [watchCollateralType, watchValueGuide]);

  useEffect(() => {
    console.log('cleared');
    setValue('year', '');
    setValue('make', '');
    setValue('model', '');
    setValue('subModel', '');
    setValue('style', '');
    setValue('mileage', '');
    setValue('state', 'PR');
    setValue('vin', '');
    setVinDetail([]);
  }, [watchlookupType, watchValueGuide, watchCollateralType]);

  const getColorsAndOptions = async (uvc = '', uvc_tie_breaker = '') => {
    try {
      const res = await ApiClient.post(
        UrlHelpers.getColorsAndOptions,
        {
          uvc,
          uvc_tie_breaker,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      const options = JSON.parse(res?.option || '{}')?.options?.option_list || [];

      return { options };
    } catch (e) {
      console.log('e', e);
    }
  };

  const getEquipmentAdjustment = async (getType) => {
    try {
      setLoader(true);

      const serviceUrl =
        getType === 'used' ? UrlHelpers.lookUpDrillDownInfo : UrlHelpers.lookUpDrillDownInfoNew;
      const resp = await ApiClient.post(
        serviceUrl,
        {
          year: watchyear,
          make: watchmake,
          model: watchModel,
          series: watchSubModel,
          style: watchStyle,
          state: watchState,
          mileage: (+watchMileage)?.toString(),
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      const productDetails = {
        year: watchyear,
        make: watchmake,
        model: watchModel,
        series: watchSubModel,
        style: watchStyle,
        valueGuide: watchValueGuide,
        state: watchState,
        mileage: watchMileage,
        collateralType: getType,
      };
      let data = [];
      if (getType === 'used') {
        data = resp?.used_vehicles?.used_vehicle_list?.[0]?.add_deduct_list || [];
        setShowSummary(false);
        setPageData({ data, productDetails });
        setShowPage('equipmentAdjustment');
      } else {
        const {
          uvc,
          uvc_tie_breaker = '',
          msrp,
          invoice,
          add_deduct_list = [],
          vin,
          cylinders,
          doors,
        } = resp?.new_vehicles?.new_vehicle_list?.[0];
        const { options = [] } = await getColorsAndOptions(uvc, uvc_tie_breaker);
        const newObj = { ...productDetails, vin, cylinders, doors };
        setShowSummary(false);
        setPageData({
          options,
          addDeductList: add_deduct_list,

          productDetails: newObj,
          msrp,
          invoice,
          uvc,
          uvcTieBreaker: uvc_tie_breaker,
        });
        setShowPage('equipmentAdjustmentNew');
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  };
  const onNext = async () => {
    setShowSecondaryPopup(false);
    setLookupLinkClicked(false);

    if (watchValueGuide === 'Distributor Data' && watchCollateralType === 'new') {
      const data = distributorData.find((x) => x.subModel === watchSubModel);
      data.mileage = watchMileage;
      setPageData({ data });
      setShowPage('distributorDataResult');
    } else if (watchCollateralType === 'new' && watchValueGuide === 'Blackbook') {
      getEquipmentAdjustment('new');
    } else {
      getEquipmentAdjustment('used');
    }
    setVehicleType(watchCollateralType);
    setVehicleYear(watchyear);
    setValue('valueGuide', '');
    setValue('collateralType', '');
    setValue('year', '');
    setValue('make', '');
    setValue('model', '');
    setValue('subModel', '');
    setValue('style', '');
    setValue('mileage', 0);
    setValue('state', 'PR');
    setValue('vin', '');
    setVinDetail([]);
    navigate('/lookup', {
      state: {
        coApplicant: coApplicant,
        coSigner: coSigner,
        reviewFlag,
        calculationState,
        completedSteps,
        viewAppFlow,
      },
    });
  };
  return (
    <>
      {loader && <Loader open={loader} />}
      {showMainPopup && (
        <LookupDialog control={control} setValue={setValue} reset={reset} loader={loader} />
      )}
      {showSecondaryPopup && (
        <LookupSecondDialog
          control={control}
          setValue={setValue}
          errors={errors}
          isValid={isValid}
          handleSubmit={handleSubmit}
          onNext={onNext}
          reset={reset}
          clearErrors={clearErrors}
          loader={loader}
        />
      )}
      {showInterPopup && (
        <LookupInterDialog
          control={control}
          setValue={setValue}
          errors={errors}
          isValid={isValid}
          handleSubmit={handleSubmit}
          onNext={onNext}
          reset={reset}
          clearErrors={clearErrors}
          trigger={trigger}
          loader={loader}
        />
      )}
    </>
  );
};

export default LookupCommon;

LookupCommon.propTypes = {
  coApplicant: PropTypes.instanceOf(Object),
  coSigner: PropTypes.instanceOf(Object),
  reviewFlag: false,
  calculationState: PropTypes.string,
  completedSteps: PropTypes.array,
  viewAppFlow: PropTypes.bool,
};
LookupCommon.defaultProps = {
  coApplicant: {},
  coSigner: [],
  reviewFlag: false,
  calculationState: '',
  completedSteps: [],
  viewAppFlow: false,
};
