import { Box, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import classes from 'styles/globalStyle.module.scss';
import { defaultFn, numberToCurrency } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';

import {
  empStatusListApi,
  industryListApi,
} from '../InformationRequest/moduleUtilities/dropdownApis';

const EmploymentInformationSection = ({
  employmentInformation,
  label,
  viewAppFlow,
  setOpenPopup,
  setLandingUser,
  setLandingStep,
}) => {
  const { empStatusList, industryList, setIndustryList, setEmpStatusList } =
    useContext(AuthContext);
  useEffect(async () => {
    await empStatusListApi({ empStatusList, setEmpStatusList });
    await industryListApi({ industryList, setIndustryList });
  }, []);
  const { setReviewFlag, setActiveStep, setUserType } = useContext(MainApplicationContext);

  const empStatusMapping =
    empStatusList.find(
      (x) => x.value === employmentInformation?.employmentInformationDetail?.employmentStatus
    )?.name || '--';

  const industryMapping =
    industryList.find(
      (x) => x.value === employmentInformation?.employmentInformationDetail?.industry
    )?.name || '--';
  const workPhone =
    `(${employmentInformation?.employmentInformationDetail?.workPhone?.slice(0, 3)}) ` +
    `${employmentInformation?.employmentInformationDetail?.workPhone?.slice(3, 6)}` +
    '-' +
    `${employmentInformation?.employmentInformationDetail?.workPhone?.slice(6)}`;
  const additionalWorkPhone =
    `(${employmentInformation?.additionalIncomeDetail?.addlWorkPhone?.slice(0, 3)}) ` +
    `${employmentInformation?.additionalIncomeDetail?.addlWorkPhone?.slice(3, 6)}` +
    '-' +
    `${employmentInformation?.additionalIncomeDetail?.addlWorkPhone?.slice(6)}`;
  const onEdit = () => {
    let landingUserType = '';
    if (label.indexOf('Applicant') > -1) {
      landingUserType = 'Applicant';
    } else if (label.indexOf('Co-applicant') > -1) {
      landingUserType = 'Co-applicant';
    } else if (label.indexOf('Co-signer 1') > -1) {
      landingUserType = 'Co-signer 1';
    } else if (label.indexOf('Co-signer 2') > -1) {
      landingUserType = 'Co-signer 2';
    }

    if (viewAppFlow) {
      setOpenPopup(true);
      setLandingUser(landingUserType);
      setLandingStep(2);
    } else {
      setUserType(landingUserType);
      setReviewFlag(true);
      setActiveStep(2);
    }
  };
  return (
    <CustomCollapse
      heading={`Employment information : ${label}`}
      open={viewAppFlow ? false : true}
      editable={true}
      onEdit={onEdit}
    >
      <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25} id="employment-status-info">
        <Typography variant="subtitle2">Employment status</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {empStatusMapping}
        </Typography>
        <Typography variant="subtitle2">Address line 1</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.employmentInformationDetail?.address?.lineOne || '--'}
        </Typography>
        <Typography variant="subtitle2">Address line 2</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.employmentInformationDetail?.address?.lineTwo || '--'}
        </Typography>

        <Typography variant="subtitle2">Country</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.employmentInformationDetail?.address?.addrCountry || '--'}
        </Typography>
        <Typography variant="subtitle2">State</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.employmentInformationDetail?.address?.addrState || '--'}
        </Typography>
        <Typography variant="subtitle2">City</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.employmentInformationDetail?.address?.addrCity || '--'}
        </Typography>
        <Typography variant="subtitle2">Zip code</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.employmentInformationDetail?.address?.zipCode || '--'}
        </Typography>
        <Typography variant="subtitle2">Job title</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.employmentInformationDetail?.jobTitle}
        </Typography>
        <Typography variant="subtitle2">Occupation</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.employmentInformationDetail?.occupation}
        </Typography>
        <Typography variant="subtitle2">Industry</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {industryMapping}
        </Typography>
        <Typography variant="subtitle2">Time under current employer</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {`${employmentInformation?.employmentInformationDetail?.employmentTimePeriodYear} Years ${employmentInformation?.employmentInformationDetail?.employmentTimePeriodMonth} months `}
        </Typography>
        <Typography variant="subtitle2">Work phone</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.employmentInformationDetail?.workPhone ? workPhone : '--'}
        </Typography>
        <Typography variant="subtitle2">Gross monthly income</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {numberToCurrency(employmentInformation?.employmentInformationDetail?.grossMonthlyIncome)}
        </Typography>
      </Box>
      {employmentInformation?.additionalIncomeDetail && (
        <>
          <Typography
            variant="h6"
            fontWeight="700 !important"
            my="20px !important"
            id="additonal-information-label"
          >
            Additional Information
          </Typography>
          <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25} id="additional-info">
            <Typography variant="subtitle2">Name</Typography>
            <Typography variant="subtitle2" className={classes.currencyValue}>
              {employmentInformation?.additionalIncomeDetail?.addlEmployerName}
            </Typography>
            <Typography variant="subtitle2">Job title</Typography>
            <Typography variant="subtitle2" className={classes.currencyValue}>
              {employmentInformation?.additionalIncomeDetail?.addlJobTitle}
            </Typography>
            <Typography variant="subtitle2">Occupation</Typography>
            <Typography variant="subtitle2" className={classes.currencyValue}>
              {employmentInformation?.additionalIncomeDetail?.addlOccupation}
            </Typography>
            <Typography variant="subtitle2">Work phone</Typography>
            <Typography variant="subtitle2" className={classes.currencyValue}>
              {employmentInformation?.additionalIncomeDetail?.addlWorkPhone
                ? additionalWorkPhone
                : '--'}
            </Typography>
            <Typography variant="subtitle2">Gross monthly income</Typography>
            <Typography variant="subtitle2" className={classes.currencyValue}>
              {numberToCurrency(
                employmentInformation?.additionalIncomeDetail?.addlGrossMonthlyIncome
              )}
            </Typography>
          </Box>
        </>
      )}
    </CustomCollapse>
  );
};

export default EmploymentInformationSection;
EmploymentInformationSection.propTypes = {
  employmentInformation: PropTypes.instanceOf(Object),
  label: PropTypes.string,
  viewAppFlow: PropTypes.bool,
  setOpenPopup: PropTypes.func,
  setLandingUser: PropTypes.func,
  setLandingStep: PropTypes.func,
};
EmploymentInformationSection.defaultProps = {
  employmentInformation: {},
  label: '',
  viewAppFlow: false,
  setOpenPopup: defaultFn,
  setLandingUser: defaultFn,
  setLandingStep: defaultFn,
};
