/* eslint-disable react/prop-types */
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, Grid, Select, TextField, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ApiClass from 'Api/ApiClient';
import DescendingSortIcon from 'assets/svg/DescendingSorting.svg';
import GlassesIcon from 'assets/svg/OperaGlasses.svg';
import SettingsIcon from 'assets/svg/Settings.svg';
import AuthContext from 'context/AuthContext';
import moment from 'moment';
import {
  dealershipUsersFetchAll,
  filterActiveDealershipuser,
} from 'pages/DealershipUsers/DealershipFetchAllApi';
import { DrawerContent } from 'pages/Home/HeaderDrawer';
import React, { useContext, useEffect, useState } from 'react';
import manageClasses from 'styles/ManageDealers.module.scss';
import dashboardClasses from 'styles/applicationDashboard.module.scss';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { ternaryCondition } from 'utils/utility';

import CustomMultiSelect from 'components/CustomMultiSelect/CustomMultiSelect';
import CustomSwitch from 'components/CustomSwitch/CustomSwitch';

export const SearchFilters = ({
  searchOptions,
  setSearchOptions,
  frequencyDetailList,
  loanStatusList,
  applicationData,
  setApplicationData,
  setLoader,
  myAppFlag,
  userID,
  page,
  setPage,
  rowsPerPage,
  searchAnchor,
  setSearchAnchor,
  flow,
  approvalStatusList,
  dealerList,
  viewApprovalQueue,
  setEmptyDataMessage,
  draftFetchApplications,
  fetchMyApplications,
  fetchApplications,
  fetchUserRequestData,
  fetchDealerShipUsers,
  fetchDealerProfiles,
}) => {
  const { role } = useContext(AuthContext);
  const ApiClient = new ApiClass();
  const [isSwitchActive, setIsSwitchActive] = useState(true);
  const [selectedDealer, setSelectedDealer] = useState([]);
  console.log('selectedDealer,', selectedDealer);
  const isAllSelected = dealerList?.length > 0 && selectedDealer?.length === dealerList?.length;
  useEffect(() => {
    if (viewApprovalQueue) {
      setSelectedDealer(dealerList?.length ? dealerList.map((x) => x.dealerId) : []);
    } else if (flow === 'UserRequest') {
      setSelectedDealer(dealerList?.length ? dealerList.map((x) => x.dealerId) : []);
    } else {
      setSelectedDealer([]);
    }
  }, [dealerList]);
  const handleSearchOptions = (e) => {
    setSearchOptions({
      ...searchOptions,
      [e.target.name]: e.target.value,
    });
  };
  const emptyMesssgaeHandle = async (resp) => {
    if (!resp) {
      return 'No record/s found';
    } else {
      return 'No Application found';
    }
  };
  const clearSearch = async () => {
    if (flow === 'Drafts') {
      setSearchOptions({
        refID: '',
        applicantName: '',
        vehicleName: '',
        createDate: null,
      });
      let resp = draftFetchApplications();

      setEmptyDataMessage(await emptyMesssgaeHandle(resp));
      setApplicationData(resp);
    } else if (flow === 'UserRequest') {
      setSelectedDealer(dealerList?.length ? dealerList.map((x) => x.dealerId) : []);
      setSearchOptions({
        frequency: '7_DAY',
        dealerId: viewApprovalQueue ? '' : dealerList[0]?.dealerId,
        approvalStatus: 'PENDING',
        firstName: '',
        lastName: '',
        requestorId: '',
      });
      let resp = await fetchUserRequestData();
      setEmptyDataMessage(await emptyMesssgaeHandle(resp));
      setApplicationData(resp?.userRequestList);
    } else if (flow === 'DealerProfiles') {
      setSearchOptions({
        dealerCode: '',
        dealerName: '',
        active: '1',
        dealerRating: '',
      });
      await fetchDealerProfiles();
    } else if (flow === 'dealershipUsers') {
      setSearchOptions({
        searchOption: '',
        searchText: '',
      });
      setLoader(true);
      let resp = await dealershipUsersFetchAll({ userID });
      setApplicationData(resp);
      setEmptyDataMessage(await emptyMesssgaeHandle(resp));
      setLoader(false);
    } else {
      setSearchOptions({
        frequency: '7_DAY',
        loanStatus: '',
        spName: '',
        createdBy: '',
        vehicleName: '',
      });
      if (flow === 'MyApplications') {
        const resp = fetchMyApplications();
        setEmptyDataMessage(await emptyMesssgaeHandle(resp));
        setApplicationData(resp);
      } else if (flow === 'Applications') {
        setSelectedDealer(dealerList?.length ? dealerList.map((x) => x.dealerId) : []);
        const resp = fetchApplications();
        setEmptyDataMessage(await emptyMesssgaeHandle(resp));
        setApplicationData();
      }
    }
  };
  const handleResponse = async (resp) => {
    console.log('hello');
    if (resp) {
      console.log('adv', { resp });
      setApplicationData(resp?.applicationList);
      setPage(1);
      if (resp.applicationList?.length === 0) {
        setEmptyDataMessage('No record/s found');
      }
    }
  };

  const flowOthers = async () => {
    let loanStatus = await ternaryCondition(
      searchOptions.loanStatus,
      `loanStatus=${searchOptions.loanStatus}&`,
      ''
    );
    let selectDealer = await ternaryCondition(
      selectedDealer?.length && viewApprovalQueue,
      `dealerId=${selectedDealer}&`,
      ``
    );
    let vehicleName = await ternaryCondition(
      searchOptions.vehicleName,
      `vehicleName=${searchOptions.vehicleName}&`,
      ''
    );
    let spName = await ternaryCondition(
      searchOptions.spName,
      `spName=${searchOptions.spName}&`,
      ''
    );
    try {
      setLoader(true);
      let commaSeperated;
      commaSeperated = dealerList?.map((x) => x.dealerId)?.join(',');
      let dealerids = viewApprovalQueue ? '' : `&dealerId=${commaSeperated}`;

      let url = `${UrlHelpers.fetchApplications}?${loanStatus}frequency=${
        searchOptions.frequency
      }&${
        !myAppFlag
          ? searchOptions.createdBy && `createdBy=${searchOptions.createdBy}&`
          : `createdBy=${userID}&`
      }${spName}${vehicleName}${selectDealer}appStatus=SUBMITTED`;

      let draftUrl = `${UrlHelpers.fetchApplications}?frequency=7_DAY&${
        searchOptions.vehicleName ? `vehicleName=${searchOptions.vehicleName}&` : ''
      }${searchOptions.refID ? `applicationId=${searchOptions.refID}&` : ''}${
        searchOptions.applicantName ? `name=${searchOptions.applicantName}&` : ''
      }${
        searchOptions?.createDate
          ? `createdOn=${moment(searchOptions?.createDate)?.format('MM/DD/YYYY')}&`
          : ''
      }appStatus=DRAFT`;

      if (flow === 'Applications' || flow === 'Drafts') {
        draftUrl =
          role === 'SALES REPRESENTATIVE' || role === 'SALES REPRESENTATIVE 2' || viewApprovalQueue
            ? `${draftUrl}&createdBy=${userID}`
            : `${draftUrl}&dealerId=${commaSeperated}`;
        url = `${url}${dealerids}`;
      }

      const resp = await ApiClient.get(flow === 'Drafts' ? draftUrl : url, {
        headers: { 'Content-Type': 'application/json' },
      });

      await handleResponse(resp);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  };

  const flowDealerProfiles = async () => {
    try {
      setLoader(true);
      let dealerCode = searchOptions.dealerCode ? `&dealerId=${searchOptions.dealerCode}` : '';
      let url = `${UrlHelpers.dealers}?status=${searchOptions.active}${dealerCode}${
        searchOptions.dealerName ? `&dealerName=${searchOptions.dealerName}` : ''
      }${
        searchOptions.dealerRating ? `&originatorOrDealerRating=${searchOptions.dealerRating}` : ''
      }`;

      const resp = await ApiClient.get(`${url}`);
      console.log('resp', resp);
      if (resp) {
        setApplicationData(resp);
        setPage(1);
      }
    } catch (e) {
      console.log('e', e);
      if (e?.response?.status === 500) {
        setApplicationData([]);
      }
    } finally {
      setLoader(false);
    }
  };

  const elseFlow = async () => {
    try {
      setLoader(true);

      let url = `${UrlHelpers.dealerUserRequest}?userType=${
        viewApprovalQueue ? 'banker' : 'dealer'
      }&frequency=${searchOptions.frequency}${
        selectedDealer?.length ? `&dealerNumber=${selectedDealer}` : ''
      }&approvalStatus=${searchOptions.approvalStatus}${
        searchOptions.firstName ? `&fNameSearchTxt=${searchOptions.firstName}` : ''
      }${searchOptions.lastName ? `&lNameSearchTxt=${searchOptions.lastName}` : ''}${
        searchOptions.requestorId ? `&requesterIdSearchTxt=${searchOptions.requestorId}` : ''
      }`;

      const resp = await ApiClient.get(`${url}`);
      if (resp) {
        console.log(resp);
        setApplicationData(resp?.userRequestList);
        if (resp.userRequestList?.length === 0) {
          setEmptyDataMessage('No record/s found');
        }
        setPage(1);
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  };
  const flowDealershipUsers = async () => {
    if (searchOptions?.searchText) {
      try {
        let resp;
        setLoader(true);
        setIsSwitchActive(false);
        let url = `${UrlHelpers.filterdealer}?${searchOptions.searchOption}=${searchOptions.searchText}`;

        resp = await ApiClient.post(
          url,
          {
            userId: userID,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        console.log({ resp, searchOptions });

        if (resp) {
          console.log(resp);
          setApplicationData(resp);
          setPage(1);
        }
      } catch (e) {
        console.log('e', e);
        if (e?.response?.status === 400) {
          setApplicationData([]);
          setEmptyDataMessage('No record/s found');
        }
      } finally {
        setLoader(false);
      }
    } else if (isSwitchActive) {
      setLoader(true);
      setApplicationData(await filterActiveDealershipuser({ userID }));
      setLoader(false);
    } else {
      setLoader(true);
      setApplicationData(await dealershipUsersFetchAll({ userID }));
      setLoader(false);
    }
  };

  const activeChange = async (e) => {
    setPage(1);
    setLoader(true);
    setIsSwitchActive(e.target.checked);
    if (e.target.checked) {
      setSearchOptions({
        searchOption: '',
        searchText: '',
        active: 'true',
      });

      setApplicationData(await filterActiveDealershipuser({ userID }));
    } else {
      setApplicationData(await fetchDealerShipUsers({ setLoader, userID }));
    }
    setLoader(false);
  };
  const handleApply = async () => {
    if (flow !== 'UserRequest' && flow !== 'DealerProfiles' && flow !== 'dealershipUsers') {
      await flowOthers();
    } else if (flow === 'DealerProfiles') {
      await flowDealerProfiles();
    } else if (flow === 'dealershipUsers') {
      await flowDealershipUsers();
    } else {
      await elseFlow();
    }
  };

  const indexOfLastPost = page * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  const toggleDrawer = (anchorType, anchorState) => {
    if (anchorType === 'search') {
      setSearchAnchor(anchorState);
    }
  };

  const handleMultiSelctChange = (event) => {
    const value = event?.target?.value;
    if (value[value.length - 1] === 'all') {
      setSelectedDealer(
        selectedDealer?.length === dealerList?.length ? [] : dealerList.map((x) => x.dealerId)
      );

      return;
    }
    let newValues = [];
    if (value?.length > 1 && value.includes('Select')) {
      newValues = value.filter((x) => x !== 'Select');
      setSelectedDealer(newValues);
    } else {
      setSelectedDealer(value);
    }
  };
  const handleOnKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^\d*$/;
    if (!regex?.test(keyValue)) {
      e.preventDefault();
    }
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.childBorderLine}
      mt={1}
      sx={{
        width: '100%',
        border: '1px solid rgb(224 224 224)',
        borderRadius: '2px',
      }}
    >
      <Box className={dashboardClasses.filterOptions}>
        <Typography variant="subtitle2">
          {applicationData?.length > 0 &&
            `Showing ${indexOfFirstPost + 1} to ${
              applicationData?.length > 9 ? indexOfLastPost : applicationData?.length
            } of ${applicationData?.length} entries`}
        </Typography>
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', gap: '5px' }}>
            <img src={SettingsIcon} />
            <Typography variant="subtitle2">Settings</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '5px' }}>
            <img src={DescendingSortIcon} />
            <Typography variant="subtitle2">Sort</Typography>
          </Box>
          {(flow === 'Applications' || flow === 'MyApplications') && (
            <Box sx={{ display: 'flex', gap: '5px' }}>
              <img src={GlassesIcon} />
              <Typography
                variant="subtitle2"
                component="p"
                tabIndex="0"
                style={{ cursor: 'pointer' }}
                onClick={() => toggleDrawer('search', true)}
                data-testid="advancedSearch"
              >
                Advanced search
              </Typography>
            </Box>
          )}
        </Box>
        <DrawerContent
          searchAnchor={searchAnchor}
          toggleDrawer={toggleDrawer}
          setLoader={setLoader}
          setApplicationData={setApplicationData}
          setEmptyDataMessage={setEmptyDataMessage}
        />
      </Box>
      <Grid container>
        <Box
          className={
            flow === 'dealershipUsers'
              ? dashboardClasses.filterInputDealerShipuser
              : dashboardClasses.filterInput
          }
          data-testid="application-table"
        >
          <FilterListIcon />
          {(flow === 'Applications' || flow === 'MyApplications' || flow === 'UserRequest') && (
            <>
              <Select
                id={'selectDays'}
                name="frequency"
                defaultValue={'7_DAY'}
                inputProps={{ 'data-testid': 'daysSearch' }}
                displayEmpty={true}
                sx={{ width: '100%', marginLeft: '5px', height: '30px' }}
                onChange={handleSearchOptions}
                value={searchOptions.frequency}
              >
                {frequencyDetailList?.map((opt) => {
                  const { name, value } = opt;
                  return (
                    <MenuItem key={value} value={value ? value : name}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
            </>
          )}
          {((flow === 'Applications' && viewApprovalQueue) || flow === 'UserRequest') && (
            <CustomMultiSelect
              selected={selectedDealer}
              handleMultiSelctChange={handleMultiSelctChange}
              isAllSelected={isAllSelected}
              options={dealerList.map((x) => ({
                displayName: x.dealerName,
                value: x.dealerId,
              }))}
              label="demo-multiple-name"
              name="demo-multiple-name"
              isStyled
              className={dashboardClasses.multiSelect}
            />
          )}
          {(flow === 'Applications' || flow === 'MyApplications') && (
            <>
              <Select
                id={'selectStatus'}
                name="loanStatus"
                defaultValue={''}
                inputProps={{ 'data-testid': 'statusSearch' }}
                displayEmpty={true}
                sx={{ width: '100%', height: '30px' }}
                onChange={handleSearchOptions}
                value={searchOptions.loanStatus}
              >
                <MenuItem value="">Status</MenuItem>
                {loanStatusList?.map((opt) => {
                  const { name, value } = opt;
                  return (
                    <MenuItem key={value} value={value ? value : name}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
              <div className="search" style={{ width: '100%' }}>
                <input
                  placeholder="Sales person"
                  name="spName"
                  className={manageClasses.inputStyle}
                  onChange={handleSearchOptions}
                  data-testid="spName"
                  value={searchOptions.spName}
                  maxLength={15}
                />
              </div>
              {!myAppFlag && (
                <div className="search" style={{ width: '100%' }}>
                  <input
                    placeholder="Created by"
                    name="createdBy"
                    className={manageClasses.inputStyle}
                    onChange={handleSearchOptions}
                    data-testid="createdBy"
                    value={searchOptions.createdBy}
                    maxLength={15}
                  />
                </div>
              )}
            </>
          )}
          {flow === 'UserRequest' && (
            <>
              {
                <Select
                  id={'approvalStatus'}
                  name="approvalStatus"
                  defaultValue={searchOptions.approvalStatus}
                  inputProps={{ 'data-testid': 'approvalStatus' }}
                  displayEmpty={true}
                  sx={{ width: '100%', marginLeft: '5px', height: '30px' }}
                  onChange={handleSearchOptions}
                  value={searchOptions.approvalStatus}
                >
                  {approvalStatusList?.map((opt) => {
                    const { name, value } = opt;
                    return (
                      <MenuItem key={value} value={value}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
            </>
          )}
          {flow === 'dealershipUsers' && (
            <Box display="flex" alignItems="center">
              <Box sx={{ marginLeft: '10px' }}>
                <Typography>Active</Typography>
              </Box>
              <Box sx={{ marginLeft: '10px' }}>
                <CustomSwitch onChange={activeChange} checked={isSwitchActive} />
              </Box>
              <Box sx={{ marginLeft: '10px' }}>
                <Select
                  id={'select'}
                  defaultValue={''}
                  inputProps={{ 'data-testid': 'searchOption' }}
                  // {...field}
                  name="searchOption"
                  value={searchOptions.searchOption}
                  displayEmpty={true}
                  sx={{ width: '200px', marginLeft: '10px', height: '30px' }}
                  onChange={handleSearchOptions}
                >
                  <MenuItem value="">Select option</MenuItem>

                  {[
                    { name: 'User ID', value: 'username' },
                    { name: 'First name', value: 'firstname' },
                    { name: 'Last name', value: 'lastname' },
                  ].map((opt) => {
                    const { name, value } = opt;
                    return (
                      <MenuItem key={value} value={value || name}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <Box sx={{ marginLeft: '10px' }}>
                <div className="search">
                  <input
                    placeholder="Enter your text"
                    className={manageClasses.inputStyle}
                    onChange={handleSearchOptions}
                    data-testid="searchTerm"
                    value={searchOptions.searchText}
                    disabled={!searchOptions.searchOption}
                    name="searchText"
                  />
                </div>
              </Box>
            </Box>
          )}
          {flow === 'UserRequest' && (
            <>
              <div className="search" style={{ width: '100%' }}>
                <input
                  placeholder="First name"
                  name="firstName"
                  className={manageClasses.inputStyle}
                  onChange={handleSearchOptions}
                  data-testid="firstName"
                  value={searchOptions.firstName}
                  maxLength={15}
                />
              </div>
              <div className="search" style={{ width: '100%' }}>
                <input
                  placeholder="Last name"
                  name="lastName"
                  className={manageClasses.inputStyle}
                  onChange={handleSearchOptions}
                  data-testid="lastName"
                  value={searchOptions.lastName}
                  maxLength={15}
                />
              </div>
            </>
          )}
          {flow === 'UserRequest' && (
            <>
              <div className="search" style={{ width: '100%' }}>
                <input
                  placeholder="Requestor ID"
                  name="requestorId"
                  className={manageClasses.inputStyle}
                  onChange={handleSearchOptions}
                  data-testid="requestorId"
                  value={searchOptions.requestorId}
                  maxLength={15}
                />
              </div>
            </>
          )}
          {flow === 'DealerProfiles' && (
            <>
              <div className="search" style={{ width: '100%' }}>
                <input
                  placeholder="Dealer code"
                  name="dealerCode"
                  className={manageClasses.inputStyle}
                  onChange={handleSearchOptions}
                  data-testid="dealerCode"
                  value={searchOptions.dealerCode}
                  maxLength={15}
                  onKeyPress={handleOnKeyPress}
                />
              </div>
              <div className="search" style={{ width: '100%' }}>
                <input
                  placeholder="Dealer name"
                  name="dealerName"
                  className={manageClasses.inputStyle}
                  onChange={handleSearchOptions}
                  data-testid="dealerName"
                  value={searchOptions.dealerName}
                  maxLength={15}
                />
              </div>
              <Select
                id={'selectActive'}
                name="active"
                defaultValue={searchOptions?.active}
                inputProps={{ 'data-testid': 'active' }}
                displayEmpty={true}
                sx={{ width: '100%', height: '30px' }}
                onChange={handleSearchOptions}
                value={searchOptions.active}
              >
                <MenuItem value="">Active</MenuItem>
                {[
                  { name: 'Yes', value: '1' },
                  { name: 'No', value: '0' },
                ]?.map((opt) => {
                  const { name, value } = opt;
                  return (
                    <MenuItem key={value} value={value ? value : name}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
              <div className="search" style={{ width: '100%' }}>
                <input
                  placeholder="Dealer rating"
                  name="dealerRating"
                  className={manageClasses.inputStyle}
                  onChange={handleSearchOptions}
                  data-testid="dealerRating"
                  value={searchOptions.dealerRating}
                  maxLength={1}
                />
              </div>
            </>
          )}

          {flow === 'Drafts' && (
            <div className="search" style={{ width: '100%' }}>
              <input
                placeholder="Reference ID"
                name="refID"
                className={manageClasses.inputStyle}
                onChange={handleSearchOptions}
                data-testid="refID"
                value={searchOptions.refID}
                maxLength={15}
              />
            </div>
          )}
          {flow !== 'UserRequest' && flow !== 'DealerProfiles' && flow !== 'dealershipUsers' && (
            <div className="search" style={{ width: '100%' }}>
              <input
                placeholder="Vehicle make"
                name="vehicleName"
                className={manageClasses.inputStyle}
                onChange={handleSearchOptions}
                data-testid="vehicleName"
                value={searchOptions.vehicleName}
                maxLength={15}
              />
            </div>
          )}
          {flow === 'Drafts' && (
            <div className="search" style={{ width: '100%' }}>
              <input
                placeholder="Applicant name"
                name="applicantName"
                className={manageClasses.inputStyle}
                onChange={handleSearchOptions}
                data-testid="applicantName"
                value={searchOptions.applicantName}
                maxLength={15}
              />
            </div>
          )}
          {flow === 'Drafts' && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                value={searchOptions.createDate}
                onChange={(val) => {
                  return setSearchOptions({
                    ...searchOptions,
                    createDate: val,
                  });
                }}
                name="createDate"
                data-testid="createDate"
                renderInput={(params) => {
                  const updatedParams = params;
                  updatedParams.inputProps['placeholder'] = 'Created date';
                  updatedParams.inputProps['data-testid'] = 'createDate';
                  return (
                    <TextField
                      {...updatedParams}
                      error={false}
                      sx={{
                        '.MuiOutlinedInput-root': {
                          height: '30px !important',
                          width: '100%',
                          borderRadius: '4px',
                          padding: '10px',
                        },
                        '.MuiOutlinedInput-input::placeholder': {
                          color: 'rgb(117, 117, 117,1) !important',
                          fontSize: '13.333px',
                          opacity: 1,
                        },
                        '.MuiOutlinedInput-input': {
                          padding: '16.5px 0',
                        },
                      }}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          )}
          <Button
            sx={{ width: '90px', height: '30px' }}
            color="secondary"
            variant="contained"
            size="small"
            onClick={handleApply}
            data-testid="apply"
            // disabled={flow === 'dealershipUsers'}
          >
            Apply
          </Button>
          {(searchOptions?.loanStatus?.length > 0 ||
            searchOptions?.spName?.length > 0 ||
            searchOptions?.createdBy?.length > 0 ||
            searchOptions?.refID?.length > 0 ||
            searchOptions?.applicantName?.length > 0 ||
            searchOptions?.createDate ||
            searchOptions?.vehicleName?.length > 0 ||
            searchOptions?.firstName?.length > 0 ||
            searchOptions?.lastName?.length > 0 ||
            searchOptions?.requestorId?.length > 0 ||
            searchOptions?.dealerCode?.length > 0 ||
            searchOptions?.dealerName?.length > 0 ||
            searchOptions?.userId?.length > 0 ||
            searchOptions?.searchOption?.length > 0 ||
            searchOptions?.searchText?.length > 0 ||
            searchOptions?.dealerRating?.length > 0 ||
            (selectedDealer?.length < dealerList?.length && selectedDealer?.length !== 0)) && (
            <Typography
              variant="subtitle2"
              style={{ cursor: 'pointer', display: 'flex' }}
              onClick={clearSearch}
              tabIndex="0"
              px={1}
              gap="2px"
              data-testid="clear"
              color="#000"
            >
              <Typography variant="subtitle2">✖</Typography>
              <Typography variant="subtitle2">Clear</Typography>
            </Typography>
          )}
        </Box>
      </Grid>
    </Box>
  );
};
