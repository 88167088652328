import { Box, Link, Paper, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import AuthContext from 'context/AuthContext';
import ApplicationsTable from 'pages/ApplicationDashboard/ApplicationsTable';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';

import TabPanel from 'components/TabPanel/TabPanel';

import UserRequestDashboard from './UserRequestDashboard';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const BankerApplicationDashboard = ({
  applicationsData,
  viewApprovalQueue,
  redirectTabValue,
}) => {
  const { role } = useContext(AuthContext);
  const [tabVal, setTabValue] = useState(redirectTabValue);
  const navigate = useNavigate();
  const [emptyDataMessage, setEmptyDataMessage] = useState('No application/s available');

  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue);
  };
  const roleCheck = role && role !== 'SALES REPRESENTATIVE' && role !== 'SALES REPRESENTATIVE 2';
  return (
    <>
      <Box display="flex" justifyContent="center">
        <Box sx={{ width: '98%' }}>
          <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
            <Box display="flex" flexDirection="column" className={classes.dummyHome} gap={2}>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="h3">Applications</Typography>
              </Box>
              <Box>
                <Box>
                  <Tabs value={tabVal} onChange={handleTabChange} aria-label="applications-tabs">
                    <Tab label="Auto loan" {...a11yProps(0)} />
                    {roleCheck && (
                      <Tab label="User request" data-testid="userReq" {...a11yProps(1)} />
                    )}
                    <Box sx={{ marginLeft: 'auto' }}>
                      <Link
                        color="secondary"
                        tabIndex="0"
                        onClick={() =>
                          navigate('/applications', {
                            state: { viewUserRequest: viewApprovalQueue },
                          })
                        }
                        data-testid="viewAll"
                      >
                        <Typography style={{ cursor: 'pointer' }} variant="subtitle2" component="p">
                          View all
                        </Typography>
                      </Link>
                    </Box>
                  </Tabs>
                </Box>
                <TabPanel value={tabVal} index={0}>
                  <ApplicationsTable
                    applicationsData={applicationsData}
                    emptyDataMessage={emptyDataMessage}
                    setEmptyDataMessage={setEmptyDataMessage}
                    mainPage
                  />
                </TabPanel>
                {roleCheck && (
                  <TabPanel value={tabVal} index={1}>
                    <UserRequestDashboard
                      viewAll={false}
                      viewApprovalQueue={viewApprovalQueue}
                      emptyDataMessage={emptyDataMessage}
                      setEmptyDataMessage={setEmptyDataMessage}
                    />
                  </TabPanel>
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

BankerApplicationDashboard.propTypes = {
  applicationsData: PropTypes.instanceOf(Object),
  viewApprovalQueue: PropTypes.bool,
  redirectTabValue: PropTypes.number,
};

BankerApplicationDashboard.defaultProps = {
  applicationsData: {},
  viewApprovalQueue: false,
  redirectTabValue: 0,
};
