import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from 'styles/globalStyle.module.scss';

export const SSN = (props) => {
  const [ssnVal, setSsnVal] = useState('true');
  const [ssnFakeValue, setSsnFakeValue] = useState('');
  const [showHideIcon, setShowHideIcon] = useState(true);
  const {
    handleKeyDown,
    ssn = '',
    errors = {},
    couserIndex = 0,
    flow = '',
    required = false,
    prefixName,
  } = props;

  const showHideHandler = (e) => {
    e.preventDefault();
    setSsnVal((ssnValue) => !ssnValue);
    setShowHideIcon(!showHideIcon);
  };

  React.useEffect(() => {
    console.log('ssn', ssn);
    const len = ssn?.length || 0;
    console.log('ssn length', len);
    let stars;
    switch (len) {
      case 1:
        stars = 'X';
        break;
      case 2:
        stars = 'XX';
        break;
      case 3:
        stars = 'XXX';
        break;
      case 4:
        stars = 'XXX-';
        break;
      case 5:
        stars = 'XXX-X';
        break;
      case 6:
        stars = 'XXX-XX';
        break;
      case 7:
        stars = 'XXX-XX-';
        break;
      case 0:
        stars = '';
        break;
      default:
        stars = 'XXX-XX-';
        break;
    }
    const ssnSubString = ssn?.substring(7);
    const result = stars + ssnSubString;
    console.log('result', result);
    setSsnFakeValue(result);
  }, [ssn]);
  const handleSSNValue = (currentKey, selectionStart, selectionEnd) => {
    const startSubstring = ssn?.substring(0, selectionStart);
    const endSubstring = ssn?.substring(selectionEnd);
    const enterValue = startSubstring + currentKey + endSubstring;
    let ssnValue = ssn !== '' ? enterValue : currentKey;
    ssnValue = ssnValue.replace(/\D/g, '');
    if (ssnValue.toString().length >= 4) {
      ssnValue = `${ssnValue.slice(0, 3)}-${ssnValue.slice(3, ssnValue.toString().length)}`;
    }
    if (ssnValue.toString().length >= 7) {
      ssnValue = `${ssnValue.slice(0, 6)}-${ssnValue.slice(6, ssnValue.toString().length)}`;
    }
    setTimeout(() => {
      const input = document.getElementById('ssn');
      if (input?.setSelectionRange) {
        input.focus();
        if (selectionStart + 1 === 4 || selectionStart + 1 === 7) {
          input.setSelectionRange(selectionStart + 2, selectionStart + 2);
        } else {
          input.setSelectionRange(selectionStart + 1, selectionStart + 1);
        }
      }
    }, 10);
    handleKeyDown(ssnValue);

    return false;
  };

  const handleDelValue = (e, selectionStart, selectionEnd) => {
    let delValue;
    if (selectionEnd === 0) {
      return;
    }
    if (selectionStart === selectionEnd) {
      const startDel = ssn?.slice(0, selectionStart - 1);
      const endDel = ssn?.slice(selectionEnd, ssn?.length);
      delValue = startDel + endDel;
    } else {
      const startDel = ssn?.substring(0, selectionStart);
      const endDel = ssn?.substring(selectionEnd);
      delValue = startDel + endDel;
    }
    delValue = delValue?.replace(/\D/g, '');
    if (delValue.toString().length >= 4) {
      delValue = `${delValue.slice(0, 3)}-${delValue.slice(3, delValue.toString().length)}`;
    }
    if (delValue.toString().length >= 7) {
      delValue = `${delValue.slice(0, 6)}-${delValue.slice(6, delValue.toString().length)}`;
    }
    setTimeout(() => {
      if (selectionStart !== selectionEnd) {
        e.target.selectionEnd = selectionStart;
      } else {
        e.target.selectionEnd = selectionStart - 1;
      }
    }, 10);
    handleKeyDown(delValue);
  };

  const maskValueChange = (e) => {
    const currentCode = e.which || e.code;
    let currentKey = e.key;
    if (currentKey === 'Unidentified') {
      currentKey = String.fromCharCode(currentCode);
    }
    if (currentKey === 'Tab') {
      return;
    }
    e.preventDefault();
    const numkeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const navkeys = [
      'Backspace',
      'Delete',
      'Shift',
      'ArrowUp',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
      '',
    ];
    const { selectionStart } = e.target;
    const { selectionEnd } = e.target;
    if (currentKey === 'ArrowLeft') {
      if (selectionStart === 0) return;
      setTimeout(() => {
        e.target.selectionEnd = selectionStart - 1;
      }, 10);
    } else if (currentKey === 'ArrowRight') {
      setTimeout(() => {
        e.target.selectionStart = selectionStart + 1;
      }, 10);
    } else if (!numkeys.includes(currentKey) && !navkeys.includes(currentKey)) {
      e.preventDefault();
    } else if (ssn?.length < 11 && numkeys.includes(currentKey)) {
      handleSSNValue(currentKey, selectionStart, selectionEnd);
    } else if (currentKey === 'Backspace' || currentKey === 'Delete') {
      handleDelValue(e, selectionStart, selectionEnd);
    }

    return false;
  };
  let err = flow ? errors?.ssn : errors?.cousers?.[couserIndex]?.ssn;

  return (
    <>
      <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection="column">
        <InputLabel htmlFor="ssn" aria-label="Social security number" Required>
          {required && <span style={{ color: 'red' }}>*&nbsp;</span>} Social security number
        </InputLabel>
        <OutlinedInput
          id={`${prefixName}.ssn`}
          name="ssn"
          fullWidth
          autoComplete="off"
          value={ssnVal ? ssnFakeValue : ssn}
          onKeyDown={maskValueChange}
          inputProps={{
            maxLength: 11,
            inputMode: 'tel',
            'data-testid': 'ssn',
            placeholder: 'Enter SSN',
          }}
          endAdornment={
            <InputAdornment
              position="end"
              style={{ marginLeft: '0', marginRight: '-7px', maxWidth: '35px' }}
            >
              <IconButton
                onClick={showHideHandler}
                tabIndex="0"
                data-testid="show-hide"
                aria-label="show/hide toggle"
              >
                {!showHideIcon ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
              </IconButton>
            </InputAdornment>
          }
        />
      </Box>
      {err && (
        <FormHelperText gap={2} className={styles.customSelectError}>
          {' '}
          {flow ? errors?.ssn?.message : errors?.cousers?.[couserIndex]?.ssn?.message}
        </FormHelperText>
      )}
    </>
  );
};

SSN.propTypes = {
  handleKeyDown: PropTypes.func,
  trigger: PropTypes.func,
  errors: PropTypes.instanceOf(Object),
  ssn: PropTypes.string,
  flow: PropTypes.string,
  isCommercialPage: PropTypes.bool,
  required: PropTypes.bool,
  couserIndex: PropTypes.number,
  prefixName: PropTypes.string,
};
