import { regexChar2, regexEmail2 } from 'utils/utility';
import * as yup from 'yup';

export const CreateApplicationSchema = (isSkipped, coappdet, cosignerEnabled) => {
  return yup.object().shape({
    CoAppFirstName: yup.string().when('applicantOrSigner', {
      is: (val) => {
        return val?.includes('coApplicant');
      },
      then: yup
        .string()
        .test('len', 'Maximum 15 characters can be entered', (val) => val.length <= 15)
        .matches(regexChar2, 'Invalid format')
        .required('* Mandatory field'),
    }),

    CoAppLastName: yup.string().when('applicantOrSigner', {
      is: (val) => {
        return val?.includes('coApplicant');
      },
      then: yup
        .string()
        .test('len', 'Maximum 25 characters can be entered', (val) => val?.length <= 25)
        .matches(regexChar2, 'Invalid format')
        .required('* Mandatory field'),
    }),

    CoSigner: yup.array().of(
      yup.object().shape({
        CoSignerName: yup.object().shape({
          CoSignerFirstName: yup
            .string()
            .max(15, 'Maximum 15 characters can be entered')
            .test('check cosigne LastName', '* Mandatory Field', (val) => {
              return !(!val && !isSkipped && (coappdet?.includes('coSigner') || cosignerEnabled));
            })
            .matches(regexChar2, 'Invalid format'),
          CoSignerLastName: yup
            .string()
            .max(25, 'Maximum 25 characters can be entered')
            .test('check cosigne LastName', '* Mandatory Field', (val) => {
              return !(!val && !isSkipped && (coappdet?.includes('coSigner') || cosignerEnabled));
            })
            .matches(regexChar2, 'Invalid format'),
        }),
      })
    ),
  });
};

export const CoAppDialogSchema = yup.object().shape({
  primaryAppFirstName: yup
    .string()
    .required('* Mandatory field')
    .test('len', 'Maximum 15 characters can be entered', (val) => val?.length <= 15)
    .matches(regexChar2, 'Invalid format'),

  primaryAppLastName: yup
    .string()
    .required('* Mandatory field')
    .test('len', 'Maximum 25 characters can be entered', (val) => val?.length <= 25)
    .matches(regexChar2, 'Invalid format'),
});

export const InvitationOrManualSchema = (
  CoAppFirstName,
  CoSigner,
  inviteCoapplicant,
  inviteCoSignerOne,
  inviteCoSignerTwo
) => {
  return yup.object().shape({
    primaryApplicantEmail: yup
      .string()
      .nullable(true)
      .when('inviOrManual', {
        is: (val) => {
          console.log('invi', val);
          return val === 'invitation';
        },
        then: yup
          .string()
          .nullable(true)
          .transform((v) => (v === '' ? null : v))
          .max(50, 'Invalid email length')
          .matches(regexEmail2, 'Invalid email format'),
      }),
    CoApplicantEmail: yup.string().when('inviOrManual', {
      is: (val) => val === 'invitation' && CoAppFirstName && inviteCoapplicant,
      then: yup
        .string()
        .required('Email mandatory to send invitation')
        .max(50, 'Invalid email length')
        .matches(regexEmail2, 'Invalid email format'),
    }),

    CoSigner1Email: yup.string().when('inviOrManual', {
      is: (val) =>
        val === 'invitation' && CoSigner[0]?.CoSignerName?.CoSignerFirstName && inviteCoSignerOne,
      then: yup
        .string()
        .required('Email mandatory to send invitation')
        .max(50, 'Invalid email length')
        .matches(regexEmail2, 'Invalid email format'),
    }),

    CoSigner2Email: yup.string().when('inviOrManual', {
      is: (val) =>
        val === 'invitation' && CoSigner[1]?.CoSignerName?.CoSignerFirstName && inviteCoSignerTwo,
      then: yup
        .string()
        .required('Email mandatory to send invitation')
        .max(50, 'Invalid email length')
        .matches(regexEmail2, 'Invalid email format'),
    }),
  });
};
