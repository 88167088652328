import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { defaultFn, regexChar, regexEmail } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';
import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomPhoneNumber from 'components/CustomPhoneNumber/CustomPhoneNumber';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';
import { SSN } from 'components/SSN';

const InviBasicInformation = ({
  control,
  errors,
  setValue,
  clearErrors,
  maritalStatusList,
  trigger,
  inviUserType,
}) => {
  const watchSSN = useWatch({
    control,
    name: 'ssn',
  });
  const handleKeyDownSSN = (value) => {
    setValue('ssn', value);
    if (value.match(/^\d/)) {
      console.log('value', value);

      clearErrors('ssn');
    }
    trigger('ssn');
  };

  return (
    <CustomCollapse heading="Demographic information" open={true} errors={errors}>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.firstName}
            errorText={errors?.firstName?.message}
            maxLength="15"
            regex={regexChar}
            placeholder="Enter first name"
            label="First name"
            name="firstName"
            flexDirection="column"
            required
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.middleName}
            errorText={errors?.middleName?.message}
            maxLength="20"
            regex={regexChar}
            placeholder="Enter middle name"
            name="middleName"
            label="Middle name"
            flexDirection="column"
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.lastName}
            errorText={errors?.lastName?.message}
            maxLength="25"
            regex={regexChar}
            placeholder="Enter last name"
            label="Last name"
            name="lastName"
            flexDirection="column"
            required
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.secondLastName}
            errorText={errors?.secondLastName?.message}
            maxLength="25"
            regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡.'\s]$/}
            placeholder="Enter second last name"
            label="Second last name"
            name="secondLastName"
            flexDirection="column"
            required={inviUserType === 'primaryApplicant'}
          />
        </Grid>
        {/* <Grid item lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="space-between"> */}
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <SSN
            handleKeyDown={handleKeyDownSSN}
            ssn={watchSSN}
            errors={errors}
            flow="inviFlow"
            trigger={trigger}
            required
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomDatePicker
            name="dob"
            label="Date of birth"
            control={control}
            error={!!errors?.dob}
            errorText={errors?.dob?.message}
            required
            maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
            placeHolder="MM/DD/YYYY"
          />
        </Grid>
        {/* </Grid> */}

        {/* <Grid item lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="space-between"> */}
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomPhoneNumber
            name="mobile"
            label="Mobile"
            flexDirection="column"
            control={control}
            error={!!errors?.mobile}
            placeholder="Enter mobile"
            errorText={errors?.mobile?.message}
            required
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomPhoneNumber
            name="mobile2"
            label="Alternate phone number"
            flexDirection="column"
            control={control}
            error={!!errors?.mobile2}
            errorText={errors?.mobile2?.message}
            placeholder="Enter alternate phone number"
          />
        </Grid>
        {/* </Grid> */}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.email}
            errorText={errors?.email?.message}
            placeholder="Enter email"
            label="Email"
            regex={regexEmail}
            maxLength={50}
            flexDirection="column"
            name="email"
            required
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomSelectBox
            flexDirection="column"
            control={control}
            options={maritalStatusList}
            label="Marital status"
            required
            name="maritalStatus"
            errors={!!errors?.maritalStatus}
            errorText={errors?.maritalStatus?.message}
          />
        </Grid>
      </Grid>
    </CustomCollapse>
  );
};
export default InviBasicInformation;

InviBasicInformation.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  maritalStatusList: PropTypes.array,
  errorText: PropTypes.string,
  setValue: PropTypes.func,
  clearErrors: PropTypes.func,
  trigger: PropTypes.func,
  inviUserType: PropTypes.string,
};
InviBasicInformation.defaultProps = {
  control: {},
  errors: {},
  errorText: '',
  setValue: defaultFn,
  clearErrors: defaultFn,
  trigger: defaultFn,
  maritalStatusList: [],
  inviUserType: '',
};
