import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Box, Link } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { defaultFn } from 'utils/utility';
import { v4 as uuidv4 } from 'uuid';

import CustomCollapse from 'components/CustomCollapse';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';

const AddCoUsers = ({ control, errors, fields, append, remove, setValue, insert, swap }) => {
  const [showCoApplicant, setShowCoApplicant] = useState(false);
  const [showCoSigner, setShowCoSigner] = useState(false);
  const [CoApplicant, setCoApplicant] = useState([]);

  const [CoSigners, setCoSigners] = useState([]);
  console.log('fields', fields);
  const removeCoApplicant = () => {
    setShowCoApplicant(false);
    const CoApplicantIndexx = fields?.findIndex((x) => x?.userType === 'Co-applicant');
    remove(CoApplicantIndexx);
  };

  useEffect(() => {
    const CoApplicantt = fields?.filter((x) => x?.userType === 'Co-applicant');
    const CoSignerss = fields.filter((x) => x?.userType?.match('Co-signer')?.length > 0);
    setCoApplicant(CoApplicantt);
    setCoSigners(CoSignerss);
  }, [fields]);

  const onAddCoApplicant = () => {
    insert(1, {
      applicantId: uuidv4(),
      userType: 'Co-applicant',
      firstName: '',
      lastName: '',
    });
    setShowCoApplicant(true);
  };

  const onAddCoSigner = () => {
    append({
      applicantId: uuidv4(),
      userType: 'Co-signer 1',
      firstName: '',
      lastName: '',
    });
    setShowCoSigner(true);
  };

  return (
    <>
      {fields?.length === 1 && !showCoSigner && !showCoApplicant && (
        <Box display="flex" gap={4}>
          <Box display="flex" gap={1}>
            <ControlPointIcon color="secondary" />
            <Link
              component="button"
              color="secondary"
              variant="subtitle2"
              onClick={onAddCoApplicant}
              id="add-coapp-btn"
            >
              Add Co-applicant
            </Link>
          </Box>
          <Box display="flex" gap={1}>
            <ControlPointIcon color="secondary" />
            <Link
              component="button"
              variant="subtitle2"
              color="secondary"
              onClick={onAddCoSigner}
              id="add-cosigner-btn"
            >
              Add Co-signer
            </Link>
          </Box>
        </Box>
      )}
      {(showCoApplicant || CoApplicant.length > 0) &&
        CoApplicant.map((x) => {
          const coapplicantMaping = fields?.findIndex((each) => x.userType === each.userType);
          return (
            <CustomCollapse key={x?.userType} heading="Co-applicant" open={true} errors={errors}>
              <Box display="flex" flexDirection="column">
                <Box display="flex" gap={2.5}>
                  <Box display="flex" flexDirection="column" width="50%">
                    <CustomInputBox
                      control={control}
                      errors={!!errors?.cousers?.[coapplicantMaping]?.firstName}
                      errorText={errors?.cousers?.[coapplicantMaping]?.firstName?.message}
                      placeholder="Enter first name"
                      label="First name"
                      name={`cousers[${coapplicantMaping}].firstName`}
                      id={`cousers[${coapplicantMaping}].firstName`}
                      flexDirection="column"
                      maxLength="15"
                      required
                      regex={/^[A-Za-z]$/}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" width="50%">
                    <CustomInputBox
                      control={control}
                      errors={!!errors?.cousers?.[coapplicantMaping]?.lastName}
                      errorText={errors?.cousers?.[coapplicantMaping]?.lastName?.message}
                      placeholder="Enter last name"
                      label="Last name"
                      name={`cousers[${coapplicantMaping}].lastName`}
                      id={`cousers[${coapplicantMaping}].lastName`}
                      flexDirection="column"
                      maxLength="25"
                      required
                      regex={/^[A-Za-z]$/}
                    />
                  </Box>
                </Box>

                <Box display="flex" gap={1}>
                  <CancelOutlinedIcon color="secondary" />
                  <Link
                    component="button"
                    color="secondary"
                    variant="subtitle2"
                    onClick={removeCoApplicant}
                    id="remove-coapp-btn"
                  >
                    Remove
                  </Link>
                </Box>
              </Box>
            </CustomCollapse>
          );
        })}

      {CoSigners?.length === 0 && !showCoSigner && CoApplicant.length > 0 && (
        <Box display="flex" gap={1}>
          <ControlPointIcon color="secondary" />
          <Link
            component="button"
            color="secondary"
            variant="subtitle2"
            onClick={onAddCoSigner}
            id="add-cosigner-btn"
          >
            Add Co-signer
          </Link>
        </Box>
      )}

      {(showCoSigner || CoSigners.length > 0) && CoApplicant.length === 0 && !showCoApplicant && (
        <Box display="flex" gap={1}>
          <ControlPointIcon color="secondary" />
          <Link
            component="button"
            color="secondary"
            variant="subtitle2"
            onClick={onAddCoApplicant}
            id="add-coapp-btn"
          >
            Add Co-applicant
          </Link>
        </Box>
      )}

      {CoSigners?.length > 0 && (
        <CustomCollapse heading="Co-signer" open={true} errors={errors}>
          {CoSigners?.map((x, index) => {
            const CoSignerMapping = fields?.findIndex((each) => x.userType === each.userType);
            console.log('cosignermapping', CoSignerMapping);
            return (
              <Box display="flex" flexDirection="column" key={x.id}>
                <Box display="flex" gap={2.5}>
                  <Box display="flex" flexDirection="column" width="50%">
                    <CustomInputBox
                      control={control}
                      errors={!!errors?.cousers?.[CoSignerMapping]?.firstName}
                      errorText={errors?.cousers?.[CoSignerMapping]?.firstName?.message}
                      placeholder="Enter first name"
                      label="First name"
                      name={`cousers[${CoSignerMapping}].firstName`}
                      id={`cousers[${CoSignerMapping}].firstName`}
                      flexDirection="column"
                      maxLength="15"
                      required
                      regex={/^[A-Za-z]$/}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" width="50%">
                    <CustomInputBox
                      control={control}
                      errors={!!errors?.cousers?.[CoSignerMapping]?.lastName}
                      errorText={errors?.cousers?.[CoSignerMapping]?.lastName?.message}
                      placeholder="Enter last name"
                      label="Last name"
                      name={`cousers[${CoSignerMapping}].lastName`}
                      id={`cousers[${CoSignerMapping}].lastName`}
                      flexDirection="column"
                      maxLength="25"
                      required
                      regex={/^[A-Za-z]$/}
                    />
                  </Box>
                </Box>

                <Box display="flex" gap={2.5}>
                  {CoSigners?.length < 2 && (
                    <Box display="flex" gap={1}>
                      <ControlPointIcon color="secondary" />
                      <Link
                        color="secondary"
                        component="button"
                        variant="subtitle2"
                        onClick={() => {
                          append({
                            applicantId: uuidv4(),
                            userType: `Co-signer ${index + 2}`,
                            firstName: '',
                            lastName: '',
                          });
                        }}
                        id="couser-add-btn"
                      >
                        Add
                      </Link>
                    </Box>
                  )}
                  <Box display="flex" gap={1}>
                    <CancelOutlinedIcon color="secondary" />
                    <Link
                      color="secondary"
                      component="button"
                      variant="subtitle2"
                      id="couser-remove-btn"
                      onClick={() => {
                        if (index === 0 && CoSigners?.length === 1) {
                          setShowCoSigner(false);
                        }
                        if (CoSigners?.length === 2 && index === 0) {
                          const CoSignerMappingInner = fields?.findIndex(
                            (each) => each.userType === 'Co-signer 1'
                          );
                          const CoSignerMappingInner2 = fields?.findIndex(
                            (each) => each.userType === 'Co-signer 2'
                          );
                          swap(CoSignerMappingInner2, CoSignerMappingInner);
                          setValue(`cousers[${CoSignerMappingInner}].userType`, 'Co-signer 1');

                          remove(CoSignerMappingInner2);
                        } else {
                          remove(CoSignerMapping);
                        }
                      }}
                    >
                      Remove
                    </Link>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </CustomCollapse>
      )}
    </>
  );
};

export default AddCoUsers;

AddCoUsers.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  setValue: PropTypes.func,
  setError: PropTypes.func,
  setCoApplicantFlag: PropTypes.func,
  clearErrors: PropTypes.func,
  fields: PropTypes.instanceOf(Object),
  append: PropTypes.func,
  remove: PropTypes.func,
  getValues: PropTypes.func,
  insert: PropTypes.func,
  swap: PropTypes.func,
};
AddCoUsers.defaultProps = {
  control: {},
  errors: {},
  setValue: defaultFn,
  setError: defaultFn,
  setCoApplicantFlag: defaultFn,
  clearErrors: defaultFn,
  fields: [],
  append: defaultFn,
  remove: defaultFn,
  insert: defaultFn,
  swap: defaultFn,
};
