import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, Grid, IconButton, Link, Paper, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import ApiClass from 'Api/ApiClient';
import { payCalcOnLoadApi } from 'Services/PaymentCalcApi';
import { getAccessToken } from 'Services/commonComponentsApi';
import AuthContext from 'context/AuthContext';
import moment from 'moment';
import { payCalcApis } from 'pages/CreateApplication/InformationRequest/moduleUtilities/dropdownApis';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { paymentCalculatorSchema } from 'schemaValidations/paymentCalculatorSchema';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import CustomCollapse from 'components/CustomCollapse';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';
import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

import printIcon from '../../assets/printIcon.svg';
import classes from '../../styles/globalStyle.module.scss';
import constants from '../../utils/constants';
import { currencyToNumber, handleFocusError, removeNullValues } from '../../utils/utility';
import BasicVehicleDetail from './BasicVehicleDetail';
import FinancedAmount from './FinancedAmount';
import Insurance from './Insurance';
import ResultPage from './ResultPage';

const { deferredPayment } = constants;

const PaymentCalculator = () => {
  const ApiClient = new ApiClass();
  const {
    monthsDeferredList,
    setMonthsDeferredList,
    newUsedList,
    setNewUsedList,
    commonToken,
    dealer,
    setCommonToken,
    programList,
    setProgramList,
    makeList,
    setMakeList,
    program,
    setProgram,
    vehicleType,
    vehicleYear,
    setVehicleType,
    setTermList,
    createAppFlag,
    setCreateAppLookup,
    setCreateAppFlag,
    setCreateAppApplicationId,
    setCreateAppLaunchType,
    createAppApplicationId,
    setDealer,
    createAppLaunchType,
    setPopupStep,
    creditLifeTypeList,
    setCreditLifeTypeList,
    creditLifeList,
    setCreditLifeList,
    vehicleInsuranceTypeList,
    setVehicleInsuranceTypeList,
    rolebasedDealers,
    setFinanceInfo,
    setLookupSaved,
  } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [calculateData, setCalculateData] = useState({});
  const [apiErr, setApiErr] = useState('');
  const [rendered, setRenderedFlag] = useState(false);
  const [basicVehicleDetailData, setBasicVehicleDetailData] = useState({
    promotions: [],
  });
  const [oldFormData, setOldFormData] = useState({});
  const [calcReqResp, setCalcReqResp] = useState({});
  const [isPromotionExpired, setIsPromotionExpired] = useState(false);
  const location = useLocation();
  console.log({ calcReqResp });
  const navigate = useNavigate();
  const {
    handleSubmit,
    clearErrors,
    trigger,
    control,
    getValues,
    setFocus,
    setValue,
    watch,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      programVal: '',
      newOrUsed: '',
      term: '',
      balloonPay: '',
      promotion: '',
      vehicleClass: '',
      rate: '',
      contractDate: null,
      wishToTradeIn: 'no',
      salePrice: '',
      cashDown: '',
      rebate: '',
      tradeDept: '',
      tradeAllowance: '',
      netTrade: '',
      carCare: '',
      other: '',
      serviceContract: '',
      extendedWarranty: '',
      paintProtection: '',
      year: '',
      make: '',
      model: '',
      balance: '',

      creditLife: 'No',
      creditLifeAmount: '0',
      creditLifeType: '',
      joint: '',

      gapFee: '',
      vehicleInsuranceType: '',
      vehicleInsuranceAmount: '0',
      dealer: '',
      registrationfee: '',

      monthDeferred: '1',
      financed: '',
      amountFinanced: '',
      isInsuranceOpen: false,
    },
    resolver: yupResolver(paymentCalculatorSchema(createAppLaunchType, createAppFlag)),
  });
  const dealerOpt = rolebasedDealers?.map((dealers) => ({
    name: dealers.dealerName,
    value: dealers.dealerId,
  }));

  const [
    watchProgram,
    watchNewUsed,
    watchTerm,
    watchVehicleInsuranceType,
    watchCreditLife,
    watchVehicleInsuranceAmount,
    watchContractDate,
  ] = watch([
    'programVal',
    'newOrUsed',
    'term',
    'vehicleInsuranceType',
    'creditLife',
    'vehicleInsuranceAmount',
    'contractDate',
  ]);
  const watchAll = watch();
  console.log({ errors });

  useEffect(async () => {
    let dateObject;
    const vehicleTypeNew = vehicleType === 'new' ? 'True' : vehicleType;
    if (createAppFlag) {
      setValue('programVal', program);
      setValue('newOrUsed', vehicleType === 'used' ? 'False' : vehicleTypeNew);
    } else {
      setCalcReqResp({});
    }
    if (location?.state?.flow === 'financialTerms' && location?.state?.data) {
      const oldData = { ...location?.state?.data?.formData };
      oldData.dealer = dealer;
      setOldFormData(oldData);
      setCalculateData(location?.state?.data?.calculatedData);
      const {
        programVal,
        newOrUsed,
        term,
        vehicleClass,
        rate,
        contractDate,
        salePrice,
        cashDown,
        rebate,
        tradeDept,
        tradeAllowance,
        netTrade,
        carCare,
        other,
        serviceContract,
        extendedWarranty,
        paintProtection,
        year,
        make,
        model,
        balance,
        creditLife,
        creditLifeAmount,
        creditLifeType,
        joint,
        criticalIllness,
        hospitalization,
        gapFee,
        vehicleInsuranceType,
        vehicleInsuranceAmount,
        registrationfee,
        monthDeferred,
        financed,
        amountFinanced,
        wishToTradeIn,
      } = location?.state?.data?.formData;
      const calculatedDataResp = {
        applicationId: createAppFlag ? createAppApplicationId : '',
        term: +term,
        newUsed: newOrUsed,
        program: programVal,
        promotion: {
          name: basicVehicleDetailData?.promotions[0]?.name,
          value: basicVehicleDetailData?.promotions[0]?.value,
          rate: currencyToNumber(rate),
        },
        baloonPayment: currencyToNumber(location?.state?.data?.baloonPayment),
        vehicleClass: vehicleClass,
        contractDate: moment(contractDate).format('MM/DD/yyyy'),
        monthDeferred: monthDeferred,
        rate: rate && currencyToNumber(rate),
        itemizationOfAmount: {
          salePrice: currencyToNumber(salePrice),
          cashDown: currencyToNumber(cashDown),
          rebate: currencyToNumber(rebate),
          carCare: currencyToNumber(carCare),
          other: currencyToNumber(other),
          serviceContact: currencyToNumber(serviceContract),
          tradeInDebt: currencyToNumber(tradeDept),
          tradeInAllowance: currencyToNumber(tradeAllowance),
          extendedWarranty: currencyToNumber(extendedWarranty),
          paintProtection: currencyToNumber(paintProtection),
          tradeInVehicleYear: year,
          tradeInVehicleMake: make,
          tradeInVehicleModel: model,
          wishToTradeIn: wishToTradeIn === 'yes',
        },
        insurance: {
          creditLife: creditLife,
          creditLifeType: creditLifeType,
          creditLifeAmount: currencyToNumber(creditLifeAmount),
          vehicleInsurance: vehicleInsuranceType,
          vehicleInsuranceAmount: currencyToNumber(vehicleInsuranceAmount),
          gapFee: currencyToNumber(gapFee),
          dealer: dealerOpt?.find((opt) => opt.value === dealer)?.value || '',
        },
      };

      setCalcReqResp({
        calrequest: calculatedDataResp,
        calresponse: location?.state?.data?.calculatedData,
      });
      let dateString = contractDate;
      let dateMomentObject = moment(dateString, 'MM/DD/YYYY');
      dateObject = dateMomentObject.toDate();
      if (creditLife?.toLowerCase() === 'yes' || gapFee || vehicleInsuranceType) {
        setValue('isInsuranceOpen', true);
      } else {
        setValue('isInsuranceOpen', false);
      }
      setValue('programVal', removeNullValues(programVal));
      setValue('newOrUsed', removeNullValues(newOrUsed));
      setValue('term', removeNullValues(term));
      setValue('balloonPay', removeNullValues(location?.state?.data?.baloonPayment));

      setValue('vehicleClass', removeNullValues(vehicleClass));
      setValue('rate', removeNullValues(rate));

      setValue('contractDate', removeNullValues(dateObject));
      setValue('salePrice', removeNullValues(salePrice));
      setValue('cashDown', removeNullValues(cashDown));
      setValue('rebate', removeNullValues(rebate));
      setValue('tradeDept', removeNullValues(tradeDept));
      setValue('tradeAllowance', removeNullValues(tradeAllowance));
      setValue('netTrade', removeNullValues(netTrade));
      setValue('carCare', removeNullValues(carCare));
      setValue('other', removeNullValues(other));
      setValue('serviceContract', removeNullValues(serviceContract));
      setValue('extendedWarranty', removeNullValues(extendedWarranty));
      setValue('paintProtection', removeNullValues(paintProtection));
      setValue('year', removeNullValues(year));
      setValue('make', removeNullValues(make));
      setValue('model', removeNullValues(model));
      setValue('balance', removeNullValues(balance));
      setValue('creditLife', removeNullValues(creditLife));
      setValue('creditLifeAmount', removeNullValues(creditLifeAmount));
      setValue('creditLifeType', removeNullValues(creditLifeType));
      setValue('joint', removeNullValues(joint));
      setValue('criticalIllness', removeNullValues(criticalIllness));
      setValue('hospitalization', removeNullValues(hospitalization));
      setValue('gapFee', removeNullValues(gapFee));
      setValue('year', removeNullValues(year));
      setValue('make', removeNullValues(make));
      setValue('model', removeNullValues(model));
      setValue('wishToTradeIn', removeNullValues(wishToTradeIn));
      await setInsuranceType(vehicleInsuranceType);

      setValue('vehicleInsuranceAmount', vehicleInsuranceAmount);
      setValue('registrationfee', removeNullValues(registrationfee));
      setValue('monthDeferred', removeNullValues(monthDeferred));
      setValue('financed', removeNullValues(financed));
      setValue('amountFinanced', removeNullValues(amountFinanced));
      setRenderedFlag(true);
    } else {
      setProgram('');
      setVehicleType('');
      setRenderedFlag(true);
    }
    if (location?.state?.data?.formData?.contractDate) {
      setValue('contractDate', removeNullValues(dateObject));
    } else {
      setValue('contractDate', new Date());
    }
  }, [createAppFlag]);
  console.log({ watchVehicleInsuranceAmount });
  useEffect(() => {
    handleFocusError({ errors, setFocus });
  }, [errors]);
  const setInsuranceType = async (vehicleInsuranceType) => {
    setValue('vehicleInsuranceType', removeNullValues(vehicleInsuranceType));
  };
  useEffect(() => {
    if (watchCreditLife.toLowerCase() !== 'yes' && rendered) {
      setValue('creditLifeAmount', '0');
      setValue('creditLifeType', '');
      clearErrors(['creditLifeAmount', 'creditLifeType']);
    }
  }, [watchCreditLife, rendered]);

  const handleVehicleInsuranceAmtChange = () => {
    setValue('vehicleInsuranceAmount', '');

    clearErrors('vehicleInsuranceAmount');
  };
  const resposeFormatter = (termListt, programm, newUsedVal) => {
    if (programm === 'conventional' && newUsedVal === 'true') {
      return JSON.parse(termListt?.['term-conventional-new']);
    } else if (programm === 'conventional' && newUsedVal === 'false') {
      return JSON.parse(termListt?.['term-conventional-used']);
    } else if (programm === 'Oriental A%2B') {
      return JSON.parse(termListt?.['term-oriental']);
    }
  };
  useEffect(async () => {
    let programm = getValues('programVal') === 'Conventional' ? 'conventional' : 'Oriental A%2B';
    let newUsedVal = getValues('newOrUsed')?.toLowerCase();
    let TermsUrl = `${UrlHelpers.masterlookup}?program=${programm}&newUsed=${newUsedVal}`;
    const serviceUrl = createAppFlag ? TermsUrl + `&year=${vehicleYear}` : TermsUrl;
    if (watchProgram && watchNewUsed) {
      try {
        setLoader(true);
        let termListt = await ApiClient.get(serviceUrl);

        termListt = await resposeFormatter(termListt, programm, newUsedVal);

        termListt = termListt?.map((x) => {
          const { displayName, value } = x;
          return { name: displayName, value };
        });
        if (termListt.findIndex((x) => x.value == watchTerm) === -1) {
          setValue('term', '');
        }
        setTermList(termListt);
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoader(false);
      }
    }
    if (watchProgram === 'Conventional') {
      setValue('balloonPay', '');
    }
  }, [watchProgram, watchNewUsed]);
  console.log('rolebasedDealers', rolebasedDealers);
  useEffect(async () => {
    try {
      setLoader(true);
      const onLoadData = await payCalcOnLoadApi({ rolebasedDealers, dealer, createAppFlag });
      if (onLoadData) {
        setBasicVehicleDetailData({ promotions: onLoadData });
        let promotionAvailable = onLoadData?.find(
          (x) => x?.promotionId === location?.state?.data?.formData?.promotion
        );
        console.log({ promotionAvailable });
        let prom = promotionAvailable ? location?.state?.data?.formData?.promotion : '';
        if (promotionAvailable) {
          setValue('promotion', prom);
        } else if (location?.state?.data?.formData?.promotion && !promotionAvailable) {
          setIsPromotionExpired(true);
        }
      }
      if (!commonToken) {
        const token = await getAccessToken();

        setCommonToken(token);
      }
    } catch (e) {
      console.log('error fetching', e);
    } finally {
      setLoader(false);
    }

    try {
      await payCalcApis({
        monthsDeferredList,
        setMonthsDeferredList,
        newUsedList,
        setNewUsedList,
        programList,
        setProgramList,
        makeList,
        setMakeList,
        setTermList,
        program,
        vehicleType,
        vehicleYear,
        creditLifeTypeList,
        setCreditLifeTypeList,
        creditLifeList,
        setCreditLifeList,
        vehicleInsuranceTypeList,
        setVehicleInsuranceTypeList,
      });
    } catch (e) {
      console.log('error fetching', e);
    } finally {
      setLoader(false);
    }
  }, []);

  const handleCalculation = async (data) => {
    const getFormValues = getValues();
    console.log('test', getFormValues);

    const {
      term,
      newOrUsed,
      programVal,
      vehicleClass,
      contractDate = moment(contractDate).format('MM/DD/yyyy'),
      rate,
      balloonPay,
      salePrice,
      cashDown,
      rebate,
      carCare,
      other,
      serviceContract,
      tradeDept,
      tradeAllowance,
      extendedWarranty,
      paintProtection,
      creditLife,
      creditLifeAmount = currencyToNumber(creditLifeAmount),
      vehicleInsuranceAmount = currencyToNumber(vehicleInsuranceAmount),
      vehicleInsuranceType,
      gapFee = currencyToNumber(gapFee),
      creditLifeType,
      monthDeferred,
      year,
      make,
      model,
      wishToTradeIn,
    } = data;
    setProgram(programVal);

    const paycalcReq = {
      applicationId: createAppFlag ? createAppApplicationId : '',
      term: +term,
      newUsed: newOrUsed,
      program: programVal,
      promotion: {
        name: basicVehicleDetailData?.promotions[0]?.name,
        value: basicVehicleDetailData?.promotions[0]?.value,
        rate: currencyToNumber(rate),
      },
      baloonPayment: currencyToNumber(balloonPay),
      vehicleClass: vehicleClass,
      contractDate: moment(contractDate).format('MM/DD/yyyy'),
      monthDeferred: monthDeferred,
      rate: rate && currencyToNumber(rate),
      itemizationOfAmount: {
        salePrice: currencyToNumber(salePrice),
        cashDown: currencyToNumber(cashDown),
        rebate: currencyToNumber(rebate),
        carCare: currencyToNumber(carCare),
        other: currencyToNumber(other),
        serviceContact: currencyToNumber(serviceContract),
        tradeInDebt: currencyToNumber(tradeDept),
        tradeInAllowance: currencyToNumber(tradeAllowance),
        extendedWarranty: currencyToNumber(extendedWarranty),
        paintProtection: currencyToNumber(paintProtection),
        tradeInVehicleYear: year,
        tradeInVehicleMake: make,
        tradeInVehicleModel: model,
        wishToTradeIn: wishToTradeIn === 'yes' ? true : false,
      },
      insurance: {
        creditLife: creditLife,
        creditLifeType: creditLifeType,
        creditLifeAmount: currencyToNumber(creditLifeAmount),
        vehicleInsurance: vehicleInsuranceType,
        vehicleInsuranceAmount: currencyToNumber(vehicleInsuranceAmount),
        gapFee: currencyToNumber(gapFee),
        dealer: dealerOpt?.find((opt) => opt.value === data?.dealer)?.value || '',
      },
    };
    console.log('dates', moment(contractDate).format('MM/dd/yyyy'));
    console.log('dates', contractDate);
    try {
      setLoader(true);
      let calculate = await ApiClient.post(
        UrlHelpers.payCalcCalculate,
        paycalcReq,

        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      setCalcReqResp({ calrequest: paycalcReq, calresponse: calculate });
      await processCalculatedData({ calculate, dealer, data, gapFee, getFormValues });
      setApiErr('');
      setIsPromotionExpired(false);
    } catch (e) {
      console.log('error fetching', e);
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later'
      );
    } finally {
      setLoader(false);
    }
  };

  const processCalculatedData = async ({ calculate, data, gapFee, getFormValues }) => {
    if (calculate) {
      let getFormValue = getFormValues;

      setDealer(data.dealer);
      if (
        watchVehicleInsuranceType?.toLowerCase() === 'full' ||
        watchVehicleInsuranceType?.toLowerCase() === 'single'
      ) {
        setCalculateData(calculate);
      } else {
        setCalculateData({
          ...calculate,
          vehicleInsuranceAmount: calculate?.vehicleInsuranceAmount,
        });
      }
      setValue('registrationfee', calculate?.registrationFees);
      getFormValue.registrationfee = calculate?.registrationFees;
      if (
        watchVehicleInsuranceType?.toLowerCase() === 'full' ||
        watchVehicleInsuranceType?.toLowerCase() === 'single'
      ) {
        setValue('vehicleInsuranceAmount', calculate?.vehicleInsuranceAmount);
        getFormValue.vehicleInsuranceAmount = calculate?.vehicleInsuranceAmount;
      }
      if (watchCreditLife?.toLowerCase() === 'yes') {
        setValue('creditLifeAmount', calculate?.creditLifeInsuranceAmount);
        getFormValue.creditLifeAmount = calculate?.creditLifeInsuranceAmount;
      }
      setOldFormData(getFormValue);
      let formDatas = { ...data };
      console.log({ formDatas });

      setFormData({
        ...formDatas,
        vehicleInsuranceAmount:
          watchVehicleInsuranceType.toLowerCase() === 'full' ||
          watchVehicleInsuranceType.toLowerCase() === 'single'
            ? currencyToNumber(calculate?.vehicleInsuranceAmount)
            : currencyToNumber(watchVehicleInsuranceAmount),
        creditLifeAmount:
          watchCreditLife.toLowerCase() === 'yes'
            ? currencyToNumber(calculate?.creditLifeInsuranceAmount)
            : '0',
        gapFee: currencyToNumber(gapFee),
      });
    }
  };
  const handleEmptyValue = (val) => {
    if (val && val !== '0') return val;
    return '';
  };
  const handleComputeError = async () => {
    let createAppDisabled = false;
    console.log({ date5: calculateData });
    if (Object.entries(calculateData)?.length > 0) {
      if (location?.state?.flow === 'financialTerms') {
        Object.keys(watchAll).forEach((x) => {
          createAppDisabled = validateonContractDate({ createAppDisabled, x });
        });
      } else {
        Object.keys(watchAll).forEach((x) => {
          if (watchAll[x]?.toString() !== oldFormData[x]?.toString()) {
            console.log('yes Disabled');
            createAppDisabled = true;
            console.log({ date3: watchAll[x]?.toString(), date4: oldFormData[x]?.toString() });
          }
        });
      }
    }
    return createAppDisabled;
  };
  console.log({ formData });

  const validateonContractDate = ({ createAppDisabled, x }) => {
    let createAppDisabledCopy = createAppDisabled;
    if (x === 'contractDate') {
      let a = moment(watchAll[x]).format('MM/DD/yyyy');
      let b = moment(oldFormData[x])?.format('MM/DD/yyyy');
      if (a !== b) {
        createAppDisabledCopy = true;
        console.log({ date1: a, b });
      }
    } else {
      let a = watchAll[x]?.toString()?.includes('$')
        ? currencyToNumber(watchAll[x]?.toString())
        : watchAll[x]?.toString();
      let b = oldFormData[x]?.toString()?.includes('$')
        ? currencyToNumber(oldFormData[x]?.toString())
        : oldFormData[x]?.toString();
      if (handleEmptyValue(a) !== handleEmptyValue(b)) {
        createAppDisabledCopy = true;
        console.log({ date2: a, b });
      }
    }
    return createAppDisabledCopy;
  };

  const handleCreateApplication = async () => {
    const createAppDisabled = await handleComputeError();
    console.log({ watchContractDate, date: new Date().setHours(0, 0, 0, 0) });
    if (watchContractDate < new Date().setHours(0, 0, 0, 0)) {
      setError(`contractDate`, {
        type: 'custom',
        message: 'Contract date cannot be in the past',
      });
    } else if (createAppDisabled && Object.entries(calculateData)?.length > 0) {
      setApiErr('Please click on calculate for deal terms with updated inputs');
    } else {
      setPopupStep(0);
      setCreateAppLookup('calc');
      setCreateAppApplicationId('');
      setCreateAppFlag(true);
      setCreateAppApplicationId('');
      setCreateAppLaunchType('payCalc');
      clearErrors('contractDate');
      setApiErr('');
      setLookupSaved(false);
    }
  };
  useEffect(() => {
    if (!createAppFlag) {
      reset();
      setCalculateData({});
      setFormData({});
      navigate('/paymentCalculator', {
        state: {},
      });
      setFinanceInfo({});
    }
  }, [createAppFlag]);

  const printPdf = async () => {
    let printPayload;
    try {
      const disAllowPrint = await handleComputeError();
      if (disAllowPrint) {
        setApiErr('Please click on calculate for deal terms with updated inputs');
      } else {
        setLoader(true);
        printPayload = { ...calcReqResp };
        printPayload.calresponse.paymentSchedules = printPayload?.calresponse?.paymentSchedules.map(
          (x) => ({
            ...x,
            startDate: moment(x?.startDate)?.format('MM/DD/yyyy'),
          })
        );
      }

      let printData = await ApiClient.post(UrlHelpers?.printPaymentCalc, printPayload, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (printData) {
        generatePdf(printData);
      }
    } catch (e) {
      console.log('error fetching', e);
      setApiErr('Failed to load pdf file');
    } finally {
      setLoader(false);
    }
  };
  const tabRef = useRef(null);
  useEffect(() => {
    if (isPromotionExpired) {
      tabRef.current.focus();
    }
  }, [isPromotionExpired]);
  const generatePdf = (base64) => {
    let byteCharacters = window.atob(base64);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: 'application/pdf;base64' });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  return (
    <>
      <Box mt={2.5}>
        <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />

        {isPromotionExpired && (
          <Alert
            sx={{
              marginTop: '8px',
              alignItems: 'center',
              fontSize: '14px !important',
            }}
            severity="info"
            action={
              <IconButton
                ref={tabRef}
                aria-label="close"
                color="inherit"
                size="small"
                data-testid="close-btn"
                onClick={() => {
                  setIsPromotionExpired(false);
                }}
              >
                <GridCloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            The expired promotion has been de-selected, please review and select another promotion
            if applicable
          </Alert>
        )}
      </Box>
      <Paper className={classes.payCalcPaper} sx={{ padding: '10px' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Typography variant="h3" component="h3" my={2}>
            Payment calculator
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <Box
              display="flex"
              onClick={printPdf}
              data-testid="print"
              tabIndex="0"
              sx={{
                cursor: Object.entries(calcReqResp)?.length && 'pointer',
                opacity: Object.entries(calcReqResp)?.length ? 1 : 0.5,
              }}
              disabled={!Object.entries(calcReqResp)?.length}
            >
              <img src={printIcon} alt="Print" />
              <Link
                sx={{ marginLeft: '2px' }}
                component="button"
                variant="subtitle2"
                color="secondary"
                disabled={!Object.entries(calcReqResp)?.length}
              >
                Print
              </Link>
            </Box>
            {!location?.state?.flow && !!Object.entries(calculateData)?.length && (
              <Button
                variant="contained"
                color="secondary"
                id="calc-create-app-btn"
                data-testid="calc-create-app-btn"
                onClick={() => handleCreateApplication()}
                // disabled={isCreateAppDisabled}
              >
                Create application
              </Button>
            )}
          </Box>
        </Box>

        <Grid container className={classes.payCalc} justifyContent="space-between">
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Grid container justifyContent="center">
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <BasicVehicleDetail
                  setValue={setValue}
                  control={control}
                  errors={errors}
                  optionsData={basicVehicleDetailData}
                  trigger={trigger}
                  flow={location?.state}
                />
                <FinancedAmount
                  setValue={setValue}
                  control={control}
                  errors={errors}
                  makeList={makeList}
                />
                <Insurance
                  control={control}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                  trigger={trigger}
                  handleVehicleInsuranceAmtChange={handleVehicleInsuranceAmtChange}
                  // isInsuranceOpen={isInsuranceOpen}
                />

                {/* Deferred payment */}
                <div>
                  <CustomCollapse heading={deferredPayment} open={false} errors={errors}>
                    <Grid container spacing={2}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomSelectBox
                          label="Months deferred"
                          control={control}
                          name="monthDeferred"
                          flexDirection="column"
                          errors={!!errors.monthDeferred}
                          errorText={errors?.monthDeferred?.message}
                          options={monthsDeferredList}
                          defaultValue="1"
                        />
                      </Grid>
                    </Grid>
                  </CustomCollapse>
                </div>

                <Loader open={loader} />
                {/* Buttons */}
                <Box display="flex" justifyContent="end" gap={3} py={5} px={2} mb={3}>
                  <Button
                    onClick={handleSubmit(handleCalculation)}
                    id="compute-btn"
                    data-testid="calculate"
                    variant="contained"
                    color="secondary"
                  >
                    Calculate
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <ResultPage
              state={{
                data: calculateData,
                baloonPayment: getValues('balloonPay'),
                flowData: location?.state,
                formData: formData,
                apr: getValues('rate'),
              }}
              watch={watch}
              getValues={getValues}
              apiErr={apiErr}
              setApiErr={setApiErr}
              handleComputeError={handleComputeError}
              errors={errors}
              setIsPromotionExpired={setIsPromotionExpired}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default PaymentCalculator;
