import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import 'styles/Requirement.module.scss';
import { defaultFn } from 'utils/utility';

export const Requirement = ({ value, requirement }) => {
  const [isValid, setIsValid] = useState();
  console.log({ value, requirement });
  useEffect(() => {
    setIsValid(requirement.validator(value));
  }, [value, requirement]);

  return (
    <div className="requirement">
      <div
        className={isValid ? 'valid' : 'invalid'}
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 5,

          // border: '2px solid red'
        }}
      >
        <div>
          {isValid ? (
            <CheckCircleOutlineIcon
              style={{ width: '0.7em', height: '0.7em', marginRight: '5px', color: '#128A08' }}
            />
          ) : (
            <RadioButtonUncheckedIcon
              style={{ width: '0.7em', height: '0.7em', marginRight: '5px', color: '#000000' }}
            />
          )}
        </div>
        <div style={{ fontSize: '14px' }}>{requirement.text}</div>
      </div>
    </div>
  );
};
Requirement.propTypes = {
  invitationExpiryCheck: PropTypes.func,
  value: PropTypes.string,
  requirement: PropTypes.instanceOf(Object),
};
Requirement.defaultProps = {
  invitationExpiryCheck: defaultFn,
  value: '',
  requirement: {},
};
