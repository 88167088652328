import { Box, Button, Grid, Link } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import { getAccessToken } from 'Services/commonComponentsApi';
import { fetchAddressLookup } from 'commonComponents/commonComponents';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import debounceLodash from 'lodash.debounce';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FormatValidationSchema } from 'schemaValidations/InformationRequestSchema';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { currencyToNumber } from 'utils';
import { defaultFn, handleError, updateCompletedSteps } from 'utils/utility';

import Loader from 'components/Loader/Loader';

import CancelEditPopup from '../CancelEditPopup';
import IdVerification from '../IdVerification/IdVerification';
import AddCoUsers from '../InformationRequest/AddCoUsers/AddCoUsers';
import BasicInformation from '../InformationRequest/BasicInformation/BasicInformation';
import MailingAddress from '../InformationRequest/MailingAddress/MailingAddress';
import ResidentStatus from '../InformationRequest/ResidentStatus/ResidentStatus';
import ResidentialAddress from '../InformationRequest/ResidentialAddress/ResidentialAddress';
import RelationWithOwner from '../InformationRequest/moduleUtilities/RelationWithOwner';
import { step1DropDownApis } from '../InformationRequest/moduleUtilities/dropdownApis';
import ReOpenApplicationFooter from '../ReOpenApplicationFooter';
import { submitApi, viewApplication } from './moduleUtilities/moduleUtilities';

const InformationRequest = ({
  control,
  prefixName,
  errors,
  getValues,
  setValue,
  clearErrors,
  couserIndex,
  dirtyFields,
  setError,
  fields,
  append,
  remove,
  insert,
  tabvalue,
  handleSubmit,
  setTabValue,
  validateAll,
  savedApplicantIds,
  trigger,
  callDraft,
  setCallDraft,
  callbackApi,
  initApplicantIds,
  setApiErr,
  swap,
  idVerificationPendingText,
}) => {
  const {
    commonToken,
    setCommonToken,
    cityList,
    cityZipList,
    setCityZipList,
    residentStatusList,
    setResidentStatusList,
    relationshipList,
    setRelationShipList,
    maritalStatusList,
    setMaritalStatusList,
    utilityBillList,
    setUtilityBillList,
    setOpenDiscardModal,
    createAppApplicationId,
    userID,
    setCreateAppFlag,
    setCreateAppLaunchType,
    setDealer,
    fullName,
    stateList,
    setStateList,
    stateCityList,
    setstateCityList,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const ApiClient = new ApiClass();
  const [addressLookupData, setAddressLookupData] = useState([]);
  const [addressLookupDataResponse, setAddressLookupDataResponse] = useState([]);
  const [loader, setLoader] = useState(false);
  const [mailAddressLookupData, setMailAddressLookupData] = useState([]);
  const [mailAddressLookupDataResponse, setMailAddressLookupDataResponse] = useState([]);
  const [showCamera, setShowCamera] = useState(false);
  const [showIDCamera, setShowIDCamera] = useState(false);
  const [isMounted, setisMounted] = useState(false);
  const [openCancelAlert, setOpenCancelAlert] = useState(false);
  const [resCityList, setResCityList] = useState([]);
  const [perCityList, setPerCityList] = useState([]);
  const [oldResState, setOldResState] = useState('');
  const [oldPerState, setOldPerState] = useState('');
  const {
    setActiveStep,
    reviewFlag,
    setOpenDraftModal,
    setDraftCheckFlag,
    completedSteps,
    setCompletedSteps,
    setAddUserTabValue,
    viewAppFlow,
    setAppSubmitted = defaultFn,
    setCaseReferenceId = defaultFn,
    unfilledId,
    setAddedApplicantFlag,

    viewAppLoanStatus,
    setVouchedReturnFlag,
  } = useContext(MainApplicationContext);
  const [
    watchresAddress1,
    watchresAddress2,
    watchresCity,
    // watchresState,
    watchresZipcode,
    watchvouchedAddress1,
    watchvouchedAddress2,
    watchvouchedCity,
    watchvouchedZipcode,
    watchperAddress1,
    watchperAddress2,
    watchperCity,
    watchperZipcode,
    isAddrSame,
    watchdob,
    watchssn,
    watchPrimaryFirstName,
    watchPrimaryLastName,
    watchcousers,
    // watchperState,
    // watchUtilityBillFile,
    watchPrimaryUtilityBill,
    watchPrimaryState,
  ] = useWatch({
    control,
    name: [
      `${prefixName}.resAddress1`,
      `${prefixName}.resAddress2`,
      `${prefixName}.resCity`,
      // `${prefixName}.resState`,
      `${prefixName}.resZipcode`,
      `${prefixName}.vouchedAddress1`,
      `${prefixName}.vouchedAddress2`,
      `${prefixName}.vouchedCity`,
      `${prefixName}.vouchedZipcode`,
      `${prefixName}.perAddress1`,
      `${prefixName}.perAddress2`,
      `${prefixName}.perCity`,
      `${prefixName}.perZipcode`,
      `${prefixName}.isAddrSame`,
      `${prefixName}.dob`,
      `${prefixName}.ssn`,
      'cousers[0].firstName',
      'cousers[0].lastName',
      'cousers',
      // `${prefixName}.perState`,
      // 'cousers[0].utilitybillfile',
      `cousers[0].resUtilityBill`,
      `cousers[0].resState`,
    ],
  });
  console.log('unfilledId', unfilledId);
  useEffect(async () => {
    if (!commonToken) {
      const token = await getAccessToken();

      setCommonToken(token);
    }

    await step1DropDownApis({
      stateCityList,
      stateList,
      cityList,
      setstateCityList,
      setStateList,
      cityZipList,
      setCityZipList,
      residentStatusList,
      setResidentStatusList,
      maritalStatusList,
      setMaritalStatusList,
      relationshipList,
      setRelationShipList,
      utilityBillList,
      setUtilityBillList,
    });
    setisMounted(true);
  }, []);

  useEffect(() => {
    if (viewAppFlow && idVerificationPendingText?.includes('utility')) {
      setValue(`${prefixName}.showUtility`, true);
    }
  }, [idVerificationPendingText]);
  useEffect(async () => {
    if (callDraft && !viewAppFlow) {
      const isValid = await draftClick({ tabSwitch: true });
      if (Object.entries(errors).length === 0) {
        window.scroll({
          top: 408,
          left: 0,
          behavior: 'smooth',
        });
      }
      setCallDraft(false);
      if (isValid) {
        await callbackApi();
      }
    }
  }, [callDraft]);

  const debounceFunc5 = debounceLodash((address1, country, query5, commonTokenn, addressType) => {
    fetchAddressLookup({
      address1,
      country,
      query5,
      commonTokenn,
      addressType,
      setAddressLookupData,
      setAddressLookupDataResponse,
      setMailAddressLookupData,
      setMailAddressLookupDataResponse,
    });
  }, 500);

  const fetchAddress5 = useCallback(debounceFunc5, []);

  const draftValidCheck = (formValue) => {
    let isValid = false;
    try {
      isValid = FormatValidationSchema.isValidSync(formValue, { abortEarly: false });
      console.log('isValid', isValid);
    } catch (err) {
      console.log('err in', err);
      return isValid;
    }
    return isValid;
  };
  const isdraftDisabled =
    Object.keys(dirtyFields)?.length === 0 && !watchssn && !watchPrimaryFirstName;
  const draftClick = async ({ tabSwitch = false }) => {
    const formValue = getValues();
    let isValid = false;
    console.log('formValue', formValue);
    try {
      isValid = draftValidCheck(formValue);
      if (!isValid && !tabSwitch) {
        setApiErr(' Please enter the field completely and try saving again.');
      } else if (isValid) {
        setApiErr('');
        await handleInfoSubmit({}, { draft: true, tabSwitch });
      }
      if ((!watchPrimaryFirstName || !watchPrimaryLastName) && !tabSwitch) {
        setApiErr(
          'First name and Last name of applicant need to be completed to save the application as draft.'
        );
        isValid = false;
      }
      return isValid;
    } catch (err) {
      console.log('err in', err);
    }
  };

  const infoObj = (index) => {
    const participantDetailInfo = getParticipantDetailInfo(index);
    const infoObjj = {
      informationRequest: {
        participantInfo: {
          customerName: {
            firstName: watchcousers[index]?.firstName,
            middleName: watchcousers[index]?.middleName,
            lastName: watchcousers[index]?.lastName,
            secondLastName: watchcousers[index]?.secondLastName,
          },
          contacts: {
            mobile: watchcousers[index]?.mobile ? `${watchcousers[index]?.mobile}` : '',
            alternatePhoneNumber: watchcousers[index]?.mobile2
              ? `${watchcousers[index]?.mobile2}`
              : '',
            applicantEmail: watchcousers[index]?.email,
          },
        },
        participantDetailInfo: { ...participantDetailInfo },
      },
    };
    if (index !== 0) {
      infoObjj.informationRequest.participantDetailInfo.relationshipWithOwner =
        watchcousers[index]?.relationship;
    }
    return infoObjj;
  };
  const primaryApplicantAddress = {
    addrType: 'Home address',
    lineOne: watchcousers[0]?.resAddress1 || '',
    lineTwo: watchcousers[0]?.resAddress2 || '',
    addrCity: watchcousers[0]?.resCity || '',
    addrState: watchcousers[0]?.resState,
    addrCountry: 'United States',
    addrCountryCode: 'USA',
    zipCode: watchcousers[0]?.resZipcode || '',
    utilityBill: watchcousers[0]?.resUtilityBill,
  };

  const primayApplicantMailingAddress = {
    addrType: 'Mailing address',
    lineOne: watchcousers[0]?.perAddress1 || '',
    lineTwo: watchcousers[0]?.perAddress2 || '',
    addrCity: watchcousers[0]?.perCity || '',
    addrState: watchcousers[0]?.perState,
    addrCountry: 'United States',
    addrCountryCode: 'USA',
    zipCode: watchcousers[0]?.perZipcode || '',
  };
  const documentInfo = ({ index }) => {
    return {
      vouchedIdStatus: watchcousers[index]?.idVerificationStatus === 'success',
      identityDocId: watchcousers[index]?.jobId ? null : watchcousers[index]?.identityDocId,
      vouchedJobId:
        watchcousers[index]?.idVerificationStatus === 'success' ? watchcousers[index]?.jobId : '',
      drivingLicenseId: watchcousers[index]?.drivingLicenseId || '',
      licenseExpirationDate: watchcousers[index]?.licenseExpirationDate || '',
      licenseIssueState: watchcousers[index]?.licenseIssueState || '',
      manualUploadFlag: !!watchcousers[index]?.manualFile,
      manualUploadFile: watchcousers[index]?.manualFile?.name,
      ocrFlag: !!watchcousers[index]?.utilitybillfile,
      ocrDocumentIdList: watchcousers[index]?.ocrDocumentIdList,
      ocrDocumentId: watchcousers[index]?.utilitybillfile ? watchcousers[index]?.ocrDocumentId : '',
      ocrFileName: watchcousers[index]?.utilitybillfile?.name,
    };
  };

  const dobObj = ({ index }) => {
    return watchcousers[index]?.dob && moment(watchcousers[index]?.dob).isValid
      ? moment(watchcousers[index]?.dob).format('MM/DD/YYYY')
      : '';
  };

  const getresAddress = ({ index }) => {
    return {
      addrType: 'Home address',
      lineOne: watchcousers[index]?.resAddress1 || '',
      lineTwo: watchcousers[index]?.resAddress2 || '',
      addrCity: watchcousers[index]?.resCity || '',
      addrState: watchcousers[index]?.resState || '',
      addrCountry: 'United States',
      addrCountryCode: 'USA',
      zipCode: watchcousers[index]?.resZipcode || '',
      utilityBill: watchcousers[index]?.utilitybillfile?.name
        ? watchcousers[index]?.resUtilityBill
        : '',
    };
  };

  const getPerAddress = ({ index }) => {
    return {
      addrType: 'Mailing address',
      lineOne: watchcousers[index]?.perAddress1 || '',
      lineTwo: watchcousers[index]?.perAddress2 || '',
      addrCity: watchcousers[index]?.perCity || '',
      addrState: watchcousers[index]?.perState || '',
      addrCountry: 'United States',
      addrCountryCode: 'USA',
      zipCode: watchcousers[index]?.perZipcode || '',
    };
  };
  const getParticipantDetailInfo = (index) => {
    const documentInfoObj = documentInfo({ index });
    return {
      gender: watchcousers[index]?.gender || '',
      dateOfBirth: dobObj({ index }),

      maritalStatus: watchcousers[index]?.maritalStatus,
      socialSecurityNumber: watchcousers[index]?.ssn?.replaceAll('-', ''),
      addresses: [
        ...(watchcousers[index]?.isAddressCopiedFromApplicant === true && index != 0
          ? [primaryApplicantAddress]
          : [{ ...getresAddress({ index }) }]),
        ...(watchcousers[index]?.isAddressCopiedFromApplicant === true &&
        watchcousers[index]?.isAddrSame === true &&
        index != 0
          ? [primayApplicantMailingAddress]
          : [{ ...getPerAddress({ index }) }]),
      ],
      residentialStatus: watchcousers[index]?.residentStatus,
      monthlyPayment: currencyToNumber(watchcousers[index]?.monthlyPayment),
      timeOfResidenceYears: watchcousers[index]?.residentYears,
      timeOfResidenceMonths: watchcousers[index]?.residentMonths,
      ...documentInfoObj,
      isAddressCopiedFromApplicant: watchcousers[index]?.isAddressCopiedFromApplicant,
    };
  };

  const onCancelEditSubmitted = async () => {
    setOpenCancelAlert(true);
  };

  const onConfirmCancel = async () => {
    setCreateAppFlag(false);
    setDealer('');
    setOpenCancelAlert(false);
    await viewApplication({ setLoader, navigate, createAppApplicationId, userID, fullName });
  };

  const updateSubmitted = async () => {
    if (watchPrimaryUtilityBill === 'Others' && watchPrimaryState !== 'PR') {
      setValue(`${prefixName}.utilitybillfile`, '');
      setError(`${prefixName}.utilitybillfile`, {
        type: 'custom',
        message:
          ' Utility bill other than LUMA,AAA is uploaded. Please review the state and address populated above',
      });
    } else {
      await handleInfoSubmit({}, { updateFlag: true });
    }
  };
  const continueSubmitted = async () => {
    if (watchPrimaryUtilityBill === 'Others' && watchPrimaryState !== 'PR') {
      setValue(`${prefixName}.utilitybillfile`, '');
      setError(`${prefixName}.utilitybillfile`, {
        type: 'custom',
        message:
          'Utility bill other than LUMA,AAA is uploaded. Please review the state and address populated above',
      });
    } else {
      await handleInfoSubmit({}, { updateFlag: false });
    }
  };

  const handleInfoSubmit = async (
    data,
    { draft = false, tabSwitch = false, updateFlag = false }
  ) => {
    console.log('handleInfoSubmit inside');
    let isValid = true;
    let origApplicantIds = [...savedApplicantIds];

    let initRenderIds = [...initApplicantIds];

    let newApplicantIds = watchcousers?.map((x) => x.applicantId);

    let removedApplicantIds = origApplicantIds.filter((x) => newApplicantIds.indexOf(x) === -1);
    let addedApplicantIds = newApplicantIds.filter((x) => initRenderIds.indexOf(x) === -1);

    const checkifFaultyidexist = watchcousers?.findIndex((x) => x.applicantId === unfilledId) > -1;
    if ((addedApplicantIds?.length || checkifFaultyidexist) && updateFlag) {
      isValid = false;
      setApiErr('Please complete employment information for the participant added');
    }
    console.log('isvalid', isValid);
    if (!draft && isValid) {
      console.log('before validate all call');
      isValid = await validateAll();
    }

    if (isValid || draft) {
      const primaryInfo = infoObj(0);
      let applicantData = {
        applicants: {
          isInvitationSent: false,
          applicantId: watchcousers[0]?.applicantId,
          ...primaryInfo,
        },
      };
      applicantData = fillcousersData({ applicantData });
      let payloadData = {
        event: 'InformationRequest',
        application: {
          products: {
            productType: 'Indirect Loan Account',
            productName: 'AutoLoanManual',
          },
          userId: userID,
          loanAppType: 'Personal',
          ...applicantData,
          applicationId: createAppApplicationId,
          createAppType: 'Manual',
          source: {
            language: 'EN',
            location: 'PR',
          },
        },
      };
      if (removedApplicantIds.length) {
        payloadData.application.deleteCoparticipants = true;
      }
      if (draft && !tabSwitch) {
        payloadData.application.landingUserType = watchcousers[tabvalue]?.userType;
      } else {
        payloadData.application.landingUserType = '';
      }
      await callInfoReqApi({ draft, payloadData, addedApplicantIds, tabSwitch, updateFlag });
    }
  };

  const callInfoReqApi = async ({
    draft,
    payloadData,
    addedApplicantIds,
    tabSwitch,
    updateFlag,
  }) => {
    try {
      setLoader(true);
      const endpoint = draft ? UrlHelpers.applicationDraft : UrlHelpers.informationReqSave;
      let calculate = await ApiClient.post(
        endpoint,
        { ...payloadData },
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );
      await decideNext({ calculate, draft, addedApplicantIds, tabSwitch, updateFlag });
    } catch (e) {
      if (e?.response?.status === 400) {
        handleError({ errResp: e?.response?.data, setTabValue, setError, user: 'cousers' });
      } else {
        setApiErr(
          e?.response?.data ||
            'We are unable to proceed due to a technical issue, please try again later'
        );
      }
    } finally {
      setLoader(false);
      setVouchedReturnFlag(false);
    }
  };
  const checkTab = ({ addedApplicantIds, decideTab }) => {
    if (addedApplicantIds?.length && reviewFlag && decideTab > -1) {
      setAddUserTabValue(decideTab);
    } else {
      setAddUserTabValue(0);
    }
  };

  const decideNext = async ({ calculate, draft, addedApplicantIds, tabSwitch, updateFlag }) => {
    setDraftCheckFlag(false);
    let decideTab = watchcousers.findIndex((x) => x.applicantId === addedApplicantIds[0]);
    const checkifFaultyidexist = watchcousers?.findIndex((x) => x.applicantId === unfilledId) > -1;
    console.log('checkFaultyIds', checkifFaultyidexist);
    checkTab({ addedApplicantIds, decideTab });
    if (!addedApplicantIds?.length && !checkifFaultyidexist) {
      setAddedApplicantFlag(false);
    }
    if (
      calculate &&
      !draft &&
      (!reviewFlag || addedApplicantIds?.length || checkifFaultyidexist) &&
      !updateFlag
    ) {
      setActiveStep(2);
      updateCompletedSteps([1], completedSteps, setCompletedSteps);
      setAddedApplicantFlag(true);
    } else if (calculate && !draft && reviewFlag && !addedApplicantIds.length && !viewAppFlow) {
      updateCompletedSteps([1, 2, 3, 4], completedSteps, setCompletedSteps);
      setActiveStep(5);
    } else if (calculate && !addedApplicantIds.length && viewAppFlow && !draft) {
      await handleResubmitApplication({ updateFlag });
    } else if (calculate && draft && !tabSwitch) {
      setOpenDraftModal(true);
    }
  };

  const handleResubmitApplication = async ({ updateFlag }) => {
    updateCompletedSteps([1, 2, 3, 4], completedSteps, setCompletedSteps);
    if (updateFlag) {
      await submitApi({
        createAppApplicationId,
        userID,
        setLoader,
        setCaseReferenceId,
        setAppSubmitted,
        setCreateAppFlag,
        setDealer,
        setCreateAppLaunchType,
        setApiErr,
        fullName,
      });
    } else {
      setActiveStep(5);
    }
  };

  const fillcousersData = ({ applicantData }) => {
    let applicantDataa = applicantData;
    const coappIndex = watchcousers.findIndex((x) => x.userType === 'Co-applicant');
    if (coappIndex > -1) {
      const coappInfo = infoObj(coappIndex);
      applicantDataa.applicants.coApplicants = [
        {
          isInvitationSent: false,
          applicantId: watchcousers[coappIndex]?.applicantId,
          ...coappInfo,
        },
      ];
    }
    const cosignerIndex = watchcousers.findIndex((x) => x.userType === 'Co-signer 1');
    if (cosignerIndex > -1) {
      const cosignerInfo = infoObj(cosignerIndex);
      applicantDataa.applicants.coSigners = [
        {
          isInvitationSent: false,
          applicantId: watchcousers[cosignerIndex]?.applicantId,
          ...cosignerInfo,
        },
      ];
      const cosignerIndex2 = watchcousers.findIndex((x) => x.userType === 'Co-signer 2');
      const cosignerInfo2 = infoObj(cosignerIndex2);
      if (cosignerIndex2 > -1) {
        applicantDataa.applicants.coSigners[1] = {
          isInvitationSent: false,
          applicantId: watchcousers[cosignerIndex2]?.applicantId,
          ...cosignerInfo2,
        };
      }
    }
    return applicantDataa;
  };

  useEffect(() => {
    if (!watchdob) {
      setValue(`${prefixName}.dob`, null);
    }
    if (isAddrSame === undefined) {
      setValue(`${prefixName}.isAddrSame`, true);
    }
  }, []);

  useEffect(() => {
    if (dirtyFields?.cousers?.[couserIndex]?.resAddress1 && commonToken) {
      let query5 = watchresAddress1;

      if (watchresAddress2) {
        query5 += ` ${watchresAddress2} `;
      }

      query5 += ` PR `;

      if (watchresCity) {
        query5 += ` ${watchresCity} `;
      }
      if (watchresZipcode) {
        query5 += ` ${watchresZipcode} `;
      }
      fetchAddress5(watchresAddress1, 'USA', query5, commonToken, 'res');
    }
  }, [watchresAddress1]);

  useEffect(() => {
    if (watchperAddress1 && commonToken && !isAddrSame) {
      let query5 = watchperAddress1;

      if (watchperAddress2) {
        query5 += ` ${watchperAddress2} `;
      }
      query5 += ` PR `;
      if (watchperCity) {
        query5 += ` ${watchperCity} `;
      }
      if (watchperZipcode) {
        query5 += ` ${watchperZipcode} `;
      }
      fetchAddress5(watchperAddress1, 'USA', query5, commonToken, 'mail');
    }
  }, [watchperAddress1]);

  useEffect(() => {
    if ((watchresAddress1 || watchresAddress2 || watchresCity || watchresZipcode) && isMounted) {
      if (
        watchresAddress1 !== watchvouchedAddress1 ||
        watchresAddress2 !== watchvouchedAddress2 ||
        watchresCity != watchvouchedCity ||
        watchresZipcode != watchvouchedZipcode
      ) {
        setValue(`${prefixName}.showUtility`, true);
      } else {
        setValue(`${prefixName}.showUtility`, false);
      }
    }
  }, [watchresAddress1, watchresAddress2, watchresCity, watchresZipcode]);

  return (
    <>
      <Loader open={loader} />
      <CancelEditPopup
        openModal={openCancelAlert}
        setOpenModal={setOpenCancelAlert}
        onConfirmCancel={onConfirmCancel}
      />
      <Grid container justifyContent="center">
        <IdVerification
          control={control}
          errors={errors}
          setValue={setValue}
          setError={setError}
          prefixName={prefixName}
          showIDCamera={showIDCamera}
          setShowIDCamera={setShowIDCamera}
          clearErrors={clearErrors}
          createAppApplicationId={createAppApplicationId}
          couserIndex={couserIndex}
          tabvalue={tabvalue}
          savedApplicantIds={savedApplicantIds}
          initApplicantIds={initApplicantIds}
          viewAppLoanStatus={viewAppLoanStatus}
        />
        <Box sx={{ width: '70%' }}>
          {couserIndex !== 0 && (
            <RelationWithOwner
              control={control}
              errors={errors}
              relationshipList={relationshipList}
              prefixName={prefixName}
              couserIndex={couserIndex}
            />
          )}
          <BasicInformation
            control={control}
            errors={errors}
            maritalStatusList={maritalStatusList}
            getValues={getValues}
            setValue={setValue}
            clearErrors={clearErrors}
            prefixName={prefixName}
            couserIndex={couserIndex}
            setError={setError}
            setLoader={setLoader}
            trigger={trigger}
          />
          <ResidentialAddress
            isMounted={isMounted}
            setResCityList={setResCityList}
            control={control}
            errors={errors}
            addressLookupData={addressLookupData}
            addressLookupDataResponse={addressLookupDataResponse}
            setValue={setValue}
            getValues={getValues}
            cityList={resCityList}
            clearErrors={clearErrors}
            isResidentAddressOpen={true}
            utilityBillList={utilityBillList}
            prefixName={prefixName}
            couserIndex={couserIndex}
            setError={setError}
            setLoader={setLoader}
            showCamera={showCamera}
            setShowCamera={setShowCamera}
            setApiErr={setApiErr}
            stateList={stateList}
            oldState={oldResState}
            setOldState={setOldResState}
            setOldPerState={setOldPerState}
          />

          {!isAddrSame && (
            <MailingAddress
              control={control}
              errors={errors}
              cityList={perCityList}
              addressLookupData={mailAddressLookupData}
              addressLookupDataResponse={mailAddressLookupDataResponse}
              setValue={setValue}
              clearErrors={clearErrors}
              prefixName={prefixName}
              couserIndex={couserIndex}
              setError={setError}
              setPerCityList={setPerCityList}
              isMounted={isMounted}
              oldState={oldPerState}
              setOldState={setOldPerState}
            />
          )}
          <ResidentStatus
            control={control}
            errors={errors}
            residentStatusList={residentStatusList}
            setValue={setValue}
            clearErrors={clearErrors}
            prefixName={prefixName}
            couserIndex={couserIndex}
            setError={setError}
          />

          {couserIndex === 0 && (
            <AddCoUsers
              control={control}
              setValue={setValue}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              fields={fields}
              append={append}
              remove={remove}
              getValues={getValues}
              setvalue={setValue}
              insert={insert}
              swap={swap}
            />
          )}
        </Box>
      </Grid>
      {!viewAppFlow && (
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" gap={2} py={4} marginLeft="auto">
            <Link
              component="button"
              variant="subtitle2"
              color="secondary"
              sx={{ fontSize: '16px' }}
              onClick={() => {
                setOpenDiscardModal(true);
              }}
              id="discard-app-btn"
            >
              Discard application
            </Link>
            <Button
              color="secondary"
              variant="outlined"
              onClick={draftClick}
              disabled={isdraftDisabled}
              id="save-as-draft-btn"
            >
              Save as draft
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSubmit(handleInfoSubmit)}
              id="continue-btn"
            >
              Continue
            </Button>
          </Box>
        </Box>
      )}
      {viewAppFlow && (
        <ReOpenApplicationFooter
          onCancelEditSubmitted={onCancelEditSubmitted}
          updateSubmitted={handleSubmit(updateSubmitted)}
          continueSubmitted={handleSubmit(continueSubmitted)}
        />
      )}
    </>
  );
};
export default InformationRequest;

InformationRequest.propTypes = {
  control: PropTypes.instanceOf(Object),
  prefixName: PropTypes.string,
  idVerificationPendingText: PropTypes.string,
  errors: PropTypes.instanceOf(Object),
  dirtyFields: PropTypes.instanceOf(Object),
  fields: PropTypes.instanceOf(Object),
  append: PropTypes.func,

  remove: PropTypes.func,
  tabvalue: PropTypes.number,
  handleSubmit: PropTypes.func,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  validateAll: PropTypes.func,
  setTabValue: PropTypes.func,
  setError: PropTypes.func,
  setLoader: PropTypes.func,
  savedApplicantIds: PropTypes.instanceOf(Object),

  trigger: PropTypes.func,
  callDraft: PropTypes.bool,
  setCallDraft: PropTypes.func,
  callbackApi: PropTypes.func,
  clearErrors: PropTypes.func,

  couserIndex: PropTypes.number,
  initApplicantIds: PropTypes.instanceOf(Object),
  setApiErr: PropTypes.func,
  insert: PropTypes.func,
  swap: PropTypes.func,
};
InformationRequest.defaultProps = {
  control: {},
  prefixName: '',
  idVerificationPendingText: '',
  errors: {},

  getValues: defaultFn,
  setTabValue: defaultFn,
  setValue: defaultFn,
  callDraft: false,
  callbackApi: defaultFn,
  setError: defaultFn,
  setLoader: defaultFn,
  showCamera: false,
  setCallDraft: defaultFn,
  clearErrors: defaultFn,
  trigger: defaultFn,
  couserIndex: 0,
  savedApplicantIds: [],
  validateAll: defaultFn,
  handleSubmit: defaultFn,
  initApplicantIds: [],
  setApiErr: defaultFn,
  insert: defaultFn,
  swap: defaultFn,
};
