/* eslint-disable react/prop-types */
import { Checkbox, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';

const CustomMultiSelect = ({
  selected,
  handleMultiSelctChange,
  isAllSelected,
  options,
  label,
  name,
  isStyled,
  className,
}) => {
  return (
    <Select
      labelId={label}
      label={label}
      name={name}
      id={name}
      data-testid="multi-select"
      multiple
      value={selected?.length ? selected : ['Select']}
      onChange={handleMultiSelctChange}
      input={<OutlinedInput />}
      inputProps={{ 'data-testid': name }}
      // renderValue={(selected) => selected.join(', ')}
      renderValue={(selectedd) => {
        console.log('selectedd', selectedd);
        const val = options
          ?.filter((dealer) => selectedd.includes(dealer.value))
          .map((i) => i.displayName);
        console.log('val', val);
        return val?.join(', ') || ['Select'];
      }}
      defaultValue={selected?.length === options?.length && 'Select all'}
      className={isStyled && className}
    >
      <MenuItem value="all">
        <Checkbox
          color="secondary"
          checked={isAllSelected}
          inputProps={{ 'data-testid': 'selectall-checkbox' }}
        />
        Select all
      </MenuItem>
      {options?.map((opt) => {
        const { displayName, value } = opt;
        return (
          <MenuItem key={value} value={value}>
            <Checkbox
              color="secondary"
              checked={selected?.indexOf(value) > -1}
              inputProps={{ 'data-testid': `select-${value}` }}
            />
            <ListItemText primary={displayName} />
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default CustomMultiSelect;
