import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Tab, Tabs } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { EmploymentInformationSchema } from 'schemaValidations/EmploymentInformationSchema';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { getEmpInfoObj } from 'utils/utility';

import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';
import TabPanel from 'components/TabPanel/TabPanel';

import EmploymentInformation from '../EmploymentInformation/EmploymentInformation';
import ApplicationPageHeading from '../InformationRequest/moduleUtilities/ApplicationPageHeading';
import { step2DropDownApis } from '../InformationRequest/moduleUtilities/dropdownApis';

const EmploymentInfoTabView = () => {
  const ApiClient = new ApiClass();
  const [callDraft, setCallDraft] = useState(false);
  const [apiErr, setApiErr] = useState('');
  const [initcouserArray, setInitCouserArray] = useState([]);
  const {
    occupationList,
    setOccupationList,
    empStatusList,
    setEmpStatusList,
    cityList,
    setCityList,
    industryList,
    setIndustryList,
    createAppApplicationId,
    cityZipList,
    setCityZipList,
    userID,
    fullName,
  } = useContext(AuthContext);
  const {
    control,
    watch,
    setValue,
    setFocus,
    getValues,
    clearErrors,
    handleSubmit,
    setError,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      cousersEmpinfo: [
        {
          userType: '',
          applicantId: '',
          empStatus: '',
          empName: '',
          empAddressLine1: '',
          empAddressLine2: '',
          empCountry: 'USA',
          empState: '',
          empCity: '',
          empZipcode: '',
          jobTitle: '',
          occupation: '',
          industry: '',
          empYears: '',
          empMonths: '',
          monthlyIncome: '',
          mobile: '',

          showAddInfo: false,
          addEmpName: '',
          addJobTitle: '',
          addOccupation: '',
          mobile2: '',
          addMonthlyIncome: '',
        },
      ],
    },
    resolver: yupResolver(EmploymentInformationSchema),
  });

  const [loader, setLoader] = useState(false);
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'cousersEmpinfo',
  });

  const { userType, reviewFlag, draftCheckFlag, addUsertabValue } =
    useContext(MainApplicationContext);

  const watchcousers = watch('cousersEmpinfo') || [];
  useEffect(() => {
    if (Object.entries(errors)?.length && errors?.cousersEmpinfo?.length > 0) {
      const errorTabIndex = findTabWitherror();
      console.log('errorTabIndex', errorTabIndex);
      if (errorTabIndex > -1) setTabValue(errorTabIndex);
      const firstError = Object.keys(errors?.cousersEmpinfo[errorTabIndex]);
      const errorFocusArrFields = [
        'monthlyIncome',
        'addMonthlyIncome',
        'empYears',
        'empMonths',
        'empStatus',
        'empCountry',
        'empState',
        'empCity',
        'occupation',
        'industry',
        'empStatus',
        'addOccupation',
      ];
      if (firstError?.length > 0 && errorFocusArrFields?.includes(firstError[0])) {
        document.getElementById(`cousersEmpinfo[${errorTabIndex}].${firstError[0]}`)?.focus();
      } else if (firstError.length > 0) {
        setFocus(`cousersEmpinfo[${errorTabIndex}].${firstError[0]}`);
      }
    }
  }, [errors]);

  const findTabWitherror = () => {
    for (let i = 0; i < fields.length; i++) {
      const hasErrors = errors.cousersEmpinfo?.[i] !== undefined;
      if (hasErrors) {
        return i;
      }
    }
    return -1;
  };

  const [tabvalue, setTabValue] = useState(0);

  const a11yProps = (index) => {
    return {
      id: `emp-tab-${index}`,
      'aria-controls': `emp-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setCallDraft(true);
  };

  useEffect(async () => {
    setLoader(true);
    await step2DropDownApis({
      occupationList,
      setOccupationList,
      empStatusList,
      setEmpStatusList,
      cityList,
      setCityList,
      industryList,
      setIndustryList,
      cityZipList,
      setCityZipList,
    });
    setLoader(false);
    await getSpecificDetailsApi();
  }, []);

  useEffect(() => {
    if (watchcousers?.length && reviewFlag) {
      const reviewTab = watchcousers.findIndex((x) => x.userType === userType);
      if (reviewTab > -1) {
        setTabValue(reviewTab);
      }
      if (addUsertabValue) {
        setTabValue(addUsertabValue);
      }
    }
  }, [reviewFlag, watchcousers, addUsertabValue]);

  const getSpecificDetailsApi = async () => {
    try {
      setLoader(true);
      const getResp = await ApiClient.get(
        `${UrlHelpers.getSpecificDetails}?applicationId=${createAppApplicationId}&eventName=EmploymentInformation&isLockRequired=true`,
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );

      if (Object.entries(getResp).length) {
        framecouserArray(getResp);
      }
    } catch (e) {
      console.log('error', e);
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later'
      );
    } finally {
      setLoader(false);
    }
  };

  const framecouserArray = async (getResp) => {
    const {
      employmentInformation,
      coSigners = [],
      coApplicants = [],
      applicantId,
    } = getResp?.participantsEmploymentInformation;
    const couserArray = [];
    await getPrimaryApplicant(employmentInformation, applicantId, couserArray);
    if (coApplicants?.length) {
      await getCoApplicantDetails(coApplicants, couserArray);
    }

    if (coSigners.length > 0) {
      await getCosignerDetails(coSigners, couserArray);
    }
    setInitCouserArray(couserArray);
    replace(couserArray);
    if (draftCheckFlag) {
      await landFromDraft(couserArray);
    }
  };

  const landFromDraft = async (cousers) => {
    if (cousers?.length && userType) {
      const landingTab = cousers.findIndex((x) => x.userType === userType);
      if (landingTab > -1) {
        setTabValue(landingTab);
      }
    }
  };
  const getPrimaryApplicant = async (employmentinfo, applicantId, couserArray) => {
    const infoData = getEmpInfoObj(applicantId, employmentinfo);
    const primaryAppt = {
      userType: 'Applicant',
      ...infoData,
    };
    couserArray.push(primaryAppt);
  };
  const getCoApplicantDetails = async (coApplicants, couserArray) => {
    const { applicantId, employmentInformation } = coApplicants?.[0];
    const infoData = getEmpInfoObj(applicantId, employmentInformation);
    const coapplicant = {
      userType: `Co-applicant`,
      ...infoData,
    };
    couserArray.push(coapplicant);
  };
  const getCosignerDetails = async (coSigners, couserArray) => {
    coSigners.map((x, index) => {
      const { applicantId, employmentInformation } = x;
      const infoData = getEmpInfoObj(applicantId, employmentInformation);
      const cosigner = {
        userType: `Co-signer ${index + 1}`,
        ...infoData,
      };
      couserArray.push(cosigner);
    });
  };
  return (
    <>
      <ApplicationPageHeading />
      <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
      <Loader open={loader} />
      {fields?.length > 0 && (
        <>
          <Box>
            <Tabs
              value={tabvalue}
              onChange={handleChange}
              aria-label="User tabs"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {fields.map((item, index) => (
                <Tab key={item.userType} label={item.userType} {...a11yProps(index)} />
              ))}
            </Tabs>
          </Box>

          {fields.map((item, index) => (
            <>
              <TabPanel key={item.id} value={tabvalue} index={index}>
                <EmploymentInformation
                  control={control}
                  prefixName={`cousersEmpinfo[${index}]`}
                  couserIndex={index}
                  errors={errors}
                  getValues={getValues}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  dirtyFields={dirtyFields}
                  setError={setError}
                  setApiErr={setApiErr}
                  fields={fields}
                  append={append}
                  remove={remove}
                  tabvalue={tabvalue}
                  handleSubmit={handleSubmit}
                  callDraft={callDraft}
                  setCallDraft={setCallDraft}
                  setTabValue={setTabValue}
                  initcouserArray={initcouserArray}
                />
              </TabPanel>
            </>
          ))}
        </>
      )}
    </>
  );
};
export default EmploymentInfoTabView;
