import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import classes from 'styles/globalStyle.module.scss';
import { isApproved, numberToCurrency } from 'utils/utility';

import DealerFundingInformation from './DealerFundingInformation';
import LatestComments from './LatestComments';

const OverviewTab = ({ appDetails, commentList }) => {
  const dealerFundingInformation = JSON.parse(appDetails?.dealerFundingInformation || '{}');
  const decision = JSON.parse(appDetails?.decisions || '{}')?.[0];
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ marginTop: '5px' }}
        className={classes.tableOutline}
      >
        <Grid
          container
          sx={{ backgroundColor: '#F2F2F2', borderBottom: '1px solid #DADADA' }}
          p={2}
        >
          <Grid item lg={2.5} md={2.5} xs={2.5}>
            <Typography variant="subtitle2" sx={{ fontWeight: '700', width: '95%' }}>
              Description
            </Typography>
          </Grid>
          <Grid item lg={2.5} md={2.5} xs={3}>
            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              Borrower name
            </Typography>
          </Grid>
          <Grid item lg={2.5} md={2.5} xs={2}>
            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              Occupation
            </Typography>
          </Grid>
          <Grid item lg={2} md={2} xs={2}>
            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              Income
            </Typography>
          </Grid>
          <Grid item lg={2} md={2} xs={2.5}>
            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              Residence status
            </Typography>
          </Grid>
        </Grid>
        <Grid container p={2}>
          <Grid item lg={2.5} md={2.5} xs={2.5}>
            <Typography variant="subtitle2" sx={{ width: '95%' }}>
              {appDetails?.collateral}
            </Typography>
          </Grid>
          <Grid item lg={2.5} md={2.5} xs={3}>
            <Typography variant="subtitle2">{appDetails?.applicantName}</Typography>
          </Grid>
          <Grid item lg={2.5} md={2.5} xs={2}>
            <Typography variant="subtitle2">{appDetails?.occupation}</Typography>
          </Grid>
          <Grid item lg={2} md={2} xs={2}>
            <Typography variant="subtitle2">
              {numberToCurrency(appDetails?.grossMonthlyIncome)}
            </Typography>
          </Grid>
          <Grid item lg={2} md={2} xs={2.5}>
            <Typography variant="subtitle2">{appDetails?.residentialStatus}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} xs={6}>
            <Box className={classes.tableOutline} sx={{ marginTop: '20px' }}>
              <Box
                display="flex"
                flexDirection="column"
                sx={{ backgroundColor: '#F2F2F2', borderBottom: '1px solid #DADADA' }}
                p={2}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
                  Latest decision
                </Typography>
              </Box>
              <Grid container>
                <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                  <Typography variant="subtitle2">Status/user</Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={6} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                  <Typography variant="subtitle2">
                    {decision?.displayStatus}/{decision?.analyst?.id}
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={6} p={2}>
                  <Typography variant="subtitle2">Date & time</Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={6} p={2}>
                  <Typography variant="subtitle2">
                    {moment(decision?.receivedDateTime?.replace('Z', '')).format(
                      'MM/DD/yyyy, h:mm A'
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} xs={6}>
            <Box className={classes.tableOutline} sx={{ marginTop: '20px', height: '87%' }}>
              <Box
                display="flex"
                flexDirection="column"
                sx={{ backgroundColor: '#F2F2F2', borderBottom: '1px solid #DADADA' }}
                p={2}
              >
                <Box>
                  <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
                    {appDetails?.loanStatus === 'DECLINE' || appDetails?.loanStatus === 'DECSCOR'
                      ? 'Decline reasons'
                      : 'Stipulations'}
                  </Typography>
                </Box>
              </Box>
              <Box p={2}>
                {isApproved(appDetails?.loanStatus) && decision?.stipulations ? (
                  decision?.stipulations?.map((item) => {
                    return (
                      <Typography variant="subtitle2" key={item?.text}>
                        {item?.text}
                      </Typography>
                    );
                  })
                ) : (
                  <Typography variant="subtitle2">
                    {appDetails?.loanStatus === 'DECLINE' || appDetails?.loanStatus === 'DECSCOR'
                      ? decision?.reasons?.map((item) => {
                          return (
                            <Typography variant="subtitle2" key={item?.text}>
                              {item?.text}
                            </Typography>
                          );
                        })
                      : '--'}
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <DealerFundingInformation info={dealerFundingInformation} />
      <LatestComments comments={commentList} />
    </>
  );
};

OverviewTab.propTypes = {
  appDetails: PropTypes.instanceOf(Object),
  commentList: PropTypes.instanceOf(Object),
};
OverviewTab.defaultProps = {
  appDetails: {},
  commentList: [],
};

export default OverviewTab;
