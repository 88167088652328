import { Box } from '@mui/material';

const AddRemoveBtn = (string, icon) => {
  return (
    <Box display="flex" aria-label={string} alignItems="center">
      <img src={icon} alt={`${string} Button`} style={{ marginRight: '8px' }} /> {string}
    </Box>
  );
};

export default AddRemoveBtn;
