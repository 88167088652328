import CloseIcon from '@mui/icons-material/Close';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { numberToCurrency } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';

const CollateralInformationForm = ({ collateralData }) => {
  console.log('collateralData', collateralData);
  const { setShowCollateralPopup } = useContext(MainApplicationContext);
  const { lookupStateList } = useContext(AuthContext);
  const [alertToggle, setAlertToggle] = useState('flex');

  const handleClose = () => {
    setAlertToggle('none');
  };

  const total =
    collateralData?.productDetails?.collateralType === 'new' &&
    collateralData?.productDetails?.valueGuide === 'Blackbook'
      ? +collateralData?.vehicleValues?.basevehicleValue.msrp +
        +collateralData?.vehicleValues?.equipmentAdjustment.msrp
      : +collateralData?.vehicleValues?.basevehicleValue +
        +collateralData?.vehicleValues?.equipmentAdjustment +
        +collateralData?.vehicleValues?.mileageAdjustment;

  const stateMapping =
    lookupStateList?.find((x) => x.value === collateralData?.productDetails?.state)?.name ||
    collateralData?.productDetails?.state;

  return (
    <Box
      width="70%"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: '0px',
        gap: '10px',
      }}
    >
      <Box
        sx={{
          display: `${alertToggle}`,
          flexDirection: 'row',
          alignItems: 'flex-start',
          padding: '10px',
          justifyContent: 'space-between',
          background: 'rgba(239, 171, 57, 0.05)',
          borderWidth: '1px 1px 1px 5px',
          borderStyle: 'solid',
          borderColor: 'rgba(239, 171, 57, 0.5)',
          gap: '10px',
        }}
      >
        <WarningAmberRoundedIcon sx={{ color: '#EFAB3980' }} />
        <Typography fontSize="14px !important" id="collateral-auto-populate-alert">
          Review the auto populated results in the collateral information and proceed. Click the
          value lookup again if a new search is required.
        </Typography>
        <IconButton
          aria-describedby="collateral-auto-populate-alert"
          aria-label="close"
          data-testid="closeBtn"
          onClick={handleClose}
          sx={{ height: '16px', width: '16px' }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ width: '100%' }}>
        <CustomCollapse
          heading="Collateral information"
          open={true}
          valueLookupButton={true}
          setShowCollateralPopup={setShowCollateralPopup}
        >
          <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">New</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {collateralData?.productDetails?.collateralType === 'new' ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">Vehicle type</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                Local
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">Year</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {collateralData?.productDetails?.year}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">Make</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {collateralData?.productDetails?.make}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">Model</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {collateralData?.productDetails?.model}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">Sub model</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {collateralData?.productDetails?.series || collateralData?.productDetails?.subModel}
              </Typography>
            </Grid>
            {collateralData?.productDetails?.valueGuide === 'Blackbook' && (
              <>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography fontSize="14px !important">Trim</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                    {collateralData?.productDetails?.style}
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography fontSize="14px !important">State</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                    {stateMapping}
                  </Typography>
                </Grid>
              </>
            )}
            {!!collateralData?.productDetails?.mileage && (
              <>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography fontSize="14px !important">Mileage</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                    {+collateralData?.productDetails?.mileage}
                  </Typography>
                </Grid>
              </>
            )}
            {collateralData?.productDetails?.vin && (
              <>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography fontSize="14px !important">VIN</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                    {collateralData?.productDetails?.vin}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </CustomCollapse>
        <CustomCollapse heading="Vehicle values" open={true}>
          {collateralData?.productDetails?.valueGuide === 'Distributor Data' && (
            <Grid
              container
              rowSpacing={1}
              columnSpacing={2}
              display="grid"
              sx={{ border: '1px solid #DADADA', gridTemplateColumns: '70% 30%' }}
            >
              <Box sx={{ backgroundColor: '#F2F2F2' }}></Box>
              <Box p={2} sx={{ backgroundColor: '#F2F2F2' }}>
                <Typography fontSize="14px !important" fontWeight="700">
                  MSRP
                </Typography>
              </Box>
              <Box p={2}>
                <Typography fontSize="14px !important">Base vehicle value</Typography>
              </Box>
              <Box p={2}>
                <Typography fontSize="14px !important">{`${numberToCurrency(
                  collateralData?.vehicleValues?.basevehicleValue?.msrp
                )}`}</Typography>
              </Box>
            </Grid>
          )}
          {collateralData?.productDetails?.valueGuide === 'Blackbook' && (
            <Grid
              container
              rowSpacing={1}
              columnSpacing={2}
              display="grid"
              sx={{ border: '1px solid #DADADA', gridTemplateColumns: '70% 30%' }}
            >
              <Box p={2} sx={{ backgroundColor: '#F2F2F2' }}>
                <Typography fontSize="14px !important" fontWeight="700">
                  {collateralData?.productDetails?.collateralType === 'new' ? 'MSRP' : 'Retail'}
                </Typography>
              </Box>
              <Box sx={{ backgroundColor: '#F2F2F2' }}></Box>
              {collateralData?.productDetails?.collateralType !== 'new' && (
                <>
                  <Box sx={{ borderBottom: '1px solid #DADADA' }}></Box>
                  <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                    <Typography fontSize="14px !important" fontWeight={700}>
                      Clean
                    </Typography>
                  </Box>
                </>
              )}
              <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography fontSize="14px !important">Base vehicle value</Typography>
              </Box>
              <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography fontSize="14px !important">
                  {collateralData?.productDetails?.collateralType === 'new'
                    ? `${numberToCurrency(collateralData?.vehicleValues?.basevehicleValue?.msrp)}`
                    : `${numberToCurrency(collateralData?.vehicleValues?.basevehicleValue)}`}
                </Typography>
              </Box>
              {collateralData?.vehicleValues?.equipmentAdjustment != 0 && (
                <>
                  <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                    <Typography fontSize="14px !important" fontWeight={700}>
                      Equipment adjustment
                    </Typography>
                  </Box>
                  <Box sx={{ borderBottom: '1px solid #DADADA' }}></Box>
                  {collateralData?.vehicleValues?.selectedEquipmentAdjustment?.map((x) => {
                    return (
                      <>
                        <Box
                          key={`${x.name}-${x.clean}`}
                          p={2}
                          sx={{ borderBottom: '1px solid #DADADA' }}
                        >
                          <Typography fontSize="14px !important">
                            {x.name || x.description}
                          </Typography>
                        </Box>
                        <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                          <Typography fontSize="14px !important">
                            {numberToCurrency(x.clean || x.retail)}
                          </Typography>
                        </Box>
                      </>
                    );
                  })}
                </>
              )}
              {collateralData?.vehicleValues?.mileageAdjustment != 0 && (
                <>
                  <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                    <Typography fontSize="14px !important" fontWeight={700}>
                      Mileage adjustment
                    </Typography>
                  </Box>
                  <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                    <Typography fontSize="14px !important">
                      {numberToCurrency(collateralData?.vehicleValues?.mileageAdjustment)}
                    </Typography>
                  </Box>
                </>
              )}
              <Box p={2} sx={{ backgroundColor: '#F2F2F2' }}>
                <Typography fontSize="14px !important" fontWeight={700}>
                  Total
                </Typography>
              </Box>
              <Box p={2} sx={{ backgroundColor: '#F2F2F2' }}>
                <Typography fontSize="14px !important" fontWeight={700}>
                  {numberToCurrency(total)}
                </Typography>
              </Box>
            </Grid>
          )}
        </CustomCollapse>
      </Box>
    </Box>
  );
};

export default CollateralInformationForm;

CollateralInformationForm.propTypes = {
  collateralData: PropTypes.instanceOf(Object),
};
CollateralInformationForm.defaultProps = {
  collateralData: {},
};
