import * as yup from 'yup';

export const approveRequestSchema = yup.object().shape({
  userId: yup
    .string()
    .required('* Mandatory field')
    .matches(/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\d\s]*$/, '* No Special Characters allowed')
    .test(
      'len',
      'Minimum 7 characters and maximum 15 characters can be entered',
      (val) => val?.length >= 7 && val?.length <= 15
    )
    .matches(/d*[a-zA-Z]/, '* Must contain 1 letter '),
  includeInSalesManager: yup.string().required('* Mandatory field'),
  includeInReference: yup.string().required('* Mandatory field'),
  active: yup.string().required('* Mandatory field'),
  maximizeTY: yup.string().required('* Mandatory field'),
  securityProfile: yup.string().required('* Mandatory field'),
  vendorNumber: yup
    .string()
    .matches(/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\d\s]*$/, '* No Special Characters allowed')
    .test('len', 'Maximum 11 characters can be entered', (val) => (val ? val?.length <= 11 : true))
    .nullable(),
});
