import * as yup from 'yup';

import {
  dobFormatValidation,
  dobPastOneYearValidation,
} from './validationFunctions/validationFunctions';

console.log('test', new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
export const KpiSchema = yup.object().shape({
  startDate: yup
    .string()
    .nullable()
    .test('date format check', 'Correct date format is mm/dd/yyyy', (value) => {
      return !value || dobFormatValidation(value);
    })
    .test('Min date validation', 'Dates should be in the last 365 days', (val) => {
      console.log('minDate', { val });
      return !val || dobPastOneYearValidation(val);
    })
    .test('Max date validation', "Date can't be in future", (val) => {
      return !val || new Date(val) <= new Date().setHours(0, 0, 0, 0);
    }),

  endDate: yup
    .string()
    .nullable()
    .test('date format check', 'Correct date format is mm/dd/yyyy', (value) => {
      return !value || dobFormatValidation(value);
    })
    .test('Min date validation', 'Dates should be in the last 365 days', (val) => {
      return !val || dobPastOneYearValidation(val);
    })
    .test('Max date validation', "Date can't be in future", (val) => {
      return !val || new Date(val) <= new Date().setHours(0, 0, 0, 0);
    })
    .when('startDate', {
      is: (val) => !!val,
      then: yup
        .string()
        .nullable()
        .test('len', 'End date should be greater than start date', function (val) {
          const { startDate } = this?.parent;
          return !val || new Date(val) >= new Date(startDate);
        }),
    }),
});
