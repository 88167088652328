import { regexEmail2 } from 'utils/utility';
import * as yup from 'yup';

export const email = {
  email: yup
    .string()
    .required('* Mandatory field')

    .matches(regexEmail2, 'Invalid email format')
    .max(50, 'Invalid email length'),
};
export const mobile2 = {
  mobile2: yup
    .string()
    .nullable()
    .transform((v) => (v === '' ? null : v))
    .matches(/^\d+$/g, 'Invalid format')
    .test('len', 'Alternate phone number should have 10 digits', (val) =>
      val ? val?.length === 10 : true
    )
    .test('len', "Alternate phone number can't be same as mobile number", function (val) {
      const { mobile } = this?.parent;
      return val ? mobile !== val : true;
    }),
};
export const dob = {
  dob: yup
    .string()

    .nullable()
    .required('* Mandatory field')
    .test('', 'Correct date format is mm/dd/yyyy', (value) => {
      return dobFormatValidation(value);
    })
    .test('max length', 'Please enter a valid dob', (val) => {
      return validDOBValidation(val);
    })
    .test('age', 'Applicant must be 18 years or older', (value) => {
      return minYearValidation(value);
    })
    .test('age > 100', 'Age of the applicant should be less than 150 years', (value) => {
      return maxYearValidation(value);
    }),
};

export const dobnonMandatory = {
  dob: yup
    .string()
    .nullable()
    .test('', 'Correct date format is mm/dd/yyyy', (value) => {
      return !value || dobFormatValidation(value);
    })
    .test('max length', 'Please enter a valid dob', (val) => {
      return !val || validDOBValidation(val);
    })
    .test('age', 'Applicant must be 18 years or older', (value) => {
      return !value || minYearValidation(value);
    })
    .test('age > 100', 'Age of the applicant should be less than 150 years', (value) => {
      return !value || maxYearValidation(value);
    }),
};

export const dobFormatValidation = (value) => {
  console.log('val', value);
  return (
    value && !isNaN(new Date(value)?.getTime()) && value?.split(' ')[3]?.substring(0, 1) !== '0'
  );
};
export const dobPastOneYearValidation = (value) => {
  return new Date(value) > new Date(new Date().setFullYear(new Date().getFullYear() - 1));
};

export const validDOBValidation = (val) => {
  const maxDate = new Date(val);
  maxDate.setHours(23, 59, 59, 999);
  return maxDate <= new Date(new Date().setDate(new Date().getDate() + 1));
};

export const minYearValidation = (value) => {
  let startDate = new Date(value);
  let endDate = new Date();

  let difference = endDate - startDate.getTime();

  let ageDate = new Date(difference);
  let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);

  return value && parseInt(calculatedAge) >= 18;
};

export const maxYearValidation = (value) => {
  let startDate = new Date(value);
  let endDate = new Date();

  let difference = endDate - startDate.getTime();

  let ageDate = new Date(difference);
  let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);

  return value && parseInt(calculatedAge) < 150;
};
