import { Box, Grid, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext.js';
import MainApplicationContext from 'context/MainApplicationContext';
import LookupCommon from 'pages/LookupCommonComponent/LookupCommon';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { updateCompletedSteps } from 'utils/utility';

import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader.js';

import CancelEditPopup from '../CancelEditPopup';
import CreateApplicationFooter from '../CreateApplicationFooter.js';
import {
  submitApi,
  viewApplication,
} from '../InformationRequest/moduleUtilities/moduleUtilities.js';
import ReOpenApplicationFooter from '../ReOpenApplicationFooter';
import CollateralInformationForm from './CollateralInformationForm.js';
import CollateralInformationLookupDialog from './CollateralInformationLookupDialog';
import ValueGuideLookupDialog from './ValueGuideLookupDialog.js';

const CollateralInformation = () => {
  const {
    lookupSaved,
    collateralInfo,
    createAppApplicationId,
    setLookupSaved,
    setCollateralInfo,
    setVehicleType,
    setCreateAppFlag,
    setDealer,
    setCreateAppLaunchType,
    userID,
    fullName,
  } = useContext(AuthContext);
  console.log('lookupSaved', lookupSaved);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [apiErr, setApiErr] = useState('');
  const [collateralData, setCollateralData] = useState();
  const [openCancelAlert, setOpenCancelAlert] = useState(false);
  const {
    setActiveStep,
    setCompletedSteps,
    coApplicant,
    coSigner,
    reviewFlag,
    setOpenDraftModal,
    calculationState,
    completedSteps,
    viewAppFlow,
    setCaseReferenceId,
    setAppSubmitted,
    setUnfilled,
  } = useContext(MainApplicationContext);
  const ApiClient = new ApiClass();
  const onBackClick = async () => {
    setActiveStep(2);
  };
  console.log('collateralData', collateralData);
  useEffect(async () => {
    console.log('collateralInfo', collateralInfo);
    if (!Object.entries(collateralInfo)?.length) {
      await getSpecificDetailsApi({ resubmitCheck: false });
    } else {
      await getSpecificDetailsApi({ resubmitCheck: false, justCall: true });
    }
  }, []);

  const getSpecificDetailsApi = async ({ resubmitCheck = false, justCall = false }) => {
    try {
      setLoader(true);
      const getResp = await ApiClient.get(
        `${UrlHelpers.getSpecificDetails}?applicationId=${createAppApplicationId}&eventName=CollateralInformation&isLockRequired=true`,
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );
      console.log('get resp of collateral prev details', getResp);
      if (!justCall) {
        if (getResp?.CollateralInformation) {
          setLookupSaved(true);
          setLoader(false);
          return frameCollateralData({
            collateraldataFromDB: getResp?.CollateralInformation,
            resubmitCheck,
          });
        } else if (!collateralData || !Object.entries(collateralData)?.length) {
          setLookupSaved(false);
        }
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoader(false);
    }
  };

  const frameCollateralData = ({ collateraldataFromDB, resubmitCheck }) => {
    let { vehicleValues, productDetails } = collateraldataFromDB;
    let framedObj = {};
    if (productDetails?.vehicleNewOrOld === 'True') {
      framedObj = {
        basevehicleValue: {
          msrp: vehicleValues?.baseVehicleValue,
        },
        selectedEquipmentAdjustment: vehicleValues?.selectedEquipmentAdjustment,
        equipmentAdjustment: {
          msrp: vehicleValues?.equipmentAdjustment,
        },
      };
    } else {
      framedObj = {
        basevehicleValue: vehicleValues?.baseVehicleValue,
        equipmentAdjustment: vehicleValues?.equipmentAdjustment,
        mileageAdjustment: vehicleValues?.mileageAdjustment,
        selectedEquipmentAdjustment: vehicleValues?.selectedEquipmentAdjustment,
      };
    }
    const collateralMappingData = {
      vehicleValues: { ...framedObj },
      productDetails: {
        year: productDetails?.year,
        make: productDetails?.brand,
        model: productDetails?.model,

        style: productDetails?.style,
        valueGuide:
          productDetails?.valueGuide === 'Black Book' ? 'Blackbook' : productDetails?.valueGuide,
        state: productDetails?.state,
        mileage: productDetails?.mileage,
        collateralType: productDetails?.vehicleNewOrOld === 'True' ? 'new' : 'used',
        vin: productDetails?.vin,
        cylinders: productDetails?.cylinders || '',
        doors: productDetails?.doors || '',
      },
    };
    if (productDetails?.valueGuide === 'Black Book') {
      collateralMappingData.productDetails.series = productDetails?.subModel;
    } else {
      collateralMappingData.productDetails.subModel = productDetails?.subModel;
    }
    if (resubmitCheck) {
      return collateralMappingData;
    } else {
      setCollateralInfo({ ...collateralMappingData });
    }
  };
  useEffect(async () => {
    if (collateralInfo && Object.entries(collateralInfo)?.length) {
      setCollateralData(collateralInfo);
    }
  }, [collateralInfo]);

  const onCancelEditSubmitted = async () => {
    console.log('intoooo 1');
    setOpenCancelAlert(true);
  };

  const onConfirmCancel = async () => {
    setCreateAppFlag(false);
    setDealer('');
    setOpenCancelAlert(false);
    await viewApplication({ setLoader, navigate, createAppApplicationId, userID, fullName });
  };
  const updateSubmitted = async () => {
    await onSaveAndContinue({ updateFlag: true });
  };

  const continueSubmitted = async () => {
    await onSaveAndContinue({ updateFlag: false });
  };

  const onSaveAndContinue = async ({ draft = false, updateFlag = false }) => {
    const returnedData = await getSpecificDetailsApi({ resubmitCheck: true });
    const isSameData = JSON.stringify(returnedData) === JSON.stringify(collateralData);
    console.log('returnedData', { returnedData, collateralData });
    if (!lookupSaved) {
      setApiErr('Please complete the required information to proceed');
    } else {
      if (isSameData && viewAppFlow) {
        setUnfilled(false);
      } else if (viewAppFlow) {
        setUnfilled(true);
      }
      const { productDetails, vehicleValues } = collateralInfo;
      await callCollateralApi({ isSameData, productDetails, vehicleValues, draft, updateFlag });
    }
  };

  const callCollateralApi = async ({
    isSameData,
    productDetails,
    vehicleValues,
    draft,
    updateFlag,
  }) => {
    try {
      setLoader(true);
      let calculationData = await ApiClient.get(
        `${UrlHelpers.financeTermsPayCalcSave}?applicationId=${createAppApplicationId}&type=FinanceTermPayCalc`
      );
      const collateralNewUsed = productDetails?.collateralType === 'new' ? 'True' : 'False';
      setVehicleType(productDetails?.collateralType);
      if (calculationData?.length > 0) {
        const resultFromPayCalc = JSON.parse(calculationData?.[0]?.searchResult || '{}');
        if (collateralNewUsed !== resultFromPayCalc?.formData?.newOrUsed) {
          setApiErr(
            'New/used vehicle should be consistent across Collateral and value entered in payment calculator. Please review'
          );
        } else {
          setApiErr('');
          validationSuccess({ isSameData, productDetails, vehicleValues, draft, updateFlag });
        }
      } else {
        setApiErr('');
        validationSuccess({ isSameData, productDetails, vehicleValues, draft, updateFlag });
      }
    } catch (e) {
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please save the application as draft and try again later'
      );
    } finally {
      setLoader(false);
    }
  };
  const validationSuccess = async ({
    isSameData,
    productDetails,
    vehicleValues,
    draft,
    updateFlag,
  }) => {
    if (!isSameData && updateFlag) {
      setApiErr('Please review financing terms, as value/s in collateral screen are modified');
    } else {
      const payload = await framePayload({ productDetails, vehicleValues });
      let initPayload = {
        event: 'CollateralInformation',
        application: {
          products: {
            productType: 'Indirect Loan Account',
            productName: 'AutoLoanManual',
          },

          applicationId: createAppApplicationId,
          // dealerId: '1234',
          userId: userID,
          collateralInformation: { ...payload },
          source: {
            language: 'EN',
            location: 'PR',
          },
        },
      };
      await callApi({ draft, initPayload, updateFlag });
    }
  };

  const framePayload = async ({ productDetails, vehicleValues }) => {
    const productDetailsPayload = getProductDetailsPayload({ productDetails });
    return {
      vehicleValues: {
        baseVehicleValue:
          productDetails?.collateralType === 'new'
            ? vehicleValues?.basevehicleValue.msrp
            : vehicleValues?.basevehicleValue,
        ...(productDetails?.collateralType === 'new' && {
          baseVehicleValueInvoice: vehicleValues?.basevehicleValue.invoice,
        }),
        equipmentAdjustment:
          productDetails?.collateralType === 'new'
            ? vehicleValues?.equipmentAdjustment?.msrp
            : vehicleValues?.equipmentAdjustment,
        ...(productDetails?.collateralType === 'new' && {
          equipmentAdjustmentInvoice: vehicleValues?.equipmentAdjustment.invoice,
        }),
        mileageAdjustment: vehicleValues?.mileageAdjustment || '',
        totalVehicleValue: vehicleValues?.totalMSRP,
        ...(productDetails?.collateralType === 'new' &&
          productDetails?.valueGuide === 'Distributor Data' && {
            dealerCost: vehicleValues?.basevehicleValue?.dealerCost,
          }),
        ...(productDetails?.collateralType === 'used' && {
          msrpOriginalUsed: vehicleValues?.originalMsrp,
        }),
        selectedEquipmentAdjustment: vehicleValues?.selectedEquipmentAdjustment?.map((x) => {
          return {
            description: x.name || x.description,
            category: x.category || '',
            retail: x.clean || x.retail,
          };
        }),
      },
      ...productDetailsPayload,
    };
  };

  const getProductDetailsPayload = ({ productDetails }) => {
    return {
      productDetails: {
        year: `${productDetails.year}`,
        // VehicleType: `${productDetails.vehicleType}`,
        vehicleType: `Local`,
        brand: `${productDetails.make}`,
        model: `${productDetails.model}`,
        subModel: `${
          productDetails?.valueGuide === 'Blackbook'
            ? productDetails.series
            : productDetails.subModel
        }`,
        style: `${productDetails.style || ''}`,
        valueGuide: productDetails.valueGuide === 'Blackbook' ? 'Black Book' : 'Distributor Data',
        state: `${productDetails.state || ''}`,
        mileage: `${productDetails.mileage || ''}`,
        // VehicleNewOrOld: `${productDetails.collateralType}`,
        vehicleNewOrOld: `${productDetails?.collateralType === 'new' ? 'True' : 'False'}`,
        vin: `${productDetails.vin || ''}`,
        cylinders: +productDetails?.cylinders || '',
        doors: +productDetails?.doors || '',
      },
    };
  };
  const callApi = async ({ draft, initPayload, updateFlag }) => {
    try {
      setLoader(true);
      const endpoint = draft ? UrlHelpers.applicationDraft : UrlHelpers.informationReqSave;
      let calculate = await ApiClient.post(
        endpoint,
        { ...initPayload },
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );
      if (!draft && calculate) {
        setApiErr('');
        if (!reviewFlag) {
          setActiveStep(4);
          updateCompletedSteps([1, 2, 3], completedSteps, setCompletedSteps);
        } else if (reviewFlag && !viewAppFlow) {
          setActiveStep(4);
          updateCompletedSteps([1, 2, 3, 4], completedSteps, setCompletedSteps);
        } else if (viewAppFlow) {
          await handleResubmitApplication({ updateFlag });
        }
      } else if (draft && calculate) {
        setOpenDraftModal(true);
      }
    } catch (e) {
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please save the application as draft and try again later'
      );
    } finally {
      setLoader(false);
    }
  };

  const handleResubmitApplication = async ({ updateFlag }) => {
    setCompletedSteps([1, 2, 3, 4, 5]);

    if (updateFlag) {
      await submitApi({
        createAppApplicationId,
        userID,
        setLoader,
        setCaseReferenceId,
        setAppSubmitted,
        setCreateAppFlag,
        setDealer,
        setCreateAppLaunchType,
        setApiErr,
        fullName,
      });
    } else {
      setActiveStep(4);
    }
  };

  return (
    <>
      <Box mt={2.5}>
        <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
      </Box>
      <Box>
        <CancelEditPopup
          openModal={openCancelAlert}
          setOpenModal={setOpenCancelAlert}
          onConfirmCancel={onConfirmCancel}
        />
        <Typography variant="h3" component="p" py={4}>
          Collateral information
        </Typography>
        <Grid container justifyContent="center">
          {lookupSaved ? (
            <CollateralInformationForm collateralData={collateralData} />
          ) : (
            <CollateralInformationLookupDialog apiErr={apiErr} />
          )}
          <ValueGuideLookupDialog setApiErr={setApiErr} />
          <LookupCommon
            coApplicant={coApplicant}
            coSigner={coSigner}
            reviewFlag={reviewFlag}
            calculationState={calculationState}
            completedSteps={completedSteps}
            viewAppFlow={viewAppFlow}
          />
        </Grid>
      </Box>
      <Loader open={loader} />
      {viewAppFlow ? (
        <ReOpenApplicationFooter
          onCancelEditSubmitted={onCancelEditSubmitted}
          updateSubmitted={updateSubmitted}
          continueSubmitted={continueSubmitted}
        />
      ) : (
        <CreateApplicationFooter
          disabledFlag={reviewFlag}
          onBackClick={onBackClick}
          onSaveContinue={onSaveAndContinue}
          onSaveDraft={() => onSaveAndContinue({ draft: true })}
          draftDisabledFlag={!lookupSaved}
        />
      )}
    </>
  );
};

export default CollateralInformation;

CollateralInformation.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
};
CollateralInformation.defaultProps = {
  control: {},
  errors: {},
};
