import { Box, Button, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { defaultFn } from 'utils/utility';

const PageHeading = ({ collateralType, valueGuide, showSummary, setCreateAppLookup }) => {
  const {
    setShowMainPopup,
    createAppFlag,
    setCreateAppApplicationId,
    setFinanceInfo,
    setCreateAppLaunchType,
    setPopupStep,
  } = useContext(AuthContext);
  return (
    <Box display="flex" justifyContent="space-between">
      <Typography variant="h3">{`${valueGuide} - ${collateralType}`}</Typography>
      <Box display="flex" gap={1}>
        <Button
          color="secondary"
          variant="outlined"
          id="lookup-again-btn"
          onClick={() => {
            setShowMainPopup(true);
          }}
        >
          Lookup again
        </Button>
        {!createAppFlag && showSummary && (
          <Button
            color="secondary"
            variant="contained"
            id="lookup-create-app-btn"
            onClick={() => {
              setCreateAppApplicationId('');
              setFinanceInfo({});
              setCreateAppLookup();
              setCreateAppLaunchType('lookup');
              setPopupStep(0);
            }}
          >
            Create application
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default PageHeading;

PageHeading.propTypes = {
  collateralType: PropTypes.string,
  valueGuide: PropTypes.string,
  setSelectedRows: PropTypes.func,
  showSummary: PropTypes.bool,
  setCreateAppLookup: PropTypes.func,
};
PageHeading.defaultProps = {
  collateralType: '',
  valueGuide: '',
  setSelectedRows: defaultFn,
  showSummary: false,
  setCreateAppLookup: defaultFn,
};
