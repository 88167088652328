/* eslint-disable react/prop-types */
import { Box, FormHelperText, Grid, InputLabel, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import globalClasses from 'styles/globalStyle.module.scss';
import { cityMapping, defaultFn } from 'utils/utility';

import { CustomAutoComplete } from 'components/CustomAutoComplete/CustomAutoComplete';
import CustomCollapse from 'components/CustomCollapse';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomNumberFormat from 'components/CustomNumberFormat/CustomNumberFormat';
import CustomPhoneNumber from 'components/CustomPhoneNumber/CustomPhoneNumber';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

const EmpInfoForm = ({
  control,
  errors,
  setValue,
  inputRef,
  addressLookupData,
  addressLookupDataResponse,
  clearErrors,
  setError,
  prefixName,
  couserIndex,
  stateList,
  cityList,
  isMounted,
  setCityList,
}) => {
  const [watchEmpAddress1, watchEmpState] = useWatch({
    control,
    name: [`${prefixName}.empAddressLine1`, `${prefixName}.empState`],
  });

  const { occupationList, empStatusList, industryList, cityZipList, stateCityList } =
    useContext(AuthContext);

  const [oldState, setOldState] = useState('');
  useEffect(async () => {
    let cityListt = [];
    if (stateCityList?.length > 0) {
      cityListt = await cityMapping(stateCityList, watchEmpState);
    }
    if (isMounted && oldState !== watchEmpState) {
      setValue(`${prefixName}.empCity`, '');
      setValue(`${prefixName}.empZipcode`, '');
    }
    setCityList(cityListt || []);
  }, [watchEmpState, stateCityList]);
  const handleStateAddress = async (AddressLine1, State) => {
    setValue(`${prefixName}.empState`, State);
    setOldState(State);
    setValue(`${prefixName}.empAddressLine1`, AddressLine1);
  };
  const handleAutoCompleteData = async (selectedObj) => {
    console.log({ selectedObj });
    if (selectedObj && selectedObj?.Address) {
      let { AddressLine1, PostalCode, City, State } = selectedObj?.Address;
      await handleStateAddress(AddressLine1, State);
      PostalCode = PostalCode?.split('-')?.[0];
      setValue(`${prefixName}.empZipcode`, PostalCode);
      const cityListt = await cityMapping(stateCityList, State);
      if (State !== 'PR') {
        setValue(`${prefixName}.empCity`, City);
      } else {
        City = cityZipList.find((x) => x?.zipcode === PostalCode)?.city;
        console.log('hii', cityList);
        City = cityListt.find((x) => x.value === City)?.value || '';
        console.log('city', City);
        if (!City) {
          setError(`${prefixName}.empCity`, {
            type: 'custom',
            message: 'Please select a city from the list and enter the zip code',
          });
        } else {
          setValue(`${prefixName}.empCity`, City);
          clearErrors([
            `${prefixName}.empAddressLine1`,
            `${prefixName}.empAddressLine2`,
            `${prefixName}.empZipcode`,
            `${prefixName}.empCity`,
            `${prefixName}.empState`,
          ]);
        }
      }
      clearErrors(`${prefixName}.empAddressLine1`);
    } else {
      setValue(`${prefixName}.empAddressLine1`, '');
      setValue(`${prefixName}.empAddressLine2`, '');
      setValue(`${prefixName}.empCity`, '');
      setValue(`${prefixName}.empState`, '');
      setValue(`${prefixName}.empZipcode`, '');
    }
  };
  console.log('watchEmpAddress1', watchEmpAddress1, cityList);
  console.log('addressLookupData', addressLookupData);
  return (
    <>
      <CustomCollapse heading="Employment information" open={true} errors={errors}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Status"
              control={control}
              name={`${prefixName}.empStatus`}
              errors={!!errors?.cousersEmpinfo?.[couserIndex]?.empStatus}
              errorText={errors?.cousersEmpinfo?.[couserIndex]?.empStatus?.message}
              options={empStatusList}
              defaultValue=""
              required
              displayEmpty
              inputRef={inputRef}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              name={`${prefixName}.empName`}
              errors={!!errors?.cousersEmpinfo?.[couserIndex]?.empName}
              errorText={errors?.cousersEmpinfo?.[couserIndex]?.empName?.message}
              placeholder="Enter name"
              label="Name"
              regex={/^[a-z0-9A-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\s]*$/}
              maxLength="50"
              flexDirection="column"
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomAutoComplete
              id="empAddressLine1"
              name={`${prefixName}.empAddressLine1`}
              flexDirection="column"
              control={control}
              dataTestIdAutocomplete="autocomplete_address1"
              dataTestIdForLabel="empAddressLine1"
              dataTestIdForInput="empAddressLine1"
              regex={/^[A-Za-z0-9áéíóúñüÁÉÍÓÚÑÜ¿¡,./-\s]$/}
              label="Address line 1"
              options={
                addressLookupData && watchEmpAddress1 && watchEmpAddress1?.length >= 6
                  ? addressLookupData
                  : []
              }
              handleChange={(_event, selectedValue) => {
                if (watchEmpAddress1 && watchEmpAddress1.length >= 6) {
                  const index = addressLookupData.indexOf(selectedValue);
                  const selectedObj = addressLookupDataResponse[index];
                  handleAutoCompleteData(selectedObj);
                }
              }}
              maxLength={40}
              autoFocus
              placeholder="Enter address line 1"
              fullWidth
              error={!!errors?.cousersEmpinfo?.[couserIndex]?.empAddressLine1}
              errorText={errors?.cousersEmpinfo?.[couserIndex]?.empAddressLine1?.message}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              placeholder="Enter address line 2"
              label="Address line 2"
              flexDirection="column"
              regex={/^[A-Za-z0-9áéíóúñüÁÉÍÓÚÑÜ¿¡,./-\s]$/}
              maxLength="20"
              name={`${prefixName}.empAddressLine2`}
              errors={!!errors?.cousersEmpinfo?.[couserIndex]?.empAddressLine2}
              errorText={errors?.cousersEmpinfo?.[couserIndex]?.empAddressLine2?.message}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Country"
              control={control}
              options={[
                {
                  name: 'United States',
                  value: 'USA',
                },
              ]}
              defaultValue="USA"
              isDisabled
              name={`${prefixName}.empCountry`}
              errors={!!errors?.cousersEmpinfo?.[couserIndex]?.empCountry}
              errorText={errors?.cousersEmpinfo?.[couserIndex]?.empCountry?.message}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="State"
              control={control}
              options={stateList}
              // defaultValue="PR"
              displayEmpty
              name={`${prefixName}.empState`}
              errors={!!errors?.cousersEmpinfo?.[couserIndex]?.empState}
              errorText={errors?.cousersEmpinfo?.[couserIndex]?.empState?.message}
            />
          </Grid>
          {watchEmpState === 'PR' && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="City"
                control={control}
                name={`${prefixName}.empCity`}
                options={cityList}
                defaultValue=""
                displayEmpty
                errors={!!errors?.cousersEmpinfo?.[couserIndex]?.empCity}
                errorText={errors?.cousersEmpinfo?.[couserIndex]?.empCity?.message}
              />
            </Grid>
          )}
          {watchEmpState !== 'PR' && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomInputBox
                control={control}
                errors={!!errors?.cousersEmpinfo?.[couserIndex]?.empCity}
                errorText={errors?.cousersEmpinfo?.[couserIndex]?.empCity?.message}
                placeholder="Enter city"
                label="City"
                name={`${prefixName}.empCity`}
                regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡\s]$/}
                maxLength="15"
                type="text"
                flexDirection="column"
              />
            </Grid>
          )}

          <Grid item lg={4} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              placeholder="Enter zipcode"
              label="Zipcode"
              regex={/^\d$/}
              maxLength="5"
              type="text"
              flexDirection="column"
              name={`${prefixName}.empZipcode`}
              errors={!!errors?.cousersEmpinfo?.[couserIndex]?.empZipcode}
              errorText={errors?.cousersEmpinfo?.[couserIndex]?.empZipcode?.message}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              placeholder="Enter job title"
              label="Job title"
              regex={/^[a-zA-Záéíóúñü¿¡\s]*$/g}
              maxLength="30"
              type="text"
              flexDirection="column"
              required
              name={`${prefixName}.jobTitle`}
              errors={!!errors?.cousersEmpinfo?.[couserIndex]?.jobTitle}
              errorText={errors?.cousersEmpinfo?.[couserIndex]?.jobTitle?.message}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              control={control}
              options={occupationList}
              placeholder="Enter occupation"
              label="Occupation"
              required
              name={`${prefixName}.occupation`}
              errors={!!errors?.cousersEmpinfo?.[couserIndex]?.occupation}
              errorText={errors?.cousersEmpinfo?.[couserIndex]?.occupation?.message}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              control={control}
              options={industryList}
              placeholder="Enter industry"
              // regex={/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\s]*$/g}
              label="Industry"
              required
              name={`${prefixName}.industry`}
              errors={!!errors?.cousersEmpinfo?.[couserIndex]?.industry}
              errorText={errors?.cousersEmpinfo?.[couserIndex]?.industry?.message}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <InputLabel htmlFor={`${prefixName}.empYears`}>
              <span style={{ color: 'red' }}> *&nbsp;</span>Time under current employer
            </InputLabel>

            <Grid item lg={12} md={12} sm={12} xs={12} container gap={1} alignItems="flex-start">
              <Grid
                item
                lg={5.5}
                md={5.5}
                sm={5.5}
                xs={5.5}
                display="flex"
                alignItems="center"
                sx={{
                  gap: '16px',
                }}
              >
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center">
                    <CustomNumberFormat
                      id={`${prefixName}.empYears`}
                      regex={/^\d$/}
                      dataTestId="empYears"
                      placeholder="0"
                      control={control}
                      maxLength={2}
                      name={`${prefixName}.empYears`}
                      error={!!errors?.cousersEmpinfo?.[couserIndex]?.empYears}
                      nonLabel="Years"
                    />
                    <Typography variant="subtitle2" component="p" pl={0.5}>
                      Years
                    </Typography>
                  </Box>
                  {!!errors?.cousersEmpinfo?.[couserIndex]?.empYears && (
                    <FormHelperText gap={2} className={globalClasses.customSelectError}>
                      {' '}
                      {errors?.cousersEmpinfo?.[couserIndex]?.empYears?.message}
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                lg={5.5}
                md={5.5}
                sm={5.5}
                xs={5.5}
                display="flex"
                alignItems="center"
                sx={{
                  gap: '16px',
                }}
              >
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center">
                    <CustomNumberFormat
                      id={`${prefixName}.empMonths`}
                      regex={/^\d$/}
                      dataTestId="empMonths"
                      placeholder="0"
                      control={control}
                      name={`${prefixName}.empMonths`}
                      error={!!errors?.cousersEmpinfo?.[couserIndex]?.empMonths}
                      maxLength={2}
                      noLabel="Months"
                    />
                    <Typography variant="subtitle2" component="p" pl={0.5}>
                      Months
                    </Typography>
                  </Box>
                  {!!errors?.cousersEmpinfo?.[couserIndex]?.empMonths && (
                    <FormHelperText gap={2} className={globalClasses.customSelectError}>
                      {' '}
                      {errors?.cousersEmpinfo?.[couserIndex]?.empMonths.message}
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              id={`${prefixName}.monthlyIncome`}
              dataTestId="monthlyIncome"
              placeholder="Enter value"
              label="Gross monthly income"
              decimalScale={2}
              control={control}
              name={`${prefixName}.monthlyIncome`}
              error={!!errors?.cousersEmpinfo?.[couserIndex]?.monthlyIncome}
              errorText={errors?.cousersEmpinfo?.[couserIndex]?.monthlyIncome?.message}
              thousandSeparator={true}
              prefix={'$ '}
              required
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <CustomPhoneNumber
              label="Work phone"
              flexDirection="column"
              placeholder="Enter work phone"
              control={control}
              name={`${prefixName}.mobile`}
              error={!!errors?.cousersEmpinfo?.[couserIndex]?.mobile}
              errorText={errors?.cousersEmpinfo?.[couserIndex]?.mobile?.message}
              required
            />
          </Grid>
        </Grid>
      </CustomCollapse>
    </>
  );
};

export default EmpInfoForm;
EmpInfoForm.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.bool,
  setValue: PropTypes.func,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  addressLookupData: PropTypes.array,
  addressLookupDataResponse: PropTypes.array,
  clearErrors: PropTypes.func,
  setError: PropTypes.func,
  prefixName: PropTypes.string,
  couserIndex: PropTypes.number,
  stateList: PropTypes.array,
  cityList: PropTypes.array,
  isMounted: PropTypes.bool,
  setCityList: PropTypes.func,
};
EmpInfoForm.defaultProps = {
  control: {},
  errors: {},
  errorText: '',
  setValue: defaultFn,
  clearErrors: defaultFn,
  inputRef: null,
  addressLookupData: [],
  addressLookupDataResponse: [],
  setError: defaultFn,
  prefixName: '',
  couserIndex: 0,
  stateList: [],
  cityList: [],
  isMounted: false,
  setCityList: defaultFn,
};
