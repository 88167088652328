import { regexChar2, regexEmail2 } from 'utils/utility';
import * as yup from 'yup';

import { dob } from './validationFunctions/validationFunctions';

export const userRequestSchema = yup.object().shape({
  dealer: yup.string().required('* Mandatory field'),
  firstName: yup
    .string()
    .required('* Mandatory field')
    .test('len', 'Maximum 25 characters can be entered', (val) => val?.length <= 25)
    .matches(regexChar2, 'Invalid format'),

  lastName: yup
    .string()
    .required('* Mandatory field')
    .test('len', 'Maximum 25 characters can be entered', (val) => val?.length <= 25)
    .matches(regexChar2, 'Invalid format'),

  secondLastName: yup
    .string()
    .nullable()
    .test('len', 'Maximum 25 characters can be entered', (val) => (val ? val?.length <= 25 : true))
    .matches(/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡.'\s]*$/g, 'Invalid format'),
  ...dob,
  ssn: yup
    .string()
    .required('* Mandatory field')
    .matches(/^\d+$/g, 'Invalid format')
    .test('length', '* Enter last 4 digits of SSN', (val) => val?.length === 4),

  mobileUser: yup
    .string()
    .required('* Mandatory field')
    .matches(/^\d+$/g, 'Invalid format')
    .test('length', '* Mobile number should have 10 digits', (val) => {
      return val?.length === 10;
    }),
  email: yup
    .string()
    .required('* Mandatory field')
    .matches(regexEmail2, '*Invalid email format')
    .max(50, '* Invalid email length'),
  accessType: yup.string().required('* Mandatory field'),
});
