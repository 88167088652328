import { ClickAwayListener } from '@mui/base';
import { Box, Grid, Link, Paper, TableHead, Tooltip, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ApiClass from 'Api/ApiClient';
import ApprovedSubIcon from 'assets/svg/ApprovedSubIcon.svg';
import DeclinedSubIcon from 'assets/svg/DeclinedSubIcon.svg';
import DraftIcon from 'assets/svg/DraftIcon.svg';
import EditIcon from 'assets/svg/EditIcon.svg';
import ThumbsUpIcon from 'assets/svg/ThumbsUpIcon.svg';
import TimerSubIcon from 'assets/svg/TimerSubIcon.svg';
import TotalApplicationSubIcon from 'assets/svg/TotalApplicationSubIcon.svg';
import WithdrawSubIcon from 'assets/svg/WithdrawSubIcon.svg';
import DeleteIcon from 'assets/svg/deleteIcon.svg';
import PenBkIcon from 'assets/svg/penbkIcon.svg';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn, numberToCurrency, popupstepMapping, stepMapping } from 'utils/utility';

import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

import TablePagination from '../RegisterUser/TablePagination';
import AppDetailPopup from './AppDetailPopup';
import CheckLandingPopup from './CheckLandingPopup';
import InviVoidPopup from './InviVoidPopup';
import UserLockPopup from './UserLockPopup';

export function TableCellTypography({ children }) {
  return (
    <Typography variant="subtitle2" component="p">
      {children}
    </Typography>
  );
}

TableCellTypography.propTypes = {
  children: PropTypes.node,
};

TableCellTypography.defaultProps = {
  children: <></>,
};

export function TableHeaderTypography({ children }) {
  return (
    <Typography variant="subtitle2" component="p" sx={{ fontWeight: 700 }}>
      {children}
    </Typography>
  );
}

TableHeaderTypography.propTypes = {
  children: PropTypes.node,
};

TableHeaderTypography.defaultProps = {
  children: <></>,
};

const ApplicationsTable = ({
  applicationsData,
  draftData,
  page,
  setPage,
  hasPagination,
  draftsTable,
  emptyDataMessage,
  notifyAppId,
  tabName,
}) => {
  const rowsPerPage = 10;
  const {
    setOpenDiscardModal,
    setCreateAppApplicationId,
    setUserID,
    setCreateAppFlag,
    setProgram,
    setVehicleType,
    setOpenPopUp,
    setPopupStep,
    setDraftFlag,
    setCreateAppLaunchType,
    setCollateralInfo,
    setDealer,
    userID,
    fullName,
  } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const [openInviPopup, setOpenInviPopup] = useState(false);
  const [userLockPopup, setUserLockPopup] = useState(false);
  const [pendingInvitation, setPendingInvitation] = useState('');

  const [execContext, setExeContext] = useState({});
  const [appData, setAppData] = useState({});
  const [landingPopup, setLandingPopup] = useState(false);
  const [landingStep, setLandingStep] = useState(1);
  const [landingUserType, setLandingUserType] = useState('');
  const [viewDetResp, setViewDetResp] = useState({});
  const [open, setOpen] = useState(null);
  const [highlightId, setHighlightId] = useState('');
  const [apiErr, setApiErr] = useState('');
  const handleTooltipClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    if (notifyAppId) {
      setHighlightId(notifyAppId);
    }
  }, [notifyAppId]);

  const handleTooltipOpen = async (e, index, applicationId) => {
    e.stopPropagation();

    try {
      setLoader(true);
      const resp = await ApiClient.get(
        `${UrlHelpers.getSpecificDetails}?applicationId=${applicationId}&eventName=viewApplication`,
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );
      if (
        resp?.lockDetails?.locked &&
        resp?.lockDetails?.lockHoldBy != 'guest' &&
        resp?.lockDetails?.lockHoldByUserName !== fullName
      ) {
        setApiErr(
          `The application is currently being accessed by ${resp?.lockDetails?.lockHoldByUserName}(${resp?.lockDetails?.lockHoldBy}).Please try again later`
        );
        setOpen(null);
      } else {
        setOpen(index);
        setViewDetResp({ ...resp, initAppId: applicationId });
        setApiErr('');
      }
    } catch (err) {
      console.log('e', err);
    } finally {
      setLoader(false);
    }
  };

  const ApiClient = new ApiClass();
  const navigate = useNavigate();
  const loanStatusMapping = {
    'Decision Pending': TimerSubIcon,
    Approved: ApprovedSubIcon,
    Declined: DeclinedSubIcon,
    Withdrawn: WithdrawSubIcon,
    'Contract Verification': TotalApplicationSubIcon,
    'Booked/Funded': ThumbsUpIcon,
    DRAFT: DraftIcon,
    'Booking Pending': PenBkIcon,
  };

  const paginate = (_, pageNumber) => {
    setPage(pageNumber);
  };
  const indexOfLastPost = page * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const calcUserList = (userArr) => {
    return Array.isArray(userArr) ? userArr?.slice(indexOfFirstPost, indexOfLastPost) : [];
  };
  const emptyValueHandle = (val) => {
    if (val) return val;
    else return '-';
  };

  const applicationTableHeading = [
    'App ID',
    'Applicant',
    'Status',
    'Dealer',
    'Manager',
    'Sales person',
    'Program',
    'Vehicle make',
    'Amount',
    'Contract package status',
    'Initiated',
    'Created by',
  ];
  const draftsTableHeading = [
    'Ref ID',
    'Applicant',
    'Pending invitation',
    'Created date',
    'Status',
    'Vehicle make',
    'Amount',
    'Action',
  ];
  const TableHeading = !draftsTable ? applicationTableHeading : draftsTableHeading;

  const onDraftEdit = async (ApplicationId, row) => {
    if (row?.pendingInvitations) {
      setPendingInvitation(row?.pendingInvitations);
    }
    try {
      setCreateAppApplicationId(ApplicationId);
      setLoader(true);
      const resp = await ApiClient.get(
        `${UrlHelpers.getApplicationDetails}?applicationId=${ApplicationId}`,
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );
      if (resp?.isLocked && resp?.lockHoldBy != 'guest' && resp?.lockHoldByUserName !== fullName) {
        setApiErr(
          `The application is currently being accessed by ${resp?.lockHoldByUserName}(${resp?.lockHoldBy}). Please try again later`
        );
      } else {
        setApiErr('');
        const parsedAppData = JSON.parse(resp?.applicationData);

        setProgram(parsedAppData?.loanInformation?.program);
        setDealer(parsedAppData?.loanInformation?.dealerId);
        setVehicleType(parsedAppData?.collateralInformation?.productDetails?.vehicleNewOrOld);
        setCreateAppLaunchType(parsedAppData?.launchType);
        const parsedExeContext = JSON.parse(resp?.executionContext);

        setExeContext(parsedExeContext);
        setAppData(parsedAppData);

        await decideLandFlow(resp, parsedExeContext, ApplicationId);
      }
    } catch (e) {
      console.log('e', e);
      setApiErr(e?.response?.data);
    } finally {
      setLoader(false);
    }
  };

  const decideLandingUser = (invitationContext) => {
    let landingUser;
    if (Object.values(invitationContext).includes(false)) {
      let user = Object.keys(invitationContext).filter((x) => invitationContext[x] === false);
      if (
        user.findIndex((x) => x.includes('primaryApplicant')) > -1 ||
        !Object.entries(user).length
      )
        landingUser = 'Applicant';
      else if (user.findIndex((x) => x.includes('CoApplicant1')) > -1) landingUser = 'Co-applicant';
      else if (user.findIndex((x) => x.includes('CoSigner1')) > -1) {
        landingUser = 'Co-signer 1';
      } else if (user.findIndex((x) => x.includes('CoSigner2')) > -1) {
        landingUser = 'Co-signer 2';
      }
    }
    setLandingUserType(landingUser);
  };
  const decideLandFlow = async (resp, exeContext, ApplicationId) => {
    if (resp.invitationStatus && !resp?.InvitationExpired) {
      try {
        const inviStatus = await ApiClient.get(
          `${UrlHelpers.inviStatus}/?applicationId=${ApplicationId}`,
          {
            headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
          }
        );

        const [, invitationContext] = Object.entries(inviStatus)?.[0];
        if (resp?.isLocked && resp?.lockHoldBy === 'guest') {
          setUserLockPopup(true);
        } else if (
          Object.values(invitationContext).includes(false) ||
          !Object.entries(invitationContext).length
        ) {
          setOpenInviPopup(true);
          decideLandingUser(invitationContext);
        } else {
          setLandingPopup(true);
          setLandingStep(findLandStep(exeContext));
        }
      } catch (e) {
        console.log('e', e);
      }
    } else {
      setLandingPopup(true);
      setLandingStep(findLandStep(exeContext));
    }
  };

  const decideLandingPopup = (exeContext, appDataa) => {
    const completedSteps = exeContext?.completedSteps;
    const lastCompletedStep = completedSteps[completedSteps?.length - 1];
    const updatedStep = popupstepMapping[lastCompletedStep];
    if (
      updatedStep === 2 &&
      !appDataa?.applicants?.addCoApplicantCheckboxSelected &&
      !appDataa?.applicants?.addCoSignerCheckboxSelected
    ) {
      setPopupStep(4);
    } else setPopupStep(updatedStep + 1 || 0);
  };

  const manualFlowLanding = async (exeContext, appDataa) => {
    setLandingPopup(false);
    setCreateAppFlag(true);
    setDraftFlag(true);
    setCollateralInfo({});
    let finishedStep = [];
    let landStep;
    landStep = findLandStep(exeContext);
    finishedStep = await getCompletedSteps(exeContext);
    if (landStep === 0) {
      decideLandingPopup(exeContext, appDataa);
      setOpenPopUp(true);
    } else {
      navigate('/home/create-app', {
        state: {
          activeStep: landStep || 1,
          completedSteps: finishedStep,
          userType: appDataa?.landingUserType,
          draftFlag: true,
          draftOpen: true,
        },
      });
    }
  };

  const findLandStep = (exeContext) => {
    const inprogressStep = exeContext?.inprogressStep;
    const inprogressLast = exeContext?.inprogressStep?.[exeContext?.inprogressStep?.length - 1];
    const completedSteps = exeContext?.completedSteps;
    let landStep;
    if (inprogressStep?.length) {
      if (inprogressLast === 'ReviewAndSubmit') {
        landStep = 5;
      } else {
        landStep = stepMapping[inprogressLast];
      }
    } else if (completedSteps?.length) {
      const lastCompletedStep = completedSteps[completedSteps?.length - 1];
      landStep = stepMapping[lastCompletedStep] + 1;
    }
    return landStep || 0;
  };

  const getCompletedSteps = async (exeContext) => {
    const completedStepExeContext = exeContext?.completedSteps;
    let finishedSteps = [];
    for (const step of completedStepExeContext) {
      if (stepMapping[step]) finishedSteps.push(stepMapping[step]);
    }
    return finishedSteps;
  };

  const handleNavigate = async (landingStepp = 1) => {
    setCreateAppFlag(true);
    setLandingPopup(false);
    setOpenInviPopup(false);
    setDraftFlag(true);
    setCollateralInfo({});
    if (landingStepp === 0) {
      setPopupStep(1);
      setOpenPopUp(true);
    } else {
      navigate('/home/create-app', {
        state: {
          activeStep: 1,
          completedSteps: [],
          userType: landingUserType,
          draftFlag: true,
        },
      });
    }
  };

  return (
    <>
      <InviVoidPopup
        openInviPopup={openInviPopup}
        setOpenInviPopup={setOpenInviPopup}
        handleNavigate={handleNavigate}
      />
      <UserLockPopup
        userLockPopup={userLockPopup}
        setUserLockPopup={setUserLockPopup}
        pendingInvitation={pendingInvitation}
        setOpenInviPopup={setOpenInviPopup}
      />
      <CheckLandingPopup
        landingPopup={landingPopup}
        setLandingPopup={setLandingPopup}
        handleNavigate={() => manualFlowLanding(execContext, appData)}
        handleFromStart={handleNavigate}
        landingStep={landingStep}
      />
      <Loader open={loader} />
      <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
      <Grid
        container
        sx={{
          overflowX: 'auto',
          width: '100%',
        }}
      >
        <Paper sx={{ width: '100%', boxShadow: 'none' }}>
          <Table aria-label="applications-table">
            <TableHead>
              <TableRow
                sx={{ backgroundColor: '#F2F2F2' }}
                className={classes.tableHeadingApplications}
              >
                {TableHeading?.map((x) => {
                  return (
                    <TableCell key={x}>
                      <TableHeaderTypography>{x}</TableHeaderTypography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {!draftsTable
                ? calcUserList(applicationsData)?.map((row, index) => (
                    <TableRow
                      key={row.applicationId}
                      className={classes.tableHeadingApplications}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                          <TableCell component="th" scope="row">
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              placement="right-start"
                              arrow
                              onClose={handleTooltipClose}
                              open={open === index}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={<AppDetailPopup details={viewDetResp} tabName={tabName} />}
                              classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
                            >
                              <Link
                                component="button"
                                variant="subtitle2"
                                sx={{
                                  color: '#0065CC',
                                  textDecoration: 'initial',
                                  textAlign: 'left',
                                }}
                                onClick={(e) => handleTooltipOpen(e, index, row?.applicationId)}
                              >
                                {row?.caseReferenceId}
                              </Link>
                            </Tooltip>
                          </TableCell>
                        </ClickAwayListener>
                      }
                      <TableCell>
                        <TableCellTypography>
                          <Link
                            component="button"
                            variant="subtitle2"
                            sx={{ color: '#0065CC', textDecoration: 'initial', textAlign: 'left' }}
                          >
                            {row?.firstName} {row?.lastName} ({row?.numberOfParticipants})
                          </Link>
                        </TableCellTypography>
                      </TableCell>

                      <TableCell width="200">
                        <TableCellTypography display="flex" alignItems="center">
                          <Typography
                            variant="subtitle2"
                            component="p"
                            display="flex"
                            alignItems="center"
                          >
                            <img src={loanStatusMapping[row?.displayLoanStatus]} />
                            &nbsp; &nbsp; <span>{row?.displayLoanStatus}</span>
                          </Typography>
                        </TableCellTypography>
                      </TableCell>

                      <TableCell>
                        <TableCellTypography>{row?.dealerName}</TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>{row?.managerName}</TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>{row?.salesPersonName}</TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>{row?.programName}</TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>
                          {row?.vehicleName == ' ' ? '-' : row?.vehicleName}
                        </TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>
                          {numberToCurrency(row?.loanAmount)}
                        </TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>{row?.readyStatus || '-'}</TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>{row?.createdOn}</TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>{row?.createdBy}</TableCellTypography>
                      </TableCell>
                    </TableRow>
                  ))
                : calcUserList(draftData)?.map((row) => (
                    <TableRow
                      key={row.applicationId}
                      className={[
                        classes.tableHeadingApplications,
                        highlightId && highlightId == row?.applicationId ? classes.highlighted : '',
                      ].join(' ')}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <TableCellTypography>
                          <Link
                            component="button"
                            variant="subtitle2"
                            sx={{ color: '#0065CC', textDecoration: 'initial', textAlign: 'left' }}
                          >
                            {emptyValueHandle(row?.applicationNumber)}
                          </Link>
                        </TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>
                          <Link
                            component="button"
                            variant="subtitle2"
                            sx={{ color: '#0065CC', textDecoration: 'initial', textAlign: 'left' }}
                          >
                            {row?.firstName} {row?.lastName} ({row?.numberOfParticipants})
                          </Link>
                        </TableCellTypography>
                      </TableCell>

                      <TableCell>
                        <TableCellTypography display="flex" alignItems="center">
                          {emptyValueHandle(row?.pendingInvitations)}
                        </TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>
                          {emptyValueHandle(row?.createdOn)}
                        </TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            display="flex"
                            alignItems="center"
                          >
                            <img src={loanStatusMapping[row?.applicationStatus]} />
                            &nbsp; &nbsp; <span>{emptyValueHandle(row?.applicationStatus)}</span>
                          </Typography>
                        </TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>
                          {emptyValueHandle(row?.vehicleName)}
                        </TableCellTypography>
                      </TableCell>
                      <TableCell>
                        <TableCellTypography>
                          {emptyValueHandle(numberToCurrency(row?.loanAmount))}
                        </TableCellTypography>
                      </TableCell>

                      <TableCell>
                        <Typography variant="subtitle2" component="p" display="flex">
                          <Link
                            color="secondary"
                            display="flex"
                            data-testid="deleteApplication"
                            onClick={() => {
                              setOpenDiscardModal(true);
                              setDraftFlag(true);
                              setCreateAppApplicationId(row.applicationId);
                              setUserID(row.createdBy);
                            }}
                            sx={{ cursor: 'pointer' }}
                            tabIndex="0"
                          >
                            <img src={DeleteIcon} />
                            &nbsp;
                            <Typography variant="subtitle2" component="p">
                              Delete
                            </Typography>
                          </Link>
                          &nbsp; &nbsp;
                          <Link
                            color="secondary"
                            display="flex"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => onDraftEdit(row.applicationId, row)}
                            tabIndex="0"
                          >
                            <img src={EditIcon} />
                            &nbsp;
                            <Typography variant="subtitle2" component="p">
                              Edit
                            </Typography>
                          </Link>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}

              {((!draftsTable && applicationsData.length === 0) ||
                (draftsTable && draftData.length === 0)) && (
                <TableRow>
                  <TableCell colSpan={12} sx={{ fontSize: '14px !important', textAlign: 'center' }}>
                    {emptyDataMessage}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Box sx={{ margin: '15px' }}>
        {hasPagination && (
          <TablePagination
            postsPerPage={10}
            totalPosts={!draftsTable ? applicationsData?.length : draftData?.length}
            paginate={paginate}
            page={page}
          />
        )}
      </Box>
    </>
  );
};

ApplicationsTable.propTypes = {
  applicationsData: PropTypes.instanceOf(Object),
  draftData: PropTypes.instanceOf(Object),
  page: PropTypes.string,
  hasPagination: PropTypes.bool,
  draftsTable: PropTypes.bool,
  setPage: PropTypes.func,
  emptyDataMessage: PropTypes.string,
  notifyAppId: PropTypes.string,
  tabName: PropTypes.string,
};

ApplicationsTable.defaultProps = {
  applicationsData: [],
  draftData: [],
  page: 1,
  setPage: defaultFn,
  hasPagination: false,
  draftsTable: false,
  emptyDataMessage: '',
  notifyAppId: '',
  tabName: '',
};

export default ApplicationsTable;
