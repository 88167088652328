import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import ApiClass from 'Api/ApiClient';
import { LockReleaseApi } from 'Services/LockReleaseApi';
import Invitation from 'assets/svg/invitation.svg';
import Manual from 'assets/svg/manualFlow.svg';
import AuthContext from 'context/AuthContext';
import CreateApplicationContext from 'context/CreateApplicationContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { InvitationOrManualSchema } from 'schemaValidations/CreateApplicationSchema';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn, regexEmail, ternaryCondition } from 'utils/utility';

import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomToggleButtonGroup from 'components/CustomToggleButtonGroup/CustomToggleButtonGroup';
import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

import ApplicationDialogHeading from '../InformationRequest/moduleUtilities/ApplicationDialogHeading';

const InvitationMaualDialog = ({
  reset: resetMain,
  control: controlMain,
  setValue: setValueMain,
}) => {
  const [popUpLoader, setPopUpLoader] = useState(false);
  const [apiErr, setApiErr] = useState('');

  const ApiClient = new ApiClass();
  const navigate = useNavigate();
  const {
    showInviManual,
    setShowInviManual,
    setShowCoAppDet,
    setShowCoApp,
    primaryApplicantData,
    setPrimaryApplicantData,
  } = useContext(CreateApplicationContext);
  const {
    setOpenPopUp,
    createAppApplicationId,
    setCreateAppLookup,
    setCreateAppFlag,
    userID,
    setInvitationSuccess,
    setCreateAppLaunchType,
    setInvitationAppNumber,
    setDealer,
    fullName,
  } = useContext(AuthContext);
  const [inviteCoapplicant, setInviteCoapplicant] = useState(false);
  const [inviteCoSignerOne, setInviteCoSignerOne] = useState(false);
  const [inviteCoSignerTwo, setInviteCoSignerTwo] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [CoAppId, CoAppFirstName, CoAppLastName, CoSigner, watchInviOrManual] = useWatch({
    control: controlMain,
    name: ['CoAppId', 'CoAppFirstName', 'CoAppLastName', 'CoSigner', 'invitationOrManual'],
  });

  console.log({ CoAppId, CoAppFirstName, CoAppLastName, CoSigner });
  const {
    control,
    formState: { errors },
    handleSubmit,
    clearErrors,
    setError,
    setValue,

    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      primaryApplicantEmail: '',
      CoApplicantEmail: '',
      CoSigner1Email: '',
      CoSigner2Email: '',
      inviOrManual: watchInviOrManual,
    },
    resolver: yupResolver(
      InvitationOrManualSchema(
        CoAppFirstName,
        CoSigner,
        inviteCoapplicant,
        inviteCoSignerOne,
        inviteCoSignerTwo
      )
    ),
  });

  const toggleChange = (e, value) => {
    setValueMain('invitationOrManual', value);
  };

  useEffect(async () => {
    await getSpecificDetailsApi();
  }, []);

  const getSpecificDetailsApi = async () => {
    try {
      setPopUpLoader(true);

      const getResp = await ApiClient.get(
        `${UrlHelpers.getSpecificDetails}?applicationId=${createAppApplicationId}&eventName=BasicInformationRequest&isLockRequired=true`,
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );

      console.log('getResp', getResp);
      const { BasicInformationRequest } = getResp;
      console.log('BasicInformationRequest', BasicInformationRequest);

      const cosigners = BasicInformationRequest?.applicants?.coSigners?.map((x) => {
        return {
          CoSignerName: {
            CoSignerFirstName: x?.informationRequest?.participantInfo?.customerName?.firstName,
            CoSignerLastName: x?.informationRequest?.participantInfo?.customerName?.lastName,
          },
          CoSignerAppId: x?.applicantId,
        };
      });
      console.log('cosigners', cosigners);
      if (cosigners?.length) {
        setValueMain('CoSigner', [...cosigners]);
      }
      if (BasicInformationRequest?.applicants?.coApplicants?.[0]?.applicantId) {
        setValueMain(
          'CoAppFirstName',
          BasicInformationRequest?.applicants?.coApplicants?.[0]?.informationRequest
            ?.participantInfo?.customerName?.firstName
        );
        setValueMain(
          'CoAppLastName',
          BasicInformationRequest?.applicants?.coApplicants?.[0]?.informationRequest
            ?.participantInfo?.customerName?.lastName
        );
        setValueMain(
          'CoAppId',
          BasicInformationRequest?.applicants?.coApplicants?.[0]?.applicantId
        );
      }
      setPrimaryApplicantData({
        primaryAppId: BasicInformationRequest?.applicants?.applicantId,
        primaryAppFirstName:
          BasicInformationRequest?.applicants?.informationRequest?.participantInfo?.customerName
            ?.firstName,
        primaryAppLastName:
          BasicInformationRequest?.applicants?.informationRequest?.participantInfo?.customerName
            ?.lastName,
      });
    } catch (e) {
      console.log('error', e);
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later.'
      );
    } finally {
      setPopUpLoader(false);
    }
  };

  useEffect(() => {
    if (!inviteCoapplicant) {
      clearErrors('CoApplicantEmail');
      setValue('CoApplicantEmail', '');
    }
    if (!inviteCoSignerOne) {
      clearErrors('CoSigner1Email');
      setValue('CoSigner1Email', '');
    }
    if (!inviteCoSignerTwo) {
      clearErrors('CoSigner2Email');
      setValue('CoSigner2Email', '');
    }
    if (watchInviOrManual === 'manual') {
      reset();
      setShowAlert(false);
      setInviteCoapplicant(false);
      setInviteCoSignerOne(false);
      setInviteCoSignerTwo(false);
    }
    setValue('inviOrManual', watchInviOrManual);
  }, [inviteCoapplicant, inviteCoSignerOne, inviteCoSignerTwo, watchInviOrManual]);

  const handleClose = () => {
    setShowInviManual(false);
    setOpenPopUp(false);
    setCreateAppLookup('');
    setCreateAppFlag(false);
    setDealer('');
    resetMain();
    setCreateAppLaunchType('');
    reset();
    setInviteCoapplicant(false);
    setInviteCoSignerOne(false);
    setInviteCoSignerTwo(false);
    LockReleaseApi({ appid: createAppApplicationId, fullName, userID });
  };

  const validateDuplicates = async (primaryEmail, coAppEmail, coSignerEmail, coSigner2Email) => {
    let isvalid = true;

    if (
      coSigner2Email &&
      (coSigner2Email === primaryEmail ||
        coSigner2Email === coAppEmail ||
        coSigner2Email === coSignerEmail)
    ) {
      setError('CoSigner2Email', {
        type: 'custom',
        message: 'Email cannot be the same as email of other applicants',
      });
      isvalid = false;
    } else if (
      coSignerEmail &&
      (coSignerEmail === primaryEmail ||
        coSignerEmail === coAppEmail ||
        coSignerEmail === coSigner2Email)
    ) {
      setError('CoSigner1Email', {
        type: 'custom',
        message: 'Email cannot be the same as email of other applicants',
      });
      isvalid = false;
    } else if (
      coAppEmail &&
      (coAppEmail === primaryEmail || coAppEmail === coSignerEmail || coAppEmail === coSigner2Email)
    ) {
      setError('CoApplicantEmail', {
        type: 'custom',
        message: 'Email cannot be the same as email of other applicants',
      });
      isvalid = false;
    } else if (
      primaryEmail &&
      (primaryEmail === coAppEmail ||
        primaryEmail === coSignerEmail ||
        primaryEmail === coSigner2Email)
    ) {
      console.log('opokok - 4');

      setError('primaryApplicantEmail', {
        type: 'custom',
        message: 'Email cannot be the same as email of other applicants',
      });
      isvalid = false;
    } else {
      clearErrors([
        'primaryApplicantEmail',
        'CoApplicantEmail',
        'CoSigner1Email',
        'CoSigner2Email',
      ]);
    }
    return isvalid;
  };
  const handleResponseOnSuccess = (url, primaryApplicantInfo, dataa) => {
    if (!url?.includes('invitation')) {
      navigate('/home/create-app', {
        state: {
          activeStep: 1,
        },
      });
      setInvitationAppNumber('');
    } else if (url?.includes('invitation')) {
      setCreateAppLaunchType('');
      setInvitationAppNumber(dataa?.applicationId);
      setInvitationSuccess(true);
    }
  };

  const handleFrameCoappPayloadData = async (applicantsData, data) => {
    let applicantData = { ...applicantsData };
    if (CoAppFirstName) {
      applicantData.coApplicants = [
        {
          applicantId: CoAppId,
          informationRequest: {
            participantInfo: {
              customerName: {
                firstName: CoAppFirstName,
                lastName: CoAppLastName,
              },
            },
          },
        },
      ];
      if (watchInviOrManual !== 'manual' && inviteCoapplicant) {
        applicantData.coApplicants[0].informationRequest.participantInfo.contacts = {
          applicantEmail: data?.CoApplicantEmail?.toLowerCase(),
        };
      }
    }
    return applicantData;
  };
  const handleAlert = async (data) => {
    if (
      !data?.primaryApplicantEmail &&
      !data?.CoApplicantEmail &&
      !data?.CoSigner1Email &&
      !data?.CoSigner2Email &&
      watchInviOrManual === 'invitation'
    ) {
      setShowAlert(true);
      return true;
    } else {
      setShowAlert(false);
      return false;
    }
  };
  const handleContinue = async (data) => {
    let isValid = await handleAlert(data);

    let isValidEmail = await validateDuplicates(
      data?.primaryApplicantEmail?.toLowerCase(),
      data?.CoApplicantEmail?.toLowerCase(),
      data?.CoSigner1Email?.toLowerCase(),
      data?.CoSigner2Email?.toLowerCase()
    );

    const primaryApplicantInfo = {
      ...primaryApplicantData,
      applicantId: primaryApplicantData?.primaryAppId,
    };

    let applicantsData = {
      applicantId: primaryApplicantInfo?.applicantId,
      informationRequest: {
        participantInfo: {
          customerName: {
            firstName: primaryApplicantInfo?.primaryAppFirstName,
            lastName: primaryApplicantInfo?.primaryAppLastName,
          },
        },
      },
    };
    let coAppData = await handleFrameCoappPayloadData(applicantsData, data);
    applicantsData = { ...applicantsData, ...coAppData };
    let cosignerArray = CoSigner.filter((x) => !!x.CoSignerName?.CoSignerFirstName) || [];
    if (cosignerArray?.length) {
      cosignerArray = cosignerArray.map((x, index) => {
        const cosignerBasic = {
          applicantId: x?.CoSignerAppId,
          informationRequest: {
            participantInfo: {
              customerName: {
                firstName: x.CoSignerName?.CoSignerFirstName,
                lastName: x.CoSignerName?.CoSignerLastName,
              },
            },
          },
        };
        if (
          watchInviOrManual !== 'manual' &&
          inviteCoSignerOne &&
          data?.CoSigner1Email &&
          index === 0
        ) {
          cosignerBasic.informationRequest.participantInfo.contacts = {
            applicantEmail: data?.CoSigner1Email?.toLowerCase(),
          };
        }
        if (
          watchInviOrManual !== 'manual' &&
          inviteCoSignerTwo &&
          data?.CoSigner2Email &&
          index === 1
        ) {
          cosignerBasic.informationRequest.participantInfo.contacts = {
            applicantEmail: data?.CoSigner2Email?.toLowerCase(),
          };
        }
        return { ...cosignerBasic };
      });
      applicantsData.coSigners = [...cosignerArray];
    }

    if (watchInviOrManual !== 'manual' && data?.primaryApplicantEmail) {
      applicantsData.informationRequest.participantInfo.contacts = {
        applicantEmail: data?.primaryApplicantEmail?.toLowerCase(),
      };
    }
    const payloadData = {
      event: await ternaryCondition(
        watchInviOrManual === 'manual',
        'BasicInformationRequest',
        'BasicInformationInvitation'
      ),
      application: {
        applicationId: createAppApplicationId,
        products: {
          productType: 'Indirect Loan Account',
          productName: 'AutoLoanManual',
        },
        userId: userID,
        loanAppType: 'Personal',
        createAppType: await ternaryCondition(
          watchInviOrManual === 'manual',
          'Manual',
          'Invitation'
        ),
        applicants: { ...applicantsData },
        source: {
          language: 'EN',
          location: 'PR',
        },
      },
    };
    const url = await ternaryCondition(
      watchInviOrManual === 'manual',
      UrlHelpers.informationReqSave,
      UrlHelpers.inviFlow
    );
    if (!isValid) {
      await callMS({ url, isValidEmail, payloadData, primaryApplicantInfo });
    }
  };

  const callMS = async ({ url, isValidEmail, payloadData, primaryApplicantInfo }) => {
    if (!Object.entries(errors).length && isValidEmail) {
      try {
        setPopUpLoader(true);
        let dataa = await ApiClient.post(
          url,

          { ...payloadData },
          {
            headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
          }
        );

        if (dataa) {
          setShowInviManual(false);
          setOpenPopUp(false);

          handleResponseOnSuccess(url, primaryApplicantInfo, dataa);
        }
      } catch (e) {
        console.log('error fetching', e);
        setApiErr(
          e?.response?.data ||
            'We are unable to proceed due to a technical issue, please try again later.'
        );
      } finally {
        setPopUpLoader(false);
      }
    }
  };
  const invitationOrManual = [
    { valuee: 'invitation', displayValue: 'Send invite to the applicant', logo: Invitation },
    { valuee: 'manual', displayValue: 'Enter customer details manually', logo: Manual },
  ];
  const handleBack = () => {
    setShowInviManual(false);
    if (CoAppFirstName || CoSigner?.[0]?.CoSignerName?.CoSignerFirstName) {
      setShowCoAppDet(true);
    } else {
      setShowCoApp(true);
    }
  };
  const tabRef = useRef(null);
  useEffect(() => {
    if (showAlert) {
      tabRef.current.focus();
    }
  }, [showAlert]);

  return (
    <>
      <Dialog
        open={showInviManual}
        aria-labelledby="customized-dialog-title"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiDialog-paper': {
              minWidth: 'clamp(400px, 50vw, 800px) !important',
              minHeight: 'clamp(450px,60vh,100%) !important',
            },
          },
        }}
      >
        <ApplicationDialogHeading handleBack={handleBack} handleClose={handleClose} />
        <Loader open={popUpLoader} />
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <Box display="flex" flexDirection="column" gap={2.5} px={5} mb={2.5}>
            <Grid container sx={{ justifyContent: 'center' }}>
              <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
            </Grid>
            <Typography variant="h4" textAlign="center" my={3}>
              Let’s go with your preference!
            </Typography>

            <CustomToggleButtonGroup
              exclusive={true}
              name="invitationOrManual"
              control={controlMain}
              toggleChange={toggleChange}
              toggleList={invitationOrManual}
            />
          </Box>
          {showAlert && (
            <Box>
              <Alert
                sx={{
                  '& .MuiAlert-icon': {
                    color: '#0052EA',
                  },
                }}
                className={classes.infoAlert}
                severity="info"
                action={
                  <IconButton ref={tabRef} aria-label="close" size="small">
                    <GridCloseIcon fontSize="inherit" onClick={() => setShowAlert(false)} />
                  </IconButton>
                }
              >
                Atleast one participant email should be entered to send an Invite
              </Alert>
            </Box>
          )}
          {watchInviOrManual === 'invitation' && (
            <Grid container px={3} my={1}>
              <Grid container pb={2} columnSpacing={2}>
                <Grid item lg={5} md={5} sm={12}>
                  <CustomInputBox
                    control={control}
                    errors={!!errors?.primaryApplicantEmail}
                    errorText={errors?.primaryApplicantEmail?.message}
                    placeholder="Enter applicant email"
                    label="Applicant email"
                    name="primaryApplicantEmail"
                    regex={regexEmail}
                    maxLength={50}
                    flexDirection="column"
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12}>
                  <Typography variant="body1" component="p" color="#909090">
                    Save time by selecting this option, where you will be asked for the customer
                    email and an invitation would be triggered to it, after which the applicant need
                    to complete the personal information using that link.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container flexDirection="column" display="block" columnSpacing={2}>
                {CoAppFirstName && (
                  <Grid item lg={5} md={5} sm={12} sx={{ margin: '10px 0' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={inviteCoapplicant}
                          onChange={() => setInviteCoapplicant(!inviteCoapplicant)}
                          name="coApplicantInvite"
                          color="secondary"
                          sx={{ paddingRight: '8px' }}
                          inputProps={{
                            'data-testid': 'coApplicantInvite',
                            'aria-labelledby': 'Co-applicant invite',
                          }}
                        />
                      }
                      label="Send invite to Co-applicant"
                      aria-label="Send invite to Co-applicant"
                      sx={{
                        margin: 0,
                        '.MuiFormControlLabel-label': {
                          fontSize: '14px !important',
                        },
                      }}
                    />
                  </Grid>
                )}
                {inviteCoapplicant && (
                  <Grid item lg={5} md={5} sm={12} sx={{ margin: '10px 0' }}>
                    <CustomInputBox
                      control={control}
                      errors={!!errors?.CoApplicantEmail}
                      errorText={errors?.CoApplicantEmail?.message}
                      placeholder="Enter co-applicant email"
                      label="Co-applicant email"
                      name="CoApplicantEmail"
                      regex={regexEmail}
                      maxLength={50}
                      flexDirection="column"
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={1} alignItems="center" sx={{ margin: '10px 0' }}>
                <Grid item lg={2.5} md={3} sm={12}>
                  {CoSigner[0]?.CoSignerName?.CoSignerFirstName && (
                    <Typography variant="subtitle2" component="p">
                      Send invite to
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={2.5} md={3} sm={12}>
                  {CoSigner[0]?.CoSignerName?.CoSignerFirstName && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={inviteCoSignerOne}
                          onChange={() => setInviteCoSignerOne(!inviteCoSignerOne)}
                          name="coSignerInviteOne"
                          color="secondary"
                          variant="subtitle2"
                          sx={{ paddingRight: '8px' }}
                          inputProps={{ 'data-testid': 'coSignerInviteOne' }}
                        />
                      }
                      label="Co-signer 1"
                      variant="subtitle2"
                      sx={{
                        margin: 0,
                        '.MuiFormControlLabel-label': {
                          fontSize: '14px !important',
                        },
                      }}
                    />
                  )}
                </Grid>
                <Grid item lg={2.5} md={3} sm={12}>
                  {CoSigner[1]?.CoSignerName?.CoSignerFirstName && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={inviteCoSignerTwo}
                          onChange={() => setInviteCoSignerTwo(!inviteCoSignerTwo)}
                          name="coSignerInviteTwo"
                          color="secondary"
                          variant="subtitle2"
                          sx={{ paddingRight: '8px' }}
                          inputProps={{ 'data-testid': 'coSignerInviteTwo' }}
                        />
                      }
                      label="Co-signer 2"
                      variant="subtitle2"
                      sx={{
                        margin: 0,
                        '.MuiFormControlLabel-label': {
                          fontSize: '14px !important',
                        },
                      }}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={2} pb={2} justifyContent>
                {CoSigner[0]?.CoSignerName?.CoSignerFirstName && inviteCoSignerOne && (
                  <Grid item lg={5} md={5} sm={12}>
                    <CustomInputBox
                      control={control}
                      errors={!!errors?.CoSigner1Email}
                      errorText={errors?.CoSigner1Email?.message}
                      placeholder="Enter co-signer 1 email"
                      label="Co-signer 1 email"
                      name="CoSigner1Email"
                      regex={regexEmail}
                      maxLength={50}
                      flexDirection="column"
                    />
                  </Grid>
                )}

                {CoSigner[1]?.CoSignerName?.CoSignerFirstName && inviteCoSignerTwo && (
                  <Grid item lg={5} md={5} sm={12}>
                    <CustomInputBox
                      control={control}
                      errors={!!errors?.CoSigner2Email}
                      errorText={errors?.CoSigner2Email?.message}
                      placeholder="Enter co-signer 2 email"
                      label="Co-signer 2 email"
                      name="CoSigner2Email"
                      regex={regexEmail}
                      maxLength={50}
                      flexDirection="column"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}

          <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              sx={{ maxHeight: '40px' }}
              id="cancel-btn"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              autoFocus
              sx={{ maxHeight: '40px' }}
              onClick={handleSubmit(handleContinue)}
              disabled={!watchInviOrManual}
              id="continue-btn"
            >
              Ok, continue
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InvitationMaualDialog;
InvitationMaualDialog.propTypes = {
  reset: PropTypes.func,
  control: PropTypes.func,
  setValue: PropTypes.func,
};
InvitationMaualDialog.defaultProps = {
  reset: defaultFn,
  control: defaultFn,
  setValue: defaultFn,
};
