import { yupResolver } from '@hookform/resolvers/yup';
import { Avatar, Badge, Box, Button, Grid, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import { handleTokenExpiry } from 'Services/commonComponentsApi';
import Success from 'assets/svg/ApprovedSubIcon.svg';
import Camera from 'assets/svg/Camera.svg';
import Pencil from 'assets/svg/pencil.svg';
import axios from 'axios';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { email } from 'schemaValidations/validationFunctions/validationFunctions';
import classes from 'styles/profile.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import {
  defaultFn,
  errorHandlingMessage,
  regexEmail,
  regexEmail2,
  replaceNullValues,
} from 'utils/utility';
import * as yup from 'yup';

import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomPhoneNumber from 'components/CustomPhoneNumber/CustomPhoneNumber';
import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

import ProfilePictureChangeModal from '../ProfilePictureChangeModal/ProfilePictureChangeModal';

export const ProfilePersonalInformation = ({ setLoader, loader }) => {
  const [profileChangeModal, setProfileChangeModal] = useState(false);

  const [editEmail, setEditEmail] = useState(false);

  const [editPhone, setEditPhone] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [dealersList, setDealersList] = useState('');
  const [apiErr, setApiErr] = useState('');
  const [isApiSuccess, setISApiSuccess] = useState(false);
  const ApiClient = new ApiClass();
  const { role, userID, setProfilePic, commonToken, setCommonToken } = useContext(AuthContext);
  const accessTokenFromIdToken = sessionStorage.getItem('accessTokenFromIdToken');
  console.log({ accessTokenFromIdToken });
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .nullable()
          .test('length', 'Invalid email format', (val) => {
            return val ? yup.string().matches(regexEmail2).isValidSync(val) : true;
          })
          .test('length', 'Invalid email length', (val) => {
            return val ? val?.length <= 50 : true;
          }),
      })
    ),
  });
  const {
    control: control1,
    setValue: setValue1,
    watch: watch1,
    handleSubmit: handleSubmit1,
    clearErrors: clearErrors1,
    setError: setError1,
    formState: { errors: errors1 },
  } = useForm({
    mode: 'all',
    defaultValues: {
      mobile: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        mobile: yup
          .string()
          .test('mobile invalid', 'Invalid Format', (val) => {
            if (!val) {
              return true;
            }
            return yup.string().matches(/^\d+$/g).isValidSync(val);
          })
          .test('length', 'Mobile number should have 10 digits', (val) => {
            return val ? val?.length === 10 : true;
          }),
      })
    ),
  });
  const watchEmail = watch('email');
  const watchPhone = watch1('mobile');
  const handlePhoneVerify = async (data) => {
    let isValidMobile = await handlePhoneMellisa(data?.mobile);
    if (isValidMobile) {
      handlePhomeEmailVerify({ phone: data?.mobile });
    }
  };
  const handlePhoneMellisa = async (phone) => {
    try {
      let isValid = true;

      setLoader(true);
      let response = await axios.post(
        UrlHelpers.commonComponentsPhoneVerification,
        [
          {
            phoneNumber: phone,
            eventType: 'UI',
            source: 'auto',
          },
        ],
        {
          headers: { Authorization: `Bearer ${commonToken}`, 'Content-Type': 'application/json' },
        }
      );
      response = response?.data;
      const mobile1status = response.find((x) => x.phoneNumber === phone)?.status;
      if (mobile1status !== 'Valid') {
        setError1(`mobile`, { type: 'custom', message: 'Invalid phone number' });
        isValid = false;
      } else {
        clearErrors1(`mobile`);
      }

      return isValid;
    } catch (e) {
      handleTokenExpiry(e, setCommonToken);
      setApiErr(e?.response?.data?.message || 'Please retry');
    } finally {
      setLoader(false);
    }
  };
  const handleEmailMellisa = async (emaill) => {
    try {
      let isValid = true;
      setLoader(true);
      let response = await axios.post(
        UrlHelpers.commonComponentsEmailVerification,
        [
          {
            email: emaill,
            eventType: 'UI',
            source: 'auto',
          },
        ],
        {
          headers: { Authorization: `Bearer ${commonToken}`, 'Content-Type': 'application/json' },
        }
      );
      response = response?.data;
      const { status, message } = response[0];
      if (status !== 'Valid' && message === 'Email Domain Not Found') {
        setError(`email`, { type: 'custom', message: 'Invalid email domain' });
        isValid = false;
      } else if (status !== 'Valid') {
        setError(`email`, { type: 'custom', message: 'Invalid email' });
        isValid = false;
      }
      return isValid;
    } catch (e) {
      handleTokenExpiry(e, setCommonToken);
      setApiErr(e?.response?.data?.message || 'Please retry');
    } finally {
      setLoader(false);
    }
  };
  const handleEmailVerify = async (data) => {
    let isValidEmail = await handleEmailMellisa(data?.email);
    if (isValidEmail) {
      handlePhomeEmailVerify({ Email: data?.email });
    }
  };
  const handlePhomeEmailVerify = async ({ phone, Email }) => {
    const payload = { username: userID };
    if (phone) {
      payload.phone = phone?.slice(0, 2) === '+1' ? `${phone}` : `+1${phone}`;
    }
    if (Email) {
      payload.email = Email;
    }
    try {
      setLoader(true);
      let emailPhoneVerify = await ApiClient.post(
        `${UrlHelpers?.uploadEmailPhone}`,

        payload,
        {
          headers: {
            appid: 'auto',

            roleid: role,
            user: accessTokenFromIdToken,
            'Content-Type': 'application/json',
          },
        }
      );

      if (emailPhoneVerify?.messages[0]?.code === '200') {
        setISApiSuccess(true);
        setApiErr('Contact information updated successfully');
        if (phone) {
          setEditPhone(false);
        }
        if (email) {
          setEditEmail(false);
        }
        onLoadApi();
      }
    } catch (e) {
      console.log('error fetching', e);
      setISApiSuccess(false);
      setApiErr(errorHandlingMessage[e?.response?.data?.errorCode || 'defaultCode']?.message);
    } finally {
      setLoader(false);
    }
  };
  const onLoadApi = async () => {
    try {
      setLoader(true);
      let onLoadProfileData = await ApiClient.get(`${UrlHelpers?.onLoadprofileData}`, {
        headers: {
          appid: 'auto',

          roleid: role,
          user: accessTokenFromIdToken,
          'Content-Type': 'application/json',
        },
      });
      if (onLoadProfileData) {
        setISApiSuccess(true);
        setProfileData(onLoadProfileData);
        const dealerList = onLoadProfileData?.dealers?.map((x) => x?.dealerName)?.join(', ') || '';
        setDealersList(dealerList);
        setProfilePic(onLoadProfileData?.picture);
      }
    } catch (e) {
      console.log('error fetching', e);
      setISApiSuccess(false);
      setApiErr(errorHandlingMessage[e?.response?.data?.errorCode || 'defaultCode']?.message);
    } finally {
      setLoader(false);
    }
  };
  useEffect(async () => {
    onLoadApi();
  }, []);

  return (
    <Grid container mx={3}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Loader open={loader} />
        <Box>
          <Typography variant="h3" component="div" pb={4}>
            Personal information
          </Typography>
          <ProfilePictureChangeModal
            profileChangeModal={profileChangeModal}
            setProfileChangeModal={setProfileChangeModal}
            loader={loader}
            setLoader={setLoader}
            fetchPersonalDetail={onLoadApi}
            setApiErr={setApiErr}
            setISApiSuccess={setISApiSuccess}
          />
          <Box>
            <Badge
              badgeContent={<img src={Camera} />}
              data-testid="profile-picture"
              onClick={() => setProfileChangeModal(true)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              className={classes.BadgeIcon}
              sx={{
                '.MuiBadge-badge': {
                  background: 'linear-gradient(180deg, #FFF 0%, #F3F3F3 100%)',
                  padding: '10px',
                  border: '1px solid #909090',
                  height: 'auto',
                  bottom: '11%',
                  right: '15%',
                  borderRadius: '50%',
                },
              }}
            >
              <Avatar alt="Remy Sharp" src={profileData?.picture} className={classes.avatarImage} />
            </Badge>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} mr={2}>
        <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} success={isApiSuccess} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} my={3}>
        <Box
          display="grid"
          gridTemplateColumns="20% 50%"
          rowGap={2}
          columnGap={2}
          alignItems="center"
          my={2}
        >
          <Typography variant="subtitle2" component="p">
            First name
          </Typography>
          <Typography color="secondary" fontWeight="700" variant="subtitle2" component="p">
            {replaceNullValues(profileData?.firstName)}
          </Typography>

          <Typography variant="subtitle2" component="p">
            Last name
          </Typography>
          <Typography color="secondary" fontWeight="700" variant="subtitle2" component="p">
            {replaceNullValues(profileData?.lastName)}
          </Typography>
          <Typography variant="subtitle2" component="p">
            Second last name
          </Typography>
          <Typography color="secondary" fontWeight="700" variant="subtitle2" component="p">
            {replaceNullValues(profileData?.secondLastName)}
          </Typography>
          <Typography variant="subtitle2" component="p">
            Dealer name
          </Typography>
          <Typography color="secondary" fontWeight="700" variant="subtitle2" component="p">
            {replaceNullValues(dealersList)}
          </Typography>

          <Typography variant="subtitle2" component="p">
            Phone
          </Typography>

          {editPhone ? (
            <Box display="flex" alignItems="flex-start" gap={2}>
              <CustomPhoneNumber
                name="mobile"
                placeholder="Enter Phone"
                flexDirection="column"
                control={control1}
                error={!!errors1?.mobile}
                errorText={errors1?.mobile?.message}
              />
              <Button
                variant="contained"
                color="secondary"
                sx={{ minWidth: 'auto', margin: '8px 0 !important' }}
                onClick={handleSubmit1(handlePhoneVerify)}
                data-testid="mobileUpdate"
                my={1}
                disabled={errors1?.mobile || watchPhone?.length <= 0}
              >
                Verify
              </Button>
            </Box>
          ) : (
            <Box display="flex" alignItems="flex-end">
              <Typography
                color="secondary"
                fontWeight="700"
                variant="subtitle2"
                component="p"
                display="flex"
                alignItems="center"
              >
                {profileData?.phone?.slice(2) ? (
                  <>
                    <img src={Success} /> &ensp; {profileData?.phone?.slice(2)}
                  </>
                ) : (
                  '--'
                )}
              </Typography>{' '}
              &emsp;
              <img
                src={Pencil}
                data-testid="phone"
                onClick={() => {
                  setEditPhone(true);
                  setValue1('mobile', profileData?.phone?.slice(2));
                }}
              />
            </Box>
          )}
          <Typography variant="subtitle2" component="p">
            Email
          </Typography>
          {editEmail ? (
            <Box display="flex" alignItems="flex-start" gap={2}>
              <CustomInputBox
                control={control}
                errors={!!errors?.email}
                errorText={errors?.email?.message}
                placeholder="Enter Email"
                regex={regexEmail}
                maxLength={50}
                flexDirection="column"
                name="email"
              />
              <Button
                variant="contained"
                color="secondary"
                sx={{ minWidth: 'auto', margin: '8px 0 !important' }}
                data-testid="emailUpdate"
                onClick={handleSubmit(handleEmailVerify)}
                disabled={errors?.email || watchEmail?.length <= 0}
              >
                Verify
              </Button>
            </Box>
          ) : (
            <Box display="flex" alignItems="flex-end">
              <Typography
                color="secondary"
                fontWeight="700"
                variant="subtitle2"
                component="p"
                display="flex"
                alignItems="center"
              >
                {profileData?.email ? (
                  <>
                    <img src={Success} /> &ensp; {profileData?.email}
                  </>
                ) : (
                  '--'
                )}
              </Typography>
              &emsp;
              <img
                src={Pencil}
                data-testid="email"
                onClick={() => {
                  setEditEmail(true);
                  setValue('email', profileData?.email);
                }}
              />
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

ProfilePersonalInformation.propTypes = {
  setLoader: PropTypes.func,
  loader: PropTypes.bool,
};
ProfilePersonalInformation.defaultProps = {
  setLoader: defaultFn,
  loader: false,
};
