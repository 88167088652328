/* eslint-disable react/prop-types */
import { Box, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import classes from 'styles/globalStyle.module.scss';
import { defaultFn, numberToCurrency } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';

const InformationRequestSection = ({
  informationRequest,
  label,
  viewAppFlow,
  setOpenPopup,
  setLandingUser,
  setLandingStep,
}) => {
  const { maritalStatusList } = useContext(AuthContext);
  const maritalMapping =
    maritalStatusList.find(
      (x) => x.value === informationRequest?.participantDetailInfo?.maritalStatus
    )?.name || '--';
  const [isSame, setIsSame] = useState('No');
  useEffect(() => {
    if (informationRequest?.participantDetailInfo?.addresses?.length > 0) {
      const isSameAddress = isSameCheck();
      setIsSame(isSameAddress);
    }
  }, [informationRequest?.participantDetailInfo?.addresses?.length]);
  const isSameCheck = () => {
    const newArray =
      informationRequest?.participantDetailInfo?.addresses?.length > 0 &&
      informationRequest?.participantDetailInfo?.addresses.map((x) => {
        let obj = { ...x };
        delete obj.addrType;
        delete obj.utilityBill;
        return obj;
      });
    const residential = JSON.stringify(newArray?.[0]);
    const mailing = JSON.stringify(newArray?.[1]);
    console.log('same check', residential === mailing, residential, mailing);
    if (residential === mailing) {
      return 'Yes';
    }
    return 'No';
  };
  const { setReviewFlag, setActiveStep, setUserType } = useContext(MainApplicationContext);
  const onEdit = () => {
    let landingUserType = '';
    if (label.indexOf('Applicant') > -1) {
      landingUserType = 'Applicant';
    } else if (label.indexOf('Co-applicant') > -1) {
      landingUserType = 'Co-applicant';
    } else if (label.indexOf('Co-signer 1') > -1) {
      landingUserType = 'Co-signer 1';
    } else if (label.indexOf('Co-signer 2') > -1) {
      landingUserType = 'Co-signer 2';
    }
    if (viewAppFlow) {
      setOpenPopup(true);
      setLandingUser(landingUserType);
      setLandingStep(1);
    } else {
      setUserType(landingUserType);
      setReviewFlag(true);
      setActiveStep(1);
    }
  };
  const mobileNumber =
    `(${informationRequest?.participantInfo?.contacts?.mobile?.slice(0, 3)}) ` +
    `${informationRequest?.participantInfo?.contacts?.mobile?.slice(3, 6)}` +
    '-' +
    `${informationRequest?.participantInfo?.contacts?.mobile?.slice(6)}`;
  const alterMobNum =
    `(${informationRequest?.participantInfo?.contacts?.alternatePhoneNumber?.slice(0, 3)}) ` +
    `${informationRequest?.participantInfo?.contacts?.alternatePhoneNumber?.slice(3, 6)}` +
    '-' +
    `${informationRequest?.participantInfo?.contacts?.alternatePhoneNumber?.slice(6)}`;

  const ssn =
    informationRequest?.participantDetailInfo?.socialSecurityNumber?.slice(0, 3) +
    '-' +
    informationRequest?.participantDetailInfo?.socialSecurityNumber?.slice(3, 5) +
    '-' +
    informationRequest?.participantDetailInfo?.socialSecurityNumber?.slice(5);
  return (
    <CustomCollapse
      heading={`Demographic information : ${label}`}
      open={viewAppFlow ? false : true}
      editable={true}
      onEdit={onEdit}
    >
      <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25} id="primary-info">
        <Typography variant="subtitle2">First name</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantInfo?.customerName?.firstName}
        </Typography>
        <Typography variant="subtitle2">Middle name</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantInfo?.customerName?.middleName || '--'}
        </Typography>
        <Typography variant="subtitle2">Last name</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantInfo?.customerName?.lastName}
        </Typography>
        <Typography variant="subtitle2">Second last name</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantInfo?.customerName?.secondLastName || '--'}
        </Typography>

        <Typography variant="subtitle2">Date of birth</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantDetailInfo?.dateOfBirth}
        </Typography>
        <Typography variant="subtitle2">Mobile</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantInfo?.contacts?.mobile ? mobileNumber : '--'}
        </Typography>
        <Typography variant="subtitle2">Alternate phone number</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantInfo?.contacts?.alternatePhoneNumber ? alterMobNum : '--'}
        </Typography>
        <Typography variant="subtitle2">Email</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantInfo?.contacts?.applicantEmail}
        </Typography>
        <Typography variant="subtitle2">Social security number</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantDetailInfo?.socialSecurityNumber ? ssn : '--'}
        </Typography>
        <Typography variant="subtitle2">Marital status</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {maritalMapping}
        </Typography>
      </Box>
      <Typography
        variant="h6"
        fontWeight="700 !important"
        my="20px !important"
        id="residential-address"
      >
        Residential address
      </Typography>
      <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25} id="residential-address-info">
        <Typography variant="subtitle2">Utility bill</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantDetailInfo?.addresses?.[0]?.utilityBill || '--'}
        </Typography>
        <Typography variant="subtitle2">Address line 1</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantDetailInfo?.addresses?.[0]?.lineOne}
        </Typography>
        <Typography variant="subtitle2">Address line 2</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantDetailInfo?.addresses?.[0]?.lineTwo || '--'}
        </Typography>
        <Typography variant="subtitle2">Country</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantDetailInfo?.addresses?.[0]?.addrCountry}
        </Typography>
        <Typography variant="subtitle2">State</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantDetailInfo?.addresses?.[0]?.addrState}
        </Typography>
        <Typography variant="subtitle2">City</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantDetailInfo?.addresses?.[0]?.addrCity}
        </Typography>
        <Typography variant="subtitle2">Zip code</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantDetailInfo?.addresses?.[0]?.zipCode}
        </Typography>
      </Box>

      <Typography
        variant="h6"
        fontWeight="700 !important"
        my="20px !important"
        id="mailing-address"
      >
        Mailing address
      </Typography>
      <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25} id="mailing-address-info">
        <Typography variant="subtitle2">Mailing address same as the residential address</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {isSame}
        </Typography>
        <Typography variant="subtitle2">Address line 1</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantDetailInfo?.addresses?.[1]?.lineOne}
        </Typography>
        <Typography variant="subtitle2">Address line 2</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantDetailInfo?.addresses?.[1]?.lineTwo || '--'}
        </Typography>
        <Typography variant="subtitle2">Country</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantDetailInfo?.addresses?.[1]?.addrCountry}
        </Typography>
        <Typography variant="subtitle2">State</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantDetailInfo?.addresses?.[1]?.addrState}
        </Typography>
        <Typography variant="subtitle2">City</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantDetailInfo?.addresses?.[1]?.addrCity}
        </Typography>
        <Typography variant="subtitle2">Zip code</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantDetailInfo?.addresses?.[1]?.zipCode}
        </Typography>
      </Box>

      <Typography
        variant="h6"
        fontWeight="700 !important"
        my="20px !important"
        id="residential-status"
      >
        Residential status
      </Typography>
      <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25} id="residential-status-info">
        <Typography variant="subtitle2">Residence status</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.participantDetailInfo?.residentialStatus}
        </Typography>
        {informationRequest?.participantDetailInfo?.monthlyPayment != 0 && (
          <>
            <Typography variant="subtitle2">Monthly payment</Typography>
            <Typography variant="subtitle2" className={classes.currencyValue}>
              {numberToCurrency(informationRequest?.participantDetailInfo?.monthlyPayment)}
            </Typography>
          </>
        )}
        <Typography variant="subtitle2">Time at residence</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {`${informationRequest?.participantDetailInfo?.timeOfResidenceYears} Years ${informationRequest?.participantDetailInfo?.timeOfResidenceMonths} Months`}
        </Typography>
      </Box>
    </CustomCollapse>
  );
};

export default InformationRequestSection;

InformationRequestSection.propTypes = {
  informationRequest: PropTypes.instanceOf(Object),
  label: PropTypes.string,
  viewAppFlow: PropTypes.bool,
  setOpenPopup: PropTypes.func,
  setLandingUser: PropTypes.func,
  setLandingStep: PropTypes.func,
};
InformationRequestSection.defaultProps = {
  informationRequest: {},
  label: '',
  viewAppFlow: false,
  setOpenPopup: defaultFn,
  setLandingUser: defaultFn,
  setLandingStep: defaultFn,
};
