import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, Grid } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import moment from 'moment';
import { stateListApi } from 'pages/CreateApplication/InformationRequest/moduleUtilities/dropdownApis';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { contractInfoSchema } from 'schemaValidations/contractInfoSchema';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn } from 'utils/utility';

import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomRadioButton from 'components/CustomRadioButton/CustomRadioButton';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';
import Loader from 'components/Loader/Loader';

const ContractInfo = ({ appId, setDisableContractDocs }) => {
  const [loader, setLoader] = useState(false);
  const ApiClient = new ApiClass();
  const [postMsg, setPostMsg] = useState('');
  const [isMounted, setIsMounted] = useState(false);
  const { stateList, setStateList, stateCityList, setstateCityList } = useContext(AuthContext);
  const {
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
    reset,
    clearErrors,
  } = useForm({
    mode: 'all',
    defaultValues: {
      collateralColor: '',
      vehicleLicensePlate: '',
      payment: '',
      accountNumber: '',
      accountType: '',
      routingNumber: '',
      bank: '',
      vinNumber: '',
      cylinders: 0,
      doors: 0,
      drivingLicenseId: '',
      licenseExpirationDate: '',
      licenseIssueState: '',
    },
    resolver: yupResolver(contractInfoSchema),
  });
  const watchPayment = watch('payment');

  console.log('watch all', watch());
  const addToAppln = async (data) => {
    console.log('data', data);
    const {
      collateralColor,
      vehicleLicensePlate,
      payment,
      accountNumber,
      accountType,
      routingNumber,
      bank,
      vinNumber,
      cylinders,
      doors,
      drivingLicenseId,
      licenseExpirationDate,
      licenseIssueState,
    } = data;
    try {
      setLoader(true);
      const id = await ApiClient.post(
        UrlHelpers?.contractInfo,
        {
          appId: appId,
          collateralColor: collateralColor,
          vehicleLicensePlate: vehicleLicensePlate,
          paymentType: payment,
          accountNumber: accountNumber,
          accountType: accountType,
          routingNumber: routingNumber,
          bank: bank,
          vinNumber: vinNumber,
          cylinders,
          doors,
          drivingLicenseId,
          licenseExpirationDate: moment(licenseExpirationDate).format('MM/DD/YYYY'),
          licenseIssueState,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (id) {
        console.log('into success');
        setDisableContractDocs(false);
        setPostMsg({ severity: 'success', message: 'Details added to application successfully' });
      }
    } catch (e) {
      setPostMsg({
        severity: 'error',
        message:
          e?.response?.data?.Message ||
          e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later',
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (isMounted) {
      setValue('bank', '');
      setValue('routingNumber', '');
      setValue('accountNumber', '');
      setValue('accountType', '');
      clearErrors(['bank', 'routingNumber', 'accountNumber', 'accountType']);
    }
  }, [watchPayment]);

  useEffect(async () => {
    await stateListApi({ stateCityList, setstateCityList, setStateList });
    await getContractInfo();
    setIsMounted(true);
  }, []);

  const getContractInfo = async () => {
    try {
      setLoader(true);
      const resp = await ApiClient.get(`${UrlHelpers.contractInfo}/?appid=${appId}`);

      console.log('resp', resp);
      const {
        iscontractInfoSubmitted,
        collateralColor,
        vehicleLicensePlate,
        paymentType,
        accountNumber,
        bank,
        routingNumber,
        vinNumber,
        accountType,
        cylinders,
        doors,
        drivingLicenseId,
        licenseExpirationDate,
        licenseIssueState,
      } = resp;
      if (!iscontractInfoSubmitted) {
        setDisableContractDocs(true);
      }
      reset({
        collateralColor,
        vehicleLicensePlate,
        payment: paymentType,
        accountNumber,
        bank,
        routingNumber,
        vinNumber,
        accountType,
        cylinders,
        doors,
        drivingLicenseId,
        licenseExpirationDate,
        licenseIssueState,
      });
    } catch (e) {
      console.log('err in get contract docs api', e);
      setDisableContractDocs(true);
      setPostMsg({
        severity: 'error',
        message:
          e?.response?.data?.Message ||
          e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later',
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" m={2} alignItems="center">
      <Loader open={loader} />
      <Grid container rowSpacing={1} columnSpacing={4} width="60%">
        {postMsg && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Alert
              severity={postMsg?.severity}
              onClose={() => {
                setPostMsg('');
              }}
              sx={{ margin: '8px', width: '100%' }}
              data-testid="post-failure-alert"
            >
              {postMsg?.message}
            </Alert>
          </Grid>
        )}
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.collateralColor}
            errorText={errors?.collateralColor?.message}
            maxLength="20"
            regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ\s]$/}
            placeholder="Enter collateral color"
            label="Collateral color"
            name="collateralColor"
            flexDirection="column"
            required
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.vehicleLicensePlate}
            errorText={errors?.vehicleLicensePlate?.message}
            maxLength="15"
            regex={/^[A-Za-z0-9áéíóúñüÁÉÍÓÚÑÜ]$/}
            placeholder="Enter vehicle license plate"
            name="vehicleLicensePlate"
            label="Vehicle license plate"
            flexDirection="column"
            required
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomRadioButton
            control={control}
            radioValues={[
              { label: 'ACH', value: 'ACH' },
              { label: 'AutoPay', value: 'Autopay' },
            ]}
            label="Payment"
            required
            name="payment"
            errors={!!errors?.payment}
            errorText={errors?.payment?.message}
          />
        </Grid>
        {!!watchPayment && (
          <>
            {' '}
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomInputBox
                control={control}
                errors={!!errors?.accountNumber}
                errorText={errors?.accountNumber?.message}
                maxLength="20"
                regex={/^[A-Za-z0-9]*$/}
                placeholder="Enter account number"
                label="Account number"
                name="accountNumber"
                flexDirection="column"
                required
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                control={control}
                options={[
                  {
                    name: 'Checking',
                    value: 'checking',
                  },
                  {
                    name: 'Saving',
                    value: 'saving',
                  },
                ]}
                label="Account type"
                required
                name="accountType"
                errors={!!errors?.accountType}
                errorText={errors?.accountType?.message}
                displayEmpty
              />
            </Grid>
          </>
        )}
        {watchPayment === 'ACH' && (
          <>
            {' '}
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomInputBox
                control={control}
                errors={!!errors?.routingNumber}
                errorText={errors?.routingNumber?.message}
                maxLength="20"
                regex={/^\d$/}
                placeholder="Enter routing number"
                label="Routing number"
                name="routingNumber"
                flexDirection="column"
                required
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomInputBox
                control={control}
                errors={!!errors?.bank}
                errorText={errors?.bank?.message}
                maxLength="15"
                regex={/^[A-Za-z0-9\s]*$/}
                placeholder="Enter bank"
                label="Bank"
                name="bank"
                flexDirection="column"
                required
              />
            </Grid>
          </>
        )}
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.vinNumber}
            errorText={errors?.vinNumber?.message}
            maxLength="17"
            regex={/^[A-HJ-NPR-Za-hj-npr-z0-9]*$/}
            placeholder="Enter VIN number"
            label="VIN number"
            name="vinNumber"
            flexDirection="column"
            required
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.cylinders}
            errorText={errors?.cylinders?.message}
            maxLength="1"
            regex={/^\d*$/}
            placeholder="Enter number of cylinders"
            label="Number of cylinders"
            name="cylinders"
            flexDirection="column"
            required
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.doors}
            errorText={errors?.doors?.message}
            maxLength="1"
            regex={/^\d*$/}
            placeholder="Enter number of doors"
            label="Number of doors"
            name="doors"
            flexDirection="column"
            required
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.drivingLicenseId}
            errorText={errors?.drivingLicenseId?.message}
            maxLength="20"
            regex={/^[A-Za-z0-9\s]*$/}
            placeholder="Enter driving license number"
            label="Driving license number"
            name="drivingLicenseId"
            flexDirection="column"
            required
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomDatePicker
            control={control}
            name="licenseExpirationDate"
            error={!!errors?.licenseExpirationDate}
            errorText={errors?.licenseExpirationDate?.message}
            minDate={new Date()}
            placeHolder="License expiration date"
            label="License expiration date"
            required
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomSelectBox
            flexDirection="column"
            label="State"
            options={stateList}
            control={control}
            name="licenseIssueState"
            errors={!!errors?.licenseIssueState}
            errorText={errors?.licenseIssueState?.message}
            required
            displayEmpty
          />
        </Grid>
        <Grid container item justifyContent="flex-end">
          <Button variant="contained" color="secondary" onClick={handleSubmit(addToAppln)}>
            Add to application
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContractInfo;

ContractInfo.propTypes = {
  appId: PropTypes.string,
  setDisableContractDocs: PropTypes.func,
};
ContractInfo.defaultProps = {
  appId: '',
  setDisableContractDocs: defaultFn,
};
