import { Box, Button, InputLabel, Typography } from '@mui/material';
import UploadIcon from 'assets/svg/uploadIcon.svg';
import PropTypes from 'prop-types';
import { FileUploader } from 'react-drag-drop-files';
import { Controller } from 'react-hook-form';
import customClasses from 'styles/InformationRequest.module.scss';
import { defaultFn } from 'utils/utility';

const CustomFileUploader = ({
  control,
  name,
  handleChange,
  fileTypes,
  setError,
  fileTypeErr,
  fileType,
  label,
  isMultiple,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box display="flex" flexDirection="column" gap={0.625}>
          {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
          <FileUploader
            handleChange={handleChange}
            inputProps={{
              'data-testid': name,
            }}
            name="file"
            types={fileTypes}
            multiple={isMultiple}
            onTypeError={() => {
              setError(name, {
                type: 'custom',
                message: fileTypeErr
                  ? fileTypeErr
                  : 'Only files with the following formats are allowed: PNG, JPG, PDF. Click to upload again',
              });
            }}
            // maxSize={10.5}
            // onSizeError={() => {
            //   setError(name, {
            //     type: 'custom',
            //     message: 'The file exceeds the maximum upload size (10 MB). Click to upload again',
            //   });
            // }}
            {...field}
          >
            <Box
              className={customClasses.uploadContainer}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{ background: 'transparent' }}
            >
              <Typography variant="subtitle2" component="p">
                Drag and drop your file here
              </Typography>
              <Typography variant="subtitle2" component="p" sx={{ padding: '20px 0' }}>
                or
              </Typography>
              <Box
                sx={{
                  display: 'block',
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  display="flex"
                  sx={{
                    borderRadius: '4px',
                  }}
                  id="upload-btn"
                >
                  <img src={UploadIcon} /> &nbsp;
                  <Typography
                    variant="subtitle1"
                    component="p"
                    sx={{ fontWeight: '700' }}
                  ></Typography>
                  Upload
                </Button>
              </Box>

              <Typography
                component="p"
                sx={{ color: '#909090', fontSize: '10px', marginTop: '20px' }}
              >
                {fileType}
              </Typography>
            </Box>
          </FileUploader>
        </Box>
      )}
    />
  );
};

export default CustomFileUploader;

CustomFileUploader.propTypes = {
  control: PropTypes.instanceOf(Object),
  name: PropTypes.string,
  handleChange: PropTypes.func,
  fileTypes: PropTypes.array,
  fileErr: PropTypes.string,
  setFileErr: PropTypes.func,
  errors: PropTypes.instanceOf(Object),
  errorText: PropTypes.string,
  flow: PropTypes.string,
  setError: PropTypes.func,
  setShowCamera: PropTypes.func,
  fileTypeErr: PropTypes.string,
  fileType: PropTypes.string,
  label: PropTypes.string,
  isMultiple: PropTypes.bool,
};
CustomFileUploader.defaultProps = {
  control: {},
  name: '',
  handleChange: defaultFn,
  fileTypes: [],
  fileErr: '',
  setFileErr: defaultFn,
  errors: {},
  errorText: '',
  setError: defaultFn,
  fileTypeErr: '',
  fileType: 'Max: 10MB supporting file types: PNG, JPG, PDF.',
  label: '',
  isMultiple: false,
};
