import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import { LockReleaseApi } from 'Services/LockReleaseApi';
import Business from 'assets/svg/business.svg';
import Personal from 'assets/svg/personal.svg';
import AuthContext from 'context/AuthContext';
import CreateApplicationContext from 'context/CreateApplicationContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn } from 'utils/utility';

import CustomToggleButtonGroup from 'components/CustomToggleButtonGroup/CustomToggleButtonGroup';
import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

import ApplicationDialogHeading from '../InformationRequest/moduleUtilities/ApplicationDialogHeading';

const ApplicationTypeDialog = ({ reset, control, setValue, watch }) => {
  const { showApptype, setShowAppType, setShowCoApp, setShowMoreDetail } =
    useContext(CreateApplicationContext);
  const { createAppApplicationId, userID } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const [apiErr, setApiErr] = useState('');
  const ApiClient = new ApiClass();
  const {
    setOpenPopUp,
    setCreateAppFlag,
    setCreateAppLookup,
    setCreateAppLaunchType,
    setDealer,
    fullName,
  } = useContext(AuthContext);
  const toggleChange = (e, value) => {
    setValue('applicationType', value);
  };
  useEffect(() => {
    setValue('applicationType', 'personal');
  }, []);
  const handleClose = () => {
    setShowAppType(false);
    setOpenPopUp(false);
    setCreateAppFlag(false);
    setCreateAppLaunchType('');
    reset();
    setCreateAppLookup('');
    setDealer('');
    LockReleaseApi({ appid: createAppApplicationId, fullName, userID });
  };
  const watchApplicationType = watch('applicationType');
  const handleContinue = async () => {
    try {
      setLoader(true);
      await ApiClient.post(
        UrlHelpers?.informationReqSave,
        {
          event: 'PreLoanTypeRequest',
          application: {
            applicationId: createAppApplicationId,
            products: {
              productType: 'Indirect Loan Account',
              productName: 'AutoLoan',
            },
            userId: userID,
            loanAppType: 'Personal',
            source: {
              language: 'EN',
              location: 'PR',
            },
          },
        },
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );
      setShowAppType(false);
      setShowCoApp(true);
    } catch (e) {
      console.log('e', e);
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later.'
      );
    } finally {
      setLoader(false);
    }
  };
  const applicationType = [
    { valuee: 'personal', displayValue: 'Personal', logo: Personal },
    { valuee: 'business', displayValue: 'Business', logo: Business },
  ];

  const handleBack = () => {
    setShowAppType(false);
    setShowMoreDetail(true);
  };
  return (
    <>
      <Dialog
        data-testid="close_btn"
        open={showApptype}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              minWidth: 'clamp(400px, 50vw, 800px) !important',
              minHeight: 'clamp(450px,60vh,100%) !important',
            },
          },
        }}
      >
        <Loader open={loader} />
        <ApplicationDialogHeading handleBack={handleBack} handleClose={handleClose} />

        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
        >
          <Box display="flex" flexDirection="column" gap={2.5} px={5} mb={2.5}>
            <Grid container sx={{ justifyContent: 'center' }}>
              <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
            </Grid>
            <Typography variant="h4" textAlign="center" my={3}>
              Choose the application you want to create
            </Typography>

            <CustomToggleButtonGroup
              exclusive={true}
              name="applicationType"
              control={control}
              toggleChange={toggleChange}
              toggleList={applicationType}
              disableCheck
              disableValue="Business"
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
            id="cancel-btn"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            sx={{ maxHeight: '40px' }}
            onClick={handleContinue}
            disabled={!watchApplicationType}
            id="continue-btn"
          >
            Ok, continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ApplicationTypeDialog;

ApplicationTypeDialog.propTypes = {
  reset: PropTypes.func,
  control: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
};
ApplicationTypeDialog.defaultProps = {
  reset: defaultFn,
  control: defaultFn,
  setValue: defaultFn,
  watch: defaultFn,
};
