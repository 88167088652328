/* eslint-disable no-unused-vars */
export const currencyToNumber = (currency) =>
  (currency && Number(currency.toString().replace(/[^0-9.-]+/g, ''))) || 0;

export const defaultFn = () => {};

export const getRes = (response) => {
  const { data: resData = {} } = response || {};
  return resData;
};

export const isApproved = (loanStatus) => {
  return ['APPROVE', 'APPCOND', 'APPSCOR', 'PENBK'].includes(loanStatus);
};

export const cityMapping = async (stateCityList, state) => {
  const resCities = stateCityList?.[0]?.states?.find((item) => item?.value === state);
  console.log('----', { stateCityList, resCities });
  let cityListt = [];
  cityListt = resCities?.cities?.map((x) => {
    const { displayName, value } = x;
    return { name: displayName, value };
  });
  console.log({ mocking: cityListt });
  return cityListt;
};
export const notificationEventMapping = {
  UPDATE_APP_STATUS: 'Application status update',
  DISCARD_APPLICATION: 'Draft application set to expire',
  PARTICIPANT_SUBMIT_INVITATION: 'Invitation status update',
  COMMENT_ADDED: 'New comment received',
  NEW_USER_REQUEST: 'User request status update',
  NEW_USER_REQUEST_REJECT: 'User request rejected',
  NEW_USER_REQUEST_APPROVED: 'User request approved',
  'AUTO REPORTS': 'Report status',
};

export const bankerRoles = {
  master: 'TEST AUTO OFGIND MASTER BD',
  employee: 'TEST AUTO BANK EMPLOYEES',
};

export const bankerRolesArray = ['TEST AUTO OFGIND MASTER BD', 'TEST AUTO BANK EMPLOYEES'];

export const handleFocusError = ({ errors, setFocus }) => {
  const firstError = Object.keys(errors);

  const arr = [
    'salePrice',
    'rate',
    'balloonPay',
    'cashDown',
    'rebate',
    'extendedWarranty',
    'carCare',
    'paintProtection',
    'other',
    'serviceContract',
    'tradeDept',
    'tradeAllowance',
    'vehicleInsuranceAmount',
    'gapFee',
    'balance',
    'creditLifeAmount',
    'netTrade',
    'residentYears',
    'resState',
    'resCity',
    'residentMonths',
    'addMonthlyIncome',
    'monthlyPayment',
    'monthlyIncome',
    'empYears',
    'ssn',
    'empMonths',
    'loanPurpose',
    'dealer',
    'manager',
    'dealerSalesReference',
    'addOccupation',
    'empStatus',
    'empCountry',
    'empState',
    'empCity',
    'occupation',
    'industry',
    'maritalStatus',
    'perState',
    'perCountry',
    'perCity',
    'resState',
    'resCountry',
    'resCity',
    'relationship',
    'resUtilityBill',
    'state',
    'year',
    'make',
    'model',
    'subModel',
    'style',
    'programVal',
    'promotion',
    'term',
    'creditLife',
    'creditLifeType',
    'vehicleInsuranceType',
    'monthDeferred',
    'includeLoanOriginator',
    'includeSalesManager',
    'includeReference',
    'canSeeOwnApplication',
    'securityProfile',
    'isActive',
    'maximizeTty',
    'accessType',
    'includeInLoanOriginator',
    'includeInSalesManager',
    'includeInReference',
    'active',
    'maximizeTY',
    'mobile',
    'mobile2',
  ];
  console.log('tes - 1', errors);
  if (firstError?.length > 0 && arr?.includes(firstError[0])) {
    console.log('tes - 2', errors);
    document.getElementById(`${firstError[0]}`)?.focus();
  } else if (firstError?.length > 0) {
    console.log('tes - 3', errors);
    setFocus(`${firstError[0]}`);
  }
};

export const stepMapping = {
  BasicInformationRequest: 0,
  InformationRequest: 1,
  InformationRquestInvitation: 1,
  InformationRequestInvitation: 1,
  EmploymentInformation: 2,
  EmploymentInformationInvitation: 2,
  ParticipantSubmitInvitation: 2,
  CollateralInformation: 3,
  FinancialTerms: 4,
  ReviewAndSubmit: 4,
  UPDATE_APPLICATION_STATUS: 4,
};

export const popupstepMapping = {
  CreateLoanApplication: 0,
  PreLoanTypeRequest: 1,
  PreApplicantInfoRequest: 2,
  PreCoApplicantCoSignerInfoRequest: 3,
  BasicInformationRequest: 4,
};

export const ReverseStepMapping = {
  0: 'basic information request',
  1: 'information on request',
  2: 'employment information',
  3: 'collateral information',
  4: 'financial terms',
  5: 'review and submit',
};
export const numberToCurrency = (number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(number || 0);
};

export const nameMapping = {
  Blackbook: 'Black Book',
  'Distributor Data': 'Distributor Data',
  new: 'New',
  used: 'Used',
};

export const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    console.log('parsed token', JSON.parse(jsonPayload));
    return JSON.parse(jsonPayload);
  } catch (e) {
    console.log('error in token parsing', e);
    return '';
  }
};

export const getBase64 = (file, callback) => {
  try {
    console.log('file', file);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      callback(reader.result);
    };
  } catch (e) {
    console.log('Error converting file to base64: ', e);
  }
};

export const blobToFile = (theBlob, fileName) => {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  const blob = theBlob;
  const { type } = blob;
  const ext = imgExtension(type);
  blob.lastModifiedDate = new Date();
  blob.name = `${fileName}.${ext}`;
  return blob;
};

export const imgExtension = (type) => {
  let format;
  if (type.includes('png')) {
    format = 'png';
  } else if (type.includes('pdf')) {
    format = 'pdf';
  } else if (type.includes('jpeg')) {
    format = 'jpg';
  }
  return format;
};
export const handleError = async ({ errResp, setTabValue, setError, setApiErr, user }) => {
  let applicants = ['Primary Applicant', 'Co-applicant', 'Co-signer1', 'Co-signer2'];
  const matchedApplicants = [];
  applicants.forEach((applicant) => {
    if (errResp?.toLowerCase().includes(applicant?.toLowerCase())) {
      console.log({ err: errResp?.toLowerCase(), app: applicant?.toLowerCase() });
      matchedApplicants.push({
        name: applicant,
        value: errResp?.toLowerCase().indexOf(applicant?.toLowerCase()),
        tabVal: applicants.indexOf(applicant),
      });
    }
  });
  console.log({ matchedApplicants });
  if (
    matchedApplicants[0]?.value < matchedApplicants[1]?.value ||
    matchedApplicants?.length === 1
  ) {
    await handleFocusErrMS({
      errResp,
      matchedApplicants: matchedApplicants[0],
      setError,
      user,
      setTabValue,
      setApiErr,
    });
  } else {
    await handleFocusErrMS({
      errResp,
      matchedApplicants: matchedApplicants[1],
      setError,
      user,
      setTabValue,
      setApiErr,
    });
  }
};
const handleFocusErrMS = async ({
  errResp,
  matchedApplicants,
  setError,
  user,
  setTabValue,
  setApiErr,
}) => {
  if (errResp?.includes('Mobile' || 'Phone')) {
    if (user !== 'inviFlow') {
      setTabValue(matchedApplicants?.tabVal);
      setError(`${user}[${matchedApplicants?.tabVal}].mobile`, {
        type: 'custom',
        message: errResp,
      });
      document.getElementById(`${user}[${matchedApplicants?.tabVal}].mobile`).focus();
    } else {
      setError(`mobile`, {
        type: 'custom',
        message: errResp,
      });
      document.getElementById(`mobile`).focus();
    }
  } else if (errResp?.includes('Email')) {
    console.log({ errResp, matchedApplicants, user });
    if (user !== 'inviFlow') {
      setTabValue(matchedApplicants?.tabVal);
      setError(`${user}[${matchedApplicants?.tabVal}].email`, {
        type: 'custom',
        message: errResp,
      });
      document.getElementById(`${user}[${matchedApplicants?.tabVal}].email`).focus();
    } else {
      setError(`email`, {
        type: 'custom',
        message: errResp,
      });
      document.getElementById(`email`).focus();
    }
  } else if (errResp?.includes('Employment Time')) {
    console.log({ errResp, matchedApplicants, user });
    if (user !== 'inviFlow') {
      setTabValue(matchedApplicants?.tabVal);
      setError(`${user}[${matchedApplicants?.tabVal}].empYears`, {
        type: 'custom',
        message: errResp,
      });
      document.getElementById(`${user}[${matchedApplicants?.tabVal}].empYears`).focus();
    } else {
      setError(`empYears`, {
        type: 'custom',
        message: errResp,
      });
      document.getElementById(`empYears`).focus();
    }
  } else {
    setApiErr(errResp);
  }
};
export const relationshipArray = [
  { name: 'Father-Mother', value: 'Father-Mother' },
  { name: 'Spouse', value: 'Spouse' },
  { name: 'Other', value: 'Other' },
  { name: 'Not related', value: 'Not related' },
];

export const removeNullValues = (value) => {
  if (value) {
    return value;
  }
  return '';
};

export const replaceNullValues = (value) => {
  return value ? value : '--';
};

export const checkAccess = async (accesspolicy, screenId = '*', actionId = '*') => {
  let isallowed;

  isallowed = accesspolicy?.allowedAccess?.findIndex(
    (x) =>
      (x?.screenId === screenId || x?.screenId === '*') &&
      (x?.actionId?.includes(actionId) || x?.actionId?.includes('*') || actionId === '*')
  );

  return isallowed >= 0;
};

export const handleLanguageChange = (event, setLanguageAnchor) => {
  setLanguageAnchor(event.currentTarget);
};

export const handleLanguageMenu = (event, index, setSelectedLanguage, setLanguageAnchor) => {
  setSelectedLanguage(index);
  setLanguageAnchor(null);
};

export const handleLanguageClose = (setLanguageAnchor) => {
  setLanguageAnchor(null);
};

const formatSSN = (ssn) => {
  if (ssn) {
    return [ssn?.slice(0, 3), ssn?.slice(3, 5), ssn?.slice(5, 9)].join('-');
  }
  return '';
};

export const updateCompletedSteps = (currentCompleted, prevcompletedSteps, setCompletedSteps) => {
  let completedResult = [];
  for (let each of currentCompleted) {
    if (!prevcompletedSteps.includes(each)) {
      completedResult.push(each);
    }
  }

  setCompletedSteps([...prevcompletedSteps, ...completedResult]);
};
const checkAddressSame = (addr1, addr2) => {
  return !!(
    addr1?.addrCity === addr2?.addrCity &&
    addr1?.lineOne === addr2?.lineOne &&
    addr1?.lineTwo === addr2?.lineTwo &&
    addr1?.zipCode === addr2?.zipCode
  );
};
export const generateDummyFile = (fileName) => {
  if (fileName) {
    const extension = fileName?.split('.').pop();
    const samplecontent = 'Sample file';
    return new File([samplecontent], fileName, { type: `text/${extension}` });
  }
};

export const getInfoReqObj = (applicantId, informationRequest) => {
  return {
    applicantId,
    firstName: informationRequest?.participantInfo?.customerName?.firstName,
    lastName: informationRequest?.participantInfo?.customerName?.lastName,
    middleName: informationRequest?.participantInfo?.customerName?.middleName,
    secondLastName: informationRequest?.participantInfo?.customerName?.secondLastName,

    mobile: informationRequest?.participantInfo?.contacts?.mobile,
    mobile2: informationRequest?.participantInfo?.contacts?.alternatePhoneNumber,
    email: informationRequest?.participantInfo?.contacts?.applicantEmail,
    dob: !isNaN(new Date(informationRequest?.participantDetailInfo?.dateOfBirth).getTime())
      ? new Date(informationRequest?.participantDetailInfo?.dateOfBirth)
      : '',
    gender: informationRequest?.participantDetailInfo?.gender,
    maritalStatus: informationRequest?.participantDetailInfo?.maritalStatus,
    resAddress1: informationRequest?.participantDetailInfo?.addresses?.[0]?.lineOne || '',
    resAddress2: informationRequest?.participantDetailInfo?.addresses?.[0]?.lineTwo,
    resCountry: 'USA',
    resState: informationRequest?.participantDetailInfo?.addresses?.[0]?.addrState,
    resCity: informationRequest?.participantDetailInfo?.addresses?.[0]?.addrCity || '',
    resZipcode: informationRequest?.participantDetailInfo?.addresses?.[0]?.zipCode,
    perAddress1: informationRequest?.participantDetailInfo?.addresses?.[1]?.lineOne,
    perAddress2: informationRequest?.participantDetailInfo?.addresses?.[1]?.lineTwo,
    perCountry: 'USA',
    perState: informationRequest?.participantDetailInfo?.addresses?.[1]?.addrState,
    perCity: informationRequest?.participantDetailInfo?.addresses?.[1]?.addrCity,
    perZipcode: informationRequest?.participantDetailInfo?.addresses?.[1]?.zipCode,
    residentStatus: informationRequest?.participantDetailInfo?.residentialStatus,
    residentYears: informationRequest?.participantDetailInfo?.timeOfResidenceYears,
    residentMonths: informationRequest?.participantDetailInfo?.timeOfResidenceMonths,
    isAddrSame: checkAddressSame(
      informationRequest?.participantDetailInfo?.addresses?.[0],
      informationRequest?.participantDetailInfo?.addresses?.[1]
    ),
    showUtility: informationRequest?.participantDetailInfo?.ocrDocumentId ? true : false,
    monthlyPayment:
      informationRequest?.participantDetailInfo?.monthlyPayment !== '0'
        ? informationRequest?.participantDetailInfo?.monthlyPayment
        : '',
    resUtilityBill: informationRequest?.participantDetailInfo?.addresses?.[0]?.utilityBill,
    utilitybillfile: generateDummyFile(informationRequest?.participantDetailInfo?.ocrFileName),
    ocrDocumentIdList: informationRequest?.participantDetailInfo?.ocrDocumentIdList || [],
    ocrDocumentId: informationRequest?.participantDetailInfo?.ocrDocumentId,
    ssn: formatSSN(informationRequest?.participantDetailInfo?.socialSecurityNumber),
    manualFile: generateDummyFile(informationRequest?.participantDetailInfo?.manualUploadFile),
    identityDocId: informationRequest?.participantDetailInfo?.identityDocId,
    jobId: informationRequest?.participantDetailInfo?.vouchedJobId,
    drivingLicenseId: informationRequest?.participantDetailInfo?.drivingLicenseId,
    licenseExpirationDate: informationRequest?.participantDetailInfo?.licenseExpirationDate,
    licenseIssueState: informationRequest?.participantDetailInfo?.licenseIssueState,
    idVerificationStatus:
      informationRequest?.participantDetailInfo?.vouchedIdStatus === 'true' ? 'success' : 'init',
    isAddressCopiedFromApplicant:
      informationRequest?.participantDetailInfo?.isAddressCopiedFromApplicant,
  };
};

export const getEmpInfoObj = (applicantId, employmentinfo) => {
  return {
    applicantId,
    empStatus: employmentinfo?.employmentInformationDetail?.employmentStatus,
    empName: employmentinfo?.employmentInformationDetail?.employer,
    empAddressLine1: employmentinfo?.employmentInformationDetail?.address?.lineOne,
    empAddressLine2: employmentinfo?.employmentInformationDetail?.address?.lineTwo,
    empCity: employmentinfo?.employmentInformationDetail?.address?.addrCity,
    empState: employmentinfo?.employmentInformationDetail?.address?.addrState,
    empZipcode: employmentinfo?.employmentInformationDetail?.address?.zipCode,
    occupation: employmentinfo?.employmentInformationDetail?.occupationCode,
    jobTitle: employmentinfo?.employmentInformationDetail?.jobTitle,

    industry: employmentinfo?.employmentInformationDetail?.industry,
    empYears: employmentinfo?.employmentInformationDetail?.employmentTimePeriodYear,
    empMonths: employmentinfo?.employmentInformationDetail?.employmentTimePeriodMonth,
    monthlyIncome: employmentinfo?.employmentInformationDetail?.grossMonthlyIncome,

    mobile: employmentinfo?.employmentInformationDetail?.workPhone,
    showAddInfo: !!employmentinfo?.additionalIncomeDetail?.addlEmployerName,
    addEmpName: employmentinfo?.additionalIncomeDetail?.addlEmployerName,
    addMonthlyIncome: employmentinfo?.additionalIncomeDetail?.addlGrossMonthlyIncome,
    mobile2: employmentinfo?.additionalIncomeDetail?.addlWorkPhone,
    addOccupation: employmentinfo?.additionalIncomeDetail?.addlOccupationCode,
    addJobTitle: employmentinfo?.additionalIncomeDetail?.addlJobTitle,
  };
};

export const ternaryCondition = async (condition, conditionSuccess, conditionFailure) => {
  if (condition) {
    return conditionSuccess;
  } else {
    return conditionFailure;
  }
};

export const regexChar = /^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡\s]$/;
export const regexChar2 = /^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\s]*$/g;
export const regexNumeric = /^\d*$/;
export const regexAddress = /^[a-z0-9A-ZáéíóúñüÁÉÍÓÚÑÜ¿¡,./-\s]*$/g;
export const regexCharNum = /^[a-z0-9A-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\s]*$/;
export const regexEmail = /^[A-Za-z0-9._%+\-@]$/;

export const regexEmail2 = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

export const reqArray = [
  {
    validator: (val) => !/\s/.test(val),
    text: 'Cannot include spaces',
    reqSatisfied: false,
  },
  {
    validator: (val) =>
      (/[A-Z]{1}/.test(val) || /[a-zÑñÁáÉéÍíÓóÚúü]{1}/.test(val)) &&
      /(\D*\d)/.test(val) &&
      !/[><\\']/.test(val) &&
      /^(?!.*(.)\1\1.*).*$/i.test(val),
    text: "Password must at least contain one letter and one number. Cannot contain the special characters of \\, <, >, '. Cannot repeat more than 2 the same characters in a row.",
    reqSatisfied: false,
  },
  {
    validator: (val) => val.length > 7 && val.length < 16,
    text: 'Password must be a minimum of 8 characters and a maximum of 15 characters',
    reqSatisfied: false,
  },
];

export const errorHandlingMessage = {
  114: {
    message: 'We are unable to process your request. Please try again later',
  },
  115: {
    message: 'New password mandatory',
  },
  116: {
    message: 'Incorrect old password',
  },
  117: {
    message: 'New password should not match last 10 passwords',
  },
  120: {
    message: 'We are unable to process your request. Please try again later',
  },
  123: {
    message: 'We are unable to process your request. Please try again later',
  },
  201: {
    message: 'We are unable to process your request. Please try again later',
  },
  701: {
    message: 'We are unable to process your request. Please try again later',
  },
  142: {
    message: 'Testing',
  },
  defaultCode: {
    message: 'We are unable to process your request. Please try again later',
  },
};
