import { Button, Grid, Typography } from '@mui/material';
import DoneIcon from 'assets/svg/done.svg';
import classes from 'styles/InviInformationRequest.module.scss';

const ThankyouScreen = () => {
  return (
    <>
      <Grid container>
        <Grid className={classes.thankyouContainer}>
          <img src={DoneIcon} className={classes.thankScreenIcon} />

          <Typography className={classes.thankyouScreen} color="#000" component="h3">
            Thank you!
          </Typography>
          <Typography
            color="#000"
            variant="subtitle1"
            component="h3"
            className={classes.thankyouScreenContent}
          >
            Your invitation has been completed and information has been captured successfully.
          </Typography>

          <Button
            component="button"
            color="secondary"
            variant="contained"
            className={classes.buttonStyle}
            sx={{ margin: '20px' }}
            data-testid="visit_bank_url"
            onClick={() => (window.location.href = 'https://orientalbank.com/en/')}
          >
            Visit orientalbank.com
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default ThankyouScreen;
