import { Box, FormHelperText, Grid, InputLabel, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import globalClasses from 'styles/globalStyle.module.scss';
import { cityMapping, defaultFn, regexCharNum } from 'utils/utility';

import { CustomAutoComplete } from 'components/CustomAutoComplete/CustomAutoComplete';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomNumberFormat from 'components/CustomNumberFormat/CustomNumberFormat';
import CustomPhoneNumber from 'components/CustomPhoneNumber/CustomPhoneNumber';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

const InviEmpInfoForm = ({
  control,
  errors,
  setValue,
  // eslint-disable-next-line react/prop-types
  inputRef,
  addressLookupData,
  addressLookupDataResponse,
  clearErrors,
  timeAtResidenceError,
  setError,
  stateList,
  cityList,
  isMounted,
  setCityList,
}) => {
  const { occupationList, empStatusList, industryList, cityZipList, stateCityList } =
    useContext(AuthContext);
  const [watchEmpAddress1, watchEmpState] = useWatch({
    control,
    name: [`empAddressLine1`, 'empState'],
  });
  const [oldState, setOldState] = useState('');
  const handleStateAddress = async (AddressLine1, State) => {
    setValue(`empAddressLine1`, AddressLine1);
    setValue(`empState`, State);
    setOldState(State);
  };
  useEffect(async () => {
    let cityListt = [];
    if (stateCityList?.length > 0) {
      cityListt = await cityMapping(stateCityList, watchEmpState);
    }
    if (isMounted && oldState !== watchEmpState) {
      setValue('empCity', '');
      setValue(`empZipcode`, '');
    }
    setCityList(cityListt || []);
  }, [watchEmpState, stateCityList]);
  const handleAutoCompleteData = async (selectedObj) => {
    console.log({ selectedObj });
    if (selectedObj && selectedObj?.Address) {
      let { AddressLine1, PostalCode, City, State } = selectedObj?.Address;

      await handleStateAddress(AddressLine1, State);
      PostalCode = PostalCode?.split('-')?.[0];
      setValue(`empZipcode`, PostalCode);
      const cityListt = await cityMapping(stateCityList, State);
      if (State !== 'PR') {
        setValue(`empCity`, City);
      } else {
        console.log({ checkingone: cityZipList, cityListt });
        City = cityZipList.find((x) => x?.zipcode === PostalCode)?.city;
        console.log({ check: City });
        City = cityListt.find((x) => x.value === City)?.value || '';
        console.log('city', City);
        clearErrors(`empAddressLine1`);
        if (!City) {
          setError(`empCity`, {
            type: 'custom',
            message: 'Please select a city from the list and enter the zip code',
          });
        } else {
          setValue(`empCity`, City);
          clearErrors([`empAddressLine1`, `empZipcode`, `empCity`]);
        }
      }
    } else {
      setValue(`empAddressLine1`, '');
      setValue(`empAddressLine1`, '');
      setValue(`empCity`, '');
      setValue(`empState`, '');
      setValue(`empZipcode`, '');
    }
  };

  return (
    <>
      <Grid container sx={{ padding: '25px ' }} columnSpacing={5} rowSpacing={1}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomSelectBox
            flexDirection="column"
            label="Status"
            control={control}
            name="empStatus"
            errors={!!errors?.empStatus}
            errorText={errors?.empStatus?.message}
            options={empStatusList}
            defaultValue=""
            required
            displayEmpty
            inputRef={inputRef}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.empName}
            errorText={errors?.empName?.message}
            placeholder="Enter name"
            label="Name"
            name="empName"
            regex={regexCharNum}
            maxLength="50"
            flexDirection="column"
            required
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomAutoComplete
            id="empAddressLine1"
            name="empAddressLine1"
            flexDirection="column"
            control={control}
            dataTestIdAutocomplete="autocomplete_address1"
            dataTestIdForLabel="empAddressLine1"
            dataTestIdForInput="empAddressLine1"
            regex={/^[A-Za-z0-9áéíóúñüÁÉÍÓÚÑÜ¿¡,./-\s]$/}
            label="Address line 1"
            options={
              addressLookupData && watchEmpAddress1 && watchEmpAddress1?.length >= 6
                ? addressLookupData
                : []
            }
            handleChange={(_event, selectedValue) => {
              if (watchEmpAddress1 && watchEmpAddress1.length >= 6) {
                const index = addressLookupData.indexOf(selectedValue);
                const selectedObj = addressLookupDataResponse[index];
                handleAutoCompleteData(selectedObj);
              }
            }}
            maxLength={40}
            autoFocus
            placeholder="Enter address line 1"
            fullWidth
            error={!!errors?.empAddressLine1}
            errorText={errors?.empAddressLine1?.message}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.empAddressLine2}
            errorText={errors?.empAddressLine2?.message}
            placeholder="Enter address line 2"
            label="Address line 2"
            name="empAddressLine2"
            flexDirection="column"
            regex={/^[A-Za-z0-9áéíóúñüÁÉÍÓÚÑÜ¿¡,./-\s]$/}
            maxLength="20"
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} columnSpacing={5}>
          <Grid container columnSpacing={3}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="Country"
                control={control}
                name="empCountry"
                errors={!!errors?.empCountry}
                errorText={errors?.empCountry?.message}
                options={[
                  {
                    name: 'United States',
                    value: 'USA',
                  },
                ]}
                defaultValue=""
                displayEmpty
                isDisabled
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="State"
                control={control}
                name="empState"
                errors={!!errors?.empState}
                errorText={errors?.empState?.message}
                options={stateList}
                defaultValue=""
                displayEmpty
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} columnSpacing={5}>
          <Grid container columnSpacing={3}>
            {watchEmpState === 'PR' && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomSelectBox
                  flexDirection="column"
                  label="City"
                  control={control}
                  name="empCity"
                  errors={!!errors?.empCity}
                  errorText={errors?.empCity?.message}
                  options={cityList}
                  defaultValue=""
                  displayEmpty
                />
              </Grid>
            )}
            {watchEmpState !== 'PR' && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomInputBox
                  control={control}
                  errors={!!errors?.empCity}
                  errorText={errors?.empCity?.message}
                  placeholder="Enter city"
                  label="City"
                  name="empCity"
                  regex={/^[A-Za-záéíóúñüÁÉÍÓÚÑÜ¿¡\s]$/}
                  maxLength="15"
                  type="text"
                  flexDirection="column"
                />
              </Grid>
            )}
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomInputBox
                control={control}
                errors={!!errors?.empZipcode}
                errorText={errors?.empZipcode?.message}
                placeholder="Enter zipcode"
                label="Zipcode"
                name="empZipcode"
                regex={/^\d$/}
                maxLength="5"
                type="text"
                flexDirection="column"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomInputBox
            control={control}
            errors={!!errors?.jobTitle}
            errorText={errors?.jobTitle?.message}
            placeholder="Enter job title"
            label="Job title"
            // regex={/^[a-zA-Záéíóúñü¿¡\s]*$/g}
            maxLength="30"
            type="text"
            name="jobTitle"
            flexDirection="column"
            required
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomSelectBox
            flexDirection="column"
            control={control}
            options={occupationList}
            errors={!!errors?.occupation}
            errorText={errors?.occupation?.message}
            placeholder="Enter occupation"
            label="Occupation"
            name="occupation"
            required
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomSelectBox
            flexDirection="column"
            control={control}
            options={industryList}
            errors={!!errors?.industry}
            errorText={errors?.industry?.message}
            placeholder="Enter industry"
            label="Industry"
            name="industry"
            required
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} alignItems="flex-start">
          <InputLabel htmlFor="time_under_current_employer">
            <span style={{ color: 'red' }}> *&nbsp;</span>Time under current employer
          </InputLabel>

          <Grid item lg={12} md={12} sm={12} xs={12} container spacing={1} alignItems="flex-start">
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={6}
              display="flex"
              alignItems="center"
              sx={{
                gap: '16px',
              }}
            >
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center">
                  <CustomNumberFormat
                    name="empYears"
                    id="empYears"
                    regex={/^\d$/}
                    dataTestId="empYears"
                    placeholder="0"
                    control={control}
                    error={!!errors?.empYears}
                    maxLength={2}
                  />
                  <Typography variant="subtitle2" component="p" pl={1}>
                    Years
                  </Typography>
                </Box>
                {!!errors?.empYears && (
                  <FormHelperText gap={2} className={globalClasses.customSelectError}>
                    {' '}
                    {errors?.empYears?.message}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={6}
              display="flex"
              alignItems="center"
              sx={{
                gap: '16px',
              }}
            >
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center">
                  <CustomNumberFormat
                    name="empMonths"
                    id="empMonths"
                    regex={/^\d$/}
                    dataTestId="empMonths"
                    placeholder="0"
                    control={control}
                    error={!!errors?.empMonths}
                    maxLength={2}
                  />
                  <Typography variant="subtitle2" component="p" pl={1}>
                    Months
                  </Typography>
                </Box>
                {!!errors?.empMonths && (
                  <FormHelperText gap={2} className={globalClasses.customSelectError}>
                    {' '}
                    {errors?.empMonths?.message}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
          </Grid>
          <InputLabel>
            <span style={{ color: '#d32f2f' }}>{timeAtResidenceError}</span>
          </InputLabel>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomNumberFormat
            name="monthlyIncome"
            id="monthlyIncome"
            dataTestId="monthlyIncome"
            placeholder="Enter value"
            label="Gross monthly income"
            control={control}
            decimalScale={2}
            error={!!errors?.monthlyIncome}
            errorText={errors?.monthlyIncome?.message}
            thousandSeparator={true}
            prefix={'$ '}
            required
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomPhoneNumber
            name="mobile"
            label="Work phone"
            flexDirection="column"
            placeholder="Enter work phone"
            control={control}
            error={!!errors?.mobile}
            errorText={errors?.mobile?.message}
            required
          />
        </Grid>
      </Grid>
    </>
  );
};
export default InviEmpInfoForm;
InviEmpInfoForm.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  maritalStatusList: PropTypes.array,
  errorText: PropTypes.string,
  setValue: PropTypes.func,
  clearErrors: PropTypes.func,
  addressLookupData: PropTypes.array,
  addressLookupDataResponse: PropTypes.array,
  stateList: PropTypes.array,
  cityList: PropTypes.array,
  timeAtResidenceError: PropTypes.string,
  setError: PropTypes.func,
  isMounted: PropTypes.bool,
  setCityList: PropTypes.func,
};
InviEmpInfoForm.defaultProps = {
  control: {},
  errors: {},
  errorText: '',
  setValue: defaultFn,
  clearErrors: defaultFn,
  maritalStatusList: [],
  addressLookupData: [],
  stateList: [],
  cityList: [],
  addressLookupDataResponse: [],
  timeAtResidenceError: '',
  setError: defaultFn,
  isMounted: false,
  setCityList: defaultFn,
};
