import { Box, Grid, Typography } from '@mui/material';
import ChevronDown from 'assets/svg/ChevronDown.svg';
import ChevronUp from 'assets/svg/ChevronUp.svg';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { numberToCurrency } from 'utils/utility';

export const ApplicationDetails = ({ appDetails }) => {
  const [expandDetails, setExpandDetails] = useState(false);
  let headingsAndValues = [
    { heading: 'App ID', value: appDetails?.appId },
    { heading: 'Status', value: appDetails?.displayLoanStatus },
    { heading: 'Requested amt', value: numberToCurrency(appDetails?.requestedAmt) },
    { heading: 'Name', value: appDetails?.applicantName },
    { heading: 'Program', value: appDetails?.program },
    { heading: 'Initiated on', value: moment(appDetails?.initiatedOn).format('MM/DD/yyyy') },
  ];
  let headingsAndValuesExpanded = [
    { heading: 'Loan purpose', value: appDetails?.loanPurpose },
    { heading: 'Dealer', value: appDetails?.dealer },
    { heading: 'Product', value: appDetails?.product },
    { heading: 'Contact name', value: appDetails?.dealer },
    { heading: 'Phone', value: appDetails?.mobile },
    { heading: 'Collateral value', value: numberToCurrency(appDetails?.collateralValue) },
    { heading: 'Contact phone', value: appDetails?.contactPhone },
    { heading: 'Created by', value: appDetails?.createdBy },
    {
      heading: 'Collateral',
      value: appDetails?.collateral,
    },
    {
      heading: 'Address',
      value: `${appDetails?.address[0]?.lineOne} ${appDetails?.address[0]?.lineTwo}, ${appDetails?.address[0]?.addrCity}, ${appDetails?.address[0]?.addrCountryCode} ${appDetails?.address[0]?.zipCode}`,
    },
  ];

  const handleClick = () => {
    if (expandDetails) {
      setExpandDetails(false);
    } else {
      setExpandDetails(true);
    }
  };
  return (
    <Box>
      <Box sx={{ padding: '10px 20px 20px 20px' }}>
        <Grid container spacing={1}>
          {headingsAndValues.map((item) => {
            return (
              <>
                <Grid item lg={4} md={6} xs={12}>
                  <Box display="flex" gap="15%">
                    <Typography sx={{ width: '30%' }} variant="subtitle2">
                      {item.heading}
                    </Typography>
                    <Typography sx={{ width: '55%', wordWrap: 'break-word' }} variant="subtitle2" fontWeight={700} color="#661C69">
                      {item?.value}
                    </Typography>
                  </Box>
                </Grid>
              </>
            );
          })}
          {expandDetails &&
            headingsAndValuesExpanded.map((item) => {
              return (
                <>
                  <Grid item lg={4} md={6} xs={12}>
                    <Box display="flex" gap="15%">
                      <Typography sx={{ width: '30%' }} variant="subtitle2">
                        {item.heading}
                      </Typography>
                      <Typography sx={{ width: '55%', wordWrap: 'break-word' }} variant="subtitle2" fontWeight={700} color="#661C69">
                        {item?.value}
                      </Typography>
                    </Box>
                  </Grid>
                </>
              );
            })}
        </Grid>
        <Box sx={{ marginTop: '25px' }} textAlign="center">
          <img
            src={expandDetails ? ChevronUp : ChevronDown}
            alt="Print"
            data-testid="expandBtn"
            onClick={handleClick}
          />
        </Box>
      </Box>
    </Box>
  );
};

ApplicationDetails.propTypes = {
  appDetails: PropTypes.instanceOf(Object),
};
ApplicationDetails.defaultProps = {
  appDetails: {},
};
