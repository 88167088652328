import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { LockReleaseApi } from 'Services/LockReleaseApi';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { defaultFn } from 'utils/utility';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';

const CancelEditPopup = ({ openModal, setOpenModal, onConfirmCancel }) => {
  const { createAppApplicationId, fullName, userID } = useContext(AuthContext);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleCancel = () => {
    onConfirmCancel();
    LockReleaseApi({ appid: createAppApplicationId, fullName, userID });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      PaperProps={{ sx: { padding: '20px' } }}
    >
      <BootstrapDialogTitle onClose={handleClose}>
        <Box display="flex" gap={1}>
          <CheckCircleOutlineIcon color="success" />
          <Typography variant="h3">Alert message</Typography>
        </Box>
      </BootstrapDialogTitle>
      <DialogContent>
        <Typography variant="subtitle2" color="#909090">
          Changes you made may not be saved. Do you wish to continue?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" gap={2}>
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            Ok, redirect
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            No
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CancelEditPopup;

CancelEditPopup.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  onConfirmCancel: PropTypes.func,
};
CancelEditPopup.defaultProps = {
  openModal: false,
  setOpenModal: defaultFn,
  onConfirmCancel: PropTypes.func,
};
