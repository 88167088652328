import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Tab, Tabs } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import { handleTokenExpiry } from 'Services/commonComponentsApi';
import axios from 'axios';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { InformationRequestSchema } from 'schemaValidations/InformationRequestSchema';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { cityMapping, getInfoReqObj } from 'utils/utility';

import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';
import TabPanel from 'components/TabPanel/TabPanel';

import InformationRequest from '../InformationRequest/InformationRequest';
import ApplicationPageHeading from '../InformationRequest/moduleUtilities/ApplicationPageHeading';

const InfoTabView = () => {
  const ApiClient = new ApiClass();
  const [loader, setLoader] = useState(false);
  const [savedApplicantIds, setSavedApplicantIds] = useState([]);
  const [initApplicantIds, setInitApplicantIds] = useState([]);
  const [callDraft, setCallDraft] = useState(false);
  const [apiErr, setApiErr] = useState('');
  const [isStatePR, setIsStatePR] = useState(true);

  const {
    control,

    handleSubmit,
    watch,
    setValue,
    getValues,
    setFocus,
    setError,
    clearErrors,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: 'all',
    defaultValues: {
      cousers: [
        {
          applicantId: '',
          userType: '',
          manualFile: null,
          relationship: '',
          firstName: '',
          middleName: '',
          lastName: '',
          secondLastName: '',
          gender: '',
          dob: null,
          mobile: '',
          mobile2: '',
          ssn: '',
          email: '',
          maritalStatus: '',
          isAddrSame: true,
          resAddress1: '',
          resAddress2: '',
          resCountry: 'USA',
          resState: '',
          resCity: '',
          resZipcode: '',
          vouchedAddress1: '',
          vouchedAddress2: '',
          vouchedCity: '',
          vouchedZipcode: '',
          showUtility: false,
          resUtilityBill: '',
          utilitybillfile: null,
          ocrDocumentIdList: [],
          ocrDocumentId: '',
          resProofCam: '',
          resProofFile: '',
          coApplicant: {},
          coSigner: [],
          perAddress1: '',
          perAddress2: '',
          perCountry: 'USA',
          perState: '',
          perCity: '',
          perZipcode: '',
          perUtilityBill: '',
          perProofCam: '',
          perProofFile: '',
          residentStatus: '',
          residenceTimeErr: '',
          monthlyPayment: '',
          residentYears: '',
          residentMonths: '',
          idVerificationStatus: 'init',
          identityDocId: '',
          jobId: '',
          drivingLicenseId: '',
          licenseExpirationDate: '',
          licenseIssueState: '',
          isAddressCopiedFromApplicant: false,
        },
      ],
      isAddressCopiedFromApplicant: false,
    },
    resolver: yupResolver(InformationRequestSchema),
  });
  const { fields, append, remove, replace, insert, swap } = useFieldArray({
    control,
    name: 'cousers',
  });

  const watchcousers = watch('cousers') || [];
  const [tabvalue, setTabValue] = useState(0);

  const {
    createAppApplicationId,
    commonToken,
    setCommonToken,
    userID,
    fullName,
    stateList,
    stateCityList,
  } = useContext(AuthContext);
  const {
    formFields,
    userType,
    vouchedReturnFlag,
    scannedData,
    infoTabValue = 0,
    reviewFlag,
    savedApplicantIdsVouched,
    initApplicantIdsVouched,
    draftCheckFlag,

    idVerificationPendingText,
  } = useContext(MainApplicationContext);

  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
    setCallDraft(true);
  };
  console.log('errors throughout', errors);
  const handleErrorFocus = async (errorTabIndex) => {
    try {
      const firstError = Object.keys(errors?.cousers?.[errorTabIndex]);
      console.log('firstError---', firstError);
      let arr = [
        'residentYears',
        'residentMonths',
        'ssn',
        'monthlyPayment',
        'relationship',
        'maritalStatus',
        'perCountry',
        'perState',
        'perCity',
        'relationship',
        'resCountry',
        'resState',
        'resCity',
        'resUtilityBill',
      ];
      if (firstError?.length > 0 && arr?.includes(firstError[0])) {
        document.getElementById(`cousers[${errorTabIndex}].${firstError?.[0]}`)?.focus();
      } else if (firstError?.length > 0) {
        setFocus(`cousers[${errorTabIndex}].${firstError?.[0]}`);
      }
    } catch (e) {
      console.log('error', e);
    }
  };
  const handleTabValue = async (errorTabIndex) => {
    if (errorTabIndex > -1 && errorTabIndex !== tabvalue) {
      setTabValue(errorTabIndex);
      setCallDraft(true);
    }
  };

  useEffect(async () => {
    let errorTabIndex;
    console.log('errors', errors);
    if (Object.entries(errors)?.length && errors?.cousers?.length > 0) {
      errorTabIndex = findTabWitherror();

      await handleTabValue(errorTabIndex);

      await handleErrorFocus(errorTabIndex);
    }
  }, [errors]);

  useEffect(() => {
    if (watchcousers.length && reviewFlag) {
      const reviewTab = watchcousers.findIndex((x) => x.userType === userType);
      if (reviewTab > -1) {
        setTabValue(reviewTab);
      }
    }
  }, [reviewFlag, watchcousers]);

  const getInfo = async () => {
    await getSpecificDetailsApi({ submitFlag: true });
  };

  const getSpecificDetailsApi = async ({ submitFlag = false, initRender = false }) => {
    try {
      setLoader(true);
      const getResp = await ApiClient.get(
        `${UrlHelpers.getSpecificDetails}?applicationId=${createAppApplicationId}&eventName=InformationRequest&isLockRequired=true`,
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );

      if (Object.entries(getResp).length) {
        await framecouserArray(getResp, submitFlag, initRender);
        setIsStatePR(
          getResp?.participantsDetailInformation?.informationRequest?.participantDetailInfo
            ?.isStatePR
        );
      }
    } catch (e) {
      console.log('error', e);
      setApiErr(
        e?.response?.data ||
          'We are unable to proceed due to a technical issue, please try again later'
      );
    } finally {
      setLoader(false);
    }
  };

  const framecouserArray = async (getResp, submitFlag, initRender) => {
    const {
      informationRequest,
      coSigners = [],
      coApplicants = {},
      applicantId,
    } = getResp?.participantsDetailInformation;

    const couserArray = [];
    if (Object.entries(informationRequest)?.length) {
      await getPrimaryApplicant(informationRequest, applicantId, couserArray);
    }
    if (Object.entries(coApplicants)?.length) {
      await getCoApplicantDetails(coApplicants, couserArray);
    }

    if (coSigners.length > 0) {
      await getCosignerDetails(coSigners, couserArray);
    }
    let storedApplicantIds = couserArray.map((x) => x?.applicantId);
    setSavedApplicantIds(storedApplicantIds);

    if (!submitFlag) {
      replace(couserArray);
    }
    if (initRender) {
      setInitApplicantIds(storedApplicantIds);
    }
    if (draftCheckFlag && initRender) {
      await landFromDraft(couserArray);
    }
  };
  const getPrimaryApplicant = async (informationRequest, applicantId, couserArray) => {
    const infoData = getInfoReqObj(applicantId, informationRequest);
    const primaryAppt = {
      userType: 'Applicant',
      ...infoData,
    };
    couserArray.push(primaryAppt);
  };
  const getCoApplicantDetails = async (coApplicants, couserArray) => {
    const { applicantId, informationRequest } = coApplicants?.[0];
    const infoData = getInfoReqObj(applicantId, informationRequest);
    const coapplicant = {
      userType: `Co-applicant`,
      ...infoData,
      relationship: informationRequest?.participantDetailInfo?.relationshipWithOwner,
    };
    couserArray.push(coapplicant);
  };
  const getCosignerDetails = async (coSigners, couserArray) => {
    coSigners.map((x, index) => {
      const { applicantId, informationRequest } = x;
      const infoData = getInfoReqObj(applicantId, informationRequest);
      const cosigner = {
        userType: `Co-signer ${index + 1}`,
        ...infoData,
        relationship: informationRequest?.participantDetailInfo?.relationshipWithOwner,
      };
      couserArray.push(cosigner);
    });
  };

  const findTabWitherror = () => {
    for (let i = 0; i < fields.length; i++) {
      const hasErrors = errors.cousers?.[i] !== undefined;
      if (hasErrors) {
        return i;
      }
    }
    return -1;
  };

  const a11yProps = (index) => {
    return {
      id: `users-tab-${index}`,
      'aria-controls': `users-tabpanel-${index}`,
    };
  };

  const applyFormFields = async (formFieldss) => {
    replace(formFieldss);
    setTabValue(infoTabValue);
  };

  const validateDuplicates = async (tabvaluee, isValidParam) => {
    console.log('---- into dupliacates check', watchcousers);
    const tabField = watchcousers?.[tabvaluee];
    let isValid = isValidParam;
    const issameSsn = watchcousers.find((x) => x?.ssn && x.ssn === tabField?.ssn)?.userType;
    const issameEmail = watchcousers.find((x) => x?.email && x.email === tabField?.email)?.userType;
    const issameNumber = watchcousers.find((x) => x?.mobile === tabField?.mobile)?.userType;
    console.log('issame flags', { issameSsn, issameEmail, issameNumber, tabField });
    if (issameEmail && issameEmail !== tabField?.userType) {
      setTabValue(tabvaluee);
      setError(`cousers[${tabvaluee}].email`, {
        type: 'custom',
        message: `Email cannot be same for ${issameEmail} and ${tabField?.userType}`,
      });
      setApiErr('');
      isValid = false;
    }

    if (issameNumber && issameNumber !== tabField?.userType) {
      setTabValue(tabvaluee);
      setError(`cousers[${tabvaluee}].mobile`, {
        type: 'custom',
        message: `Mobile Number cannot be same for ${issameNumber} and ${tabField?.userType}`,
      });
      setApiErr('');
      isValid = false;
    }

    if (issameSsn && issameSsn !== tabField?.userType) {
      setTabValue(tabvaluee);
      setError(`cousers[${tabvaluee}].ssn`, {
        type: 'custom',
        message: `SSN should be unique and can't be same as other applicants`,
      });
      setApiErr('');
      isValid = false;
      document.getElementById(`cousers[${tabvaluee}].ssn`).focus();
    }

    return isValid;
  };
  const validateAll = async () => {
    console.log('into validate All');
    let isValid = true;
    for (let i = 0; i < watchcousers?.length; i++) {
      isValid = await validateDuplicates(i, isValid);
    }
    console.log('after validate duplicates', isValid);
    if (isValid) {
      for (let i = 0; i < watchcousers?.length; i++) {
        if (isValid) {
          isValid = await validateMellisa(i, isValid);
        }
      }
    }
    return isValid;
  };

  const validateMellisa = async (i, isValid) => {
    let isValidated = isValid;
    isValidated = await phoneValidation({
      mobile1: watchcousers[i].mobile,
      mobile2: watchcousers[i].mobile2,
      tabIndex: i,
      isValidated,
    });
    isValidated = await emailValidation(watchcousers[i].email, i, isValidated);
    return isValidated;
  };

  const emailValidation = async (email, tabIndex, isValidated) => {
    try {
      let isValid = isValidated;
      setLoader(true);
      let prefixName = `cousers[${tabIndex}]`;
      let response = await axios.post(
        UrlHelpers.commonComponentsEmailVerification,
        [
          {
            email,
            eventType: 'UI',
            source: 'auto',
          },
        ],
        {
          headers: { Authorization: `Bearer ${commonToken}`, 'Content-Type': 'application/json' },
        }
      );
      response = response?.data;
      const { status, message } = response[0];
      if (status !== 'Valid' && message === 'Email Domain Not Found') {
        setTabValue(tabIndex);
        setError(`${prefixName}.email`, { type: 'custom', message: '* Invalid email domain' });
        isValid = false;
      } else if (status !== 'Valid') {
        setTabValue(tabIndex);
        setError(`${prefixName}.email`, { type: 'custom', message: '* Invalid email' });
        isValid = false;
      }
      return isValid;
    } catch (e) {
      handleTokenExpiry(e, setCommonToken);
      setApiErr(e?.response?.data?.message || 'Please retry');
    } finally {
      setLoader(false);
    }
  };
  const phoneValidation = async ({ mobile1, mobile2, tabIndex, isValidated }) => {
    let isValid = isValidated;
    const payload = [
      {
        phoneNumber: mobile1,
        eventType: 'UI',
        source: 'auto',
      },
    ];
    if (mobile2) {
      payload.push({
        phoneNumber: mobile2,
        eventType: 'UI',
        source: 'auto',
      });
    }
    let prefixName = `cousers[${tabIndex}]`;

    try {
      setLoader(true);
      let response = await axios.post(UrlHelpers.commonComponentsPhoneVerification, payload, {
        headers: { Authorization: `Bearer ${commonToken}`, 'Content-Type': 'application/json' },
      });
      response = response?.data;

      const mobile1status = response.find((x) => x.phoneNumber === mobile1)?.status;
      if (mobile1status !== 'Valid') {
        setTabValue(tabIndex);
        setError(`${prefixName}.mobile`, { type: 'custom', message: '* Invalid phone number' });
        isValid = false;
      } else {
        clearErrors(`${prefixName}.mobile`);
      }
      if (mobile2) {
        const mobile2status = response.find((x) => x.phoneNumber === mobile2)?.status;
        if (mobile2status !== 'Valid') {
          setTabValue(tabIndex);
          setError(`${prefixName}.mobile2`, { type: 'custom', message: '* Invalid phone number' });
          isValid = false;
        } else {
          clearErrors(`${prefixName}.mobile2`);
        }
      }
      return isValid;
    } catch (e) {
      handleTokenExpiry(e, setCommonToken);
      setApiErr(e?.response?.data?.message || 'Please retry');
    } finally {
      setLoader(false);
    }
  };

  useEffect(async () => {
    if (formFields?.length && vouchedReturnFlag) {
      const changeIndex = formFields?.findIndex((x) => x?.userType === userType);
      const prefix = `cousers[${changeIndex}]`;

      applyFormFields(formFields);
      setSavedApplicantIds(savedApplicantIdsVouched);
      setInitApplicantIds(initApplicantIdsVouched);
      if (scannedData && scannedData?.success) {
        applyScannedData({ prefix, scannedData });
      } else if (Object.entries(scannedData).length && !scannedData?.success) {
        setValue(`${prefix}.idVerificationStatus`, 'failed');
      }
    } else {
      await getSpecificDetailsApi({ submitFlag: false, initRender: true });
    }
  }, [scannedData, vouchedReturnFlag, formFields]);

  const landFromDraft = async (cousers) => {
    if (cousers?.length && userType) {
      const landingTab = cousers.findIndex((x) => x.userType === userType);
      if (landingTab > -1) {
        setTabValue(landingTab);
      }
    }
  };

  const applyScannedData = async ({ prefix, scannedData }) => {
    let isValid = true;
    isValid =
      scannedData?.expireDate &&
      new Date(scannedData?.expireDate) >= new Date().setHours(0, 0, 0, 0);
    if (!isValid) {
      setValue(`${prefix}.idVerificationStatus`, 'expired');
    } else {
      setValue(`${prefix}.idVerificationStatus`, 'success');
      setValue(`${prefix}.showUtility`, false);

      const {
        firstName,
        middleName,
        lastName,
        birthDate,
        gender,
        dob,
        jobId: jobIdd,
        type,
        id,
        expireDate,
        state,
        country,
        jobId,
        status,
      } = scannedData;
      let vouchedResponse = {};
      let barDataObj = {};

      if (jobId && status === 'completed') {
        let apiResp = null;
        apiResp = await ApiClient.get(`${process.env.REACT_APP_VOUCHED_MS_BARCODE}?jobId=${jobId}`);
        if (apiResp) {
          vouchedResponse = apiResp?.items[0];
          barDataObj = vouchedResponse?.result?.barcodeData;
        }
      }
      if (type === 'drivers-license') {
        setValue(`${prefix}.drivingLicenseId`, id);
        setValue(`${prefix}.licenseExpirationDate`, expireDate);
        setValue(`${prefix}.licenseIssueState`, country === 'PR' ? country : state);
      }
      setValue(`${prefix}.jobId`, jobIdd);
      setValue(`${prefix}.firstName`, firstName);
      setValue(`${prefix}.middleName`, middleName);

      setLastSecondLast({ lastName, prefix });
      setValue(`${prefix}.resUtilityBill`, '');
      setValue(`${prefix}.utilitybillfile`, '');
      setValue(`${prefix}.manualFile`, '');

      setGender({ prefix, gender });

      setValue(`${prefix}.dob`, dob || birthDate || barDataObj?.birthDate);
      clearErrors('utilitybillfile');

      setValue(`${prefix}.resAddress1`, barDataObj?.streetAddress);
      setValue(`${prefix}.vouchedAddress1`, barDataObj?.streetAddress);
      setAddress({ prefix, barDataObj });
    }
  };

  const setLastSecondLast = ({ lastName, prefix }) => {
    let spliLastName = lastName?.split(' ');
    if (spliLastName?.length > 1) {
      setValue(`${prefix}.lastName`, spliLastName[0]);
      setValue(`${prefix}.secondLastName`, spliLastName[1]);
    } else {
      setValue(`${prefix}.lastName`, lastName);
    }
  };

  const setGender = ({ prefix, gender }) => {
    if (gender.gender === 'woman') setValue(`${prefix}.gender`, 'Female');
    else if (gender.gender === 'man') setValue(`${prefix}.gender`, 'Male');
  };

  const setAddress = async ({ prefix, barDataObj }) => {
    if (Object.keys(barDataObj).length > 0) {
      if (barDataObj?.streetAddress2?.length > 20) {
        setError(`${prefix}.resAddress2`, {
          type: 'custom',
          message: 'Address line 2 should be 20 characters or less',
        });
        if (getValues(`${prefix}.isAddrSame`)) {
          setError(`${prefix}.perAddress2`, {
            type: 'custom',
            message: 'Address line 2 should be 20 characters or less',
          });
        }
      }
      setValue(`${prefix}.resAddress2`, barDataObj.streetAddress2);
      setValue(`${prefix}.vouchedAddress2`, barDataObj.streetAddress2);
      let { city, postalCode, state } = barDataObj;
      const stateMapping = stateList.find((x) => x.value === state)?.value;
      setValue(`${prefix}.resState`, stateMapping);
      if (stateMapping === 'PR') {
        let cityList = await cityMapping(stateCityList, 'PR');
        city = cityList?.find((x) => x.value?.toLowerCase() === city?.toLowerCase())?.value;
      }

      setValue(`${prefix}.resCity`, city);
      setValue(`${prefix}.vouchedCity`, city);

      setValue(`${prefix}.resZipcode`, postalCode);
      setValue(`${prefix}.vouchedZipcode`, postalCode);
    }
  };

  return (
    <>
      <ApplicationPageHeading />

      <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
      <Loader open={loader} />
      {fields?.length > 0 && (
        <>
          <Box>
            <Tabs
              value={tabvalue}
              onChange={handleChange}
              aria-label="User tabs"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {fields.map((item, index) => (
                <Tab key={item.userType} label={item.userType} {...a11yProps(index)} />
              ))}
            </Tabs>
          </Box>

          {fields.map((item, index) => (
            <>
              <TabPanel key={item.id} value={tabvalue} index={index}>
                <InformationRequest
                  control={control}
                  prefixName={`cousers[${index}]`}
                  couserIndex={index}
                  errors={errors}
                  getValues={getValues}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  dirtyFields={dirtyFields}
                  setError={setError}
                  fields={fields}
                  append={append}
                  insert={insert}
                  remove={remove}
                  swap={swap}
                  tabvalue={tabvalue}
                  handleSubmit={handleSubmit}
                  setTabValue={setTabValue}
                  validateAll={validateAll}
                  initApplicantIds={initApplicantIds}
                  savedApplicantIds={savedApplicantIds}
                  trigger={trigger}
                  callDraft={callDraft}
                  setCallDraft={setCallDraft}
                  callbackApi={getInfo}
                  setApiErr={setApiErr}
                  isStatePR={isStatePR}
                  idVerificationPendingText={idVerificationPendingText}
                />
              </TabPanel>
            </>
          ))}
        </>
      )}
    </>
  );
};
export default InfoTabView;
