import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, FormHelperText, Grid, InputLabel, Paper, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import { generateFileMD5 } from 'Services/commonComponentsApi';
import {
  uploadDocumentByPreSignedURL,
  uploadDocumentManually,
  uploadXlDocumentManually,
} from 'Services/uploadService';
import { Buffer } from 'buffer';
import AuthContext from 'context/AuthContext';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreatePromotionSchema } from 'schemaValidations/CreatePromotionSchema';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { generateDummyFile, imgExtension } from 'utils/utility';

import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import CustomFileUploader from 'components/CustomFileUploader/CustomFileUploader';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomMultiSelect from 'components/CustomMultiSelect/CustomMultiSelect';
import CustomMultilineInput from 'components/CustomMultilineInput/CustomMultilineInput';
import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

const CreatePromotion = () => {
  const location = useLocation();
  const { promotion = {}, editFlag = false } = location?.state || {};
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      promotionImage: '',
      promotionLabel: '',
      promotionTitle: '',
      promotionDescription: '',
      effectiveDate: null,
      expirationDate: null,
      gracePeriod: '',
      associatedDealers: '',
    },
    resolver: yupResolver(CreatePromotionSchema),
  });
  console.log('errors', errors);

  const [uploadedDocId, setUploadedDocId] = useState('');
  const [apiErr, setApiErr] = useState('');
  const [imgErr, setImgErr] = useState('');
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const ApiClient = new ApiClass();
  const {
    rolebasedDealers,
    //  userType
  } = useContext(AuthContext);
  const fileTypes = ['JPG', 'PNG', 'JPEG'];
  console.log('errors', errors);
  const [selected, setSelected] = useState([]);
  const isAllSelected = rolebasedDealers.length > 0 && selected.length === rolebasedDealers.length;
  useEffect(() => {
    if (!editFlag) {
      setSelected(rolebasedDealers?.length ? rolebasedDealers.map((x) => x.dealerId) : []);
    }
  }, [rolebasedDealers]);
  const [watchEffectiveDate, watchpromotionImage] = useWatch({
    control,
    name: ['effectiveDate', 'promotionImage'],
  });

  useEffect(() => {
    if (editFlag) {
      const imageFile = generateDummyFile(promotion?.promotionImageName);
      console.log('dummy promotionImage', imageFile);
      setValue('promotionImage', imageFile);

      Object.entries(promotion).forEach(([key, value]) => {
        if (key !== 'promotionImage') setValue(key, value);
      });
      let parsed = JSON.parse(promotion?.associatedDealers);

      setSelected(Object.keys(parsed));
    }
  }, [location?.state]);

  const handleClose = () => {
    navigate('/promotions');
  };
  const createPromotionData = async (data) => {
    const {
      effectiveDate,
      expirationDate,
      gracePeriod,
      promotionLabel,
      promotionTitle,
      promotionDescription,
      promotionImage,
    } = data;

    const matchedDealers = {};
    selected.forEach((selectedId) => {
      const matchedDealer = rolebasedDealers.find((dealer) => dealer.dealerId === selectedId);
      if (matchedDealer) {
        matchedDealers[matchedDealer.dealerId] = matchedDealer.dealerName;
      }
    });

    const CommaSeperatedDealerList = JSON.stringify(matchedDealers);

    const formData = {
      effectiveDate: moment(effectiveDate).format('MM/DD/yyyy'),
      expirationDate: moment(expirationDate).format('MM/DD/yyyy'),
      gracePeriod: +gracePeriod,
      promotionImage: uploadedDocId,
      promotionImageName: promotionImage?.name,
      promotionLabel: promotionLabel,
      promotionTitle: promotionTitle,
      promotionDescription: promotionDescription,
      associatedDealers: CommaSeperatedDealerList,
    };
    try {
      setLoader(true);
      if (editFlag) {
        await ApiClient.put(
          UrlHelpers?.createPromotion,
          {
            ...formData,
            promotionId: promotion?.promotionId,
          },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        navigate('/promotions', {
          state: { msg: 'Promotion updated successfully' },
        });
      } else {
        await ApiClient.post(
          UrlHelpers?.createPromotion,
          {
            ...formData,
          },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );

        navigate('/promotions', {
          state: { msg: 'Promotion saved successfully' },
        });
      }
    } catch (e) {
      console.log('e', e);
      setApiErr(e?.response?.data || 'Internal server error');
    } finally {
      setLoader(false);
    }
  };

  console.log('promotionImage', watchpromotionImage);
  const handleChange = async (filee) => {
    console.log('file', filee);

    const { name, type, size } = filee;

    let format = imgExtension(type);
    if (size > 10485760) {
      setError(`promotionImage`, {
        type: 'custom',
        message: 'The file exceeds the maximum upload size (10 MB). Click to upload again',
      });
    } else {
      setImgErr('');
      clearErrors('promotionImage');
      setValue('promotionImage', filee);
      setLoader(true);
      if (size > 3145728) {
        const fileMD5 = await generateFileMD5(filee);
        const fileMd5Base64 = Buffer.from(fileMD5, 'hex').toString('base64');
        const payload = {
          format,
          name,
          type: 'promotion',
          size,
          source: 'auto',
          eventType: 'UI',
          contentMD5: fileMd5Base64,
        };
        const resp = await uploadXlDocumentManually(
          payload,
          setLoader,
          setImgErr,
          setValue,
          'promotionImage'
        );
        if (resp) {
          setLoader(true);
          const preSignedUrl = resp.preSignedUrl ? resp.preSignedUrl : null;
          setUploadedDocId(resp.documentId);
          await uploadDocumentByPreSignedURL(
            preSignedUrl,
            filee,
            fileMd5Base64,
            setLoader,
            setImgErr,
            setValue,
            `promotionImage`
          );
        }
      } else {
        const docUploadDetails = await uploadDocumentManually(
          format,
          '',
          '',
          name,
          filee,
          setImgErr,
          'promotion',
          setValue,
          'promotionImage'
        );
        setUploadedDocId(docUploadDetails.documentId);
        setLoader(false);
      }
    }
  };

  const handleMultiSelctChange = (event) => {
    const value = event?.target?.value;
    if (value[value.length - 1] === 'all') {
      setSelected(
        selected?.length === rolebasedDealers.length ? [] : rolebasedDealers.map((x) => x.dealerId)
      );

      return;
    }
    setSelected(value);
  };

  return (
    <>
      <Loader open={loader} />
      <Box py={3} px={2} display="flex" justifyContent="space-between">
        <Typography variant="h3">Create promotion</Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box sx={{ width: { sm: '70%', xs: '100%' } }}>
          <Paper className={classes.infoRequestPaper} display="flex" flexDirection="column">
            <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
            <Box display="flex" justifyContent="center">
              <Box sx={{ width: { sm: '80%', xs: '100%' } }} py={3} px={2}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomInputBox
                      control={control}
                      errors={!!errors?.promotionTitle}
                      errorText={errors?.promotionTitle?.message}
                      maxLength="20"
                      regex={/^[a-z0-9A-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\s,'!@$%&*()\-./"\\]*$/}
                      placeholder="Enter promotion title"
                      label="Promotion title"
                      name="promotionTitle"
                      flexDirection="column"
                      required
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomInputBox
                      control={control}
                      errors={!!errors?.promotionLabel}
                      errorText={errors?.promotionLabel?.message}
                      maxLength="20"
                      regex={/^[a-z0-9A-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\s,'!@$%&*()\-./"\\]*$/}
                      placeholder="Enter promotion label"
                      name="promotionLabel"
                      label="Promotion label"
                      flexDirection="column"
                      required
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <CustomMultilineInput
                      control={control}
                      errors={!!errors?.promotionDescription}
                      errorText={errors?.promotionDescription?.message}
                      regex={/^[a-z0-9A-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\s,'!@$%&*()\-./"\\]*$/}
                      maxLength="50"
                      placeholder="Enter your text..."
                      label="Promotion description"
                      name="promotionDescription"
                      flexDirection="column"
                      multiline
                      resize="none"
                      rows={6}
                      required
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Grid item lg={8} md={8} sm={12} xs={4}>
                      <CustomDatePicker
                        name="effectiveDate"
                        control={control}
                        error={!!errors?.effectiveDate}
                        errorText={errors?.effectiveDate?.message}
                        minDate={new Date()}
                        placeHolder="Effective date"
                        label="Effective date"
                        noGap
                        noMargin
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Grid item lg={8} md={8} sm={12} xs={4}>
                      <CustomDatePicker
                        name="expirationDate"
                        control={control}
                        error={!!errors?.expirationDate}
                        errorText={errors?.expirationDate?.message}
                        minDate={watchEffectiveDate || new Date()}
                        placeHolder="Expiration date"
                        label="Expiration date"
                        noGap
                        noMargin
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomInputBox
                      control={control}
                      errors={!!errors?.gracePeriod}
                      errorText={errors?.gracePeriod?.message}
                      maxLength="2"
                      regex={/^\d*$/}
                      placeholder="Enter grace period"
                      label="Grace period"
                      name="gracePeriod"
                      flexDirection="column"
                      required
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box display="flex" gap={0.625} my={0.5} flexDirection="column">
                      <InputLabel htmlFor="associatedDealers">
                        {<span style={{ color: 'red' }}>*&nbsp;</span>}Promotion associated to
                        dealers
                      </InputLabel>
                      <CustomMultiSelect
                        selected={selected}
                        handleMultiSelctChange={handleMultiSelctChange}
                        isAllSelected={isAllSelected}
                        options={rolebasedDealers.map((x) => ({
                          displayName: x.dealerName,
                          value: x.dealerId,
                        }))}
                        label="Promotion associated to dealers"
                        name="associatedDealers"
                      />

                      {selected?.length === 0 && (
                        <FormHelperText gap={2} className={classes.customSelectError}>
                          {'* Mandatory Field'}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box display="flex" flexDirection="column" width="50%">
                      <CustomFileUploader
                        handleChange={handleChange}
                        control={control}
                        fileTypes={fileTypes}
                        setError={setError}
                        errors={!!errors?.promotionImage}
                        errorText={errors?.promotionImage?.message}
                        name="promotionImage"
                        fileType="Max: 10MB supporting file types: PNG, JPG."
                        fileTypeErr="Only PNG, JPG formats are allowed"
                        label="Upload image"
                      />

                      {!errors?.promotionImage?.message && !imgErr && watchpromotionImage && (
                        <Box
                          display="flex"
                          gap={0.5}
                          mt={2.5}
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                        >
                          <CheckCircleIcon htmlColor="green" />
                          <Typography variant="" data-testid="filename">
                            {watchpromotionImage?.name}
                          </Typography>
                        </Box>
                      )}
                      <Box
                        display="flex"
                        gap={0.5}
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                      >
                        <Typography component="p" color="red">
                          {errors?.promotionImage?.message || imgErr}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <div style={{ display: 'flex', justifyContent: 'end', margin: '10px 0' }}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                data-testid="cancel_testid"
                sx={{ maxHeight: '40px', marginRight: '20px' }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                autoFocus
                data-testid="submit_testid"
                onClick={handleSubmit(createPromotionData)}
                disabled={selected?.length === 0}
                sx={{ maxHeight: '40px' }}
              >
                Submit
              </Button>
            </div>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default CreatePromotion;

CreatePromotion.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
};
CreatePromotion.defaultProps = {
  control: {},
  errors: {},
};
