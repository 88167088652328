import { Box, Grid, Snackbar, Typography } from '@mui/material';
import NotificationCancel from 'assets/svg/NotificationCancel.svg';
import NotificationPopupIcon from 'assets/svg/NotificationPopupIcon.svg';
import PopupOpenIcon from 'assets/svg/PopupOpenIcon.svg';
import PurpleDotIcon from 'assets/svg/PurpleDotIcon.svg';
import SnoozeIcon from 'assets/svg/SnoozeIcon.svg';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import classes from 'styles/header.module.scss';
import { defaultFn } from 'utils/utility';

const NotificationSnackBar = ({
  snackBarOpen,
  setSnackBarOpen,
  transition,
  alertmsg,
  onOpen,
  setSnooze,
}) => {
  const handleSnackbarClose = () => {
    setSnackBarOpen(false);
    setSnooze(false);
  };

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (snackBarOpen) {
        console.log('close snackbar');

        setSnackBarOpen(false);
      }
    }, 30000);
    return () => clearTimeout(intervalId);
  }, [snackBarOpen]);

  return (
    <Grid container>
      <Snackbar
        open={snackBarOpen}
        key={transition ? transition.name : ''}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        // classes={{ root: classes.snackBarContainer }}
        ContentProps={{
          className: classes.snackBarContainer,
        }}
        sx={{
          '.MuiSnackbarContent-message': {
            width: '100%',
          },
        }}
        message={
          <Grid container>
            <Box display="flex" width="100%" sx={{ padding: '10px 0' }}>
              <img src={NotificationPopupIcon} />
              <Typography className={classes.snackBarHeading} variant="subtitle1">
                New notification
              </Typography>
            </Box>
            <Box display="flex" width="100%" alignItems="flex-start">
              <img src={PurpleDotIcon} style={{ padding: '6px 10px' }} />
              <Box sx={{ paddingLeft: '13px', width: '100%' }}>
                <Typography className={classes.notificationPopupHeading} variant="subtitle2">
                  {`Reference ID - ${alertmsg?.referenceId}`}
                </Typography>
                <Typography
                  className={classes.snackBarContent}
                  variant="subtitle2"
                  tabIndex="0"
                  onClick={onOpen}
                  sx={{ cursor: 'pointer' }}
                >
                  {alertmsg?.displayText}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ paddingTop: '10px', textAlign: 'center' }}
                >
                  <Typography
                    className={classes.snackBarHeading}
                    data-testid="snackbarClose"
                    onClick={handleSnackbarClose}
                    tabIndex={0}
                    sx={{ cursor: 'pointer' }}
                  >
                    <img src={NotificationCancel} />
                    <Typography variant="subtitle2" component="p" color="secondary" mt={1}>
                      Dismiss
                    </Typography>
                  </Typography>
                  <Typography
                    className={classes.snackBarHeading}
                    onClick={() => {
                      setSnackBarOpen(false);
                      setSnooze(true);
                    }}
                    tabIndex={0}
                    sx={{ cursor: 'pointer' }}
                  >
                    <img src={SnoozeIcon} />
                    <Typography variant="subtitle2" component="p" color="secondary" mt={1}>
                      Snooze
                    </Typography>
                  </Typography>

                  <Typography
                    className={classes.snackBarHeading}
                    onClick={onOpen}
                    tabIndex={0}
                    sx={{ cursor: 'pointer' }}
                  >
                    <img src={PopupOpenIcon} />
                    <Typography variant="subtitle2" component="p" color="secondary" mt={1}>
                      Open
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        }
      />
    </Grid>
  );
};
export default NotificationSnackBar;

NotificationSnackBar.propTypes = {
  snackBarOpen: PropTypes.bool,
  setSnackBarOpen: PropTypes.func,
  transition: PropTypes.instanceOf(Object),
  alertmsg: PropTypes.string,
  onOpen: PropTypes.func,
  setSnooze: PropTypes.func,
};
NotificationSnackBar.defaultProps = {
  snackBarOpen: false,
  transition: {},
  setSnackBarOpen: defaultFn,
  alertmsg: '',
  onOpen: defaultFn,
  setSnooze: defaultFn,
};
