import { Box, Button, Paper, Tab, Tabs, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import GeneralInfo from 'pages/Lookup/components/GeneralInfo';
import PageHeading from 'pages/Lookup/components/PageHeading';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import Loader from 'components/Loader/Loader';
import TabPanel from 'components/TabPanel/TabPanel';

import VehicleValueSummaryNew from '../VehicleValueSummaryNew/VehicleValueSummaryNew';
import { OptionLogicErrorPopup } from './OptionLogicErrorPopup';
import OptionsDialog from './OptionsDialog';
import Residuals from './Residuals';
import StandardOptions from './StandardOptions';

const EquipmentAdjustmentNew = ({
  options,
  productDetails,
  addDeductList,
  msrp,
  invoice: invoicee,
  uvc,
  uvcTieBreaker,
  coApplicant,
  coSigner,
  reviewFlag,
  calculationState,
  completedSteps,
  viewAppFlow,
}) => {
  console.log('addDeductList', addDeductList);
  const ApiClient = new ApiClass();
  const [selectedRows, setSelectedRows] = useState([]);
  const [popupRows, setPopupRows] = useState([]);
  const [errorPopup, setErrorPopup] = useState(false);
  console.log('errorPopup', errorPopup);
  const {
    showSummary,
    setShowSummary,
    lookupLinkClicked,
    collateralInfo,
    setDialogOpen,
    pageData,
    setPageData,
    setCreateAppLookup,
    setCreateAppFlag,
  } = useContext(AuthContext);
  const [selectedDeductRows, setSelectedDeductRows] = useState([]);
  const [fields, setFields] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedDeductRows([]);
  }, [addDeductList]);

  const rows = options.map((x) => {
    const {
      option_category: category,
      option_description: description,
      retail_amount: retail = 0,
      invoice_amount: invoice = 0,
      status_code: statusCode,
      sequence_number: seqNumber,
      include_option_flag: includeOptionFlag,
    } = x;
    return {
      description,
      category,
      retail,
      invoice,
      statusCode,
      seqNumber,
      includeOptionFlag,
    };
  });

  const groupBy = (data, key) =>
    data.reduce((acc, item) => {
      // eslint-disable-next-line no-param-reassign

      const group = item[key];
      if (!acc[group]) {
        // eslint-disable-next-line no-param-reassign
        acc[group] = [];
      }
      acc[group].push(item);
      return acc;
    }, {});

  useEffect(() => {
    const popRow = rows
      .filter((x) => x.statusCode === 'Y')
      .map((item) => {
        if (selectedRows.find((el) => el.seqNumber === item.seqNumber)) {
          return { ...item, isChecked: true };
        }
        return { ...item, isChecked: false };
      });

    setPopupRows(popRow);
    if (popRow.length > 0) {
      if (selectedRows.filter((r) => r.statusCode === 'Y').length) {
        setDialogOpen(false);
      } else {
        setDialogOpen(true);
      }
    }
    let selRow = rows.filter((x) => x.statusCode === 'G');
    setSelectedRows(selRow);
  }, [options]);

  const excludingY = rows.filter((x) => x.statusCode !== 'Y');

  const groupedByCategory = groupBy([...excludingY, ...popupRows], 'category');

  const { year, make, model, series, style } = productDetails;

  const getOptions = async (seqNum, status, selected, uvc = '', uvc_tie_breaker = '') => {
    try {
      setLoading(true);
      const res = await ApiClient.post(
        UrlHelpers.getColorsAndOptions,
        {
          uvc,
          uvc_tie_breaker,
          optionAffected: `${seqNum}`,
          chgOptStCode: status,
          optChangeState: `${selected ? 1 : 0}`,
          customerid: process.env.REACT_APP_CUSTOMER_ID,
          option_list: options,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      const optionsList = JSON.parse(res?.option || '{}')?.options?.option_list || [];
      setLoading(false);
      return { optionsList };
    } catch (e) {
      setLoading(false);
      console.log('e', e);
    }
  };

  const handleCheckboxChange = async (event, row) => {
    console.log('row', row);
    const selected = event.target.checked;
    if (selected) {
      setSelectedRows([...selectedRows, row]);
      if (row.statusCode === 'Y') {
        const newRow = popupRows.map((x) => {
          if (x.seqNumber === row.seqNumber) {
            console.log('popuprows', x);
            return { ...x, isChecked: true };
          }
          return x;
        });
        setPopupRows(newRow);
        setDialogOpen(false);
      }
    } else {
      setSelectedRows(selectedRows.filter((r) => r.seqNumber !== row.seqNumber));
      if (row.statusCode === 'Y') {
        const newRow = popupRows.map((x) => {
          if (x.seqNumber === row.seqNumber) {
            return { ...x, isChecked: false };
          }
          return x;
        });
        setPopupRows(newRow);
        setDialogOpen(false);
      }
    }
    const { optionsList = [] } = await getOptions(
      row.seqNumber,
      row.statusCode,
      selected,
      pageData.uvc,
      pageData.uvcTieBreaker
    );
    if (optionsList.length > 0) {
      setPageData({ ...pageData, options: optionsList });
    } else {
      setErrorPopup(true);
      let wholeOptions = [...pageData?.options];
      let disableErrorIndex = pageData?.options.findIndex(
        (x) => x['sequence_number'] === row.seqNumber
      );
      wholeOptions[disableErrorIndex]['status_code'] = 'R';
      setPageData({ ...pageData, options: wholeOptions });
    }
  };

  const handleCloseError = () => {
    setErrorPopup(false);
  };

  const handleResidualsCheckboxChange = (event, row) => {
    console.log('row', row, event);
    const selected = event.target.checked;
    if (selected) {
      setSelectedDeductRows([...selectedDeductRows, row]);
    } else {
      setSelectedDeductRows(selectedDeductRows.filter((r) => r.seqNumber !== row.seqNumber));
    }
  };

  console.log('selectedRows', selectedRows);

  const totalmsrp = selectedRows.reduce((acc, currentValue) => acc + currentValue.retail, 0);
  const totalInvoice = selectedRows.reduce((acc, currentValue) => acc + currentValue.invoice, 0);
  const totalResiduals = selectedDeductRows?.reduce(
    (acc, eachObj) => {
      let totall = acc;
      totall.residual_12 += eachObj?.residual_12;
      totall.residual_24 += eachObj?.residual_24;
      totall.residual_36 += eachObj?.residual_36;
      totall.residual_48 += eachObj?.residual_48;
      totall.residual_60 += eachObj?.residual_60;
      return totall;
    },
    { residual_12: 0, residual_24: 0, residual_36: 0, residual_48: 0, residual_60: 0 }
  );
  let mt = '140px';
  if (addDeductList.length === 0 && !showSummary) {
    mt = '70px';
  } else if (showSummary) {
    mt = '38px';
  }
  console.log('fields', fields);
  const onNext = () => {
    setFields({
      productDetails,
      equipmentAdjustment: { invoice: totalInvoice, msrp: totalmsrp },
      residuals: { ...totalResiduals },
      uvc,
      uvcTieBreaker,
      basevehicleValue: { msrp, invoice: invoicee },
    });
    setShowSummary(true);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };
  const [tabvalue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper sx={{ minHeight: '80vh', padding: '20px', marginBottom: '60px' }}>
      <OptionLogicErrorPopup open={errorPopup} handleClose={handleCloseError} />
      <PageHeading
        collateralType="New"
        valueGuide="Black Book"
        showSummary={showSummary}
        setShowSummary={setShowSummary}
        setSelectedRows={setSelectedRows}
        setCreateAppLookup={() => {
          setCreateAppLookup('new');
          setCreateAppFlag(true);
        }}
      />
      <Box display="flex" className={classes.equipAdjustment}>
        {showSummary && (
          <VehicleValueSummaryNew
            equipmentAdjustment={fields?.equipmentAdjustment}
            residuals={fields?.residuals}
            uvc={fields?.uvc}
            uvcTieBreaker={fields?.uvcTieBreaker}
            basevehicleValue={fields?.basevehicleValue}
            selectedRows={selectedRows}
            coApplicant={coApplicant}
            coSigner={coSigner}
            reviewFlag={reviewFlag}
            calculationState={calculationState}
            completedSteps={completedSteps}
            viewAppFlow={viewAppFlow}
          />
        )}
        {!showSummary && (
          <Box display="flex" flexDirection="column" gap={2} flexGrow="1">
            {<Loader open={loading} />}
            <Typography sx={{ fontSize: '18px!important', fontWeight: 700 }}>
              Optional equipment
            </Typography>
            <OptionsDialog
              popupRows={popupRows}
              handleCheckboxChange={handleCheckboxChange}
              selectedRows={selectedRows}
            />
            {addDeductList.length > 0 ? (
              <Box sx={{ width: '100%' }}>
                <Box>
                  <Tabs
                    value={tabvalue}
                    onChange={handleChange}
                    aria-label="new equipment adjustment"
                  >
                    <Tab label="Standard Options" {...a11yProps(0)} />
                    <Tab label="Residuals" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={tabvalue} index={0}>
                  <StandardOptions
                    year={year}
                    make={make}
                    model={model}
                    series={series}
                    style={style}
                    totalInvoice={totalInvoice}
                    totalmsrp={totalmsrp}
                    groupedByCategory={groupedByCategory}
                    selectedRows={selectedRows}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                </TabPanel>
                <TabPanel value={tabvalue} index={1}>
                  <Residuals
                    year={year}
                    make={make}
                    model={model}
                    series={series}
                    style={style}
                    total={totalResiduals}
                    addDeductList={addDeductList}
                    selectedRows={selectedDeductRows}
                    handleCheckboxChange={handleResidualsCheckboxChange}
                  />
                </TabPanel>
              </Box>
            ) : (
              <Box width="100%">
                <StandardOptions
                  year={year}
                  make={make}
                  model={model}
                  series={series}
                  style={style}
                  totalInvoice={totalInvoice}
                  totalmsrp={totalmsrp}
                  groupedByCategory={groupedByCategory}
                  selectedRows={selectedRows}
                  handleCheckboxChange={handleCheckboxChange}
                />
              </Box>
            )}
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                onClick={onNext}
                variant="contained"
                color="secondary"
                id="get-vehicle-value-btn"
              >
                Get vehicle value
              </Button>
            </Box>
          </Box>
        )}

        <Box
          display="flex"
          flexDirection="column"
          minWidth="25%"
          gap={2.5}
          mt={{ lg: mt }}
          className={classes.tableOutline}
          maxHeight={showSummary ? '500px' : '450px'}
          backgroundColor="#f2f2f2"
          p={2.5}
        >
          <GeneralInfo
            productDetails={lookupLinkClicked ? collateralInfo?.productDetails : productDetails}
          />
        </Box>
      </Box>
    </Paper>
  );
};
export default EquipmentAdjustmentNew;

EquipmentAdjustmentNew.propTypes = {
  options: PropTypes.instanceOf(Object),
  productDetails: PropTypes.instanceOf(Object),
  addDeductList: PropTypes.instanceOf(Object),
  msrp: PropTypes.number,
  invoice: PropTypes.number,
  uvc: PropTypes.string,
  uvcTieBreaker: PropTypes.string,
  coApplicant: PropTypes.instanceOf(Object),
  coSigner: PropTypes.instanceOf(Object),
  reviewFlag: PropTypes.bool,
  calculationState: PropTypes.string,
  completedSteps: PropTypes.array,
  viewAppFlow: PropTypes.bool,
};
EquipmentAdjustmentNew.defaultProps = {
  options: [],
  productDetails: {},
  addDeductList: [],
  msrp: 0,
  invoice: 0,
  uvc: '',
  uvcTieBreaker: '',
  coApplicant: {},
  coSigner: [],
  reviewFlag: false,
  calculationState: '',
  completedSteps: [],
  viewAppFlow: false,
};
