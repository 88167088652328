import { Box, Button, Grid, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import { payCalcOnLoadApi } from 'Services/PaymentCalcApi';
import FinancialTermsImage from 'assets/svg/FinancialTermsImage.svg';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { removeNullValues, ternaryCondition } from 'utils/utility';

import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';

import CancelEditPopup from '../CancelEditPopup';
import CreateApplicationFooter from '../CreateApplicationFooter';
import { submitApi, viewApplication } from '../InformationRequest/moduleUtilities/moduleUtilities';
import ReOpenApplicationFooter from '../ReOpenApplicationFooter';
import EstimatedMonthlyPayment from './EstimatedMonthlyPayment.js/EstimatedMonthlyPayment';
import PaymentCalculatorDetails from './PaymentCalculatorDetails/PaymentCalculatorDetails';

const FinancialTerms = () => {
  const navigate = useNavigate();

  const ApiClient = new ApiClass();
  const [loader, setLoader] = useState(false);
  const [calculatedData, setCalculatedData] = useState({});
  const [apiErr, setApiErr] = useState('');
  const [openCancelAlert, setOpenCancelAlert] = useState(false);
  const [promotionsData, setPromotionsData] = useState({});
  const {
    setActiveStep,
    setCompletedSteps,
    reviewFlag,
    coApplicant,
    coSigner,
    setOpenDraftModal,
    completedSteps,
    viewAppFlow,
    setCaseReferenceId,
    setAppSubmitted,
    unfilled,
    setUnfilled,
  } = useContext(MainApplicationContext);
  const {
    createAppApplicationId,
    setOpenPopUp,
    setCreateAppLookup,
    userID,
    setCreateAppFlag,
    setDealer,
    setCreateAppLaunchType,
    fullName,
    rolebasedDealers,
    dealer,
    createAppFlag,
  } = useContext(AuthContext);

  const onBackClick = () => {
    setActiveStep(3);
  };

  const onCancelEditSubmitted = async () => {
    if (unfilled) {
      setApiErr(
        'Please review financing terms and Continue, as value/s in collateral screen are modified'
      );
    } else {
      setOpenCancelAlert(true);
    }
  };

  const onConfirmCancel = async () => {
    setCreateAppFlag(false);
    setDealer('');
    setOpenCancelAlert(false);
    await viewApplication({ setLoader, navigate, createAppApplicationId, userID, fullName });
  };
  const updateSubmitted = async () => {
    await handleSave({ updateFlag: true });
  };

  const continueSubmitted = async () => {
    await handleSave({ updateFlag: false });
  };
  useEffect(async () => {
    try {
      setLoader(true);
      let calculationData = await ApiClient.get(
        `${UrlHelpers.financeTermsPayCalcSave}?applicationId=${createAppApplicationId}&type=FinanceTermPayCalc`
      );
      await ApiClient.get(
        `${UrlHelpers.getSpecificDetails}?applicationId=${createAppApplicationId}&eventName=FinancialTerms&isLockRequired=true`,
        {
          headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
        }
      );
      if (calculationData) {
        let resultFromPayCalc = JSON.parse(calculationData?.[0]?.searchResult || '{}');
        console.log({ resultFromPayCalc, calculationData });
        let paymentSchedule = resultFromPayCalc?.calculatedData?.paymentSchedules?.map((each) => {
          return {
            noOfPayments: each.noOfPayments,
            paymentAmount: each.paymentAmount,
            startDate: each.startDate,
          };
        });
        console.log({ paymentSchedule });
        resultFromPayCalc.calculatedData.paymentSchedules = paymentSchedule;
        setCalculatedData(resultFromPayCalc);
      }
    } catch (e) {
      console.log('error fetching', e);
    } finally {
      setLoader(false);
    }
  }, []);

  const handleSave = async ({ draft = false, updateFlag }) => {
    let isValid = true;
    if (!Object.entries(calculatedData).length) {
      isValid = false;
      setApiErr('Please complete the required information to proceed');
    }
    if (Object.entries(calculatedData).length && isValid) {
      let finalPayCalc = calculatedData?.calculatedData?.paymentSchedules?.map((each) => {
        return {
          noOfPayments: each.noOfPayments,
          amount: each.paymentAmount,
          startDate: moment(each.startDate).format('YYYY-MM-DD'),
        };
      });
      let dateString = calculatedData?.formData?.contractDate;
      let dateMomentObject = moment(dateString, 'MM/DD/YYYY');
      let dateObject = dateMomentObject.toDate();
      const formData = {
        estimatedMonthlyPayment: {
          financedFees: removeNullValues(calculatedData?.calculatedData?.financedFees),
          registrationFees: removeNullValues(calculatedData?.calculatedData?.registrationFees),
          amountFinanced: removeNullValues(calculatedData?.calculatedData?.amountFinanced),
          balanceAmountFinance: removeNullValues(calculatedData?.calculatedData?.balance),
          netTradeIn: removeNullValues(calculatedData?.calculatedData?.netTradeIn),
          financeCharge: removeNullValues(calculatedData?.calculatedData?.financeCharge),
          baloonPayment: removeNullValues(calculatedData?.baloonPayment),
          nonFinancedFees: removeNullValues(calculatedData?.calculatedData?.nonFinancedFees),
          aPR: removeNullValues(calculatedData?.apr),
          paymentSchedule: finalPayCalc,
        },
        paymentCalculatorDetails: {
          program: removeNullValues(calculatedData?.formData?.programVal),
          promotion: removeNullValues(calculatedData?.formData?.promotion),
          promotionLabel: removeNullValues(promotionsData?.promotionLabel),
          newUsed: removeNullValues(calculatedData?.formData?.newOrUsed),
          vehicleClass: removeNullValues(
            await ternaryCondition(
              calculatedData?.formData?.vehicleClass === 'import',
              'imported',
              'local'
            )
          ),
          term: removeNullValues(calculatedData?.formData?.term),
          rate: removeNullValues(calculatedData?.formData?.rate),
          contractDate: removeNullValues(moment(dateObject).format('MM/DD/yyyy')),
          salePrice: removeNullValues(calculatedData?.formData?.salePrice),
          tradeInDebt: removeNullValues(calculatedData?.formData?.tradeDept),
          cashDown: removeNullValues(calculatedData?.formData?.cashDown),
          tradeInAllowance: removeNullValues(calculatedData?.formData?.tradeAllowance),
          rebate: removeNullValues(calculatedData?.formData?.rebate),
          netTradeIn: removeNullValues(calculatedData?.formData?.netTrade),
          carCare: removeNullValues(calculatedData?.formData?.carCare),
          extendedWarranty: removeNullValues(calculatedData?.formData?.extendedWarranty),
          serviceContract: removeNullValues(calculatedData?.formData?.serviceContract),
          paintProtection: removeNullValues(calculatedData?.formData?.paintProtection),
          other: removeNullValues(calculatedData?.formData?.other),
          tradeInVehicleYear: removeNullValues(calculatedData?.formData?.year),
          tradeInVehicleMake: removeNullValues(calculatedData?.formData?.make),
          tradeInVehicleModel: removeNullValues(calculatedData?.formData?.model),
          wishToTradeIn: removeNullValues(
            await ternaryCondition(calculatedData?.formData?.wishToTradeIn === 'yes', true, false)
          ),
          balance: removeNullValues(calculatedData?.formData?.balance),
          creditLife: removeNullValues(calculatedData?.formData?.creditLife),
          creditLifeType: removeNullValues(calculatedData?.formData?.creditLifeType),
          vehicleInsuranceType: removeNullValues(calculatedData?.formData?.vehicleInsuranceType),
          creditLifeLifeInsuranceAmount:
            removeNullValues(calculatedData?.formData?.creditLifeAmount) || 0,
          vehicleInsuranceAmount:
            removeNullValues(calculatedData?.formData?.vehicleInsuranceAmount) || 0,
          gapFee: removeNullValues(calculatedData?.formData?.gapFee),
          registrationFees: removeNullValues(calculatedData?.formData?.registrationfee),
          monthsDeferred: removeNullValues(calculatedData?.formData?.monthDeferred),
        },
      };

      let financeTermPayload = {
        event: 'FinancialTerms',
        application: {
          products: {
            productType: 'Indirect Loan Account',
            productName: 'AutoLoanManual',
          },

          applicationId: createAppApplicationId,
          userId: userID,
          financialTerms: { ...formData },
          source: {
            language: 'EN',
            location: 'PR',
          },
        },
      };

      try {
        setLoader(true);
        const endpoint = await ternaryCondition(
          draft,
          UrlHelpers.applicationDraft,
          UrlHelpers.informationReqSave
        );
        let calculate = await ApiClient.post(
          endpoint,
          { ...financeTermPayload },
          {
            headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
          }
        );

        if (calculate && !draft && !viewAppFlow) {
          setActiveStep(5);
          setCompletedSteps([1, 2, 3, 4]);
        } else if (viewAppFlow) {
          await handleResubmitApplication({ updateFlag });
        } else if (draft && calculate) {
          setOpenDraftModal(true);
        }
      } catch (e) {
        console.log('error fetching', e);
        setApiErr(
          e?.response?.data ||
            'We are unable to proceed due to a technical issue, please try again later'
        );
      } finally {
        setLoader(false);
      }
    }
  };

  const handleResubmitApplication = async ({ updateFlag }) => {
    setCompletedSteps([1, 2, 3, 4, 5]);
    setUnfilled(false);
    if (updateFlag) {
      await submitApi({
        createAppApplicationId,
        userID,
        setLoader,
        setCaseReferenceId,
        setAppSubmitted,
        setCreateAppFlag,
        setDealer,
        setCreateAppLaunchType,
        setApiErr,
        fullName,
      });
    } else {
      setActiveStep(5);
    }
  };

  useEffect(async () => {
    if (calculatedData?.formData?.promotion) {
      const onLoadData = await payCalcOnLoadApi({
        rolebasedDealers,
        dealer,
        createAppFlag,
        promotionId: calculatedData?.formData?.promotion,
      });
      setPromotionsData(onLoadData);
    }
  }, [calculatedData?.formData?.promotion]);
  return (
    <>
      <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
      <CancelEditPopup
        openModal={openCancelAlert}
        setOpenModal={setOpenCancelAlert}
        onConfirmCancel={onConfirmCancel}
      />
      <Typography variant="h3" component="p" py={4}>
        Financing terms
      </Typography>
      <Loader open={loader} />
      <Grid container justifyContent="center">
        <Box sx={{ width: '70%' }}>
          {Object.entries(calculatedData).length === 0 && (
            <Grid
              container
              justifyContent="center"
              sx={{
                border: apiErr ? '1px solid red' : 'none',
              }}
            >
              <Grid
                container
                lg={12}
                md={12}
                justifyContent="space-between"
                sx={{
                  background: 'linear-gradient(180deg, #FFFFFF 0%, #F3F3F3 100%)',
                  border: '1px solid #909090',
                  padding: '22px',
                }}
              >
                <Grid item lg={4} md={4} p={1}>
                  <img src={FinancialTermsImage} alt="Calculator Image" style={{ width: '100%' }} />
                </Grid>
                <Grid item lg={8} md={8} p={1}>
                  <Box>
                    <Typography variant="h3" component="p" color="primary">
                      Payment calculator tool
                    </Typography>
                    <Typography variant="subtitle2" component="p" py={3}>
                      This tool helps to bring an estimate on payments based on the financial
                      information provided. Click the button below.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ maxHeight: '40px' }}
                      onClick={() => {
                        setOpenPopUp(false);
                        setCreateAppLookup('');
                        navigate('/paymentCalculator', {
                          state: {
                            flow: 'financialTerms',
                            coApplicant,
                            coSigner,
                            completedSteps,
                            viewAppFlow,
                            unfilled,
                          },
                        });
                      }}
                    >
                      Calculate
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}

          {Object.entries(calculatedData).length > 0 && (
            <>
              <EstimatedMonthlyPayment
                calculatedData={calculatedData}
                reviewFlag={reviewFlag}
                completedSteps={completedSteps}
                viewAppFlow={viewAppFlow}
                unfilled={unfilled}
              />
              <PaymentCalculatorDetails
                calculatedData={calculatedData}
                promotionsData={promotionsData}
              />
            </>
          )}
        </Box>
      </Grid>

      {viewAppFlow ? (
        <ReOpenApplicationFooter
          onCancelEditSubmitted={onCancelEditSubmitted}
          updateSubmitted={updateSubmitted}
          continueSubmitted={continueSubmitted}
          backFlag={unfilled}
          onBackClick={onBackClick}
        />
      ) : (
        <CreateApplicationFooter
          disabledFlag={reviewFlag}
          onBackClick={onBackClick}
          onSaveContinue={handleSave}
          onSaveDraft={() => handleSave({ draft: true })}
          draftDisabledFlag={!Object.entries(calculatedData).length}
        />
      )}
    </>
  );
};

export default FinancialTerms;
