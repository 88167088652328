import axios from 'axios';
import axiosRetry from 'axios-retry';

const customAxios = () => {
  const axiosInstance = axios.create();
  const accessToken = sessionStorage.getItem('accessToken');
  const RoleId = sessionStorage.getItem('RoleId');

  // request interceptor for adding token
  axiosInstance.interceptors.request.use((config) => {
    const configg = config;

    configg.headers['Authorization'] = `Bearer ${accessToken}`;
    configg.headers['RoleID'] = RoleId;
    configg.headers['appid'] = 'auto';

    return config;
  });

  // response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log('err', error);
      if (error?.response?.status === 403) {
        const userType = sessionStorage.getItem('userType');

        if (userType?.toLowerCase() === 'dealer') {
          const cognitoConfig = JSON.parse(process.env.REACT_APP_CONFIG);
          window.location.replace(`${cognitoConfig.REDIRECTSIGNOUT}/?autoSignout=no`);
        } else {
          window.location.replace(`${process.env.REACT_APP_LOGOUT_URL}`);
        }
      }

      return Promise.reject(error);
    }
  );

  axiosRetry(axiosInstance, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition(error) {
      switch (error.response.status) {
        case 500:
        case 501:
        case 502:
        case 503:
        case 504:
        case 505:
          if (
            error?.response?.data?.responseCode &&
            [1009, 1010].includes(error?.response?.data?.responseCode)
          ) {
            return false;
          }

          return true;

        default:
          return false;
      }
    },
  });
  return axiosInstance;
};

export default customAxios;
