import { Box, Grid, InputLabel, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import manageClasses from 'styles/ManageDealers.module.scss';

import CustomCollapse from 'components/CustomCollapse';
import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

const UserStatus = ({
  control,
  errors,
  isViewProfile,
  lastLoginDate,
  lastReActivationDate,
  lastInactivationDate,
  activeText,
}) => {
  let activetext = activeText === 'Yes' ? 'Active' : 'Inactive';
  return (
    <div>
      <CustomCollapse heading="User status" open={true} errors={errors}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
              <InputLabel>Status</InputLabel>
              <Typography sx={{ width: '100%' }}>
                {!isViewProfile ? '' : `${activetext}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Active"
              control={control}
              name="isActive"
              errors={!!errors?.isActive}
              errorText={errors?.isActive?.message}
              options={[
                {
                  name: 'Yes',
                  value: 'Yes',
                },
                {
                  name: 'No',
                  value: 'No',
                },
              ]}
              defaultValue=""
              required={!isViewProfile}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
              <InputLabel>Last inactivation date</InputLabel>
              {isViewProfile ? (
                <Typography sx={{ width: '100%' }}>
                  {lastInactivationDate
                    ? moment(lastInactivationDate).format('MM/DD/yyyy, h:mm A')
                    : '-'}
                </Typography>
              ) : (
                <Typography sx={{ width: '100%' }}>-</Typography>
              )}
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
              <InputLabel>Last reactivation date</InputLabel>
              {isViewProfile ? (
                <Typography sx={{ width: '100%' }}>
                  {lastReActivationDate
                    ? moment(lastReActivationDate).format('MM/DD/yyyy, h:mm A')
                    : '-'}
                </Typography>
              ) : (
                <Typography sx={{ width: '100%' }}>-</Typography>
              )}
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
              <InputLabel>Last login</InputLabel>
              {isViewProfile ? (
                <Typography sx={{ width: '100%' }}>
                  {lastLoginDate ? moment(lastLoginDate).format('MM/DD/YYYY') : '-'}
                </Typography>
              ) : (
                <Typography sx={{ width: '100%' }}>-</Typography>
              )}
            </Box>
          </Grid>
          {isViewProfile && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
                <InputLabel>Password</InputLabel>
                <input
                  placeholder="Enter reset password"
                  className={manageClasses.resetPasswordInputStyle}
                  // onChange={searchTermChange}
                  data-testid="resetPassword"
                  value={'●●●●●●●●'}
                  // disabled
                  contentEditable={'false'}
                  // type="password"
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </CustomCollapse>
      <CustomCollapse heading="User configuration" open={true}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Maximize TTY"
              control={control}
              name="maximizeTty"
              errors={!!errors?.maximizeTty}
              errorText={errors?.maximizeTty?.message}
              options={[
                {
                  name: 'Yes',
                  value: 'Yes',
                },
                {
                  name: 'No',
                  value: 'No',
                },
              ]}
              defaultValue=""
              required={!isViewProfile}
              displayEmpty
            />
          </Grid>
        </Grid>
      </CustomCollapse>
      <CustomCollapse heading="Miscellaneous fields" open={true}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.ssn}
              errorText={errors?.ssn?.message}
              placeholder="Enter SSN"
              label="SSN (LAST 4 DIGITS)"
              name="ssn"
              flexDirection="column"
              required={!isViewProfile}
              maxLength={4}
              regex={/^\d$/}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomDatePicker
              name="dob"
              label="Date of birth"
              control={control}
              error={!!errors?.dob}
              errorText={errors?.dob?.message}
              required={!isViewProfile}
              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
              placeHolder="MM/DD/YYYY"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.vendorNumber}
              errorText={errors?.vendorNumber?.message}
              placeholder="Enter vendor number"
              label="Vendor number"
              name="vendorNumber"
              flexDirection="column"
              maxLength="11"
            />
          </Grid>
        </Grid>
      </CustomCollapse>
    </div>
  );
};

UserStatus.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  isViewProfile: PropTypes.bool,
  lastLoginDate: PropTypes.string,
  lastReActivationDate: PropTypes.string,
  lastInactivationDate: PropTypes.string,
  activeText: PropTypes.string,
};
UserStatus.defaultProps = {
  control: {},
  errors: {},
  isViewProfile: false,
  lastLoginDate: '',
  lastReActivationDate: '',
  lastInactivationDate: '',
  activeText: '',
};
export default UserStatus;
