import { Box } from '@mui/material';
import MainApplicationContext from 'context/MainApplicationContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import classes from 'styles/globalStyle.module.scss';
import { defaultFn } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';

import FinancingDetails from '../FinancialTerms/FinancingDetails';
import PaymentCalculatorDetails from '../FinancialTerms/PaymentCalculatorDetails/PaymentCalculatorDetails';

const FinancialTermsSection = ({
  financialTerms,
  viewAppFlow,
  setOpenPopup,
  setLandingUser,
  setLandingStep,
  promotionsData,
}) => {
  console.log({ financialTerms });
  const monthlyPayData = {
    financedFees: financialTerms?.estimatedMonthlyPayment?.financedFees,
    registrationFees: financialTerms?.estimatedMonthlyPayment?.registrationFees,
    amountFinanced: financialTerms?.estimatedMonthlyPayment?.amountFinanced,
    balance: financialTerms?.estimatedMonthlyPayment?.balanceAmountFinance,
    netTradeIn: financialTerms?.estimatedMonthlyPayment?.netTradeIn,
    financeCharge: financialTerms?.estimatedMonthlyPayment?.financeCharge,
    baloonPayment: financialTerms?.estimatedMonthlyPayment?.baloonPayment,
    nonFinancedFees: financialTerms?.estimatedMonthlyPayment?.nonFinancedFees,
    apr: financialTerms?.estimatedMonthlyPayment?.apr,
    paymentSchedules: financialTerms?.estimatedMonthlyPayment?.paymentSchedule,
  };

  const calculatedData = {
    formData: {
      programVal: financialTerms?.paymentCalculatorDetails?.program,
      promotion: financialTerms?.paymentCalculatorDetails?.promotion,
      promotionLabel: promotionsData?.promotionLabel,
      newOrUsed: financialTerms?.paymentCalculatorDetails?.newUsed,
      vehicleClass: financialTerms?.paymentCalculatorDetails?.vehicleClass,
      term: financialTerms?.paymentCalculatorDetails?.term,
      rate: financialTerms?.paymentCalculatorDetails?.rate,
      contractDate: financialTerms?.paymentCalculatorDetails?.contractDate,
      salePrice: financialTerms?.paymentCalculatorDetails?.salePrice,
      tradeDept: financialTerms?.paymentCalculatorDetails?.tradeInDebt,
      cashDown: financialTerms?.paymentCalculatorDetails?.cashDown,
      tradeAllowance: financialTerms?.paymentCalculatorDetails?.tradeInAllowance,
      rebate: financialTerms?.paymentCalculatorDetails?.rebate,
      carCare: financialTerms?.paymentCalculatorDetails?.carCare,

      extendedWarranty: financialTerms?.paymentCalculatorDetails?.extendedWarranty,
      serviceContract: financialTerms?.paymentCalculatorDetails?.serviceContract,
      paintProtection: financialTerms?.paymentCalculatorDetails?.paintProtection,
      other: financialTerms?.paymentCalculatorDetails?.other,
      year:
        financialTerms?.paymentCalculatorDetails?.year ||
        financialTerms?.paymentCalculatorDetails?.tradeInVehicleYear,
      make:
        financialTerms?.paymentCalculatorDetails?.make ||
        financialTerms?.paymentCalculatorDetails?.tradeInVehicleMake,
      model:
        financialTerms?.paymentCalculatorDetails?.model ||
        financialTerms?.paymentCalculatorDetails?.tradeInVehicleModel,
      creditLife: financialTerms?.paymentCalculatorDetails?.creditLife,
      creditLifeType: financialTerms?.paymentCalculatorDetails?.creditLifeType,
      vehicleInsuranceType: financialTerms?.paymentCalculatorDetails?.vehicleInsuranceType,
      creditLifeAmount: financialTerms?.paymentCalculatorDetails?.creditLifeLifeInsuranceAmount,
      vehicleInsuranceAmount: financialTerms?.paymentCalculatorDetails?.vehicleInsuranceAmount,
      criticalIllness: financialTerms?.paymentCalculatorDetails?.crititicalIllness,
      hospitalization: financialTerms?.paymentCalculatorDetails?.hospitalization,
      gapFee: financialTerms?.paymentCalculatorDetails?.gapFee,
      monthDeferred: financialTerms?.paymentCalculatorDetails?.monthsDeferred,
    },
    calculatedData: {
      balance: financialTerms?.paymentCalculatorDetails?.balance,
      registrationFees: financialTerms?.estimatedMonthlyPayment?.registrationFees,
      netTradeIn: financialTerms?.paymentCalculatorDetails?.netTradeIn,
    },
  };

  console.log('financialTerms', financialTerms);
  const { setReviewFlag, setActiveStep } = useContext(MainApplicationContext);
  const onEdit = () => {
    if (viewAppFlow) {
      setOpenPopup(true);
      setLandingUser('');
      setLandingStep(4);
    } else {
      setReviewFlag(true);
      setActiveStep(4);
    }
  };
  return (
    <Box className={classes.reviewSubmitFinance}>
      <CustomCollapse
        heading="Financial terms"
        open={viewAppFlow ? false : true}
        editable={true}
        onEdit={onEdit}
      >
        <FinancingDetails monthlyPayData={monthlyPayData} calculatedData={calculatedData} />
        <PaymentCalculatorDetails
          calculatedData={calculatedData}
          promotionsData={promotionsData}
          reviewScreen
        />
      </CustomCollapse>
    </Box>
  );
};

export default FinancialTermsSection;
FinancialTermsSection.propTypes = {
  financialTerms: PropTypes.instanceOf(Object),
  viewAppFlow: PropTypes.bool,
  setOpenPopup: PropTypes.func,
  setLandingUser: PropTypes.func,
  setLandingStep: PropTypes.func,
  promotionsData: PropTypes.array,
};
FinancialTermsSection.defaultProps = {
  financialTerms: {},
  viewAppFlow: false,
  setOpenPopup: defaultFn,
  setLandingUser: defaultFn,
  setLandingStep: defaultFn,
  promotionsData: [],
};
