import { yupResolver } from '@hookform/resolvers/yup';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import RegisterUserContext from 'context/RegisterUserContext';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { editDealerUserStatusSchema } from 'schemaValidations/editDealerUserStatusSchema';
import { enrollDealerUserStatusSchema } from 'schemaValidations/enrollDealerUserStatusSchema';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn, handleFocusError, updateCompletedSteps } from 'utils/utility';

import Loader from 'components/Loader/Loader';

import UserStatus from '../UserStatus/UserStatus';

const UserStatusConfiguration = ({ handleCancel }) => {
  const [loader, setLoader] = useState(false);
  const yesAndNo = {
    Yes: true,
    No: false,
  };
  const ApiClient = new ApiClass();
  const navigate = useNavigate();

  const { setOpenDiscardModal, userName } = useContext(AuthContext);

  const {
    activeStep,
    setActiveStep,
    formDetails,
    setFormDetails,
    eachFormData,
    setEachFormData,
    isFormFilled,
    setIsFormFilled,
    isReview,
    setIsReview,
    isViewProfile,
    fullProfileData,
    formDirty,
    setFormDirty,
    completedSteps,
    setCompletedSteps,
  } = useContext(RegisterUserContext);
  const {
    control,
    watch,
    handleSubmit,
    setFocus,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: 'all',

    defaultValues: {
      isActive: '',
      maximizeTty: '',
      ssn: null,
      dob: '',
      vendorNumber: null,
    },
    resolver: yupResolver(
      !isViewProfile ? enrollDealerUserStatusSchema : editDealerUserStatusSchema
    ),
  });
  const [watchisActive, watchmaximizeTty, watchssn, watchdob, watchvendorNumber] = watch([
    'isActive',
    'maximizeTty',
    'ssn',
    'dob',
    'vendorNumber',
  ]);
  useEffect(() => {
    handleFocusError({ errors, setFocus });
  }, [errors]);
  const nextStep = () => {
    const formObj = {
      isActive: watchisActive,
      maximizeTty: watchmaximizeTty,
      ssn: watchssn,
      dob: watchdob,
      vendorNumber: watchvendorNumber,
    };
    setFormDetails({ ...formDetails, ...formObj });
    if (isReview) {
      setIsReview(false);
      setActiveStep(4);
    } else {
      setActiveStep(activeStep + 1);
    }
    const eachData = { ...eachFormData };
    eachData['2'] = formObj;
    setEachFormData(eachData);
    const formFilled = isFormFilled;
    formFilled['2'] = true;
    setIsFormFilled(formFilled);
    updateCompletedSteps([1, 2], completedSteps, setCompletedSteps);
  };
  const updateData = async () => {
    const formObj = {
      isActive: yesAndNo[watchisActive],
      maximizeTty: yesAndNo[watchmaximizeTty],
      ssn: watchssn,
      dob: watchdob || '',
      vendorNumber: watchvendorNumber,
    };
    const newObj = Object.keys(dirtyFields).reduce((a, v) => ({ ...a, [v]: formObj[v] }), {
      editedBy: userName,
    });
    try {
      setLoader(true);
      const r = await ApiClient.post(
        UrlHelpers.updateUserInfo + fullProfileData.userId,
        { ...newObj },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      console.log(r);
      navigate('/view-user-profile', {
        state: { updateStatus: 'success', profileData: { userId: fullProfileData.userId } },
      });
    } catch (e) {
      console.log('error fetching', e);
    } finally {
      setLoader(false);
    }
  };

  const continueNext = async () => {
    const formObject = {
      isActive: watchisActive,
      maximizeTty: watchmaximizeTty,
      ssn: watchssn,
      dob: watchdob || '',
      vendorNumber: watchvendorNumber,
    };
    setFormDirty({ ...formDirty, ...dirtyFields });
    console.log(formObject);
    setFormDetails({ ...formDetails, ...formObject });
    const eachData = { ...eachFormData };
    eachData['2'] = { ...eachData['2'], ...formObject };
    setEachFormData(eachData);

    setActiveStep(activeStep + 1);
  };
  const applyFormFields = async (formFields) => {
    Object.entries(formFields).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  useEffect(() => {
    if (isFormFilled['2'] === true) {
      applyFormFields(eachFormData['2']);
    }
  }, []);
  return (
    <>
      {loader && <Loader open={loader} />}
      <Typography variant="h3" component="p" pt={4} px={8}>
        User status and configuration
      </Typography>
      <Grid container justifyContent="center">
        <Box sx={{ width: '70%' }}>
          <UserStatus
            control={control}
            errors={errors}
            isViewProfile={isViewProfile}
            lastLoginDate={eachFormData['2'].lastLoginDate}
            lastReActivationDate={eachFormData['2'].lastReActivationDate}
            lastInactivationDate={eachFormData['2'].lastInactivationDate}
            activeText={watchisActive}
          />
        </Box>
      </Grid>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap={0.5} alignItems="center">
          <KeyboardArrowLeftIcon color="secondary" />
          <Link
            component="button"
            variant="subtitle2"
            color="secondary"
            onClick={() => {
              setActiveStep(1);
            }}
          >
            Back
          </Link>
        </Box>
        {!isViewProfile ? (
          <Box display="flex" gap={2} py={4} marginLeft={'auto'}>
            <Link
              component="button"
              onClick={() => {
                setOpenDiscardModal(true);
              }}
              variant="subtitle2"
              color="secondary"
              sx={{ fontSize: '16px' }}
              data-testid="discard-userstatus-application"
            >
              Discard application
            </Link>

            <Button
              color="secondary"
              variant="contained"
              onClick={handleSubmit(nextStep)}
              disabled={Object.entries(errors)?.length}
              data-testid="save-continue-userstatus"
            >
              Continue
            </Button>
          </Box>
        ) : (
          <Box display="flex" gap={2} py={4} marginLeft={'auto'}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              // sx={{ maxHeight: '40px' }}
              data-testid="cancel-userstatus-button"
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSubmit(updateData)}
              disabled={Object.entries(errors)?.length}
              data-testid="save-update-view-userstatus"
            >
              Update
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSubmit(continueNext)}
              disabled={Object.entries(errors)?.length}
              data-testid="save-continue-view-userstatus"
            >
              Continue
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default UserStatusConfiguration;

UserStatusConfiguration.propTypes = {
  handleCancel: PropTypes.func,
};

UserStatusConfiguration.defaultProps = {
  handleCancel: defaultFn,
};
