import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import RegisterUserContext from 'context/RegisterUserContext';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { editDealerUserSchema } from 'schemaValidations/editDealerUserSchema';
import { enrollDealerUserSchema } from 'schemaValidations/enrollDealerUserSchema';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { defaultFn, handleFocusError, updateCompletedSteps } from 'utils/utility';

import Loader from 'components/Loader/Loader';

import BasicInformation from '../BasicInformation/BasicInformation';

const UserInformation = ({ handleCancel }) => {
  const { setOpenDiscardModal, userName } = useContext(AuthContext);
  const {
    activeStep,
    setActiveStep,
    formDetails,
    setFormDetails,
    eachFormData,
    setEachFormData,
    isFormFilled,
    setIsFormFilled,
    isReview,
    setIsReview,
    isUserIdValid,
    setIsUserIdValid,
    isViewProfile,
    fullProfileData,
    setFormDirty,
    formDirty,
    completedSteps,
    setCompletedSteps,
  } = useContext(RegisterUserContext);
  const navigate = useNavigate();
  const yesAndNo = {
    Yes: true,
    No: false,
    '': '',
  };

  const {
    control,
    watch,
    handleSubmit,
    setFocus,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: 'all',

    defaultValues: {
      userId: '',
      firstName: null,
      lastName: null,
      secondLastName: null,
      mobile: null,
      email: null,
      includeSalesManager: null,
      includeReference: null,
    },
    resolver: yupResolver(!isViewProfile ? enrollDealerUserSchema : editDealerUserSchema),
  });
  const ApiClient = new ApiClass();
  const [loader, setLoader] = useState(false);
  const [
    watchemail,
    watchuserId,
    watchfirstName,
    watchlastName,
    watchsecondLastName,
    watchincludeSalesManager,
    watchincludeReference,
    watchmobile,
  ] = watch([
    'email',
    'userId',
    'firstName',
    'lastName',
    'secondLastName',
    'includeSalesManager',
    'includeReference',
    'mobile',
  ]);
  useEffect(() => {
    handleFocusError({ errors, setFocus });
  }, [errors]);
  const updateData = async () => {
    const formObj = {
      email: watchemail,
      userId: watchuserId,
      linkedUserId: watchuserId,
      firstName: watchfirstName,
      lastName: watchlastName,
      secondLastName: watchsecondLastName,
      includeSalesManager: yesAndNo[watchincludeSalesManager],
      includeReference: yesAndNo[watchincludeReference],
      phone: '+1' + watchmobile,
      team: 'DEALER',
      editedBy: userName,
    };
    const newObj = Object.keys(dirtyFields).reduce((a, v) => ({ ...a, [v]: formObj[v] }), {
      editedBy: userName,
    });
    if (Object.keys(dirtyFields).includes('mobile')) {
      delete newObj.mobile;
      newObj.phone = watchmobile?.length > 0 ? '+1' + watchmobile : '';
    }
    try {
      setLoader(true);
      const r = await ApiClient.post(
        UrlHelpers.updateUserInfo + fullProfileData.userId,
        { ...newObj },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      console.log(r);
      navigate('/view-user-profile', {
        state: { updateStatus: 'success', profileData: { userId: fullProfileData.userId } },
      });
    } catch (e) {
      console.log('error fetching', e);
    } finally {
      setLoader(false);
    }
  };

  const continueNext = () => {
    const formObject = {
      email: watchemail,
      userId: watchuserId,
      linkedUserId: watchuserId,
      firstName: watchfirstName,
      lastName: watchlastName,
      secondLastName: watchsecondLastName,
      includeSalesManager: watchincludeSalesManager,
      includeReference: watchincludeReference,
      mobile: watchmobile,
      team: 'DEALER',
    };
    setFormDirty({ ...formDirty, ...dirtyFields });
    setFormDetails({ ...formDetails, ...formObject });
    const eachData = { ...eachFormData };
    eachData['1'] = formObject;
    setEachFormData(eachData);

    setActiveStep(activeStep + 1);
  };

  const nextStep = () => {
    const formObj = {
      email: watchemail,
      userId: watchuserId,
      linkedUserId: watchuserId,
      firstName: watchfirstName,
      lastName: watchlastName,
      secondLastName: watchsecondLastName,
      includeSalesManager: watchincludeSalesManager,
      includeReference: watchincludeReference,
      mobile: watchmobile,
      team: 'DEALER',
    };
    console.log('formObj');
    console.log(formObj);
    setFormDetails({ ...formDetails, ...formObj });
    const eachData = { ...eachFormData };
    eachData['1'] = formObj;
    setEachFormData(eachData);
    const formFilled = isFormFilled;
    formFilled['1'] = true;
    setIsFormFilled(formFilled);
    updateCompletedSteps([1], completedSteps, setCompletedSteps);
    if (isReview) {
      setIsReview(false);
      setActiveStep(4);
    } else {
      setActiveStep(activeStep + 1);
    }
  };
  const applyFormFields = async (formFieldss) => {
    Object.entries(formFieldss).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  useEffect(() => {
    if (isFormFilled['1'] === true) {
      applyFormFields(eachFormData['1']);
    }
  }, []);
  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const userIdCheck = async () => {
    if (!isViewProfile || formDetails?.userId !== watchuserId) {
      let response = null;
      if (watchuserId && watchuserId.length > 6) {
        setLoader(true);
        try {
          response = await ApiClient.post(`${UrlHelpers.userIdCheck}${watchuserId}`);
          if (response?.statusCode === 200) {
            setIsUserIdValid(true);
          } else {
            setIsUserIdValid(false);
          }
        } catch (e) {
          setIsUserIdValid(null);
          console.log(e);
        } finally {
          setLoader(false);
        }
      } else {
        setIsUserIdValid(null);
      }
    }
  };
  return (
    <>
      {loader && <Loader open={loader} />}
      <Typography variant="h3" component="p" pt={4} px={8}>
        User information
      </Typography>
      <Grid id="1" container justifyContent="center">
        <Box id="2" sx={{ width: '70%' }}>
          <BasicInformation
            control={control}
            errors={errors}
            userIdCheck={userIdCheck}
            isUserIdValid={isUserIdValid}
            setIsUserIdValid={setIsUserIdValid}
            isViewProfile={isViewProfile}
          />
        </Box>
      </Grid>
      <Box display="flex" justifyContent="space-between">
        {!isViewProfile ? (
          <Box display="flex" gap={2} py={4} marginLeft={'auto'}>
            <Link
              component="button"
              onClick={() => {
                setOpenDiscardModal(true);
              }}
              variant="subtitle2"
              color="secondary"
              sx={{ fontSize: '16px' }}
              data-testid="discard-userinfo-application"
            >
              Discard application
            </Link>

            <Button
              color="secondary"
              variant="contained"
              onClick={handleSubmit(nextStep)}
              disabled={Object.entries(errors)?.length || !isUserIdValid}
              data-testid="save-continue-userinfo"
            >
              Continue
            </Button>
          </Box>
        ) : (
          <Box display="flex" gap={2} py={4} marginLeft={'auto'}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              // sx={{ maxHeight: '40px' }}
              data-testid="cancel-userinfo-button"
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSubmit(updateData)}
              disabled={Object.entries(errors)?.length || isUserIdValid === false}
              data-testid="save-update-view-userinfo"
            >
              Update
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSubmit(continueNext)}
              disabled={Object.entries(errors)?.length || isUserIdValid === false}
              data-testid="save-continue-view-userinfo"
            >
              Continue
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default UserInformation;

UserInformation.propTypes = {
  handleCancel: PropTypes.func,
};

UserInformation.defaultProps = {
  handleCancel: defaultFn,
};
