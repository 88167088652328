import * as yup from 'yup';

import { dob } from './validationFunctions/validationFunctions';

export const enrollDealerUserStatusSchema = yup.object().shape({
  isActive: yup.string().required('* Mandatory field').nullable(),
  maximizeTty: yup.string().required('* Mandatory field').nullable(),
  ssn: yup
    .string()
    .nullable()
    .required('* Mandatory field')
    .matches(/^\d+$/g, 'Invalid format')
    .test('length', '* Enter last 4 digits of SSN', (val) => val?.length === 4),
  ...dob,
  vendorNumber: yup
    .string()
    .nullable()
    .matches(/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\d\s]*$/, '* No Special Characters allowed')
    .test('len', 'Maximum 11 characters can be entered', (val) => (val ? val?.length <= 11 : true)),
});
