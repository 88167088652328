import ApiClass from 'Api/ApiClient';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { popupstepMapping, stepMapping } from 'utils/utility';

export const statusTransition = async ({ applicationId, setLoader, userID, fullName }) => {
  const ApiClient = new ApiClass();
  try {
    setLoader(true);
    let resp = await ApiClient.get(
      `${UrlHelpers.getSpecificDetails}?applicationId=${applicationId}&eventName=viewApplication`,
      {
        headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
      }
    );
    return { ...resp, success: true };
  } catch (err) {
    console.log('e', err);
    return err;
  } finally {
    setLoader(false);
  }
};

export const draftExpiry = async ({
  applicationId,
  setLoader,
  setCreateAppApplicationId,
  setProgram,
  setDealer,
  setVehicleType,
  setCreateAppLaunchType,
  setCollateralInfo,
  setDraftFlag,
  setCreateAppFlag,
  setOpenPopUp,
  setPopupStep,
  userID,
  fullName,
}) => {
  const ApiClient = new ApiClass();

  console.log('draft expiry soon', { applicationId, setLoader });

  try {
    setCreateAppApplicationId(applicationId);
    setLoader(true);
    const resp = await ApiClient.get(
      `${UrlHelpers.getApplicationDetails}?applicationId=${applicationId}`,
      {
        headers: { 'Content-Type': 'application/json', userid: userID, userName: fullName },
      }
    );

    const parsedAppData = JSON.parse(resp?.applicationData);

    setProgram(parsedAppData?.loanInformation?.program);
    setDealer(parsedAppData?.loanInformation?.dealerId);
    setVehicleType(parsedAppData?.collateralInformation?.productDetails?.vehicleNewOrOld);
    setCreateAppLaunchType(parsedAppData?.launchType);
    const parsedExeContext = JSON.parse(resp?.executionContext);

    let response = await manualFlowLanding({
      parsedExeContext,
      parsedAppData,
      setCollateralInfo,
      setDraftFlag,
      setCreateAppFlag,
      setOpenPopUp,
      setPopupStep,
    });
    if (response?.navigateFlag) {
      const {
        activeStep = 1,
        userType,
        completedSteps,
        draftFlag,
        draftOpen,
        navigateFlag,
      } = response;
      return {
        activeStep,
        completedSteps,
        userType,
        draftFlag,
        draftOpen,
        navigateFlag,
        success: true,
      };
    } else {
      return { success: true };
    }
  } catch (e) {
    console.log('e', e);
    return { ...e, success: false };
  } finally {
    setLoader(false);
  }
};

const manualFlowLanding = async ({
  parsedExeContext,
  parsedAppData,
  setCollateralInfo,
  setDraftFlag,
  setCreateAppFlag,
  setOpenPopUp,
  setPopupStep,
}) => {
  setCreateAppFlag(true);
  setDraftFlag(true);
  setCollateralInfo({});
  let finishedStep = [];
  let landStep;
  landStep = findLandStep(parsedExeContext);
  finishedStep = await getCompletedSteps(parsedExeContext);
  if (landStep === 0) {
    decideLandingPopup(parsedExeContext, parsedAppData, setPopupStep);
    setOpenPopUp(true);
  } else {
    return {
      activeStep: landStep || 1,
      completedSteps: finishedStep,
      userType: parsedAppData?.landingUserType,
      draftFlag: true,
      draftOpen: true,
      navigateFlag: true,
    };
  }
};

const getCompletedSteps = async (exeContext) => {
  const completedStepExeContext = exeContext?.completedSteps;
  let finishedSteps = [];
  for (const step of completedStepExeContext) {
    if (stepMapping[step]) finishedSteps.push(stepMapping[step]);
  }
  return finishedSteps;
};

const decideLandingPopup = (exeContext, appDataa, setPopupStep) => {
  const completedSteps = exeContext?.completedSteps;
  const lastCompletedStep = completedSteps[completedSteps?.length - 1];
  const updatedStep = popupstepMapping[lastCompletedStep];
  if (
    updatedStep === 2 &&
    !appDataa?.applicants?.addCoApplicantCheckboxSelected &&
    !appDataa?.applicants?.addCoSignerCheckboxSelected
  ) {
    setPopupStep(4);
  } else setPopupStep(updatedStep + 1 || 0);
};

const findLandStep = (exeContext) => {
  const inprogressStep = exeContext?.inprogressStep;
  const inprogressLast = exeContext?.inprogressStep?.[exeContext?.inprogressStep?.length - 1];
  const completedSteps = exeContext?.completedSteps;
  let landStep;
  if (inprogressStep?.length) {
    landStep = stepMapping[inprogressLast];
  } else if (completedSteps?.length) {
    const lastCompletedStep = completedSteps[completedSteps?.length - 1];
    landStep = stepMapping[lastCompletedStep] + 1;
  }
  return landStep || 0;
};
