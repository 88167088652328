import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, IconButton, Typography } from '@mui/material';
import { DataGrid, GridCloseIcon } from '@mui/x-data-grid';
import ApiClass from 'Api/ApiClient';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { handleFocusError, numberToCurrency } from 'utils/utility';
import * as yup from 'yup';

import CustomFileUpload from 'components/CustomFileUpload/CustomFileUpload';
import Loader from 'components/Loader/Loader';

import classes from '../../styles/globalStyle.module.scss';

const DistributedData = () => {
  const ApiClient = new ApiClass();
  const [loader, setLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [getAllData, setGetAllData] = useState([]);
  const [uploadApistatus, setUploadApiStatus] = useState([]);
  const schema = yup.object().shape({
    uploadFile: yup.mixed(),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch,
    setError,
    setFocus,
    clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const watchUploadFile = watch('uploadFile');
  const [openAlert, setOpenAlert] = useState(false);
  useEffect(async () => {
    try {
      setLoader(true);
      const resp = await ApiClient.get(UrlHelpers.distributedDataUploadOnLoad, {
        headers: { 'Content-Type': 'application/json' },
      });
      setGetAllData(resp);
    } catch (e) {
      console.log('error fetching', e);
    } finally {
      setLoader(false);
    }
  }, [uploadApistatus]);
  useEffect(() => {
    const rows = getAllData?.map?.((x, index) => {
      const rowEl = x;
      rowEl.dealerCost = numberToCurrency(parseInt(x?.dealerCost || 0)?.toFixed?.(2));
      return { ...x, id: index };
    });
    setRows(rows);
  }, [getAllData]);
  useEffect(() => {
    handleFocusError({ errors, setFocus });
  }, [errors]);
  const columns = [
    {
      field: 'make',
      headerName: 'Make',
      minWidth: 100,
      flex: 1,
      whiteSpace: 'normal',
    },
    {
      field: 'model',
      headerName: 'Model',
      minWidth: 100,
      flex: 1,
      whiteSpace: 'normal',
    },
    {
      field: 'year',
      headerName: 'Year',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'subModel',
      headerName: 'Sub model',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'dealerCost',
      headerName: 'Dealer cost',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'msrp',
      headerName: 'MSRP',
      minWidth: 100,
      flex: 1,
    },
  ];
  const handleUpload = async (file) => {
    const formData = new FormData();
    console.log('file', file);
    const fileName = file.uploadFile[0]?.name;
    console.log('name', fileName);
    const supportedFileFormats = [
      '.csv',
      'text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ];
    formData.append('file', file.uploadFile[0]);
    if (supportedFileFormats?.includes(file.uploadFile[0]?.type)) {
      clearErrors('uploadFile');
      console.log('make call');
      try {
        setLoader(true);
        const resp = await ApiClient.post(
          `${UrlHelpers.distributedDataUpload}?fileName=${fileName}`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );
        setUploadApiStatus(resp);
        if (resp) {
          setOpenAlert(true);
          setValue('uploadFile', '');
        }
      } catch (e) {
        setError('uploadFile', {
          type: 'custom',
          message: e?.response?.data,
        });
        console.log('error fetching', e);
      } finally {
        setLoader(false);
      }
    } else {
      setError('uploadFile', {
        type: 'custom',
        message: 'Only file types csv/Excel can be uploaded',
      });
      setValue('uploadFile', '');
    }
  };

  return (
    <div>
      {loader && <Loader open={loader} />}
      {openAlert && (
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenAlert(false);
              }}
            >
              <GridCloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          File Uploaded Successfully
        </Alert>
      )}
      <Typography variant="h3" component="h3" my={2} className={classes.heading}>
        Distributor Data
      </Typography>
      <Box display="flex" gap={2.5}>
        <CustomFileUpload
          name="uploadFile"
          label="Upload Excel file(.xlsx)"
          control={control}
          register={register}
          errors={!!errors}
          errorText={errors?.uploadFile?.message}
        />
        <Button
          onClick={handleSubmit(handleUpload)}
          disabled={!!errors?.uploadFile?.message || watchUploadFile?.length <= 0}
          variant="contained"
          color="secondary"
          id="upload-btn"
        >
          Upload
        </Button>
      </Box>

      <Box sx={{ width: '100%' }}>
        <Typography variant="h3" component="h3" my={2} className={classes.heading}>
          Vehicle values
        </Typography>
        <DataGrid
          rows={rows || []}
          columns={columns}
          disableSelectionOnClick
          autoHeight
          experimentalFeatures={{ newEditingApi: true }}
          hideFooter
          getRowId={(row) => row.vId}
        />
      </Box>
    </div>
  );
};

export default DistributedData;
