import { yupResolver } from '@hookform/resolvers/yup';
import AuthContext from 'context/AuthContext';
import CreateApplicationContext from 'context/CreateApplicationContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { CreateApplicationSchema } from 'schemaValidations/CreateApplicationSchema.js';

import ApplicationType from '../ApplicationTypeDialog/ApplicationTypeDialog.js';
import CoAppDetailDialog from '../CoAppDetailDialog/CoAppDetailDialog.js';
import CoAppDialog from '../CoAppDialog/CoAppDialog.js';
import InvitationMaualDialog from '../InvitationManualDialog/InvitationManualDialog.js';
import MoreDetailDialog from '../MoreDetailDialog/MoreDetailDialog.js';

const CreateAppMain = () => {
  const { popupStep } = useContext(AuthContext);
  const [showApptype, setShowAppType] = useState(false);
  const [showCoApp, setShowCoApp] = useState(false);
  const [showInviManual, setShowInviManual] = useState(false);
  const [isMailResidentSame, setIsMailResidentSame] = useState(true);

  const [showCoAppDet, setShowCoAppDet] = useState(false);
  const [coSignerContainer, setCosignerContainer] = useState(false);
  const [addCoSigner, setAddCoSigner] = useState(false);
  const [showMoreDetail, setShowMoreDetail] = useState(!popupStep);
  const [applicationId, setApplicationId] = useState('');
  const [isSkipped, setIsSkipped] = useState(false);
  const [appOrSign, setAppOrSign] = useState([]);
  const [cosignerEnabled, setCosignerEnabled] = useState(false);
  const [showInformationRequestPage, setShowInformationRequestPage] = useState(false);
  const [createAppSchema1, setCreateAppSchema1] = useState({});
  const [primaryApplicantData, setPrimaryApplicantData] = useState({});
  const [loanData, setLoanData] = useState({});
  const {
    control,
    watch,
    clearErrors,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      applicationType: '',
      applicantOrSigner: [],
      invitationOrManual: '',
      CoAppFirstName: '',
      CoAppLastName: '',
      CoAppId: '',
      skipped: isSkipped,

      isNewCoSigner: false,

      CoSigner: [
        { CoSignerName: { CoSignerFirstName: '', CoSignerLastName: '' }, CoSignerAppId: '' },
      ],
    },
    resolver: yupResolver(CreateApplicationSchema(isSkipped, appOrSign, cosignerEnabled)),
  });

  const { fields, append, remove, replace } = useFieldArray({
    name: 'CoSigner',
    control,
  });

  useEffect(() => {
    switch (popupStep) {
      case 1:
        setShowAppType(true);
        break;
      case 2:
        setShowCoApp(true);
        break;
      case 3:
        setShowCoAppDet(true);
        break;
      case 4:
        setShowInviManual(true);
        break;
      default:
        setShowMoreDetail(true);
        break;
    }
  }, [popupStep]);

  useEffect(() => {
    setValue('isNewCoSigner', addCoSigner);
    setAppOrSign(getValues('applicantOrSigner'));
  }, [addCoSigner, getValues('applicantOrSigner')]);

  const applicationContextValue = useMemo(
    () => ({
      showApptype,
      setShowAppType,
      showCoApp,
      setShowCoApp,
      showInviManual,
      setShowInviManual,
      showCoAppDet,
      setShowCoAppDet,
      showMoreDetail,
      setShowMoreDetail,
      applicationId,
      setApplicationId,
      showInformationRequestPage,
      setShowInformationRequestPage,
      isMailResidentSame,
      setIsMailResidentSame,
      isSkipped,
      setIsSkipped,
      createAppSchema1,
      setCreateAppSchema1,
      primaryApplicantData,
      setPrimaryApplicantData,
      loanData,
      setLoanData,
    }),
    [
      showApptype,
      setShowAppType,
      showCoApp,
      setShowCoApp,
      showInviManual,
      setShowInviManual,
      showCoAppDet,
      setShowCoAppDet,
      showMoreDetail,
      setShowMoreDetail,
      applicationId,
      setApplicationId,
      showInformationRequestPage,
      setShowInformationRequestPage,
      isMailResidentSame,
      setIsMailResidentSame,
      isSkipped,
      setIsSkipped,
      createAppSchema1,
      setCreateAppSchema1,
      primaryApplicantData,
      setPrimaryApplicantData,
      loanData,
      setLoanData,
    ]
  );
  return (
    <>
      <CreateApplicationContext.Provider value={applicationContextValue}>
        {showApptype && (
          <ApplicationType
            control={control}
            reset={reset}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
          />
        )}
        {showCoApp && (
          <CoAppDialog
            control1={control}
            reset={reset}
            setValue1={setValue}
            getValues={getValues}
            watch={watch}
            setCosignerEnabled={setCosignerEnabled}
            setCosignerContainer={setCosignerContainer}
          />
        )}

        {showCoAppDet && (
          <CoAppDetailDialog
            control={control}
            reset={reset}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            watch={watch}
            addCoSigner={addCoSigner}
            setAddCoSigner={setAddCoSigner}
            coSignerContainer={coSignerContainer}
            setCosignerContainer={setCosignerContainer}
            handleSubmit={handleSubmit}
            fields={fields}
            append={append}
            remove={remove}
            replace={replace}
            clearErrors={clearErrors}
            cosignerEnabled={cosignerEnabled}
            setCosignerEnabled={setCosignerEnabled}
          />
        )}
        {showInviManual && (
          <InvitationMaualDialog
            control={control}
            reset={reset}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
          />
        )}
        {showMoreDetail && <MoreDetailDialog />}
      </CreateApplicationContext.Provider>
    </>
  );
};
export default CreateAppMain;
