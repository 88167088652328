import 'Api/customAxios';
import { getAccessToken } from 'Services/commonComponentsApi';
import AuthContext from 'context/AuthContext';
import ErrorPage from 'pages/ErrorPage';
import InvitationFlow from 'pages/InviFlow/InvitationFlow';
import { useEffect, useMemo, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import LogoutDialog from 'components/LogoutDialog/LogoutDialog';
import SessionTimeoutDialog from 'components/SessionTimeoutDialog/SessionTimeoutDialog';

import { ProtectedRouteOutlet } from '../Routes/ProtectedRoute';
import protectedRoutes from '../Routes/routes';
import VouchedFlow from '../pages/CreateApplication/VouchedFlow/VouchedFlow';
import LoginFlow from '../pages/LoginFlow/LoginFlow';

const App = () => {
  const [accessToken, setAccessToken] = useState('');
  const [accessTokenId, setAccessTokenId] = useState('');
  const [commonToken, setCommonToken] = useState(false);
  const [idToken, setIdToken] = useState('');
  const [userType, setUserType] = useState('dealer');
  const [role, setRole] = useState('Role not specified');
  const [openPopUp, setOpenPopUp] = useState(false);
  const [popupStep, setPopupStep] = useState(0);
  const [createAppApplicationId, setCreateAppApplicationId] = useState('');
  const [createAppFlag, setCreateAppFlag] = useState(false);
  const [showMainPopup, setShowMainPopup] = useState(false);
  const [showSecondaryPopup, setShowSecondaryPopup] = useState(false);
  const [showInterPopup, setShowInterPopup] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [stateCityList, setstateCityList] = useState({});
  const [stateList, setStateList] = useState([]);
  const [lookupStateList, setLookupStateList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [subModelList, setSubModelList] = useState([]);
  const [styleList, setStyleList] = useState([]);

  const [cityList, setCityList] = useState([]);
  const [cityZipList, setCityZipList] = useState([]);
  const [residentStatusList, setResidentStatusList] = useState([]);
  const [maritalStatusList, setMaritalStatusList] = useState([]);
  const [utilityBillList, setUtilityBillList] = useState([]);
  const [occupationList, setOccupationList] = useState([]);
  const [relationshipList, setRelationShipList] = useState([]);
  const [empStatusList, setEmpStatusList] = useState([]);
  const [industryList, setIndustryList] = useState([]);

  const [vinDetail, setVinDetail] = useState([]);
  const [distributorData, setDistributorResult] = useState([]);
  const [newVehicleDetails, setNewVehicleDetails] = useState([]);
  const [usedVehicleDetails, setUsedVehicleDetails] = useState([]);
  const [newDistributorDetails, setNewDistributorDetails] = useState([]);
  const [pageData, setPageData] = useState({});
  const [showPage, setShowPage] = useState('');
  const [collateralInfo, setCollateralInfo] = useState({});
  const [financeInfo, setFinanceInfo] = useState({});
  const [lookupSaved, setLookupSaved] = useState(false);
  const [lookupLinkClicked, setLookupLinkClicked] = useState(false);
  const [loanPurposeList, setLoanPurposeList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [newUsedList, setNewUsedList] = useState([]);
  const [monthsDeferredList, setMonthsDeferredList] = useState([]);
  const [lookupValue, setLookupValue] = useState({});
  const [rejectClicked, setRejectClicked] = useState(false);
  const [approveClicked, setApproveClicked] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [accessPolicy, setAccessPolicy] = useState({});
  const [createAppLookup, setCreateAppLookup] = useState('');

  const [program, setProgram] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [vehicleYear, setVehicleYear] = useState('');
  const [termList, setTermList] = useState([]);
  const [frequencyList, setFrequencyList] = useState([]);
  const [openExitModal, setOpenExitModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [userID, setUserID] = useState('');
  const [userName, setuserName] = useState('');
  const [fullName, setFullName] = useState('');
  const [rolebasedDealers, setRoleBasedDealers] = useState([]);
  const [createAppLaunchType, setCreateAppLaunchType] = useState('');
  const [invitationSuccess, setInvitationSuccess] = useState(false);
  const [invitationAppNumber, setInvitationAppNumber] = useState('');
  const [inviApplicationNum, setInviApplicationNum] = useState('');
  const [inviApplicantId, setInviApplicantId] = useState('');
  const [inviUserType, setInviUserType] = useState('');
  const [isUserIdValid, setIsUserIdValid] = useState(null);
  const [frequencyDetailList, setFrequencyDetailList] = useState([]);
  const [loanStatusList, setLoanStatusList] = useState([]);
  const [viewApprovalQueue, setViewApprovalQueue] = useState(false);
  const [dealer, setDealer] = useState('');
  const [draftFlag, setDraftFlag] = useState(false);
  const [creditLifeTypeList, setCreditLifeTypeList] = useState([]);
  const [creditLifeList, setCreditLifeList] = useState([]);
  const [vehicleInsuranceTypeList, setVehicleInsuranceTypeList] = useState([]);
  const [generatedDoc, setGeneratedDoc] = useState([]);

  const [isUserIdle, setIsUserIdle] = useState(false);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
  const [accessTokenFromIdToken, setAccessTokenFromIdToken] = useState('');
  const [profilePic, setProfilePic] = useState('');

  const handleOnIdle = () => {
    setIsUserIdle(true);
  };

  const { getRemainingTime } = useIdleTimer({
    timeout: 780000,
    onIdle: handleOnIdle,
    events: ['mousemove', 'DOMMouseScroll', 'click', 'keypress', 'submit', 'keydown', 'keyup'],
  });

  console.log(getRemainingTime());

  const commonTokenExpiry = 3600;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = new useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(async () => {
      const token = await getAccessToken();
      setCommonToken(token);
    }, commonTokenExpiry * 1000);
    return () => clearTimeout(timer);
  }, [commonToken]);

  useEffect(() => {
    if (query.get('autoloan') === 'invitation-form') {
      setInviApplicationNum(query.get('applicationID'));
      setInviApplicantId(query.get('applicantID'));
      setInviUserType(query.get('participantIdentifier'));
      navigate('/invi-flow');
    }
  }, []);

  const authObj = {
    userType,
    accessToken,
    setUserType,
    setAccessToken,
    idToken,
    role,
    setRole,
    setIdToken,
    commonToken,
    setCommonToken,
    cityList,
    setCityList,
    cityZipList,
    setCityZipList,
    residentStatusList,
    setResidentStatusList,
    maritalStatusList,
    setMaritalStatusList,
    utilityBillList,
    setUtilityBillList,
    occupationList,
    setOccupationList,
    empStatusList,
    setEmpStatusList,
    relationshipList,
    setRelationShipList,
    industryList,
    setIndustryList,
    openPopUp,
    setOpenPopUp,
    createAppApplicationId,
    setCreateAppApplicationId,
    createAppFlag,
    setCreateAppFlag,
    showSecondaryPopup,
    setShowSecondaryPopup,
    showMainPopup,
    setShowMainPopup,
    showInterPopup,
    setShowInterPopup,
    showSummary,
    setShowSummary,
    stateList,
    setStateList,
    yearList,
    setYearList,
    makeList,
    setMakeList,
    modelList,
    setModelList,
    subModelList,
    setSubModelList,
    styleList,
    setStyleList,
    vinDetail,
    setVinDetail,
    distributorData,
    setDistributorResult,
    newVehicleDetails,
    setNewVehicleDetails,
    usedVehicleDetails,
    setUsedVehicleDetails,
    newDistributorDetails,
    setNewDistributorDetails,
    pageData,
    setPageData,
    showPage,
    setShowPage,
    collateralInfo,
    setCollateralInfo,
    lookupSaved,
    setLookupSaved,
    lookupLinkClicked,
    setLookupLinkClicked,
    loanPurposeList,
    setLoanPurposeList,
    programList,
    setProgramList,
    newUsedList,
    setNewUsedList,
    monthsDeferredList,
    setMonthsDeferredList,
    lookupValue,
    setLookupValue,
    rejectClicked,
    setRejectClicked,
    approveClicked,
    setApproveClicked,
    dialogOpen,
    setDialogOpen,
    accessPolicy,
    setAccessPolicy,
    program,
    setProgram,
    vehicleType,
    setVehicleType,
    vehicleYear,
    setVehicleYear,
    termList,
    setTermList,
    frequencyList,
    setFrequencyList,
    createAppLookup,
    setCreateAppLookup,
    financeInfo,
    setFinanceInfo,
    openExitModal,
    setOpenExitModal,
    openDiscardModal,
    setOpenDiscardModal,
    userID,
    setUserID,
    userName,
    setuserName,
    rolebasedDealers,
    setRoleBasedDealers,
    createAppLaunchType,
    setCreateAppLaunchType,
    invitationSuccess,
    setInvitationSuccess,
    invitationAppNumber,
    setInvitationAppNumber,
    inviApplicationNum,
    setInviApplicationNum,
    inviApplicantId,
    setInviApplicantId,
    inviUserType,
    setInviUserType,
    isUserIdValid,
    setIsUserIdValid,
    frequencyDetailList,
    setFrequencyDetailList,
    loanStatusList,
    setLoanStatusList,
    viewApprovalQueue,
    setViewApprovalQueue,
    dealer,
    setDealer,
    popupStep,
    setPopupStep,
    draftFlag,
    setDraftFlag,
    creditLifeTypeList,
    setCreditLifeTypeList,
    creditLifeList,
    setCreditLifeList,
    vehicleInsuranceTypeList,
    setVehicleInsuranceTypeList,
    isUserIdle,
    setIsUserIdle,
    isLogoutDialogOpen,
    setIsLogoutDialogOpen,
    generatedDoc,
    setGeneratedDoc,
    accessTokenId,
    setAccessTokenId,
    fullName,
    setFullName,
    accessTokenFromIdToken,
    setAccessTokenFromIdToken,
    profilePic,
    setProfilePic,
    stateCityList,
    setstateCityList,
    lookupStateList,
    setLookupStateList,
  };
  const authContextValue = useMemo(
    () => ({
      ...authObj,
    }),
    [{ ...authObj }]
  );
  console.log('lookupSaved', lookupSaved);
  return (
    <AuthContext.Provider value={authContextValue}>
      {isUserIdle && <SessionTimeoutDialog />}
      {isLogoutDialogOpen && <LogoutDialog />}

      <Routes>
        <Route exact path="/login" element={<LoginFlow />}></Route>
        <Route exact path="/invi-flow" element={<InvitationFlow />}></Route>
        <Route exact path="/saml-error" element={<ErrorPage />}></Route>
        <Route exact path="/vouched" element={<VouchedFlow />}></Route>
        {protectedRoutes.map((x) => (
          <Route key={x.path} path={x.path} exact element={<ProtectedRouteOutlet />}>
            <Route path="" element={x.element} />
          </Route>
        ))}

        <Route exact path="/#access_token=:token" element={null} />
      </Routes>
    </AuthContext.Provider>
  );
};
//sca analysis test

export default App;
