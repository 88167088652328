import { Box, Checkbox, Grid, InputLabel, Typography } from '@mui/material';
import NewUserRequestContext from 'context/NewUserRequestContext';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import CustomCollapse from 'components/CustomCollapse';
import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomMultilineInput from 'components/CustomMultilineInput/CustomMultilineInput';
import CustomPhoneNumber from 'components/CustomPhoneNumber/CustomPhoneNumber';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

const UserRequestInformation = ({ control, errors }) => {
  const { formDetails, setFormDetails, accessTypes, dealers } = useContext(NewUserRequestContext);
  const [authorizeCheck, setAuthorizeCheck] = useState(false);
  const handleCheckboxChange = (e) => {
    setAuthorizeCheck(e.target.checked);
    setFormDetails({ ...formDetails, authorize: e.target.checked });
  };

  return (
    <div>
      <CustomCollapse heading="Information about dealer" open={true} errors={errors}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {/* <CustomInputBox
              control={control}
              errors={!!errors?.userId}
              errorText={errors?.userId?.message}
              placeholder="Enter your text"
              label="User ID"
              name="userId"
              flexDirection="column"
              required
            /> */}
            <CustomSelectBox
              flexDirection="column"
              label="Dealer"
              control={control}
              name="dealer"
              errors={!!errors?.dealer}
              errorText={errors?.dealer?.message}
              options={dealers?.map((dealer) => ({
                name: dealer.dealerName,
                ...dealer,
              }))}
              defaultValue=""
              required
              displayEmpty
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
              <InputLabel>Dealer number</InputLabel>
              <Typography sx={{ width: '100%' }}>{formDetails?.dealerNumber}</Typography>
            </Box>
          </Grid>
        </Grid>
      </CustomCollapse>
      <CustomCollapse heading="Information about user" open={true} errors={errors}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.firstName}
              errorText={errors?.firstName?.message}
              placeholder="Enter first name"
              label="First name"
              name="firstName"
              flexDirection="column"
              required
              regex={/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\s]*$/g}
              maxLength={25}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.lastName}
              errorText={errors?.lastName?.message}
              placeholder="Enter last name"
              label="Last name"
              name="lastName"
              flexDirection="column"
              required
              regex={/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\s]*$/g}
              maxLength={25}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.secondLastName}
              errorText={errors?.secondLastName?.message}
              placeholder="Enter second last name"
              label="Second last name"
              name="secondLastName"
              flexDirection="column"
              regex={/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜ¿¡\s]*$/g}
              maxLength={25}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomDatePicker
              name="dob"
              label="Date of birth"
              control={control}
              error={!!errors?.dob}
              errorText={errors?.dob?.message}
              required
              placeHolder="MM/DD/YYYY"
              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
              sx={{ width: '65%' }}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.ssn}
              errorText={errors?.ssn?.message}
              placeholder="Enter SSN"
              label="SSN (LAST 4 DIGITS)"
              name="ssn"
              flexDirection="column"
              required
              maxLength={4}
              regex={/^\d$/}
              sx={{ width: '65%' }}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomPhoneNumber
              name="mobileUser"
              label="Phone"
              flexDirection="column"
              control={control}
              error={!!errors?.mobileUser}
              errorText={errors?.mobileUser?.message}
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.email}
              errorText={errors?.email?.message}
              placeholder="Enter email"
              label="Email"
              name="email"
              flexDirection="column"
              required
              maxLength={50}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Access type"
              control={control}
              name="accessType"
              errors={!!errors?.accessType}
              errorText={errors?.accessType?.message}
              options={accessTypes?.map((type) => ({
                name: type.displayName,
                ...type,
              }))}
              defaultValue=""
              required
              displayEmpty
            />
          </Grid>
        </Grid>
      </CustomCollapse>
      <CustomCollapse heading="Comment" open={true} errors={errors}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomMultilineInput
              control={control}
              errors={!!errors?.comment}
              errorText={errors?.comment?.message}
              placeholder="Enter comment"
              label="Comment"
              name="comment"
              flexDirection="column"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </CustomCollapse>
      {/* <Box sx={{ flexGrow: 1 }}> */}
      <Box
        sx={{
          bottom: '0',
          // width: '100%',
          // position: 'fixed',
          height: '50px',
          backgroundColor: '#E6E6E6',
          display: 'flex',
          // justifyContent: 'space-around',
        }}
        // classes={{ root: classes.footer }}
      >
        <Box display="flex" justifyContent={'flex-start'}>
          <Box display="flex" justifyContent={'space-around'}>
            <Checkbox
              color="primary"
              onChange={(event) => handleCheckboxChange(event)}
              inputProps={{ 'aria-label': 'authorize-user-creation' }}
              checked={authorizeCheck}
            />
            <Box
              display="flex"
              alignItems="center"
              flexDirection={'column'}
              justifyContent={'center'}
            >
              <Typography variant="subtitle2">I authorize the creation of user</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* </Box> */}
      <Grid container rowSpacing={1} columnSpacing={4}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
            <InputLabel>Name</InputLabel>
            <Typography sx={{ width: '100%' }}>{formDetails.name}</Typography>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
            <InputLabel>Date</InputLabel>
            <Typography sx={{ width: '100%' }}>{moment().format('MM/DD/YYYY')}</Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

UserRequestInformation.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
};
UserRequestInformation.defaultProps = {
  control: {},
  errors: {},
};
export default UserRequestInformation;
