import { ApplicationDashboard } from 'pages/ApplicationDashboard/ApplicationDashboard';
import { ViewApplication } from 'pages/ApplicationDashboard/ViewApplication';
import CreateApplicationBase from 'pages/CreateApplication/CreateApplicationBase';
import InformationRequest from 'pages/CreateApplication/InformationRequest/InformationRequest';
import DealershipUsers from 'pages/DealershipUsers/DealershipUsers';
import DistributedData from 'pages/DistributedData/DistributedData';
import Home from 'pages/Home/Home';
import Lookup from 'pages/Lookup/Lookup';
import ViewOffers from 'pages/Offers/ViewOffers';
import PaymentCalculator from 'pages/PaymentCalculator/PaymentCalculator';
import ProfileView from 'pages/ProfileView/ProfileView';
import Promotions from 'pages/Promotions/Promotions';
import CreatePromotion from 'pages/Promotions/components/CreatePromotion';
import { ManageDealers } from 'pages/RegisterUser/ManageDealers';
import RegisterUser from 'pages/RegisterUser/RegisterUser';
import ViewUserProfile from 'pages/RegisterUser/ViewUserProfile';
import Reports from 'pages/Reports/Reports';
import UserRequest from 'pages/UserRequest/UserRequest';
import UserRequestStatus from 'pages/UserRequestStatus/UserRequestStatus';

const protectedRoutes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/home',
    element: <Home />,
  },
  {
    path: '/paymentCalculator',
    element: <PaymentCalculator />,
  },
  {
    path: '/distributed-data',
    element: <DistributedData />,
  },
  {
    path: '/lookup',
    element: <Lookup />,
  },
  {
    path: '/promotions',
    element: <Promotions />,
  },
  {
    path: '/reports',
    element: <Reports />,
  },
  {
    path: '/home/info-req',
    element: <InformationRequest />,
  },
  {
    path: '/home/create-app',
    element: <CreateApplicationBase />,
  },
  {
    path: '/manage-dealers',
    element: <ManageDealers />,
  },
  {
    path: '/dealership-users',
    element: <DealershipUsers />,
  },
  {
    path: '/register-user',
    element: <RegisterUser />,
  },
  {
    path: '/view-user-profile',
    element: <ViewUserProfile />,
  },
  {
    path: '/new-user-request',
    element: <UserRequest />,
  },
  {
    path: '/user-request',
    element: <UserRequestStatus />,
  },
  {
    path: '/applications',
    element: <ApplicationDashboard />,
  },
  {
    path: '/applications/viewApp',
    element: <ViewApplication />,
  },
  {
    path: '/profile-view',
    element: <ProfileView />,
  },
  {
    path: '/create-promotion',
    element: <CreatePromotion />,
  },
  {
    path: '/offers',
    element: <ViewOffers />,
  },
];

export default protectedRoutes;
